import React, { createElement, FC } from "react";
import { FoodGroup } from "../../models";
import {
  FoodGroupBarChartIcon as FoodGroupSvgIcon,
  FoodGroupLabel,
} from "../../constants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ShoppingAllocationBar from "./ShoppingAllocationBar";
import { ReactComponent as RemoveIcon } from "../../icons/remove-circle.svg";
import { ReactComponent as AddIcon } from "../../icons/add-circle.svg";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import { COLOR_GREYSCALE } from "../../theme";

const BAR_HEIGHT = 36;

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    margin: spacing(2, 0),
    alignItems: "center",
  },
  removeIcon: {
    marginLeft: spacing(2),
    marginRight: spacing(1),
    cursor: "pointer",
    width: "20px",
    height: "20px",
    "&:focus": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "revert",
    },
    "& > path": {
      fill: COLOR_GREYSCALE[500],
    },
  },
  addIcon: {
    cursor: "pointer",
    marginLeft: spacing(1),
    width: "20px",
    height: "20px",
    "&:focus": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "revert",
    },
    "& > path": {
      fill: COLOR_GREYSCALE[700],
    },
  },
  barContainer: {
    display: "flex",
    flex: 1,
    "& > *": {
      flex: 1,
      display: "flex",
      marginLeft: 1,
      height: BAR_HEIGHT,
      borderRadius: 2,
      cursor: "pointer",
    },
    "& > *:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& > *:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  foodGroupIcon: {
    width: "36px",
    height: "36px",
  },
}));

const FoodGroupShoppingAllocation: FC<{
  foodGroup: FoodGroup;
  value: number;
  onChange: (value: number) => void;
}> = ({ foodGroup, value, onChange }) => {
  const classes = useStyles();

  const reduce = () => {
    if (value > 0) {
      onChange(value - 0.25);
    }
  };

  const increase = () => {
    if (value < 1) {
      onChange(value + 0.25);
    }
  };

  return (
    <div className={classes.root}>
      {createElement(FoodGroupSvgIcon[foodGroup], {
        "aria-label":
          FoodGroupLabel[foodGroup] +
          `. Current shopping is ${value * 100}% at Woolworths`,
        className: classes.foodGroupIcon,
      })}
      <RemoveIcon
        role={"button"}
        className={classes.removeIcon}
        onClick={reduce}
        tabIndex={0}
        onKeyPress={(e) => ifEnterOrSpace(e, reduce)}
        aria-label={`Reduce ${FoodGroupLabel[foodGroup]} shopping by ${
          value === 0 ? "0" : "25"
        }%. Current shopping is ${value * 100}% at Woolworths`}
      />
      <div className={classes.barContainer}>
        <ShoppingAllocationBar
          threshold={0.25}
          value={value}
          onClick={() => onChange(0.25)}
        />
        <ShoppingAllocationBar
          threshold={0.5}
          value={value}
          onClick={() => onChange(0.5)}
        />
        <ShoppingAllocationBar
          threshold={0.75}
          value={value}
          onClick={() => onChange(0.75)}
        />
        <ShoppingAllocationBar
          threshold={1}
          value={value}
          onClick={() => onChange(1)}
        />
      </div>
      <AddIcon
        role={"button"}
        className={classes.addIcon}
        onClick={increase}
        tabIndex={0}
        onKeyPress={(e) => ifEnterOrSpace(e, increase)}
        aria-label={`Increase ${FoodGroupLabel[foodGroup]} shopping by ${
          value === 1 ? "0" : "25"
        }%. Current value is ${value * 100}%`}
      />
    </div>
  );
};

export default FoodGroupShoppingAllocation;
