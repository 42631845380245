import { createContext, FC, useEffect, useState } from "react";
import { User } from "@firebase/auth";
import { exchangeJwtForCustomAuthToken, signIn } from "../../services/auth";

interface Props {
  jwt?: string;
}

interface AuthContextProps {
  user: User | null;
  jwt?: string;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  jwt: "",
});

const AuthProvider: FC<Props> = ({ jwt, children }) => {
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    const init = async () => {
      try {
        const customToken = await exchangeJwtForCustomAuthToken(jwt ?? "");
        setUser(await signIn(customToken));
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [jwt]);

  return (
    <AuthContext.Provider value={{ user, jwt }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
