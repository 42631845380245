import React, { useContext, useEffect, useState } from "react";
import useApplyFeedbackDialogConfig from "../../hooks/useApplyFeedbackDialogConfig";
import { Feedback } from "./index";
import { NewFeaturesContext } from "../../components/new-features";
import { Button, Dialog, IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { LIGHTER_CHARCOAL } from "../../theme";
import useFeedback from "../../hooks/useFeedback";

const useStyles = makeStyles(() => ({
  closeButton: {
    position: "absolute",
    top: "12px",
    right: "8px",
    color: LIGHTER_CHARCOAL,
  },
  dialogPaper: {
    width: "calc(100% - 32px)",
    margin: "16px",
  },
}));

const FeedbackDialog: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isFeaturesAcknowledged } = useContext(NewFeaturesContext);
  const { handleDismissed } = useFeedback();
  const showFeedbackDialog = useApplyFeedbackDialogConfig();

  useEffect(() => {
    if (!isFeaturesAcknowledged && showFeedbackDialog) {
      setOpen(true);
    }
  }, [isFeaturesAcknowledged, showFeedbackDialog]);

  const handleFeedbackDismissed = () => {
    handleDismissed();
    setOpen(false);
  };

  const handleFeedbackSubmit = () => {
    //submitting feedback logic is handled by <Feedback/>
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleFeedbackDismissed}
      open={open}
      fullScreen={false}
      maxWidth={"sm"}
      fullWidth={true}
      TransitionProps={{ role: "presentation" } as any}
      PaperProps={{ role: "main", className: classes.dialogPaper } as any}
    >
      <IconButton
        aria-label="Close Button"
        onClick={handleFeedbackDismissed}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <Feedback
        DoneButton={
          <Button
            variant={"contained"}
            color={"primary"}
            endIcon={<CheckIcon />}
          >
            Submit Feedback
          </Button>
        }
        onDoneClick={handleFeedbackSubmit}
      />
    </Dialog>
  );
};

export default FeedbackDialog;
