import {
  InfoModalContent,
  InfoModalTitle,
} from "../../../components-2/InfoModal";
import { FoodGroup } from "../../../models";

const RecommendedServesExplainerContent = ({
  foodGroup,
}: {
  foodGroup: FoodGroup | "sometimes";
}) => {
  const Content = () => {
    switch (foodGroup) {
      case "dairy":
        return <Dairy />;
      case "fruit":
        return <Fruit />;
      case "grains":
        return <Grains />;
      case "vegetables":
        return <Vegetables />;
      case "protein":
        return <Protein />;
      case "sometimes":
        return <Sometimes />;
      default:
        return <NotFound />;
    }
  };
  return (
    <>
      <InfoModalContent>
        {foodGroup === "sometimes"
          ? "Sometimes foods are broken down into six categories so you can get a clear picture of your shopping basket."
          : "Recommended serves for your household are based on the Australian Dietary Guidelines. This shows the % of serves in your basket compared to the total recommended serves for your household."}
      </InfoModalContent>
      {foodGroup !== "sometimes" && (
        <>
          <InfoModalContent>
            <ul>
              <li>
                If your result is at or above 100% then you’ve met your
                recommended serves.
              </li>
              <li>
                If your result is below 100% then there’s room for improvement
                to meet your recommended serves.
              </li>
            </ul>
          </InfoModalContent>
          <InfoModalTitle>How many serves do I need?</InfoModalTitle>
        </>
      )}
      <Content />
    </>
  );
};

const NotFound = () => {
  return (
    <>
      <InfoModalContent>Not Found</InfoModalContent>
    </>
  );
};

const Dairy = () => {
  return (
    <>
      <InfoModalContent>
        Most people need at least 2-3 serves from this group each day, however,
        the minimum recommended amount will vary according to your age, sex and
        life stage.
      </InfoModalContent>
      <InfoModalContent>
        Food Tracker takes the recommended daily serves for each person you have
        set up in your household and multiplies this to show you the recommended
        serves across week, fortnight or month (depending on what you select).
      </InfoModalContent>
    </>
  );
};

const Fruit = () => {
  return (
    <>
      <InfoModalContent>
        Most people need 2 serves of fruit per day.
      </InfoModalContent>
      <InfoModalContent>
        Food Tracker takes the recommended daily serves for each person in your
        household and multiplies this to show you the recommended serves across
        week, fortnight or month (depending on what you select).
      </InfoModalContent>
    </>
  );
};

const Grains = () => {
  return (
    <>
      <InfoModalContent>
        Most people need 4-6 serves of grains per day, however your
        recommendation will vary according to your age, sex and life stage.
      </InfoModalContent>
      <InfoModalContent>
        Food Tracker takes the recommended daily serves for each person in your
        household and multiplies this to show you the recommended serves across
        week, fortnight or month (depending on what you select).
      </InfoModalContent>
    </>
  );
};

const Vegetables = () => {
  return (
    <>
      <InfoModalContent>
        Most people need at least 5-6 serves from this group each day, however,
        the minimum recommended amount will vary according to your age, sex and
        life stage.
      </InfoModalContent>
      <InfoModalContent>
        Food Tracker takes the recommended daily serves for each person in your
        household and multiplies this to show you the recommended serves across
        week, fortnight or month (depending on what you select).
      </InfoModalContent>
    </>
  );
};

const Protein = () => {
  return (
    <>
      <InfoModalContent>
        Most people need 2-3 serves of Meat & alternatives per day, however your
        recommendation will vary according to your age, sex and life stage.
      </InfoModalContent>
      <InfoModalContent>
        Food Tracker takes the recommended daily serves for each person in your
        household and multiplies this to show you the recommended serves across
        week, fortnight or month (depending on what you select).
      </InfoModalContent>
    </>
  );
};

const Sometimes = () => {
  return (
    <InfoModalContent>
      <ul>
        <li>
          Sweet & savoury foods: lollies, cakes, biscuits, muesli bars and ice
          cream, and savoury foods like savoury biscuits, pastries and chips.
        </li>
        <li>
          Sugar sweetened drinks: drinks with added sugar, such as soft drinks,
          sports drinks and cordial.
        </li>
        <li>
          Sugar substitute drinks: drinks with less sugar that may also have
          natural or artificial sweeteners.
        </li>
        <li>
          Fried foods & mixed foods: usually convenience foods such as
          ready-made-pizza, garlic bread, spring rolls and other party foods.
        </li>
        <li>
          Sauces, spreads & miscellaneous: foods like cooking sauces and pastes,
          sweet spreads, syrups, butter, mayonnaise and dressings.
        </li>
        <li>
          Processed meats: salami, bacon, burgers, sausages and other processed
          meats.
        </li>
      </ul>
    </InfoModalContent>
  );
};

export default RecommendedServesExplainerContent;
