import { useContext, useMemo } from "react";
import { SelectedTimeFrameBasketExclusionsContext } from "../providers/SelectedTimeFrameBasketExclusionsProvider";
import {
  filterBasketByNonExcludedItems,
  totalServingsByDiscretionaryCategory,
  totalServingsByFoodGroup,
} from "../../services/basketdetails";
import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";
import { BasketDay } from "../../models";

interface Props {
  categories: {
    id: string;
    title: string;
    alias: string;
    description: string;
  }[];
  basketDetails: BasketDay[];
  isEmpty?: boolean;
}

const SometimesFoodsBreakdownChart = ({
  categories,
  basketDetails,
  isEmpty,
}: Props) => {
  const { exclusions } = useContext(SelectedTimeFrameBasketExclusionsContext);

  const useStyles = makeStyles(({ breakpoints }) => ({
    percentageWrapper: {
      display: "grid",
      marginTop: "24px",
      columnGap: "8px",
      rowGap: "12px",
      gridTemplateColumns: "147px 1fr",
      boxSizing: "border-box",
      [breakpoints.up("lg")]: {
        gridTemplateColumns: "176px 1fr",
        columnGap: "24px",
        rowGap: "16px",
        marginBottom: "24px",
      },
    },
    label: {
      width: "100%",
      textTransform: "uppercase",
      textAlign: "right",
      color: COLOR_GREYSCALE[800],
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      margin: 0,
      [breakpoints.up("lg")]: {
        fontSize: "12px",
        letterSpacing: "0.3px",
      },
    },
    percentageBar: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: "calc(100% - 28px)",
      [breakpoints.up("lg")]: {
        width: "calc(100% - 36px)",
      },
    },
    bar: {
      position: "relative",
      width: isEmpty ? "0%" : "100%",
      minWidth: "1px",
      borderRadius: "12px",
      height: "12px",
      backgroundColor: "#FFE14E",
    },
    percent: {
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      marginRight: "auto",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "-28px",
      [breakpoints.up("lg")]: {
        right: "-36px",
      },
    },
  }));

  const classes = useStyles();

  const basketItemsWithoutExclusions = useMemo(
    () => filterBasketByNonExcludedItems(basketDetails, exclusions),
    [basketDetails, exclusions]
  );

  let percents = useMemo(() => {
    const emptyState = [
      {
        title: "Sweet & savoury foods",
        percent: 0,
      },
      {
        title: "Sugar sweetened drinks",
        percent: 0,
      },
      {
        title: "Sugar substitute drinks",
        percent: 0,
      },
      {
        title: "Fried foods & mixed foods",
        percent: 0,
      },
      {
        title: "Sauces, spreads & misc.",
        percent: 0,
      },
      {
        title: "Processed meat",
        percent: 0,
      },
    ];
    if (isEmpty || !basketItemsWithoutExclusions.length) {
      return emptyState;
    }

    const totalServings = totalServingsByFoodGroup(
      basketItemsWithoutExclusions,
      "discretionary"
    );

    if (totalServings === 0) return emptyState;

    const nonRoundedPercents = categories.map((category) => {
      return {
        title: category.alias,
        percent:
          (totalServingsByDiscretionaryCategory(
            basketItemsWithoutExclusions,
            category.id
          ) /
            totalServings) *
          100,
      };
    });

    const roundedPercents = nonRoundedPercents.map((category) => ({
      title: category.title,
      percent: Math.round(category.percent),
    }));

    const totalRounded = roundedPercents.reduce(
      (sum, category) => sum + category.percent,
      0
    );
    const difference = totalRounded - 100;
    if (difference !== 0) {
      const highest = roundedPercents.reduce((a, b) =>
        a.percent > b.percent ? a : b
      );
      if (difference > 0) {
        highest.percent -= difference;
      } else {
        highest.percent += Math.abs(difference);
      }
    }

    return roundedPercents.sort((a, b) => b.percent - a.percent);
  }, [basketItemsWithoutExclusions, categories, isEmpty]);

  return (
    <div className={classes.percentageWrapper}>
      {percents.map((percent) => (
        <>
          <p className={classes.label}>{percent.title}</p>
          <div className={classes.percentageBar}>
            <div
              className={classes.bar}
              style={{
                width: `${
                  percents[0].percent === 0
                    ? 0
                    : Math.ceil((percent.percent / percents[0].percent) * 100)
                }%`,
              }}
            >
              <span className={classes.percent}>{percent.percent}%</span>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default SometimesFoodsBreakdownChart;
