import { makeStyles } from "@material-ui/core";
import { ReactComponent as Info } from "../../icons/info.svg";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";

const BalanceOfShopHouseholdEmptyServing = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      display: "flex",
      position: "relative",
      overflow: "hidden",
      padding: "12px",
      paddingLeft: "16px",
      borderRadius: "8px",
      gap: "10px",
      marginTop: "81px",
      backgroundColor: COLOR_GREYSCALE[50],
      "&::before": {
        content: `" "`,
        width: "8px",
        left: 0,
        top: 0,
        position: "absolute",
        display: "block",
        height: "100%",
        backgroundColor: DARK_GREEN,
      },
      [breakpoints.up("md")]: {
        marginTop: "122px",
        marginBottom: "26px",
      },
    },
    emptyDisclaimer: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "#1D1F20",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      gap: "16px 8px",
      width: "100%",
      [breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
    insightDescription: {
      letterSpacing: "0.3px",
      color: "#1D1F20",
      margin: 0,
      fontSize: "14px",
      lineHeight: "20px",
      flex: 1,
      boxSizing: "border-box",
    },
    icon: {
      minWidth: 24,
      transform: "rotate(180deg)",
      "& path": {
        stroke: DARK_GREEN,
      },
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      boxShadow: "none",
      textTransform: "initial",
      flex: 1,
      marginLeft: "-16px",
      marginRight: "16px",
      "&:disabled": {
        boxShadow: "none",
      },
      [breakpoints.up("md")]: {
        margin: "0 12px",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Info className={classes.icon} />
      <div className={classes.emptyDisclaimer}>
        <p className={classes.insightDescription}>
          Bummer! We don’t have anything to show you at the moment. Check back
          once you’ve shopped.
        </p>
      </div>
    </div>
  );
};

export default BalanceOfShopHouseholdEmptyServing;
