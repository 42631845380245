import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import { Typography } from "@material-ui/core";
import { Text } from "../Text";
import { ImageCardStepper } from "./stepper";
import { makeStyles } from "@material-ui/core/styles";
import { BackButton, CancelButton } from "../animated-drawer";

interface CardProps {
  title: string;
  content: {
    buttonColor: string;
    title: string | ReactElement;
    image: string;
    content: (ReactElement | string)[];
  }[];
  setOpenCard: Dispatch<SetStateAction<string | null>>;
  layoutId: string;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export const IMAGE_HEIGHT = 289;

export const OpenCard = ({
  title,
  content,
  setOpenCard,
  layoutId,
  setOpenDrawer,
}: CardProps) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    cardContainer: {
      [breakpoints.down("sm")]: {
        borderRadius: "10px",
      },
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "fixed",
      bottom: 0,
      zIndex: 100,
      width: "100vw",
      maxWidth: "506px",
      left: 0,
    },
    imageSection: {
      width: "auto",
      zIndex: 2,
      position: "sticky",
      top: 0,
      height: `${IMAGE_HEIGHT}px`,
      transition: "background-image 0.2s ease-in",
      backgroundSize: `auto ${IMAGE_HEIGHT * 1.2}px`,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "24px",
      overflow: "hidden",
      backgroundPosition: "center",
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      border: "none",
      padding: "5px 10px",
      borderRadius: "5px",
      cursor: "pointer",
    },
    overlayContainer: {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "calc(100% - 32px)",
      backgroundColor: "rgb(145 144 144 / 90%)",
      padding: "12px 16px 12px 16px",
      boxSizing: "content-box",
    },
    overlayText: {
      margin: 0,
      height: "100%",
      color: "white",
      fontFamily: "Gilroy-SemiBold",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "0.1px",
    },
    contentSection: {
      padding: "24px",
      zIndex: 1,
      position: "relative",
      overflowY: "auto",
      overflowX: "hidden",
      flex: 1,
    },
    gradientOverlay: {
      position: "fixed",
      bottom: "60px",
      left: "0",
      width: "100%",
      height: "80px",
      marginTop: "80px",
      background:
        "linear-gradient(to bottom, transparent, #faf5ed 100%, #faf5ed)",
      pointerEvents: "none",
      zIndex: 2,
    },
    stepperContainer: {
      position: "sticky",
      bottom: 0,
      padding: "0px 24px",
      zIndex: 3,
      display: "flex",
      alignItems: "flex-end",
    },
  }));

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const imageRef = useRef<HTMLDivElement>(null);
  const contentParentRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [scrollBlockedBefore, setScrollBlockedBefore] = useState(false);
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT);

  const handleNext = () => {
    if (activeStep === content.length - 1) {
      setOpenCard(null);
    } else {
      setActiveStep((step) => step + 1);
    }
  };

  const handlePrevious = () => {
    setActiveStep((step) => step - 1);
  };

  useLayoutEffect(() => {
    const contentParentSection = contentParentRef.current;
    const contentSection = contentRef.current;
    const imageSection = imageRef.current;

    if (!contentParentSection || !imageSection || !contentSection) return;

    let isProcessing = false;

    const handleScroll = (event: any) => {
      const scrollRequired =
        contentSection.clientHeight > contentParentSection.clientHeight;
      const isVerticalScroll = event.deltaY !== 0;

      if (
        !scrollBlockedBefore &&
        !isProcessing &&
        scrollRequired &&
        isVerticalScroll
      ) {
        event.preventDefault();
        isProcessing = true;

        const scrollTop = contentParentSection.scrollTop;
        contentParentSection.style.overflowY = "hidden";

        if (!collapsed) {
          setImageHeight((height) => height / 2);
          setCollapsed(true);
        }

        setTimeout(() => {
          contentParentSection.style.overflowY = "auto";
          contentParentSection.scrollTop = scrollTop;
          setScrollBlockedBefore(true);
          isProcessing = false;
        }, 225);
      }
    };

    contentParentSection.addEventListener("wheel", handleScroll, {
      passive: false,
    });
    contentParentSection.addEventListener("touchmove", handleScroll, {
      passive: false,
    });

    return () => {
      contentParentSection.removeEventListener("wheel", handleScroll);
      contentParentSection.removeEventListener("touchmove", handleScroll);
    };
  }, [collapsed, scrollBlockedBefore, activeStep]);

  useEffect(() => {
    // cache images for smoother transitions
    let timeout: NodeJS.Timeout | undefined;
    timeout = setTimeout(() => {
      content.forEach((step) => {
        new Image().src = step.image;
      });
    }, 500);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [content]);

  const [animationComplete, setAnimationComplete] = useState(false);

  return (
    <motion.div className={classes.cardContainer}>
      <motion.div
        onAnimationComplete={() => {
          setAnimationComplete(true);
          if (imageRef?.current) {
            imageRef.current.style.transform = "none";
          }
        }}
        layoutId={layoutId}
        initial={{
          borderTopRightRadius: "100px",
          borderTopLeftRadius: "100px",
          height: "176px",
        }}
        animate={{
          borderTopRightRadius: "0px",
          borderTopLeftRadius: "0px",
          height: `${imageHeight}px`,
        }}
        transition={{
          duration: 0.35,
        }}
        className={classes.imageSection}
        ref={imageRef}
        style={{
          backgroundImage: `url("${content[activeStep].image}")`,
        }}
      >
        <BackButton
          color={content[activeStep]?.buttonColor}
          onClick={() => setOpenCard(null)}
        />
        <CancelButton onClick={() => setOpenDrawer(false)} />
        <motion.div className={classes.overlayContainer}>
          <Typography className={classes.overlayText}>{title}</Typography>
        </motion.div>
      </motion.div>
      <motion.div
        ref={contentParentRef}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            opacity: 0,
            height: "176px",
          },
          visible: {
            opacity: 1,
            height: "inherit",
            transition: {
              opacity: {
                duration: activeStep === 0 ? 0.45 : 0,
                type: "Inertia",
              },
            },
          },
        }}
        style={{
          flex: 1,
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          overflowX: "hidden",
        }}
      >
        <motion.div
          ref={contentRef}
          key={activeStep}
          data-vaul-no-drag
          className={classes.contentSection}
        >
          <Text
            data-vaul-no-drag
            variant={"h1"}
            style={{
              marginBottom: "16px",
            }}
          >
            {content[activeStep]?.title}
          </Text>
          {content[activeStep]?.content.map((subContent, index, arr) => {
            const isLast = index === arr.length - 1;
            return (
              <Text
                data-vaul-no-drag
                key={index}
                variant={"body1"}
                style={{
                  marginBottom: isLast ? "40px" : 0,
                }}
              >
                {subContent}
              </Text>
            );
          })}
          <motion.div
            className={classes.gradientOverlay}
            data-vaul-no-drag="true"
          />
        </motion.div>
      </motion.div>
      <motion.div
        initial={false}
        className={classes.stepperContainer}
        animate={{ opacity: animationComplete ? 1 : 0 }}
        transition={{
          duration: animationComplete ? 0.35 : 0,
        }}
      >
        <ImageCardStepper
          activeStep={activeStep}
          amountOfSteps={content.length}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </motion.div>
    </motion.div>
  );
};
