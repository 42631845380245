import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { ThemeOptions } from "@material-ui/core/styles";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "intersection-observer";

import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { getImpersonationJwt } from "./services/auth";
import { HttpStatusCodes } from "./constants";
import "./index.css";
import {
  ADD_REALTIME_ITEM_EVENT,
  REMOVE_REALTIME_ITEM_EVENT,
} from "./components/providers/RealtimeListProvider";

const isDevelopment = process.env.NODE_ENV === "development";

interface Options {
  mountId: string;
  jwt: string;
  theme?: ThemeOptions;
  onError?: (error: Error) => void;
}

interface ErrorOptions {
  mountId: string;
  statusCode: HttpStatusCodes;
  theme?: ThemeOptions;
}

export const fetchImpersonationJwt = async (
  memberId: string,
  idToken?: string
): Promise<string> => {
  return getImpersonationJwt(memberId, idToken);
};

export const init = ({ mountId, jwt, theme, onError }: Options) => {
  render(mountId, <App jwt={jwt} theme={theme} onError={onError} />);
};

export const error = ({ mountId, statusCode, theme }: ErrorOptions) => {
  render(mountId, <App errorStatusCode={statusCode} theme={theme} />);
};

export const addRealtimeItem = (articleId: string) => {
  const event = new CustomEvent(ADD_REALTIME_ITEM_EVENT, {
    detail: { articleId },
  });
  document.dispatchEvent(event);
};

export const removeRealtimeItem = (articleId: string) => {
  const event = new CustomEvent(REMOVE_REALTIME_ITEM_EVENT, {
    detail: { articleId },
  });
  document.dispatchEvent(event);
};

const render = (mountId: string, component: ReactNode) => {
  ReactDOM.render(
    <React.StrictMode>{component}</React.StrictMode>,
    document.getElementById(mountId)
  );
};

if (isDevelopment) {
  const memberId = "9355130153753";

  fetchImpersonationJwt(memberId).then((jwt) => {
    init({
      mountId: "root",
      jwt,
    });
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
