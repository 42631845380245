import { makeStyles, Typography } from "@material-ui/core";
import { CSSProperties, FC, ReactNode } from "react";
import { WHITE } from "../theme";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(3),
  },
  field: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    whiteSpace: "nowrap",
    minWidth: 82,
    marginRight: spacing(2),
  },
  select: {
    "& .MuiSelect-root": {
      background: WHITE,
    },
  },
  h3: {
    fontFamily: "'Gilroy-Semibold'",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontWeight: "normal",
    margin: 0,
  },
}));

interface Props {
  id: string;
  label: string;
  children: ReactNode;
  style?: CSSProperties;
}

const LeftAlignedLabel: FC<Props> = ({ id, label, children, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <label className={classes.label} htmlFor={id}>
        <Typography variant="h3" className={classes.h3} style={style}>
          {label}
        </Typography>
      </label>
      {children}
    </div>
  );
};

export default LeftAlignedLabel;
