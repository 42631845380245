import { cloneElement, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { RECIPES_EVENTS } from "../../../constants";

import { RecipeContext } from "../../../components/providers/RecipeProvider";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { COLOR_GREYSCALE } from "../../../theme";
import useEvents from "../../../hooks/useEvents";
import { RECIPE_DISPLAY_LIMIT } from "../../../constants";
import useHideDietaryPreferences from "../../../hooks/useHideDietaryPreferences";

const useStyles = makeStyles(() => ({
  filterTags: {
    padding: "4px 8px",
    color: COLOR_GREYSCALE[900],
    fontSize: "12px",
    fontFamily: "Gilroy",
    lineHeight: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    border: `solid 1px ${COLOR_GREYSCALE[200]}`,
    letterSpacing: "0.2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  activeFilter: {
    backgroundColor: COLOR_GREYSCALE[900],
    color: "#FFF",
  },
  checkIcon: {
    width: "12px",
    height: "12px",
    "& path": {
      stroke: "#9EFF70",
    },
  },
  closeIcon: {
    width: "12px",
    height: "12px",
    "& path": {
      stroke: "#fff",
    },
  },
}));

const FilterTag = ({
  filter,
  isSelected,
  group,
  isResultPills,
}: {
  filter: any;
  isSelected: boolean;
  group: any;
  isResultPills?: boolean;
}) => {
  const classes = useStyles();
  const { track } = useEvents();

  const {
    setFilteredRecipes,
    setCurrentFilters,
    filteredRecipes,
    setTempFilters,
    tempFilters,
    setRecipeCounter,
    setActiveFoodGroup,
  } = useContext(RecipeContext);

  const hideDP = useHideDietaryPreferences();

  const capitalizeFirstWordLowercaseRest = (filterName: string) => {
    const words = filterName.split(" ");
    const processedWords = words.map((word, index) => {
      if (word.length > 0) {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word.toLowerCase();
        }
      } else {
        return "";
      }
    });
    const resultString = processedWords.join(" ");
    return resultString;
  };

  const replaceAndWithAmpersand = (inputString: string) => {
    const replacedString = inputString.replace(/ and /g, " & ");
    return replacedString;
  };

  return (
    <button
      key={filter.id}
      className={`${classes.filterTags} ${isSelected && classes.activeFilter}`}
      type="button"
      onClick={() => {
        const groupname = group.name.replace(" ", "");
        track(RECIPES_EVENTS.RLP_FILTER_APPLY_FILTER);
        setRecipeCounter(RECIPE_DISPLAY_LIMIT);

        if (groupname === "foodgroup") {
          if (isResultPills) {
            if (filteredRecipes.includes(`${groupname}:${filter.id}`)) {
              setFilteredRecipes(
                filteredRecipes.filter(
                  (item: string) => item !== `${groupname}:${filter.id}`
                )
              );
              setTempFilters(
                filteredRecipes.filter(
                  (item: string) => item !== `${groupname}:${filter.id}`
                )
              );
              setCurrentFilters(
                filteredRecipes.filter(
                  (item: string) => item !== `${groupname}:${filter.id}`
                )
              );
            } else {
              setFilteredRecipes([
                ...filteredRecipes,
                `${groupname}:${filter.id}`,
              ]);
              setTempFilters([...filteredRecipes, `${groupname}:${filter.id}`]);
              setCurrentFilters([
                ...filteredRecipes,
                `${groupname}:${filter.id}`,
              ]);
            }
          } else {
            // choose only 1 food group and reset ingredients and meal type filters
            let f = [] as string[];

            if (!hideDP) {
              f = tempFilters.filter((filter) =>
                filter.includes("dietarypreferences")
              );
            }

            if (tempFilters.includes(`${groupname}:${filter.id}`)) {
              setTempFilters(f);
              setActiveFoodGroup(f);
            } else {
              setTempFilters([...f, `${groupname}:${filter.id}`]);
              setActiveFoodGroup([...f, `${groupname}:${filter.id}`]);
            }
          }
        } else {
          if (isResultPills) {
            if (filteredRecipes.includes(`${groupname}:${filter.id}`)) {
              setFilteredRecipes(
                filteredRecipes.filter(
                  (item: string) => item !== `${groupname}:${filter.id}`
                )
              );
              setCurrentFilters(
                filteredRecipes.filter(
                  (item: string) => item !== `${groupname}:${filter.id}`
                )
              );
            } else {
              setFilteredRecipes([
                ...filteredRecipes,
                `${groupname}:${filter.id}`,
              ]);
            }
          }

          if (tempFilters.includes(`${groupname}:${filter.id}`)) {
            setTempFilters(
              tempFilters.filter(
                (item: string) => item !== `${groupname}:${filter.id}`
              )
            );
          } else {
            setTempFilters([...tempFilters, `${groupname}:${filter.id}`]);
          }
        }
      }}
    >
      {filter.icon &&
        cloneElement(filter.icon, {
          width: 21,
          height: 21,
        })}
      {replaceAndWithAmpersand(capitalizeFirstWordLowercaseRest(filter.name))}
      {isSelected && <CheckIcon className={classes.checkIcon} />}
    </button>
  );
};

export default FilterTag;
