export const SOMETIMES_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Sometimes foods & drinks",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [
              {
                __typename: "FoodGroupCategory",
                id: "sugar_sweetened_beverages",
                title: "Sugar sweetened drinks",
                description:
                  "This group contains drinks that have added sugar, such as soft drinks, sports drinks, sweetened waters and cordial.",
                color: "#BD75B7",
              },
              {
                __typename: "FoodGroupCategory",
                id: "sweet_savoury_snack_foods",
                title: "Sweet & savoury foods",
                description:
                  "This group contains sweet foods like lollies, cakes, biscuits, muesli bars and ice cream, and savoury foods like savoury biscuits, pastries and crisps.",
                color: "#7575B5",
              },
              {
                __typename: "FoodGroupCategory",
                id: "fried_foods_prepared_meals",
                title: "Fried foods & mixed foods",
                description:
                  "This group contains convenience foods such as ready-made-pizza, garlic bread, spring rolls and other party foods.",
                color: "#6ABD97",
              },
              {
                __typename: "FoodGroupCategory",
                id: "sauces_spreads_misc_ingredients",
                title: "Sauces, spreads & miscellaneous",
                description:
                  "This group contains things like sweet spreads, syrups, butter, mayonnaise, sugar, salt and gravy.",
                color: "#C2B660",
              },
              {
                __typename: "FoodGroupCategory",
                id: "processed_meat",
                title: "Processed meat",
                description:
                  "This group contains foods like salami, bacon, burgers, sausages and other processed meats.",
                color: "#D17474",
              },
            ],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "heading-2",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "What are sometimes (discretionary) foods or drinks?",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Sometimes (",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "discretionary",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              ") foods and drinks are usually high in additional energy (kilojoules) and low in nutrients the body needs. It’s OK to have these sometimes foods in small amounts as part of a balanced nutritious diet and to enjoy them mindfully.\r\nIf your percentage of ‘sometimes’ foods is looking a bit high, start by aiming to gradually bring this down each week by making some healthier swaps.\r\n",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "heading-2",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Why have sometimes foods and drinks in small amounts?",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "While food provides us with nutrition, we also eat for other reasons, and enjoying your favourite foods, eating socially and eating foods that bring you joy is also important. It’s important not to focus on just one food or one meal, but on your whole diet. It’s OK to have these sometimes foods in small amounts as part of a balanced nutritious diet and to enjoy them mindfully.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Sometimes we don’t realise how many of these foods we are choosing. This tool is a really useful way to help you become more aware of your choices. If your percentage of ‘sometimes’ foods is looking a bit high, start by aiming to gradually bring this down each week by making some healthier swaps.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Evidence shows",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " these foods have been associated with an increased risk of some long-term health conditions, including type 2 diabetes, and they are not an essential part of the diet.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "We have identified five major categories of sometimes foods, based on the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Health Survey",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/4363.0.55.001Chapter65062011-13",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              ": Users' Guide, 2011-13 — Discretionary Food List and the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                            },
                          },
                          {
                            nodeType: "text",
                            value: ".",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "unordered-list",
                        content: [
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "Sugar sweetened drinks",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "Sweet & savoury foods",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "Fried foods & mixed foods",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "Sauces, spreads & miscellaneous",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "Processed meat",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
            ],
          },
          relatedChallenge: {
            __typename: "Challenge",
            title: "Ways to manage sugar intake",
            url: "https://www.healthylife.com.au/programs/ways-to-eat/sugar-lifes-good",
            image: {
              __typename: "Asset",
              url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/sugar-tile-desktop.png",
            },
          },
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "4 healthier food swaps to manage a sweet tooth",
                url: "https://www.healthylife.com.au/learn/how-to-manage-sweet-tooth",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/4_healthier_food_swaps_to_manage_a_sweet_tooth__1_.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "10 healthier snacks to keep in your cupboard",
                url: "https://www.healthylife.com.au/learn/healthy-snack-box",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/10_healthier_snacks_to_keep_in_your_cupboard__1_.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const GRAINS_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Grains",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "What are grains?",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/grain-cereal-foods-mostly-wholegrain-and-or-high-cereal-fibre",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Foods in this group",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " are mostly made from wheat, oats, rice, rye, barley, millet, quinoa and corn. Grain foods (also referred to as cereal foods) can be broken down into 4 main groups:",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageNumberedListSection",
                itemsCollection: {
                  __typename: "FoodGroupPageNumberedListSectionItemsCollection",
                  items: [
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Breads",
                      description:
                        "Wholemeal, wholegrain, white, rye, pita, lavash, naan, focaccia, crispbreads, damper",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Breakfast Cereals",
                      description:
                        "Ready to eat, high fibre (wholegrain) oats, porridge, muesli, wholewheat biscuits",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Grains",
                      description:
                        "Rice, barley, corn, polenta, buckwheat, spelt, millet, sorghum, triticale, rye, quinoa, semolina",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Other products",
                      description:
                        "Pasta, noodles, English muffin, crumpet, rice cakes, couscous, bulgur, popcorn, flour",
                    },
                  ],
                },
              },
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Grains",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.glnc.org.au/grains-2/grains-and-nutrition/",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " are packed with goodness, and contain carbohydrates (an essential energy source), protein, fibre and a wide range of vitamins and minerals including iron, vitamin E, zinc and magnesium. High fibre grains are also beneficial to our gut health.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "When it comes to ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "grains",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/grain-cereal-foods-mostly-wholegrain-and-or-high-cereal-fibre",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              ", it is best to choose wholegrain varieties, as these are more nutrient rich and higher in fibre. When searching for wholegrain based foods, look for ‘wholegrain’ or ‘wholemeal’ listed in the ingredients",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "It is recommended that ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "text",
                            value:
                              "most people have 4-6 serves of grains per day",
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "text",
                            value:
                              ", however your recommendation will vary according to your age, sex and life stage. ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Click here",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " to find out how many serves you need to eat per day (based on the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                          },
                          {
                            nodeType: "text",
                            value: ").",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FoodGroupPageServeInfoSection",
                content: {
                  __typename: "FoodGroupPageServeInfoSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "heading-2",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "What is a serve of grains?",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/grain-cereal-foods-mostly-wholegrain-and-or-high-cereal-fibre",
                            },
                          },
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "A standard serve is about 500 kJ, or:",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "unordered-list",
                        content: [
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "1 slice (40g) bread",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "½ medium (40g) roll or flat bread",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "½ cup (75-120g) cooked rice, pasta, noodles, barley, buckwheat, semolina, polenta, bulgur or quinoa",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "½ cup (120g) cooked porridge",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "²/³ cup (30g) wheat cereal flakes",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "¼ cup (30g) muesli",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "3 (35g) crispbreads",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "1 (60g) crumpet",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "1 small (35g) English muffin or scone",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
            ],
          },
          relatedChallenge: null,
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "What is low GI eating... and how to do it",
                url: "https://www.healthylife.com.au/learn/low-gi-eating",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/what_is_low-GI-eating.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title:
                  "How to eat more of the healthy foods that make you feel better",
                url: "https://www.healthylife.com.au/learn/foods-that-make-you-feel-better",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/How_to_eat_more_of_the_healthy_foods_that_make_you_feel_better.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "Is bloating a symptom of wheat intolerance?",
                url: "https://www.healthylife.com.au/learn/wheat-intolerance-symptoms",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/Is_bloating_a_symptom_of_wheat_intolerance_.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const FRUIT_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Fruit",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "heading-2",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value: "What are the different types of fruit?",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "We are lucky as there is a large variety of fruit available and grown in Australia. When it comes to fruit, try eating seasonal fruit, to help add variety and colours to your diet (and reduce the cost!). ",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/fruit",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "Fruit",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              " is naturally high in fibre, vitamins, minerals and phytochemicals beneficial for your health and wellbeing and as with vegetables, different colours provide different nutrients so variety is the key. It is recommended to ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "text",
                            value: "aim for 2 serves of fruit per day.",
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FoodGroupPageServeInfoSection",
                content: {
                  __typename: "FoodGroupPageServeInfoSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/fruit",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "What is a serve of fruit?",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "A standard serve is about 150 g (350 kJ), or:",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "Standard serve = 150 g",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "1 medium apple, banana, orange or pear",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "2 small apricots, kiwi fruits or plums",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "1 cup diced or canned fruit (no added sugar)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                        ],
                        nodeType: "unordered-list",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "Or only occasionally:",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "125ml (½ cup) fruit juice (no added sugar)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "30g dried fruit (for example, 4 dried apricot halves, 1½ tablespoons of sultanas)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                        ],
                        nodeType: "unordered-list",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Tip: Choose whole fruit over dried fruit or fruit juice to help look after your ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/fruit",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "dental health",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              ". It is recommended to consume dried fruit and fruit juice in moderation.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          relatedChallenge: {
            __typename: "Challenge",
            title: "Ways to manage sugar intake",
            url: "https://www.healthylife.com.au/programs/ways-to-eat/sugar-lifes-good",
            image: {
              __typename: "Asset",
              url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/sugar-tile-desktop.png",
            },
          },
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "4 foods for gut health",
                url: "https://www.healthylife.com.au/learn/4-gut-health-foods",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/4_foods_for_gut_health__1_.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "Dietitian-approved foods and healthy snacks for kids",
                url: "https://www.healthylife.com.au/learn/healthy-snacks-for-kids",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/Dietitian-approved_foods_and_healthy_snacks_for_kids-x2.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title:
                  "How to eat more of the healthy foods that make you feel better",
                url: "https://www.healthylife.com.au/learn/foods-that-make-you-feel-better",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/How_to_eat_more_of_the_healthy_foods_that_make_you_feel_better.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const VEGETABLES_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Vegetables and legumes",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "heading-2",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "What are the different types of vegetables and legumes?",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Vegetables come in all shapes, colours and sizes. Vegetables are ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3649719/",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "naturally rich",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value: " in vitamins and minerals, antioxidants ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.tryfor5.org.au/the-power-of-veg",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "and fibre",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value: " and different ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9268388/",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "coloured",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              " vegetables will provide you with a range of different nutrients. Eating five or more servings of vegetables each day may ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.betterhealth.vic.gov.au/health/healthyliving/fruit-and-vegetables#fruit-and-vegetables-and-protection-against-diseases",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "help",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              " with maintaining a healthy weight, and help reduce the risk of long-term illnesses such as heart conditions and type 2 diabetes.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "When it comes to vegetables, variety matters! Introducing new vegetables and including plenty of variety with different coloured vegetables helps keep things interesting while giving your body a great mix of nutrients.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/vegetables-and-legumes-beans",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "Legumes",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              " are the seeds of a plant - they count as both veggies and protein in the diet and also contain fibre, vitamins and minerals. Examples of legumes include all forms of beans and peas, chickpeas, lentils and lupins. In healthylife Food Tracker, legumes count as a serve of both vegetables and meat & alternatives.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FoodGroupPageServeInfoSection",
                content: {
                  __typename: "FoodGroupPageServeInfoSectionContent",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "heading-2",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "What is a serve of vegetables?",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/vegetables-and-legumes-beans",
                            },
                          },
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "A standard serve is about 75 g (100 - 350 kJ), or:",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "unordered-list",
                        content: [
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "½ cup cooked green or orange vegetables (for example, broccoli, spinach, carrots or pumpkin)",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "½ cup chopped raw vegetables",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "½ cup cooked dried or canned beans, peas or lentils (preferably with no added salt)",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "1 cup green leafy or raw salad vegetables",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "½ cup sweet corn",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value:
                                      "½ medium potato or other starchy vegetables (sweet potato, taro or cassava)",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                          {
                            nodeType: "list-item",
                            content: [
                              {
                                nodeType: "paragraph",
                                content: [
                                  {
                                    nodeType: "text",
                                    value: "1 medium tomato",
                                    marks: [],
                                    data: {},
                                  },
                                ],
                                data: {},
                              },
                            ],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
            ],
          },
          relatedChallenge: {
            __typename: "Challenge",
            title: "Ways to eat more veggies",
            url: "https://www.healthylife.com.au/programs/ways-to-eat/veggies-lifes-good",
            image: {
              __typename: "Asset",
              url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/veggies-tile-desktop.png",
            },
          },
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "How veggies (actually) benefit your health",
                url: "https://www.healthylife.com.au/learn/the-health-benefits-of-eating-vegetables",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/How_veggies__actually__benefit_your_health.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title:
                  "Eating veggies for the seasons (plus 4 budget saver tips)",
                url: "https://www.healthylife.com.au/learn/eating-veggies-for-the-seasons",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/Eating_veggies_for_the_seasons.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "Is this a vegetable?",
                url: "https://www.healthylife.com.au/learn/is-mushroom-a-vegetable",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/is-this-a-vegetable.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const DAIRY_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Dairy and/or alternatives",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "What are dairy and alternatives?",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "This ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/milk-yoghurt-cheese-andor-their-alternatives-mostly-reduced-fat",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "food group",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " includes milk, yoghurt, cheese and their alternatives. Alternatives include products such as calcium fortified plant based products (e.g. soy based, almond based or rice based).",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            value: "Please note:",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " Some dairy alternatives may not be fortified with calcium - these will not count towards your serves of dairy and alternative products. Where possible, choose products fortified with at least 100mg calcium/100mL. Butter and cream are not included in this group they are considered to be sometimes (discretionary) foods.",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "Milk can be fresh, dried, evaporated, or UHT (long life).",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "Cheese includes all hard cheese products (either reduced fat or full fat options).",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "Yoghurt includes reduced or full fat, plain and flavoured and soy and other alternative yoghurts (calcium fortified).",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.healthdirect.gov.au/dairy-foods",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Dairy",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " is an important source of calcium and also provides protein, vitamins and minerals such as vitamin A, D, B12 and zinc.",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageServeInfoSection",
                content: {
                  __typename: "FoodGroupPageServeInfoSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/milk-yoghurt-cheese-andor-their-alternatives-mostly-reduced-fat",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value:
                                  "What is a serve of dairy and alternatives?",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "A standard serve is about 500-600 kJ, or:",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "1 cup (250ml) fresh, UHT long life, reconstituted powdered milk or buttermilk",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "½ cup (120ml) evaporated milk",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "2 slices (40g) or 4 x 3 x 2cm cube (40g) of hard cheese, such as cheddar",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "½ cup (120g) ricotta cheese",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "¾ cup (200g) yoghurt",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "1 cup (250ml) soy, rice or other cereal drink with at least 100mg of added calcium per 100ml",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                        ],
                        nodeType: "unordered-list",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "Note: ",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            value:
                              "Most people need at least 2-3 serves from this group each day",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              ", however, the minimum recommended amount will vary according to your age, sex and life stage. ",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            value:
                              "e.g. women who are 51 years and older need 4 serves a day",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [],
                            value: " as their calcium requirements are high. ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/milk-yoghurt-cheese-andor-their-alternatives-mostly-reduced-fat",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Click here",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " to find out how many serves you need to eat per day (based on the ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Australian Dietary Guidelines",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: ").",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
            ],
          },
          relatedChallenge: null,
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "4 foods for gut health",
                url: "https://www.healthylife.com.au/learn/4-gut-health-foods",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/4_foods_for_gut_health__1_.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "What foods are best for fussy toddlers?",
                url: "https://www.healthylife.com.au/learn/food-for-fussy-toddlers",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/What_foods_are_best_for_fussy_toddlers_.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title: "What’s new in healthy eating? Clue... a lot",
                url: "https://www.healthylife.com.au/learn/food-pyramid-australia",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/whats-new-in-healthy-eating.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const PROTEIN_FOOD_GROUP_PAGE = {
  data: {
    foodGroupPageCollection: {
      __typename: "FoodGroupPageCollection",
      items: [
        {
          __typename: "FoodGroupPage",
          title: "Meat, tofu, legumes, nuts & alternatives",
          categoriesCollection: {
            __typename: "FoodGroupPageCategoriesCollection",
            items: [],
          },
          sectionsCollection: {
            __typename: "FoodGroupPageSectionsCollection",
            items: [
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "What is included in this group?",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "This group includes a variety of products - lean meat & poultry, fish, eggs, tofu, nuts & seeds and legumes/beans.",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "Foods in this group are considered to be rich in protein. An adequate amount of ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.betterhealth.vic.gov.au/health/healthyliving/protein",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "protein",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " is needed to help the body’s cells grow and repair and to make hormones and enzymes.",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "These products also ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/lean-meat-and-poultry-fish-eggs-tofu-nuts-and-seeds-and",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "provide",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " a variety of other nutrients such as iron, zinc, vitamins and essential fatty acids.",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "These foods can be broken down into six ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/lean-meat-and-poultry-fish-eggs-tofu-nuts-and-seeds-and",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "main categories",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: ":",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageNumberedListSection",
                itemsCollection: {
                  __typename: "FoodGroupPageNumberedListSectionItemsCollection",
                  items: [
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Lean meats",
                      description:
                        "Beef, lamb, veal, pork, kangaroo, lean (lower salt) sausages",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Poultry",
                      description:
                        "Chicken, turkey, duck, emu, goose, bush birds",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Fish and seafood",
                      description:
                        " Fish and seafood Fish, prawns, crab, lobster, mussels, oysters, scallops, clams",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Legumes/beans & other",
                      description:
                        "All beans, lentils, chickpeas, split peas, tofu, textured vegetable protein (TVP).",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Nuts and seeds",
                      description:
                        "Almonds, pine nuts, walnut, macadamia, hazelnut, cashew, peanut, nut spreads, pumpkin seeds, sesame seeds, sunflower seeds, brazil nuts",
                    },
                    {
                      __typename: "FoodGroupPageListItem",
                      title: "Eggs",
                      description: "Chicken eggs, duck eggs.",
                    },
                  ],
                },
              },
              {
                __typename: "FoodGroupPageServeInfoSection",
                content: {
                  __typename: "FoodGroupPageServeInfoSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups/lean-meat-and-poultry-fish-eggs-tofu-nuts-and-seeds-and",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "What is a serve?",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-2",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "A standard serve is about 500-600 kJ, or:",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "65g cooked lean red meats such as beef, lamb, veal, pork, goat or kangaroo (about 90-100g raw)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "80g cooked lean poultry such as chicken or turkey (100g raw)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "100g cooked fish fillet (about 115g raw) or one small can of fish",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "2 large (120g) eggs",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "1 cup (150g) cooked or canned legumes/beans such as lentils, chick peas or split peas (preferably with no added salt)",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value: "170g tofu",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                content: [
                                  {
                                    data: {},
                                    marks: [],
                                    value:
                                      "30g nuts, seeds, peanut or almond butter or tahini or other nut or seed paste (no added salt)*",
                                    nodeType: "text",
                                  },
                                ],
                                nodeType: "paragraph",
                              },
                            ],
                            nodeType: "list-item",
                          },
                        ],
                        nodeType: "unordered-list",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "*Keep in mind that while nuts and seeds contain many beneficial nutrients, they have less protein, iron and zinc than most meat and alternatives (note: this amount gives approximately the same amount of energy as the other foods in this group but will provide less protein, iron or zinc).",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "heading-6",
                      },
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FoodGroupPageTextSection",
                content: {
                  __typename: "FoodGroupPageTextSectionContent",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value: "It is recommended that ",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [
                              {
                                type: "bold",
                              },
                            ],
                            value: "most people have 2-3 serves per day",
                            nodeType: "text",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              ", however your recommendation will vary according to your age, sex and life stage. ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Click here",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value:
                              " to find out how many serves you need to eat per day (based on the ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Australian Dietary Guidelines",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: ").",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
            ],
          },
          relatedChallenge: null,
          relatedArticlesCollection: {
            __typename: "FoodGroupPageRelatedArticlesCollection",
            items: [
              {
                __typename: "RelatedArticle",
                title: "Protein, what is it good for?  Lots of things!",
                url: "https://www.healthylife.com.au/learn/best-protein-for-women",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/Protein__what_is_it_good_for__Lots_of_things_.jpg",
                },
              },
              {
                __typename: "RelatedArticle",
                title:
                  "How to eat more of the healthy foods that make you feel better",
                url: "https://www.healthylife.com.au/learn/foods-that-make-you-feel-better",
                image: {
                  __typename: "Asset",
                  url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/How_to_eat_more_of_the_healthy_foods_that_make_you_feel_better__1_.jpg",
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const GENDER_OPTIONS = {
  data: {
    genderCollection: {
      __typename: "GenderCollection",
      items: [
        {
          __typename: "Gender",
          sys: {
            __typename: "Sys",
            id: "569CWroiO9DqIprLXColoI",
          },
          name: "Female",
          applicableAgeGroupsCollection: {
            __typename: "GenderApplicableAgeGroupsCollection",
            items: [
              {
                __typename: "AgeGroup",
                sys: {
                  __typename: "Sys",
                  id: "2TaFrF7fkW9ZuOyUp39lg7",
                },
                name: "Adult",
                enabled: true,
              },
              {
                __typename: "AgeGroup",
                sys: {
                  __typename: "Sys",
                  id: "7hMAPjiRRdWc7YQSKfO953",
                },
                name: "Child",
                enabled: true,
              },
            ],
          },
          enabled: true,
        },
        {
          __typename: "Gender",
          sys: {
            __typename: "Sys",
            id: "4PelSuXzzitoqcx8Lwt5D",
          },
          name: "Male",
          applicableAgeGroupsCollection: {
            __typename: "GenderApplicableAgeGroupsCollection",
            items: [
              {
                __typename: "AgeGroup",
                sys: {
                  __typename: "Sys",
                  id: "2TaFrF7fkW9ZuOyUp39lg7",
                },
                name: "Adult",
                enabled: true,
              },
              {
                __typename: "AgeGroup",
                sys: {
                  __typename: "Sys",
                  id: "7hMAPjiRRdWc7YQSKfO953",
                },
                name: "Child",
                enabled: true,
              },
            ],
          },
          enabled: true,
        },
        {
          __typename: "Gender",
          sys: {
            __typename: "Sys",
            id: "2IrBfsgHEvUO760TUcNX2l",
          },
          name: "Non binary",
          applicableAgeGroupsCollection: {
            __typename: "GenderApplicableAgeGroupsCollection",
            items: [
              {
                __typename: "AgeGroup",
                sys: {
                  __typename: "Sys",
                  id: "2TaFrF7fkW9ZuOyUp39lg7",
                },
                name: "Adult",
                enabled: true,
              },
            ],
          },
          enabled: false,
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const AGE_GROUP_OPTIONS = [
  {
    id: "2TaFrF7fkW9ZuOyUp39lg7",
    name: "Adult",
    enabled: true,
  },
  {
    id: "7hMAPjiRRdWc7YQSKfO953",
    name: "Child",
    enabled: true,
  },
];

export const MAIN_PAGE_CONTENT = {
  data: {
    mainPageCollection: {
      __typename: "MainPageCollection",
      items: [
        {
          __typename: "MainPage",
          barContent: {
            __typename: "MainPageBarContent",
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        "This bar chart shows how many serves you still have to go over the next seven days (from your last shop) across the five ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "main food groups",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value:
                        " to reach recommended targets. These are based on the ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.eatforhealth.gov.au/",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "Australian Dietary Guidelines",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value: " and the ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "Australian Guide to Healthy Eating",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value: " for the total household you entered.",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "paragraph",
                },
              ],
              nodeType: "document",
            },
          },
          dialContent: {
            __typename: "MainPageDialContent",
            json: {
              nodeType: "document",
              data: {},
              content: [
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "Your plate shows a % balance of your shopping broken down by food group and compares that to the recommended servings for the total household you entered (based on the ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: "hyperlink",
                      data: {
                        uri: "https://www.eatforhealth.gov.au/",
                      },
                      content: [
                        {
                          nodeType: "text",
                          value: "Australian Dietary Guidelines",
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: "text",
                      value: ").",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          discretionaryContent: {
            __typename: "MainPageDiscretionaryContent",
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: "This graph shows your balance between the five ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "main food groups",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value:
                        " and sometimes (discretionary) foods and drinks. Sometimes foods and drinks have been determined based on the ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/4363.0.55.001Chapter65062011-13",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "Australian Health Survey",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value: ": Users' Guide, 2011-13 — ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            "Discretionary Food List and the Australian Dietary Guidelines.",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value: "",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "paragraph",
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        "Balancing your intake of the 5 food groups and sometimes (discretionary) food can be tricky. Based on the most recent ",
                      nodeType: "text",
                    },
                    {
                      data: {
                        uri: "https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/4363.0.55.001Chapter65062011-13",
                      },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: "Australian Health Survey (2013)",
                          nodeType: "text",
                        },
                      ],
                      nodeType: "hyperlink",
                    },
                    {
                      data: {},
                      marks: [],
                      value:
                        ", Australians have a high intake of sometimes foods. Making small swaps to include more nutritious foods from the 5 food groups can help you find your healthy balance.",
                      nodeType: "text",
                    },
                  ],
                  nodeType: "paragraph",
                },
              ],
              nodeType: "document",
            },
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const AGE_RANGE_OPTIONS = {
  data: {
    ageRangeCollection: {
      __typename: "AgeRangeCollection",
      items: [
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "1n8p9CMz4q7TLoKl6iYSqs",
          },
          name: "2 - 3",
          from: 2,
          to: 3,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "3AoNLXw7sphsoOpBeCvJWO",
          },
          name: "4 - 18",
          from: 4,
          to: 18,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: false,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "6jHIUKlQOnDvyaxHHfysin",
          },
          name: "4 - 8",
          from: 4,
          to: 8,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "6H1FAqHi8C27fhLqGKmOBU",
          },
          name: "9 - 11",
          from: 9,
          to: 11,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "3sjJGWo2o3JLz1FPAnwhn3",
          },
          name: "12 - 13",
          from: 12,
          to: 13,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "7eKao72hxnPTQgXPhpIZCj",
          },
          name: "14 - 18",
          from: 14,
          to: 18,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "7hMAPjiRRdWc7YQSKfO953",
            },
            name: "Child",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "296H4PgHvBO2ERycif8B95",
          },
          name: "19 - 120",
          from: 19,
          to: 120,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "2TaFrF7fkW9ZuOyUp39lg7",
            },
            name: "Adult",
            enabled: true,
          },
          enabled: false,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "2HOvYZL6AdNjABp2Oeq3Kd",
          },
          name: "19 - 50",
          from: 19,
          to: 50,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "2TaFrF7fkW9ZuOyUp39lg7",
            },
            name: "Adult",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "5pMMlcBGh841woeO4ONo4m",
          },
          name: "51 - 70",
          from: 51,
          to: 70,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "2TaFrF7fkW9ZuOyUp39lg7",
            },
            name: "Adult",
            enabled: true,
          },
          enabled: true,
        },
        {
          __typename: "AgeRange",
          sys: {
            __typename: "Sys",
            id: "4B4fMF7u9qqcvEhQkRXxvR",
          },
          name: "70+",
          from: 70,
          to: null,
          group: {
            __typename: "AgeGroup",
            sys: {
              __typename: "Sys",
              id: "2TaFrF7fkW9ZuOyUp39lg7",
            },
            name: "Adult",
            enabled: true,
          },
          enabled: true,
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const HEALTH_CONDITIONS_OPTIONS = {
  data: {
    healthConditionCollection: {
      __typename: "HealthConditionCollection",
      items: [
        {
          __typename: "HealthCondition",
          sys: {
            __typename: "Sys",
            id: "8R0KdvBdV5glSMV89epl8",
          },
          name: "Breastfeeding",
          applicableAgeRangesCollection: {
            __typename: "HealthConditionApplicableAgeRangesCollection",
            items: [
              {
                __typename: "AgeRange",
                sys: {
                  __typename: "Sys",
                  id: "2HOvYZL6AdNjABp2Oeq3Kd",
                },
                name: "19 - 50",
                from: 19,
                to: 50,
                group: {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                  enabled: true,
                },
                enabled: true,
              },
              {
                __typename: "AgeRange",
                sys: {
                  __typename: "Sys",
                  id: "296H4PgHvBO2ERycif8B95",
                },
                name: "19 - 120",
                from: 19,
                to: 120,
                group: {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                  enabled: true,
                },
                enabled: false,
              },
            ],
          },
          applicableGendersCollection: {
            __typename: "HealthConditionApplicableGendersCollection",
            items: [
              {
                __typename: "Gender",
                sys: {
                  __typename: "Sys",
                  id: "569CWroiO9DqIprLXColoI",
                },
                name: "Female",
                applicableAgeGroupsCollection: {
                  __typename: "GenderApplicableAgeGroupsCollection",
                  items: [
                    {
                      __typename: "AgeGroup",
                      sys: {
                        __typename: "Sys",
                        id: "2TaFrF7fkW9ZuOyUp39lg7",
                      },
                      name: "Adult",
                      enabled: true,
                    },
                    {
                      __typename: "AgeGroup",
                      sys: {
                        __typename: "Sys",
                        id: "7hMAPjiRRdWc7YQSKfO953",
                      },
                      name: "Child",
                      enabled: true,
                    },
                  ],
                },
                enabled: true,
              },
            ],
          },
          consentCopy: {
            __typename: "HealthConditionConsentCopy",
            json: {
              nodeType: "document",
              data: {},
              content: [
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "I consent to healthylife collecting my breastfeeding and pregnancy status for the purpose of providing the healthylife Food Tracker",
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "Recommended servings across the main food groups increase for those who are pregnant or breastfeeding.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          enabled: true,
        },
        {
          __typename: "HealthCondition",
          sys: {
            __typename: "Sys",
            id: "1zlmXbYsanLFn6c8DDM3IZ",
          },
          name: "Pregnant",
          applicableAgeRangesCollection: {
            __typename: "HealthConditionApplicableAgeRangesCollection",
            items: [
              {
                __typename: "AgeRange",
                sys: {
                  __typename: "Sys",
                  id: "2HOvYZL6AdNjABp2Oeq3Kd",
                },
                name: "19 - 50",
                from: 19,
                to: 50,
                group: {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                  enabled: true,
                },
                enabled: true,
              },
              {
                __typename: "AgeRange",
                sys: {
                  __typename: "Sys",
                  id: "296H4PgHvBO2ERycif8B95",
                },
                name: "19 - 120",
                from: 19,
                to: 120,
                group: {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                  enabled: true,
                },
                enabled: false,
              },
            ],
          },
          applicableGendersCollection: {
            __typename: "HealthConditionApplicableGendersCollection",
            items: [
              {
                __typename: "Gender",
                sys: {
                  __typename: "Sys",
                  id: "569CWroiO9DqIprLXColoI",
                },
                name: "Female",
                applicableAgeGroupsCollection: {
                  __typename: "GenderApplicableAgeGroupsCollection",
                  items: [
                    {
                      __typename: "AgeGroup",
                      sys: {
                        __typename: "Sys",
                        id: "2TaFrF7fkW9ZuOyUp39lg7",
                      },
                      name: "Adult",
                      enabled: true,
                    },
                    {
                      __typename: "AgeGroup",
                      sys: {
                        __typename: "Sys",
                        id: "7hMAPjiRRdWc7YQSKfO953",
                      },
                      name: "Child",
                      enabled: true,
                    },
                  ],
                },
                enabled: true,
              },
            ],
          },
          consentCopy: {
            __typename: "HealthConditionConsentCopy",
            json: {
              nodeType: "document",
              data: {},
              content: [
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "I consent to healthylife collecting my breastfeeding and pregnancy status for the purpose of providing the healthylife Food Tracker",
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "Recommended servings across the main food groups increase for those who are pregnant or breastfeeding.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: "paragraph",
                  data: {},
                  content: [
                    {
                      nodeType: "text",
                      value: "",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          enabled: true,
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const FAQS = {
  data: {
    faqPageCollection: {
      __typename: "FaqPageCollection",
      items: [
        {
          __typename: "FaqPage",
          questionsCollection: {
            __typename: "FaqPageQuestionsCollection",
            items: [
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What is healthlylife Food Tracker?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "healthlylife Food Tracker is a tool to help you review your food shopping against the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value: " recommendations ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "text",
                            value:
                              "and then inspire you to make healthier food choices and achieve a more balanced diet.\n\n",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What is healthlylife Food Tracker based on?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "The information in healthlylife Food Tracker is aligned to the principles and recommendations in the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                          },
                          {
                            nodeType: "text",
                            value: ".",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "How do you know my shopping history?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This information is based on your food shopping history from the Everyday Rewards program. ",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What about the shopping I purchased from another store - is this counted?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              'If you purchase items from other retailers, you can account for this in your settings under "your Woolworths shopping allocation."\n\n',
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What about the shopping I purchased from another store (e.g. butcher and green grocer) - is this counted?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "If you purchase items from other retailers, this is not included in the calculation. Therefore, healthylife Food Tracker may not provide a complete picture of your total food shopping.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Is every product in Woolworths supermarket covered by healthlylife Food Tracker?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "We’ve been working hard to analyse around 97% of the most common products available in Woolworths. In some cases, the specific item you purchased has not been individually analysed yet due to the transient nature of daily stock control. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Are legumes treated as vegetables or proteins in the calculations?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Legumes have been programmed to count towards vegetables & legumes and meat & alternatives serves (they are double counted).",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How are dried herbs and species treated in the calculations?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Aligned with the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "AUSNUT",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.foodstandards.gov.au/science/monitoringnutrients/ausnut/Pages/default.aspx",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " database, dried herbs and spices are not allocated to any food group categories. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "How have serves been assigned to foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Food items have been assigned to one of the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "five main food groups",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              " – fruit, vegetables, grains, meat and alternatives, dairy and alternatives. If a food item was made up of multiple ingredients, then the food was distributed across the appropriate food groups in the proportion of the ingredients within it.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "\nUnder purchased items you can view which products contribute to your recommended serves by food group. In some cases, a food item may contain a very small serve of a particular food group but is excluded from the purchased item breakdown based on a pre-determined threshold. However this contribution is not significant.\n",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How has the volume of products been considered in the calculations?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Liquids have different densities, and this can vary at different temperatures. For pure water, 1L of water is equal to 1kg. For ease of calculation, it was assumed that for all liquids 1L was equal to 1kg.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Who helped create the data to sit behind healthlylife Food Tracker?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "CSIRO nutrition researchers prepared the food database used by Woolworths in healthlylife Food Tracker. The database calculates the amount of each food groups in food products. The database was prepared using an approach that was consistent with published data files available from the Australian Bureau of Statistics and Food Standard Australia and New Zealand and used in the 2011-13 Australian National Nutrition Survey.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What happens if I buy food for a special occasion or for someone else?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              'You can choose to exclude this from your healthylife Food Tracker. To do this, click on the food group it belongs in and tick the "exclude" box next to it\n',
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Who are the experts who have developed healthlylife Food Tracker?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "healthlylife Food Tracker has been developed by the experts at healthylife (dietitians, nutritionists), in collaboration with CSIRO, and has been expert reviewed by Nutrition Australia. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How is healthlylife Food Tracker designed for use? What will it do for me and my family and our nutrition habits?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    data: {},
                    content: [
                      {
                        data: {},
                        content: [
                          {
                            data: {},
                            marks: [],
                            value:
                              "healthlylife Food Tracker is designed to help you view your shopping habits over time, and see where you could make some simple changes to balance your plate against the ",
                            nodeType: "text",
                          },
                          {
                            data: {
                              uri: "https://www.eatforhealth.gov.au/guidelines",
                            },
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: "Australian Dietary Guidelines",
                                nodeType: "text",
                              },
                            ],
                            nodeType: "hyperlink",
                          },
                          {
                            data: {},
                            marks: [],
                            value: ".",
                            nodeType: "text",
                          },
                        ],
                        nodeType: "paragraph",
                      },
                    ],
                    nodeType: "document",
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "In the Bar Chart, what period does the 7 days refer to? ",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "The seven day period refers to the amount of serves you (and anyone else in your household) have left for the next seven days across the five main food groups since your last shop (based on recommended serves in the Australian Dietary Guidelines for the total household you entered).",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "In the plate chart - what does it mean when you choose week, fortnight or month?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "The option to change from week, fortnight and month view, shows your shopping trends over either the past 7 days, 14 days, or the previous month. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What happens if my shopping habits are irregular?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "We understand shopping habits may change. That’s why the healthylife Food Tracker gives you the option of toggling between week, fortnight and month, to give you an overall picture.\n\n",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What does the main plate graph show?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Your plate chart shows a % balance of your shopping broken down by food group and compares that to the recommended serves for  the total household you entered (based on the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value:
                              ").  Choose to review your balance over the past week, fortnight or month, based on your shopping history.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What does the sometimes food graph show?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "This graph shows your balance between the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "five main food groups",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups",
                            },
                          },
                          {
                            nodeType: "text",
                            value: " and sometimes (",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "discretionary",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              ") foods.  Sometimes foods have been determined based on the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Health Survey: Users Guide",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/guidelines",
                            },
                          },
                          {
                            nodeType: "text",
                            value: ", 2011-13 - Discretionary Food List. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What is a core food or drink?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "A core food or drink sits with one of the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "five main food groups",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/five-food-groups",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " - Vegetables & legumes, fruit, meat & alternatives, dairy & alternatives and grains.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What is a non-core food or drink?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "A non-core food or drink is referred to in healthylife Food Tracker as a 'sometimes food or drink' (",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "discretionary food",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              "). These foods and drinks are recommended to have only sometimes and in small amounts. These foods are high in additional energy (kilojoules) and low in nutrients the body needs. Sometimes foods typically contain high levels of saturated fats, sugar, salt and/or alcohol. Examples include cakes, biscuits, confectionery, chocolate, cream, potato chips and sugar sweetened beverages. ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What foods and drinks are included in the sugar sweetened beverages group of sometimes foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This group contains drinks that have added sugar, such as soft drinks, sports drinks, sweetened waters and cordial.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What foods and drinks are included in the sweet & savoury foods group of sometimes foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This group contains sweet foods like lollies, cakes, biscuits, muesli bars and ice cream, and savoury foods like savoury biscuits, pastries and crisps.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What foods and drinks are included in the fried foods & mixed foods group of sometimes foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This group contains convenience foods such as ready made pizza, garlic bread, spring rolls and other party foods.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What foods and drinks are included in the sauces, spreads & miscellaneous group of sometimes foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This group contains things like sweet spreads, syrups, butter, mayonnaise, sugar, salt and gravy.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "What foods and drinks are included in the processed meat group of sometimes foods?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "This group contains foods like salami, bacon, burgers, sausages and other processed meats.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What are the Australian Dietary Guidelines?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "The ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/guidelines",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " provide current advice about the amount and kinds of foods that we should eat for health and wellbeing. The recommendations are based on scientific evidence, developed after looking at good quality research.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "What is the Australian Guide to Healthy Eating?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "The ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Guide to Healthy Eating",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              " is a food selection guide which visually represents the proportion of the five main food groups recommended for consumption each day.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "Are all my grocery items included in this analysis?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "healthylife food tracker analyses the foods and drinks in your shopping basket from Woolworths, using your Everyday Rewards card information. Around 97% of the most common food and drinks that are stocked at Woolworths Supermarkets, Online and Metro are analysed by the tracker.\n\n",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How can healthlylife Food Tracker guide my health and nutrition? Does it provide individual dietary/ health advice?\t",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "healthlylife Food Tracker is a useful tool to help you track the balance of your shop, when compared with the recommendations in the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/guidelines",
                            },
                          },
                          {
                            nodeType: "text",
                            value:
                              ".  However it does not provide individual dietary or health advice. For individual dietary advice, please consult an Accredited Practising Dietitian - ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Dietitians Australia",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://dietitiansaustralia.org.au/find-an-apd/",
                            },
                          },
                          {
                            nodeType: "text",
                            value: " or your medical practitioner.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "I am confused by the bar chart - what is it showing?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "The bar chart is designed to show you how many serves you have remaining (for your household entered) for the next 7 days, since your last shop. Your last shop is shown by the shopping trolley icon.  This chart assumes that your family each consume the recommended serves from each food group, as per the ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            content: [
                              {
                                nodeType: "text",
                                value: "Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day",
                            },
                          },
                          {
                            nodeType: "text",
                            value: ". ",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Does it cost anything to use healthylife Food Tracker?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "There is no cost, simply sign up to healthylife and link your Everyday Rewards account.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How is healthylife Food Tracker different to the apps that track food intake?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Most food intake apps require you to manually enter your food consumption. healthylife Food Tracker reviews and counts your Woolworths grocery shopping against the",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: " Australian Dietary Guidelines",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value: " to make it easy for you.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "Is my personal information secure and will it be shared?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "We value your trust and take the protection of your personal information very seriously. For more information, you can review our ",
                            marks: [],
                            data: {},
                          },
                          {
                            nodeType: "hyperlink",
                            data: {
                              uri: "https://www.healthylife.com.au/privacy-policy",
                            },
                            content: [
                              {
                                nodeType: "text",
                                value: "Privacy Policy here",
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: "text",
                            value: ".",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question: "How does the meat section work for vegetarians?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Meat, tofu, legumes, nuts & alternatives are counted in the “Meats and alternatives group.” For those who do not eat animal foods, and instead look to foods such as nuts, seeds and legumes for key nutrients, will have their foods counted here.",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                __typename: "FrequentlyAskedQuestions",
                question:
                  "How long does the healthylife Food Tracker take to update?",
                answer: {
                  __typename: "FrequentlyAskedQuestionsAnswer",
                  json: {
                    nodeType: "document",
                    data: {},
                    content: [
                      {
                        nodeType: "paragraph",
                        data: {},
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Once you’ve done your shopping, you can expect your tracker to update by the next way. Please note: if you are shopping online, the date shown under your items will be the delivery date (not purchase date).",
                            marks: [],
                            data: {},
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const BENCHMARKS = {
  data: {
    benchmarkCollection: {
      __typename: "BenchmarkCollection",
      items: [
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "70PhV9ljzP1dQC5cEsalLk",
          },
          title: "2-3 Child Male ",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "1n8p9CMz4q7TLoKl6iYSqs",
            },
            name: "2 - 3",
            from: 2,
            to: 3,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 2.5,
          dailyServingOfProtein: 1,
          dailyServingOfFruit: 1,
          dailyServingOfDairy: 1.5,
          dailyServingOfSometimesFoods: 1,
          enabled: true,
          saltTarget: 400,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "790uCnwH8IitPyOhe4rJWs",
          },
          title: "2-3 Child Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "1n8p9CMz4q7TLoKl6iYSqs",
            },
            name: "2 - 3",
            from: 2,
            to: 3,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 2.5,
          dailyServingOfProtein: 1,
          dailyServingOfFruit: 1,
          dailyServingOfDairy: 1.5,
          dailyServingOfSometimesFoods: 1,
          enabled: true,
          saltTarget: 400,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "4WVMUhbOgiuOBaKKsaxWT2",
          },
          title: "19-50 Adult Female ",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "2HOvYZL6AdNjABp2Oeq3Kd",
            },
            name: "19 - 50",
            from: 19,
            to: 50,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 6,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "7fMLB1AH4NBQi1iouUMxzV",
          },
          title: "19-50 Adult Male ",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "2HOvYZL6AdNjABp2Oeq3Kd",
            },
            name: "19 - 50",
            from: 19,
            to: 50,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 6,
          dailyServingOfVegetables: 6,
          dailyServingOfProtein: 3,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 3,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "5T3QbldGgtP5ooxFIxsKCm",
          },
          title: "51-70 Adult Female ",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "5pMMlcBGh841woeO4ONo4m",
            },
            name: "51 - 70",
            from: 51,
            to: 70,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 4,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "QQhSjh8fLXfvskhijNMDG",
          },
          title: "51-70 Adult Male ",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "5pMMlcBGh841woeO4ONo4m",
            },
            name: "51 - 70",
            from: 51,
            to: 70,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 6,
          dailyServingOfVegetables: 5.5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "67xHxpaCJZJ1xX8CqZi12N",
          },
          title: "4-8 Child Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "6jHIUKlQOnDvyaxHHfysin",
            },
            name: "4 - 8",
            from: 4,
            to: 8,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 4.5,
          dailyServingOfProtein: 1.5,
          dailyServingOfFruit: 1.5,
          dailyServingOfDairy: 1.5,
          dailyServingOfSometimesFoods: 1,
          enabled: true,
          saltTarget: 600,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "3KlVmWlyv3DiQKGvLxe4Ol",
          },
          title: "70+ Adult Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "4B4fMF7u9qqcvEhQkRXxvR",
            },
            name: "70+",
            from: 70,
            to: null,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 3,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 4,
          dailyServingOfSometimesFoods: 2,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "9GIbcF6xRRaaA211jgS5R",
          },
          title: "70+ Adult Male",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "4B4fMF7u9qqcvEhQkRXxvR",
            },
            name: "70+",
            from: 70,
            to: null,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4.5,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "559yoptA2dIwuwrpq9y2L1",
          },
          title: "12-13 Child Male",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "3sjJGWo2o3JLz1FPAnwhn3",
            },
            name: "12 - 13",
            from: 12,
            to: 13,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 6,
          dailyServingOfVegetables: 5.5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3.5,
          dailyServingOfSometimesFoods: 3,
          enabled: true,
          saltTarget: 800,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "5GgUdWi0KNh8DfLbC8rj8n",
          },
          title: "4-8 Child Male",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "6jHIUKlQOnDvyaxHHfysin",
            },
            name: "4 - 8",
            from: 4,
            to: 8,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 4.5,
          dailyServingOfProtein: 1.5,
          dailyServingOfFruit: 1.5,
          dailyServingOfDairy: 2,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 600,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "TlAkGKfClsSEna1RfxgRJ",
          },
          title: "9-11 Child Male",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "6H1FAqHi8C27fhLqGKmOBU",
            },
            name: "9 - 11",
            from: 9,
            to: 11,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 5,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 3,
          enabled: true,
          saltTarget: 800,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "5ZO5DJPeeP0ZBT3kuDw4eY",
          },
          title: "9-11 Child Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "6H1FAqHi8C27fhLqGKmOBU",
            },
            name: "9 - 11",
            from: 9,
            to: 11,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 4,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3,
          dailyServingOfSometimesFoods: 3,
          enabled: true,
          saltTarget: 800,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "KFJ5CPxyf1XRFdo6JsMQH",
          },
          title: "14-18 Child Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "7eKao72hxnPTQgXPhpIZCj",
            },
            name: "14 - 18",
            from: 14,
            to: 18,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 7,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 920,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "sT0raUXRlF6NGYOSuwdlq",
          },
          title: "14-18 Child Male",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "7eKao72hxnPTQgXPhpIZCj",
            },
            name: "14 - 18",
            from: 14,
            to: 18,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "4PelSuXzzitoqcx8Lwt5D",
            },
            name: "Male",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 7,
          dailyServingOfVegetables: 5.5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3.5,
          dailyServingOfSometimesFoods: 5,
          enabled: true,
          saltTarget: 920,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "ZflFpgaNFE9sPMYVw9YaC",
          },
          title: "12-13 Child Female",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "3sjJGWo2o3JLz1FPAnwhn3",
            },
            name: "12 - 13",
            from: 12,
            to: 13,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "7hMAPjiRRdWc7YQSKfO953",
              },
              name: "Child",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: null,
          dailyServingOfGrains: 5,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 3.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 800,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "6jyp4qml3hJapPhDCEW3Ip",
          },
          title: "19-50 Adult Female Pregnant",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "2HOvYZL6AdNjABp2Oeq3Kd",
            },
            name: "19 - 50",
            from: 19,
            to: 50,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: {
            __typename: "HealthCondition",
            sys: {
              __typename: "Sys",
              id: "1zlmXbYsanLFn6c8DDM3IZ",
            },
            name: "Pregnant",
            applicableAgeRangesCollection: {
              __typename: "HealthConditionApplicableAgeRangesCollection",
              items: [
                {
                  __typename: "AgeRange",
                  sys: {
                    __typename: "Sys",
                    id: "2HOvYZL6AdNjABp2Oeq3Kd",
                  },
                  name: "19 - 50",
                  group: {
                    __typename: "AgeGroup",
                    sys: {
                      __typename: "Sys",
                      id: "2TaFrF7fkW9ZuOyUp39lg7",
                    },
                    name: "Adult",
                    enabled: true,
                  },
                  enabled: true,
                },
                {
                  __typename: "AgeRange",
                  sys: {
                    __typename: "Sys",
                    id: "296H4PgHvBO2ERycif8B95",
                  },
                  name: "19 - 120",
                  group: {
                    __typename: "AgeGroup",
                    sys: {
                      __typename: "Sys",
                      id: "2TaFrF7fkW9ZuOyUp39lg7",
                    },
                    name: "Adult",
                    enabled: true,
                  },
                  enabled: false,
                },
              ],
            },
            applicableGendersCollection: {
              __typename: "HealthConditionApplicableGendersCollection",
              items: [
                {
                  __typename: "Gender",
                  sys: {
                    __typename: "Sys",
                    id: "569CWroiO9DqIprLXColoI",
                  },
                  name: "Female",
                  applicableAgeGroupsCollection: {
                    __typename: "GenderApplicableAgeGroupsCollection",
                    items: [
                      {
                        __typename: "AgeGroup",
                        sys: {
                          __typename: "Sys",
                          id: "2TaFrF7fkW9ZuOyUp39lg7",
                        },
                      },
                      {
                        __typename: "AgeGroup",
                        sys: {
                          __typename: "Sys",
                          id: "7hMAPjiRRdWc7YQSKfO953",
                        },
                      },
                    ],
                  },
                  enabled: true,
                },
              ],
            },
          },
          dailyServingOfGrains: 8.5,
          dailyServingOfVegetables: 5,
          dailyServingOfProtein: 3.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
        {
          __typename: "Benchmark",
          sys: {
            __typename: "Sys",
            id: "5fRmHDkHLuZ91GOXb2CAQH",
          },
          title: "19-50 Adult Female Breastfeeding",
          ageRange: {
            __typename: "AgeRange",
            sys: {
              __typename: "Sys",
              id: "2HOvYZL6AdNjABp2Oeq3Kd",
            },
            name: "19 - 50",
            from: 19,
            to: 50,
            group: {
              __typename: "AgeGroup",
              sys: {
                __typename: "Sys",
                id: "2TaFrF7fkW9ZuOyUp39lg7",
              },
              name: "Adult",
            },
            enabled: true,
          },
          gender: {
            __typename: "Gender",
            sys: {
              __typename: "Sys",
              id: "569CWroiO9DqIprLXColoI",
            },
            name: "Female",
            applicableAgeGroupsCollection: {
              __typename: "GenderApplicableAgeGroupsCollection",
              items: [
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "2TaFrF7fkW9ZuOyUp39lg7",
                  },
                  name: "Adult",
                },
                {
                  __typename: "AgeGroup",
                  sys: {
                    __typename: "Sys",
                    id: "7hMAPjiRRdWc7YQSKfO953",
                  },
                  name: "Child",
                },
              ],
            },
            enabled: true,
          },
          healthCondition: {
            __typename: "HealthCondition",
            sys: {
              __typename: "Sys",
              id: "8R0KdvBdV5glSMV89epl8",
            },
            name: "Breastfeeding",
            applicableAgeRangesCollection: {
              __typename: "HealthConditionApplicableAgeRangesCollection",
              items: [
                {
                  __typename: "AgeRange",
                  sys: {
                    __typename: "Sys",
                    id: "2HOvYZL6AdNjABp2Oeq3Kd",
                  },
                  name: "19 - 50",
                  group: {
                    __typename: "AgeGroup",
                    sys: {
                      __typename: "Sys",
                      id: "2TaFrF7fkW9ZuOyUp39lg7",
                    },
                    name: "Adult",
                    enabled: true,
                  },
                  enabled: true,
                },
                {
                  __typename: "AgeRange",
                  sys: {
                    __typename: "Sys",
                    id: "296H4PgHvBO2ERycif8B95",
                  },
                  name: "19 - 120",
                  group: {
                    __typename: "AgeGroup",
                    sys: {
                      __typename: "Sys",
                      id: "2TaFrF7fkW9ZuOyUp39lg7",
                    },
                    name: "Adult",
                    enabled: true,
                  },
                  enabled: false,
                },
              ],
            },
            applicableGendersCollection: {
              __typename: "HealthConditionApplicableGendersCollection",
              items: [
                {
                  __typename: "Gender",
                  sys: {
                    __typename: "Sys",
                    id: "569CWroiO9DqIprLXColoI",
                  },
                  name: "Female",
                  applicableAgeGroupsCollection: {
                    __typename: "GenderApplicableAgeGroupsCollection",
                    items: [
                      {
                        __typename: "AgeGroup",
                        sys: {
                          __typename: "Sys",
                          id: "2TaFrF7fkW9ZuOyUp39lg7",
                        },
                      },
                      {
                        __typename: "AgeGroup",
                        sys: {
                          __typename: "Sys",
                          id: "7hMAPjiRRdWc7YQSKfO953",
                        },
                      },
                    ],
                  },
                  enabled: true,
                },
              ],
            },
          },
          dailyServingOfGrains: 9,
          dailyServingOfVegetables: 7.5,
          dailyServingOfProtein: 2.5,
          dailyServingOfFruit: 2,
          dailyServingOfDairy: 2.5,
          dailyServingOfSometimesFoods: 2.5,
          enabled: true,
          saltTarget: 2000,
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const CHALLENGES = {
  data: {
    challengeCollection: {
      __typename: "ChallengeCollection",
      items: [
        {
          __typename: "Challenge",
          title: "Ways to move",
          url: "https://www.healthylife.com.au/programs/ways-to-move/lifes-good",
          image: {
            __typename: "Asset",
            url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/move-tile-desktop.png",
          },
        },
        {
          __typename: "Challenge",
          title: "Ways to manage sugar intake",
          url: "https://www.healthylife.com.au/programs/ways-to-eat/sugar-lifes-good",
          image: {
            __typename: "Asset",
            url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/sugar-tile-desktop.png",
          },
        },
        {
          __typename: "Challenge",
          title: "Ways to eat more veggies",
          url: "https://www.healthylife.com.au/programs/ways-to-eat/veggies-lifes-good",
          image: {
            __typename: "Asset",
            url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/veggies-tile-desktop.png",
          },
        },
        {
          __typename: "Challenge",
          title: "Weight and wellbeing",
          url: "https://www.healthylife.com.au/programs/weight-wellbeing/lifes-good",
          image: {
            __typename: "Asset",
            url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/Weight-Wellbeing.png",
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const PRECEDENCE_MAP = {
  data: {
    healthConditionPrecedence: {
      __typename: "HealthConditionPrecedence",
      title: "Default",
      precedenceCollection: {
        __typename: "HealthConditionPrecedencePrecedenceCollection",
        items: [
          {
            __typename: "HealthCondition",
            sys: {
              __typename: "Sys",
              id: "8R0KdvBdV5glSMV89epl8",
            },
          },
          {
            __typename: "HealthCondition",
            sys: {
              __typename: "Sys",
              id: "1zlmXbYsanLFn6c8DDM3IZ",
            },
          },
        ],
      },
    },
  },
  loading: false,
  networkStatus: 7,
};

export const BAR_CHART_EXPLAINER = {
  data: {
    chartExplainerCollection: {
      __typename: "ChartExplainerCollection",
      items: [
        {
          __typename: "ChartExplainer",
          imagesCollection: {
            __typename: "AssetCollection",
            items: [
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/bar-chart-walkthrough-0.svg",
                description: "Click start to understand how to read the chart",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/bar-chart-walkthrough-1.svg",
                description:
                  "This icon indicates the days you shopped at Woolworths",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/bar-chart-walkthrough-2.svg",
                description: "This label indicates today's day",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/bar-chart-walkthrough-3.svg",
                description:
                  "The bar chart shows how many serves you still have to go over the next seven days",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/bar-chart-walkthrough-4.svg",
                description:
                  "It will have an arrow shape when you have more than enough serves for the week",
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const PIE_CHART_EXPLAINER = {
  data: {
    chartExplainerCollection: {
      __typename: "ChartExplainerCollection",
      items: [
        {
          __typename: "ChartExplainer",
          imagesCollection: {
            __typename: "AssetCollection",
            items: [
              {
                //@ts-ignore
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/pie-chart-walkthrough-0.svg",
                description: "Click start to understand how to read the chart",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/pie-chart-walkthrough-1.svg",
                description:
                  "The size of the slice of pie is the recommended %",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/pie-chart-walkthrough-2.svg",
                description: "Gray circle is what you're aiming for",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/pie-chart-walkthrough-3.svg",
                description:
                  "If the colour is inside the line you don't have enough",
              },
              {
                __typename: "Asset",
                url: "https://storage.googleapis.com/food-tracker-images-prod/contentful-images/pie-chart-walkthrough-4.svg",
                description: "If it's over you have more than enough",
              },
            ],
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const TERMS_AND_CONDITIONS = {
  data: {
    termsAndConditionsPageCollection: {
      __typename: "TermsAndConditionsPageCollection",
      items: [
        {
          __typename: "TermsAndConditionsPage",
          sys: {
            __typename: "Sys",
            id: "4ovjrqwUgpMIjINvgbXWxT",
            publishedAt: "2021-10-18T04:25:20.696Z",
            publishedVersion: 125,
          },
          content: {
            __typename: "TermsAndConditionsPageContent",
            json: {
              nodeType: "document",
              data: {},
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "healthylife Food Tracker Terms & Conditions",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Effective date: 18 October 2021",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "1. General ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        '1.1 HealthyLife Company Pty Limited (ABN 95 647 137 111) ("HealthyLife”, “HealthyLife Online", "us", “our” or "we"), part of Woolworths Group Limited (ABN 000 914 675) (“Woolworths”), offers a variety of content through the healthylife Food Tracker, including health, wellness and nutrition regimen-oriented content, dietary program-oriented content, options and guidance or recommendations on wellness regimens, meal planning and dietary alternatives and services through the healthylife Food Tracker(collectively, the “Content”) at www.healthylife.com.au/account/health (the “Site”). The purpose of Healthylife and the healthylife Food Tracker is to help inspire you to make healthier food choices and achieve a more balanced diet. By looking back at your recent purchases made with your Woolworths’ Everyday Rewards Card, you can assess the balance of foods that make it to your and your family\'s plate. Healthylife provides the Site and the Content for information purposes only.  Healthylife is not a health service provider and it does not intend or claim to be providing a health service nor does it claim to improve your health or wellbeing. The Site and Content provided is general information only and is based on Australian Dietary Guidelines.  It is applicable to generally healthy Australians.  The Content is not tailored to, nor does it consider or assess, your or your family\'s individual health needs or circumstances',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "1.2 Please read these healthylife Food Tracker terms & conditions before accessing or using this Site or the Content.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "1.3. Your access to and use of this Site and the Content is subject to these healthylife Food Tracker terms & conditions.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "2. Your agreement to the healthylife Food Tracker terms & conditions",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "2.1. By accessing, using or interacting with this Site or the Content, including uploading any information to this Site, you agree that you will be subject to and will comply with these healthylife Food Tracker terms & conditions.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "3. Not medical advice or health services",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "3.1  HealthyLife cares about your safety. It's important to us that you stay healthy while achieving your health and wellness goals. Please be responsible and use your best judgment and common sense. As noted above, we provide this Site and the Content for information purposes only, and can't be held liable if you suffer an injury or experience a health condition. HealthyLife is not a medical professional, and HealthyLife does not provide health services, medical services or render medical advice. Nothing contained in this Site or the Content should be construed as such service, advice or diagnosis. The information and reports generated by us should not be interpreted as a substitute for physician consultation, evaluation, or treatment, and the information made available on or through this Site or the Content should not be relied upon when making medical decisions, or to diagnose or treat a health condition or illness. Under no circumstances will any of your use, access or interactions with this Site or the Content be deemed to create a doctor-patient relationship or a fiduciary duty of any kind whatsoever, between you and HealthyLife. you are solely responsible for your use, access or interactions with this Site and the Content.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "3.2 You should consult with your physician or medical professional(s) and consider the associated risks before accessing or using this Site or the Content. You should also seek the advice of a physician or medical professional(s) with any questions you may have regarding your health before beginning any dietary programs or plans, exercise regimen, food swaps or any other fitness or wellness activities or plans that may be referenced, discussed or offered under thisSite or the Content. If you are being treated for a disease, health condition or illness, taking prescription medication or following a therapeutic diet to treat a disease, health condition or illness, you should consult with your physician or medical professional(s) before using this Site or the Content. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "3.3 You represent to us (which representation shall be deemed to be made each time you use this Site or the Content), that:",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        " (a)  you are not using or interacting with this Site or the Content or participating in any of the activities offered by the Content for the purpose of seeking medical attention or a health service; ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        " (b) before using or interacting with this Site or the Content (including making any dietary, health or wellness changes based on information available through this Site or the Content), you will consult with and receive the consent of your physician or medical professional(s). If any information you receive or obtain from using this Site or the Content is inconsistent with the medical advice from your physician or medical professional(s), you should follow the advice of your physician or medical professional(s); and",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        " (c) accessing, using or interacting with this Site or the Content or participating in any of the activities offered by this Site or the Content is at your own risk and that if you choose to do so, you are doing so of your own free will and accord, knowingly and voluntarily assuming all risks associated with such Content or activities.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "3.4 Except as otherwise set out in these healthylife Food Tracker terms & conditions, and to the maximum extent permitted by applicable law, we are not responsible or liable, either directly or indirectly, for any injury, illness, or damages sustained from your use of, or inability to use, this Site, any Content or features of the Content, including any activities or content you access or learn about through this Site or the Content even if caused in whole or part by the action, inaction or negligence of HealthyLife or others.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "4. Registration ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "4.1. You must be a registered cardholder of Woolworths’ Everyday Rewards Card to access, use or interact with this Site and Content. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "4.2  You must complete the customer registration process by linking your Everyday Rewards Card  through this Site before accessing, using or interacting with this Site. Registration is open to all Australian Residents aged 18 years and older.  If you are under 18 years of age, then you must obtain your parent or guardian’s consent before accessing, using or interacting with this Site or the Content.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "4.3. You may not have more than one active account linked to your Everyday Rewards Card, and your account is non-transferable. You may update, edit or terminate your account at any time through this Site.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "4.4 By accessing this Site and the Content, you are also agreeing to be subject to the Everyday Rewards Terms & Conditions. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "5. Disclaimer regarding accuracy and reliance on Content",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "5.1 We make no representations or warranties as to the accuracy, reliability, completeness or timeliness of any Content available through this Site, and we make no commitment to update such Content.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "5.2 HealthyLife’s food database (“Food Database”) used in creating or developing the Content contains a combination of information prepared by Commonwealth Scientific and Industrial Research Organisation (“CSIRO”), , your Everyday Rewards Card and/or HealthyLife . Please be advised that nutritional information in the Food Database has not been reviewed by persons with the expertise required to provide you with personalised, complete, accurate, or reliable information. HealthyLife does not: ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(a) guarantee the accuracy, recency,  completeness, or usefulness of any nutritional information or ingredients in the Food Database or the Content; or ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(b) adopt, endorse or accept responsibility for the accuracy or reliability of any such nutritional information or the Content. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "To the extent permitted by applicable law, under no circumstances will HealthyLife be responsible for any loss or damage resulting from your reliance on nutritional information or the Content. You are solely responsible for ensuring any nutritional information in the Food Database and the Content is accurate, complete and useful. Further, you acknowledge that there may be a time delay between using your Everyday Rewards Card and the Food Database and Content being updated and that the Food Database and Content may not provide a complete, accurate or reliable picture of your total food and beverage consumption or shopping habits.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "5.3  In addition, any user-generated content on this Site or related to this Site, including advice, statements, or other information, including, without limitation, food, nutrition, dietary guidance, exercise or training guidance, athletic activities, exercise and health database entries, are not produced by HealthyLife, and should not be relied on without independent verification. Such user-generated content, whether publicly posted or privately transmitted, is the sole responsibility of the user from whom such user-generated content originated. All information is provided “as is” without any representation, warranty or condition as to its accuracy or reliability.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "5.3 Any success stories posted by HealthyLife or HealthyLife members in the Content or connected with the Content may not represent typical or even accurate results obtained from any particular wellness, health or nutrition activity or diet. To the extent permitted by applicable law, HealthyLife has no and assumes no obligation or liability associated with the accuracy, reliability or effectiveness of any wellness or health activity or dietary recommendation contained in any user success stories.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "6. Termination or amendment",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "6.1 Our Content is constantly evolving. With the launch of new Content, and features, we need the flexibility to make changes, impose limits, and occasionally suspend or terminate certain Content. We have no obligation to continue to provide or enable any particular features or functionality of any Content. We may also update the Content, which might not work properly if you don't install the updates (if any). The Content may change frequently, and their form and functionality may change without prior notice to you.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "6.2 We reserve the right, in our sole discretion, to change, modify, add or remove portions of these healthylife Food Tracker terms & conditions at any time, and such modifications will be effective as soon as they are posted. For future access and/or use of this Site or the Content, these healthylife Food Tracker terms & conditions may therefore be different. We recommend that you read these healthylife Food Tracker terms & conditions carefully each time you agree to them when accessing and/or using the Site or the Content. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "If we change these healthylife Food Tracker terms & conditions, we will notify you.  If you do not accept the change, you should cancel your registration with us.  If you continue to use this Site or Content following our notification of the change in healthylife Food Tracker terms & conditions, you will be deemed to have accepted the change. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "6.3 Either you or we may terminate or cancel your registration in healthylife Food Tracker at any time by notice to us by emailing tracker@healthylife.com.au which may take up to 7 business days to action or by you unlinking your Everyday Rewards Card. ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        " 6.4 You understand and agree that to the maximum extent permitted by applicable law the withdrawal of your registration is your sole right and remedy with respect to any dispute with us including, any dispute related to, or arising out of:",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(a) these healthylife Food Tracker terms & conditions (including our enforcement or application of these healthylife Food Tracker terms & conditions);",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(b) any of our practices or policies, including our Privacy Policy, (and including our enforcement or application of these policies);",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(c) the Content available through this Site including any change in content provided through this Site; and",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(d) your ability to access and/or use this Site or the Content.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "6.5 Upon withdrawal of your participation in healthylife Food Tracker, we may immediately deactivate your user account and all related information and/or files in your user account and/or bar any further access to such information and/or files, this Site (or part thereof). ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "7. Privacy and security ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "7.1 We are committed to protecting your privacy and security.  For more information, you should review our Privacy Policy.  By agreeing to these healthylife Food Tracker terms & conditions, you also consent to the collection and use of your Personal Information in accordance with the Privacy Policy.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "7.2  Depending on how you use the healthylife Food Tracker, we may obtain information from you about your household for example, the age and gender of other members of your household. By agreeing to these healthylife Food Tracker terms & conditions, you confirm that you have obtained the appropriate consents from the relevant members of your household to include this information in healthylife Food Tracker on their behalf.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "8. Ownership of Materials",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "8.1 You:",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(a) acknowledge that materials in the Site, including the software, design, text, graphics, video, photographs, images and other materials associated with healthylife Food Tracker and the Content (together, the “Materials”) are protected by copyright and/or other intellectual property rights, and are owned by or licensed to us;",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(b) must not (nor allow or facilitate any third party to) modify, copy, adapt, store in a retrieval system, reproduce, sub-license upload, post, transmit, sell, distribute or otherwise commercially exploit in any way or communicate to the public any Material without our prior written consent; and",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(c) must not frame or embed in another website any Materials without our prior written consent.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "8.2. Unless otherwise specified, we grant you a limited, non-exclusive, non-transferable, revocable right to access and use this Site (including the healthylife Food Tracker and the Content) for your personal and non-commercial use, subject to your compliance with these healthylife Food Tracker terms & conditions.  ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "8.3. This Site contains trade marks which are protected by law. You must not (nor allow or facilitate any third party to) use any of the marks or trademarks appearing on this Site, or our name or the names of our related bodies corporate without our prior written consent. You must also not use any other company, product and services marks on this Site that are owned by other third parties without obtaining the relevant third party owner’s consent.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "9. General",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "9.1 This Site and Content may contain links to external websites that are not operated by us or our related bodies corporate. These links are provided for your convenience only and you agree that:",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(a) we make no representations or warranties, or have any responsibility or liability for those websites;",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(b) we do not guarantee (i) the accuracy, recency,  completeness, or usefulness of any of those ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "websites or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any content, products or service available through these websites;",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(c) these links do not indicate, expressly or impliedly, that we endorse the site or the products, content or services that are provided at those websites; ",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(d) when you access or use the content, products or services on the websites made available through these links, HealthyLife is not responsible for or affiliated with the providers, and does not provide or control their content, products or services; and",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "(e) your access or use of the content, products or services on the websites made available through these links is solely at your own risk.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "9.2. If the whole or any part of a provision of this agreement is void, unenforceable or illegal in a jurisdiction it is severed for that jurisdiction. The remainder of this agreement has full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected. This clause has no effect if the severance alters the basic nature of this agreement or is contrary to public policy.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "9.3 Any reference in these healthylife Food Tracker terms & conditions to “including” means “including but not limited to”.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value:
                        "9.4. This agreement is governed by the laws of New South Wales, Australia. Each party submits to the non-exclusive jurisdiction of the courts of that place. Any rights or remedies to which you are entitled under the Australian Consumer Law arise independently of these terms and conditions and this clause does not apply to any claim you may have under the Australian Consumer Law.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "\n\n\n\n",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            },
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const TERMS_AND_CONDITIONS_VERSION = {
  data: {
    termsAndConditionsPageCollection: {
      __typename: "TermsAndConditionsPageCollection",
      items: [
        {
          __typename: "TermsAndConditionsPage",
          sys: {
            __typename: "Sys",
            publishedVersion: 125,
          },
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};

export const RECIPE_FOOD_GROUP_FILTERS = {
  data: {
    recipeFoodGroupFilterCollection: {
      __typename: "RecipeFoodGroupFilterCollection",
      items: [
        {
          __typename: "RecipeFoodGroupFilter",
          fruit: [
            "Pear",
            "Apple",
            "Lemon",
            "Stone fruit",
            "Mango",
            "Blueberry",
            "Banana",
            "Orange",
            "Raspberry",
            "Strawberry",
            "Grape",
            "Pineapple",
            "Peach",
            "Cherry",
            "Mandarin",
            "Watermelon",
            "Rhubarb",
            "Nectarine",
            "Blackberry",
            "Plum",
            "Rockmelon",
            "Pomegranate",
            "Fig",
          ],
          vegetablesAndLegumes: [
            "Zucchini",
            "Cauliflower",
            "Tomato",
            "Potato",
            "Sweet potato",
            "Spinach",
            "Asparagus",
            "Eggplant",
            "Mushroom",
            "Kale",
            "Avocado",
            "Pumpkin",
            "Broccolini",
            "Broccoli",
            "Parsnip",
            "Brussels sprout",
            "Beetroot",
            "Corn",
            "Green beans",
            "Carrot",
            "Iceberg lettuce",
            "Snow peas",
            "Capsicum",
            "Red cabbage",
            "Lentil",
            "Chickpea",
          ],
          meatAndAlternatives: [
            "Lamb",
            "Beef",
            "Chicken",
            "Pork",
            "Fish",
            "Salmon",
            "Mince",
            "Plant-based",
            "Tofu",
            "Egg",
            "Veal",
            "Barramundi",
            "Seafood",
            "Prawn",
            "Turkey",
            "Sausage",
            "Lentil",
            "Chickpea",
            "Smoked salmon",
            "Smoked trout",
          ],
          sometimesFoods: ["Chocolate", "Ham"],
          grains: ["Rice"],
          dairyAndAlternatives: [
            "Baked cheese",
            "Baked cheesecake",
            "Caesar salad",
            "Caprese salad",
            "Cheese",
            "Cheese board",
            "Cheese dip",
            "Cheese fondue",
            "Cheese pizza",
            "Cheese souffle",
            "Cheesecake",
            "Frittata",
            "Frozen yoghurt",
            "Granola",
            "Greek salad",
            "Layered Cheesecake",
            "Mac and cheese",
            "Milkshake",
            "Muesli",
            "Muesli granola and porridge",
            "No-bake cheesecake",
            "Oats",
            "Omelette",
            "Overnight oat",
            "Parfait",
            "Parmigiana",
            "Pasta bake",
            "Pizza",
            "Porridge",
            "Quesadilla",
            "Quiche",
            "Quiche and frittata",
            "Ricotta cheesecake",
            "Schnitzel and parmigiana",
            "Smoothie and frappe",
            "Smoothie bowl",
            "Tzatziki",
            "Vegetable pie and tart",
            "Vegetable pizza",
            "Zucchini slice",
          ],
        },
      ],
    },
  },
  loading: false,
  networkStatus: 7,
};
