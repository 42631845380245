import SidebarSettings from "./SidebarSettings";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { useContext, useState } from "react";
import { ReactComponent as OptOutIcon } from "../../icons/opt-out.svg";
import { ReactComponent as Logout } from "../../icons/log-out.svg";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useMember from "../../hooks/useMember";
import { useHistory } from "react-router-dom";
import useHousehold from "../../hooks/useHousehold";

const SidebarOptout = () => {
  const { setIsOpenSidebar, isOpenOptout, setIsOpenOptout, closeAllSidebar } =
    useContext(SettingsContext);
  const { storeMemberUnconsent } = useMember();
  const { household, removeFromHousehold } = useHousehold();
  const history = useHistory();
  const [optingOut, setOptingOut] = useState<boolean>(false);

  const classes = makeStyles(({ breakpoints }) => ({
    container: {
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      height: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    heading: {
      padding: "16px 24px",
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
      display: "flex",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "100%",
    },
    headingText: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    innerContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px 24px",
      boxSizing: "border-box",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      width: "100%",
    },
    buttonContainer: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 16px 24px",
      marginTop: "auto",
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
    },
    // Content styling here!
    header: {
      margin: 0,
      marginBottom: "16px",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      color: COLOR_GREYSCALE[900],
    },
    body: {
      margin: 0,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[900],
    },
  }))();

  const handleOptOut = async () => {
    setOptingOut(true);
    const removeAllFromHousehold = household.map((hh) =>
      removeFromHousehold(hh)
    );
    try {
      await Promise.all(removeAllFromHousehold);
      setOptingOut(false);
      closeAllSidebar();
      history.push("/opt-out");
      await storeMemberUnconsent();
    } catch (e) {
      console.log("Somethings wrong:", e);
    }
  };

  return (
    <SidebarSettings
      sidebarOpen={isOpenOptout}
      setSidebarOpen={setIsOpenOptout}
      backButtonAction={() => {
        setIsOpenOptout(false);
        setIsOpenSidebar(true);
      }}
      contentContainerExtended={true}
    >
      <div className={classes.container}>
        <div className={classes.heading}>
          <OptOutIcon width={24} height={24} />
          <h3 className={classes.headingText}>Opt out of Food Tracker</h3>
        </div>
        <div className={classes.innerContent}>
          <Typography className={classes.header}>
            Are you sure you want to opt out of Food Tracker?
          </Typography>
          <Typography className={classes.body}>
            By opting out of Food Tracker you will unlink your Everyday Rewards
            card and deactivate your Food Tracker from Healthylife. You will no
            longer be able to see any insights if you do this.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant={"contained"}
            color={"default"}
            className={classes.buttonCancel}
            onClick={() => setIsOpenOptout(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            endIcon={<Logout />}
            className={classes.button}
            onClick={handleOptOut}
            disabled={optingOut}
          >
            Opt out
          </Button>
        </div>
      </div>
    </SidebarSettings>
  );
};

export default SidebarOptout;
