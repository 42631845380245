import { TimeFrame } from "../constants";
import { createContext, FC, ReactNode, useState } from "react";

const DEFAULT = TimeFrame.Month;

interface TimeFrameState {
  timeFrame: TimeFrame;
  setTimeFrame: (timeFrame: TimeFrame) => void;
}

export const SelectedTimeFrameContext = createContext<TimeFrameState>({
  timeFrame: DEFAULT,
  setTimeFrame: () => {},
});

const SelectedTimeFrameProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [timeFrame, setTimeFrame] = useState(DEFAULT);

  return (
    <SelectedTimeFrameContext.Provider
      value={{
        timeFrame,
        setTimeFrame,
      }}
    >
      {children}
    </SelectedTimeFrameContext.Provider>
  );
};

export default SelectedTimeFrameProvider;
