import { useContext, useEffect, useState } from "react";
import { RecipeProps } from "../models";
import { getAllHealthierRecipes } from "../services/recipes";
import {
  RecipeFoodGroupItems,
  fetchFoodGroupFilters,
} from "../services/contentful/recipe";
import { DIETARY_PREFERENCES_DATA } from "../components-2/dietary-preference/config";
import { RecipeContext } from "../components/providers/RecipeProvider";
import useHideDietaryPreferences from "./useHideDietaryPreferences";

export interface FilterProps {
  id: string;
  name: string;
  icon?: JSX.Element;
}
export interface RecipeFilterProps {
  dietary_preferences: FilterProps[];
  food_group: FilterProps[];
  ingredients: FilterProps[];
  meal_type: FilterProps[];
}

export default function useRecipes() {
  const [recipes, setRecipes] = useState<RecipeProps[]>([]);
  const [formattedRecipes, setFormattedRecipes] = useState<RecipeProps[]>([]);
  const [dairyRecipes, setDairyRecipes] = useState<RecipeProps[]>([]);
  const [recipeFoodGroupItems, setRecipeFoodGroupItems] =
    useState<RecipeFoodGroupItems>();
  const [filters, setFilters] = useState<RecipeFilterProps>({
    dietary_preferences: [],
    food_group: [],
    ingredients: [],
    meal_type: [],
  });
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [foodGroupFilters, setFoodGroupFilters] =
    useState<RecipeFoodGroupItems>({
      fruit: [],
      vegetablesAndLegumes: [],
      meatAndAlternatives: [],
      grains: [],
      dairyAndAlternatives: [],
    });
  const [allIngredients, setAllIngredients] = useState<FilterProps[]>([]);
  const [allMealTypes, setAllMealTypes] = useState<FilterProps[]>([]);

  const { currentFilters } = useContext(RecipeContext);
  const hideDP = useHideDietaryPreferences();

  useEffect(() => {
    const updateValues = async () => {
      const foodGroupItems = await fetchFoodGroupFilters();
      /** format values in array from "Apple" to "apple" || "Ice Cream" to "ice-cream" */
      let formattedFoodGroupItems: RecipeFoodGroupItems = {
        fruit: [],
        vegetablesAndLegumes: [],
        meatAndAlternatives: [],
        grains: [],
        dairyAndAlternatives: [],
      };

      for (let i in recipeFoodGroupItems) {
        if (
          Array.isArray(recipeFoodGroupItems[i as keyof RecipeFoodGroupItems])
        ) {
          formattedFoodGroupItems[i as keyof RecipeFoodGroupItems] =
            recipeFoodGroupItems[i as keyof RecipeFoodGroupItems].map(
              (item: string) => item.replace(" ", "-").toLowerCase()
            );
        }
      }

      // START DIETARY PREFERENCE FILTER
      /**
       * FILTER ALL RECIPES BASED ON DIETARY PREFERENCE
       *
       */
      let dietaryRecipes = recipes;
      const preferenceFilters = currentFilters.filter((filter) =>
        filter.includes("dietarypreferences")
      );
      if (!hideDP && preferenceFilters && preferenceFilters.length > 0) {
        // have lactose_free filter work as dairy_free filter
        const pfs = preferenceFilters
          .map((pf) => {
            if (pf === "dietarypreferences:lactose_free") {
              return "dietarypreferences:dairy_free";
            } else return pf;
          })
          .filter((item, i, arr) => arr.indexOf(item) === i);

        dietaryRecipes = recipes.filter((item) => {
          let inPreference = false;
          const tags = item.tags
            .map((tag: string) => tag.split("/"))
            .flat()
            .map((item: string) => item.toLowerCase());
          // MAKE SURE ALL PREFERENCES ARE AVAILABLE ON A RECIPE
          inPreference = pfs.every((pf) => {
            const pfn = pf.replaceAll("dietarypreferences:", "");
            const name = pfn.replaceAll("_", "-");
            return tags.some((tag) => tag.includes(name));
          });
          return inPreference;
        });
      }
      // END DIETARY PREFERENCE FILTER

      const newRecipes = dietaryRecipes.map((item: RecipeProps) => {
        item.foodGroup = [];
        item.ingredients = [];
        item.mealType = [];
        item.dietaryPreferences = [];

        const recipeTags = item.tags
          .map((item: string) => item.split("/"))
          .flat()
          .map((item: string) => item.toLowerCase());

        for (let i in formattedFoodGroupItems) {
          for (let c = 0; c < recipeTags.length; c++) {
            if (recipeTags.length) {
              if (
                formattedFoodGroupItems[
                  i as keyof RecipeFoodGroupItems
                ].includes(recipeTags[c])
              ) {
                item.foodGroup?.push(i);
              }

              if (
                formattedFoodGroupItems[
                  i as keyof RecipeFoodGroupItems
                ].includes(recipeTags[c])
              ) {
                item.ingredients?.push(recipeTags[c]);
              }
            }
          }
        }

        item.mealType = item.tags
          .filter((item: string) => item.includes("meal-type"))
          .map((item: string) => {
            const t = item.replace("foodhub:meal-type", "");
            const tags = t.split("/").filter((t) => t !== "");
            return tags;
          })
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index);

        if (!hideDP) {
          item.dietaryPreferences = item.tags
            .filter(
              (item: string) =>
                item.includes("vegan") ||
                item.includes("vegetarian") ||
                item.includes("gluten-free") ||
                item.includes("lactose-free") ||
                item.includes("dairy-free")
            )
            .map((item: string) => {
              const t = item.split("/").filter((t) => t !== "");
              const tag = t[t.length - 1].replaceAll("-", "_");
              return tag;
            })
            .flat()
            .filter((value, index, self) => self.indexOf(value) === index);
        }

        item.foodGroup = item.foodGroup.filter(
          (value, index, self) => self.indexOf(value) === index
        );

        item.ingredients = item.ingredients.filter(
          (value, index, self) => self.indexOf(value) === index
        );

        return item;
      });

      const getFilterList = async (
        recipes: RecipeProps[],
        foodGroupItems: RecipeFoodGroupItems
      ) => {
        const ingredients: RecipeFilterProps["ingredients"] = [];
        const meal_type: RecipeFilterProps["meal_type"] = [];
        let ingredientArr: string[] = [];
        let mealTypeArr: string[] = [];
        recipes
          .map((recipe: RecipeProps) => recipe.tags)
          .flat()
          .filter((tag, index, tags) => tags.indexOf(tag) === index)
          .filter(
            (tag) =>
              tag.includes("meal-type") || tag.includes("fresh-ingredients")
          )
          .forEach((tag) => {
            if (tag.includes("meal-type")) {
              const t = tag.replace("foodhub:meal-type", "");
              const tags = t.split("/").filter((t) => t !== "");
              tags.forEach((t) => mealTypeArr.push(t));
            } else if (tag.includes("fresh-ingredients")) {
              const t = tag.replace("foodhub:fresh-ingredients", "");
              const tags = t.split("/").filter((t) => t !== "");
              tags.forEach((t) => ingredientArr.push(t));
            }
          });
        // include food group ingredients on filter
        let foodgroupKeys = Object.keys(foodGroupItems) as Array<
          keyof typeof foodGroupItems
        >;

        foodgroupKeys
          // .filter((key) => key !== "dairyAndAlternatives")
          .map((key) => foodGroupItems[key])
          .flat()
          .filter((ingredient) => ingredient !== "RecipeFoodGroupFilter")
          .map((ingredient) => ingredient.toLowerCase().replaceAll(" ", "-"))
          .forEach((ingredient) => ingredientArr.push(ingredient));

        // clean up ingredients array

        const uniqueIngredientsArr = ingredientArr.filter(
          (tag, index, tags) => tags.indexOf(tag) === index
        );

        // get only ingredients with recipes
        const filteredRecipes = recipes.filter((recipe) =>
          uniqueIngredientsArr.some((ingredient) =>
            recipe.ingredients?.includes(ingredient)
          )
        );

        const ingredientsFromRecipes = filteredRecipes
          .map((recipe) => recipe.ingredients)
          .flat()
          .filter((item, i, items) => items.indexOf(item) === i) as string[];

        ingredientsFromRecipes.forEach((tag) =>
          ingredients.push({
            id: tag,
            name: tag.replaceAll("-", " "),
          })
        );

        // clean up meal type array
        mealTypeArr
          .filter((tag, index, tags) => tags.indexOf(tag) === index)
          .forEach((tag) => {
            meal_type.push({
              id: tag,
              name: tag.replaceAll("-", " "),
            });
          });

        const foodGroupFilter = [
          {
            id: "fruit",
            name: "Fruit",
          },
          {
            id: "vegetablesAndLegumes",
            name: "Vegetables and Legumes",
          },
          {
            id: "meatAndAlternatives",
            name: "Meat and Alternatives",
          },
          {
            id: "grains",
            name: "Grains",
          },
          {
            id: "dairyAndAlternatives",
            name: "Dairy and Alternatives",
          },
        ];

        const preferenceFilter = !hideDP
          ? Object.keys(DIETARY_PREFERENCES_DATA).map((key) => {
              const pref =
                DIETARY_PREFERENCES_DATA[
                  key as keyof typeof DIETARY_PREFERENCES_DATA
                ];
              return {
                id: pref.id,
                name: pref.name,
                icon: pref.icon,
              };
            })
          : [];

        setAllIngredients(ingredients);
        setAllMealTypes(meal_type);
        setFilters({
          dietary_preferences: preferenceFilter,
          food_group: foodGroupFilter,
          ingredients,
          meal_type,
        });
      };

      await getFilterList(newRecipes, foodGroupItems);
      setFormattedRecipes(newRecipes);
    };

    updateValues();
    // eslint-disable-next-line
  }, [recipes, currentFilters, hideDP, recipeFoodGroupItems]);

  useEffect(() => {
    const getFoodGroupItems = async (
      recipes: RecipeProps[],
      foodGroupItems: RecipeFoodGroupItems
    ) => {
      const dairyFoodGroup = foodGroupItems["dairyAndAlternatives"].map(
        (classification) => classification.replaceAll(" ", "-").toLowerCase()
      );
      const dairyFoodGroupIngredients: string[] = [];
      const dairyFoodGroupRecipes = recipes.filter((recipe: RecipeProps) => {
        const tags = recipe.tags;
        const withClassification = tags.filter((tag) => {
          const tagList = tag.split("/");
          return (
            tag.includes("classification") &&
            dairyFoodGroup.some((item) => tagList.includes(item))
          );
        });

        return withClassification.length > 0;
      });

      dairyFoodGroupRecipes
        .map((recipe: RecipeProps) => recipe.tags)
        .flat()
        .filter((tag, index, tags) => tags.indexOf(tag) === index)
        .filter((tag) => tag.includes("fresh-ingredients"))
        .forEach((tag) => {
          const t = tag.replace("foodhub:fresh-ingredients", "");
          const tags = t.split("/").filter((t) => t !== "");
          tags.forEach((t) => dairyFoodGroupIngredients.push(t));
        });

      const foodGroupItemCopy = { ...foodGroupItems };
      foodGroupItemCopy.dairyAndAlternatives = dairyFoodGroupIngredients;
      setDairyRecipes(dairyFoodGroupRecipes);
      setFoodGroupFilters(foodGroupItemCopy);
    };

    const init = async () => {
      const allRecipes = await getAllHealthierRecipes();
      const foodGroupItems = await fetchFoodGroupFilters();
      await getFoodGroupItems(allRecipes.data, foodGroupItems);
      setRecipeFoodGroupItems(foodGroupItems);
      setRecipes(allRecipes.data);
      setTotal(allRecipes.total);
      setIsLoading(false);
    };
    init();
  }, []);

  return {
    formattedRecipes, // RecipeProps with foodGroup, ingredients, mealType,
    recipes,
    dairyRecipes,
    filters,
    isLoading,
    foodGroupFilters,
    total,
    allIngredients,
    allMealTypes,
  };
}
