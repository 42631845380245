import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";
import { ReactComponent as RecipesIcon } from "../../icons/recipes-icon.svg";
import RecipeExplainer from "../../components-2/recipe/RecipeExplainer";

const useStyles = makeStyles(({ breakpoints }) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "8px",
  },
  title: {
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
    margin: "0px",
    [breakpoints.up("md")]: {
      fontSize: "28px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      lineHeight: "36px",
    },
  },
  descriptionWrapper: {
    borderBottom: `1px solid ${COLOR_GREYSCALE[200]}`,
    marginBottom: "24px",
    [breakpoints.up("md")]: {
      paddingBottom: "32px",
    },
  },
  description: {
    fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    color: "#1D1F20",
    [breakpoints.up("md")]: {
      fontSize: "16px",
      marginBottom: "0px",
    },
  },
}));

const RecipeHeader = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleWrapper}>
        <RecipesIcon />
        <h1 className={classes.title}>
          {/* temporarily hardcoded */}
          Recipes
          {/* {heading} */}
        </h1>
        <RecipeExplainer />
      </div>

      <div className={classes.descriptionWrapper}>
        <p className={classes.description}>
          {/* temporarily hardcoded */}
          Make eating healthier easier with our collection of recipes from
          Woolworths. Get quick dinners and lunches, easy snack and lunchbox
          ideas and much more tasty goodness.
          {/* {description} */}
        </p>
      </div>
    </>
  );
};

export default RecipeHeader;
