import Lottie from "react-lottie";
import animationData from "../../lotties/loader-success.json";

interface Props {
  width?: number;
  height?: number;
}

const LoadingAnimation = ({ width = 150, height = 150 }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie options={defaultOptions} height={height} width={width} />;
};

export default LoadingAnimation;
