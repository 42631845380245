import { makeStyles } from "@material-ui/core";
import Blockquote from "../Blockquote";
import { ReactComponent as Bulb } from "../../icons/lightbulb.svg";
import { ReactComponent as Star } from "../../icons/star.svg";
import { NutrientTrackerTypes } from "../../models";
import { getDifferenceFromLastMonth } from "./utils";
import { TimeFrame } from "../../constants";
import useNutrients from "../../hooks/useNutrients";

interface BlockquoteInfo {
  [key: string]: {
    bulb: string;
    star: string;
  };
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    paddingBottom: "16px",
    paddingTop: "16px",
    [breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const NutrientTrackerBlockqoutes = ({
  type,
  timeFrame,
}: {
  type: NutrientTrackerTypes["type"];
  timeFrame: TimeFrame;
}) => {
  const { nutrientsHistory } = useNutrients();
  const classes = useStyles();
  const difference = getDifferenceFromLastMonth(nutrientsHistory, type);
  const absoluteDifference = Math.abs(difference);
  const differencePhrase = `<span style="font-family: Gilroy-Bold; font-weight: normal;">${
    difference > 0 ? "up" : "down"
  } ${absoluteDifference}%</span>`;
  const blockquoteInfo: BlockquoteInfo = {
    sugar: {
      bulb: `Added sugar is ${differencePhrase} compared to the last ${timeFrame.toLowerCase()}`,
      star: `<p style="margin-top: 0; margin-bottom: 8px; font-family: Gilroy-Bold; font-weight: normal; font-size: 14px; line-height: 20px">Sweet Tip!</p>
            Opt for natural sugars from fruit, veggies, dairy and grains, and reduce added sugars from sometimes foods.`,
    },
    salt: {
      bulb: `Salt is ${differencePhrase} compared to the last ${timeFrame.toLowerCase()}`,
      star: `<p style="margin-top: 0; margin-bottom: 8px; font-family: Gilroy-Bold; font-weight: normal; font-size: 14px; line-height: 20px">Get salt savvy!</p>
      A balanced diet with the five main food groups means you get naturally occurring salt. You can reduce salt by lowering your sometimes foods.`,
    },
    fat: {
      bulb: `Saturated fat is <span style="font-family: Gilroy-Bold; font-weight: normal;"> ${absoluteDifference}% ${
        difference > 0 ? "higher" : "lower"
      } </span> compared to last ${timeFrame.toLowerCase()}`,
      star: `<p style="margin-top: 0; margin-bottom: 8px; font-family: Gilroy-Bold; font-weight: normal; font-size: 14px; line-height: 20px">Good fat is good for you!</p>
      A balanced diet with the five main food groups means you get more healthy fats. You can reduce saturated fat (the not-so-good fat) by lowering your sometimes foods.
      `,
    },
  };
  return (
    <div className={classes.root}>
      {difference !== 0 && difference !== -100 && (
        <div>
          <Blockquote icon={<Bulb />} text={blockquoteInfo[type].bulb} />
        </div>
      )}
      <div>
        <Blockquote icon={<Star />} text={blockquoteInfo[type].star} />
      </div>
    </div>
  );
};

export default NutrientTrackerBlockqoutes;
