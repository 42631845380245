import { makeStyles } from "@material-ui/core/styles";
import { COLOR_GREYSCALE, DARK_GREEN } from "../theme";
import { Divider, Typography } from "@material-ui/core";
import ExternalLink from "../components/ExternalLink";
import { ChevronRight } from "@material-ui/icons";

type Props = {
  icon?: React.ReactElement;
  text: string;
  title?: string;
  whiteBg?: boolean;
  hyperlink?: string;
};

const Blockquote = ({ title, text, icon, whiteBg, hyperlink }: Props) => {
  const useStyles = makeStyles(() => ({
    root: {
      borderLeft: `8px solid ${DARK_GREEN}`,
      backgroundColor: whiteBg ? "white" : COLOR_GREYSCALE[50],
      padding: "12px",
      borderRadius: "8px",
      color: COLOR_GREYSCALE[700],
      display: "flex",
      gap: "8px",
    },
    iconContainer: {
      width: "24px",
    },
    title: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "4px",
      color: DARK_GREEN,
    },
    hyperlinkContainer: {
      marginTop: "8px",
      display: "flex",
      justifyContent: "space-between",
    },
    hyperlink: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy-SemiBold",
      fontSize: "14px",
      lineHeight: "20px",
      width: "100%",
    },
    hyperlinkIcon: {
      color: COLOR_GREYSCALE[900],
    },
    divider: {
      marginTop: "8px",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {icon ? <div className={classes.iconContainer}>{icon}</div> : null}
      <Typography variant="body2" component="span">
        {title && <p className={classes.title}>{title}</p>}
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        {hyperlink && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.hyperlinkContainer}>
              <ExternalLink href={hyperlink} className={classes.hyperlink}>
                Explore telehealth
              </ExternalLink>
              <ChevronRight className={classes.hyperlinkIcon} />
            </div>
          </>
        )}
      </Typography>
    </div>
  );
};

export default Blockquote;
