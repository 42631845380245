import { OnboardingPageLayout } from "./OnboardingPageLayout";
import React, { ChangeEvent, ReactElement, useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CHARCOAL, COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import { emailValidation } from "../../utils/regex";
import {
  Checkbox,
  ClickAwayListener,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import ExternalLink from "../../components/ExternalLink";
import { ExternalLinks } from "../../constants";
import useMember from "../../hooks/useMember";
import {
  changeMarketingEmail,
  subscribeToMarketing,
  unsubscribeFromMarketing,
} from "../../services/members";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_COMMUNICATIONS_EVENT } from "../../events";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { WowEmailCampaignBanner } from "../wow-email-campaign-banner";
import {
  isEdrApp,
  isWowApp,
  withinEdrEmailCampaignDates,
  withinWowEmailCampaignDates,
} from "../../utils/misc";
import { EdrEmailCampaignBanner } from "../edr-email-campaign-banner";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: spacing(2),
  },
  title: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-Bold'",
    fontWeight: "normal",
  },
  body: {
    margin: 0,
    marginTop: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
    marginBottom: "8px",
  },
  allocationRoot: {
    [breakpoints.up("md")]: {
      maxWidth: "300px",
      width: "100%",
    },
  },
  allocationAndLegendRoot: {
    [breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  desktopLegendRoot: {
    display: "flex",
    width: "200px",
    alignItems: "flex-end",
    marginLeft: "16px",
  },
  gridContainer: {
    //marginBottom: spacing(1),
    "& > *:not(:first-child)": {
      marginTop: "16px",
    },
  },
  removeTopBottomMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  disclaimerText: {
    fontFamily: "'Gilroy'",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
    margin: 0,
  },
  errorText: {
    color: red[500],
    fontWeight: 400,
    lineHeight: "1.66",
    fontSize: "0.75rem",
    margin: "0",
    marginLeft: "0px",
    textAlign: "left",
  },
  hyperlinkText: {
    textDecoration: "underline",
    color: "#007A71",
  },
  heading: {
    margin: 0,
  },
  textArea: {
    width: "100%",
    "& .MuiInputBase-root": {
      marginTop: spacing(1),
      marginBottom: 0,
    },
  },
  checkBox: {
    padding: 0,
    marginRight: "10px",
  },

  subscribeToggleContainer: {
    boxSizing: "border-box",
    marginBottom: "16px",
    display: "flex",
    width: "100%",
  },
  toggleContainer: {
    padding: "16px!important" as any,
    borderRadius: "8px",
    backgroundColor: "#F6EFDF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subscribeText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-SemiBold'",
    fontWeight: "normal",
    color: COLOR_GREYSCALE[900],
    margin: 0,
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    columnGap: "20px",
    fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#83898C",
  },
  subscribeSwitch: {
    padding: "0px",
    width: "51px",
    height: "31px",
    borderRadius: "999px",

    "& .MuiSwitch-input": {
      width: "51px !important",
      left: "0px!important",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#eaeefd",
      opacity: 1,
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      color: "#bbc5cb",
      "&.Mui-checked": {
        color: "#F6EFDF",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: DARK_GREEN,
        opacity: 1,
      },
      "&.Mui-checked .MuiSwitch-input": {
        left: "-20px!important",
      },
    },
    "& .MuiSwitch-thumb": {
      height: "27px",
      width: "27px",
      boxShadow: "none",
    },
    "& .MuiIconButton-label": {
      margin: "2px",
    },
  },
  emailPrefTitle: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
    color: COLOR_GREYSCALE[900],
    margin: "8px 0 0 0",
  },
  emailPrefDescription: {
    fontFamily: "Gilroy",
    color: COLOR_GREYSCALE[700],
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    margin: 0,
    padding: "8px 12px 0px",
  },
  subscribeCheckbox: {
    "& input[type=checkbox]": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const CommunicationStep = ({
  StepperComponent,
  step,
}: {
  StepperComponent: ReactElement;
  step: number;
}) => {
  const { track } = useEvents();
  const classes = useStyles();
  const { member } = useMember();
  const [memberId] = useState(member!.memberId);
  const [name, setName] = useState<string | null>(member?.name ?? null);
  const [email, setEmail] = useState<string | null>(member?.email ?? null);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showConsentError, setShowConsentError] = useState(false);
  const [showPreferenceError, setShowPreferenceError] = useState(false);
  const [loading, setLoading] = useState(false);
  useState(true);
  const [tAndCAgree, setTAndCAgree] = useState<boolean>(false);
  const marketingConsented = typeof email === "string" && email.length > 0;
  const [wrapUpConsented, setWrapupConsented] = useState<boolean>(
    member?.communicationWrapUp ?? false
  );
  const [insightsConsented, setInsightsConsented] = useState<boolean>(
    member?.communicationInsights ?? false
  );
  const [expertAdviceConsented, setExpertAdviceConsented] = useState<boolean>(
    marketingConsented && member?.communicationExpertAdvice === undefined
      ? true
      : member?.communicationExpertAdvice ?? false
  );

  const { featureFlags } = useFeatureFlags();

  const showWrapUp =
    featureFlags.showCommunicationWrapUp?.enable?.everybody ||
    featureFlags.showCommunicationWrapUp?.enable?.specificMembers?.includes(
      memberId
    );

  const showInsights =
    featureFlags.showCommunicationInsights?.enable?.everybody ||
    featureFlags.showCommunicationInsights?.enable?.specificMembers?.includes(
      memberId
    );

  const showWowEmailCampaignBanner = useMemo(() => {
    return (
      withinWowEmailCampaignDates() &&
      isWowApp() &&
      member?.privateFirstTimeConsentingToTracker !== false
    );
  }, [member]);

  const showEdrEmailCampaignBanner = useMemo(() => {
    return (
      withinEdrEmailCampaignDates() &&
      isEdrApp() &&
      member?.privateFirstTimeConsentingToTracker !== false
    );
  }, [member]);

  const getSaveValidationStatus = () => {
    setShowEmailError(false);
    setShowNameError(false);
    setShowConsentError(false);
    setShowPreferenceError(false);
    setLoading(true);
    if (email) {
      let error = false;

      if (!tAndCAgree) {
        error = true;
        setShowConsentError(true);
      }
      if (
        !expertAdviceConsented &&
        (!showWrapUp || (showWrapUp && !wrapUpConsented)) &&
        (!showInsights || (showInsights && !insightsConsented))
      ) {
        error = true;
        setShowPreferenceError(true);
      }
      if (!name) {
        error = true;
        setShowNameError(true);
      }
      if (!email.match(emailValidation)) {
        error = true;
        setShowEmailError(true);
      }
      if (error) {
        setLoading(false);
        return false;
      }
    } else if (!email && tAndCAgree) {
      if (!name) {
        setShowNameError(true);
      }
      if (
        !expertAdviceConsented &&
        (!showWrapUp || (showWrapUp && !wrapUpConsented)) &&
        (!showInsights || (showInsights && !insightsConsented))
      ) {
        setShowPreferenceError(true);
      }
      setShowEmailError(true);
      setLoading(false);
      return false;
    } else if (!email && !tAndCAgree) {
      if (
        expertAdviceConsented ||
        (showWrapUp && insightsConsented) ||
        (showInsights && insightsConsented)
      ) {
        setShowEmailError(true);
        setShowConsentError(true);
        setLoading(false);
        return false;
      }
    }

    if (name) {
      const spacesOnly = !name.replace(/\s/g, "").length;
      if (spacesOnly) {
        setShowNameError(true);
        setLoading(false);
        return false;
      }
    }
    return true;
  };

  const getSaveIntent = () => {
    const changingEmail = Boolean(
      email && typeof member?.email === "string" && email !== member?.email
    );
    const changingName = Boolean(
      name && typeof member?.name === "string" && name !== member?.name
    );
    const changingPreferences = Boolean(
      expertAdviceConsented !== member?.communicationExpertAdvice ||
        wrapUpConsented !== member?.communicationWrapUp ||
        insightsConsented !== member?.communicationInsights
    );
    const unsubscribing = Boolean(!email && typeof member?.email === "string");
    const subscribing = Boolean(email && !member?.email);

    return {
      changingEmail,
      changingName,
      changingPreferences,
      unsubscribing,
      subscribing,
    };
  };

  const onSkip = async () => {
    track(ONBOARDING_COMMUNICATIONS_EVENT.SKIP);
  };

  const onSave = async () => {
    const success = getSaveValidationStatus();
    if (!success) return;
    const {
      changingEmail,
      changingName,
      changingPreferences,
      unsubscribing,
      subscribing,
    } = getSaveIntent();

    if (unsubscribing) {
      await unsubscribeFromMarketing(memberId, member?.email!);
    } else if (subscribing || changingPreferences || changingName) {
      track(ONBOARDING_COMMUNICATIONS_EVENT.EMAIL);
      track(ONBOARDING_COMMUNICATIONS_EVENT.NAME);
      await subscribeToMarketing(
        memberId,
        email!,
        name!,
        expertAdviceConsented,
        wrapUpConsented,
        insightsConsented
      );
    } else if (changingEmail) {
      await changeMarketingEmail(memberId, member?.email!, email!);
    }
    track(ONBOARDING_COMMUNICATIONS_EVENT.SAVE);
    return true;
  };

  const [emailClickAway, setEmailClickAway] = useState(false);
  const [nameClickAway, setNameClickAway] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setEmail(value);
    setEmailClickAway(false);
    setShowEmailError(!value.match(emailValidation));
    if (value.length === 0) {
      setShowEmailError(false);
      setEmail(null);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
    setNameClickAway(false);
    setShowNameError(false);
    if (value.length === 0) setName(null);
  };

  const handleExpertAdviceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExpertAdviceConsented(event.target.checked);
    if (!event.target.checked && !wrapUpConsented && !insightsConsented) {
      setEmail(null);
      setTAndCAgree(false);
    } else if (event.target.checked && showPreferenceError) {
      setShowPreferenceError(false);
    }
  };

  const handleWrapUpChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWrapupConsented(event.target.checked);
    if (!event.target.checked && !expertAdviceConsented && !insightsConsented) {
      setEmail(null);
      setTAndCAgree(false);
    } else if (event.target.checked && showPreferenceError) {
      setShowPreferenceError(false);
    }
  };

  const handleInsightsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInsightsConsented(event.target.checked);
    if (!event.target.checked && !expertAdviceConsented && !wrapUpConsented) {
      setEmail(null);
      setTAndCAgree(false);
    } else if (event.target.checked && showPreferenceError) {
      setShowPreferenceError(false);
    }
  };

  const handleConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTAndCAgree(event.target.checked);
    if (!event.target.checked) {
      setEmail(null);
      setExpertAdviceConsented(false);
      setWrapupConsented(false);
      setInsightsConsented(false);
      setShowPreferenceError(false);
      setShowEmailError(false);
      setShowConsentError(false);
    }
  };

  return (
    <OnboardingPageLayout
      stepper={React.cloneElement(StepperComponent, {
        customHandleNext: onSave,
        customHandleSkip: onSkip,
        nextButtonLoading: loading,
      })}
      stepNumber={step}
    >
      <Typography variant="h3" component="h2" className={classes.title}>
        Expert recommendations, delivered to you
      </Typography>
      <Typography className={classes.body}>
        Get expert tips and tricks, enjoy access to our dietitians and
        nutritionists and be the first to hear about new features.
      </Typography>
      {showEdrEmailCampaignBanner && <EdrEmailCampaignBanner />}
      {showWowEmailCampaignBanner && <WowEmailCampaignBanner />}
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography className={classes.heading}>First name</Typography>
          <ClickAwayListener onClickAway={() => setNameClickAway(true)}>
            <TextField
              value={name || ""}
              error={showNameError && nameClickAway}
              helperText={
                showNameError && nameClickAway
                  ? "Please enter a valid name"
                  : ""
              }
              variant="outlined"
              className={classes.textArea}
              placeholder="First name"
              onChange={handleNameChange}
              InputProps={{
                inputProps: {
                  "data-cs-mask": "",
                },
              }}
            />
          </ClickAwayListener>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.heading}>Email</Typography>
          <ClickAwayListener onClickAway={() => setEmailClickAway(true)}>
            <TextField
              value={email || ""}
              error={showEmailError && emailClickAway}
              helperText={
                showEmailError && emailClickAway
                  ? "Please enter a valid email address"
                  : ""
              }
              type={"email"}
              variant="outlined"
              className={classes.textArea}
              placeholder="Email address"
              onChange={handleEmailChange}
              InputProps={{
                inputProps: {
                  "data-cs-mask": "",
                },
              }}
            />
          </ClickAwayListener>
        </Grid>

        <Grid item container xs={12}>
          <p className={classes.emailPrefTitle}>Email Preferences</p>
        </Grid>
        {/* Expert advice */}
        <Grid
          item
          container
          xs={12}
          className={classes.subscribeToggleContainer}
        >
          <div className={classes.toggleContainer}>
            <Typography className={classes.subscribeText}>
              Get expert advice
            </Typography>
            <div className={classes.switchContainer}>
              <Switch
                className={classes.subscribeSwitch}
                checked={expertAdviceConsented}
                onChange={handleExpertAdviceChange}
              />
            </div>
          </div>
          <p className={classes.emailPrefDescription}>
            Tips and tricks from qualified health professionals.
          </p>
        </Grid>

        {showWrapUp && (
          <>
            {/* Personalised wrap up */}
            <Grid
              item
              container
              xs={12}
              className={classes.subscribeToggleContainer}
            >
              <div className={classes.toggleContainer}>
                <Typography className={classes.subscribeText}>
                  Personalised wrap up
                </Typography>
                <div className={classes.switchContainer}>
                  <Switch
                    className={classes.subscribeSwitch}
                    checked={wrapUpConsented}
                    onChange={handleWrapUpChange}
                  />
                </div>
              </div>
              <p className={classes.emailPrefDescription}>
                Get a monthly wrap up to see how well you're tracking.
              </p>
            </Grid>
          </>
        )}

        {showInsights && (
          <>
            {/* Instant insights */}
            <Grid
              item
              container
              xs={12}
              className={classes.subscribeToggleContainer}
            >
              <div className={classes.toggleContainer}>
                <Typography className={classes.subscribeText}>
                  Instant insights
                </Typography>
                <div className={classes.switchContainer}>
                  <Switch
                    className={classes.subscribeSwitch}
                    checked={insightsConsented}
                    onChange={handleInsightsChange}
                  />
                </div>
              </div>
              <p className={classes.emailPrefDescription}>
                Unlock key insights straight after your grocery shop.
              </p>
            </Grid>
          </>
        )}

        <Grid
          item
          container
          xs={12}
          className={classes.subscribeCheckbox}
          style={{
            paddingBottom: 0,
            flexWrap: "nowrap",
            alignItems: "flex-start",
          }}
        >
          <Checkbox
            checked={tAndCAgree}
            onChange={handleConsentChange}
            style={{
              color:
                email && showConsentError && !tAndCAgree ? red[500] : grey[700],
            }}
            className={classes.checkBox}
            inputProps={{ "aria-label": "Consent to marketing" }}
          />
          <Grid container spacing={1}>
            <Grid item>
              <Typography className={classes.disclaimerText}>
                By subscribing, you agree to receive marketing communications
                from Healthylife Food Tracker and you agree to the Healthylife{" "}
                <ExternalLink
                  href={ExternalLinks.TermsAndConditions}
                  target="_blank"
                  className={classes.hyperlinkText}
                >
                  Terms and Conditions
                </ExternalLink>
                . View the{" "}
                <ExternalLink
                  href={ExternalLinks.PrivacyPolicy}
                  target="_blank"
                  className={classes.hyperlinkText}
                >
                  Privacy Policy
                </ExternalLink>{" "}
                and{" "}
                <ExternalLink
                  href={ExternalLinks.CollectionNotice}
                  target="_blank"
                  className={classes.hyperlinkText}
                >
                  Collection Notice
                </ExternalLink>{" "}
                to understand how we manage your personal information.
              </Typography>
            </Grid>

            {email && showConsentError && !tAndCAgree && showPreferenceError && (
              <Grid item>
                <Typography className={classes.errorText}>
                  Please consent and choose at least one email preference to
                  subscribe.
                </Typography>
              </Grid>
            )}

            {email && showConsentError && !tAndCAgree && !showPreferenceError && (
              <Grid item>
                <Typography className={classes.errorText}>
                  Please consent to marketing to subscribe.
                </Typography>
              </Grid>
            )}

            {!showConsentError && showPreferenceError && (
              <Grid item>
                <Typography className={classes.errorText}>
                  Please choose at least one email preference to subscribe.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </OnboardingPageLayout>
  );
};
