import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { FC } from "react";
import { FoodGroups, ShippingAllocationValues } from "../../models";
import FoodGroupLegend from "../shopping-allocation/FoodGroupLegend";
import FoodGroupShoppingAllocation from "../shopping-allocation/FoodGroupShoppingAllocation";
import NoneHalfAllIndicators from "../shopping-allocation/NoneHalfAllIndicators";
import ShoppingAllocationLegend from "../shopping-allocation/ShoppingAllocationLegend";
import { CHARCOAL, COLOR_GREYSCALE } from "../../theme";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  setShippingAllocationValues: (
    value: React.SetStateAction<ShippingAllocationValues>
  ) => void;
  shippingAllocationValues: ShippingAllocationValues;
  isOnboarding?: boolean;
  inSidebar?: boolean;
}

const ShoppingAllocationV2: FC<Props> = ({
  shippingAllocationValues,
  setShippingAllocationValues,
  isOnboarding = false,
  inSidebar,
}) => {
  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    top: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: spacing(2),
    },
    title: {
      margin: 0,
      marginBottom: "16px",
      color: CHARCOAL,
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      fontFamily: "'Gilroy-Bold'",
      fontWeight: "normal",
    },
    body: {
      margin: 0,
      marginBottom: "16px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
    },
    allocationRoot: {
      [breakpoints.up("md")]: isOnboarding
        ? {}
        : {
            maxWidth: "300px",
            width: "100%",
          },
    },
    allocationAndLegendRoot: {
      [breakpoints.up("md")]: isOnboarding
        ? {}
        : {
            display: "flex",
            flexDirection: "row",
          },
    },
    desktopLegendRoot: {
      display: "flex",
      width: "200px",
      alignItems: "flex-end",
      marginLeft: "16px",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {!inSidebar && (
        <Typography variant="h3" component="h2" className={classes.title}>
          Shopping allocation
        </Typography>
      )}

      <Typography className={classes.body}>
        Estimate the amount of shopping you do in Woolworths vs other stores so
        we can give you the most representative view of your shopping.
      </Typography>
      <div className={classes.allocationAndLegendRoot}>
        <div className={classes.allocationRoot}>
          <ShoppingAllocationLegend />
          <NoneHalfAllIndicators />
          {FoodGroups.map((foodGroup) => (
            <div key={foodGroup}>
              <FoodGroupShoppingAllocation
                foodGroup={foodGroup}
                value={shippingAllocationValues[foodGroup]}
                onChange={(value) =>
                  setShippingAllocationValues({
                    ...shippingAllocationValues,
                    [foodGroup]: value,
                  })
                }
              />
            </div>
          ))}
          {(isMobile || isOnboarding || inSidebar) && (
            <FoodGroupLegend
              isOnboarding={isOnboarding}
              inSidebar={inSidebar}
            />
          )}
        </div>
        {!isMobile && !isOnboarding && !inSidebar && (
          <div className={classes.desktopLegendRoot}>
            <FoodGroupLegend isOnboarding={isOnboarding} />
          </div>
        )}
      </div>
    </>
  );
};

export default ShoppingAllocationV2;
