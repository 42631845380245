import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: spacing(3),
    marginLeft: spacing(7),
    "& > *": {
      color: COLOR_GREYSCALE[700],
      backgroundColor: COLOR_GREYSCALE[50],
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      borderRadius: 4,
      padding: spacing(0.5, 1),
      position: "relative",
      "&:after": {
        content: "' '",
        position: "absolute",
        top: "100%",
        left: "50%",
        marginLeft: -6,
        borderWidth: 6,
        borderStyle: "solid",
        borderColor: `${COLOR_GREYSCALE[50]} transparent transparent transparent`,
      },
    },
  },
}));

const NoneHalfAllIndicators = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} aria-hidden={"true"}>
      <Typography variant="body2">None</Typography>
      <Typography variant="body2">Half</Typography>
      <Typography variant="body2">All</Typography>
    </div>
  );
};

export default NoneHalfAllIndicators;
