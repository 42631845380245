import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";

export const ImageCardStepper = ({
  amountOfSteps,
  activeStep,
  handleNext,
  handlePrevious,
}: {
  amountOfSteps: number;
  activeStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    stepperRoot: {
      flexGrow: 1,
      padding: "8px 0px",
      background: "transparent",
      "& .MuiMobileStepper-dots": {
        "& .MuiMobileStepper-dot": {
          marginRight: "10px",
          backgroundColor: "#E1E1E1",
        },
        "& .MuiMobileStepper-dotActive": {
          backgroundColor: DARK_GREEN,
        },
      },
    },
    nextButton: {
      padding: "12px",
      borderRadius: "12px",
      minWidth: "67px",
      "& .MuiButton-label": {
        fontFamily: "'Gilroy-Semibold'",
        fontWeight: "normal",
        textTransform: "none",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
      },
      "&:disabled": {
        color: "white",
        backgroundColor: "rgb(16 66 57 / 60%) !important",
      },
      "&:hover": {},
      "&:focus": {},
    },
    skipButton: {
      opacity: 1,
      visibility: "visible",
      transition: "opacity 0.2s ease",
      background: "transparent",
      boxShadow: "none",
      "& .MuiButton-label": {
        fontSize: "16px",
        letterSpacing: "0.1px",
        lineHeight: "24px",
        fontFamily: "'Gilroy'",
        textTransform: "none",
        color: COLOR_GREYSCALE[700],
      },
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
    hiddenButton: {
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.2s ease-in",
    },
  }));

  const classes = useStyles();
  const disablePrevious = activeStep === 0;

  return (
    <MobileStepper
      variant="dots"
      steps={amountOfSteps}
      position="static"
      activeStep={activeStep}
      className={classes.stepperRoot}
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          color={"primary"}
          className={classes.nextButton}
        >
          {activeStep === amountOfSteps - 1 ? "Finish" : "Next"}
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={handlePrevious}
          className={`${classes.skipButton} ${
            disablePrevious && classes.hiddenButton
          }`}
        >
          Previous
        </Button>
      }
    />
  );
};
