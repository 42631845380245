import {
  Link,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { PageLayout } from "../components-2/page-layout";
import TimeFrameTabs from "../components/TimeFrameTabs";
import { ReactComponent as MainVsSometimesIcon } from "../icons/main-vs-sometimes-icon.svg";
import { ReactComponent as HealthierRecipesIcon } from "../icons/recipe-icon.svg";
import { ReactComponent as FastFoodIcon } from "../icons/fastfood-icon.svg";
import { ReactComponent as HealthierOptionsIcon } from "../icons/healthier-options-icon.svg";
import { ReactComponent as Star } from "../icons/star.svg";

import MainVsSometimesExplainer from "../components-2/main-vs-sometimes/MainVsSometimesExplainer";
import useDiscretionaryPercent from "../hooks/useDiscretionaryPercent";
import { COLOR_GREYSCALE } from "../theme";
import SometimesFoodsBar from "../components-2/main-vs-sometimes/MainVsSometimesFoodsBar";
import Blockquote from "../components-2/Blockquote";
import SometimesFoodsTrends from "../components-2/main-vs-sometimes/MainVsSometimesFoodsTrends";
import HealthierRecipes from "../components/food-groups/healthier-recipes";
import HealthierOptions from "../components-2/healthier-options";
import { ChevronRight } from "@material-ui/icons";
import { HealthierOptionsExplainer } from "../components-2/healthier-options/HealthierOptionsExplainer";
import { useHistory } from "react-router-dom";
import HealthyInspiration from "../components/healthy-inspiration/HealthyInspiration";
import { ifEnterOrSpace } from "../utils/keyboardNavigation";
import RecipeExplainer from "../components-2/recipe/RecipeExplainer";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    [breakpoints.up("md")]: {
      paddingBottom: "40px",
      marginBottom: "40px",
    },
  },
  header: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    padding: "16px 16px 10px",
    fontSize: "16px",
    [breakpoints.up("md")]: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "28px",
      padding: "40px 40px 0px",
    },
  },
  subtitle: {
    color: COLOR_GREYSCALE[900],
    padding: "0px 16px 32px",
    fontFamily: "Gilroy",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    [breakpoints.up("md")]: {
      padding: "8px 40px 32px",
    },
  },
  description: {
    fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    marginBottom: "16px",
    [breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: "24px",
    },
  },
  cardWrapper: {
    padding: "0px 16px",
    [breakpoints.up("md")]: {
      padding: "24px 40px",
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${COLOR_GREYSCALE[200]}`,
      borderRadius: "12px",
      margin: "0px 40px",
    },
  },
  timeFrameTabsContainer: {
    margin: "32px 0 24px 0",
    [breakpoints.up("md")]: {
      margin: "40px 0",
    },
  },
  icons: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  legendContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  legend: {
    borderRadius: "2px",
    width: "19px",
    height: "12px",
  },
  legendText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    color: COLOR_GREYSCALE[900],
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
  },
  cardInformationWrapper: {
    [breakpoints.up("md")]: {
      display: "flex",
    },
  },
  cardInformation: {
    [breakpoints.up("md")]: {
      width: "50%",
      paddingRight: "32px",
    },
  },
  sometimesFoodTrendsContainer: {
    padding: "16px 0px",
    borderTop: `1px solid ${COLOR_GREYSCALE[200]}`,
    [breakpoints.up("md")]: {
      borderLeft: `1px solid ${COLOR_GREYSCALE[200]}`,
      paddingLeft: "32px",
      width: "50%",
      borderTop: "none",
    },
  },
  viewSometimesFood: {
    textDecoration: "none",
    color: COLOR_GREYSCALE[800],
    cursor: "pointer",
    marginTop: "24px",
    background: "#fff",
    padding: "16px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    gap: "8px",
    [breakpoints.up("md")]: {
      marginTop: "40px",
      background: "#FAF5EC",
      fontSize: "16px",
    },
  },
  chevronRight: {
    marginLeft: "auto",
  },
  healthierOptionsContainer: {
    marginTop: "24px",
    marginBottom: "40px",
    [breakpoints.up("md")]: {
      padding: "0px 40px",
      marginTop: "56px",
    },
  },
  titleHeader: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    fontSize: "28px",
    lineHeight: "36px",
    color: COLOR_GREYSCALE[900],
  },
  titleIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    borderRadius: 40,
    marginRight: 8,
    "& svg": {
      width: 30,
      height: 30,
    },
  },
}));

const MainVsSometimesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const { emptyServings, discretionaryPercent } = useDiscretionaryPercent();
  const discPercent = emptyServings ? 100 : discretionaryPercent;
  const goodPercent = emptyServings ? 0 : 100 - discPercent;
  let mainFoodDescription = "";

  if (goodPercent <= 50) {
    mainFoodDescription = `Your split of main foods is ${goodPercent}%. Get more balance by incorporating more of the 5 main food groups.`;
  }
  if (goodPercent >= 71) {
    mainFoodDescription = `Your split of main foods is ${goodPercent}% - above the household average. Well done, you make it look so easy.`;
  }
  if (goodPercent >= 51 && goodPercent <= 70) {
    mainFoodDescription = `You're on a roll with ${goodPercent}% main foods. Keep crowding with the 5 main food groups to improve your split.`;
  }

  const SometimesFoodComponent = () => (
    <Link
      className={classes.viewSometimesFood}
      tabIndex={0}
      onKeyPress={(e) =>
        ifEnterOrSpace(e, history.push, "/food-group/sometimes")
      }
      onClick={() => history.push("/food-group/sometimes")}
    >
      <FastFoodIcon />
      View your Sometimes foods
      <ChevronRight className={classes.chevronRight} />
    </Link>
  );

  const HealthyInspirationComponent = () => (
    <div className={classes.healthierOptionsContainer}>
      <HealthyInspiration source="mainvssometimes" />
    </div>
  );

  return (
    <PageLayout title="Main vs Sometimes foods">
      <div className={classes.timeFrameTabsContainer}>
        <TimeFrameTabs textColorMobile={COLOR_GREYSCALE[600]} />
      </div>

      <div className={classes.root}>
        <div className={classes.header}>
          <MainVsSometimesIcon />
          Main vs sometimes foods
          <MainVsSometimesExplainer />
        </div>
        {!isMobile && (
          <div className={classes.subtitle}>
            Main foods support health and wellbeing. Sometimes foods are okay to
            eat in moderation, as part of a balanced and nutritious diet.
          </div>
        )}

        <div className={classes.cardWrapper}>
          <div className={classes.cardInformationWrapper}>
            <div className={classes.cardInformation}>
              <div className={classes.description}>
                Shows the split between main and sometimes foods based on your
                recent Woolworths shops.
              </div>

              <div>
                <SometimesFoodsBar
                  emptyServings={emptyServings}
                  discretionaryPercent={discretionaryPercent}
                />
              </div>

              <div className={classes.icons}>
                <div className={classes.legendContainer}>
                  <div
                    className={classes.legend}
                    style={{ background: "#104239" }}
                  ></div>
                  <span className={classes.legendText}>Main foods</span>
                </div>
                <div className={classes.legendContainer}>
                  <span className={classes.legendText}>Sometimes foods</span>
                  <div
                    className={classes.legend}
                    style={{ background: "#FFD60A" }}
                  ></div>
                </div>
              </div>

              <div className={classes.icons} style={{ paddingBottom: "16px" }}>
                <Blockquote icon={<Star />} text={mainFoodDescription} />
              </div>
            </div>

            <div className={classes.sometimesFoodTrendsContainer}>
              <SometimesFoodsTrends />
            </div>
          </div>
          {!isMobile && <SometimesFoodComponent />}
        </div>

        {!isMobile && (
          <>
            <div className={classes.healthierOptionsContainer}>
              <Typography
                variant="h3"
                component="h1"
                className={classes.titleHeader}
              >
                <HealthierRecipesIcon className={classes.titleIcon} />
                <Link
                  tabIndex={0}
                  onKeyPress={(e) =>
                    ifEnterOrSpace(e, history.push, "/recipes")
                  }
                  onClick={() => history.push("/recipes")}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                  }}
                >
                  <span style={{ display: "inline-block", marginRight: 4 }}>
                    Healthier Recipes
                  </span>
                </Link>
                <RecipeExplainer />
              </Typography>
              <HealthierRecipes showHeader={false} />
            </div>

            <div className={classes.healthierOptionsContainer}>
              <Typography
                variant="h3"
                component="h1"
                className={classes.titleHeader}
              >
                <HealthierOptionsIcon className={classes.titleIcon} />
                <Link
                  tabIndex={0}
                  onKeyPress={(e) =>
                    ifEnterOrSpace(e, history.push, "/healthier-options/plp")
                  }
                  onClick={() => history.push("/healthier-options/plp")}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                  }}
                >
                  <span style={{ display: "inline-block", marginRight: 4 }}>
                    Healthier Options
                  </span>
                </Link>
                <HealthierOptionsExplainer source="main-vs-sometimes" />
              </Typography>
              <HealthierOptions
                marginTop={24}
                showHeader={isMobile}
                source="main-vs-sometimes"
              />
            </div>
          </>
        )}

        {!isMobile && <HealthyInspirationComponent />}
      </div>

      {isMobile && (
        <>
          <SometimesFoodComponent />
          <HealthierRecipes />
          <HealthierOptions source="main-vs-sometimes" />
          <HealthyInspirationComponent />
        </>
      )}
    </PageLayout>
  );
};
export default MainVsSometimesPage;
