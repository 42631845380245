import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import FruitAndVegChallengeExplainer from "./FruitAndVegChallengeExplainer";
import { COLOR_GREYSCALE, COLOR_SUPPORT_EVERYDAY_REWARDS } from "../../theme";
import { ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import FruitAndVegChallengeCompleted from "./FruitAndVegChallengeCompleted";
import { formatGMTToSydneyISO, getSydneyISOTime } from "../../utils/dates";
import { POINTS_PROMO_END_DATE } from "../../constants";
import React, { useContext } from "react";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";
import { FruitAndVegCampaignContext } from "../../components/providers/FruitAndVegCampaignPurchasesProvider";
import useMember from "../../hooks/useMember";

export function addTwoWeeks(dateString: string) {
  const date = new Date(dateString);
  const twoWeeks = 14 * 24 * 60 * 60 * 1000;
  const newDate = new Date(date.getTime() + twoWeeks);
  return newDate.toUTCString();
}

const FruitAndVegChallengeJoined = () => {
  const { member } = useMember();
  const { totalPoints } = useContext(FruitAndVegCampaignContext);
  const hasPoints = totalPoints > 0;
  const currDate = getSydneyISOTime();
  const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE!);
  const promoEndDatePlus2Weeks = formatGMTToSydneyISO(
    addTwoWeeks(POINTS_PROMO_END_DATE!)
  );
  const completedChallenge =
    currDate >= promoEndDate && currDate <= promoEndDatePlus2Weeks;

  const challengeFinished = currDate >= promoEndDate;
  const challengeFinishedAndAlreadyJoinedLeaderboard =
    challengeFinished &&
    (member?.joinedFruitAndVegMayCampaignLeaderboard ?? false);
  const challengeStillRunning = !challengeFinished;
  const showLeaderboardText =
    challengeStillRunning || challengeFinishedAndAlreadyJoinedLeaderboard;

  const { track } = useEvents();

  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "12px",
      padding: "16px",
      background: "white",
      boxShadow: "0px 1px 2px 0px #0000001A",
      position: "relative",
      overflow: "hidden",
      [breakpoints.up("md")]: {
        marginBottom: "12px",
        padding: "24px",
      },
      [`@media (min-width: ${1024}px) and (max-width: ${1162}px)`]: {
        marginBottom: "48px",
      },
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "0px",
      paddingBottom: "6px",
      borderBottom: "1px solid #D3D6D9",
      width: "100%",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      fontSize: "18px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      zIndex: 1,
      marginRight: 4,
      [breakpoints.up("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
    fruitAndVegChallengeTitleContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: -1,
    },
    challengeText: {
      zIndex: 2,
      position: "relative",
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 98,
      height: 12,
      zIndex: 1,
      [breakpoints.up("md")]: {
        width: 100,
        height: 15,
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    pointDescription: {
      display: "flex",
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      flexGrow: 1,
      paddingRight: !hasPoints ? "42px" : "0px",
      flexShrink: hasPoints ? 0 : 1,
      [breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    pointsContainer: {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      flex: 1,
      [breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
    },
    points: {
      flexGrow: hasPoints ? 1 : 0,
      flexShrink: hasPoints ? 0 : 1,
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_SUPPORT_EVERYDAY_REWARDS[500],
      textAlign: "center",
      margin: 0,
      paddingLeft: "16px",
      "& span": {
        fontFamily: "Gilroy-Bold",
        fontSize: "36px",
        lineHeight: "25px",
      },
      [breakpoints.up("md")]: {
        flexGrow: 0,
      },
    },
    showDetailContainer: {},
    showDetailBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
      border: "none",
      width: "100%",
      textAlign: "center",
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
    },
    showDetailChevron: {
      transform: `rotate(90deg)`,
    },
    paragraph: {
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      margin: "16px 0",
      [breakpoints.up("md")]: {
        maxWidth: "100%",
      },
    },
    paragraphOrangeText: {
      color: "#FD6400",
    },
    purchasedItemsHeading: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    purchasedItems: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      margin: 0,
    },
    purchasedItemsViewAll: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      background: "transparent",
      border: "none",
      textAlign: "center",
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      textDecoration: "none",
      color: COLOR_GREYSCALE[900],
    },
    tac: {
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: "16px 0",
    },
    link: {
      color: "#60A498",
      textDecoration: "underline",
    },
    viewAllText: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "fit-content",
      columnGap: "8px",
      textDecoration: "none",
      [breakpoints.up("md")]: {
        fontSize: "13px",
      },
    },
    viewAllBtn: {
      display: "flex",
      width: "100%",
      marginTop: "14px",
      paddingTop: "4px",
      borderTop: "solid 1px #E9EBEC",
      boxSizing: "border-box",
      textDecoration: "none",
      color: COLOR_GREYSCALE[900],
    },
  }));

  const classes = useStyles();

  if (completedChallenge) {
    return (
      <FruitAndVegChallengeCompleted
        totalPoints={totalPoints}
        showLeaderboardText={showLeaderboardText}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.headerTitle}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <div className={classes.fruitAndVegChallengeTitleContainer}>
              <GreenRectangle className={classes.greenRectangle} />
              <span className={classes.challengeText}>Fruit &amp; Veg</span>
            </div>{" "}
            Challenge!
          </div>
          <FruitAndVegChallengeExplainer />
        </div>
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "14px",
        }}
      >
        <div>
          <div className={classes.textContainer}>
            <div className={classes.pointDescription}>
              {hasPoints
                ? "You have collected a total of:"
                : "Shop any product containing a serve of fruit or veg - that’s anything from apples to frozen peas and even tinned beans."}
            </div>
          </div>
          {hasPoints && (
            <div className={classes.showDetailContainer}>
              <Link
                to="/fruit-and-veg-challenge"
                className={classes.viewAllBtn}
                onClick={() => {
                  track(HOMEPAGE_EVENT.VIEW_PURCHASES_AND_LEADERBOARD);
                }}
              >
                <p className={classes.viewAllText}>
                  View purchases & leaderboard
                </p>
                <ChevronRight
                  style={{
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Link>
            </div>
          )}
        </div>
        <div className={classes.pointsContainer}>
          <div className={classes.points}>
            <span>{hasPoints ? totalPoints : "0"}</span>
            <br />
            bonus points
          </div>
        </div>
      </div>
    </div>
  );
};

export default FruitAndVegChallengeJoined;
