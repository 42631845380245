import makeStyles from "@material-ui/core/styles/makeStyles";
import { FC } from "react";

interface Props {
  color: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: 20,
    height: 10,
    borderRadius: 5,
    marginRight: spacing(1),
  },
}));

const LegendDot: FC<Props> = ({ color }) => {
  const classes = useStyles();
  return <div className={classes.root} style={{ backgroundColor: color }} />;
};

export default LegendDot;
