import { CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  actions: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: theme.spacing(2.5),
  },
  actionButton: {
    fontSize: 12,
    padding: "2px 6px",
    textTransform: "none",
    "&:not(:first-child)": {
      marginRight: theme.spacing(),
    },
  },
  progress: {
    marginLeft: 2,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}));

interface Props {
  title: string;
  currentStep: number;
  totalSteps: number;
  onAcknowledge: () => Promise<void>;
  onBack: () => void;
}

const NewFeatureContent: FC<Props> = ({
  title,
  currentStep,
  totalSteps,
  onAcknowledge,
  onBack,
  children,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleAcknowledge = async () => {
    try {
      setLoading(true);
      await onAcknowledge();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography className={classes.title} variant="h5">
        {title}{" "}
        {totalSteps > 1 && (
          <span className={classes.progress}>
            ({currentStep} of {totalSteps})
          </span>
        )}
      </Typography>
      <div>{children}</div>
      <div className={classes.actions}>
        <Button
          className={classes.actionButton}
          size="small"
          variant="outlined"
          color="inherit"
          disabled={loading}
          startIcon={loading && <CircularProgress color="inherit" size={12} />}
          onClick={async (e) => {
            e.stopPropagation();
            await handleAcknowledge();
          }}
        >
          {currentStep < totalSteps ? "Next" : "Done"}
        </Button>
        {totalSteps > 1 && currentStep > 1 && (
          <Button
            className={classes.actionButton}
            size="small"
            variant="text"
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              onBack();
            }}
          >
            Back
          </Button>
        )}
      </div>
    </div>
  );
};

export default NewFeatureContent;
