import { FC, useEffect, useState } from "react";
import CenteredLoadingIndicator from "./CenteredLoadingIndicator";
import ContentfulRichText from "./ContentfulRichText";
import {
  fetchTermsAndConditions,
  TermsAndConditionsContent,
} from "../services/contentful/termsandconditions";

const TermsAndConditions: FC = () => {
  const [doc, setDoc] = useState<TermsAndConditionsContent | null>(null);

  useEffect(() => {
    const init = async () => {
      setDoc(await fetchTermsAndConditions());
    };

    init();
  }, []);

  return doc ? (
    <ContentfulRichText content={doc?.content.json} />
  ) : (
    <CenteredLoadingIndicator loading />
  );
};

export default TermsAndConditions;
