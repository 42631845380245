import { Duration } from "luxon";
import { useContext, useEffect, useState } from "react";
import { MemberContext } from "../components/providers/MemberProvider";
import { AggregateDailyServings, Servings } from "../models";
import {
  accumulateDailyServings,
  addRealtimeServings,
  EMPTY_SERVINGS,
  subscribeToRealTimeServings,
  subscribeToServings,
} from "../services/servings";
import { endOfYesterday } from "../utils/dates";
import { RealtimeListContext } from "../components/providers/RealtimeListProvider";
import { SelectedTimeFrameBasketExclusionsContext } from "../components/providers/SelectedTimeFrameBasketExclusionsProvider";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";

const until = endOfYesterday();

export default function useServingsUntilYesterday(timeFrame: Duration) {
  const { memberId, shoppingAllocation } = useContext(MemberContext)! || {};
  const { exclusions } = useContext(SelectedTimeFrameBasketExclusionsContext);
  const { realtimeList } = useContext(RealtimeListContext);
  const { collected, featureFlags } = useContext(FeatureFlagContext);

  const [loading, setLoading] = useState(true);
  const [servingsPurchased, setServingsPurchased] = useState<
    AggregateDailyServings[]
  >([]);
  const [adjustedServingsPurchased, setAdjustedServingsPurchased] =
    useState<Servings>(EMPTY_SERVINGS);

  useEffect(() => {
    if (!memberId || !collected) return;

    if (
      featureFlags?.realTimeData?.enable?.everybody === true ||
      featureFlags?.realTimeData?.enable?.specificMembers?.includes(memberId)
    ) {
      const unsubscribe = subscribeToRealTimeServings(
        memberId,
        timeFrame,
        until,
        (changes) => {
          setServingsPurchased(changes);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );

      return () => {
        unsubscribe();
      };
    } else {
      const unsubscribe = subscribeToServings(
        memberId,
        timeFrame,
        until,
        (changes) => {
          setServingsPurchased(changes);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [
    collected,
    featureFlags?.realTimeData?.enable?.everybody,
    featureFlags?.realTimeData?.enable?.specificMembers,
    memberId,
    timeFrame,
  ]);

  useEffect(() => {
    if (!shoppingAllocation) return;
    const allServings = addRealtimeServings(servingsPurchased, realtimeList);
    const servings = accumulateDailyServings(
      allServings,
      exclusions,
      shoppingAllocation
    );

    setAdjustedServingsPurchased(servings);
  }, [servingsPurchased, realtimeList, shoppingAllocation, exclusions]);

  return {
    servingsPurchased: adjustedServingsPurchased,
    loadingServings: loading,
  };
}
