import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as Star } from "../../icons/star.svg";

import useDiscretionaryPercent from "../../hooks/useDiscretionaryPercent";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as MainVsSometimesIcon } from "../../icons/main-vs-sometimes-icon.svg";
import SometimesFoodsBar from "./MainVsSometimesFoodsBar";
import { COLOR_GREYSCALE } from "../../theme";

import MainVsSometimesExplainer from "./MainVsSometimesExplainer";
import Blockquote from "../Blockquote";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  cardWrapper: {
    background: "white",
    position: "relative",
    padding: "16px",
    marginBottom: "16px",
    borderRadius: "12px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    [breakpoints.up("md")]: {
      marginBottom: "0px",
      marginTop: "16px",
      padding: "24px",
      width: "100%",
    },
  },
  root: {
    marginTop: spacing(2),
  },
  icons: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionText: {
    fontFamily: "Gilroy",
    color: COLOR_GREYSCALE[700],
    marginTop: spacing(2),
    letterSpacing: "0.3px",
    fontSize: 14,
  },
  header: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${COLOR_GREYSCALE[200]}`,
    paddingBottom: "10px",
    gap: "4px",
  },
  title: {
    margin: "0px",
    fontSize: "16px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    color: "#1D1F29",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    [breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
    },
  },
  legendContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  legend: {
    borderRadius: "2px",
    width: "19px",
    height: "12px",
  },
  legendText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    textDecoration: "none",
    color: COLOR_GREYSCALE[900],
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
  },
}));

const MainVsSometimesComponent: FC = () => {
  const classes = useStyles();
  const { track } = useEvents();
  const { emptyServings, discretionaryPercent } = useDiscretionaryPercent();

  const discPercent = emptyServings ? 100 : discretionaryPercent;
  const goodPercent = emptyServings ? 0 : 100 - discPercent;
  let mainFoodDescription = "";

  if (goodPercent <= 50) {
    mainFoodDescription = `Your split of main foods is ${goodPercent}%. Get more balance by incorporating more of the 5 main food groups.`;
  }
  if (goodPercent >= 71) {
    mainFoodDescription = `Your split of main foods is ${goodPercent}% - above the household average. Well done, you make it look so easy.`;
  }
  if (goodPercent >= 51 && goodPercent <= 70) {
    mainFoodDescription = `You're on a roll with ${goodPercent}% main foods. Keep crowding with the 5 main food groups to improve your split.`;
  }

  return (
    <div className={classes.cardWrapper}>
      <div className={classes.header}>
        <Link
          to="/main-vs-sometimes"
          aria-label="Go to Main Vs Sometimes Page"
          style={{ textDecoration: "none", color: COLOR_GREYSCALE[800] }}
          onClick={() => {
            track(HOMEPAGE_EVENT.MAIN_SOMETIMES_MAIN_CLICK);
          }}
        >
          <h1 className={classes.title}>
            <MainVsSometimesIcon /> Main vs sometimes foods
          </h1>
        </Link>
        <MainVsSometimesExplainer />
        <Link
          to="/main-vs-sometimes"
          aria-label="Go to Main Vs Sometimes Page"
          style={{
            textDecoration: "none",
            color: COLOR_GREYSCALE[800],
            marginLeft: "auto",
          }}
          onClick={() => {
            track(HOMEPAGE_EVENT.MAIN_SOMETIMES_NEXT);
          }}
        >
          <ChevronRight />
        </Link>
      </div>
      <div className={classes.descriptionText}>
        <p>
          {emptyServings
            ? `Bummer! We don’t have anything to show you at the moment. Check back once you’ve shopped.`
            : `The split between main and sometimes foods based on your recent
          Woolworths shops`}
        </p>
      </div>
      <div className={classes.root}>
        <div>
          <SometimesFoodsBar
            emptyServings={emptyServings}
            discretionaryPercent={discretionaryPercent}
          />
        </div>

        <div className={classes.icons}>
          {emptyServings ? (
            <div className={classes.legendContainer}>
              <div
                className={classes.legend}
                style={{ background: COLOR_GREYSCALE[200] }}
              ></div>
              <span className={classes.legendText}>No Data</span>
            </div>
          ) : (
            <>
              <div className={classes.legendContainer}>
                <div
                  className={classes.legend}
                  style={{ background: "#104239" }}
                ></div>
                <Link
                  to="/main-vs-sometimes"
                  aria-label="Go to Main Vs Sometimes Page"
                  className={classes.legendText}
                  onClick={() => {
                    track(HOMEPAGE_EVENT.MAIN_SOMETIMES_MAIN_CLICK);
                  }}
                >
                  <span>Main foods</span>
                </Link>
              </div>
              <div className={classes.legendContainer}>
                <Link
                  to="/food-group/sometimes"
                  aria-label="Go toSometimes Page"
                  className={classes.legendText}
                  onClick={() => {
                    track(HOMEPAGE_EVENT.MAIN_SOMETIMES_SOMETIMES_CLICK);
                  }}
                >
                  <span>Sometimes foods</span>
                </Link>
                <div
                  className={classes.legend}
                  style={{ background: "#FFD60A" }}
                ></div>
              </div>
            </>
          )}
        </div>

        {emptyServings ? null : (
          <div className={classes.icons}>
            <Blockquote icon={<Star />} text={mainFoodDescription} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainVsSometimesComponent;
