import { FC, MouseEvent } from "react";
import { Link, LinkProps } from "@material-ui/core";
import useEvents from "../hooks/useEvents";

const MS_DELAY_TO_ALLOW_FOR_TRACK_TO_COMPLETE = 300;

const ExternalLink: FC<LinkProps> = ({
  children,
  href,
  target,
  ...otherProps
}) => {
  const { track } = useEvents();

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();

    track("ExternalLink Clicked", { url: href });

    setTimeout(() => {
      window.open(href, target);
    }, MS_DELAY_TO_ALLOW_FOR_TRACK_TO_COMPLETE);
  };

  return (
    <Link onClick={handleClick} href={href} {...otherProps}>
      {children}
    </Link>
  );
};

export default ExternalLink;
