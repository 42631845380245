import { ReactElement } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  whenFalse: boolean;
  redirectTo: string;
  Page: ReactElement;
}

export default function MemberRoute({
  whenFalse,
  redirectTo,
  Page,
  ...rest
}: Props) {
  return (
    <Route
      {...rest}
      render={() => (whenFalse ? Page : <Redirect to={redirectTo} />)}
    />
  );
}
