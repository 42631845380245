import useHideDietaryPreferences from "./useHideDietaryPreferences";

type StepTitle = {
  title: string;
  disableSkip: boolean;
};

type StepTitles = {
  [key: number]: StepTitle;
};

export default function useOnboardingStepper() {
  const hideDp = useHideDietaryPreferences();
  const STEP_TITLES: StepTitles = hideDp
    ? {
        1: {
          title: "Household setting",
          disableSkip: true,
        },
        2: {
          title: "Shopping elsewhere?",
          disableSkip: true,
        },
        3: {
          title: "Health goals",
          disableSkip: false,
        },
        4: {
          title: "Communication",
          disableSkip: false,
        },
      }
    : {
        1: {
          title: "Household setting",
          disableSkip: true,
        },
        2: {
          title: "Dietary preferences?",
          disableSkip: false,
        },
        3: {
          title: "Shopping elsewhere?",
          disableSkip: true,
        },
        4: {
          title: "Health goals",
          disableSkip: false,
        },
        5: {
          title: "Communication",
          disableSkip: false,
        },
      };

  return {
    STEP_TITLES,
  };
}
