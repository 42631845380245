import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
} from "../InfoModal";
import React from "react";
import { GOALS_EVENTS } from "../../events";
export const GoalsExplainer = () => {
  return (
    <InfoModalContainer
      eventName={GOALS_EVENTS.MODAL_OPEN}
      eventCloseName={GOALS_EVENTS.MODAL_CLOSED}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalContent>
        Want to eat more veggies or focus on your fun food portions? Your goals
        are here to help you keep track of what’s important to you.
      </InfoModalContent>
      <InfoModalContent>
        Once you’ve selected your goal(s), Food Tracker will prioritise the
        relevant information and insights, so you can easily see how you’re
        tracking!
      </InfoModalContent>
      <InfoModalContent>
        If you are seeing the 'No data' message it means that you have not
        shopped in the previous and/or current period. Check back once you've
        shopped.
      </InfoModalContent>
    </InfoModalContainer>
  );
};
