import {
  Avatar,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { BLACK, COLOR_GREYSCALE } from "../../theme";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y } from "swiper";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";

SwiperCore.use([A11y]);

type FeatureWithRoute = {
  title: string;
  icon: React.ReactElement;
  route: string;
  scrollId?: never;
};

type FeatureWithScrollId = {
  title: string;
  icon: React.ReactElement;
  route?: never;
  scrollId: string;
};

type Feature = FeatureWithRoute | FeatureWithScrollId;

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    color: BLACK,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  featuresContainer: {
    position: "relative",
    "& .swiper": {
      overflow: "visible",
      [breakpoints.up("md")]: {
        overflow: "hidden",
      },
    },
    "& .swiper-wrapper": {
      paddingBottom: "2px",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > *:not(:last-child)": {
        marginRight: "32px",
      },
    },
  },
  featureContainer: {
    border: `1px solid ${COLOR_GREYSCALE[100]}`,
    borderRadius: "8px",
    padding: "8px",
    backgroundColor: "white",
    boxShadow: "0px 4px 16px 0px #0000000D",
    cursor: "pointer",
    display: "flex",
    [breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    [breakpoints.up("md")]: {
      flexWrap: "wrap",
      flexDirection: "column",
      height: "110px",
      justifyContent: "space-between",
    },
  },
  iconContainer: {
    [breakpoints.down("sm")]: {
      marginRight: "8px",
    },
    [breakpoints.up("md")]: {
      width: "fit-content",
    },
  },
  avatar: {
    background: "#F3E9D3",
  },
  featureText: {
    fontFamily: "'Gilroy-Semibold'",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    letterSpacing: "0.1px",
    color: COLOR_GREYSCALE[700],
    margin: 0,
    [breakpoints.up("md")]: {
      width: "fit-content",
    },
  },
}));

const FeatureBox = ({ feature }: { feature: Feature }) => {
  const history = useHistory();
  const { track } = useEvents();
  const goToFeature = (feature: Feature) => {
    track(HOMEPAGE_EVENT.QUICKFEATURE_SELECT);
    if (feature.title === "Healthier Options") {
      track(HOMEPAGE_EVENT.QUICKFEATURE_HEALTHIER_OPTIONS);
    } else if (feature.title === "Recipes") {
      track(HOMEPAGE_EVENT.QUICKFEATURE_RECIPES);
    } else if (feature.title === "Nutrient Tracker") {
      track(HOMEPAGE_EVENT.QUICKFEATURE_NUTRIENT_TRACKER);
    } else if (feature.title === "Main vs Sometimes") {
      track(HOMEPAGE_EVENT.QUICKFEATURE_MAIN_SOMETIMES);
    } else if (feature.title === "Healthy Inspiration") {
      track(HOMEPAGE_EVENT.QUICKFEATURE_HEALTHY_INSPIRATIONS);
    }

    if (feature.route) {
      history.push(feature.route);
    } else if (feature.scrollId) {
      const element = document.getElementById(feature.scrollId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const classes = useStyles();
  return (
    <Grid
      tabIndex={0}
      container
      className={classes.featureContainer}
      onClick={() => goToFeature(feature)}
      onKeyPress={(e) => ifEnterOrSpace(e, goToFeature, feature)}
    >
      <Grid item className={classes.iconContainer}>
        <Avatar className={classes.avatar}>{feature.icon}</Avatar>
      </Grid>
      <Grid item>
        <Typography className={classes.featureText}>{feature.title}</Typography>
      </Grid>
    </Grid>
  );
};

const FeatureCarousel = ({ features }: { features: Feature[] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { track } = useEvents();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [slideTracked, setSlideTracked] = useState(false);
  const handleSlideScrollEvent = () => {
    if (slideTracked) return;
    setSlideTracked(true);
    track(HOMEPAGE_EVENT.QUICKFEATURE_SCROLLED);
  };
  return (
    <div className={classes.root}>
      <div className={classes.featuresContainer}>
        {isMobile ? (
          <Swiper
            spaceBetween={16}
            slidesPerView={2.1}
            allowTouchMove={true}
            onSliderFirstMove={handleSlideScrollEvent}
            watchSlidesProgress={true}
            breakpoints={{
              400: {
                slidesPerView: 2.3,
              },
            }}
          >
            {features.map((feature, index) => (
              <SwiperSlide key={feature.title}>
                <FeatureBox feature={feature} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            {features.map((feature) => (
              <FeatureBox feature={feature} key={feature.title} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FeatureCarousel;
