import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Collect1000PointsIcon } from "../../icons/collect-1000-points-circle.svg";
import { Typography } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";
import ExternalLink from "../../components/ExternalLink";

const TermsAndConditions = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    tac: {
      marginTop: "4px",
      color: "#585C60",
      fontFamily: "Gilroy",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    link: {
      color: "#585C60",
      textDecoration: "underline",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <p className={classes.tac}>
        Offer starts 00:01 AEDT 08/07/2024 and ends 23:59 AEDT 04/08/2024.{" "}
        <ExternalLink
          href={"https://www.healthylife.com.au/discover/food-tracker"}
          target="_blank"
          className={classes.link}
        >
          T&Cs apply.
        </ExternalLink>
      </p>
    </>
  );
};

export const WowEmailCampaignBanner: FC = () => {
  const useStyles = makeStyles(() => ({
    root: {
      marginBottom: "8px",
      borderRadius: "12px",
      paddingTop: "8px",
      border: "1px solid #FD6400",
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    root2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    icon: {
      width: "72px",
      height: "76px",
    },
    header: {
      fontFamily: "Gilroy-Semibold",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      margin: 0,
    },
    body: {
      fontFamily: "Gilroy",
      fontSize: "12px",
      letterSpacing: "0.3px",
      lineHeight: "16px",
      color: COLOR_GREYSCALE[900],
      margin: 0,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.root2}>
        <div>
          <Typography variant={"h3"} className={classes.header}>
            Almost there!
          </Typography>
          <Typography variant={"body2"} className={classes.body}>
            Enter your email and subscribe to expert advice to receive your
            points. It’s win win!
          </Typography>
        </div>
        <div>
          <Collect1000PointsIcon className={classes.icon} />
        </div>
      </div>
      <TermsAndConditions />
    </div>
  );
};
