import { AgeRange, Gender, HealthCondition } from "../../models";
import { mapToAgeRange } from "./agerange";
import { mapToGender } from "./gender";
import {
  HEALTH_CONDITIONS_OPTIONS,
  PRECEDENCE_MAP,
} from "./hardcodedContentfulContent";

export const listHealthConditionOptions = async (
  ageRange: AgeRange,
  gender: Gender
): Promise<HealthCondition[]> => {
  const precedence = await getPrecedenceMap();
  // Used to fetched from Contentful, now hard coded.
  const { items } = HEALTH_CONDITIONS_OPTIONS.data.healthConditionCollection;

  return items
    .map((item: any) => mapToHealthCondition(item, precedence))
    .filter(
      (item: HealthCondition) =>
        item.enabled &&
        item.applicableAgeRanges.find((val) => val.id === ageRange.id) &&
        item.applicableGenders.find((val) => val.id === gender.id)
    );
};

export const mapToHealthCondition = (
  item: any,
  precedenceMap: PrecedenceMap
): HealthCondition => ({
  id: item.sys.id,
  name: item.name,
  applicableAgeRanges:
    item.applicableAgeRangesCollection.items.map(mapToAgeRange),
  applicableGenders: item.applicableGendersCollection.items.map(mapToGender),
  precedence: precedenceMap[item.sys.id],
  consentCopy: item.consentCopy,
  enabled: item.enabled,
});

interface PrecedenceMap {
  [id: string]: number;
}

export const getPrecedenceMap = async () => {
  // Used to fetched from Contentful, now hard coded.
  const { items } =
    PRECEDENCE_MAP.data.healthConditionPrecedence.precedenceCollection;

  return items.reduce((map: PrecedenceMap, item: any, index: number) => {
    return { ...map, [item.sys.id]: index };
  }, {} as PrecedenceMap);
};
