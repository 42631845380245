import { Typography, makeStyles } from "@material-ui/core";
import { FC, cloneElement, useContext, useState } from "react";
import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalSubTitle,
  InfoModalTitle,
} from "../../components-2/InfoModal";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../../components-2/Accordion";
import { FAQ_ACCORDION_EVENT, HOMEPAGE_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";
import {
  DIETARY_PREFERENCES,
  DIETARY_PREFERENCES_DATA,
} from "../../components-2/dietary-preference/config";
import { ChevronRight } from "@material-ui/icons";
import { DietaryPreferencesContext } from "../providers/DietaryPreferencesProvider";
import { COLOR_GREYSCALE } from "../../theme";

const BalanceOfShopV2DietaryPreferenceExplainer: FC = () => {
  const { track } = useEvents();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const { checked } = useContext(DietaryPreferencesContext);

  const faqTitles = [
    "Dairy free",
    "Gluten free",
    "Lactose free",
    "Vegan",
    "Vegetarian",
  ];

  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = makeStyles(() => ({
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
    },
    accordionContent: {
      marginTop: 0,
      "& a": {
        color: "#60A498",
      },
    },
    titleIcon: {
      marginRight: "8px",
    },
    explainerText: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      columnGap: "8px",
    },
    explainerBtn: {
      display: "flex",
      justifyContent: "space-between",
      columnGap: "8px",
      width: "100%",
    },
  }))();

  return (
    <InfoModalContainer
      eventName={HOMEPAGE_EVENT.DIETARY_PREFERENCE_HELP_OPEN}
      eventCloseName={HOMEPAGE_EVENT.DIETARY_PREFERENCE_HELP_CLOSE}
      button={
        <div className={classes.explainerBtn}>
          <p className={classes.explainerText}>
            Find out how this impacts your Food Tracker
          </p>
          <ChevronRight />
        </div>
      }
    >
      <InfoModalTitle>Dietary Preferences</InfoModalTitle>
      <AccordionContainer>
        {checked.includes(DIETARY_PREFERENCES.DAIRY_FREE) && (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AccordionIcon />}
            >
              {cloneElement(DIETARY_PREFERENCES_DATA["dairy_free"].icon, {
                width: 24,
                height: 24,
                className: classes.titleIcon,
              })}{" "}
              Dairy free
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  If you’re looking for dairy free options and want to make sure
                  you're hitting your recommended serves, you're in the right
                  place. We've got the lowdown on what you need to know.
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                Calcium fortified alternatives
              </InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Calcium fortified plant-based milk alternatives still
                      count towards the Dairy food group.
                    </li>
                    <li>
                      Look for calcium fortified plant milks and yoghurts such
                      as almond, soy or oat milk. Where possible, choose
                      products fortified with at least 100mg calcium per 100mL.
                    </li>
                    <li>1 cup of calcium fortified plant milk is 1 serve.</li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>Naturally dairy free foods</InfoModalSubTitle>

              <InfoModalContent>
                <p>
                  Many of the main food groups contain naturally dairy free
                  foods. These include:
                </p>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>Fresh fruit</li>
                    <li>Fresh vegetables</li>
                    <li>
                      Unprocessed poultry and meat, eggs, legumes, nuts and
                      seeds.
                    </li>
                    <li>Wholegrains</li>
                  </ul>
                </Typography>
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}

        {checked.includes(DIETARY_PREFERENCES.GLUTEN_FREE) && (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<AccordionIcon />}
            >
              {cloneElement(DIETARY_PREFERENCES_DATA["gluten_free"].icon, {
                width: 24,
                height: 24,
                className: classes.titleIcon,
              })}{" "}
              Gluten free
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  You can still get the gains from grains even when following a
                  gluten free diet. Let us show you how to choose a variety of
                  gluten free grains, to help you get the wonderful benefits of
                  folate, iron, B vitamins and keep your dietary fibre in check!
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> If following a gluten free diet,
                  it’s important to choose gluten free grain options but don’t
                  forget the other main food groups: Fruit, Vegetables &
                  legumes, Meat & alternatives, Dairy & alternatives.
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                Get the gains from gluten free grains
              </InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Choose wholegrain, gluten free cereals and breads. Look
                      for the words “gluten free” and “wholegrain” or
                      “wholemeal” on pack.
                    </li>
                    <li>
                      Look for grains that are gluten free, such as corn/maize,
                      rice, quinoa, buckwheat, millet, amaranth, soy and legume
                      flours.
                    </li>
                    <li>
                      Use the “Gluten free” filter when shopping for your grains
                      on Woolworths online.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>Naturally gluten free foods</InfoModalSubTitle>

              <InfoModalContent>
                <p>
                  Many of the main food groups contain naturally gluten free
                  foods. These include:
                </p>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>Fresh fruit</li>
                    <li>Fresh vegetables</li>
                    <li>
                      Unprocessed poultry and meat, eggs, legumes, nuts and
                      seeds.
                    </li>
                    <li>
                      Dairy foods and alternatives such as milk, cheese and
                      yoghurt.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> Look for “gluten free” on the
                  product pack.
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  Important note: If you think gluten may be affecting your
                  health, it’s important to discuss this with a healthcare
                  professional.
                </Typography>
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}

        {checked.includes(DIETARY_PREFERENCES.LACTOSE_FREE) && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3", 2)}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<AccordionIcon />}
              className=""
            >
              {cloneElement(DIETARY_PREFERENCES_DATA["lactose_free"].icon, {
                width: 24,
                height: 24,
                className: classes.titleIcon,
              })}{" "}
              Lactose free
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  Lactose intolerance might mean you are scanning the dairy
                  aisle for lactose free options. Lactose intolerance doesn’t
                  mean you have to avoid all dairy foods, it’s more about which
                  foods and quantity you can tolerate. Let’s get moo-ving on
                  what you need to know.
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                What is lactose intolerance?
              </InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Lactose is a natural sugar in dairy foods that is digested
                      by an enzyme called lactase.
                    </li>
                    <li>
                      Lactose intolerance may happen when the body doesn’t
                      produce enough lactase enzyme.
                    </li>
                    <li>
                      The undigested lactose travels to the large intestine
                      where it may cause gut symptoms like bloating, diarrhoea
                      or gas.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                Look for lactose free options
              </InfoModalSubTitle>

              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Lactose free milk, yoghurt and soft cheeses contain the
                      goodness of dairy but without the lactose. These products
                      are usually produced by adding the enzyme lactase to break
                      down the lactose so they don’t cause stomach upset. Look
                      out for lactose-free on the label.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>

              <InfoModalSubTitle>Lower lactose options</InfoModalSubTitle>

              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Yoghurt is usually lower in lactose as the bacteria (good
                      gut bugs) help to break it down.
                    </li>
                    <li>
                      Hard cheeses (such as cheddar) are very low in lactose and
                      therefore may be tolerated.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> Sometimes it’s about the quantity
                  over time. Spread lactose containing foods out during the day
                  or eat them with other foods to slow down digestion.
                </Typography>
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}

        {checked.includes(DIETARY_PREFERENCES.VEGAN) && (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordionChange("panel4", 3)}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<AccordionIcon />}
            >
              {cloneElement(DIETARY_PREFERENCES_DATA["vegan"].icon, {
                width: 24,
                height: 24,
                className: classes.titleIcon,
              })}{" "}
              Vegan
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  If you're into the whole plant-based vibe and want to make
                  sure you're hitting your recommended serves, you're in the
                  right place. We've got the lowdown on plant-based alternatives
                  that'll help you when following a vegan diet.
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> If following a vegan diet:
                  protein, iron, zinc and calcium are important nutrients to
                  keep an eye on. You can use the “Eat more protein” goal to
                  help you meet serves of these important nutrients. B12 is also
                  key, see our note below.
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                Meet your serves with plant proteins
              </InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Choose a variety of colourful legumes such as lentils,
                      peas and beans. Legumes are important sources of protein,
                      iron and zinc in vegan diets.
                    </li>
                    <li>
                      Liven up your salads, stirfries and casseroles with kidney
                      beans, soy beans, cannelini beans or chickpeas. 1 cup of
                      cooked beans = 1 serve.
                    </li>
                    <li>
                      Tofu, tempeh and textured vegetable protein (TVP) also
                      count toward your serves. 170g of tofu = 1 serve.
                    </li>
                    <li>
                      Nuts and seeds contain many beneficial nutrients but keep
                      in mind they have less protein, iron and zinc compared to
                      animal protein.
                    </li>
                    <li>
                      Snack on a handful of almonds, sprinkle a handful of
                      pumpkin or sesame seeds on your meal or try a nut spread
                      on your crackers or sandwich.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>
                Plant-based ‘dairy’ still counts
              </InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Look for calcium fortified plant milks such as almond, soy
                      or oat milk. Where possible, choose products fortified
                      with at least 100mg calcium per 100mL.{" "}
                    </li>
                    <li>1 cup of calcium fortified plant milk is 1 serve.</li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> Leafy greens such as spinach
                  provide iron. Vitamin C supports iron absorption from plants.
                  Squeeze some lemon juice on your leafy greens or pair
                  vegetables like broccoli or capsicum that also contain vitamin
                  C with your meal.
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  Important note: Vitamin B12 is only found in animal products
                  and therefore some individuals may require a supplement. If
                  you need further advice, it’s important to discuss this with a
                  healthcare professional.
                </Typography>
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}

        {checked.includes(DIETARY_PREFERENCES.VEGETARIAN) && (
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordionChange("panel5", 4)}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
              expandIcon={<AccordionIcon />}
            >
              {cloneElement(DIETARY_PREFERENCES_DATA["vegetarian"].icon, {
                width: 24,
                height: 24,
                className: classes.titleIcon,
              })}{" "}
              Vegetarian
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  If you're into the whole plant-based vibe and want to make
                  sure you're hitting your recommended serves, you're in the
                  right place. We've got the lowdown on plant-based alternatives
                  that'll help you with a balanced vegetarian diet.
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> If following a vegetarian diet:
                  protein, B12 iron and zinc are important nutrients to keep an
                  eye on. You can use the “Eat more protein” goal to help you
                  meet serves of these important nutrients.
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>Meat alternatives</InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Scrambled, poached or fried? One serve of protein is two
                      eggs.
                    </li>
                    <li>
                      Choose a variety of colourful legumes such as lentils,
                      peas and beans. Legumes are important sources of protein,
                      iron and zinc in vegan diets.
                    </li>
                    <li>
                      Liven up your salads, stirfries and casseroles with kidney
                      beans, soy beans, cannelini beans or chickpeas. 1 cup of
                      cooked beans = 1 serve.
                    </li>
                    <li>
                      Tofu, tempeh and textured vegetable protein (TVP) also
                      count toward your serves. 170g of tofu = 1 serve.
                    </li>
                    <li>
                      Nuts and seeds contain many beneficial nutrients but keep
                      in mind they have less protein, iron and zinc compared to
                      animal sources.
                    </li>
                    <li>
                      Snack on a handful of almonds, sprinkle a handful of
                      pumpkin or sesame seeds on your meal or try a nut spread
                      on your crackers or sandwich.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalSubTitle>Meet your Dairy serves</InfoModalSubTitle>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <ul>
                    <li>
                      Milk, cheese and yoghurt count towards the Dairy &
                      alternatives food group. These foods are an important
                      source of calcium, protein, vitamins and minerals such as
                      vitamin A, D, B12 and zinc.
                    </li>
                  </ul>
                </Typography>
              </InfoModalContent>
              <InfoModalContent>
                <Typography
                  variant="body1"
                  className={classes.accordionContent}
                >
                  <strong>Expert tip:</strong> To get the potential benefits of
                  being vegetarian, it is recommended to eat a balanced diet of
                  fruit, veggies, grains, legumes, nuts, seeds, eggs and dairy.
                </Typography>
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}
      </AccordionContainer>
      <InfoModalContent>
        <Typography
          variant="body1"
          className={classes.accordionContent}
          style={{ marginTop: "20px" }}
        >
          <em>
            Dietary preference filters and tags are powered by{" "}
            <a href="https://www.spoon.guru/" target="_blank" rel="noreferrer">
              Spoon Guru
            </a>
            .
          </em>
        </Typography>
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default BalanceOfShopV2DietaryPreferenceExplainer;
