import { ReactComponent as DF } from "../../icons/dietary-df.svg";
import { ReactComponent as GF } from "../../icons/dietary-gf.svg";
import { ReactComponent as LF } from "../../icons/dietary-lf.svg";
import { ReactComponent as V } from "../../icons/dietary-v.svg";
import { ReactComponent as VG } from "../../icons/dietary-vg.svg";
import { ReactComponent as DFBlack } from "../../icons/dietary-df-black.svg";
import { ReactComponent as GFBlack } from "../../icons/dietary-gf-black.svg";
import { ReactComponent as LFBlack } from "../../icons/dietary-lf-black.svg";
import { ReactComponent as VBlack } from "../../icons/dietary-v-black.svg";
import { ReactComponent as VGBlack } from "../../icons/dietary-vg-black.svg";
import React from "react";
import {
  DAIRY_FREE_DRAWER_CONTENT,
  GLUTEN_FREE_DRAWER_CONTENT,
  LACTOSE_FREE_DRAWER_CONTENT,
  VEGAN_DRAWER_CONTENT,
  VEGETARIAN_DRAWER_CONTENT,
} from "./drawer-config";

export enum DIETARY_PREFERENCES {
  DAIRY_FREE = "dairy_free",
  GLUTEN_FREE = "gluten_free",
  LACTOSE_FREE = "lactose_free",
  VEGAN = "vegan",
  VEGETARIAN = "vegetarian",
}

export const DIETARY_PREFERENCES_DATA = {
  [DIETARY_PREFERENCES.DAIRY_FREE]: {
    id: DIETARY_PREFERENCES.DAIRY_FREE,
    code: "dairyFree",
    name: "Dairy free",
    icon: <DF />,
    cancels: [DIETARY_PREFERENCES.LACTOSE_FREE],
    BLACK_ICON: <DFBlack />,
    DRAWER_CONTENT: DAIRY_FREE_DRAWER_CONTENT,
    ARTICLE_LINK: "https://www.healthylife.com.au/food-nutrition",
  },
  [DIETARY_PREFERENCES.GLUTEN_FREE]: {
    id: DIETARY_PREFERENCES.GLUTEN_FREE,
    code: "glutenFree",
    name: "Gluten free",
    icon: <GF />,
    cancels: [],
    BLACK_ICON: <GFBlack />,
    DRAWER_CONTENT: GLUTEN_FREE_DRAWER_CONTENT,
    ARTICLE_LINK: "https://www.healthylife.com.au/food-nutrition",
  },
  [DIETARY_PREFERENCES.LACTOSE_FREE]: {
    id: DIETARY_PREFERENCES.LACTOSE_FREE,
    code: "lactoseFree",
    name: "Lactose free",
    icon: <LF />,
    cancels: [],
    BLACK_ICON: <LFBlack />,
    DRAWER_CONTENT: LACTOSE_FREE_DRAWER_CONTENT,
    ARTICLE_LINK: "https://www.healthylife.com.au/food-nutrition",
  },
  [DIETARY_PREFERENCES.VEGAN]: {
    id: DIETARY_PREFERENCES.VEGAN,
    code: "vegan",
    name: "Vegan",
    icon: <V />,
    cancels: [
      DIETARY_PREFERENCES.VEGETARIAN,
      DIETARY_PREFERENCES.LACTOSE_FREE,
      DIETARY_PREFERENCES.DAIRY_FREE,
    ],
    BLACK_ICON: <VBlack />,
    DRAWER_CONTENT: VEGAN_DRAWER_CONTENT,
    ARTICLE_LINK: "https://www.healthylife.com.au/plant-based-eating",
  },
  [DIETARY_PREFERENCES.VEGETARIAN]: {
    id: DIETARY_PREFERENCES.VEGETARIAN,
    code: "vegetarian",
    name: "Vegetarian",
    icon: <VG />,
    cancels: [],
    BLACK_ICON: <VGBlack />,
    DRAWER_CONTENT: VEGETARIAN_DRAWER_CONTENT,
    ARTICLE_LINK: "https://www.healthylife.com.au/food-nutrition",
  },
};
