import { useEffect, useState } from "react";
import { FeedbackDialogConfiguration } from "../models";
import { getFeedbackConfiguration } from "../services/configuration";

const useFeedbackDialogConfig = () => {
  const [config, setConfig] = useState<FeedbackDialogConfiguration | undefined>(
    undefined
  );

  useEffect(() => {
    const init = async () => {
      setConfig(await getFeedbackConfiguration());
    };

    init();
  }, []);

  return config;
};

export default useFeedbackDialogConfig;
