import { useContext } from "react";
import { MemberContext } from "../components/providers/MemberProvider";
import {
  storeFeedbackDismissed,
  storeFeedback,
} from "../services/feedbackSubmissions";

const useFeedback = () => {
  const member = useContext(MemberContext);

  return {
    handleSubmit: (rating: number, comment?: string) => {
      if (member?.memberId) {
        storeFeedback(member.memberId, rating, comment);
      }
    },
    handleDismissed: () => {
      if (member?.memberId) {
        storeFeedbackDismissed(member.memberId);
      }
    },
  };
};

export default useFeedback;
