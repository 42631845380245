import SidebarSettings from "./SidebarSettings";
import { Typography, makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, LIGHT_GREY, WHITE } from "../../theme";
import { useContext } from "react";
import { ReactComponent as RewardsIcon } from "../../icons/rewards.svg";
import { ReactComponent as RewardsBoxed } from "../../icons/rewards-boxed.svg";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useMember from "../../hooks/useMember";

const SidebarRewards = () => {
  const { setIsOpenSidebar, isOpenRewards, setIsOpenRewards } =
    useContext(SettingsContext);
  const { member } = useMember();

  const memberlast4digits = member?.memberId.substring(
    member.memberId.length - 4
  );

  const classes = makeStyles(({ breakpoints }) => ({
    container: {
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      height: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    heading: {
      padding: "16px 24px",
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
      display: "flex",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "100%",
    },
    headingText: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    innerContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px 24px",
      boxSizing: "border-box",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      width: "100%",
    },
    buttonContainer: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 16px 24px",
      marginTop: "auto",
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
    },
    // Content styling here!
    rewardsContainer: {
      padding: "16px",
      backgroundColor: LIGHT_GREY,
      boxSizing: "border-box",
      display: "flex",
      columnGap: "8px",
      borderRadius: "4px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      marginBottom: "16px",
      alignItems: "center",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "4px",
      color: COLOR_GREYSCALE[900],
    },
    text: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "4px",
      color: COLOR_GREYSCALE[900],
    },
    bottomText: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "4px",
      color: COLOR_GREYSCALE[700],
    },
  }))();

  return (
    <SidebarSettings
      sidebarOpen={isOpenRewards}
      setSidebarOpen={setIsOpenRewards}
      backButtonAction={() => {
        setIsOpenRewards(false);
        setIsOpenSidebar(true);
      }}
      contentContainerExtended={true}
    >
      <div className={classes.container}>
        <div className={classes.heading}>
          <RewardsIcon width={24} height={24} />
          <h3 className={classes.headingText}>Everyday Rewards</h3>
        </div>
        <div className={classes.innerContent}>
          <div className={classes.rewardsContainer}>
            <RewardsBoxed />
            <div className={classes.textContainer}>
              <Typography className={classes.header}>
                Your card is linked
              </Typography>
              <Typography className={classes.text}>
                XXXXXXXXXXX{memberlast4digits}
              </Typography>
            </div>
          </div>
          <Typography className={classes.bottomText}>
            Your Everyday Rewards card is successfully linked with Food Tracker
            by Healthylife.
          </Typography>
        </div>
      </div>
    </SidebarSettings>
  );
};

export default SidebarRewards;
