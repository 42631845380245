import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, useMediaQuery } from "@material-ui/core";
import useOnboardingStepper from "../../hooks/useOnboardingStepper";

export const MobilePageHeader = ({
  title,
  stepNumber,
}: {
  title: string;
  stepNumber?: number;
}) => {
  const headerStyles = makeStyles(({ breakpoints }) => ({
    title: {
      color: "#FFFFFF",
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      textAlign: "center",
      paddingRight: "18px",
      margin: 0,
      fontSize: "24px",
      lineHeight: "32px",
    },
    step: {
      marginRight: "16px",
      padding: "4px 8px",
      background: "#F3E9D3",
      color: "#0A2822",
      borderRadius: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      textAlign: "center",
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
    },
  }));

  const classes = headerStyles();

  return (
    <Grid
      direction="row"
      justifyContent="center"
      alignItems="center"
      container
      style={{
        paddingTop: "29px",
      }}
    >
      <Grid item>
        <span className={classes.step}>Step {stepNumber}</span>
      </Grid>
      <Grid item>
        <Typography className={classes.title} component={"h1"}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const DesktopPageHeader = ({
  title,
  activeStep,
}: {
  title: string;
  activeStep?: number;
}) => {
  const { STEP_TITLES } = useOnboardingStepper();
  const headerStyles = makeStyles(({ breakpoints }) => ({
    title: {
      color: "#FFFFFF",
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      textAlign: "center",
      margin: 0,
      fontSize: "20px",
      letterSpacing: "0.1px",
      lineHeight: "28px",
      marginTop: "12px",
    },
    step: {
      padding: "4px 8px",
      background: "#F3E9D3",
      color: "#0A2822",
      borderRadius: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      textAlign: "center",
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
    },
    nonActiveStep: {
      opacity: "50%",
    },
    nonActiveTitle: {
      opacity: "50%",
    },
    container: {
      marginTop: "32px",
      padding: "0px 24px",
      gap: "118px",
    },
    stepAndTitleContainer: {},
  }));

  const classes = headerStyles();

  return (
    <Grid
      direction="row"
      justifyContent="center"
      alignItems="center"
      container
      className={classes.container}
    >
      {Object.entries(STEP_TITLES).map(([step, { title }]) => {
        const stepNumber = parseInt(step);
        return (
          <Grid item className={classes.stepAndTitleContainer} key={title}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <span
                  className={
                    stepNumber === activeStep
                      ? classes.step
                      : `${classes.step} ${classes.nonActiveStep}`
                  }
                >
                  Step {stepNumber}
                </span>
              </Grid>
              <Grid item>
                <Typography
                  className={
                    stepNumber === activeStep
                      ? classes.title
                      : `${classes.title} ${classes.nonActiveTitle}`
                  }
                  component={"h1"}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const OnboardingPageHeader = ({
  title,
  stepNumber,
}: {
  title: string;
  stepNumber?: number;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) {
    return <MobilePageHeader title={title} stepNumber={stepNumber} />;
  } else {
    return <DesktopPageHeader title={title} activeStep={stepNumber} />;
  }
};
