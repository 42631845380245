import React, { ChangeEvent, useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import { FaqContent, fetchFaqPageQuestions } from "../services/contentful/faq";
import { makeStyles, Typography } from "@material-ui/core";
import ContentfulRichText from "../components/ContentfulRichText";
import useEvents from "../hooks/useEvents";
import Accordion from "../components/accordion/Accordion";

const useStyles = makeStyles((theme) => ({
  questionsContainer: {
    marginTop: theme.spacing(2),
  },
  accordionRoot: {
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.12); !important",
    },
    "&.Mui-expanded + &": {
      "&:before": {
        // Don't hide border when previous item is expanded (copied idea from mui source)
        display: "unset",
      },
    },
    "&.Mui-expanded": {
      "&:before": {
        opacity: 1,
      },
    },
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    "& p": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const FaqPage = () => {
  const classes = useStyles();
  const { track } = useEvents();
  const [questions, setQuestions] = useState<FaqContent[]>([]);

  useEffect(() => {
    const init = async () => {
      setQuestions(await fetchFaqPageQuestions());
    };

    init();
  }, []);

  const handleChange =
    (item: FaqContent) => (_event: ChangeEvent<{}>, expanded: boolean) => {
      if (expanded) {
        track("FAQ Opened", {
          question: item.question,
        });
      }
    };

  return (
    <div role={"main"} style={{ position:"relative" }}>
      <PageHeader title="Frequently Asked Questions" />
      <div className={classes.questionsContainer}>
        {questions.map((item, idx) => (
          <Accordion
            title={
              <Typography variant="h2" className={classes.title}>
                {idx + 1}. {item.question}
              </Typography>
            }
            className={classes.accordionRoot}
            onChange={handleChange(item)}
          >
            <Typography variant="body2" className={classes.body}>
              <ContentfulRichText content={item.answer} />
            </Typography>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FaqPage;
