import { useContext } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { COLOR_GREYSCALE } from "../../../theme";
import useEvents from "../../../hooks/useEvents";
import { RECIPES_EVENTS } from "../../../constants";
import { RecipeContext } from "../../../components/providers/RecipeProvider";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.2px",
    margin: "0px",
    color: COLOR_GREYSCALE[900],
  },
  closeButton: {
    fontSize: "14px",
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: COLOR_GREYSCALE[900],
    display: "flex",
    gap: "20px",
    padding: "0px",
  },
}));

const RecipeFilterHeading = () => {
  const { track } = useEvents();
  const classes = useStyles();
  const {
    setToggleFilter,
    setTempFilters,
    filteredRecipes,
  } = useContext(RecipeContext);

  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>Filter by</h2>
      <IconButton
        aria-label="Close Button"
        onClick={() => {
          setTempFilters(filteredRecipes);
          track(RECIPES_EVENTS.RLP_FILTER_CLOSE);
          setToggleFilter(false);
        }}
        className={classes.closeButton}
      >
        <CloseIcon />
        <span style={{ paddingLeft: "8px" }}>Close</span>
      </IconButton>
    </div>
  );
};

export default RecipeFilterHeading;
