import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CHARCOAL, COLOR_GREYSCALE } from "../../theme";
import { Grid } from "@material-ui/core";
import useMember from "../../hooks/useMember";
import { setMemberDietaryPreference } from "../../services/members";
import { DIETARY_PREFERENCES } from "../dietary-preference/config";
import { DietaryPreferenceCheckbox } from "../dietary-preference/DietaryPreferenceCheckbox";
import { SettingType } from "../sidebar-settings/SidebarPersonalisation";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { DietaryPreferencesContext } from "../../components/providers/DietaryPreferencesProvider";
import useEvents from "../../hooks/useEvents";
import { DIETARY_PREFERENCE_EVENTS } from "../../events";

interface DietaryPreferenceProps {
  setIsOpenPersonalisation?: Dispatch<SetStateAction<SettingType | false>>;
  inSidebar?: boolean;
}

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-Bold'",
    fontWeight: "normal",
  },
  body: {
    margin: 0,
    marginTop: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
  },
  subTitle: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-SemiBold'",
    fontWeight: "normal",
    marginTop: "24px",
    marginBottom: "24px",
  },
  gridContainer: {
    "& > *:not(:first-child)": {
      marginTop: "16px",
    },
  },
  disclaimer: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[700],
  },
  buttonContainer: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "auto",
  },
  buttonSave: {
    padding: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    columnGap: "8px",
    boxSizing: "border-box",
    width: "144px",
    boxShadow: "none",
    textTransform: "initial",
    "&:disabled": {
      boxShadow: "none",
    },
  },
  buttonCancel: {
    padding: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    color: COLOR_GREYSCALE[800],
    backgroundColor: "transparent",
    border: `solid 2px ${COLOR_GREYSCALE[800]}`,
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    columnGap: "8px",
    boxSizing: "border-box",
    width: "144px",
    boxShadow: "none",
    textTransform: "initial",
  },
}));

const DietaryPreference = ({
  inSidebar,
  setIsOpenPersonalisation,
}: DietaryPreferenceProps) => {
  const classes = useStyles();
  const { member } = useMember();
  const [saveLoading, setSaveLoading] = useState(false);

  const { track } = useEvents();

  const { checked } = useContext(DietaryPreferencesContext);

  useEffect(() => {
    track(DIETARY_PREFERENCE_EVENTS.SETTING_OPEN);

    return () => track(DIETARY_PREFERENCE_EVENTS.SETTING_CLOSE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async () => {
    setSaveLoading(true);
    await setMemberDietaryPreference(member!.memberId, checked);
    setSaveLoading(false);
    if (inSidebar) {
      setIsOpenPersonalisation!("main");
    }
  };
  return (
    <>
      <Typography variant="h3" component="h2" className={classes.title}>
        Have dietary preferences?
      </Typography>
      <Typography className={classes.body}>
        This will help give you the best personalised experience.
      </Typography>
      <Typography component="h3" className={classes.subTitle}>
        Please select all that may apply.
      </Typography>
      <Grid container className={classes.gridContainer}>
        {Object.keys(DIETARY_PREFERENCES).map((key) => {
          const preference =
            DIETARY_PREFERENCES[key as keyof typeof DIETARY_PREFERENCES];
          return (
            <DietaryPreferenceCheckbox
              key={preference}
              preference={preference}
            />
          );
        })}
      </Grid>
      <Typography component="h4" className={classes.disclaimer}>
        This assists in finding products and recipes that align with your
        dietary or lifestyle preferences. It's not a substitute for medical
        advice or reviewing the product label in full.
      </Typography>
      {inSidebar && (
        <>
          <div style={{ marginTop: 24 }} />
          <div className={classes.buttonContainer}>
            <Button
              variant={"contained"}
              color={"default"}
              className={classes.buttonCancel}
              onClick={() => setIsOpenPersonalisation!("main")}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              endIcon={!saveLoading && <CheckIcon />}
              className={classes.buttonSave}
              onClick={onSave}
              disabled={saveLoading}
            >
              {saveLoading ? "Saving..." : "Save"}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DietaryPreference;
