import { useContext } from "react";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";

export default function useFeatureFlags() {
  const { collected, featureFlags } = useContext(FeatureFlagContext);

  return {
    featureFlags: featureFlags,
    loadingFeatureFlags: !collected,
  };
}
