interface Props {
  marginTop?: number;
  marginBottom?: number;
  styles?: object;
}

const BreakSeparator = ({
  marginTop = 24,
  marginBottom = 24,
  styles,
}: Props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "0.5px",
        background: `linear-gradient(270deg, rgba(198, 198, 198, 0.00) 8.85%, #C6C6C6 32.81%, #C6C6C6 67.19%, rgba(198, 198, 198, 0.00) 89.06%)`,
        marginTop,
        marginBottom,
        ...styles,
      }}
    ></div>
  );
};

export default BreakSeparator;
