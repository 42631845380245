import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { NO_CONSENT_REDIRECT_URL } from "../../constants";
import useMember from "../../hooks/useMember";
import {
  fetchTermsAndConditions,
  TermsAndConditionsContent,
} from "../../services/contentful/termsandconditions";
import { sendGtagEvent } from "../../services/events";
import { TEAL } from "../../theme";

import StyledDialog from "../StyledDialog";
import ConsentCheckbox from "./ConsentCheckbox";

interface Props {
  disabled: boolean;
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  dialog: {
    "& .MuiDialogContent-root": {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      padding: spacing(0, 2, 3, 2),
      overflow: "hidden",
      [breakpoints.up("sm")]: {
        padding: spacing(0, 4, 3, 4),
        maxWidth: spacing(62.5),
      },
    },
  },
  root: {
    padding: 2,
  },
  heading: {
    margin: 0,
    fontSize: 20,
    lineHeight: "24px",
    [breakpoints.up("sm")]: {
      fontSize: 28,
      lineHeight: "36px",
    },
    textAlign: "center",
    color: TEAL,
  },
  getStartedButtonContainer: {
    marginTop: spacing(3),
    textAlign: "center",
  },
  buttonIcon: {
    marginLeft: spacing(1),
  },
  arrowIcon: {
    fontSize: 22,
  },
  backdrop: {
    position: "absolute",
  },
  submitButton: {
    marginLeft: 48,
    borderRadius: "12px",
    backgroundColor: "#B9E04C",
    boxShadow: "none",
    padding: "12px 24px",
    color: "#0A2822",
    "&:hover": {
      backgroundColor: "#A0C734",
      boxShadow: "none",
    },
  },
}));

const ConsentDialog: React.FC<Props> = ({ disabled }) => {
  const classes = useStyles();

  const { member, storeMemberConsent } = useMember();

  const [consented, setConsented] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsAndConditionsContent, setTermsAndConditionsContent] =
    useState<TermsAndConditionsContent>();

  useEffect(() => {
    const init = async () => {
      setTermsAndConditionsContent(await fetchTermsAndConditions());
    };

    init();
  }, []);

  const getStarted = async () => {
    setLoading(true);
    const { sys } = termsAndConditionsContent!;
    await storeMemberConsent(sys.id, sys.publishedVersion);
    if (member?.memberId) {
      sendGtagEvent(member?.memberId, "Submit Application");
    }
    if (window) {
      window.location.reload();
    }
  };

  const handleCheckboxChanged = (checked: boolean) => {
    setConsented(checked);
  };

  return (
    <StyledDialog
      title=""
      open={false}
      onClose={() => {
        if (window) {
          window.location.href = NO_CONSENT_REDIRECT_URL;
        }
      }}
      mobileFullScreen={false}
      className={classes.dialog}
      BackdropProps={{ className: classes.backdrop }}
      classes={{ root: classes.root }}
    >
      <Typography variant="h1" className={classes.heading}>
        Connect your Everyday Rewards membership card to access your shopping
        insights
      </Typography>
      <ConsentCheckbox
        disabled={loading || disabled}
        value={consented}
        onChange={handleCheckboxChanged}
      />
      <div className={classes.getStartedButtonContainer}>
        <Button
          onClick={() => {
            if (window) {
              window.location.href = NO_CONSENT_REDIRECT_URL;
            }
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!consented || loading || disabled}
          onClick={getStarted}
          className={classes.submitButton}
        >
          Submit
          {loading ? (
            <CircularProgress size="1.25rem" className={classes.buttonIcon} />
          ) : (
            <ArrowForwardIcon
              className={clsx(classes.buttonIcon, classes.arrowIcon)}
            />
          )}
        </Button>
      </div>
    </StyledDialog>
  );
};

export default ConsentDialog;
