import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PageLayout } from "../page-layout";
import useOnboardingStepper from "../../hooks/useOnboardingStepper";

export const OnboardingPageLayout = ({
  stepNumber,
  stepper,
  children,
}: {
  stepNumber: number;
  stepper: ReactNode;
  children: ReactNode;
}) => {
  const { STEP_TITLES } = useOnboardingStepper();
  const useStyles = makeStyles(({ breakpoints }) => ({
    container: {
      position: "relative",
      background: "white",
      borderRadius: "12px",
      [breakpoints.down("sm")]: {
        padding: "16px",
      },
      [breakpoints.up("md")]: {
        padding: "40px",
      },
      flexGrow: 1,
      display: "flex",
      marginBottom: "12px",
      flexDirection: "column",
      marginTop: "24px",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      [breakpoints.up("md")]: {
        width: "327px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    stepperContainer: {},
  }));

  const classes = useStyles();
  return (
    <PageLayout
      title={STEP_TITLES[stepNumber as keyof typeof STEP_TITLES].title}
      stepNumber={stepNumber}
      isOnboarding
    >
      <div className={classes.container}>
        <div className={classes.contentContainer}>{children}</div>
        <div className={classes.stepperContainer}>{stepper}</div>
      </div>
    </PageLayout>
  );
};
