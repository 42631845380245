import { useContext } from "react";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";
import { MemberContext } from "../components/providers/MemberProvider";

export default function useHideDietaryPreferences() {
  const { featureFlags } = useContext(FeatureFlagContext);
  const member = useContext(MemberContext);
  const { dietaryPreferences } = featureFlags;

  if (
    member?.memberId &&
    (dietaryPreferences?.enable?.everybody ||
      dietaryPreferences?.enable?.specificMembers?.includes(member.memberId))
  ) {
    return false;
  }
  return true;
}
