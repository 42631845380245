import { createContext, Dispatch, FC, SetStateAction, useState } from "react";

interface Props {}

interface PersonalizationContextState {
  isSettingPersonalization: boolean;
  setIsSettingPersonalization: Dispatch<SetStateAction<boolean>>;
}

export const PersonalizationContext =
  createContext<PersonalizationContextState>({
    isSettingPersonalization: false,
    setIsSettingPersonalization: () => {},
  });

const PersonalizationProvider: FC<Props> = ({ children }) => {
  const [isSettingPersonalization, setIsSettingPersonalization] =
    useState(false);
  const value = {
    isSettingPersonalization,
    setIsSettingPersonalization,
  };
  return (
    <PersonalizationContext.Provider value={value}>
      {children}
    </PersonalizationContext.Provider>
  );
};

export default PersonalizationProvider;
