import { Link, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import HealthierOptionsCarousel from "./HealthierOptionsCarousel";
import { HealthierOptionsExplainer } from "./HealthierOptionsExplainer";
import { useHistory } from "react-router-dom";
import SimilarProductsText from "./SimilarProductsText";
import { HealthierOptionsFoodGroup } from "../../models";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import { NoSwapsText } from "./NoSwapsText";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import useEvents from "../../hooks/useEvents";
import {
  FOOD_GROUP_PAGES_EVENT,
  HOMEPAGE_EVENT,
  MAIN_VS_SOMETIMES_EVENT,
  NUTRIENT_TRACKER_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../events";

const HealthierOptions = ({
  iconColor = DARK_GREEN,
  foodGroup = "all",
  marginTop = 16,
  showHeader = true,
  forHomepage = false,
  source,
}: {
  iconColor?: string;
  foodGroup?: HealthierOptionsFoodGroup;
  marginTop?: number;
  showHeader?: boolean;
  forHomepage?: boolean;
  source?: string;
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      marginTop: marginTop,
      padding: "16px",
      borderRadius: 12,
      border: forHomepage ? "none" : `1px solid ${COLOR_GREYSCALE[200]}`,
      boxShadow: forHomepage ? "0px 1px 2px 0px rgba(0, 0, 0, 0.10)" : "none",
      overflow: "hidden",
      [breakpoints.up("md")]: {
        padding: "24px",
      },
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D3D6D9",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      [breakpoints.up("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
    similarProductsText: {
      padding: "0px",
    },
    noSwapsText: {
      color: "#585C60",
      "& > ul": {
        margin: 0,
        marginTop: "10px",
      },
    },
    icon: {
      "& path": {
        fill: iconColor,
      },
    },
  }));
  const history = useHistory();
  const classes = useStyles();
  const { track } = useEvents();
  const { purchasedItemsWithSwaps } = useHealthierOptions("all");

  const handleHOPageOpenTrack = (fromArrow?: boolean) => {
    if (!forHomepage) {
      switch (source) {
        case "main-vs-sometimes":
          return track(MAIN_VS_SOMETIMES_EVENT.HO_OPEN);
        case "food-group":
          return track(FOOD_GROUP_PAGES_EVENT.HO_OPEN, { foodGroup });
        case "sometimes-food":
          return track(SOMETIMES_FOOD_PAGE_EVENT.HO_OPEN);
        case "nutrient-tracker":
          return track(NUTRIENT_TRACKER_EVENT.HO_OPEN);
      }
    }

    if (forHomepage && fromArrow) {
      return track(HOMEPAGE_EVENT.HO_NEXT);
    }
    return track(HOMEPAGE_EVENT.HO_OPEN);
  };

  return (
    <div className={classes.root}>
      {showHeader && (
        <Typography variant="h3" component="h1" className={classes.headerTitle}>
          <div className={classes.titleContainer}>
            <HealthierOptionsIcon className={classes.icon} />
            <Link
              tabIndex={0}
              onKeyPress={(e) => {
                ifEnterOrSpace(e, () => {
                  handleHOPageOpenTrack();
                  history.push("/healthier-options/plp?source=" + source);
                });
              }}
              onClick={() => {
                handleHOPageOpenTrack();
                history.push("/healthier-options/plp?source=" + source);
              }}
              style={{
                textDecoration: "none",
                color: COLOR_GREYSCALE[800],
                cursor: "pointer",
              }}
            >
              <div className={classes.title}>Healthier Options</div>
            </Link>
            <HealthierOptionsExplainer
              source={!forHomepage ? source! : ""}
              foodGroup={foodGroup}
            />
          </div>
          <Link
            tabIndex={0}
            onKeyPress={(e) =>
              ifEnterOrSpace(e, () => {
                handleHOPageOpenTrack(true);
                history.push("/healthier-options/plp?source=" + source);
              })
            }
            onClick={() => {
              handleHOPageOpenTrack(true);
              history.push("/healthier-options/plp?source=" + source);
            }}
            style={{
              textDecoration: "none",
              color: COLOR_GREYSCALE[800],
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ChevronRight />
          </Link>
        </Typography>
      )}
      {purchasedItemsWithSwaps.length < 1 ? (
        <NoSwapsText forHomepage={forHomepage} />
      ) : (
        <>
          <SimilarProductsText
            className={classes.similarProductsText}
            highlightColor={iconColor}
            foodGroup={foodGroup}
            showHeader={showHeader}
          />
          <HealthierOptionsCarousel
            healthierOption={foodGroup}
            forHomepage={forHomepage}
            source={source}
          />
        </>
      )}
    </div>
  );
};

export default HealthierOptions;
