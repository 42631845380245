import {
  DocumentData,
  DocumentReference,
  Firestore,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import throttle from "lodash/throttle";

interface Options {
  waitTimeMillis?: number;
  maxBatchSize?: number;
}

type Doc = { [x: string]: any };

/**
 * Push db changes to firestore in batches, ensuring batch is flushed when window unloads.
 * See test for expectations.
 */
export class ThrottledBatchQueue {
  private _batchSize = 0;
  private batch: WriteBatch;
  private readonly maxBatchSize;
  private readonly maybeCommit: ReturnType<typeof throttle>;

  constructor(
    private readonly db: Firestore,
    { waitTimeMillis = 250, maxBatchSize = 500 }: Options = {}
  ) {
    this.maxBatchSize = maxBatchSize;
    this.batch = writeBatch(db);

    this.maybeCommit = throttle(async () => {
      const oldBatch = this.batch;

      this.newBatch();
      await oldBatch.commit();
    }, waitTimeMillis);

    window.addEventListener("beforeunload", this.maybeCommit.flush);
  }

  enqueueSet = (ref: DocumentReference<DocumentData>, data: Doc) => {
    if (this._batchSize === this.maxBatchSize) {
      this.maybeCommit.flush();
    }

    this.addToBatch(ref, data);
    this.maybeCommit();
  };

  // Used in tests
  _cancel = () => this.maybeCommit.cancel();

  get batchSize() {
    return this._batchSize;
  }

  private addToBatch = (ref: DocumentReference<DocumentData>, data: Doc) => {
    this.batch.set(ref, data);
    this._batchSize++;
  };

  private newBatch = () => {
    this.batch = writeBatch(this.db);
    this._batchSize = 0;
  };
}
