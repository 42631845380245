import some from "lodash/some";
import sortBy from "lodash/sortBy";
import { Duration } from "luxon";
import {
  AgeRange,
  Benchmark,
  Gender,
  HealthCondition,
  HouseholdMemberWithBenchmark,
  Servings,
} from "../../models";
import { accumulateServings } from "../servings";
import { mapToAgeRange } from "./agerange";
import { mapToGender } from "./gender";
import { getPrecedenceMap, mapToHealthCondition } from "./healthconditions";
import { BENCHMARKS } from "./hardcodedContentfulContent";

export const getBenchmarks = async (): Promise<Benchmark[]> => {
  const precedence = await getPrecedenceMap();
  // Used to fetched from Contentful, now hard coded.
  const { items } = BENCHMARKS.data.benchmarkCollection;

  return items.map((item: any) => {
    const benchMark: Benchmark = {
      id: item.sys.id,
      title: item.title,
      ageRange: mapToAgeRange(item.ageRange),
      gender: mapToGender(item.gender),
      healthCondition: item.healthCondition
        ? mapToHealthCondition(item.healthCondition, precedence)
        : undefined,
      dailyServings: {
        grains: item.dailyServingOfGrains,
        vegetables: item.dailyServingOfVegetables,
        protein: item.dailyServingOfProtein,
        fruit: item.dailyServingOfFruit,
        dairy: item.dailyServingOfDairy,
        discretionary: item.dailyServingOfSometimesFoods,
      },
      enabled: item.enabled,
      saltTarget: item.saltTarget,
    };
    return benchMark;
  });
};

export const withId = (benchmarks: Benchmark[], id: string) =>
  benchmarks.find((b) => b.id === id);

export const onlyEnabled = (benchmarks: Benchmark[]): Benchmark[] =>
  benchmarks.filter((b) => b.enabled);

export const calculateBenchmarkServingsForHousehold = (
  household: HouseholdMemberWithBenchmark[],
  timeFrame: Duration
): Servings => {
  const totalHouseholdServings = accumulateServings(
    household.map((hm) => hm.benchmark.dailyServings)
  );

  const days = timeFrame.as("days");

  return {
    grains: totalHouseholdServings.grains * days,
    vegetables: totalHouseholdServings.vegetables * days,
    protein: totalHouseholdServings.protein * days,
    dairy: totalHouseholdServings.dairy * days,
    fruit: totalHouseholdServings.fruit * days,
    discretionary: totalHouseholdServings.discretionary * days,
  };
};

export const matchToBenchmark = (
  benchmarks: Benchmark[],
  ageRange: AgeRange,
  gender: Gender,
  healthConditions: HealthCondition[]
): Benchmark => {
  const hasHealthConditions = healthConditions.length > 0;

  const filtered = sortBy(
    benchmarks.filter((b) => {
      const ageRangeMatches = b.ageRange.id === ageRange.id;
      const genderMatches = b.gender.id === gender.id;

      if (hasHealthConditions !== Boolean(b.healthCondition)) {
        return false;
      }

      const healthConditionMatches =
        !hasHealthConditions ||
        some(healthConditions, ["id", b.healthCondition?.id]);

      return ageRangeMatches && genderMatches && healthConditionMatches;
    }),
    "healthCondition.precedence"
  );

  if (filtered.length === 0) {
    throw new Error("No matching benchmark");
  }

  return filtered[0];
};
