import { useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { FoodGroupColorIntake, TimeFrameDuration } from "../../../constants";

import { ReactComponent as LongLeftArrow } from "../../../icons/long-left-arrow.svg";
import { ReactComponent as IndicatorTriangle } from "../../../icons/day-indicator-triangle.svg";

import { COLOR_GREYSCALE, LEAFY_GREEN, PINK_SUNRISE } from "../../../theme";
import { SelectedTimeFrameContext } from "../../SelectedTimeFrameProvider";
import useHouseholdBenchmarkServings from "../../../hooks/useHouseholdBenchmarkServings";
import TimeframeBar from "./TimeframeBar";
import { FoodGroup } from "../../../models";
import useDiscretionaryPercentHistory from "../../../hooks/useDiscretionaryPercentHistory";

const TimeframeBarChart = ({
  foodGroup,
}: {
  foodGroup: FoodGroup | "discretionary";
}) => {
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const timeFrameDuration = TimeFrameDuration[timeFrame];
  const householdBenchmarkServings =
    useHouseholdBenchmarkServings(timeFrameDuration);

  const { accumulatedServings, discretionaryPercentDataList } =
    useDiscretionaryPercentHistory();

  const chartData = useMemo(() => {
    if (foodGroup === "discretionary") {
      return discretionaryPercentDataList.map((data) => {
        return {
          servingsPurchased: data.discretionaryPercent,
          householdBenchmarkServings: 100,
        };
      });
    }
    return accumulatedServings.map((servings) => {
      return {
        servingsPurchased: servings[foodGroup],
        householdBenchmarkServings: householdBenchmarkServings[foodGroup],
      };
    });
  }, [
    accumulatedServings,
    discretionaryPercentDataList,
    foodGroup,
    householdBenchmarkServings,
  ]);

  const servingExceeded = chartData.some(
    (data) =>
      Math.ceil(
        (data.servingsPurchased / data.householdBenchmarkServings) * 100
      ) >= 100
  );

  const percentLegendColor = {
    grains: "#995900",
    vegetables: LEAFY_GREEN[500],
    fruit: FoodGroupColorIntake.fruit,
    protein: PINK_SUNRISE[500],
    dairy: FoodGroupColorIntake.dairy,
  };

  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    barChart: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
    },
    barChartContainer: {
      display: "grid",
      gridTemplateColumns: "20px 24px 1fr",
      gridColumnGap: "4px",
      flexDirection: "row",
    },
    percentageLegendContainer: {
      display: "flex",
      position: "relative",
      flexDirection: "column-reverse",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      gridColumn: "2 / span all",
      gridRow: "1",
    },
    percentageLegend: {
      position: "absolute",
      left: 0,
      display: "block",
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      margin: 0,
      width: "100%",
      "&:nth-of-type(1)": {
        bottom: "-7px",
      },
      "&:nth-of-type(2)": {
        bottom: servingExceeded ? "52px" : "82px",
        zIndex: servingExceeded ? 1 : 3,
      },
      "&:nth-of-type(3)": {
        fontFamily:
          foodGroup === "discretionary" ? "Gilroy" : "Gilroy-SemiBold",
        bottom: servingExceeded ? "112px" : "172px",
        zIndex: servingExceeded ? 3 : 1,
        color:
          foodGroup === "discretionary"
            ? COLOR_GREYSCALE[800]
            : percentLegendColor[foodGroup],
        "&::after": {
          borderBottom: `dashed 1px ${
            foodGroup === "discretionary"
              ? COLOR_GREYSCALE[800]
              : percentLegendColor[foodGroup]
          }`,
          zIndex: 10,
          content: `" "`,
          display: foodGroup === "discretionary" ? "none" : "block",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: "calc(100% - 28px)",
          left: "28px",
        },
      },
      "&:nth-of-type(4)": {
        bottom: "172px",
      },
    },
    barContainer: {
      display: "flex",
      position: "relative",
      alignItems: "flex-end",
      height: "180px",
      width: "100%",
      gridColumn: "3 / span 1",
      gridRow: "1",
    },
    barInnerContainer: {
      display: "grid",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    hundredPercentText: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      color: "#585C60",
      margin: 0,
      marginLeft: "5px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "#ffffff",
      opacity: 0.8,
      zIndex: 10,
      borderRadius: 12,
    },
    textContainer: {
      position: "absolute",
      top: "calc(50% - 50px)",
      left: "calc(50% - 150px)",
      padding: spacing(1.5, 0),
      width: 300,
      zIndex: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
    loaderContainer: {
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10,
    },
    overlayText: {
      padding: spacing(0.5),
      textAlign: "center",
      lineHeight: "24px",
      fontFamily: "Gilroy",
      textDecoration: "underline",
    },
    timeFrameContainer: {
      display: "grid",
      gridTemplateColumns: "24px 1fr 16px",
      marginTop: "26px",
    },
    timeFrame: {
      display: "flex",
      gap: "8px",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "10px",
    },
    timeFrameLatest: {
      fontSize: "12px",
      lineHeight: "16px",
      color: COLOR_GREYSCALE[900],
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      textTransform: "uppercase",
      margin: 0,
      textAlign: "right",
    },
    arrowContainer: {
      position: "relative",
      display: "flex",
      flex: 1,
      "&:after": {
        content: `""`,
        display: "block",
        height: "1px",
        backgroundColor: COLOR_GREYSCALE[300],
        width: "calc(100% - 10px)",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0,
      },
    },
    timeFrameArrow: {
      marginRight: "auto",
    },
    timeFrameDuration: {
      fontSize: "12px",
      lineHeight: "16px",
      color: COLOR_GREYSCALE[700],
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      margin: 0,
    },
    indicatorTriangle: {
      marginRight: "5px",
      "& path": {
        fill: "#707881",
      },
    },
    barYAxis: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gridColumn: "1/ span 1",
    },
    verticalLabel: {
      color: COLOR_GREYSCALE[700],
      writingMode: "vertical-rl",
      transform: "rotate(180deg)",
      margin: 0,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy",
    },
    description: {
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "20px",
      [breakpoints.up("md")]: {
        fontSize: "14px",
      },
    },
  }));

  const classes = useStyles();

  const getMonthsLabel = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const today = new Date();
    let lastSixMonths = [];
    for (var i = 0; i < 6; i++) {
      const date = new Date(
        today.getFullYear(),
        today.getMonth() - i,
        today.getDate()
      );
      const month = date.getMonth();
      lastSixMonths.push(months[month]);
    }
    return lastSixMonths.reverse();
  };

  const monthsLabel = getMonthsLabel();

  return (
    <div className={classes.barChart}>
      <p className={classes.description}>
        {foodGroup === "discretionary"
          ? "This is the % of your Sometimes foods, trended over time."
          : "How many serves you have, compared to the recommended amount (100%)."}
      </p>
      <div className={classes.barChartContainer}>
        <div className={classes.barYAxis}>
          <p className={classes.verticalLabel}>
            {foodGroup === "discretionary"
              ? "% of sometimes food"
              : "% of recommended serves"}
          </p>
        </div>
        <div className={classes.percentageLegendContainer}>
          <p className={classes.percentageLegend}>0%</p>
          <p className={classes.percentageLegend}>50%</p>
          <p className={classes.percentageLegend}>100%</p>
          {servingExceeded && <p className={classes.percentageLegend}>150%</p>}
        </div>
        <div className={classes.barContainer}>
          <div className={classes.barInnerContainer}>
            {chartData.map((data, index) => (
              <TimeframeBar
                key={index}
                foodGroup={foodGroup}
                servingsPurchased={data.servingsPurchased}
                householdBenchmarkServings={data.householdBenchmarkServings}
                delayIndex={index}
                month={monthsLabel[index]}
                isMonthTimeframe={timeFrame === "Month"}
                servingsExceeded={servingExceeded}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.timeFrameContainer}>
        <div></div>
        <div className={classes.timeFrame}>
          <p className={classes.timeFrameDuration}>
            6 {timeFrame.toLowerCase()}s ago
          </p>
          <div className={classes.arrowContainer}>
            <LongLeftArrow className={classes.timeFrameArrow} />
          </div>
          <IndicatorTriangle className={classes.indicatorTriangle} />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default TimeframeBarChart;
