import { OnboardingPageLayout } from "./OnboardingPageLayout";
import { cloneElement, ReactElement, useContext, useState } from "react";
import useMember from "../../hooks/useMember";
import { setMemberDietaryPreference } from "../../services/members";
import DietaryPreference from "../dietary-preference/DietaryPreference";
import { DietaryPreferencesContext } from "../../components/providers/DietaryPreferencesProvider";

export const DietaryPreferenceStep = ({
  StepperComponent,
  step,
}: {
  StepperComponent: ReactElement;
  step: number;
}) => {
  const { member } = useMember();
  const [saveLoading, setSaveLoading] = useState(false);
  const { checked } = useContext(DietaryPreferencesContext);
  const onSave = async () => {
    setSaveLoading(true);
    await setMemberDietaryPreference(member!.memberId, checked);
    setSaveLoading(false);
    return true;
  };

  return (
    <OnboardingPageLayout
      stepper={cloneElement(StepperComponent, {
        customHandleNext: onSave,
        nextButtonLoading: saveLoading,
      })}
      stepNumber={step}
    >
      <DietaryPreference />
    </OnboardingPageLayout>
  );
};
