import {
  collection,
  DocumentData,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import {
  BaseBasketArticleWithPurchaseDate,
  BasketArticle,
  BasketDay,
  BasketExclusion,
  BasketSyncHistoryRecord,
  BasketSyncStatus,
  FoodGroup,
} from "../models";
import { Collections, db, Fields, Shards } from "./firebase";
import {
  formatGMTToSydneyISO,
  formatGMTToSydneyISOMinusDay,
  oneMonthAgo,
} from "../utils/dates";
import keyBy from "lodash/keyBy";
import { POINTS_PROMO_END_DATE, POINTS_PROMO_START_DATE } from "../constants";

export type BasketSyncStatusChangeListener = (status: BasketSyncStatus) => void;

export const subscribeToBasketSyncStatus = (
  memberId: string,
  onChange: BasketSyncStatusChangeListener
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.BasketSyncHistory
    ),
    orderBy(Fields.BasketSyncHistory.RunDate, "desc"),
    limit(1)
  );
  return onSnapshot(
    q,
    (snapshot) => {
      const [latest] = snapshot.docs;
      const status = latest
        ? (latest.data() as BasketSyncHistoryRecord).status
        : BasketSyncStatus.Unknown;
      onChange(status);
    },
    (error) => {
      console.error("Error reading basket sync status:", error.message);
    }
  );
};

const toRealTimeBasketDetails = (snapshot: QuerySnapshot<DocumentData>) => {
  const aggregates: Map<string, BasketDay> = new Map();

  snapshot.docs.forEach((doc) => {
    const transaction = doc.data() as BasketDay;
    if (!aggregates.has(transaction.purchaseDate)) {
      aggregates.set(transaction.purchaseDate, { ...transaction });
    } else {
      const existingT = aggregates.get(transaction.purchaseDate)!;
      transaction.articles.forEach((article) => {
        const existingArticle = existingT.articles.find(
          (a) => a.articleId === article.articleId
        );

        const fieldsToMerge: (keyof BasketArticle)[] = [
          "packSizeMultiplier",
          "addedSugars",
          "dairy",
          "discretionary",
          "fatSaturated",
          "fatTotal",
          "fruit",
          "grains",
          "protein",
          "quantity",
          "sodium",
          "totalSugars",
          "vegetables",
        ];

        if (existingArticle) {
          fieldsToMerge.forEach((field: keyof BasketArticle) => {
            (existingArticle as any)[field] += article[field];
          });
        } else {
          existingT.articles.push(article);
        }
      });
    }
  });

  return Array.from(aggregates.values());
};

export type BasketDetailsChangeListener = (details: BasketDay[]) => void;
export type BasketDetailsErrorListener = (error: Error) => void;

export const subscribeToFruitAndVegBasketDetails = (
  memberId: string,
  onChange: BasketDetailsChangeListener,
  onError: BasketDetailsErrorListener
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.BasketDetailsSharded
    ),
    where(Fields.BasketDetailsSharded.Shard, "in", Shards.BasketDetailsSharded),
    where(
      Fields.BasketDetailsSharded.PurchaseDate,
      ">=",
      formatGMTToSydneyISOMinusDay(
        formatGMTToSydneyISO(POINTS_PROMO_START_DATE!)
      )
    ),
    where(
      Fields.BasketDetailsSharded.PurchaseDate,
      "<=",
      formatGMTToSydneyISO(POINTS_PROMO_END_DATE!)
    ),
    orderBy(Fields.BasketDetailsSharded.PurchaseDate, "desc")
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const basketDetails: BasketDay[] = [];
      snapshot.forEach((result) => {
        basketDetails.push(result.data() as BasketDay);
      });
      onChange(basketDetails);
    },
    (error) => {
      console.error(
        "Error reading fruit and veg basket details:",
        error.message
      );
      onError(error);
    }
  );
};

export const subscribeToRealTimeBasketDetails = (
  memberId: string,
  onChange: BasketDetailsChangeListener
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.RealTimeDailyBasketDetails
    ),
    where(
      Fields.RealTimeDailyBasketDetails.Shard,
      "in",
      Shards.RealTimeDailyBasketDetails
    ),
    where(Fields.RealTimeDailyBasketDetails.PurchaseDate, ">=", oneMonthAgo()),
    orderBy(Fields.RealTimeDailyBasketDetails.PurchaseDate, "desc")
  );

  return onSnapshot(
    q,
    (snapshot) => {
      onChange(toRealTimeBasketDetails(snapshot));
    },
    (error) => {
      console.error("Error reading real time basket details:", error.message);
    }
  );
};

export const subscribeToCachedBasketDetails = (
  memberId: string,
  onChange: BasketDetailsChangeListener
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.BasketDetailsSharded
    ),
    where(Fields.BasketDetailsSharded.Shard, "in", Shards.BasketDetailsSharded),
    where(Fields.BasketDetailsSharded.PurchaseDate, ">=", oneMonthAgo()),
    orderBy(Fields.BasketDetailsSharded.PurchaseDate, "desc")
  );

  return onSnapshot(
    q,
    (snapshot) => {
      const basketDetails: BasketDay[] = [];
      snapshot.forEach((result) => {
        basketDetails.push(result.data() as BasketDay);
      });
      onChange(basketDetails);
    },
    (error) => {
      console.error("Error reading sharded basket details:", error.message);
    }
  );
};

export const subscribeToBasketDetails = (
  memberId: string,
  onChange: BasketDetailsChangeListener
) => {
  const q = query(
    collection(db, Collections.Members, memberId, Collections.BasketDetails),
    where(Fields.BaseBasketDetails.PurchaseDate, ">=", oneMonthAgo()),
    orderBy(Fields.BaseBasketDetails.PurchaseDate, "desc")
  );
  return onSnapshot(
    q,
    (snapshot) => {
      const basketDetails: BasketDay[] = [];
      snapshot.forEach((result) => {
        basketDetails.push(result.data() as BasketDay);
      });
      onChange(basketDetails);
    },
    (error) => {
      console.error("Error reading basket details:", error.message);
    }
  );
};

const filterBasket = (
  basketDetails: BasketDay[],
  predicate: (article: BasketArticle) => boolean
): BasketDay[] =>
  basketDetails
    .map((basketDay) => ({
      purchaseDate: basketDay.purchaseDate,
      articles: basketDay.articles.filter(predicate),
    }))
    .filter((basketDay) => basketDay.articles.length);

export const filterBasketByDiscretionaryCategory = (
  basketDetails: BasketDay[],
  category: string
): BasketDay[] =>
  filterBasket(
    basketDetails,
    (article) => article.discretionaryCategory === category
  );

export const filterBasketByNonExcludedItems = (
  basketDetails: BasketDay[],
  exclusions: BasketExclusion[]
): BasketDay[] => {
  const exclusionLookup = keyBy(exclusions, getUniqueBasketArticleId);
  return filterBasket(
    basketDetails,
    (article) => !exclusionLookup[getUniqueBasketArticleId(article)]
  );
};

export const totalServingsByFoodGroup = (
  basketDetails: BasketDay[],
  foodGroup: FoodGroup | "discretionary"
): number =>
  basketDetails
    .flatMap((basketDay) => basketDay.articles)
    .reduce((total, article) => total + article[foodGroup], 0);

export const totalServingsByDiscretionaryCategory = (
  basketDetails: BasketDay[],
  category: string
): number =>
  totalServingsByFoodGroup(
    filterBasketByDiscretionaryCategory(basketDetails, category),
    "discretionary"
  );

export const getUniqueBasketArticleId = (
  article: BaseBasketArticleWithPurchaseDate
) => `${article.purchaseDate}_${article.articleId}`;
