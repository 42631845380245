import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import TrolleyLoader from "./TrolleyLoader";

interface Props {
  loading: boolean;
  size?: number;
}

const useStyles = makeStyles(() => ({
  loading: {
    margin: "auto",
    width: "128px",
    minHeight: "128px",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CenteredLoadingIndicator: FC<Props> = ({ loading, size = 80 }) => {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <div className={classes.loading}>
      <TrolleyLoader size={size} />
    </div>
  );
};

export default CenteredLoadingIndicator;
