// If ever needs some changes, take a look at the HouseholdSettingStep.tsx as well

import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CHARCOAL, COLOR_GREYSCALE, WHITE } from "../../theme";

import useHousehold from "../../hooks/useHousehold";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Button from "@material-ui/core/Button";
import {
  ConsentCheckbox,
  FormErrors,
  FormValues,
  HouseholdAddFormV2,
} from "../../components/ConfigToolbarV2/HouseholdAddFormV2";
import { listAgeGroupOptions } from "../../services/contentful/agegroup";
import useBenchmarks from "../../hooks/useBenchmarks";
import { matchToBenchmark } from "../../services/contentful/benchmarks";
import { SettingType } from "../sidebar-settings/SidebarPersonalisation";
import CheckIcon from "@material-ui/icons/Check";

interface Props {
  inSidebar?: boolean;
  setIsOpenPersonalisation?: Dispatch<SetStateAction<SettingType | false>>;
}

interface DeleteFormPopupProps {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: (idx: number | null) => void;
  idToDelete: number | null;
  setIdToDelete: Dispatch<SetStateAction<number | null>>;
}

const DeleteFormPopup = ({
  setIsPopupOpen,
  handleDelete,
  idToDelete,
  setIdToDelete,
}: DeleteFormPopupProps) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    container: {
      position: "fixed",
      zIndex: 10,
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(29, 31, 32, 0.40)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //maxWidth: "375px",
      width: "100%",
      [breakpoints.up("md")]: {
        maxWidth: "391px",
      },
    },
    confirmationBox: {
      padding: "24px 16px",
      backgroundColor: WHITE,
      borderRadius: "12px",
      width: "100%",
      maxWidth: "343px",
      boxSizing: "border-box",
    },
    buttonContainer: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "auto",
    },
    buttonSave: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "144px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "144px",
      boxShadow: "none",
      textTransform: "initial",
    },
    popupMessage: {
      fontFamily: "Gilroy",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      textAlign: "center",
      margin: 0,
      marginBottom: "24px",
    },
  }));
  const classes = useStyles();
  const firstFocusableRef = useRef<HTMLButtonElement | null>(null);
  const lastFocusableRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!firstFocusableRef.current || !lastFocusableRef.current) return;
    const handleTabKey = (e: {
      key: string;
      shiftKey: any;
      preventDefault: () => void;
    }) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableRef.current) {
            lastFocusableRef?.current?.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableRef.current) {
            firstFocusableRef?.current?.focus();
            e.preventDefault();
          }
        }
      }
    };
    firstFocusableRef?.current?.focus();
    document.addEventListener("keydown", handleTabKey);
    return () => {
      document.removeEventListener("keydown", handleTabKey);
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.confirmationBox}>
        <Typography className={classes.popupMessage}>
          Are you sure you want to remove this household member?
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            ref={firstFocusableRef}
            variant={"contained"}
            color={"default"}
            className={classes.buttonCancel}
            onClick={() => {
              setIsPopupOpen(false);
              setIdToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            ref={lastFocusableRef}
            variant={"contained"}
            color={"primary"}
            className={classes.buttonSave}
            onClick={() => {
              handleDelete(idToDelete);
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

const HouseholdSettingV2 = ({ inSidebar, setIsOpenPersonalisation }: Props) => {
  const { currentBenchmarks } = useBenchmarks();
  const { household, addToHousehold, removeFromHousehold } = useHousehold();
  const [saving, setSaving] = useState<boolean>(false);
  const [consented, setConsented] = useState(false);
  const [householdRequiredError, setHouseholdRequiredError] = useState(false);
  const [forms, setForms] = useState<
    {
      formValues: FormValues;
      formErrors: FormErrors;
    }[]
  >([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    title: {
      margin: 0,
      marginBottom: "24px",
      color: CHARCOAL,
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      fontFamily: "'Gilroy-Bold'",
      fontWeight: "normal",
    },
    button: {
      padding: inSidebar ? "8px 18px" : "8px 20px",
      alignItems: "center",
      borderRadius: "10px",
      "& .MuiButton-endIcon": {
        color: COLOR_GREYSCALE[900],
      },
    },
    buttonText: {
      margin: 0,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[900],
    },
    alertText: {
      color: "#DE0000",
      marginBottom: spacing(3),
    },

    buttonContainer: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 0",
      marginTop: "auto",
    },
    buttonSave: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const formlist: {
        formValues: FormValues;
        formErrors: FormErrors;
      }[] = await household.map((h) => {
        const { benchmark } = h;
        const applicableAgeGroupsEnabled =
          benchmark.gender.applicableAgeGroups.map((ageGroups) => {
            return { ...ageGroups, enabled: true };
          });
        const ageRangeGroup = benchmark.ageRange.group;
        ageRangeGroup["enabled"] = true;
        return {
          formValues: {
            ageGroup: ageRangeGroup,
            ageRange: { ...benchmark.ageRange, enabled: true },
            gender: {
              ...benchmark.gender,
              applicableAgeGroups: applicableAgeGroupsEnabled,
            },
            healthConditions: benchmark.healthCondition
              ? [benchmark.healthCondition!]
              : [],
            consented: false,
          },
          formErrors: {},
        };
      });

      setForms(formlist);
    };
    init();
  }, [household]);

  const addForm = async (ageGroupType: "Adult" | "Child") => {
    setHouseholdRequiredError(false);
    const ageGroupOptions = await listAgeGroupOptions();
    const selectedAgeGroup = ageGroupOptions.find(
      (ageGroup) => ageGroup.name === ageGroupType
    );
    const formLength = forms.length;
    await setForms((prev) => [
      ...prev,
      {
        formValues: {
          ageGroup: selectedAgeGroup,
          healthConditions: [],
          consented: false,
        },
        formErrors: {},
      },
    ]);
    const ageGroupInput = document.getElementById(
      `select-ageRange-${formLength}`
    );

    (ageGroupInput as HTMLElement).focus();
  };

  const addAdult = async () => {
    await addForm("Adult");
  };

  const addChild = async () => {
    await addForm("Child");
  };

  /*const handleEditDelete = async (
    householdMember: HouseholdMemberWithBenchmark | null
  ) => {
    if (householdMember) {
      await removeFromHousehold(householdMember);
    }
  };*/

  const validateSingleForm = (formValues: FormValues): FormErrors => {
    let errors: FormErrors = {};

    if (formValues.healthConditions.length > 0 && !formValues.consented) {
      errors.consent = "Please consent";
    }

    if (!formValues.ageRange) {
      errors.ageRange = "Please select an age range";
    }

    if (!formValues.gender) {
      errors.gender = "Please select a gender";
    }

    return errors;
  };

  const validateForms = (): {
    formValues: FormValues;
    formErrors: FormErrors;
  }[] => {
    return forms.map(({ formValues }) => {
      const errors = validateSingleForm(formValues);
      return {
        formValues,
        formErrors: errors,
      };
    });
  };

  const submitForms = async () => {
    const householdsToAdd = forms.map(({ formValues }) => {
      return {
        benchmark: matchToBenchmark(
          currentBenchmarks,
          formValues.ageRange!,
          formValues.gender!,
          formValues.healthConditions
        ),
        consentCopy: formValues.consentCopy,
      };
    });

    const addToHouseholdPromises = householdsToAdd.map((household) =>
      addToHousehold(household.benchmark, household.consentCopy)
    );

    try {
      if (household && household.length > 0) {
        const removeAllExistingHouseholdPromise = household.map((hh) =>
          removeFromHousehold(hh)
        );
        await Promise.all(removeAllExistingHouseholdPromise);
      }
      await Promise.all(addToHouseholdPromises);
    } catch (error) {
      console.error("Error adding one or more households:", error);
    }
  };

  const handleSaveAndNext = async () => {
    setSaving(true);
    const validationResults = validateForms();
    const hasError = validationResults.some(
      ({ formErrors }) => Object.keys(formErrors).length > 0
    );

    if (hasError) {
      setForms(validationResults);
      setSaving(false);
      return false;
    } else if (validationResults.length === 0) {
      setHouseholdRequiredError(true);
      setSaving(false);
      return false;
    } else {
      await submitForms();

      if (inSidebar) {
        setIsOpenPersonalisation!("main");
      }
      setSaving(false);
      return true;
    }
  };

  const ageGroupFormNumbers = useMemo(() => {
    let adultCount = 0;
    let childCount = 0;

    return forms.map((form) => {
      if (form.formValues.ageGroup?.name === "Adult") {
        adultCount++;
        return adultCount;
      } else if (form.formValues.ageGroup?.name === "Child") {
        childCount++;
        return childCount;
      }
      return 0;
    });
  }, [forms]);

  const handleConsentChanged = (consented: boolean) => {
    setConsented(consented);
    setForms((forms) => {
      return forms.map((form) => {
        if (form.formValues.healthConditions!.length !== 0) {
          if (consented) {
            delete form.formErrors.consent;
          }
          form.formValues.consented = consented;
        }
        return form;
      });
    });
  };

  const { consentLocation, formsRequiringConsentCount } = useMemo(() => {
    let consentLocation = -1;
    let formsRequiringConsentCount = 0;
    for (let i = 0; i < forms.length; i++) {
      if (forms[i].formValues.healthConditions.length > 0) {
        formsRequiringConsentCount++;
        if (formsRequiringConsentCount === 1) {
          consentLocation = i;
        }
      }
    }
    return { consentLocation, formsRequiringConsentCount };
  }, [forms]);

  const formDelete = (idx: number | null) => {
    if (idx || idx === 0) {
      const newForms = [...forms];
      newForms.splice(idx, 1);
      setForms(newForms);
    }
    setIdToDelete(null);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    handleConsentChanged(consented);
  }, [consented, formsRequiringConsentCount]);
  return (
    <>
      {isPopupOpen && (
        <DeleteFormPopup
          setIsPopupOpen={setIsPopupOpen}
          handleDelete={formDelete}
          idToDelete={idToDelete}
          setIdToDelete={setIdToDelete}
        />
      )}

      <Typography variant="h3" component="h2" className={classes.title}>
        Tell us who you shop for
      </Typography>
      {forms.map(({ formValues, formErrors }, idx) => {
        const showDeleteButton = forms.length > 1;
        const showConsentCheckbox =
          formsRequiringConsentCount === 1 && idx === consentLocation;
        const showMarginBottom =
          idx + 1 !== forms.length && !showConsentCheckbox;
        return (
          <>
            <HouseholdAddFormV2
              style={{
                marginBottom: showMarginBottom ? "24px" : "0px",
              }}
              showDeleteButton={showDeleteButton}
              index={idx}
              key={idx}
              ageGroupFormNumber={ageGroupFormNumbers[idx]}
              formValues={formValues}
              formErrors={formErrors}
              onFormDelete={() => {
                setIdToDelete(idx);
                setIsPopupOpen(true);
              }}
              onFormValuesChange={(newValues) => {
                const newForms = [...forms];
                newForms[idx].formValues = newValues;
                if (Object.keys(formErrors).length > 0) {
                  newForms[idx].formErrors = validateSingleForm(newValues);
                }
                setForms(newForms);
              }}
            />
            {showConsentCheckbox && (
              <ConsentCheckbox
                onConsentChange={handleConsentChanged}
                consentValue={consented}
                consentError={formErrors.consent}
              />
            )}
          </>
        );
      })}
      {householdRequiredError && (
        <Typography variant="caption" className={classes.alertText}>
          You need to add at least one person for your Food Tracker to be
          accurate.
        </Typography>
      )}
      <div
        style={{
          marginTop:
            forms.length > 0 &&
            (formsRequiringConsentCount !== 1 ||
              (forms.length > 1 && consentLocation + 1 !== forms.length))
              ? "40px"
              : "0px",
          marginBottom: "32px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          className={classes.button}
          endIcon={<AddRoundedIcon />}
          onClick={addAdult}
          style={{
            marginRight: inSidebar ? "15px" : "24px",
          }}
        >
          <Typography className={classes.buttonText}>ADD ADULT</Typography>
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={addChild}
          endIcon={<AddRoundedIcon />}
        >
          <Typography className={classes.buttonText}>ADD CHILD</Typography>
        </Button>
      </div>
      {formsRequiringConsentCount > 1 && (
        <ConsentCheckbox
          onConsentChange={handleConsentChanged}
          consentValue={consented}
          consentError={
            forms.filter((form) => form.formErrors.consent !== undefined)[0]
              ?.formErrors?.consent
          }
        />
      )}
      {inSidebar && (
        <>
          <div style={{ marginTop: 24 }} />
          <div className={classes.buttonContainer}>
            <Button
              variant={"contained"}
              color={"default"}
              className={classes.buttonCancel}
              onClick={() => setIsOpenPersonalisation!("main")}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              endIcon={!saving && <CheckIcon />}
              className={classes.buttonSave}
              onClick={handleSaveAndNext}
              disabled={saving}
            >
              {saving ? "Saving..." : "Save"}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default HouseholdSettingV2;
