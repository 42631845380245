import { useContext } from "react";
import { MemberContext } from "../components/providers/MemberProvider";
import { sendGtagEvent } from "../services/events";
import {
  incrementVisitCount,
  storeConsent,
  unsubscribeFromMarketing,
} from "../services/members";

export default function useMember() {
  const member = useContext(MemberContext);

  return {
    member: member,
    loadingMember:
      !member ||
      member.consented === null ||
      member.shoppingAllocation === undefined,
    storeMemberConsent: async (
      legalsId: string,
      legalsVersion: number,
      consented: boolean = true
    ) => {
      if (member) {
        await storeConsent(member.memberId, consented, {
          id: legalsId,
          version: `${legalsVersion}`,
        });
        sendGtagEvent(member.memberId, "Consent Accepted");
      }
    },
    storeMemberUnconsent: async () => {
      if (member) {
        if (member.email) {
          await unsubscribeFromMarketing(member.memberId, member.email);
        }
        await storeConsent(member.memberId, false);
      }
    },
    incrementMemberVisitCount: async () => {
      if (member && member.consented) {
        await incrementVisitCount(member.memberId);
      }
    },
  };
}
