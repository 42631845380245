import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { BasketArticle, BasketExclusion } from "../../models";
import { MemberContext } from "./MemberProvider";
import {
  addExclusion,
  getBasketExclusions,
  removeExclusion,
} from "../../services/exclusions";
import { getUniqueBasketArticleId } from "../../services/basketdetails";
import {
  addRequiredExclusionAttributesToExclusions,
  hasRequiredExclusionAttributes,
} from "../../services/nutrients";
import { BasketDetailsContext } from "./BasketDetailsProvider";

export const SelectedTimeFrameBasketExclusionsContext = createContext<{
  exclusions: BasketExclusion[];
  exclusionsLoading: boolean;
  setExclusionsLoading: Dispatch<SetStateAction<boolean>>;
  addExclusion: (article: BasketArticle) => Promise<void>;
  removeExclusion: (article: BasketArticle) => Promise<void>;
}>({
  exclusions: [],
  exclusionsLoading: false,
  setExclusionsLoading: () => {},
  addExclusion: async () => {},
  removeExclusion: async () => {},
});

const SelectedTimeFrameBasketExclusionsProvider: FC = ({ children }) => {
  const member = useContext(MemberContext);
  const { basketDetails } = useContext(BasketDetailsContext);
  const [exclusionsLoading, setExclusionsLoading] = useState(false);
  const [exclusions, setExclusions] = useState<BasketExclusion[]>([]);
  const [
    exclusionsAttributeUpdateRequired,
    setExclusionsAttributeUpdateRequired,
  ] = useState(false);

  const memberId = member?.memberId;
  const consented = member?.consented;

  useEffect(() => {
    const init = async () => {
      if (memberId && consented) {
        setExclusionsLoading(true);
        const excls = await getBasketExclusions(memberId);
        if (!hasRequiredExclusionAttributes(excls)) {
          setExclusionsAttributeUpdateRequired(true);
        }
        setExclusions(excls);
        setExclusionsLoading(false);
      }
    };

    init();
  }, [memberId, consented]);

  useEffect(() => {
    const init = async () => {
      if (basketDetails.length === 0) return;

      const products = basketDetails
        .flatMap((day) => day.articles)
        .filter((product: BasketArticle) =>
          exclusions.some(
            (exclusion) =>
              getUniqueBasketArticleId(exclusion) ===
              getUniqueBasketArticleId(product)
          )
        );

      const exclusionsToUpdate = addRequiredExclusionAttributesToExclusions(
        exclusions,
        products
      );

      if (!memberId) {
        throw new Error("Remove basket exclusion failed: member not loaded");
      }

      const updatedExclusion: BasketExclusion[] = [];
      for (const exclusion of exclusionsToUpdate) {
        updatedExclusion.push(await addExclusion(memberId, exclusion));
      }

      setExclusions([
        ...updatedExclusion,
        ...exclusions.filter(
          (exclusions) =>
            !updatedExclusion
              .map((uE) => getUniqueBasketArticleId(uE))
              .includes(getUniqueBasketArticleId(exclusions))
        ),
      ]);
    };

    if (exclusionsAttributeUpdateRequired) {
      init();
    }
    // eslint-disable-next-line
  }, [exclusionsAttributeUpdateRequired, basketDetails.length]);

  const add = async (article: BasketArticle) => {
    if (!memberId) {
      throw new Error("Add basket exclusion failed: member not loaded");
    }

    const exclusion = await addExclusion(memberId, article);
    setExclusions([...exclusions, exclusion]);
  };

  const remove = async (article: BasketArticle) => {
    if (!memberId) {
      throw new Error("Remove basket exclusion failed: member not loaded");
    }

    await removeExclusion(memberId, article);
    setExclusions(
      exclusions.filter(
        (e) => getUniqueBasketArticleId(e) !== getUniqueBasketArticleId(article)
      )
    );
  };

  return (
    <SelectedTimeFrameBasketExclusionsContext.Provider
      value={{
        exclusions,
        exclusionsLoading,
        setExclusionsLoading,
        addExclusion: add,
        removeExclusion: remove,
      }}
    >
      {children}
    </SelectedTimeFrameBasketExclusionsContext.Provider>
  );
};

export default SelectedTimeFrameBasketExclusionsProvider;
