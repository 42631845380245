import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
  InfoModalTitle,
} from "../InfoModal";
import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import ExternalLink from "../../components/ExternalLink";
import {
  FAQ_ACCORDION_EVENT,
  FOOD_GROUP_PAGES_EVENT,
  HEALTHIER_OPTIONS_PDP_EVENT,
  HEALTHIER_OPTIONS_PLP_EVENT,
  HOMEPAGE_EVENT,
  MAIN_VS_SOMETIMES_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../events";
import { HealthierOptionsFoodGroup } from "../../models";
import useEvents from "../../hooks/useEvents";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { COLOR_TEAL } from "../../theme";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

export const HealthierOptionsExplainer = ({
  forHomepage = false,
  source,
  foodGroup,
}: {
  forHomepage?: boolean;
  source: string;
  foodGroup?: HealthierOptionsFoodGroup;
}) => {
  const useStyles = makeStyles(() => ({
    icon: {
      width: 21,
      height: 21,
    },
    listSpacing: {
      "& > li": {
        marginBottom: "4px",
      },
    },
    dpToggleBtn: {
      padding: 0,
      border: 0,
      backgroundColor: "transparent",
      textDecoration: "underline",
      letterSpacing: "0.3px",
      cursor: "pointer",
      color: COLOR_TEAL[700],
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Gilroy",
      verticalAlign: "baseline",
    },
  }));
  const { track } = useEvents();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const faqTitles = [
    "What is Health Star Rating?",
    "Is my Healthier Option the same price?",
    "How are my Healthier Option(s) selected?",
    "Are Healthier Options suitable for my dietary requirements?",
    "Why can’t I see Healthier Option(s) for some of my products?",
    "What do the symbols mean next to my Healthier Options?",
  ];
  const handleChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const { setIsOpenPersonalisation } = useContext(SettingsContext);

  const classes = useStyles();

  const handleOpenEvent = () => {
    if (!forHomepage) {
      switch (source) {
        case "healthier-options-pdp":
          return HEALTHIER_OPTIONS_PDP_EVENT.HO_HELP_OPEN;
        case "healthier-options-plp":
          return HEALTHIER_OPTIONS_PLP_EVENT.HO_HELP_OPEN;
        case "main-vs-sometimes":
          return MAIN_VS_SOMETIMES_EVENT.HO_HELP_OPEN;
        case "food-group":
          return FOOD_GROUP_PAGES_EVENT.HO_HELP_OPEN;
        case "sometimes-food":
          return SOMETIMES_FOOD_PAGE_EVENT.HO_HELP_OPEN;
      }
    }
    return HOMEPAGE_EVENT.HO_HELP_OPEN;
  };

  const trackerOpen = handleOpenEvent();

  const handleCloseEvent = () => {
    if (!forHomepage) {
      switch (source) {
        case "healthier-options-pdp":
          return HEALTHIER_OPTIONS_PDP_EVENT.HO_HELP_CLOSE;
        case "healthier-options-plp":
          return HEALTHIER_OPTIONS_PLP_EVENT.HO_HELP_CLOSE;
        case "main-vs-sometimes":
          return MAIN_VS_SOMETIMES_EVENT.HO_HELP_CLOSE;
        case "food-group":
          return FOOD_GROUP_PAGES_EVENT.HO_HELP_CLOSE;
        case "sometimes-food":
          return SOMETIMES_FOOD_PAGE_EVENT.HO_HELP_CLOSE;
      }
    }
    return HOMEPAGE_EVENT.HO_HELP_CLOSE;
  };

  const trackerClose = handleCloseEvent();
  const hideDP = useHideDietaryPreferences();
  return (
    <InfoModalContainer
      eventName={trackerOpen}
      eventCloseName={trackerClose}
      eventParams={
        source === "food-group" && foodGroup
          ? { foodgroup: foodGroup }
          : undefined
      }
      button={<InfoModalDefaultButton />}
    >
      <InfoModalContent>
        Healthier Options shows you similar products that are healthier, based
        on the product’s Health Star Rating.
      </InfoModalContent>
      <InfoModalTitle>
        What makes the Healthier Options healthier?
      </InfoModalTitle>
      <InfoModalContent>
        Your Healthier Options have a higher{" "}
        <ExternalLink
          underline={"always"}
          aria-label="Health Star Rating"
          href="http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/Content/About-health-stars"
        >
          Health Star Rating
        </ExternalLink>{" "}
        or calculated health rating (calculated by using the{" "}
        <ExternalLink
          aria-label="Health Star Rating Calculator"
          underline={"always"}
          href="http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/Content/excel-calculator"
        >
          Health Star Rating Calculator
        </ExternalLink>
        ) than your previously purchased products. It may also be lower* in
        saturated fat, sugar or salt.
      </InfoModalContent>
      <InfoModalContent>
        *At least 25% lower when compared to the original product
      </InfoModalContent>
      <InfoModalContent>
        In short, healthier Options does the nutritional panel detective work so
        you don’t have to!
      </InfoModalContent>
      <InfoModalTitle>How are my Healthier Option(s) selected?</InfoModalTitle>
      <InfoModalContent>
        We selected products that are similar to your previously purchased
        products and within 10% price variance, then rank them based on Health
        Star Rating.{" "}
        {!hideDP &&
          "We also use your selected dietary preferences to sort the products."}{" "}
        You can choose to view your Healthier Options based on week, fortnight
        or month view.
      </InfoModalContent>
      <InfoModalTitle>FAQs</InfoModalTitle>
      <AccordionContainer>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1", 0)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<AccordionIcon />}
          >
            What is Health Star Rating?
          </AccordionSummary>
          <AccordionDetails>
            <InfoModalContent>
              <ExternalLink
                underline={"always"}
                href="http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/Content/About-health-stars"
              >
                Health Star Rating
              </ExternalLink>{" "}
              allows you to quickly compare foods in the same category, such as
              a breakfast cereal with another or a muesli bar with another.{" "}
              <br /> <br />
              It takes into account the product’s nutritional value, like the
              energy, sodium, saturated fat, total sugar, fibre, and protein
              content, as well as the amount of fruit, veggies, nuts or legumes,
              and rates it anywhere between ½ a star and 5 stars. The more
              stars, the healthier the product choice, when compared to products
              in the same category.
            </InfoModalContent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2", 1)}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<AccordionIcon />}
          >
            Is my Healthier Option the same price?
          </AccordionSummary>
          <AccordionDetails>
            <InfoModalContent>
              Healthier Options are within 10% of the price of your original
              item. We also display options that are matched on product type,
              weight and pack size, which means you’ll be able to enjoy
              something that’s similar but healthier for you. How good is that?
            </InfoModalContent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3", 2)}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={<AccordionIcon />}
          >
            How are my Healthier Option(s) selected?
          </AccordionSummary>
          <AccordionDetails>
            <InfoModalContent>
              We select products that are similar to your previously purchased
              products and within 10% price variance, then rank them based on
              Health Star Rating.{" "}
              {!hideDP &&
                "We also use your selected dietary preferences to sort the products."}{" "}
              You can choose to view your Healthier Options based on week,
              fortnight or month view.
            </InfoModalContent>
          </AccordionDetails>
        </Accordion>
        {!hideDP && (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4", 3)}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<AccordionIcon />}
            >
              Are Healthier Options suitable for my dietary requirements?
            </AccordionSummary>
            <AccordionDetails>
              <InfoModalContent>
                Healthier Options factors in your selected{" "}
                <button
                  className={classes.dpToggleBtn}
                  onClick={() => setIsOpenPersonalisation("dietary")}
                >
                  Dietary Preferences
                </button>
                . Look out for the corresponding logo on the products below to
                understand if they're right for you ie VE, V, GF etc. This
                assists in finding products and recipes that align with your
                dietary or lifestyle preferences. It's not a substitute for
                medical advice or reviewing a product label in full.
              </InfoModalContent>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5", 4)}
        >
          <AccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            expandIcon={<AccordionIcon />}
          >
            Why can’t I see Healthier Option(s) for some of my products?
          </AccordionSummary>
          <AccordionDetails>
            <InfoModalContent>
              Healthier Options won’t display where we don’t have a product’s
              Health Star Rating nor sufficient product data to calculate a
              product’s health rating using the{" "}
              <ExternalLink
                underline={"always"}
                href="http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/content/home"
              >
                Government’s Health Star Rating calculator
              </ExternalLink>
              . <br />
              <br />
              Also, certain product categories won’t have Healthier Options
              provided. These include:
              <ul className={classes.listSpacing}>
                <li>
                  Fruit and veggies, including fresh and frozen, with no added
                  sugar, fat or salt
                </li>
                <li>
                  Foods that aren’t required to display nutrition information,
                  such as vinegar, tea bags, herbs and spices
                </li>
                <li>
                  Foods that are not permitted to display a Health Star Rating,
                  including alcoholic beverages and certain special-purpose
                  foods, such as infant formula, foods for infants and
                  formulated supplementary sports foods
                </li>
                <li>
                  Products where there are simply no similar products that have
                  a Health Star Rating or calculated health rating that’s at
                  least ½ a star higher than the product you’re viewing
                </li>
              </ul>
            </InfoModalContent>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6", 5)}
        >
          <AccordionSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
            expandIcon={<AccordionIcon />}
          >
            What do the symbols mean next to my Healthier Options?
          </AccordionSummary>
          <AccordionDetails>
            <InfoModalContent>
              <ul className={classes.listSpacing}>
                <li>
                  The saturated fat, sugar and salt symbols indicate the
                  Healthier Options product is at least 25% lower in that
                  particular nutrient, when compared to the original product.
                  This calculation is based on available product information.
                </li>
                {!hideDP && (
                  <li>The VE, V, GF etc indicate dietary preferences</li>
                )}
              </ul>
            </InfoModalContent>
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
    </InfoModalContainer>
  );
};
