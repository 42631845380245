import { makeStyles, Typography } from "@material-ui/core";
import Blockquote from "../../components-2/Blockquote";
import { ReactComponent as Star } from "../../icons/star.svg";
import { ReactComponent as Alert } from "../../icons/alert-circle.svg";
import { ReactComponent as SometimesFoodIcon } from "../../icons/sometimes-food-v2.svg";
import RecommendedServesExplainer from "../food-groups/recommended-serves/RecommendedServesExplainer";
import { COLOR_GREYSCALE } from "../../theme";
import SometimesFoodsBreakdownChart from "./SometimesFoodsBreakdownChart";
import useBasketDetails from "../../hooks/useBasketDetails";
import { WarningOutlined } from "@material-ui/icons";
import TrolleyLoader from "../TrolleyLoader";

interface Props {
  showHeader?: boolean;
  categories: {
    id: string;
    title: string;
    alias: string;
    description: string;
  }[];
}

const SometimesFoodsBreakdown = ({ showHeader = true, categories }: Props) => {
  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: "16px",
      paddingBottom: "16px",
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    header: {
      display: "flex",
      alignItems: "center",
      margin: 0,
      marginBottom: 10,
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      paddingBottom: 10,
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      borderRadius: 40,
      marginRight: 8,
      "& svg": {
        width: 24,
        height: 24,
      },
    },
    warningContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "24px",
      marginBottom: "24px",
      padding: "36px 24px",
      borderRadius: "12px",
      backgroundColor: COLOR_GREYSCALE[50],
    },
    warningIcon: {
      marginRight: "16px",
      color: COLOR_GREYSCALE[700],
    },
    center: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    },
  }));

  const classes = useStyles();

  const {
    basketDetailsEmpty: empty,
    basketDetailsError: error,
    basketDetailsLoading: loading,
    basketDetails,
  } = useBasketDetails("discretionary");

  return (
    <div className={classes.root}>
      {showHeader && (
        <Typography variant="h2" component="h1" className={classes.header}>
          <span className={classes.icon}>
            <SometimesFoodIcon />
          </span>
          <span style={{ display: "inline-block", marginRight: 4 }}>
            Sometimes foods breakdown
          </span>
          <RecommendedServesExplainer foodGroup="sometimes" />
        </Typography>
      )}
      {loading || error ? (
        loading ? (
          <div className={classes.center}>
            <TrolleyLoader size={64} />
          </div>
        ) : (
          <div className={classes.warningContainer}>
            <WarningOutlined className={classes.warningIcon} />
            <Typography variant="body2" component="h2">
              "Sorry, an error occurred loading data for this period. Please
              check back later."
            </Typography>
          </div>
        )
      ) : (
        <>
          <SometimesFoodsBreakdownChart
            basketDetails={basketDetails}
            categories={categories}
            isEmpty={empty}
          />
          {empty && (
            <div style={{ marginTop: "20px" }}>
              <Blockquote
                icon={empty ? <Alert /> : <Star />}
                title={empty ? "" : "You're quite the Food Tracker!"}
                text={
                  empty
                    ? "Bummer! We don't have anything to show you at the moment. Check back once you’ve shopped."
                    : "Your main foods have increased in the past 3 months"
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SometimesFoodsBreakdown;
