import { Button, Drawer, makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { SidebarSettingsContent } from "../sidebar-settings/SidebarSettings";
import { ReactComponent as Success } from "../../icons/success.svg";

const SuccessfulSignupDrawer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const classes = makeStyles(() => ({
    container: {
      padding: "0px 24px 0px 24px",
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      height: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    saveSuccessContainer: {
      display: "flex",
      padding: "120px 0",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
    },
    success: {
      margin: "24px 0px 8px",
      fontFamily: "Gilroy-Bold",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[900],
      textAlign: "center",
    },
    message: {
      margin: 0,
      fontFamily: "Gilroy",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      textAlign: "center",
    },
    messageSmall: {
      margin: 0,
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      textAlign: "center",
    },
    button: {
      width: "100%",
      border: `2px solid ${COLOR_GREYSCALE[800]}`,
      backgroundColor: "transparent",
      borderRadius: "10px",
      boxShadow: "none",
      textTransform: "none",
      fontSize: "16px",
    },
  }))();

  const SaveSuccess = () => {
    return (
      <div className={classes.saveSuccessContainer}>
        <Success />
        <p className={classes.success}>Success!</p>
        <p className={classes.message}>You've successfully signed up!</p>
      </div>
    );
  };

  const ManagePreferences = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          paddingBottom: "24px",
        }}
      >
        <p className={classes.messageSmall}>
          You can manage your preferences in the settings tab.
        </p>
        <Button className={classes.button} onClick={handleClose}>
          Okay
        </Button>
      </div>
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <SidebarSettingsContent
        setSidebarOpen={() => {}}
        sidebarOpen={true}
        backButtonAction={handleClose}
        showBackButton={true}
        contentContainerExtended={true}
        fullHeight={true}
        customTitle="Get instant updates"
      >
        <div className={classes.container}>
          <SaveSuccess />
          <ManagePreferences />
        </div>
      </SidebarSettingsContent>
    </Drawer>
  );
};

export default SuccessfulSignupDrawer;
