import { makeStyles, Typography } from "@material-ui/core";
import { useContext } from "react";
import useDiscretionaryPercentHistory from "../../hooks/useDiscretionaryPercentHistory";
import { ReactComponent as IndicatorTriangle } from "../../icons/day-indicator-triangle.svg";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";
import TrolleyLoader from "../../components/TrolleyLoader";
import SometimesFoodsTrendBar from "./MainVsSometimesFoodsTrendBar";
import { COLOR_GREYSCALE } from "../../theme";

const useStyles = makeStyles(({ spacing }) => ({
  barsContainer: {
    marginTop: spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  lastSixLabel: {
    marginTop: spacing(1),
    fontWeight: "normal",
    fontFamily: "Gilroy-SemiBold",
    paddingBottom: "16px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  barYAxis: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  yAxisLabels: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    fontFamily: "Gilroy",
    color: COLOR_GREYSCALE[700],
    minWidth: "30px",
  },

  timeFrameContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  timeFrame: {
    width: "90%",
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  timeFrameDuration: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "12px",
    lineHeight: "16px",
    maxWidth: "100px",
    width: "100%",
    marginRight: "8px",
    color: COLOR_GREYSCALE[700],
    letterSpacing: "0.3px",
    fontFamily: "Gilroy",
    margin: 0,
  },
  indicatorTriangleContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50px",
  },
  arrow: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  arrowHead: {
    position: "absolute",
    borderRight: `1px solid ${COLOR_GREYSCALE[200]}`,
    borderBottom: `1px solid ${COLOR_GREYSCALE[200]}`,
    height: "6px",
    width: "6px",
    left: "10px",
    transform: "rotate(135deg)",
    bottom: "3px",
  },
  arrowBody: {
    position: "absolute",
    left: "10px",
    bottom: "6px",
    width: "90%",
    height: "1px",
    backgroundColor: COLOR_GREYSCALE[200],
  },
  month: {
    fontFamily: "Gilroy",
    color: COLOR_GREYSCALE[700],
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "0.2px",
    marginTop: "8px",
  },
}));

const MainVsSometimesFoodsTrends = () => {
  const classes = useStyles();
  const { discretionaryPercentDataList, loading } =
    useDiscretionaryPercentHistory();
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  if (!discretionaryPercentDataList.length) {
    return (
      <div className={classes.center}>
        <TrolleyLoader size={64} />
      </div>
    );
  }

  return (
    <>
      <Typography
        variant="body2"
        className={classes.lastSixLabel}
        aria-hidden="true"
      >
        Last 6 {timeFrame.toLowerCase()}s
      </Typography>
      <div
        className={classes.barsContainer}
        aria-label={`In your last six ${timeFrame} your split was`}
        role={"group"}
      >
        <div className={classes.barYAxis}>
          <div className={classes.yAxisLabels}>
            {["0", "20", "40", "60", "80", "100"].reverse().map((label) => (
              <span>{label}%</span>
            ))}
          </div>
        </div>
        {discretionaryPercentDataList.map((dpd, idx) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SometimesFoodsTrendBar
                key={idx}
                discretionaryPercentData={dpd}
                loading={loading}
              />
              {dpd.month && <div className={classes.month}>{dpd.month}</div>}
            </div>
          );
        })}
      </div>
      <div className={classes.timeFrameContainer}>
        <div className={classes.timeFrame}>
          <p className={classes.timeFrameDuration}>
            6 {timeFrame.toLowerCase()}s ago
          </p>
          <div className={classes.arrow}>
            <div className={classes.arrowHead}></div>
            <div className={classes.arrowBody}></div>
          </div>
          <div className={classes.indicatorTriangleContainer}>
            <IndicatorTriangle width="12" height="12" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainVsSometimesFoodsTrends;
