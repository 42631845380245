import React, { Dispatch, memo, ReactElement, SetStateAction } from "react";
import { DefaultCard } from "./defaultCard";

interface CardProps {
  image: string;
  title: string;
  content: {
    buttonColor: string;
    title: string | ReactElement;
    image: string;
    content: (ReactElement | string)[];
  }[];
  uniqueKey: string;
  openCard: string | null;
  setOpenCard: Dispatch<SetStateAction<string | null>>;
  layoutId: string;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export const AnimatedImageCard = memo(
  ({
    image,
    title,
    content,
    uniqueKey,
    openCard,
    setOpenCard,
    layoutId,
    setOpenDrawer,
  }: CardProps) => {
    return (
      <DefaultCard
        key={uniqueKey}
        content={content}
        title={title}
        uniqueKey={uniqueKey}
        image={image}
        setOpenCard={setOpenCard}
        layoutId={layoutId}
        isOpen={openCard === uniqueKey}
        setOpenDrawer={setOpenDrawer}
      />
    );
  }
);
