import makeStyles from "@material-ui/core/styles/makeStyles";
import { WHITE } from "../../theme";
import IntakeFoodGroup from "./IntakeFoodGroup";
import { grey } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";

// TODO do this a less dumb way

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    marginTop: spacing(2),
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: WHITE,
    opacity: 0.9,
    zIndex: 10,
    borderRadius: 12,
  },
  textContainer: {
    position: "absolute",
    top: "calc(50% - 50px)",
    left: "calc(50% - 150px)",
    padding: spacing(1.5, 0),
    width: 300,
    zIndex: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    padding: spacing(0.5),
    textAlign: "center",
    lineHeight: "24px",
    fontFamily: '"Montserrat"',
    color: "#DE0000",
    textDecoration: "underline",
  },
  descriptionList: {
    marginTop: "18px",
    marginBottom: "18px",
    color: grey[600],
  },
  descriptionText: {
    color: grey[600],
    fontSize: 14,
    margin: 0,
    [breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
}));

const FoodGroupInsightsContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} role="group">
      <ul className={classes.descriptionList}>
        <li>
          <Typography variant="body1" className={classes.descriptionText}>
            Learn more about each food group
          </Typography>
        </li>
        <li>
          <Typography variant="body1" className={classes.descriptionText}>
            View your purchases
          </Typography>
        </li>
        <li>
          <Typography variant="body1" className={classes.descriptionText}>
            Get access to helpful resources
          </Typography>
        </li>
      </ul>

      <IntakeFoodGroup />
    </div>
  );
};

export default FoodGroupInsightsContent;
