import { AgeGroup } from "../../models";
import { AGE_GROUP_OPTIONS } from "./hardcodedContentfulContent";

export const mapToAgeGroup = (item: any): AgeGroup => ({
  id: item.sys.id,
  name: item.name,
  enabled: item.enabled,
});

export const listAgeGroupOptions = async (): Promise<AgeGroup[]> => {
  // Used to fetched from Contentful, now hard coded.
  return AGE_GROUP_OPTIONS;
};
