import { useContext } from "react";
import { MemberContext } from "../components/providers/MemberProvider";
import { track } from "../services/events";
import { isNonProdEnv } from "../utils/debug";

const useEvents = () => {
  const member = useContext(MemberContext);

  return {
    track: (event: string, props?: Record<string, unknown>) => {
      if (member?.memberId) {
        if (isNonProdEnv()) {
          console.log(event, props);
        }
        track(member.memberId, event, props);
      }
    },
  };
};

export default useEvents;
