import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

interface Props extends DialogProps {
  title: string;
  onClose: () => void;
  maxWidth?: Breakpoint;
  mobileFullScreen?: boolean;
  contentClassName?: string;
  hideTitleVisually?: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  closeButton: {
    position: "absolute",
    right: spacing(1),
    top: spacing(1),
  },
  closeIcon: {
    color: palette.text.primary,
  },
  titleText: {
    marginTop: spacing(1),
    marginRight: spacing(4),
    maxWidth: 280,
    fontFamily: '"Gilroy-Bold"',
    fontWeight: "normal",
    fontSize: "20px",
    margin: "0",
    letterSpacing: "0.3px",
    lineHeight: "20px",
  },
  titleTextVisuallyHidden: {
    // This is a hack to hide the title visually but keep it accessible
    // for screen readers. This is because some charts have the text hard coded
    // into the picture, and we don't want to duplicate the text.
    color: "white",
    height: "1px",
    userSelect: "none",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
  },
  dialogHeader: {
    padding: "16px 24px",
  },
}));

export default function StyledDialog({
  children,
  title,
  onClose,
  maxWidth = "sm",
  mobileFullScreen = true,
  hideTitleVisually = false,
  ...rest
}: Props) {
  const classes = useStyles();
  const { breakpoints } = useTheme();

  const showFullScreen = useMediaQuery(breakpoints.down(maxWidth));
  return (
    <Dialog
      onClose={onClose}
      fullScreen={mobileFullScreen && showFullScreen}
      maxWidth={maxWidth}
      TransitionProps={{ role: "presentation" } as any}
      PaperProps={{ role: "main" } as any}
      {...rest}
    >
      <div className={classes.dialogHeader}>
        <Typography
          className={`${classes.titleText} ${
            hideTitleVisually ? classes.titleTextVisuallyHidden : ""
          }`}
          component={"h1"}
          aria-live="polite"
        >
          {title}
        </Typography>
        <IconButton
          aria-label="Close Button"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
