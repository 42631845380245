import { useHistory, useLocation } from "react-router-dom";
import HealthierOptionsProductDisplayPageComp from "../components-2/healthier-options/HealthierOptionsProductDisplayPageComp";
import { PageLayout } from "../components-2/page-layout";
import { HEALTHIER_OPTIONS_PLP_EVENT, NUTRIENT_TRACKER_EVENT } from "../events";
import useEvents from "../hooks/useEvents";

const HealthierOptionsProductDisplayPage = () => {
  const history = useHistory();
  const { track } = useEvents();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get("source");

  const handleTrackBack = () => {
    switch (source) {
      case "nutrient-tracker":
        return track(NUTRIENT_TRACKER_EVENT.HO_CLOSED);
      case "ho-plp":
        return track(HEALTHIER_OPTIONS_PLP_EVENT.HO_CLOSED)
      default:
        return;
    }
  };

  return (
    <PageLayout
      title={"Healthier Options"}
      onBackClick={() => {
        history.goBack();
        handleTrackBack();
      }}
    >
      <HealthierOptionsProductDisplayPageComp />
    </PageLayout>
  );
};

export default HealthierOptionsProductDisplayPage;
