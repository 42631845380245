import { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import { COLOR_GREYSCALE } from "../../theme";
import { ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { ConfettiContext } from "../../components/providers/ConfettiProvider";
import useConfetti from "../../hooks/useConfetti";
import { HOMEPAGE_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";

const FruitAndVegChallengeCompleted = ({
  totalPoints,
  showLeaderboardText,
}: {
  totalPoints: number;
  showLeaderboardText: boolean;
}) => {
  const { track } = useEvents();
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      marginTop: "24px",
      columnGap: "24px",
      display: "flex",
      flexDirection: "row",
      borderRadius: "12px",
      padding: "16px",
      background: "white",
      boxShadow: "0px 1px 2px 0px #0000001A",
      position: "relative",
      overflow: "hidden",
      justifyContent: "space-between",
      [breakpoints.up("md")]: {
        marginBottom: "12px",
      },
      [`@media (min-width: ${1024}px) and (max-width: ${1162}px)`]: {
        marginBottom: "48px",
      },
    },
    viewAllText: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      columnGap: "8px",
      textDecoration: "none",
    },
    viewAllBtn: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: "10px",
      borderTop: "solid 1px #E9EBEC",
      boxSizing: "border-box",
      textDecoration: "none",
      color: COLOR_GREYSCALE[900],
    },
    pointsContainer: {
      zIndex: 1,
      position: "relative",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 0,
      flexShrink: 1,
    },
    pointsText: {
      fontFamily: "Gilroy-SemiBold",
      width: "max-content",
      lineHeight: "22.46px",
      fontSize: "16px",
      color: "#39464E",
      margin: 0,
      textWrap: "nowrap!important",
    },
    image: {
      width: "45px",
      height: "31px",
    },
    points: {
      fontFamily: "Gilroy-SemiBold",
      lineHeight: "42px",
      fontSize: "36px",
      color: "#39464E",
      margin: 0,
    },
    content:
      totalPoints < 1
        ? {
            zIndex: 1,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            [breakpoints.up("md")]: {
              flexGrow: 0,
            },
          }
        : {
            zIndex: 1,
            flexGrow: 0,
          },
    intro: {
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[800],
      margin: 0,
    },
    title: {
      display: "inline-block",
      fontSize: "24px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      lineHeight: "30px",
      letterSpacing: "0.3px",
      zIndex: 1,
      position: "relative",
      marginBottom: "20px",
      [breakpoints.up("md")]: {
        fontSize: "36px",
        lineHeight: "48px",
        marginBottom: "16px",
      },
    },
    fruitAndVegChallengeTitleContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: -1,
    },
    challengeText: {
      zIndex: 2,
      position: "relative",
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 122,
      height: 15,
      zIndex: 1,
      [breakpoints.up("md")]: {
        width: 182,
        height: 30,
      },
    },
    confettiImage: {
      position: "absolute",
      right: "-1px",
      zIndex: 0,
      top: 0,
    },
    canvas: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      pointerEvents: "none",
    },
  }));
  const { attemptFruitAndVegCampaignConfetti } = useConfetti();

  useEffect(() => {
    attemptFruitAndVegCampaignConfetti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const { fruitAndVegCampaignConfettiRef } = useContext(ConfettiContext);

  return (
    <div className={classes.root}>
      <canvas className={classes.canvas} ref={fruitAndVegCampaignConfettiRef} />
      <div className={classes.content}>
        <p className={classes.intro}>You've completed the</p>
        <div className={classes.title}>
          <div className={classes.fruitAndVegChallengeTitleContainer}>
            <GreenRectangle className={classes.greenRectangle} />
            <span className={classes.challengeText}>Fruit &amp; Veg</span>
          </div>{" "}
          Challenge!
        </div>
        {totalPoints > 0 && (
          <Link
            to="/fruit-and-veg-challenge"
            className={classes.viewAllBtn}
            onClick={() => {
              track(HOMEPAGE_EVENT.VIEW_PURCHASES_AND_LEADERBOARD);
            }}
          >
            <p className={classes.viewAllText}>
              View purchases {showLeaderboardText ? "& leaderboard" : ""}
            </p>
            <ChevronRight />
          </Link>
        )}
      </div>
      <div className={classes.pointsContainer}>
        <img
          className={classes.image}
          src={
            "https://storage.googleapis.com/food-tracker-images-prod/rewards-icon.png"
          }
          alt={"edr card"}
        />
        <p className={classes.pointsText}>Collected</p>
        <p className={classes.points}>{totalPoints}</p>
        <p className={classes.pointsText}>bonus points</p>
      </div>
    </div>
  );
};

export default FruitAndVegChallengeCompleted;
