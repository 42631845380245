import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { FoodGroup } from "../../../models";
import { FoodGroupColorIntake } from "../../../constants";
import { COLOR_GREYSCALE } from "../../../theme";

interface Props {
  foodGroup?: FoodGroup | "discretionary";
  isSometimesFoodGroup?: boolean;
  servingsPurchased: number | null;
  householdBenchmarkServings: number | null;
  delayIndex: number;
  month: string;
  isMonthTimeframe: boolean;
  servingsExceeded: boolean;
}

const TimeframeBar: FC<Props> = ({
  foodGroup,
  servingsPurchased,
  householdBenchmarkServings,
  delayIndex,
  month,
  isMonthTimeframe,
  servingsExceeded,
}) => {
  const barColors: {
    [key in FoodGroup | "discretionary"]: { prev: string; latest: string };
  } = {
    dairy: {
      prev: "#E2F0FF",
      latest: FoodGroupColorIntake[foodGroup as FoodGroup],
    },
    grains: {
      prev: "#FFD599",
      latest: FoodGroupColorIntake[foodGroup as FoodGroup],
    },
    vegetables: {
      prev: "#E3F9A6",
      latest: FoodGroupColorIntake[foodGroup as FoodGroup],
    },
    fruit: {
      prev: "#E5C8F4",
      latest: FoodGroupColorIntake[foodGroup as FoodGroup],
    },
    protein: {
      prev: "#FFA9BD",
      latest: FoodGroupColorIntake[foodGroup as FoodGroup],
    },
    discretionary: {
      prev: COLOR_GREYSCALE[100],
      latest: "#FFD60A",
    },
  };

  const calculateHeight = (
    servesPercentage: number,
    keyPercentage: number
  ): number => {
    const containerHeight = 100;
    const servesHeight = containerHeight / (keyPercentage / 100);
    return servesHeight * (servesPercentage / 100);
  };

  const noData = !servingsPurchased && !householdBenchmarkServings;
  const barTotalHeightPercent = !noData
    ? calculateHeight(
        (servingsPurchased! / householdBenchmarkServings!) * 100,
        servingsExceeded ? 150 : 100
      )
    : 0;

  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      transitionDelay: delayIndex >= 0 ? `${delayIndex * 0.2}s` : "0s",
      height: "100%",
      display: "flex",
      position: "relative",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      alignSelf: "center",
      justifyContent: "center",
      "&:nth-of-type(6) .barValue": {
        backgroundColor: barColors[foodGroup!].latest,
      },
    },
    bar: {
      display: "flex",
      position: "relative",
      width: "34px",
      overflow: "visible",
      height: "100%",
      alignItems: "flex-end",
      justifyContent: "center",
      "&.no-data": {
        height: "100%",
        border: "dashed 1px #D3D6D9",
        alignItems: "center",
        flexWrap: "wrap",
        fontSize: "10px",
        lineHeight: "12px",
        letterSpacing: "-0.02em",
        color: "#585C60",
        textAlign: "center",
      },
      [breakpoints.up("md")]: {
        width: "40px",
      },
      [breakpoints.up("xl")]: {
        width: "57.5px",
      },
    },
    barValue: {
      overflow: "hidden",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      zIndex: 2,
      height: `${barTotalHeightPercent}%`,
      backgroundColor: barColors[foodGroup!].prev,
      width: "100%",
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "4px",
      boxSizing: "border-box",
      transition: "height .5s ease-in-out",
      transitionDelay: delayIndex >= 0 ? `${delayIndex * 0.2}s` : "0s",
      maxHeight: "100%",
    },
    barValueText: {
      fontSize: "10px",
      lineHeight: "16px",
      color: "#FFFFFF",
      marginTop: "7px",
    },
    monthLabel: {
      position: "absolute",
      bottom: "-20px",
      left: "50%",
      transform: "translateX(-50%)",
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[700],
    },
  }));
  const classes = useStyles();

  const bar = () => {
    return (
      <div className={classes.bar}>
        <div className={`barValue ${classes.barValue}`}></div>
      </div>
    );
  };

  const noDataBar = () => {
    return <div className={`${classes.bar} no-data`} />;
  };

  return (
    <div className={`bar ${classes.root}`}>
      <>
        {householdBenchmarkServings &&
        servingsPurchased &&
        servingsPurchased >= 0.01
          ? bar()
          : noDataBar()}
      </>
      {isMonthTimeframe && <span className={classes.monthLabel}>{month}</span>}
    </div>
  );
};

export default TimeframeBar;
