import { useEffect, useState } from "react";
import {
  fetchFoodGroupPage,
  FoodGroupPageContent,
} from "../services/contentful/foodgroups";
import { FoodGroup, SOMETIMES_FOOD } from "../models";

const useFetchFoodGroupPage = (
  foodGroup: FoodGroup | typeof SOMETIMES_FOOD
) => {
  const [content, setContent] = useState<FoodGroupPageContent>();

  useEffect(() => {
    const init = async () => {
      setContent(await fetchFoodGroupPage(foodGroup));
    };

    init();
  }, [foodGroup]);

  return content;
};

export default useFetchFoodGroupPage;
