import SidebarSettings from "./SidebarSettings";
import { Button, Typography, makeStyles, Grid } from "@material-ui/core";
import { CHARCOAL, COLOR_GREYSCALE, WHITE } from "../../theme";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ReactComponent as HeadingIcon } from "../../icons/settings-icon.svg";
import { ReactComponent as Chevron } from "../../icons/chevron-right.svg";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { ChevronRight } from "@material-ui/icons";
import ShoppingAllocationV2 from "../../components/ConfigToolbarV2/ShoppingAllocationV2";
import useMember from "../../hooks/useMember";
import CheckIcon from "@material-ui/icons/Check";
import { setMemberGoals, setShoppingAllocation } from "../../services/members";
import HouseholdSettingV2 from "../household-setting/HouseholdSettingV2";
import { GOALS } from "../goals/config";
import { GoalEditBar } from "../goals/GoalEditBar";
import { useHistory, useLocation } from "react-router-dom";
import DietaryPreference from "../dietary-preference/DietaryPreference";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

export type SettingType =
  | "household"
  | "dietary"
  | "shopping"
  | "health"
  | "main";

interface SettingStateProps {
  setIsOpenPersonalisation: Dispatch<SetStateAction<SettingType | false>>;
  closeAllSidebar?: () => void;
  closeAllOnExit?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: WHITE,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  heading: {
    padding: "16px 24px",
    borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    display: "flex",
    columnGap: "8px",
    boxSizing: "border-box",
    width: "100%",
  },
  headingText: {
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
    margin: 0,
  },
  innerContent: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 24px",
    boxSizing: "border-box",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    width: "100%",
  },
  buttonContainer: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 0",
    marginTop: "auto",
  },
  button: {
    padding: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    columnGap: "8px",
    boxSizing: "border-box",
    width: "135px",
    boxShadow: "none",
    textTransform: "initial",
  },
  buttonCancel: {
    padding: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    color: COLOR_GREYSCALE[800],
    backgroundColor: "transparent",
    border: `solid 2px ${COLOR_GREYSCALE[800]}`,
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    columnGap: "8px",
    boxSizing: "border-box",
    width: "135px",
    boxShadow: "none",
    textTransform: "initial",
  },
  // Content styling here!
  settingsButton: {
    cursor: "pointer",
    border: "none",
    padding: "8px 0",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: COLOR_GREYSCALE[900],
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    backgroundColor: "transparent",
    display: "flex",
    columnGap: "16px",
    width: "100%",
    justifyContent: "space-between",
  },
  separator: {
    width: "100%",
    height: "1px",
    marginTop: "8px",
    marginBottom: "8px",
    backgroundColor: COLOR_GREYSCALE[100],
  },
  settingHeading: {
    margin: 0,
    marginBottom: "16px",
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
  },
  dropdownSettings: {
    position: "relative",
    marginBottom: "24px",
    boxSizing: "border-box",
  },
  dropdownSelect: {
    padding: "8px 56px 8px 16px",
    borderRadius: "8px",
    border: `solid 1px ${COLOR_GREYSCALE[200]}`,
    backgroundColor: COLOR_GREYSCALE[50],
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    width: "100%",
    appearance: "none",
    position: "relative",
  },
  selectChevron: {
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translateY(-50%) rotate(90deg)",
    pointerEvents: "none",
  },
  goalsContainer: {
    "& > *:not(:first-child)": {
      marginTop: "16px",
    },
  },
  disclaimer: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textAlign: "center",
    color: COLOR_GREYSCALE[700],
    margin: 0,
    marginTop: "16px",
  },
  body: {
    margin: 0,
    marginBottom: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
  },
  subTitle: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-SemiBold'",
    fontWeight: "normal",
    marginBottom: "24px",
  },
}));

const PersonalisationMain = ({
  setIsOpenPersonalisation,
}: SettingStateProps) => {
  const classes = useStyles();
  const hideDP = useHideDietaryPreferences();
  let settings: { title: string; key: SettingType }[] = [
    {
      title: "Household setting",
      key: "household",
    },
    {
      title: "Dietary preferences",
      key: "dietary",
    },
    {
      title: "Shopping allocation",
      key: "shopping",
    },
    {
      title: "Health goal setting",
      key: "health",
    },
  ];

  if (hideDP) {
    settings = settings.filter((s) => s.key !== "dietary");
  }

  return (
    <>
      {settings.map((tool, index) => (
        <>
          <button
            key={tool.title}
            className={classes.settingsButton}
            onClick={() => setIsOpenPersonalisation(tool.key)}
          >
            {tool.title}
            <ChevronRight />
          </button>
          {settings.length - 1 !== index && (
            <div className={classes.separator} key={tool.title + "1"} />
          )}
        </>
      ))}
    </>
  );
};

const PersonalisationHousehold = ({
  setIsOpenPersonalisation,
}: SettingStateProps) => {
  return (
    <HouseholdSettingV2
      inSidebar={true}
      setIsOpenPersonalisation={setIsOpenPersonalisation}
    />
  );
};

const PersonalisationDietary = ({
  setIsOpenPersonalisation,
}: SettingStateProps) => {
  return (
    <DietaryPreference
      inSidebar={true}
      setIsOpenPersonalisation={setIsOpenPersonalisation}
    />
  );
};

const PersonalisationShopping = ({
  setIsOpenPersonalisation,
}: SettingStateProps) => {
  const classes = useStyles();
  const { member } = useMember();
  const [shoppingAllocationValues, setShoppingAllocationValues] = useState({
    ...member!.shoppingAllocation,
  });

  const saveShoppingAllocation = async () => {
    try {
      await setShoppingAllocation(member!.memberId, shoppingAllocationValues);
      setIsOpenPersonalisation("main");
    } catch (e) {
      console.log("ERROR SAVING SHOPPING ALLOCATION");
    }
  };

  return (
    <>
      <Typography className={classes.settingHeading}>
        Set shopping allocation
      </Typography>
      <ShoppingAllocationV2
        shippingAllocationValues={shoppingAllocationValues}
        setShippingAllocationValues={setShoppingAllocationValues}
        inSidebar={true}
      />
      <div style={{ marginTop: 24 }} />
      <div className={classes.buttonContainer}>
        <Button
          variant={"contained"}
          color={"default"}
          className={classes.buttonCancel}
          onClick={() => setIsOpenPersonalisation("main")}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          endIcon={<CheckIcon />}
          className={classes.button}
          onClick={saveShoppingAllocation}
        >
          Save
        </Button>
      </div>
    </>
  );
};

const PersonalisationHealthGoals = ({
  setIsOpenPersonalisation,
  closeAllOnExit,
  closeAllSidebar,
}: SettingStateProps) => {
  const { member } = useMember();
  const classes = useStyles();
  const [checked, setChecked] = useState<GOALS[]>(member?.goals ?? []);
  const location = useLocation();
  const history = useHistory();
  const saveGoals = async () => {
    try {
      await setMemberGoals(member!.memberId, checked);
      if (closeAllOnExit) {
        closeAllSidebar!();
        if (location.pathname === "/settings") {
          history.push("/");
        }
      } else {
        setIsOpenPersonalisation("main");
      }
    } catch (e) {
      console.log("ERROR SAVING GOALS");
    }
  };

  const cancelGoals = () => {
    if (closeAllOnExit) {
      closeAllSidebar!();
      if (location.pathname === "/settings") {
        history.push("/");
      }
    } else {
      setIsOpenPersonalisation("main");
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        component="h2"
        className={classes.settingHeading}
      >
        Set your health goals
      </Typography>
      <Typography className={classes.body}>
        Choose your specific health goal(s) so you can track what's important to
        you.
      </Typography>
      <Typography component="h3" className={classes.subTitle}>
        Please select a maximum of 3:
      </Typography>
      <Grid container className={classes.goalsContainer}>
        {Object.keys(GOALS).map((goalKey) => {
          const goal = GOALS[goalKey as keyof typeof GOALS];
          return (
            <GoalEditBar
              key={goal}
              goal={goal}
              checked={checked}
              setChecked={setChecked}
            />
          );
        })}
      </Grid>
      <Typography component="h4" className={classes.disclaimer}>
        * Protein includes Meat, Dairy & alternatives
      </Typography>
      <div style={{ marginTop: 24 }} />
      <div className={classes.buttonContainer}>
        <Button
          variant={"contained"}
          color={"default"}
          className={classes.buttonCancel}
          onClick={cancelGoals}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          endIcon={<CheckIcon />}
          className={classes.button}
          onClick={saveGoals}
        >
          Save
        </Button>
      </div>
    </>
  );
};

const SidebarPersonalisation = () => {
  const {
    isOpenPersonalisation,
    setIsOpenPersonalisation,
    closeAllSidebar,
    closeAllOnExit,
  } = useContext(SettingsContext);

  const hideDP = useHideDietaryPreferences();

  const classes = useStyles();

  const displaySetting = () => {
    switch (isOpenPersonalisation) {
      case "main":
        return (
          <PersonalisationMain
            setIsOpenPersonalisation={setIsOpenPersonalisation}
          />
        );
      case "household":
        return (
          <PersonalisationHousehold
            setIsOpenPersonalisation={setIsOpenPersonalisation}
          />
        );
      case "dietary":
        return (
          <PersonalisationDietary
            setIsOpenPersonalisation={setIsOpenPersonalisation}
          />
        );
      case "shopping":
        return (
          <PersonalisationShopping
            setIsOpenPersonalisation={setIsOpenPersonalisation}
          />
        );
      case "health":
        return (
          <PersonalisationHealthGoals
            setIsOpenPersonalisation={setIsOpenPersonalisation}
            closeAllOnExit={closeAllOnExit}
            closeAllSidebar={closeAllSidebar}
          />
        );
      default:
        return (
          <PersonalisationMain
            setIsOpenPersonalisation={setIsOpenPersonalisation}
          />
        );
    }
  };

  let settingDropdown: { label: string; value: SettingType }[] = [
    {
      value: "household",
      label: "Household setting",
    },
    {
      value: "dietary",
      label: "Dietary preferences",
    },
    {
      value: "shopping",
      label: "Shopping allocation",
    },
    {
      value: "health",
      label: "Health goal setting",
    },
  ];

  if (hideDP) {
    settingDropdown = settingDropdown.filter((d) => d.value !== "dietary");
  }

  const handleBack = () => {
    if (closeAllOnExit) {
      closeAllSidebar();
    } else if (isOpenPersonalisation !== "main") {
      setIsOpenPersonalisation("main");
    } else {
      setIsOpenPersonalisation(false);
    }
  };

  return (
    <SidebarSettings
      sidebarOpen={isOpenPersonalisation !== false}
      setSidebarOpen={(open) => {
        if (!open) {
          handleBack();
        } else {
          setIsOpenPersonalisation("main");
        }
      }}
      backButtonAction={handleBack}
      contentContainerExtended={true}
    >
      <div className={classes.container}>
        <div className={classes.heading}>
          <HeadingIcon width={24} height={24} />
          <h3 className={classes.headingText}>Personalisation settings</h3>
        </div>
        <div className={classes.innerContent}>
          {isOpenPersonalisation !== "main" && (
            <div className={classes.dropdownSettings}>
              <select
                className={classes.dropdownSelect}
                defaultValue={isOpenPersonalisation || "main"}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const val = e.target.value as SettingType;
                  setIsOpenPersonalisation(val);
                }}
              >
                {settingDropdown.map((setting) => {
                  return (
                    <option value={setting.value} key={setting.label}>
                      {setting.label}
                    </option>
                  );
                })}
              </select>
              <Chevron
                width={24}
                height={24}
                className={classes.selectChevron}
              />
            </div>
          )}
          {displaySetting()}
        </div>
      </div>
    </SidebarSettings>
  );
};

export default SidebarPersonalisation;
