import { Typography, makeStyles } from "@material-ui/core";
import { FC } from "react";
import {
  InfoModalContainer,
  InfoModalDefaultButton,
} from "../../components-2/InfoModal";
import { COLOR_GREYSCALE } from "../../theme";

const FoodGroupInsightsExplainer: FC = () => {
  const classes = makeStyles(() => ({
    text: {
      color: COLOR_GREYSCALE[700],
    },
  }))();
  return (
    <InfoModalContainer
      eventName={"food_group_insights"}
      button={<InfoModalDefaultButton />}
    >
      <Typography variant="body1" className={classes.text}>
        The main food groups come from the dietary guidelines which show us the
        types and amounts of foods to eat for optimal wellbeing, based on
        research.
      </Typography>

      <Typography variant="body1" className={classes.text}>
        <ul>
          <li>Main foods support health, wellbeing and a balanced diet.</li>
          <li>
            Sometimes foods are okay to eat in moderation, as part of a balanced
            and nutritious diet.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" className={classes.text}>
        Click through to learn more about each food group and see which items in
        your basket contribute.
      </Typography>
    </InfoModalContainer>
  );
};

export default FoodGroupInsightsExplainer;
