import { makeStyles } from "@material-ui/core/styles";
import { PageLayout } from "../components-2/page-layout";
import FruitAndVegChallengeTable from "../components-2/fruit-and-veg-challenge/FruitAndVegChallengeTable";
import { COLOR_GREYSCALE, WHITE } from "../theme";
import { useEffect, useState } from "react";
import useMember from "../hooks/useMember";
import { useHistory } from "react-router-dom";
import ExternalLink from "../components/ExternalLink";
import StyledTabs from "../components/tabs/StyledTabs";
import StyledTab from "../components/tabs/StyledTab";
import FruitAndVegLeaderboard from "../components-2/fruit-and-veg-leaderboard";
import useEvents from "../hooks/useEvents";
import { FRUIT_AND_VEG_CHALLENGE_EVENTS } from "../events";
import { formatGMTToSydneyISO, getSydneyISOTime } from "../utils/dates";
import { POINTS_PROMO_END_DATE } from "../constants";

export interface FruitAndVegLeaderboardFetchProps {
  user: UserLeaderboardProps;
  localLeaderboard: LeaderboardProps[];
  globalLeaderboard: LeaderboardProps[];
}
export interface UserLeaderboardProps {
  city: string;
  globalPosition: number;
  localPosition: number;
  name: string;
  points: number;
}

export interface LeaderboardProps {
  name: string;
  position: string;
  points: number;
}

const FruitAndVegChallengePage = () => {
  const { member } = useMember();
  const history = useHistory();
  const { track } = useEvents();

  const currDate = getSydneyISOTime();
  const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE!);
  const challengeFinished = currDate >= promoEndDate;
  const challengeFinishedAndAlreadyJoinedLeaderboard =
    challengeFinished &&
    (member?.joinedFruitAndVegMayCampaignLeaderboard ?? false);
  const challengeStillRunning = !challengeFinished;

  const [tabValue, setTabValue] = useState<string>("purchasedItems");

  useEffect(() => {
    if (!member?.eligibleForFruitAndVegMayCampaign) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  const useStyles = makeStyles(({ breakpoints }) => ({
    pageWrapper: {
      marginTop: "32px",
      borderRadius: "12px",
      background: "#fff",
      padding: "16px",
      [breakpoints.up("md")]: {
        padding: "40px",
        minHeight: "400px",
      },
    },
    purchasedItemsHeading: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    purchasedItems: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      margin: 0,
    },
    paragraph: {
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      margin: "16px 0",
      zIndex: 1,
      [breakpoints.up("md")]: {
        maxWidth: "100%",
        margin: "55px 0 16px",
        position: "relative",
      },
    },
    paragraphOrangeText: {
      color: "#FD6400",
    },
    tac: {
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      zIndex: 1,
      [breakpoints.up("lg")]: {
        maxWidth: "100%",
        position: "relative",
      },
    },
    link: {
      color: "#60A498",
      textDecoration: "underline",
    },
    bottomImage: {
      display: "block",
      width: "100%",
      maxWidth: "343px",
      margin: "0 auto",
      zIndex: 0,
      [breakpoints.up("lg")]: {
        marginLeft: "auto",
        marginRight: 0,
        marginTop: "-55px",
        maxWidth: "790px",
      },
    },

    tabs: {
      padding: "8px",
      backgroundColor: WHITE,
      borderRadius: "16px",
      border: `solid 1px ${COLOR_GREYSCALE[400]}`,
      marginTop: "32px",
      [breakpoints.up("md")]: {
        margin: "32px auto 0 0",
        alignItems: "flex-start",
        width: "fit-content",
      },
    },
    tab: {
      padding: "8px 16px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      lineHeight: "20px",
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[700],
      borderRadius: "8px",
      flex: 1,
      "&.Mui-selected": {
        backgroundColor: COLOR_GREYSCALE[700],
        color: WHITE,
      },
      [breakpoints.up("md")]: {
        lineHeight: "24px",
        fontSize: "16px",
        width: "163.5px",
      },
    },
  }));

  const handleTabChange = (_event: React.ChangeEvent<{}>, value: any) => {
    setTabValue(value);
    track(FRUIT_AND_VEG_CHALLENGE_EVENTS.TAB_SELECT, { tab: value });
  };

  const classes = useStyles();

  return (
    <PageLayout title={"Fruit And Veg Challenge"} headingTextColor="#F6EFDF">
      {(challengeStillRunning ||
        challengeFinishedAndAlreadyJoinedLeaderboard) && (
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}
        >
          <StyledTab
            className={classes.tab}
            value="purchasedItems"
            label="Purchased Items"
          />
          <StyledTab
            className={classes.tab}
            value="leaderboard"
            label="Leaderboard"
          />
        </StyledTabs>
      )}

      {tabValue === "purchasedItems" ? (
        <>
          <div className={classes.pageWrapper}>
            <div className={classes.purchasedItemsHeading}>
              <p className={classes.purchasedItems}>Purchased items</p>
            </div>
            <FruitAndVegChallengeTable />
          </div>
          <div>
            <p className={classes.paragraph}>
              From apples to frozen peas and even tinned beans,{" "}
              <span className={classes.paragraphOrangeText}>
                collect 5x points per dollar
              </span>{" "}
              on any product that contains fruit or veg serves.*
            </p>
            <p className={classes.tac}>
              *Offer starts 00:01 AEST 08/05/2024 and ends 23:59 AEST
              05/06/2024.{" "}
              <ExternalLink
                href={"https://www.healthylife.com.au/discover/food-tracker"}
                target="_blank"
                className={classes.link}
              >
                T&Cs apply.
              </ExternalLink>
            </p>
          </div>
        </>
      ) : (
        <FruitAndVegLeaderboard setTabValue={setTabValue} />
      )}

      <img
        src="https://storage.googleapis.com/food-tracker-images-prod/fruit-veggies-group-2.png"
        alt="fruits and vegetables"
        className={classes.bottomImage}
      />
    </PageLayout>
  );
};

export default FruitAndVegChallengePage;
