import { FC } from "react";
import RecipeFilterHeading from "./RecipeFilterHeading";
import RecipeFilterGroups from "./RecipeFilterGroups";
import RecipeApplyCancelButtons from "./RecipeFilterApplyCancelButtons";

const RecipeFilters: FC = () => {
  return (
    <>
      <div>
        <RecipeFilterHeading />
        <RecipeFilterGroups isSelected={false} />
        <RecipeApplyCancelButtons />
      </div>
    </>
  );
};

export default RecipeFilters;
