import { Backdrop, makeStyles } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}));

interface Props {
  open: boolean;
}

const BlockingBackdrop: FC<Props> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default BlockingBackdrop;
