import { RECIPE_FOOD_GROUP_FILTERS } from "./hardcodedContentfulContent";

export interface RecipeFoodGroupItems {
  fruit: string[];
  vegetablesAndLegumes: string[];
  meatAndAlternatives: string[];
  grains: string[];
  dairyAndAlternatives: string[];
}

export const fetchFoodGroupFilters =
  async (): Promise<RecipeFoodGroupItems> => {
    // Used to fetched from Contentful, now hard coded.
    return RECIPE_FOOD_GROUP_FILTERS.data.recipeFoodGroupFilterCollection
      .items[0];
  };
