import { useContext } from "react";
import { HouseholdContext } from "../components/providers/HouseholdProvider";
import { MemberContext } from "../components/providers/MemberProvider";
import { Benchmark, HouseholdMember } from "../models";
import { addToHousehold, removeFromHousehold } from "../services/households";

export default function useHousehold() {
  const member = useContext(MemberContext);
  const household = useContext(HouseholdContext);

  const add = async (
    benchmark: Benchmark,
    consent?: string
  ): Promise<HouseholdMember> => {
    if (!member) {
      throw new Error("Add to household failed: member not loaded");
    }
    return addToHousehold(member.memberId, benchmark.id, consent);
  };

  const remove = async (hm: HouseholdMember) => {
    if (!member) {
      throw new Error("Remove from household failed: member not loaded");
    }
    return removeFromHousehold(member.memberId, hm);
  };

  return {
    loadingHousehold: household === null,
    household: household ?? [],
    addToHousehold: add,
    removeFromHousehold: remove,
  };
}
