import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { NutrientTrackerTypes } from "../../models";
import { COLOR_GREYSCALE, COLOR_ORANGE } from "../../theme";

interface Props {
  total: number | null;
  value: number | null;
  type: NutrientTrackerTypes["type"];
  maxLabelValue: number;
  saltTarget?: number;
  delayIndex: number;
  month?: string;
}

const NutrientBar: FC<Props> = ({
  total,
  value,
  type,
  maxLabelValue,
  saltTarget = 40000,
  delayIndex,
  month,
}) => {
  const barColors = {
    sugar: {
      total: COLOR_GREYSCALE[200],
      value: COLOR_GREYSCALE[600],
    },
    fat: {
      total: COLOR_GREYSCALE[200],
      value: COLOR_GREYSCALE[600],
    },
    salt: {
      value: COLOR_GREYSCALE[200],
      border: "transparent",
    },
  };

  const noData = !total && !value;
  const barTotalHeightPercent = !noData ? (total! / maxLabelValue) * 100 : 0;
  const barValuePercent = !noData ? (value! / maxLabelValue) * 100 : 0;

  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      transitionDelay: delayIndex >= 0 ? `${delayIndex * 0.2}s` : "0s",
      height: "calc(100% - 16px)",
      display: "flex",
      position: "relative",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      alignSelf: "center",
    },
    bar: {
      display: "flex",
      position: "relative",
      width: "30px",
      overflow: "visible",
      height: "100%",
      alignItems: "flex-end",
      justifyContent: "center",
      "&.no-data": {
        border: "dashed 1px #D3D6D9",
        alignItems: "center",
        flexWrap: "wrap",
        fontSize: "10px",
        lineHeight: "12px",
        letterSpacing: "-0.02em",
        color: "#585C60",
        textAlign: "center",
      },
      [breakpoints.up("md")]: {
        width: "40px",
      },
    },
    barTotal: {
      position: "relative",
      height: `${barTotalHeightPercent}%`,
      width: "100%",
      borderRadius: "4px",
      transition: "height .5s ease-in-out",
      maxHeight: "100%",
    },
    barValue: {
      overflow: "hidden",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      zIndex: 2,
      height: `${barValuePercent}%`,
      width: "100%",
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "0px 0px 4px 4px",
      boxSizing: "border-box",
      transition: "height .5s ease-in-out",
      maxHeight: "100%",
    },
    month: {
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[700],
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.2px",
      marginTop: "8px",
    },
  }));
  const classes = useStyles();

  const sugarAndFatBar = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.bar}>
          <div
            className={`barTotal ${classes.barTotal}`}
            style={{
              backgroundColor:
                type === "sugar" ? barColors.sugar.total : barColors.fat.total,
            }}
            role="progressbar"
            aria-label={`${total} grams total ${type}`}
          ></div>
          <div
            className={`barValue ${classes.barValue}`}
            style={{
              backgroundColor:
                type === "sugar" ? barColors.sugar.value : barColors.fat.value,
            }}
            role="progressbar"
            aria-label={`${value} grams ${
              type === "sugar" ? "added" : "saturated"
            } ${type}`}
          ></div>
        </div>
        {month && <div className={classes.month}>{month}</div>}
      </div>
    );
  };

  const saltBar = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.bar}>
          <div
            className={`barValue ${classes.barTotal}`}
            style={{
              backgroundColor:
                value! >= saltTarget ? COLOR_ORANGE[300] : barColors.salt.value,
            }}
          ></div>
        </div>
        {month && <div className={classes.month}>{month}</div>}
      </div>
    );
  };

  const noDataBar = () => {
    return <div className={`${classes.bar} no-data`}></div>;
  };

  return (
    <div className={`bar ${classes.root}`}>
      <>
        {total && value && value >= 0.01
          ? type === "salt"
            ? saltBar()
            : sugarAndFatBar()
          : noDataBar()}
      </>
    </div>
  );
};

export default NutrientBar;
