import { createContext, FC, useContext, useEffect, useState } from "react";
import { MemberContext } from "./MemberProvider";
import { BasketDay, BasketSyncStatus } from "../../models";
import {
  subscribeToBasketDetails,
  subscribeToBasketSyncStatus,
  subscribeToCachedBasketDetails,
  subscribeToRealTimeBasketDetails,
} from "../../services/basketdetails";
import { FeatureFlagContext } from "./FeatureFlagProvider";

type BasketDetailsContextState = {
  basketSyncStatus: BasketSyncStatus;
  basketDetails: BasketDay[];
};

export const BasketDetailsContext = createContext<BasketDetailsContextState>({
  basketSyncStatus: BasketSyncStatus.Unknown,
  basketDetails: [],
});

const BasketDetailsProvider: FC = ({ children }) => {
  const member = useContext(MemberContext);
  const { collected, featureFlags } = useContext(FeatureFlagContext);

  const [basketSyncStatus, setBasketSyncStatus] = useState(
    BasketSyncStatus.Unknown
  );
  const [basketDetails, setBasketDetails] = useState<BasketDay[]>([]);

  const memberId = member?.memberId;
  const consented = member?.consented;

  useEffect(() => {
    if (!memberId || !consented || !collected) return;
    if (
      featureFlags?.realTimeData?.enable?.everybody === true ||
      featureFlags?.realTimeData?.enable?.specificMembers?.includes(memberId)
    ) {
      const unsubscribeFromRealTimeBasketDetails =
        subscribeToRealTimeBasketDetails(memberId, (changes) => {
          setBasketDetails(changes);
          setBasketSyncStatus(BasketSyncStatus.Complete);
        });
      return () => {
        unsubscribeFromRealTimeBasketDetails();
      };
    } else if (
      featureFlags?.cachedMemberBasket?.enable?.everybody === true ||
      featureFlags?.cachedMemberBasket?.enable?.specificMembers?.includes(
        memberId
      )
    ) {
      const unsubscribeFromCachedBasketDetails = subscribeToCachedBasketDetails(
        memberId,
        (changes) => {
          setBasketDetails(changes);
          setBasketSyncStatus(BasketSyncStatus.Complete);
        }
      );
      return () => {
        unsubscribeFromCachedBasketDetails();
      };
    } else {
      const unsubscribeFromSyncStatus = subscribeToBasketSyncStatus(
        memberId,
        (status) => {
          setBasketSyncStatus(status);
        }
      );

      const unsubscribeFromBasketDetails = subscribeToBasketDetails(
        memberId,
        (changes) => {
          setBasketDetails(changes);
        }
      );

      return () => {
        unsubscribeFromSyncStatus();
        unsubscribeFromBasketDetails();
      };
    }
  }, [
    memberId,
    consented,
    collected,
    featureFlags?.cachedMemberBasket?.enable?.everybody,
    featureFlags?.cachedMemberBasket?.enable?.specificMembers,
    featureFlags?.realTimeData?.enable?.everybody,
    featureFlags?.realTimeData?.enable?.specificMembers,
  ]);

  return (
    <BasketDetailsContext.Provider value={{ basketSyncStatus, basketDetails }}>
      {children}
    </BasketDetailsContext.Provider>
  );
};

export default BasketDetailsProvider;
