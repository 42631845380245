import { withStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";

export const StyledTable = withStyles({
  root: (props: { vertical?: boolean }) => ({
    backgroundColor: "#FAF5EC",
    ...(props.vertical
      ? {
          display: "flex",
        }
      : {}),
  }),
})(Table);
