import { Button, makeStyles, Typography } from "@material-ui/core";
import { GoalViewBar } from "./GoalViewBar";
import { useContext, useEffect, useMemo, useState } from "react";
import useMember from "../../hooks/useMember";
import useDiscretionaryPercentHistory from "../../hooks/useDiscretionaryPercentHistory";
import {
  GOAL_DATA,
  getAddedSugarGoalPercentage,
  getMultipleServingsGoalPercentage,
  getServingsGoalPercentage,
  getSometimesGoalPercentage,
} from "./config";
import useNutrients from "../../hooks/useNutrients";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";
import { COLOR_GREYSCALE } from "../../theme";

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: "grid",
    gridRowGap: "4px",
    marginTop: "16px",
    [breakpoints.up("md")]: {
      gridRowGap: "8px",
      marginTop: "24px",
    },
  },
  buttonContainer: {
    padding: "0px 12px",
    marginTop: "16px",
  },
  button: {
    width: "100%",
    borderRadius: "12px",
    "& .MuiButton-label": {
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "rgb(16 66 57 / 60%) !important",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(16 66 57 / 60%) !important",
    },
  },
  header: {
    color: "#585C60",
    [breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      marginTop: "16px",
    },
  },
  editGoalsButton: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "flex",
      margin: "auto",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: "white",
      border: `2px solid ${COLOR_GREYSCALE[800]}`,
      boxShadow: "none",
      textTransform: "none",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
    },
  },
}));

export const GoalsContainer = ({
  redirectToGoalsSettings,
}: {
  redirectToGoalsSettings: () => void;
}) => {
  const classes = useStyles();
  const { member } = useMember();
  const hasGoals = useMemo(() => {
    if (member?.goals) {
      return member.goals?.length > 0;
    } else {
      return false;
    }
  }, [member?.goals]);

  const { accumulatedServings, discretionaryPercentDataList, loading } =
    useDiscretionaryPercentHistory();
  const { nutrientsHistory, nutrientsHistoryLoading } = useNutrients();
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const [dynamicGoalData, setDynamicGoalData] = useState<(number | null)[]>([]);

  useEffect(() => {
    if (nutrientsHistoryLoading || loading) return;

    const computedPercentages =
      member?.goals?.map((goal) => {
        const { foodGroup, foodGroups, sometimes, nutrient } = GOAL_DATA[goal];
        if (foodGroup) {
          return getServingsGoalPercentage(accumulatedServings, foodGroup);
        } else if (foodGroups) {
          return getMultipleServingsGoalPercentage(
            accumulatedServings,
            foodGroups
          );
        } else if (sometimes) {
          return getSometimesGoalPercentage(discretionaryPercentDataList);
        } else if (nutrient === "addedSugar") {
          return getAddedSugarGoalPercentage(nutrientsHistory);
        }
        return null;
      }) || [];

    setDynamicGoalData(computedPercentages);
  }, [
    member,
    accumulatedServings,
    discretionaryPercentDataList,
    nutrientsHistory,
    nutrientsHistoryLoading,
    loading,
  ]);

  const allPercentagesAreNull = useMemo(
    () => dynamicGoalData.every((p) => p === null),
    [dynamicGoalData]
  );

  if (!hasGoals) {
    return (
      <>
        <Typography variant="body2" className={classes.header}>
          Goals are here to help you keep track of what’s important to you.
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            color={"primary"}
            className={classes.button}
            onClick={redirectToGoalsSettings}
          >
            Add health goal
          </Button>
        </div>
      </>
    );
  }

  if (allPercentagesAreNull) {
    return (
      <Typography variant="body2" className={classes.header}>
        Bummer! We don’t have anything to show you at the moment. Check back
        once you’ve shopped.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body2" className={classes.header}>
        Your goal progress vs previous {timeFrame.toLowerCase()}
      </Typography>
      <div className={classes.container}>
        {member?.goals?.map((goal, index) => {
          return (
            <GoalViewBar
              key={goal}
              goal={goal}
              percentage={dynamicGoalData[index]}
            />
          );
        })}
      </div>
      <Button
        className={classes.editGoalsButton}
        onClick={redirectToGoalsSettings}
      >
        Edit goals
      </Button>
    </>
  );
};
