import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { teal } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

export const StyledAccordion = withStyles(() => ({
  root: {
    "&:before": {
      backgroundColor: "transparent",
    },
  },
}))(MuiAccordion);

export const StyledAccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    display: "block",
  },
}))(MuiAccordionDetails);

export const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
    "&.Mui-expanded": {
      minHeight: 48,
    },
  },
  expandIcon: {
    backgroundColor: teal[100],
    "&:hover": {
      backgroundColor: teal[200],
    },
  },
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: "0 !important",
    },
  },
}))(MuiAccordionSummary);
