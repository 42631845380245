import { FeatureFlags } from "../models";

import { Collections, Configurations, db } from "./firebase";
import {
  doc,
  onSnapshot,
} from "firebase/firestore";


export type FeatureFlagChangeListener = (featureFlags: FeatureFlags) => void;

export const subscribeToFeatureFlags = (
  onChange: FeatureFlagChangeListener
) =>
  onSnapshot(doc(db, Collections.Configurations, Configurations.FeatureFlags),
    (snapshot) => {
    if (snapshot.exists()) {
      onChange(snapshot.data());
    } else {
      onChange({})
    }
  });
