import { makeStyles } from "@material-ui/core";
import { ReactComponent as SweetsIcon } from "../../icons/sweets.svg";
import { ReactComponent as FatIcon } from "../../icons/fat.svg";
import { ReactComponent as SaltIcon } from "../../icons/salt.svg";
import { ReactComponent as Chevron } from "../../icons/chevron-right.svg";
import { COLOR_GREYSCALE, GOLD } from "../../theme";
import { Link } from "react-router-dom";
import useNutrients from "../../hooks/useNutrients";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";
import { isNonProdEnv } from "../../utils/debug";

interface Props {
  setInsightEmpty: Dispatch<SetStateAction<boolean>>;
}
interface NutrientDifferenceProps {
  type: "salt" | "fat" | "sugar";
  absValue: number;
  value: number;
}

const NutrientInsights = ({ setInsightEmpty }: Props) => {
  const { nutrientsHistory, nutrientsHistoryLoading } = useNutrients();
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const [nutrientToShow, setNutrientToShow] =
    useState<NutrientDifferenceProps>();
  const [nutrientEmpty, setNutrientEmpty] = useState<boolean>(true);

  const computeAverageDifference = (type: "salt" | "fat" | "sugar") => {
    const currentNutrient = nutrientsHistory[nutrientsHistory.length - 1];
    if (currentNutrient[type].innerValue === 0) return 0;

    const historyCopy = [...nutrientsHistory];
    historyCopy.pop();
    const prevValues = historyCopy.map((nutrient) => {
      return nutrient[type].innerValue;
    });

    const filteredPrevValues = prevValues.filter((val) => val! > 0);

    if (filteredPrevValues.length >= 2) {
      const summedPrevValues = filteredPrevValues.reduce((a, b) => a! + b!, 0);
      const prevTotalAverage = summedPrevValues! / filteredPrevValues.length;
      const currPercentageVsPrev = Math.round(
        ((currentNutrient[type].innerValue! - prevTotalAverage) /
          prevTotalAverage) *
          100
      );
      return currPercentageVsPrev;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (nutrientsHistoryLoading) return;

    const currentNutrientValues = nutrientsHistory[nutrientsHistory.length - 1];
    const { salt, fat, sugar } = currentNutrientValues!;

    let currSaltDiff = 0,
      currFatDiff = 0,
      currSugDiff = 0;

    if (salt && salt.innerValue! > 0) {
      currSaltDiff = computeAverageDifference("salt");
    }

    if (fat && fat.innerValue! > 0) {
      currFatDiff = computeAverageDifference("fat");
    }

    if (sugar && sugar.innerValue! > 0) {
      currSugDiff = computeAverageDifference("sugar");
    }

    const differences: NutrientDifferenceProps[] = [
      {
        type: "salt",
        absValue: Math.abs(currSaltDiff),
        value: currSaltDiff,
      },
      {
        type: "fat",
        absValue: Math.abs(currFatDiff),
        value: currFatDiff,
      },
      {
        type: "sugar",
        absValue: Math.abs(currSugDiff),
        value: currSugDiff,
      },
    ];

    const sortedDifferences = differences.sort(
      (a, b) => b.absValue - a.absValue
    );

    if (isNonProdEnv()) {
      console.log("Log: nutrientsHistory", nutrientsHistory);
    }

    if (differences.every((diff) => diff.value === 0 || diff.value === null)) {
      setNutrientEmpty(true);
      setInsightEmpty(true);
    } else {
      setNutrientToShow(sortedDifferences[0]);
      setNutrientEmpty(false);
      setInsightEmpty(false);
    }
    // eslint-disable-next-line
  }, [nutrientsHistoryLoading, timeFrame]);

  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      boxSizing: "border-box",
      padding: "16px 16px 6px",
      borderRadius: "8px",
      backgroundColor:
        nutrientToShow?.type === "sugar" ? GOLD : COLOR_GREYSCALE[200],
      display: "flex",
      gap: "16px",
      height: "116px",
      [breakpoints.up("md")]: {
        height: "94px",
      },
    },
    icon: {
      width: "40px",
      height: "40px",
      flexShrink: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    text: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    showMe: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[900],
      textDecoration: "none",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      paddingTop: "8px",
      paddingBottom: "8px",
      marginRight: "auto",
      marginTop: "auto",
      "& svg path": {
        width: "16px",
        height: "16px",
        stroke: COLOR_GREYSCALE[900],
      },
    },
  }))();

  const setMessage = (type: "salt" | "sugar" | "fat", value: number) => {
    const adjust = value >= 0 ? "up" : "down";
    const identifier = {
      salt: "Salt",
      sugar: "Added sugar",
      fat: "Saturated fat",
    };
    return nutrientsHistoryLoading
      ? "Loading data..."
      : `${identifier[type]} is ${adjust} ${Math.abs(
          value
        )}% compared to usual.`;
  };

  const renderIcon = () => {
    switch (nutrientToShow?.type) {
      case "salt":
        return <SaltIcon className={classes.icon} />;
      case "fat":
        return <FatIcon className={classes.icon} />;
      case "sugar":
        return <SweetsIcon className={classes.icon} />;
      default:
        return <SweetsIcon className={classes.icon} />;
    }
  };

  if (nutrientsHistoryLoading || nutrientEmpty) return <></>;

  return (
    <div className={classes.root}>
      {renderIcon()}
      <div className={classes.content}>
        <p className={classes.text}>
          {setMessage(nutrientToShow?.type!, nutrientToShow?.value!)}
        </p>

        <Link
          to={`/nutrient-tracker?type=${nutrientToShow?.type!}`}
          className={classes.showMe}
        >
          Show me <Chevron />
        </Link>
      </div>
    </div>
  );
};

export default NutrientInsights;
