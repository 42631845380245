import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import { useHistory } from "react-router-dom";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { WHITE } from "../../theme";

export const OtherPageHeader = ({
  title,
  titleColor,
  onBackClick,
  onMenuClick,
  hideBackButton,
  hideMenuButton,
  headingTextColor,
  backButtonColor,
  menuButtonColor,
}: {
  title: string;
  titleColor?: string;
  hideBackButton: boolean;
  hideMenuButton: boolean;
  onBackClick?: () => void;
  onMenuClick?: () => void;
  headingTextColor?: string;
  backButtonColor?: string;
  menuButtonColor?: string;
}) => {
  const history = useHistory();
  const defaultOnBackClick = () => {
    history.goBack();
  };

  const headerStyles = makeStyles(({ breakpoints }) => ({
    icon: {
      color: "white",
      height: "24px",
      width: "24px",
    },
    iconButton: {
      height: "42px",
      width: "42px",
      "& path": {
        fill: backButtonColor ? backButtonColor : WHITE,
      },
    },
    iconMenuButton: {
      height: "42px",
      width: "42px",
      "& path": {
        fill: menuButtonColor ? menuButtonColor : WHITE,
      },
    },
    iconWhiteBackground: {
      background: "rgb(255 255 255 / 50%)",
      "&:hover": {
        background: "rgb(255 255 255 / 25%)",
      },
    },
    hiddenButton: {
      visibility: "hidden",
      opacity: 0,
    },
    title: {
      color: headingTextColor ? headingTextColor : WHITE,
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      textAlign: "center",
      paddingRight: "18px",
      margin: 0,
      //mobile
      [breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
      },
      //desktop
      [breakpoints.up("md")]: {
        fontSize: "36px",
        lineHeight: "48px",
        paddingLeft: "24px",
      },
    },
    backText: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      color: backButtonColor ? backButtonColor : WHITE,
      margin: 0,
    },
  }));

  const classes = headerStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const { isOpenSidebar, setIsOpenSidebar } = useContext(SettingsContext);

  const defaultOnMenuClick = () => setIsOpenSidebar(!isOpenSidebar);

  return (
    <Grid
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      container
      style={{
        paddingTop: "29px",
      }}
    >
      {!hideBackButton ? (
        <Grid item>
          <IconButton
            onClick={onBackClick ? onBackClick : defaultOnBackClick}
            className={classes.iconButton}
            role="button"
            aria-label="Go Back"
          >
            <ChevronLeftRoundedIcon className={classes.icon} />
            {!isMobile && (
              <Typography className={classes.backText}>Back</Typography>
            )}
          </IconButton>
        </Grid>
      ) : (
        <Grid item className={`${classes.hiddenButton} ${classes.iconButton}`}>
          <IconButton role="button" aria-label="Go Back">
            <ChevronLeftRoundedIcon className={classes.icon} />
          </IconButton>
        </Grid>
      )}
      <Grid item xs>
        <Typography className={classes.title} component={"h1"}>
          {title}
        </Typography>
      </Grid>
      {!hideMenuButton ? (
        <Grid item>
          <IconButton
            className={`${classes.iconWhiteBackground} ${classes.iconMenuButton}`}
            onClick={onMenuClick ? onMenuClick : defaultOnMenuClick}
            aria-label="Menu"
          >
            <MenuRoundedIcon className={classes.icon} />
          </IconButton>
        </Grid>
      ) : (
        <Grid
          item
          className={`${classes.hiddenButton} ${classes.iconMenuButton}`}
        >
          <IconButton className={classes.iconWhiteBackground} aria-label="Menu">
            <MenuRoundedIcon className={classes.icon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
