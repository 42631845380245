import { createContext, FC, useEffect, useState } from "react";
import { Benchmark } from "../../models";
import { getBenchmarks } from "../../services/contentful/benchmarks";

interface Props {}

export const BenchmarksContext = createContext<Benchmark[] | null>(null);

const BenchmarksProvider: FC<Props> = ({ children }) => {
  const [benchmarks, setBenchmarks] = useState<Benchmark[] | null>(null);

  useEffect(() => {
    const init = async () => {
      setBenchmarks(await getBenchmarks());
    };

    init();
  }, []);

  return (
    <BenchmarksContext.Provider value={benchmarks}>
      {children}
    </BenchmarksContext.Provider>
  );
};

export default BenchmarksProvider;
