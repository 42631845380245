import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { LinkWithImage } from "../models";
import { fetchAllChallenges } from "../services/contentful/challenges";
import ChallengeTile from "./ChallengeTile";
import TrolleyLoader from "./TrolleyLoader";
import { makeStyles } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  cardWrapper: {
    [breakpoints.up("md")]: {
      marginTop: spacing(2),
      border: "1px solid #E1E1E1",
      padding: spacing(2, 4, 4, 4),
      position: "relative",
      backgroundColor: teal[50],
      borderRadius: 12,
    },
  },
}));

const WaysToWellSection = () => {
  const classes = useStyles();
  const [challenges, setChallenges] = useState<LinkWithImage[]>();

  useEffect(() => {
    const init = async () => {
      setChallenges(await fetchAllChallenges());
    };

    init();
  }, []);

  if (!challenges) {
    return <TrolleyLoader />;
  }

  return (
    <div className={classes.cardWrapper} role={"group"} aria-label={"The last section gives you buttons to the ways to well programs. You can choose between ways to manage sugar intake, ways to eat more veggies or ways to move."}>
      <Typography variant="h2" component="h1">Ways to well programs</Typography>
      {challenges.map((challenge) => (
        <ChallengeTile key={challenge.title} content={challenge} />
      ))}
    </div>
  );
};

export default WaysToWellSection;
