import { Servings } from "../models";
import { doc, getDoc } from "firebase/firestore";
import { Collections, db } from "./firebase";
import { EMPTY_SERVINGS } from "./servings";

const cache: { [articleId: string]: Servings } = {};

export const getArticleServings = async (
  articleId: string
): Promise<Servings> => {
  if (!cache[articleId]) {
    const docRef = doc(db, Collections.ArticleServings, articleId);
    const articleServings = await getDoc(docRef);
    if (articleServings.exists()) {
      cache[articleId] = articleServings.data() as Servings;
    } else {
      cache[articleId] = EMPTY_SERVINGS;
    }
  }

  return cache[articleId];
};
