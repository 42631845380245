import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import React, { CSSProperties } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import { useMediaQuery } from "@material-ui/core";
import useOnboardingStepper from "../../hooks/useOnboardingStepper";

export const Stepper = ({
  customHandleNext,
  customHandleSkip,
  amountOfSteps,
  activeStep,
  handleNext,
  handleSkip,
  nextButtonLoading,
  nextButtonDisabled = false,
  style,
}: {
  amountOfSteps: number;
  activeStep: number;
  handleNext: () => void;
  handleSkip: () => void;
  customHandleNext?: () => boolean;
  customHandleSkip?: () => void;
  nextButtonLoading?: boolean;
  nextButtonDisabled?: boolean;
  style?: CSSProperties;
}) => {
  const { STEP_TITLES } = useOnboardingStepper();
  const useStyles = makeStyles(({ breakpoints }) => ({
    stepperRoot: {
      flexGrow: 1,
      padding: "8px 0px",
      background: "transparent",
      "& .MuiMobileStepper-dots": {
        "& .MuiMobileStepper-dot": {
          marginRight: "10px",
          backgroundColor: activeStep === 0 ? "white" : COLOR_GREYSCALE[100],
        },
        "& .MuiMobileStepper-dotActive": {
          backgroundColor: DARK_GREEN,
        },
      },
    },
    nextButton: {
      borderRadius: "12px",
      "& .MuiButton-label": {
        fontFamily: "'Gilroy-Semibold'",
        fontWeight: "normal",
        textTransform: "none",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
      },
      "&:disabled": {
        color: "white",
        backgroundColor: "rgb(16 66 57 / 60%) !important",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "rgb(16 66 57 / 60%) !important",
      },
    },
    skipButton: {
      background: "transparent",
      boxShadow: "none",
      "& .MuiButton-label": {
        fontSize: "16px",
        letterSpacing: "0.1px",
        lineHeight: "24px",
        fontFamily: "'Gilroy'",
        textTransform: "none",
        color: COLOR_GREYSCALE[700],
      },
    },
    hiddenButton: {
      visibility: "hidden",
    },
  }));

  const handleNextInternal = async () => {
    if (customHandleNext) {
      const nextStep = await customHandleNext();
      if (!nextStep) {
        return;
      }
    }
    handleNext();
  };
  const classes = useStyles();
  const disableSkip =
    STEP_TITLES[activeStep as keyof typeof STEP_TITLES]?.disableSkip !== false;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <MobileStepper
      variant="dots"
      steps={amountOfSteps}
      position="static"
      activeStep={activeStep}
      className={classes.stepperRoot}
      style={style}
      nextButton={
        <Button
          size="small"
          onClick={handleNextInternal}
          color={"primary"}
          disabled={
            activeStep === amountOfSteps ||
            nextButtonLoading ||
            nextButtonDisabled
          }
          className={classes.nextButton}
          style={{
            padding: activeStep === 0 ? "12px 24px" : "12px",
            minWidth: activeStep === amountOfSteps - 1 ? "123.52px" : "inherit",
          }}
        >
          {nextButtonLoading
            ? "Loading"
            : activeStep === 0
            ? "Next"
            : activeStep === amountOfSteps - 1
            ? "Save & Finish"
            : "Save & Next"}
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={() => {
            if (customHandleSkip) {
              customHandleSkip();
            }
            handleSkip();
          }}
          disabled={disableSkip}
          className={`${classes.skipButton} ${
            disableSkip && classes.hiddenButton
          }`}
        >
          {isMobile
            ? "Skip"
            : activeStep === amountOfSteps - 1
            ? "Skip to homepage"
            : "Skip"}
        </Button>
      }
    />
  );
};
