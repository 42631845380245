import { Dispatch, ReactNode, SetStateAction } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles(({breakpoints}) => ({
  paper: {
    width: "300px",
    padding: "24px",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",

    [breakpoints.up("md")]: {
      width: "359px",
      boxSizing: "border-box",
    }
  },
}));

interface Props {
  anchor: "top" | "left" | "bottom" | "right";
  isDrawerOpen?: boolean;
  handleDrawer: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  anchor = "right",
  isDrawerOpen,
  handleDrawer,
  children,
}: Props) {
  const classes = useStyles();

  return (
    <Drawer
      anchor={anchor}
      open={isDrawerOpen}
      onClose={() => handleDrawer(false)}
      classes={{ paper: classes.paper }}
    >
      {children}
    </Drawer>
  );
}
