import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useFeedback from "../../hooks/useFeedback";
import { ReactComponent as MessageHeartIcon } from "../../icons/message-heart.svg";
import Rating from "./Rating";
import Comment from "./Comment";
import { Divider } from "@material-ui/core";

export interface FeedbackProps {
  BackButton?: React.ReactNode; // Optional back button - for when in onboarding
  DoneButton: React.ReactNode; // Done button is mandatory
  onDoneClick?: () => void; // Additional click logic for the done button - submitting logic is already handled in the comp
  inSidebar?: boolean; // check if used in sidebar
}

export interface FeedbackValues {
  rating?: number;
  comment?: string;
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    borderRadius: "12px",
    boxShadow: "0px 1px 2px 0px #0000001A",
    height: "100%",
    flex: 1,
  },
  titleText: {
    fontFamily: "'Gilroy-Bold'",
    fontWeight: "normal",
    margin: 0,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
  },
  ratingAndCommentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 24px",
  },
  iconAndTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "24px 24px 0px 24px",
    alignItems: "center",
  },
  messageHeartIcon: {
    marginRight: "8px",
  },
  titleDivider: {
    margin: "16px 0px",
  },
  ratingAndCommentDivider: {
    margin: "32px 0px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 24px 24px 24px",
    marginTop: "32px",
  },
}));

export const Feedback: FC<FeedbackProps> = ({
  BackButton,
  DoneButton,
  onDoneClick,
  inSidebar,
}) => {
  const classes = useStyles();
  const [noRatingError, setNoRatingError] = useState<boolean>(false);
  const [feedbackValues, setFeedbackValues] = useState<FeedbackValues>({});
  const { handleSubmit } = useFeedback();

  const submitFeedback = () => {
    if (feedbackValues?.rating === undefined) {
      setNoRatingError(true);
      return;
    }
    setNoRatingError(false);
    handleSubmit(feedbackValues.rating, feedbackValues?.comment);
    if (onDoneClick) {
      onDoneClick();
    }
  };

  const DoneButtonWithSubmit = React.cloneElement(
    DoneButton as React.ReactElement,
    {
      onClick: submitFeedback,
    }
  );

  return (
    <div className={classes.root}>
      <div className={classes.iconAndTitleContainer}>
        <MessageHeartIcon className={classes.messageHeartIcon} />
        <Typography className={classes.titleText} variant={"h1"}>
          Leave us feedback
        </Typography>
      </div>
      <Divider className={classes.titleDivider} />
      <div className={classes.ratingAndCommentContainer}>
        <Rating
          feedbackValues={feedbackValues}
          setFeedbackValues={setFeedbackValues}
          noRatingError={noRatingError}
          setNoRatingError={setNoRatingError}
          inSidebar={inSidebar}
        />
        <Divider className={classes.ratingAndCommentDivider} />
        <Comment
          feedbackValues={feedbackValues}
          setFeedbackValues={setFeedbackValues}
        />
      </div>
      <div style={{ flex: 1 }} />
      <div className={classes.buttonContainer}>
        {BackButton || <div style={{ flex: 1 }} />}
        {DoneButtonWithSubmit}
      </div>
    </div>
  );
};
