import { FoodGroup } from "../../models";

export type ArticleSource =
  | FoodGroup
  | "home"
  | "sometimes"
  | "mainvssometimes"
  | "nutrienttracker";

interface ArticleProps {
  title: string;
  description: string;
  url: string;
  image: {
    url: string;
  };
  tagName?: string;
}

type Articles = {
  [key in ArticleSource]: ArticleProps[];
};

export const articlesBySource: Articles = {
  dairy: [
    {
      title: "5 vegan milk alternatives to consider trying",
      description:
        "While cow’s milk is a long-standing staple in many Australian fridges, more and more dairy milk alternatives are becoming widely available. Let’s look at five popular options to help you decide what the best dairy milk alternative might be for you.",
      url: "https://www.healthylife.com.au/learn/best-milk-alternative",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
      },
      tagName: "Article",
    },
    {
      title: "Calcium and women’s health",
      description:
        "You have probably heard of calcium before, but do you know why it is important? And what role it plays within women's health? What does calcium do? Calcium helps with the functioning and development of our bones, muscles, heart, nervous system, and chemical reactions.",
      url: "https://www.healthylife.com.au/learn/calcium-and-womens-health",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/3493e7c1-a162-4090-9c10-9312ee2fbb56/womens-health-and-calcium.webp",
      },
      tagName: "Article",
    },
    {
      title: "The truth about cheese and lactose",
      description:
        "Are you following a low FODMAP diet and excluding cheese because it has lactose in it? Well fear not, there are actually a variety of low lactose cheeses that you can enjoy!",
      url: "https://www.healthylife.com.au/learn/the-truth-about-cheese-and-lactose",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/432be470-619b-4e68-ad32-7882c35da6fb/LF72-the-truth-about-cheese-and-lactose.webp",
      },
      tagName: "Article",
    },
    {
      title: "Low FODMAP milk alternatives – which is best?",
      description:
        "The popularity of milk alternatives is booming with a growing number of consumers swapping dairy for varieties like almond, coconut, soy and macadamia. After low FODMAP milk alternatives? Here are your best options.",
      url: "https://www.healthylife.com.au/learn/low-fodmap-milk-alternatives",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/8ef8ef9e-86d3-40f3-bfe7-bc66a603a00c/LF15-low-fodmap-milk-alternatives.webp",
      },
      tagName: "Article",
    },
    {
      title: "Is Cottage Cheese and Mustard Diet Good For You?",
      description:
        "There’s a bizarre new viral food trend taking over TikTok involving cottage cheese and… yellow mustard. If you’ve yet to come across it, you’ll be surprised that the #veggieswithmustard hashtag has had over 525M views. It appears to be taking over social media, but does the trend live up to the hype?",
      url: "https://www.healthylife.com.au/learn/cottage-cheese-and-mustard-diet",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
      },
      tagName: "Article",
    },
  ],
  vegetables: [
    {
      title: "Eat more veggies program",
      description:
        "Get to know the amazing health benefits of veggies and explore ways on how to meet your daily serves. From creative recipes and meal planning like a pro to encouraging even the fussiest of eaters, we’ve got you covered in this free program.",
      url: "https://www.healthylife.com.au/discover/eat-more-veggies-sign-up",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/3777ca66-4d55-42d4-be45-b21e7a24d63e/Veggies%202.webp",
      },
      tagName: "Free Program",
    },
    {
      title: "Can lectins impact your gut?",
      description:
        "Sometimes referred to as “anti-nutrients” lectins have been in the spotlight of late. Much of the attention has been around how they may impact gut health.  Let’s take a closer look at this popular food component and see what science tells us. ",
      url: "https://www.healthylife.com.au/learn/can-lectins-impact-your-gut",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/5c7382ee-13af-44fc-a1e1-157d62ca7d14/bowls-of-seeds-and-legumes.webp",
      },
      tagName: "Article",
    },
    {
      title: "What is resistant starch and why do we need it?",
      description:
        "When it comes to optimal food and nutrition for gut health, resistant starch is something that falls into this category. But what is it, exactly? Put simply, resistant starch is a type of dietary fibre that passes through the digestive tract undigested.",
      url: "https://www.healthylife.com.au/learn/what-is-resistant-starch-and-why-do-we-need-it",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/285b3cc6-bc43-4513-aa76-98e676e25a5d/What-is-resistant-starch-and-why-do-we-need-it_Article_Hero_684x386.webp",
      },
      tagName: "Article",
    },
    {
      title: "Why we should be eating 30 plant foods a week",
      description:
        "You may be wondering what is so important about eating 30 different plant foods each week? Maybe you’ve heard about it but can’t understand how it’s possible on a low FODMAP diet. The good news is, that it’s much more achievable than you may think.",
      url: "https://www.healthylife.com.au/learn/why-we-should-all-be-eating-30-plant-foods-a-week",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ac8453dd-377d-4063-9512-48df34170d90/LF28-why-we-shoud-eat-30-plant-foods-a-week.webp",
      },
      tagName: "Article",
    },
    {
      title: "10 tips to get your kids to eat their vegetables",
      description:
        "There are so many benefits from eating enough veggies - for both adults and kids but try telling a tantruming kid that! ",
      url: "https://www.healthylife.com.au/learn/how-to-get-kids-to-eat-veggies",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/47d6686d-ef86-4931-968a-79ee3caa8fdb/kids-and-vegetables.jpg",
      },
      tagName: "Article",
    },
    {
      title: "9 popular veggies that are good for your health",
      description:
        "With a bombardment of health-related trends, advice and diet tips emerging from every direction, it can be easy to feel overwhelmed.",
      url: "https://www.healthylife.com.au/learn/how-many-servings-of-vegetables-per-day",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/8c3c8163-6bee-4c7d-b9b7-c6d8bbdf8123/vegetables-and-health.webp",
      },
      tagName: "Article",
    },
  ],
  fruit: [
    {
      title: "What is the best fruit for gut health?",
      description:
        "We’ve all heard of the legendary gut health powers of foods like fermented vegetables and natural yoghurt. But did you know that fruit might also positively affect your gastrointestinal system?",
      url: "https://www.healthylife.com.au/learn/best-fruit-for-gut-health",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/7f657529-330d-4cc5-b105-b2ece81b234c/woman-eating-best-fruit-for-gut-health.jpg",
      },
      tagName: "Article",
    },
    {
      title: "Are bananas good for gut health? Here’s what the science says",
      description:
        "Short answer – yes! The evidence suggests bananas are great for gut health. They also taste great, come in their own sustainable packaging, are packed with nutrients and are a filling snack.",
      url: "https://www.healthylife.com.au/learn/are-bananas-good-for-gut-health",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/3f9218bc-e3f1-4e84-a4b0-0feb0fa1eb1a/woman-eating-banana.jpg",
      },
      tagName: "Article",
    },
    {
      title: "Top 10 hydrating snacks and meals",
      description:
        "While drinking water is the best way to hydrate for most of us, it’s estimated that around 19% of water intake in the Western diet comes from food sources. And some foods count toward your hydration level, which is wonderful news if you ask any food lover.",
      url: "https://www.healthylife.com.au/learn/top-10-hydrating-snacks-and-meals",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/3faf8aa2-b04f-432a-bd7d-e0bf2887b1ac/10-hydrating-foods.webp",
      },
      tagName: "Article",
    },
    {
      title: "Low FODMAP summer produce",
      description:
        "With almost all fruit and vegetables available all year round, it’s harder to work out what produce is in season at any given time.",
      url: "https://www.healthylife.com.au/learn/low-fodmap-summer-produce",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/7cff421d-7a83-4c6c-91db-a92187de0a06/LF78-low-fodmap-summer-produce.webp",
      },
      tagName: "Article",
    },
    {
      title: "Is coconut a fruit or a vegetable?",
      description:
        "It turns out that people have a ‘bunch’ of coconut-related questions. We get it: coconuts are tasty, reminiscent of a tropical island, and one of the cutest emojis around. ",
      url: "https://www.healthylife.com.au/learn/is-coconut-a-fruit-or-vegetable",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/bcfd4390-498a-47ea-9b94-4b754b035e4d/coconut-questions.jpg",
      },
      tagName: "Article",
    },
  ],
  grains: [
    {
      title: "Food and fibre: the way to a healthy future?",
      description:
        "If you’ve ever felt bloated or heard your stomach making loud gurgling noises, someone may have suggested increasing your dietary fibre. ",
      url: "https://www.healthylife.com.au/learn/food-and-fibre",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/fc6cb73a-b8ef-4b06-9d04-486dee720a23/fibre-rich-foods.webp",
      },
      tagName: "Article",
    },
    {
      title: "Going for grains!",
      description:
        "Are you looking to take your pantry staples to the next level? A typical Australian pantry tends to include a lot of grain foods, so let’s focus on getting the most from our grains. ",
      url: "https://www.healthylife.com.au/learn/going-for-grains",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/3113336f-6cb9-4418-bf1e-159b3c76ab6b/wholegrain-pantry-staples.webp",
      },
      tagName: "Article",
    },
    {
      title: "Low FODMAP grains",
      description:
        "Grain-based products are one of the first food groups that are blamed for triggering digestive symptoms including bloating and diarrhoea. As a result, more and more people are unnecessarily eliminating carbohydrates and restricting gluten-containing products from their diet.",
      url: "https://www.healthylife.com.au/learn/low-fodmap-grains",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/afaaa219-a85b-465c-b61a-dbbd14f9dd6c/LF24-low-fodmap-grains.webp",
      },
      tagName: "Article",
    },
    {
      title: "5 of the best, affordable (and healthier) pantry staples",
      description:
        "We’ve all heard of the legendary gut health powers of foods like fermented vegetables and natural yoghurt. But did you know that fruit might also positively affect your gastrointestinal system?When it comes to buying healthy ingredients, you don't just have to limit yourself to fresh salads. Stocking your pantry with cheap, long-lasting staples is a smart choice if you’re wanting to be healthy.",
      url: "https://www.healthylife.com.au/learn/best-affordable-and-healthy-pantry-staples",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/9d088540-6486-4505-83e3-451b75228b61/healthy-pantry-basics.webp",
      },
      tagName: "Article",
    },
    {
      title: "7 easy ways to turn pantry staples into a meal (or snack)",
      description:
        "We’ve pulled together seven of our best pantry staple meal and snack ideas to give you some inspiration – one for every day of the week.",
      url: "https://www.healthylife.com.au/learn/7-inspired-tips-for-pantry-staple-recipes",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/5deeb6df-72aa-4a75-a198-642c6cefe5dc/cooking-with-pantry-staples.webp",
      },
      tagName: "Article",
    },
  ],
  protein: [
    {
      title: "Are baked beans good for you?",
      description:
        "Baked beans are a staple in the Australian pantry and whilst a convenient food, it might be a surprise to some that they are healthier than you probably think.",
      url: "https://www.healthylife.com.au/learn/are-baked-beans-good-for-you",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/21028e9e-4310-4ef7-896f-cf4c5bcccb7a/people-cooking-baked-beans-hero.webp",
      },
      tagName: "Article",
    },
    {
      title: "What are the health benefits of legumes?",
      description:
        "Ever wondered what legumes are and whether you've eaten them or not? Legumes are a staple in many cuisines and dishes across the world, and come with a number of health benefits. ",
      url: "https://www.healthylife.com.au/learn/health-benefits-legumes",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/5e87743a-cbd0-4479-aec7-c766d2c2dc19/bowls-of-legumes-hero.webp",
      },
      tagName: "Article",
    },
    {
      title: "How much protein do adults really need per day?",
      description:
        "Protein has the wellness world abuzz, with people sharing recommendations for protein shakes and other ways to up your protein intake. ",
      url: "https://www.healthylife.com.au/learn/how-much-protein-do-adults-really-need-per-day",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ee2eb719-00f7-4deb-98de-ba2363b55d56/protein-sources.jpg",
      },
      tagName: "Article",
    },
    {
      title: "5 common vegan questions answered",
      description:
        "Considering switching to veganism and want to know if you’ll have to give up your favourite hot chip topping? Curious about the vegan lifestyle? Wondering if the world’s most well known animal lover eats animal products?",
      url: "https://www.healthylife.com.au/learn/is-david-attenborough-a-vegan",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/1907e80d-3e2c-4e0b-8437-0874444bdbce/vegan-faqs.webp",
      },
      tagName: "Article",
    },
    {
      title: "What is a flexitarian diet?",
      description:
        "Nothing in life fits perfectly into a box. Your identity. Your family. Your lifestyle. It’s all perfectly imperfect.",
      url: "https://www.healthylife.com.au/learn/what-is-a-flexitarian",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/74dec2f4-69e9-42eb-a123-0ce6615519ec/flexitarian-diet.jpg",
      },
      tagName: "Article",
    },
  ],
  home: [
    {
      title: "6 signs of poor gut health",
      description:
        "Talking about your bowel problems or gut health is not a conversation most people want to have. As embarrassing as it may be, it’s important to know what some of the signs are so you can decide whether to take action.",
      url: "https://www.healthylife.com.au/learn/bowel-problems",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/a31da05e-d8f3-4c56-ac38-ef873e1706cb/gut-health-signs.webp",
      },
      tagName: "Article",
    },
    {
      title: "Book a Telehealth Appointment Online 24/7",
      description:
        "The easiest way to see an Australian registered doctor online 24 hours a day, 7 days a week, including after hours, weekends and public holidays.",
      url: "https://www.healthylife.com.au/telehealth/telehealth-appointment",
      image: {
        url: "https://storage.googleapis.com/food-tracker-images-prod/telehealth-v2.png",
      },
    },
    {
      title: "Is Cottage Cheese and Mustard Diet Good For You?",
      description:
        "There’s a bizarre new viral food trend taking over TikTok involving cottage cheese and… yellow mustard. If you’ve yet to come across it, you’ll be surprised that the #veggieswithmustard hashtag has had over 525M views. It appears to be taking over social media, but does the trend live up to the hype?",
      url: "https://www.healthylife.com.au/learn/cottage-cheese-and-mustard-diet",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/8447c371-be58-4a55-9c1a-e54b5e9c9930/cottage-cheese-and-mustard%20%281%29.webp",
      },
      tagName: "Article",
    },
    {
      title: "How to build a healthy shopping list",
      description:
        "It can be easy to get caught up in a myriad of information when it comes to healthy eating and planning your weekly meals. What should be in the grocery basket? Where can you find the healthiest food in the supermarket? What exactly should you look out for on food labels? These are all valid questions. ",
      url: "https://www.healthylife.com.au/learn/healthy-shopping-list",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/2842cc39-2f00-4fba-9159-105708af1a08/woman-grocery-shopping.jpg",
      },
      tagName: "Article",
    },
    {
      title: "15 foods that may help support your immune system",
      description:
        "Fruits, vegetables, legumes, nuts and seeds are not only delicious, they’re essential for good health. They contain phytonutrients, vitamins and minerals that may support your immune system. ",
      url: "https://www.healthylife.com.au/learn/foods-that-may-support-your-immune-system",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/a3142f84-174a-4a75-b97a-c8530090a2d3/fruits-and-vegetables.jpg",
      },
      tagName: "Article",
    },
    {
      title: "How to Organise Your Pantry: From Cluttered to Clean",
      description:
        "If the kitchen is the heart of the home, then the pantry is the soul that needs a little nurturing too. A cluttered and disorganised pantry can be a source of frustration, but with a pinch of time and a dash of effort, you can transform your pantry into an Instagram-worthy space that is both beautiful and functional.",
      url: "https://www.healthylife.com.au/learn/how-to-organise-your-pantry",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/11f0d764-62ce-406f-9fc8-046499edbf03/organised-pantry.jpg",
      },
      tagName: "Article",
    },
    {
      title: "Is the healthy eating food pyramid still best practice?",
      description:
        "Anyone who grew up in Australia since the 1980s knows exactly what a food pyramid is. It’s reminiscent of primary school visits to the Healthy Harold van, or your childhood doctor’s waiting room. ",
      url: "https://www.healthylife.com.au/learn/food-pyramid-australia",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/78d601d8-fe40-43ad-8fde-0a546a56a034/whats-new-in-healthy-eating.jpg",
      },
      tagName: "Article",
    },
  ],
  sometimes: [
    {
      title: "What are sometimes foods and drinks?",
      description:
        "Sometimes (discretionary) foods and drinks are recommended to have only sometimes and in small amounts. There are a wide range of foods and drinks that are in the ‘sometimes’ category.",
      url: "https://www.healthylife.com.au/learn/sometimes-foods-and-drinks",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/fd33df24-2c67-4501-8a47-43ea16e02c31/sometimes-foods.webp",
      },
      tagName: "Article",
    },
    {
      title: "The facts behind food cravings",
      description:
        "If you’ve ever Googled ‘best way to lose weight or ‘how to deal with weight loss cravings’, you probably stumbled across an article giving you a long list of foods you should try to avoid.",
      url: "https://www.healthylife.com.au/learn/the-facts-behind-food-cravings",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/778311f1-2e22-434b-bfa6-52bbdb22e081/the-facts-behind-food-cravings.webp",
      },
      tagName: "Article",
    },
    {
      title: "How to spot added sugar",
      description:
        "Sugar is unhealthy for our bodies, right? But fruit is healthy and there’s sugar in fruit. No wonder so many people are confused about sugar. ",
      url: "https://www.healthylife.com.au/learn/what-is-sugar",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/50a15faf-6ba9-42b2-b0c2-924a9acc20af/how-to-spot-added-sugar.webp",
      },
      tagName: "Article",
    },
    {
      title: "Is sugar really that bad for us?",
      description:
        "Sugar: we literally can’t live without it, but too much added sugar can hurt us. It’s in almost every packaged and fast food out there, and our high-sugar Western diet is now associated with  chronic ill health. ",
      url: "https://www.healthylife.com.au/learn/why-is-sugar-bad-for-you",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/2a6439de-1d58-4fb2-a022-068d4a52a6d1/ID1005%20Is%20sugar%20really%20that%20bad%20for%20us%20-%20iStock-1097879026.jpg",
      },
      tagName: "Article",
    },
    {
      title: "4 healthier food swaps to manage a sweet tooth",
      description:
        "You’ve finished dinner and sit down to your favourite TV show. Almost as soon as you’ve settled into the couch, you notice the familiar tug of a sweet craving. Next thing you know, you know - you’ve eaten half a block of chocolate without realising what happened. ",
      url: "https://www.healthylife.com.au/learn/how-to-manage-sweet-tooth",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/6742a271-03cf-49e5-b0de-ad9a7238c937/sweet-tooth.jpg",
      },
      tagName: "Article",
    },
  ],
  mainvssometimes: [
    {
      title: "Healthy lunchbox ideas the whole family will love",
      description:
        "Looking for lunchbox inspiration? We have you covered! Nutrition Australia’s Healthy Lunchbox Week runs from the 6th-12th February and aims to empower parents, carers, children and adults to pack tasty and nourishing lunchboxes.",
      url: "https://www.healthylife.com.au/learn/healthy-lunchbox-week",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/400c0844-f0e1-4f98-a481-3710a87ec876/healthy-lunch-ideas.webp",
      },
      tagName: "Article",
    },
    {
      title: "What is a healthy weight? (Spoiler alert: It’s personal)",
      description:
        "What is a healthy weight? It’s a complex question. Research has shown that some people with a ‘healthy’ weight are considered to be metabolically unhealthy. As in they have high blood pressure, high blood sugar, and high triglycerides – a type of fat found in the blood. ",
      url: "https://www.healthylife.com.au/learn/what-is-a-healthy-weight",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/40594db3-0b59-44be-b15c-ccaaac7a996f/Healthy-weight.jpg",
      },
      tagName: "Article",
    },
    {
      title: "Are you drinking enough water? 7 signs to find out",
      description:
        "It’s tricky to drink enough water and it’s something many of us struggle with. Forgetting to drink water happens, especially if you’ve fallen out of the habit. ",
      url: "https://www.healthylife.com.au/learn/are-drinking-enough-water",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/59150cc9-d8d4-4db4-82ab-a97d9af7ac05/are-you-drinking-enough-water.webp",
      },
      tagName: "Article",
    },
    {
      title:
        "Are you hungry or thirsty? The link between hydration and appetite",
      description:
        "Are you likely to eat more if you’re thirsty? Does not drinking enough water impact your appetite? Some smart researchers have found that the answer to these questions is most likely yes. ",
      url: "https://www.healthylife.com.au/learn/hungry-or-thirsty",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/19cd5ffd-6383-4db0-bd24-30832f6239d3/hungry-or-thirsty.webp",
      },
      tagName: "Article",
    },
  ],
  nutrienttracker: [
    {
      title: "How to spot added sugar",
      description:
        "Sugar is unhealthy for our bodies, right? But fruit is healthy and there’s sugar in fruit. No wonder so many people are confused about sugar. ",
      url: "https://www.healthylife.com.au/learn/what-is-sugar",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/50a15faf-6ba9-42b2-b0c2-924a9acc20af/how-to-spot-added-sugar.webp",
      },
      tagName: "Article",
    },
    {
      title: "Is sugar really that bad for us?",
      description:
        "Sugar: we literally can’t live without it, but too much added sugar can hurt us. It’s in almost every packaged and fast food out there, and our high-sugar Western diet is now associated with  chronic ill health. ",
      url: "https://www.healthylife.com.au/learn/why-is-sugar-bad-for-you",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/2a6439de-1d58-4fb2-a022-068d4a52a6d1/ID1005%20Is%20sugar%20really%20that%20bad%20for%20us%20-%20iStock-1097879026.jpg",
      },
      tagName: "Article",
    },
    {
      title: "How to read nutrition labels",
      description:
        "Learning how to read nutrition labels is a great way to become more informed about the foods you’re eating. Whether you’re comparing products or just curious about the nutritional value of your snack, the nutrition label has a lot of great information. ",
      url: "https://www.healthylife.com.au/learn/how-to-read-nutrition-labels",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/bf32bba8-4b5f-4da7-a756-0ca0851fa01a/food-labels.webp",
      },
      tagName: "Article",
    },
    {
      title: "Dietary fats – friend or foe for IBS?",
      description:
        "The low FODMAP diet is a well-established method of successfully managing IBS symptoms in many people. In addition to FODMAPs, there are other dietary factors that may worsen IBS symptoms.  ",
      url: "https://www.healthylife.com.au/learn/dietary-fats-friend-foe-ibs",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/7e53fcb3-8487-4b12-8a65-354415cebdde/LF32-dietary-fats-friend-or-foe.webp",
      },
      tagName: "Article",
    },
    {
      title: "Are you ultra-sure you know what ultra-processed food is?",
      description:
        "Most people assume that fresh food is healthier than processed food. But is this a bit of an urban legend? Before you limit your bread and stop snacking on packaged products, let’s explore what processed food actually is, and the different ways of processing it.",
      url: "https://www.healthylife.com.au/learn/what-is-processed-food",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/e36debfa-f8b9-4c15-8923-32061cf386ac/ultra-processed-foods.webp",
      },
      tagName: "Article",
    },
    {
      title: "What are non-perishable foods and are they healthy for me?",
      description:
        "Although eating all our fruit and vegetables in season, picked when optimally ripe and fresh might be ideal, it isn’t realistic. The good news is we can eat a nourishing diet with a mix of fresh, frozen, dried and canned food making it a whole lot easier.",
      url: "https://www.healthylife.com.au/learn/non-perishable-foods",
      image: {
        url: "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/67ec192d-cbe2-42f0-a23f-3a806d1ee8b4/a-meal-based-on-non-perishable-foods.webp",
      },
      tagName: "Article",
    },
  ],
};
