import { useHistory } from "react-router-dom";
import {
  Link,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import HealthierOptionsList from "./HealthierOptionsList";
import { COLOR_GREYSCALE } from "../../theme";
import HealthierOptionsCarousel from "../../components-2/healthier-options/HealthierOptionsCarousel";
import { NutrientTrackerTypes } from "../../models";
import useEvents from "../../hooks/useEvents";
import { NUTRIENT_TRACKER_EVENT } from "../../events";

const HealthierOptionsSwap = ({
  type,
}: {
  type: NutrientTrackerTypes["type"];
}) => {
  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      marginTop: spacing(2),
      borderRadius: 12,
      padding: "16px 16px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("md")]: {
        boxShadow: "none",
      },
    },
    headerTitle: {
      display: "flex",
      marginBottom: "10px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: `1px solid ${COLOR_GREYSCALE[200]}`,
      [breakpoints.up("md")]: {
        borderBottom: "none",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      [breakpoints.up("md")]: {
        fontSize: "28px",
        fontFamily: "Gilroy-Bold",
        fontWeight: "normal",
        lineHeight: "36px",
      },
    },
    similarProductsText: {
      padding: "0px 16px",
    },
    container: {
      [breakpoints.up("md")]: {
        border: `1px solid ${COLOR_GREYSCALE[200]}`,
        margin: "0 60px",
        borderRadius: "12px",
        padding: "16px",
        overflow: "auto",
      },
    },
    healthierOptionsIcon: {
      "& path": {
        fill: COLOR_GREYSCALE[700],
        width: "20",
        height: "20",
      },
    },
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const classes = useStyles();
  const { track } = useEvents();
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.headerTitle}>
        <div className={classes.titleContainer}>
          <Link
            onClick={() => {
              track(NUTRIENT_TRACKER_EVENT.HO_OPEN);
              history.push("/healthier-options/plp?source=nutrient-tracker");
            }}
            style={{
              textDecoration: "none",
              color: COLOR_GREYSCALE[800],
            }}
          >
            <div className={classes.title}>You might consider swapping</div>
          </Link>
        </div>
      </Typography>

      <div className={classes.container}>
        <div>
          <Typography
            variant="body2"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              marginBottom: "4px",
            }}
          >
            <HealthierOptionsIcon className={classes.healthierOptionsIcon} />
            <span style={{ color: COLOR_GREYSCALE[700] }}>
              <span
                style={{ fontFamily: "Gilroy-SemiBold", fontWeight: "normal" }}
              >
                - Healthier Options
              </span>{" "}
              available
            </span>
          </Typography>
        </div>

        {isMobile ? (
          <HealthierOptionsList type={type} />
        ) : (
          <div style={{ marginBottom: "24px" }}>
            <HealthierOptionsCarousel
              filterByNutrients={type}
              source="nutrient-tracker"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HealthierOptionsSwap;
