import { makeStyles } from "@material-ui/core";
import { FoodGroup } from "../../../models";
import { FoodGroupColorIntake } from "../../../constants";
import { ReactComponent as IndicatorTriangle } from "../../../icons/day-indicator-triangle.svg";
import { COLOR_GREYSCALE, LEAFY_GREEN, WHITE } from "../../../theme";
interface Props {
  foodGroup: FoodGroup;
  percent: number | null;
  empty: boolean;
}
const RecommendedServesVerticalBar = ({ foodGroup, percent, empty }: Props) => {
  const BarColors: {
    [key in FoodGroup]: {
      root: string;
      main: string;
      percentText: string;
    };
  } = {
    dairy: {
      root: "rgba(176, 215, 255, 0.25)",
      main: "#B0D7FF",
      percentText: WHITE,
    },
    fruit: {
      root: "rgba(242, 226, 249, 0.50)",
      main: "#E5C8F4",
      percentText: WHITE,
    },
    grains: {
      root: "#FFEED6",
      main: "#FFD599",
      percentText: COLOR_GREYSCALE[900],
    },
    vegetables: {
      root: "#F1F6E3",
      main: "#E3F9A6",
      percentText: LEAFY_GREEN[700],
    },
    protein: {
      root: "#FFDAE3",
      main: "#FFA9BD",
      percentText: COLOR_GREYSCALE[900],
    },
  };

  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      height: "28px",
      width: "100%",
      backgroundColor: BarColors[foodGroup].root,
      borderRadius: "4px",
      position: "relative",
    },
    mainBar: {
      position: "relative",
      height: "100%",
      width: empty ? "0%" : "80%",
      backgroundColor: BarColors[foodGroup].main,
      overflowY: "visible",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    hundredPercentIndicator: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      right: "-44px",
      top: "-18px",
    },
    recommendedText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: 0,
      marginTop: "-13px",
      marginBottom: "4px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      color: COLOR_GREYSCALE[900],
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
    },
    indicatorTriangle: {
      transform: "rotate(180deg)",
      "& path": {
        fill: FoodGroupColorIntake[foodGroup],
      },
    },
    dashed: {
      height: "33px",
      width: "1px",
      borderLeft: "dashed 1px #000000",
    },
    hundredText: {
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[900],
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.2px",
      margin: 0,
      marginTop: "4px",
    },
    percentBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      height: "100%",
      backgroundColor: FoodGroupColorIntake[foodGroup],
      width: `${percent ?? 0}%`,
      transition: `width .3s ease-in-out`,
      maxWidth: "125%",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderTopRightRadius: `${(percent ?? 0) >= 125 ? "4px" : "0px"}`,
      borderBottomRightRadius: `${(percent ?? 0) >= 125 ? "4px" : "0px"}`,
    },
    percentText: {
      color: BarColors[foodGroup].percentText,
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      marginRight: "8px",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.mainBar}>
        <div className={classes.hundredPercentIndicator}>
          {!empty && (
            <>
              <p className={classes.recommendedText}>
                Recommended
                <IndicatorTriangle className={classes.indicatorTriangle} />
              </p>
              <span className={classes.dashed}></span>
              <span className={classes.hundredText}>100%</span>
            </>
          )}
        </div>
        <div className={classes.percentBar}>
          {!empty && <span className={classes.percentText}>{percent}%</span>}
        </div>
        {empty && <span className={classes.percentText}>0%</span>}
      </div>
    </div>
  );
};

export default RecommendedServesVerticalBar;
