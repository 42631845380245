import { Dispatch, FC, SetStateAction } from "react";
import { makeStyles } from "@material-ui/core";
import { NutrientTrackerTypes } from "../../models";
import useEvents from "../../hooks/useEvents";
import { NUTRIENT_TRACKER_EVENTS } from "../../constants";
import { COLOR_GREYSCALE } from "../../theme";
import { NUTRIENT_TRACKER_EVENT } from "../../events";

interface NutrientTracketTabsProps {
  type: NutrientTrackerTypes["type"];
  setType: Dispatch<SetStateAction<NutrientTrackerTypes["type"]>>;
}

const NutrientTrackerTabs: FC<NutrientTracketTabsProps> = ({
  type,
  setType,
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      display: "flex",
      gap: "4px",
      width: "100%",
      borderRadius: "8px",
      backgroundColor: COLOR_GREYSCALE[50],
      padding: "4px",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      overflow: "hidden",
      [breakpoints.up("lg")]: {
        maxWidth: "348px",
      },
    },
    tabs: {
      padding: "4px 8px",
      borderRadius: "8px",
      backgroundColor: "transparent",
      color: COLOR_GREYSCALE[700],
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      flex: 1,
      border: 0,
      cursor: "pointer",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      boxSizing: "border-box",
    },
    tabActive: {
      backgroundColor: "#fff",
      color: "#1D1F20",
      border: "1px solid #BEC2C6",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    },
  }));
  const { track } = useEvents();
  const changeType = (type: NutrientTrackerTypes["type"]) => {
    track(NUTRIENT_TRACKER_EVENTS.SWITCH_NUTRIENT, { type: type });
    if (type === "fat") {
      track(NUTRIENT_TRACKER_EVENT.FAT);
    } else if (type === "salt") {
      track(NUTRIENT_TRACKER_EVENT.SALT);
    } else if (type === "sugar") {
      track(NUTRIENT_TRACKER_EVENT.SUGAR);
    }
    setType(type);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <button
        className={`${classes.tabs} ${type === "sugar" && classes.tabActive}`}
        onClick={() => changeType("sugar")}
      >
        Sugar
      </button>
      <button
        className={`${classes.tabs} ${type === "salt" && classes.tabActive}`}
        onClick={() => changeType("salt")}
      >
        Salt
      </button>
      <button
        className={`${classes.tabs} ${type === "fat" && classes.tabActive}`}
        onClick={() => changeType("fat")}
      >
        Fat
      </button>
    </div>
  );
};

export default NutrientTrackerTabs;
