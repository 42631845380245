import {
  InfoModalContainer,
  InfoModalDefaultButton,
} from "../../../components-2/InfoModal";
import {
  FOOD_GROUP_PAGES_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../../events";
import { FoodGroup } from "../../../models";
import RecommendedServesExplainerContent from "./RecommendedServesExplainerContent";

const RecommendedServesExplainer = ({
  foodGroup,
}: {
  foodGroup: FoodGroup | "sometimes";
}) => {
  return (
    <InfoModalContainer
      eventName={
        foodGroup === "sometimes"
          ? SOMETIMES_FOOD_PAGE_EVENT.HELP_OPEN
          : FOOD_GROUP_PAGES_EVENT.SERVES_HELP_OPEN
      }
      eventCloseName={
        foodGroup === "sometimes"
          ? SOMETIMES_FOOD_PAGE_EVENT.HELP_CLOSE
          : FOOD_GROUP_PAGES_EVENT.SERVES_HELP_CLOSE
      }
      eventParams={{ foodgroup: foodGroup }}
      button={<InfoModalDefaultButton />}
    >
      <RecommendedServesExplainerContent foodGroup={foodGroup} />
    </InfoModalContainer>
  );
};

export default RecommendedServesExplainer;
