import { Dispatch, FC, SetStateAction } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { BasketDay } from "../../models";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BasketDetailsTable from "../food-groups/basket-details/BasketDetailsTable";
import { Accordion, AccordionIcon } from "../../components-2/Accordion";

interface Props {
  id: string;
  expanded: boolean;
  handleChange: Dispatch<SetStateAction<string>>;
  title: string;
  description: string;
  basketDetails: BasketDay[];
}

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: "solid 1px #C5C5C5",
    marginTop: 0,

    "&.Mui-expanded": {
      marginTop: 0,
    },

    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  accordionHeader: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    padding: "16px",
    margin: 0,
    minHeight: "auto",
    transition: "all .3s ease-in-out",

    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "auto",
      paddingBottom: 16,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 0,
    },
  },
  accordionContent: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    paddingTop: 0,
    margin: 0,
  },
}));

const SometimesFoodBasketDetailsAccordion: FC<Props> = ({
  id,
  title,
  description,
  expanded,
  handleChange,
  basketDetails,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleChange(id)}
      className={classes.root}
    >
      <AccordionSummary
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        expandIcon={<AccordionIcon />}
        className={classes.accordionHeader}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContent}>
        {basketDetails.length > 0 ? (
          <>
            <Typography variant="subtitle1">{description}</Typography>
            <Typography variant="body1">Purchased items</Typography>
            <BasketDetailsTable
              basketDetails={basketDetails}
              foodGroup={"discretionary"}
            />
          </>
        ) : (
          <Typography variant="subtitle1">
            Bummer! We don't have any products to show you at the moment. Check
            back once you've shopped.
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SometimesFoodBasketDetailsAccordion;
