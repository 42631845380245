import { Tab, withStyles } from "@material-ui/core";
import { CHARCOAL } from "../../theme";

const StyledTab = withStyles((theme) => ({
  root: {
    minHeight: 36,
    textTransform: "none",
    color: CHARCOAL,
    fontFamily: '"Gilroy-Bold"',
    fontSize: 20,
    fontWeight: "normal",
    opacity: 1,
    minWidth: "0",
    "@media (min-width: 0px)": {
      minWidth: 0,
    },
    padding: theme.spacing(0, 1.5),
    "&:focus-visible": {
      outline: "revert"
    }
  },
}))(Tab);

export default StyledTab;
