import useHousehold from "./useHousehold";
import { accumulateServings } from "../services/servings";
import { Duration } from "luxon";

const useHouseholdBenchmarkServings = (timeFrame: Duration) => {
  const { household } = useHousehold();

  return accumulateServings(
    household.map((hm) => hm.benchmark.dailyServings),
    timeFrame
  );
};

export default useHouseholdBenchmarkServings;
