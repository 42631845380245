import { useCallback, useContext, useEffect, useState } from "react";
import { BenchmarksContext } from "../components/providers/BenchmarksProvider";
import { Benchmark } from "../models";
import { onlyEnabled } from "../services/contentful/benchmarks";

export default function useBenchmarks() {
  const benchmarks = useContext(BenchmarksContext);
  const [currentBenchmarks, setCurrentBenchmarks] = useState<Benchmark[]>([]);

  const benchmarkWithId = useCallback(
    (id: string) => benchmarks?.find((b) => b.id === id),
    [benchmarks]
  );

  useEffect(() => {
    if (benchmarks) {
      setCurrentBenchmarks(onlyEnabled(benchmarks));
    }
  }, [benchmarks]);

  return {
    loadingBenchmarks: benchmarks !== null,
    benchmarks: benchmarks ?? [],
    currentBenchmarks,
    benchmarkWithId,
  };
}
