import { FC, useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { TimeFrame } from "../../constants";
import BalanceOfShopV2Bar from "./BalanceOfShopV2Bar";
import { ServingData } from "./BalanceOfShopV2";
import TrolleyLoader from "../TrolleyLoader";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import useConfetti from "../../hooks/useConfetti";

interface Props {
  householdServingsData: ServingData[];
  householdNotEmpty: boolean;
  emptyServings: boolean;
  timeFrame: TimeFrame;
  loadingServings: boolean;
}

function findLastNonNegativeIndex(arr: ServingData[]) {
  const reversedIndex = arr
    .slice()
    .reverse()
    .findIndex((item) => item.servingsPurchased >= 0);
  if (reversedIndex === -1) return -1; // not found
  return arr.length - 1 - reversedIndex;
}

const BalanceOfShopV2BarChart: FC<Props> = ({
  householdServingsData,
  householdNotEmpty,
  emptyServings,
  timeFrame,
  loadingServings,
}) => {
  const servingExceeded = householdServingsData.some(
    (data) =>
      Math.ceil((data.servingsPurchased / data.benchmarkServings) * 100) >= 100
  );

  const servingLessFifty = householdServingsData.every(
    (data) =>
      Math.ceil((data.servingsPurchased / data.benchmarkServings) * 100) <= 50
  );

  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    barChart: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    barChartContainer: {
      display: "grid",
      gridTemplateColumns: "24px 1fr",
      gridColumnGap: "4px",
      flexDirection: "row",
    },
    percentageLegendContainer: {
      display: "flex",
      position: "relative",
      flexDirection: "column-reverse",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      gridColumn: "1 / span all",
      gridRow: "1",
    },
    percentageLegend: {
      position: "absolute",
      left: 0,
      display: "block",
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      margin: 0,
      width: "100%",
      [breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",
        fontFamily: "Gilroy",
      },

      "&:nth-of-type(1)": {
        bottom: "-7px",
      },
      "&:nth-of-type(2)": {
        bottom: servingExceeded ? "38px" : "62px",
      },
      "&:nth-of-type(3)": {
        bottom: servingExceeded ? "86px" : "134px",
        color: DARK_GREEN,
        "&::after": {
          borderBottom: `dashed 1px ${DARK_GREEN}`,
        },
      },
      "&:nth-of-type(4)": {
        bottom: "134px",
      },
      "&::after": {
        content: `" "`,
        display: "block",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        borderBottom: "solid 1px #C5C5C7",
        width: "calc(100% - 28px)",
        left: "28px",
        [breakpoints.up("md")]: {
          width: "calc(100% - 40px)",
          left: "40px",
        },
      },
    },
    barContainer: {
      display: "flex",
      position: "relative",
      alignItems: "flex-end",
      height: "144px",
      width: "100%",
      gridColumn: "2 / span 1",
      gridRow: "1",
    },
    barInnerContainer: {
      display: "grid",
      justifyContent: "center",
      height: servingExceeded ? "96px" : "144px",
      width: "100%",
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    hundredPercentText: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      color: "#585C60",
      margin: 0,
      marginLeft: "5px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "#ffffff",
      opacity: 0.8,
      zIndex: 10,
      borderRadius: 12,
    },
    textContainer: {
      position: "absolute",
      top: "calc(50% - 50px)",
      left: "calc(50% - 150px)",
      padding: spacing(1.5, 0),
      width: 300,
      zIndex: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
    loaderContainer: {
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 10,
    },
    overlayText: {
      padding: spacing(0.5),
      textAlign: "center",
      lineHeight: "24px",
      fontFamily: "Gilroy",
      textDecoration: "underline",
    },
  }));
  const classes = useStyles();

  const { attemptBalanceOfShopConfetti } = useConfetti();

  const barRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    const handleTransitionEnd = () => {
      const oneFoodGroupHasHit100Percent = householdServingsData.some(
        ({ benchmarkServings, servingsPurchased }) =>
          servingsPurchased >= benchmarkServings && servingsPurchased > 0
      );
      if (oneFoodGroupHasHit100Percent) {
        attemptBalanceOfShopConfetti();
      }
    };

    let lastBarRef =
      barRefs.current[findLastNonNegativeIndex(householdServingsData)];

    lastBarRef?.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      lastBarRef?.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [attemptBalanceOfShopConfetti, householdServingsData]);

  const [shouldShowLoader, setShouldShowLoader] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (loadingServings) {
      timer = setTimeout(() => {
        setShouldShowLoader(true);
      }, 500);
    } else {
      if (timer) clearTimeout(timer);
      setShouldShowLoader(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [loadingServings]);

  const noServingHouseHold = useMemo(
    () => (emptyServings && householdNotEmpty) || !householdNotEmpty,
    [emptyServings, householdNotEmpty]
  );

  return (
    <div className={classes.barChart}>
      <div className={classes.barChartContainer}>
        <div className={classes.percentageLegendContainer}>
          <p className={classes.percentageLegend}>0%</p>
          <p className={classes.percentageLegend}>
            {servingLessFifty ? "25%" : "50%"}
          </p>
          <p className={classes.percentageLegend}>
            {servingLessFifty ? "50%" : "100%"}
          </p>
          {servingExceeded && <p className={classes.percentageLegend}>150%</p>}
        </div>
        <div
          className={classes.barContainer}
          aria-label={`
            Balance of your shop shows your food groups, broken down as a bar chart.
            You can see Vegetables and legumes, then Fruit, then Dairy and alternatives, then Meat and alternatives and finally Grains.
        `}
        >
          <div className={classes.barInnerContainer}>
            {householdServingsData.map((data, index) => (
              <BalanceOfShopV2Bar
                ref={(el: HTMLElement | null) => (barRefs.current[index] = el)}
                key={data.foodGroup}
                {...data}
                keyIndex={index}
                timeFrame={timeFrame}
                servingLessFifty={!noServingHouseHold && servingLessFifty}
                servingExceeded={!noServingHouseHold && servingExceeded}
                noServingHousehold={noServingHouseHold}
              />
            ))}
          </div>
        </div>
      </div>
      {shouldShowLoader && (
        <>
          <div className={classes.overlay} />
          <div tabIndex={0} className={classes.loaderContainer}>
            <TrolleyLoader size={80} />
          </div>
        </>
      )}
    </div>
  );
};

export default BalanceOfShopV2BarChart;
