import { AgeGroup, Gender } from "../../models";
import { mapToAgeGroup } from "./agegroup";
import { GENDER_OPTIONS } from "./hardcodedContentfulContent";

export const mapToGender = (item: any): Gender => ({
  id: item.sys.id,
  name: item.name,
  applicableAgeGroups:
    item.applicableAgeGroupsCollection.items.map(mapToAgeGroup),
  enabled: item.enabled,
});

export const listGenderOptions = async (
  ageGroup: AgeGroup
): Promise<Gender[]> => {
  // Used to fetched from Contentful, now hard coded.
  const { items } = GENDER_OPTIONS.data.genderCollection;

  return items
    .map(mapToGender)
    .filter(
      (item: Gender) =>
        item.enabled &&
        item.applicableAgeGroups.find((val) => val.id === ageGroup.id)
    );
};
