import { makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
  InfoModalTitle,
} from "../InfoModal";
import { useState } from "react";
import { FAQ_ACCORDION_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";

export const HealthyInsightsExplainer = () => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const faqTitles = [
    "Why can't I see any insights?",
    "How should I use the insights feature?",
  ];

  const { track } = useEvents();

  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = makeStyles(() => ({
    accordionHeader: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      padding: 0,
      margin: 0,
      minHeight: "auto",
      transition: "all .3s ease-in-out",

      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "auto",
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiIconButton-root": {
        padding: 0,
        marginRight: 0,
      },
    },
    accordionContent: {
      display: "block",
      padding: 0,
      marginTop: "8px",
    },
  }))();
  return (
    <InfoModalContainer
      eventName={"healthy_insights_help_open"}
      eventCloseName={"healthy_insights_help_close"}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalContent>
        Insights are here to help you quickly hone in on any obvious areas of
        improvement and remove the hard work. We analyse your shopping basket
        for you and give you the key insights that are most relevant.
      </InfoModalContent>
      <InfoModalContent>
        Food group and nutrient insights compare your latest period selected vs
        the previous five periods of shopping data to establish a positive or
        negative trend
      </InfoModalContent>
      <InfoModalContent>
        You can click on the insight to get a more detailed view and use our
        simple tools and tips to help you make healthier choices, all in the
        palm of your hand.
      </InfoModalContent>
      <InfoModalTitle>FAQs</InfoModalTitle>
      <InfoModalContent>
        <AccordionContainer>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="barchart-content"
              id="barchart-header"
              expandIcon={<AccordionIcon />}
              className={classes.accordionHeader}
            >
              Why can't I see any insights?
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Don't fret if you can't find any insights. You'll need at least
              two previous periods of data before Insights can work its magic.
              If you haven't shopped recently, this feature may not yield
              results just yet.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="barchart-content"
              id="barchart-header"
              expandIcon={<AccordionIcon />}
              className={classes.accordionHeader}
            >
              How should I use the insights feature?
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Empower your shopping decisions with data! Insights help you
              identify recent trends, spot opportunities for improvement, and
              understand your evolving behaviour.
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
      </InfoModalContent>
    </InfoModalContainer>
  );
};
