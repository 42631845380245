import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { Table, TableBody, TableHead } from "@material-ui/core";
import { DateTime } from "luxon";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import { getDayOfMonthSuffix } from "../../utils/dates";
import { StyledTableRow } from "../../components/table/StyledTableRow";
import { COLOR_GREYSCALE } from "../../theme";
import { FruitAndVegCampaignContext } from "../../components/providers/FruitAndVegCampaignPurchasesProvider";

const TableCell = withStyles({
  root: {
    // all of these to override styles being applied from healthylife website
    borderRight: "none !important",
    padding: "8px 16px !important",
    borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
  },
})(MuiTableCell);

const TableContainer = withStyles({
  root: {
    paddingRight: 0,
  },
})(MuiTableContainer);

const formatDate = (date: DateTime) => {
  const day = date.get("day");
  return date.toFormat(`d'${getDayOfMonthSuffix(day)}' MMM`);
};

const useStyles = makeStyles(({ spacing }) => ({
  tableContainer: {
    marginTop: spacing(2),
    backgroundColor: COLOR_GREYSCALE[50],
    borderRadius: "8px",
  },
  tableCellHeading: {
    borderBottom: "none!important",
  },
  excludeCheckbox: {
    padding: 0,
  },
  tableHeading: {
    marginTop: spacing(1),
    marginBottom: spacing(0.5),
    color: "#3A3D40",
    fontFamily: '"Gilroy-SemiBold"',
    fontWeight: "normal",
  },
  purchaseDate: {
    backgroundColor: "rgba(255, 255, 255, 0.60)!important",
    borderBottom: "none!important",
    padding: "4px 16px !important",
  },
  tableText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
}));

const FruitAndVegChallengeTable = () => {
  const classes = useStyles();
  const { basketDetails: eligibleProductsByDay } = useContext(
    FruitAndVegCampaignContext
  );
  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <TableCell className={classes.tableCellHeading}>
              <Typography variant="body2" className={classes.tableHeading}>
                Items
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCellHeading}>
              <Typography
                variant="body2"
                style={{ textAlign: "center" }}
                className={classes.tableHeading}
              >
                Quantity
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <React.Fragment>
            {eligibleProductsByDay.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={2} className={classes.purchaseDate}>
                  <Typography
                    variant="body1"
                    style={{
                      color: "#585C60",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    No Purchased Items.
                  </Typography>
                </TableCell>
              </StyledTableRow>
            )}
            {eligibleProductsByDay
              .sort(
                (a, b) =>
                  new Date(b.purchaseDate).getTime() -
                  new Date(a.purchaseDate).getTime()
              )
              .map(({ purchaseDate, articles }) => {
                return (
                  <>
                    <StyledTableRow key={purchaseDate}>
                      <TableCell className={classes.purchaseDate}>
                        <Typography
                          variant="subtitle1"
                          component={"h3"}
                          style={{
                            color: "#585C60",
                            textTransform: "uppercase",
                          }}
                        >
                          {formatDate(DateTime.fromISO(purchaseDate))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.purchaseDate} />
                    </StyledTableRow>
                    {articles.map((a) => (
                      <StyledTableRow
                        key={a.articleId + a.purchaseDate + a.amountSpent}
                      >
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            component={"h4"}
                            className={classes.tableText}
                          >
                            {a.description}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            component={"h4"}
                            className={classes.tableText}
                            style={{ textAlign: "center" }}
                          >
                            {a.quantity}
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </>
                );
              })}
          </React.Fragment>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FruitAndVegChallengeTable;
