import React, {
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Link,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Drawer } from "vaul";
import { ArrowBackIos, ChevronRight, CloseRounded } from "@material-ui/icons";
import { CHARCOAL, COLOR_GREYSCALE, WHITE } from "../../theme";
import ExternalLink from "../../components/ExternalLink";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import { useHistory } from "react-router-dom";

export const ArticleCard = ({
  url,
  title,
  description,
  image,
}: {
  url: string;
  title: string;
  description: string;
  image: string;
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    article: {
      display: "flex",
      height: "128px",
      borderRadius: "12px",
      backgroundColor: WHITE,
      overflow: "hidden",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    },
    articleUrl: {
      textDecoration: "none",
    },
    articleImage: {
      "& img": {
        width: "110px",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },
    articleDetail: {
      padding: "8px 16px 8px 8px",
    },
    articleTitle: {
      margin: 0,
      marginBottom: "4px",
      color: "#1D1F29",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 2,
      display: "-webkit-box",
      boxOrient: "vertical",
    },
    articleDescription: {
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginTop: "auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 4,
      display: "-webkit-box",
      boxOrient: "vertical",
    },
  }));

  const classes = useStyles();

  return (
    <a
      href={`${url}?utm_source=app-food-tracker&utm_medium=referral&utm_campaign=articles`}
      className={classes.articleUrl}
      target="_blank"
      rel="noreferrer"
    >
      <div className={classes.article}>
        <div className={classes.articleImage}>
          <img src={image} alt={`${title}`} />
        </div>
        <div className={classes.articleDetail}>
          <h2 className={classes.articleTitle}>{title}</h2>
          <p className={classes.articleDescription}>{description}</p>
        </div>
      </div>
    </a>
  );
};

export const BackButton = ({
  onClick,
  color,
}: {
  onClick: () => void;
  color?: string;
}) => {
  return (
    <Button
      onClick={onClick}
      variant={"text"}
      style={{
        color: color ? color : "#1D1F20",
        fontSize: "13.333px",
        fontFamily: "Gilroy-SemiBold",
        lineHeight: "20px",
        letterSpacing: "0.375px",
        textDecoration: "none",
        padding: "0px",
        transition: "color 0.1s ease",
      }}
      startIcon={
        <ArrowBackIos
          style={{
            width: "16px",
            height: "16px",
            color: color ? color : CHARCOAL,
            transition: "color 0.1s ease",
          }}
        />
      }
    >
      Back
    </Button>
  );
};

export const CancelButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={onClick}
      style={{
        padding: "0px",
      }}
    >
      <CloseRounded
        style={{
          backgroundColor: COLOR_GREYSCALE[800],
          color: WHITE,
          borderRadius: "32px",
        }}
      />
    </IconButton>
  );
};

export const InternalPageLink = ({
  route,
  text,
}: {
  route: string;
  text: string;
}) => {
  const history = useHistory();
  return (
    <Link
      onKeyPress={(e) =>
        ifEnterOrSpace(e, () => {
          history.push(route);
        })
      }
      onClick={() => {
        history.push(route);
      }}
      style={{
        color: COLOR_GREYSCALE[900],
        fontFamily: "Gilroy-Semibold",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.2px",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {text}
      <ChevronRight />
    </Link>
  );
};

export const ExternalArticleLink = ({
  href,
  text,
  utmContent,
}: {
  href: string;
  text: string;
  utmContent?: string;
}) => {
  return (
    <ExternalLink
      href={`${href}?utm_source=app-food-tracker&utm_medium=referral&utm_campaign=articles${
        utmContent ? "&utm_content=" + utmContent : ""
      }`}
      style={{
        color: COLOR_GREYSCALE[900],
        fontFamily: "Gilroy-Semibold",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.2px",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        paddingBottom: "24px",
      }}
    >
      {text}
      <ChevronRight width={16} height={16} />
    </ExternalLink>
  );
};

export const AnimatedDrawer = ({
  children,
  openButton,
  onClose,
  open,
  setOpen,
  height = 96,
}: {
  openButton: ReactElement;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactElement;
  onClose: () => void;
  height?: number;
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    drawerOverlay: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999999,
    },
    drawerContent: {
      outline: "none",
      backgroundColor: "#faf5ec",
      position: "fixed",
      bottom: "0px",
      right: "0px",
      display: "flex",
      flexDirection: "column",
      [breakpoints.down("sm")]: {
        maxHeight: `${height}%`,
        height: `${height}%`,
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        left: "0px",
        zIndex: 999999999999,
      },
      [breakpoints.up("md")]: {
        height: "100%",
        zIndex: 99999999,
        width: "506px",
      },
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  return (
    <Drawer.Root
      dismissible={false}
      shouldScaleBackground={false}
      onClose={onClose}
      open={open}
      direction={isMobile ? "bottom" : "right"}
    >
      {cloneElement(openButton, {
        onClick: () => setOpen(true),
        style: {
          cursor: "pointer",
        },
      })}
      <Drawer.Portal>
        <Drawer.Overlay
          className={classes.drawerOverlay}
          onClick={() => setOpen(false)}
        />
        <Drawer.Content className={classes.drawerContent}>
          {children}
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
