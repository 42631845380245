import SidebarSettings from "./SidebarSettings";
import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { useContext } from "react";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { ReactComponent as BasketIcon } from "../../icons/basket.svg";
import { useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

const SidebarTools = () => {
  const { setIsOpenSidebar, isOpenTools, setIsOpenTools, closeAllSidebar } =
    useContext(SettingsContext);
  const history = useHistory();

  const classes = makeStyles(({ breakpoints }) => ({
    container: {
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("md")]: {
        height: "100%",
        overflow: "auto",
      },
    },
    exclude: {
      marginTop: "32px",
      padding: "24px",
      [breakpoints.up("md")]: {
        marginTop: "64px",
      },
      "& h4": {
        margin: 0,
        marginBottom: "16px",
        color: COLOR_GREYSCALE[900],
        fontFamily: "Gilroy-SemiBold",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.1px",
      },
      "& p": {
        margin: 0,
        marginBottom: "16px",
        color: COLOR_GREYSCALE[700],
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",

        "& strong": {
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
        },

        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    heading: {
      padding: "16px 24px",
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
      display: "flex",
      columnGap: "8px",
    },
    headingText: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    innerContent: {
      padding: "16px 24px",
    },
    button: {
      border: "none",
      padding: "8px 0",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      backgroundColor: "transparent",
      display: "flex",
      columnGap: "16px",
      width: "100%",
      justifyContent: "space-between",
    },
    separator: {
      width: "100%",
      height: "1px",
      marginTop: "8px",
      marginBottom: "8px",
      backgroundColor: COLOR_GREYSCALE[100],
    },
  }))();

  const tools = [
    {
      title: "Healthier Options",
      route: "/healthier-options/plp",
    },
    {
      title: "Nutrient Tracker",
      route: "/nutrient-tracker",
    },
    {
      title: "Recipes",
      route: "/recipes",
    },
    {
      title: "Main vs Sometimes Food",
      route: "/main-vs-sometimes",
    },
  ];
  return (
    <SidebarSettings
      sidebarOpen={isOpenTools}
      setSidebarOpen={setIsOpenTools}
      backButtonAction={() => {
        setIsOpenTools(false);
        setIsOpenSidebar(true);
      }}
    >
      <div className={classes.container}>
        <div className={classes.heading}>
          <BasketIcon width={20} />
          <h3 className={classes.headingText}>Explore Food Tracker tools</h3>
        </div>
        <div className={classes.innerContent}>
          {tools.map((tool, index) => (
            <div key={tool.title}>
              <button
                className={classes.button}
                onClick={() => {
                  closeAllSidebar();
                  history.push(tool.route);
                }}
              >
                {tool.title}
                <ChevronRight />
              </button>
              {tools.length - 1 !== index && (
                <div className={classes.separator} />
              )}
            </div>
          ))}
        </div>
      </div>
    </SidebarSettings>
  );
};

export default SidebarTools;
