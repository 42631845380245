import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import StyledDialog from "../StyledDialog";
import TermsAndConditions from "../TermsAndConditions";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";

interface Props {
  disabled: boolean;
  value: boolean;
  onChange: (checked: boolean) => void;
}

const useStyles = makeStyles(() => ({
  checkboxWrapper: {
    marginTop: 24,
    alignItems: "flex-start",
  },
  checkbox: {
    paddingTop: 0,
  },
  checkboxLabel: {
    margin: 0,
  },
  checkboxText: {
    fontFamily: '"Montserrat"',
  },
}));

const ConsentCheckbox: React.FC<Props> = ({ disabled, value, onChange }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControlLabel
      className={classes.checkboxWrapper}
      classes={{ label: classes.checkboxLabel }}
      control={
        <Checkbox
          className={classes.checkbox}
          disabled={disabled}
          value={value}
          onChange={(e) => onChange(e.target.checked)}
          color="default"
        />
      }
      label={
        <>
          <Typography variant="subtitle2" className={classes.checkboxText}>
            I consent to link my Everyday Rewards membership card to get access
            to Healthylife Food Tracker and agree to the{" "}
            <Link
              onClick={handleOpen}
              onKeyPress={(e) => ifEnterOrSpace(e, handleOpen)}
              tabIndex={0}
            >
              Food Tracker Terms &amp; Conditions
            </Link>
            .
          </Typography>

          <StyledDialog title="" open={open} onClose={handleClose}>
            <TermsAndConditions />
          </StyledDialog>
        </>
      }
    />
  );
};

export default ConsentCheckbox;
