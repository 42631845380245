import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC } from "react";
import { COLOR_GREYSCALE, COLOR_SUPPORT_SUCCESS, WHITE } from "../../theme";

interface Props {
  threshold: number;
  value: number;
  onClick: () => void;
}

const barStyle = {
  alignItems: "center",
  justifyContent: "center",
  position: "relative" as const,
};

const useStyles = makeStyles(({ spacing }) => ({
  wooliesBar: {
    ...barStyle,
    backgroundColor: COLOR_SUPPORT_SUCCESS[600],
  },
  otherBar: {
    ...barStyle,
    backgroundColor: COLOR_GREYSCALE[300],
  },
  percent: {
    fontSize: "14px",
    color: WHITE,
    margin: 0,
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    letterSpacing: "0.5px",
    lineHeight: "24px",
  },
  clickToAllocate: {
    position: "absolute",
    left: "36px",
    whiteSpace: "nowrap",
    zIndex: 1,
    fontFamily: '"Gilroy-Bold"',
    fontWeight: "normal",
    color: COLOR_GREYSCALE[700],
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const ShoppingAllocationBar: FC<Props> = ({ threshold, value, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={value >= threshold ? classes.wooliesBar : classes.otherBar}
      onClick={onClick}
      aria-hidden={"true"}
    >
      {threshold === value && (
        <Typography className={classes.percent}>{value * 100}%</Typography>
      )}
      {threshold === 0.25 && value === 0 && (
        <Typography className={classes.clickToAllocate}>
          Click to allocate %
        </Typography>
      )}
    </div>
  );
};

export default ShoppingAllocationBar;
