import { RefObject, useEffect, useRef, useState } from "react";

const useElementBecameVisibleObserver = () => {
  const [becameVisible, setBecameVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersecting = entries[0].isIntersecting;
        if (intersecting) {
          setBecameVisible(intersecting);
          observer.unobserve(containerRefForCleanup.current!);
        }
      },
      { threshold: 0.6 }
    );

    let containerRefForCleanup: RefObject<HTMLDivElement>;
    if (containerRef?.current) {
      observer.observe(containerRef.current);
      containerRefForCleanup = containerRef;
    }

    return () => {
      if (containerRefForCleanup?.current)
        observer.unobserve(containerRefForCleanup.current);
    };
  }, []);

  return { becameVisible, containerRef };
};

export default useElementBecameVisibleObserver;
