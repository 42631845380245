import { createContext, FC, useEffect, useRef, useState } from "react";
import { BaseBasketArticle } from "../../models";
import { getArticleServings } from "../../services/articleservings";

export const ADD_REALTIME_ITEM_EVENT = "healthylife:add-realtime-item";
export const REMOVE_REALTIME_ITEM_EVENT = "healthylife:remove-realtime-item";

export const RealtimeListContext = createContext<{
  realtimeList: BaseBasketArticle[];
}>({
  realtimeList: [],
});

const RealtimeListProvider: FC = ({ children }) => {
  const [_realtimeList, _setRealtimeList] = useState<BaseBasketArticle[]>([]);
  const realtimeListRef = useRef(_realtimeList);

  const setRealtimeList = (realtimeList: BaseBasketArticle[]) => {
    realtimeListRef.current = realtimeList;
    _setRealtimeList(realtimeList);
  };

  useEffect(() => {
    document.addEventListener(ADD_REALTIME_ITEM_EVENT, async (event) => {
      const {
        detail: { articleId },
      } = event as CustomEvent;

      const realtimeList = realtimeListRef.current;

      const articleIdx = realtimeList.findIndex(
        (article) => article.articleId === articleId
      );

      if (articleIdx !== -1) {
        realtimeList[articleIdx].quantity++;
        setRealtimeList([...realtimeList]);
      } else {
        const servings = await getArticleServings(articleId);
        const article = { articleId, quantity: 1, ...servings };
        setRealtimeList([...realtimeList, article]);
      }
    });

    document.addEventListener(REMOVE_REALTIME_ITEM_EVENT, (event) => {
      const {
        detail: { articleId },
      } = event as CustomEvent;

      const realtimeList = realtimeListRef.current;

      const articleIdx = realtimeList.findIndex(
        (article) => article.articleId === articleId
      );

      if (articleIdx !== -1 && realtimeList[articleIdx].quantity) {
        realtimeList[articleIdx].quantity--;
        setRealtimeList([...realtimeList]);
      }
    });
  }, []);

  return (
    <RealtimeListContext.Provider value={{ realtimeList: _realtimeList }}>
      {children}
    </RealtimeListContext.Provider>
  );
};

export default RealtimeListProvider;
