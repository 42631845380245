import { makeStyles } from "@material-ui/core";
import { LinkWithImage } from "../../models";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { BLACK, COLOR_GREYSCALE, WHITE } from "../../theme";
import ExternalLink from "../ExternalLink";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y } from "swiper";
import ArticleSwiperButtons from "./ArticleSwiperButtons";
import { useState } from "react";
import { FEATURE_IDS } from "../../components-2/feature-carousel";
import useEvents from "../../hooks/useEvents";
import {
  FOOD_GROUP_PAGES_EVENT,
  HOMEPAGE_EVENT,
  MAIN_VS_SOMETIMES_EVENT,
  NUTRIENT_TRACKER_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../events";
import { ArticleSource, articlesBySource } from "./articles";

SwiperCore.use([A11y]);
interface Props {
  source: ArticleSource;
  articles?: LinkWithImage[]; // For future to be edited when links are dynamic
  showHeader?: boolean;
}

const HealthyInspiration = ({ source, articles, showHeader = true }: Props) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const { track } = useEvents();

  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      color: BLACK,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginBottom: "24px",
    },
    heading: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "22px",
      lineheight: "28px",
      letterspacing: "0.1px",
      margin: 0,
      [breakpoints.down(375)]: {
        paddingLeft: 8,
      },
      [breakpoints.up("md")]: {
        fontFamily: "Gilroy-Bold",
        fontWeight: "normal",
        fontSize: "28px",
        lineheight: "36px",
      },
    },
    description: {
      fontFamily: "Gilroy",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "24px",
      [breakpoints.down(375)]: {
        paddingLeft: 8,
      },
      [breakpoints.up("md")]: {
        marginBottom: "40px",
      },
    },
    articles: {
      position: "relative",
      marginBottom: "16px",
      [breakpoints.up("lg")]: {
        marginBottom: "24px",
      },
      [breakpoints.up("xl")]: {
        padding: "0 64px",
      },
      "& .swiper": {
        overflow: "visible",
        [breakpoints.up("md")]: {
          overflow: "hidden",
        },
      },
      "& .swiper-wrapper": {
        paddingBottom: "2px",
      },
    },
    article: {
      borderRadius: "12px",
      backgroundColor: WHITE,
      overflow: "hidden",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
    },
    articleUrl: {
      textDecoration: "none",
    },
    articleImage: {
      position: "relative",
      aspectRatio: "240 / 165",
      "& img": {
        display: "block",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
      },
    },
    badge: {
      position: "absolute",
      top: 8,
      left: 8,
      color: WHITE,
      textTransform: "uppercase",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      paddingLeft: "8px",
      paddingRight: "8px",
      border: "solid 0.5px rgba(255, 255, 255, 0.81)",
      borderRadius: "4px",
      backgroundColor: "rgba(128, 128, 128, 0.90)",
    },
    articleDetail: {
      padding: "16px 16px 20px",
      minBlockSize: "168px",
      boxSizing: "border-box",
    },
    articleTitle: {
      margin: 0,
      marginBottom: "8px",
      color: "#1D1F29",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.3px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 2,
      display: "-webkit-box",
      boxOrient: "vertical",
    },
    articleDescription: {
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      marginTop: "auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineClamp: 4,
      display: "-webkit-box",
      boxOrient: "vertical",
    },
    viewMoreLink: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      color: BLACK,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      textDecoration: "none",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      marginRight: "auto",
      [breakpoints.down(375)]: {
        paddingLeft: 8,
      },
      [breakpoints.up("lg")]: {
        marginLeft: "auto",
      },
    },
  }));

  const classes = useStyles();

  const trackClickEvent = (article: string) => {
    switch (source) {
      case "home":
        track(HOMEPAGE_EVENT.INSPIRATION_CLICK, { article });
        return;
      case "mainvssometimes":
        track(MAIN_VS_SOMETIMES_EVENT.INSPIRATION_CLICK, { article });
        return;
      case "sometimes":
        track(SOMETIMES_FOOD_PAGE_EVENT.INSPIRATION_CLICK, { article });
        return;
      case "dairy":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_CLICK, {
          article,
          foodGroup: "dairy",
        });
        return;
      case "protein":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_CLICK, {
          article,
          foodGroup: "protein",
        });
        return;
      case "vegetables":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_CLICK, {
          article,
          foodGroup: "vegetables",
        });
        return;
      case "fruit":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_CLICK, {
          article,
          foodGroup: "fruit",
        });
        return;
      case "grains":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_CLICK, {
          article,
          foodGroup: "grains",
        });
        return;
      case "nutrienttracker":
        track(NUTRIENT_TRACKER_EVENT.INSPIRATION_CLICK, { article });
        return;
      default:
        track(HOMEPAGE_EVENT.INSPIRATION_CLICK, { article });
        return;
    }
  };

  const trackScrollEvent = () => {
    switch (source) {
      case "home":
        track(HOMEPAGE_EVENT.INSPIRATION_SCROLL);
        return;
      case "mainvssometimes":
        track(MAIN_VS_SOMETIMES_EVENT.INSPIRATION_SCROLL);
        return;
      case "sometimes":
        track(SOMETIMES_FOOD_PAGE_EVENT.INSPIRATION_SCROLL);
        return;
      case "dairy":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_SCROLL, {
          foodGroup: "dairy",
        });
        return;
      case "protein":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_SCROLL, {
          foodGroup: "protein",
        });
        return;
      case "vegetables":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_SCROLL, {
          foodGroup: "vegetables",
        });
        return;
      case "fruit":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_SCROLL, {
          foodGroup: "fruit",
        });
        return;
      case "grains":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_SCROLL, {
          foodGroup: "grains",
        });
        return;
      case "nutrienttracker":
        track(NUTRIENT_TRACKER_EVENT.INSPIRATION_SCROLL);
        return;
      default:
        track(HOMEPAGE_EVENT.INSPIRATION_SCROLL);
        return;
    }
  };

  const trackViewMoreEvent = () => {
    switch (source) {
      case "home":
        track(HOMEPAGE_EVENT.INSPIRATION_VIEW_MORE);
        return;
      case "mainvssometimes":
        track(MAIN_VS_SOMETIMES_EVENT.INSPIRATION_VIEW_MORE);
        return;
      case "sometimes":
        track(SOMETIMES_FOOD_PAGE_EVENT.INSPIRATION_VIEW_MORE);
        return;
      case "dairy":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_VIEW_MORE, {
          foodGroup: "dairy",
        });
        return;
      case "protein":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_VIEW_MORE, {
          foodGroup: "protein",
        });
        return;
      case "vegetables":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_VIEW_MORE, {
          foodGroup: "vegetables",
        });
        return;
      case "fruit":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_VIEW_MORE, {
          foodGroup: "fruit",
        });
        return;
      case "grains":
        track(FOOD_GROUP_PAGES_EVENT.INSPIRATION_VIEW_MORE, {
          foodGroup: "grains",
        });
        return;
      case "nutrienttracker":
        track(NUTRIENT_TRACKER_EVENT.INSPIRATION_VIEW_MORE);
        return;
      default:
        track(HOMEPAGE_EVENT.INSPIRATION_VIEW_MORE);
        return;
    }
  };

  return (
    <div className={classes.root} id={FEATURE_IDS.HEALTH_INSPIRATION}>
      {showHeader && (
        <>
          <h2 className={classes.heading}>Healthy inspiration</h2>
          <p className={classes.description}>
            Articles and programs from our experts
          </p>
        </>
      )}
      <div className={classes.articles}>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          spaceBetween={16}
          slidesPerView={1.4}
          allowTouchMove={true}
          breakpoints={{
            576: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
          onSlideChange={() => trackScrollEvent()}
        >
          {articlesBySource[source].map((article, index) => (
            <SwiperSlide key={index}>
              <a
                href={`${article.url}?utm_source=app-food-tracker&utm_medium=referral&utm_campaign=articles`}
                className={classes.articleUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackClickEvent(article.title)}
              >
                <div className={classes.article}>
                  <div className={classes.articleImage}>
                    {article.tagName && (
                      <span className={classes.badge}>{article.tagName}</span>
                    )}
                    <img src={article.image.url} alt={`${article.title}`} />
                  </div>
                  <div className={classes.articleDetail}>
                    <h2 className={classes.articleTitle}>{article.title}</h2>
                    <p className={classes.articleDescription}>
                      {article.description}
                    </p>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        {articlesBySource[source].length > 0 && (
          <ArticleSwiperButtons swiper={swiper} />
        )}
      </div>
      <ExternalLink
        className={classes.viewMoreLink}
        href="https://www.healthylife.com.au/discover/learn?utm_source=app-food-tracker&utm_medium=referral&utm_campaign=articles"
        onClick={() => trackViewMoreEvent()}
      >
        View more on Healthylife
        <ChevronRight width={16} height={16} />
      </ExternalLink>
    </div>
  );
};

export default HealthyInspiration;
