import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";

export const StyledTableCell = withStyles({
  // @ts-ignore
  root: (props: {
    rightBorder?: boolean;
    bold?: boolean;
    leftAlignHeader?: boolean;
    whiteBackground?: boolean;
    greenText?: boolean;
  }) => ({
    padding: "8px 12px !important",
    fontFamily: props.bold ? "Gilroy-Semibold !important" : "Gilroy !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
    letterSpacing: "0.3px !important",
    textAlign: props.leftAlignHeader ? "left !important" : "center !important",
    color: props.greenText
      ? `${DARK_GREEN} !important`
      : `${COLOR_GREYSCALE[900]} !important`,
    borderBottom: `1px solid ${COLOR_GREYSCALE[700]} !important`,
    borderRight: props.rightBorder
      ? `1px solid ${COLOR_GREYSCALE[700]} !important`
      : "inherit",
    backgroundColor: props.whiteBackground ? "white !important" : "inherit",
  }),
})(TableCell);
