import { ChevronRight } from "@material-ui/icons";
import SidebarSettings from "./SidebarSettings";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { useContext, useEffect } from "react";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { ReactComponent as BasketIcon } from "../../icons/basket.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings-icon.svg";
import { ReactComponent as HelpIcon } from "../../icons/help-circle.svg";
import { ReactComponent as MailCog } from "../../icons/mail-cog.svg";
import { ReactComponent as Rewards } from "../../icons/rewards.svg";
import { ReactComponent as MessageHeart } from "../../icons/message-heart.svg";
import { ReactComponent as Optout } from "../../icons/opt-out.svg";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useEvents from "../../hooks/useEvents";
import { MENU_SETTINGS_EVENT } from "../../events";

const SidebarMain = () => {
  const {
    isOpenSidebar,
    setIsOpenSidebar,
    setIsOpenTools,
    setIsOpenHelp,
    setIsOpenFeedback,
    setIsOpenRewards,
    setIsOpenPersonalisation,
    setIsOpenCommunication,
    setIsOpenOptout,
    isAdditionalPageOpen,
  } = useContext(SettingsContext);

  const classes = makeStyles(() => ({
    settingButton: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: WHITE,
      padding: "8px 12px",
      border: "none",
      borderRadius: "8px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[800],
      height: "52px",
    },
    icon: {
      marginRight: "8px",
      display: "flex",
      alignItems: "center",
      height: "24px",
    },
    darkIcon: {
      "& path": {
        stroke: COLOR_GREYSCALE[800],
      },
    },
  }))();
  const trackerSettings = [
    {
      icon: <BasketIcon width={20} />,
      label: "Explore Food Tracker tools",
      onClick: () => setIsOpenTools(true),
    },
    {
      icon: <SettingsIcon width={20} className={classes.darkIcon} />,
      label: "Personalisation Settings",
      onClick: () => setIsOpenPersonalisation("main"),
    },
    {
      icon: <HelpIcon width={20} className={classes.darkIcon} />,
      label: "Help centre",
      onClick: () => setIsOpenHelp(true),
    },
    {
      icon: <MailCog width={20} className={classes.darkIcon} />,
      label: "Communication settings",
      onClick: () => setIsOpenCommunication(true),
    },
    {
      icon: <Rewards width={20} />,
      label: "Everyday Rewards link",
      onClick: () => setIsOpenRewards(true),
    },
    {
      icon: <MessageHeart width={20} />,
      label: "Leave us feedback",
      onClick: () => setIsOpenFeedback(true),
    },
    {
      icon: <Optout width={20} />,
      label: "Opt out of Food Tracker",
      onClick: () => setIsOpenOptout(true),
    },
  ];
  const { track } = useEvents();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isMobile) return;

    if (isOpenSidebar) {
      history.push("/settings");
    } else if (!isAdditionalPageOpen) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar]);

  useEffect(() => {
    if (history.location.pathname === "/settings" && !isOpenSidebar) {
      setIsOpenSidebar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (history.location.pathname !== "/settings" && isMobile) ||
    isAdditionalPageOpen
  ) {
    return null;
  }
  const handleSettingClick = ({
    onClick,
    label,
  }: {
    icon: JSX.Element;
    label: string;
    onClick: () => void;
  }) => {
    if (label === "Explore Food Tracker tools") {
      track(MENU_SETTINGS_EVENT.EXPLORE_FOODTRACKER_TOOLS_CLICK);
    } else if (label === "Personalisation Settings") {
      track(MENU_SETTINGS_EVENT.PERSONALIZATION_CLICK);
    } else if (label === "Help centre") {
      track(MENU_SETTINGS_EVENT.HELP_CLICK);
    } else if (label === "Communication settings") {
      track(MENU_SETTINGS_EVENT.COMMUNICATIONS_CLICK);
    } else if (label === "Everyday Rewards link") {
      track(MENU_SETTINGS_EVENT.REWARDS_CLICK);
    } else if (label === "Leave us feedback") {
      track(MENU_SETTINGS_EVENT.FEEDBACK_CLICK);
    } else if (label === "Opt out of Food Tracker") {
      track(MENU_SETTINGS_EVENT.OPT_OUT_CLICK);
    }
    onClick();
  };

  return (
    <SidebarSettings
      sidebarOpen={isOpenSidebar}
      setSidebarOpen={setIsOpenSidebar}
    >
      {trackerSettings.map((setting, index) => {
        return (
          <button
            key={index}
            className={classes.settingButton}
            onClick={() => {
              handleSettingClick(setting);
            }}
          >
            <span className={classes.icon}>{setting.icon}</span>
            {setting.label}{" "}
            <ChevronRight
              style={{ marginLeft: "auto", width: 16, height: 16 }}
            />
          </button>
        );
      })}
    </SidebarSettings>
  );
};

export default SidebarMain;
