import {
  collection,
  DocumentData,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import { DateTime, Duration } from "luxon";
import {
  AggregateDailyServings,
  BaseBasketArticle,
  BasketExclusion,
  FoodGroup,
  Servings,
  ShoppingAllocation,
} from "../models";
import {
  generateDateRangesBackFromYesterday,
  getFromAndTo,
} from "../utils/dates";
import { Collections, db, Fields, Shards } from "./firebase";

export type ServingsChangeListener = (
  servings: AggregateDailyServings[]
) => void;

export type ServingsErrorListener = (error: Error) => void;

const getServingsQuery = (
  timeFrame: Duration,
  until: DateTime,
  memberId: string
) => {
  const { to, from } = getFromAndTo(timeFrame, until);
  return query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.AggregateDailyServingsSharded
    ),
    where(
      Fields.AggregateDailyServingsSharded.Shard,
      "in",
      Shards.AggregateDailyServingsSharded
    ),
    where(
      Fields.AggregateDailyServingsSharded.PurchaseDate,
      ">=",
      from.toMillis()
    ),
    where(
      Fields.AggregateDailyServingsSharded.PurchaseDate,
      "<=",
      to.toMillis()
    )
  );
};

const toRealTimeAggregateDailyServings = (
  snapshot: QuerySnapshot<DocumentData>
) => {
  const aggregates: Map<string, AggregateDailyServings> = new Map();

  snapshot.docs.forEach((doc) => {
    const transaction = doc.data() as AggregateDailyServings;
    const dateKey = DateTime.fromMillis(transaction.purchaseDate).toISODate(); // Converts timestamp to 'YYYY-MM-DD'
    if (!aggregates.has(dateKey)) {
      aggregates.set(dateKey, { ...transaction });
    } else {
      const existingT = aggregates.get(dateKey)!;
      existingT.servings.grains += transaction.servings.grains;
      existingT.servings.vegetables += transaction.servings.vegetables;
      existingT.servings.protein += transaction.servings.protein;
      existingT.servings.dairy += transaction.servings.dairy;
      existingT.servings.fruit += transaction.servings.fruit;
      existingT.servings.discretionary += transaction.servings.discretionary;
    }
  });

  return Array.from(aggregates.values());
};

const toAggregateDailyServings = (snapshot: QuerySnapshot<DocumentData>) =>
  snapshot.docs.map((doc) => doc.data() as AggregateDailyServings);

export const getServings = async (
  memberId: string,
  timeFrame: Duration,
  until: DateTime
): Promise<AggregateDailyServings[]> => {
  const q = getServingsQuery(timeFrame, until, memberId);
  const snapshot = await getDocs(q);
  return toAggregateDailyServings(snapshot);
};

export const subscribeToRealTimeServings = (
  memberId: string,
  timeFrame: Duration,
  until: DateTime,
  onChange: ServingsChangeListener,
  onError: ServingsErrorListener
): (() => void) => {
  const { from } = getFromAndTo(timeFrame, until);
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.RealTimeDailyServings
    ),
    where(
      Fields.RealTimeDailyServings.Shard,
      "in",
      Shards.RealTimeDailyServings
    ),
    where(Fields.RealTimeDailyServings.PurchaseDate, ">=", from.toMillis())
  );
  return onSnapshot(
    q,
    (snapshot) => {
      onChange(toRealTimeAggregateDailyServings(snapshot));
    },
    (error) => {
      console.error("Error reading servings snapshot:", error.message);
      onError(error);
    }
  );
};

export const subscribeToServings = (
  memberId: string,
  timeFrame: Duration,
  until: DateTime,
  onChange: ServingsChangeListener,
  onError: ServingsErrorListener
): (() => void) => {
  const q = getServingsQuery(timeFrame, until, memberId);
  return onSnapshot(
    q,
    (snapshot) => {
      onChange(toAggregateDailyServings(snapshot));
    },
    (error) => {
      console.error("Error reading servings snapshot:", error.message);
      onError(error);
    }
  );
};

const applyExclusions = (
  servings: Servings,
  exclusions: BasketExclusion[]
): Servings =>
  exclusions.reduce(
    (adjusted, exclusion) =>
      mapValues(adjusted, (val, key) => val - exclusion[key as keyof Servings]),
    servings
  );

const applyShoppingAllocation = (
  servings: Servings,
  shoppingAllocation: ShoppingAllocation
): Servings =>
  mapValues(servings, (serves, foodGroup) =>
    foodGroup === "discretionary"
      ? serves
      : serves / (shoppingAllocation[foodGroup as FoodGroup] || 1)
  );

export const accumulateDailyServings = (
  dailyServings: AggregateDailyServings[],
  exclusions: BasketExclusion[],
  shoppingAllocation: ShoppingAllocation
): Servings => {
  const days = dailyServings.map(getPurchaseDateString);
  const filteredExclusions = exclusions.filter((exclusion) =>
    days.includes(exclusion.purchaseDate)
  );
  const servings = accumulateServings(dailyServings.map((ds) => ds.servings));
  const servingsMinusExclusions = applyExclusions(servings, filteredExclusions);
  return applyShoppingAllocation(servingsMinusExclusions, shoppingAllocation);
};

export const EMPTY_SERVINGS: Servings = {
  grains: 0,
  vegetables: 0,
  protein: 0,
  dairy: 0,
  fruit: 0,
  discretionary: 0,
};

export const accumulateServings = (
  listOfServings: Servings[],
  timeFrame?: Duration
) => {
  const multiplier = timeFrame?.as("days") ?? 1;

  return listOfServings.reduce(
    (total, servings) =>
      mapValues(
        total,
        (value, key) =>
          value + (servings[key as keyof Servings] || 0) * multiplier
      ),
    EMPTY_SERVINGS
  );
};

export const isEmptyServings = (servings: Servings) =>
  !Object.values(servings).some((serving) => serving > 0);

export const adjustServings = (
  dailyServings: AggregateDailyServings[],
  exclusions: BasketExclusion[],
  shoppingAllocation: ShoppingAllocation
): AggregateDailyServings[] => {
  const exclusionsByDate = groupBy(exclusions, "purchaseDate");
  return dailyServings.map((dailyServing) => {
    const isoDate = getPurchaseDateString(dailyServing);
    const exclusionsMatchingShopDate = exclusionsByDate[isoDate] || [];

    const servingsMinusExclusions = applyExclusions(
      dailyServing.servings,
      exclusionsMatchingShopDate
    );
    const servings = applyShoppingAllocation(
      servingsMinusExclusions,
      shoppingAllocation
    );

    return {
      purchaseDate: dailyServing.purchaseDate,
      servings,
    };
  });
};

// Speculative feature
export const addRealtimeServings = (
  purchased: AggregateDailyServings[],
  realtime: BaseBasketArticle[]
): AggregateDailyServings[] => {
  const servings = realtime.map((article) => ({
    grains: article.grains * article.quantity,
    vegetables: article.vegetables * article.quantity,
    protein: article.protein * article.quantity,
    dairy: article.dairy * article.quantity,
    fruit: article.fruit * article.quantity,
    discretionary: article.discretionary * article.quantity,
  }));

  const realtimeServings = {
    purchaseDate: DateTime.now().toMillis(),
    servings: accumulateServings(servings),
  };

  return [...purchased, realtimeServings];
};

export const groupServingsByTimePeriods = (
  servingsList: AggregateDailyServings[],
  timePeriod: Duration,
  howMany: number
): AggregateDailyServings[][] => {
  const dateRanges = generateDateRangesBackFromYesterday(howMany, timePeriod);
  return dateRanges.map((dateRange) =>
    servingsList.filter(
      (servings) =>
        servings.purchaseDate >= dateRange.from.toMillis() &&
        servings.purchaseDate <= dateRange.to.toMillis()
    )
  );
};

export interface DiscretionaryPercentData {
  totalServings: number;
  emptyServings: boolean;
  discretionaryPercent: number;
  month?: string;
}

export const getDiscretionaryPercentData = (
  servings: Servings
): DiscretionaryPercentData => {
  const totalServings =
    servings.grains +
    servings.vegetables +
    servings.protein +
    servings.dairy +
    servings.fruit +
    servings.discretionary;

  const emptyServings = totalServings < 0.01; // because of js floating point calcs, when excluding everything we end up with a number very close to but not quite exactly zero

  const discretionaryPercent = emptyServings
    ? 0
    : Math.round((servings.discretionary / totalServings) * 100);

  return { totalServings, emptyServings, discretionaryPercent };
};

const getPurchaseDateString = (dailyServing: AggregateDailyServings) =>
  DateTime.fromMillis(dailyServing.purchaseDate).toISODate();
