import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import { useHistory, useLocation } from "react-router-dom";
import { BasketArticle, HealthierOptionsFoodGroup } from "../../models";
import SelectDropdown from "../../components/SelectDropdown";
import useEvents from "../../hooks/useEvents";
import { HEALTHIER_OPTIONS_EVENTS } from "../../constants";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import SimilarProductsText from "./SimilarProductsText";
import { HealthierOptionsExplainer } from "./HealthierOptionsExplainer";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import TimeFrameTabs from "../../components/TimeFrameTabs";
import { ChevronRight } from "@material-ui/icons";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import { HEALTHIER_OPTIONS_PLP_EVENT } from "../../events";
import TrolleyLoader from "../../components/TrolleyLoader";

const useStyles = makeStyles(({ breakpoints }) => ({
  productListerContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(299px, 1fr))",
    gap: "24px",
    marginTop: "16px",
    [breakpoints.down("sm")]: {
      gap: "4px",
      gridTemplateColumns: "1fr",
    },
  },
  imageContainer: {
    border: "1px solid #D3D6D9",
    borderRadius: "8px",
    background: "white",
    //mobile
    [breakpoints.down("sm")]: {
      padding: "3px",
    },
    //desktop
    [breakpoints.up("md")]: {
      padding: "20px",
    },
  },
  image: {
    display: "block",
    width: "auto",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    //mobile
    [breakpoints.down("sm")]: {
      maxWidth: "42px",
      maxHeight: "42px",
    },
    //desktop
    [breakpoints.up("md")]: {
      maxWidth: "80px",
      maxHeight: "80px",
    },
  },
  purchasedItemsText: {
    padding: "16px 0px",
    margin: 0,
    fontFamily: '"Gilroy-SemiBold"',
    fontWeight: "normal",
    //mobile
    [breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: "32px",
      color: DARK_GREEN,
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "inline-block",
    margin: "0 4px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    //mobile
    [breakpoints.down("sm")]: {
      lineHeight: "20px",
      fontSize: "16px",
      letterSpacing: "0.3px",
    },
    //desktop
    [breakpoints.up("md")]: {
      lineHeight: "36px",
      fontSize: "28px",
    },
  },
  similarProductsText: {
    padding: "10px 0px",
  },
  productContainer: {
    [breakpoints.down("sm")]: {
      display: "flex",
    },
    [breakpoints.up("md")]: {
      minWidth: "299px",
      background: "#F4F5F6",
      borderRadius: "8px",
    },
  },
  itemNameTextContainer: {
    width: "100%",
    [breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      height: "122px",
      justifyContent: "space-between",
    },
  },
  itemNameText: {
    margin: 0,
    paddingLeft: "8px",
    fontFamily: '"Gilroy-SemiBold"',
    fontWeight: "normal",
    overflow: "hidden",
    //mobile
    [breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
    },
  },
  textAndImageContainer: {
    //mobile
    display: "flex",
    alignItems: "center",
    [breakpoints.down("sm")]: {
      borderRadius: "8px 0px 0px 8px",
      padding: "8px",
      marginRight: "3px",
      width: "80%",
      background: COLOR_GREYSCALE[50],
    },
    //desktop
    [breakpoints.up("md")]: {
      padding: "16px",
    },
  },
  iconContainer: {
    [breakpoints.down("sm")]: {
      width: "20%",
      background: COLOR_GREYSCALE[50],
      borderRadius: "0px 8px 8px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [breakpoints.up("md")]: {
      background: DARK_GREEN,
      width: "fit-content",
      display: "flex",
      borderRadius: "50%",
      padding: "4px",
      position: "absolute",
      marginLeft: "70px",
      marginTop: "-14px",
    },
  },
  icon: {
    [breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
      "& > * > *": {
        fill: "black",
      },
    },
    [breakpoints.up("md")]: {
      width: "16px",
      height: "16px",
      "& > * > *": {
        fill: "#FFFFFF",
      },
    },
  },
  purchasedItemsAndFilterContainer: {
    //desktop
    [breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  filterByDropdown: {
    //desktop
    [breakpoints.up("md")]: {
      width: "30%",
    },
  },
  desktopContainer: {
    //desktop
    [breakpoints.up("md")]: {
      padding: "24px 40px",
      border: `1px solid ${COLOR_GREYSCALE[200]}`,
      borderRadius: "12px",
      marginTop: "32px",
    },
  },
  container: {
    position: "relative",
    background: "white",
    borderRadius: "12px",
    [breakpoints.down("sm")]: {
      padding: "16px",
    },
    [breakpoints.up("md")]: {
      padding: "40px",
    },
  },
  desktopOptionButton: {
    alignSelf: "flex-end",
    paddingBottom: "0px",
    paddingTop: "4px",
    color: DARK_GREEN,
    textDecoration: "none",
  },
  timeFrameTabsContainer: {
    margin: "32px 0 24px 0",
    [breakpoints.up("md")]: {
      margin: "40px 0",
    },
  },
}));

interface ProductListerProps {
  purchasedItemsWithSwaps: BasketArticle[];
  isLoading?: boolean;
}

const ProductLister: FC<ProductListerProps> = ({
  purchasedItemsWithSwaps,
  isLoading,
}) => {
  const { track } = useEvents();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const goToProductPDP = (articleId: string) => {
    track(HEALTHIER_OPTIONS_EVENTS.PLP_PRODUCT_CLICK, { type: articleId });
    track(HEALTHIER_OPTIONS_PLP_EVENT.HO_PRODUCT_CLICK, { type: articleId });
    history.push(
      `/healthier-options/pdp?back=plp&articleId=${articleId}&source=${
        source || "ho-plp"
      }`
    );
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isLoading)
    return (
      <div style={{ marginTop: 48 }}>
        <TrolleyLoader size={128} />
      </div>
    );
  return (
    <div className={classes.productListerContainer}>
      {purchasedItemsWithSwaps.map((item, index) => (
        <div
          key={index}
          className={classes.productContainer}
          tabIndex={isMobile ? 0 : -1}
          onKeyPress={(e) =>
            isMobile && ifEnterOrSpace(e, goToProductPDP, item.articleId)
          }
          onClick={() => isMobile && goToProductPDP(item.articleId)}
        >
          <div className={classes.textAndImageContainer}>
            <div className={classes.imageContainer} aria-hidden>
              {!isMobile && (
                <div className={classes.iconContainer}>
                  <HealthierOptionsIcon className={classes.icon} />
                </div>
              )}
              <img
                src={item.imageURL?.replace("/large/", "/medium/")}
                onError={(e) => {
                  e.currentTarget.src = item.imageURL ?? "";
                }}
                alt={item.description}
                className={classes.image}
              />
            </div>
            <div className={classes.itemNameTextContainer}>
              <Typography
                variant="h3"
                component="h3"
                className={classes.itemNameText}
              >
                {item.description}
              </Typography>
              {!isMobile && (
                <Button
                  variant={"text"}
                  endIcon={
                    <ChevronRight
                      style={{
                        color: "black",
                      }}
                    />
                  }
                  onClick={() => goToProductPDP(item.articleId)}
                  className={classes.desktopOptionButton}
                >
                  See options
                </Button>
              )}
            </div>
          </div>
          {isMobile && (
            <div className={classes.iconContainer}>
              <HealthierOptionsIcon className={classes.icon} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

interface FilterByDropdownProps {
  value: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const FilterByDropdown: FC<FilterByDropdownProps> = ({
  value,
  handleChange,
}) => {
  const { foodGroupCount } = useHealthierOptions("all");
  const classes = useStyles();
  return (
    <div className={classes.filterByDropdown}>
      <SelectDropdown
        value={value}
        onChange={handleChange}
        id="foodGroupSelect"
        labelId="foodGroupSelectLabel"
      >
        <MenuItem value={"all"}>All food groups</MenuItem>
        <MenuItem
          value={"discretionary"}
          disabled={foodGroupCount.discretionary === 0}
        >
          Sometimes foods & drinks
        </MenuItem>
        <MenuItem value={"protein"} disabled={foodGroupCount.protein === 0}>
          Meat & alternatives
        </MenuItem>
        <MenuItem value={"dairy"} disabled={foodGroupCount.dairy === 0}>
          Dairy & alternatives
        </MenuItem>
        <MenuItem value={"grains"} disabled={foodGroupCount.grains === 0}>
          Grains
        </MenuItem>
        <MenuItem
          value={"vegetables"}
          disabled={foodGroupCount.vegetables === 0}
        >
          Vegetables & legumes
        </MenuItem>
        <MenuItem value={"fruit"} disabled={foodGroupCount.fruit === 0}>
          Fruit
        </MenuItem>
      </SelectDropdown>
    </div>
  );
};

const HealthierOptionsProductListerPageComp = () => {
  const { track } = useEvents();
  const [foodGroup, setFoodGroup] =
    React.useState<HealthierOptionsFoodGroup>("all");
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const foodGroup = event.target.value as HealthierOptionsFoodGroup;
    track(HEALTHIER_OPTIONS_EVENTS.PLP_FILTER, { type: foodGroup });
    setFoodGroup(foodGroup);
  };

  const [viewTracked, setViewTracked] = useState(false);
  useEffect(() => {
    if (viewTracked) return;
    track(HEALTHIER_OPTIONS_EVENTS.PLP_PAGE_VIEW);
    setViewTracked(true);
  }, [track, viewTracked]);

  const classes = useStyles();
  const { purchasedItemsWithSwaps, isLoading } = useHealthierOptions(foodGroup);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div className={classes.timeFrameTabsContainer}>
        <TimeFrameTabs textColorMobile={COLOR_GREYSCALE[600]} />
      </div>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <HealthierOptionsIcon />
          <Typography variant="h1" className={classes.title}>
            Healthier Options
          </Typography>
          <HealthierOptionsExplainer source="healthier-options-plp" />
        </div>
        <SimilarProductsText className={classes.similarProductsText} />
        {isMobile && <Divider />}
        <div className={classes.desktopContainer}>
          <div className={classes.purchasedItemsAndFilterContainer}>
            <Typography variant="h2" className={classes.purchasedItemsText}>
              Your purchased items
            </Typography>
            <FilterByDropdown value={foodGroup} handleChange={handleChange} />
          </div>
          <ProductLister
            purchasedItemsWithSwaps={purchasedItemsWithSwaps}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default HealthierOptionsProductListerPageComp;
