import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import useMember from "../../hooks/useMember";
import { fetchTermsAndConditions } from "../../services/contentful/termsandconditions";
import TermsAndConditions from "../TermsAndConditions";

type Legals = { id: string; version: number; updatedAt: DateTime } | null;

const useStyles = makeStyles(({ spacing }) => ({
  pre: {
    margin: spacing(1, 0),
    fontWeight: "bold",
  },
  title: {
    marginTop: spacing(),
  },
  date: {
    marginBottom: spacing(),
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  terms: {
    borderTop: "1px #BEBEBE solid",
    flex: 1,
    overflowY: "scroll",
    minHeight: 250,
    paddingBottom: spacing(2),
  },
  actions: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    "&:before": {
      content: '""',
      position: "absolute",
      top: -200,
      left: 0,
      right: 0,
      height: 200,
      background: "linear-gradient(transparent 150px, white)",
      pointerEvents: "none",
    },
  },
  button: {
    marginRight: spacing(2),
    textTransform: "uppercase",
    padding: spacing(1, 3),
    minWidth: 120,
    borderRadius: "12px",
    backgroundColor: "#B9E04C",
    boxShadow: "none",
    color: "#0A2822",
    "&:hover": {
      backgroundColor: "#A0C734",
      boxShadow: "none",
    },
  },
}));

const UpdatedTermsDialog = () => {
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));

  const { member, storeMemberConsent } = useMember();
  const open = member?.termsAndConditionsUpdated ?? false;

  const [legals, setLegals] = useState<Legals>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      const terms = await fetchTermsAndConditions();
      setLegals({
        id: terms.sys.id,
        version: terms.sys.publishedVersion,
        updatedAt: DateTime.fromISO(terms.sys.publishedAt),
      });

      setLoading(false);
    };

    init();
  }, []);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const divEl = scrollContainerRef.current;
  const [hasReadTerms, setHasReadTerms] = useState(false);

  useEffect(() => {
    const handleScroll = (_event: any) => {
      if (
        divEl &&
        divEl.scrollHeight - divEl.scrollTop - divEl.clientHeight < 1
      ) {
        setHasReadTerms(true);
      }
    };

    divEl?.addEventListener("scroll", handleScroll);

    return () => {
      divEl?.removeEventListener("scroll", handleScroll);
    };
  }, [divEl]);

  const handleAccept = async () => {
    setLoading(true);

    const { id, version } = legals!;
    await storeMemberConsent(id, version, true);

    setLoading(false);
  };

  const handleDecline = async () => {
    setLoading(true);

    const { id, version } = legals!;
    await storeMemberConsent(id, version, false);

    setLoading(false);
  };

  return (
    <Dialog
      aria-labelledby="updated-terms-dialog-title"
      open={open}
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogTitle id="updated-terms-dialog-title">
        <Typography variant="body1" className={classes.pre}>
          We have updated the
        </Typography>
        <Typography variant="h2" className={classes.title}>
          Terms &amp; Conditions
        </Typography>
        <Typography variant="body1" className={classes.date}>
          Last updated {legals?.updatedAt?.toLocaleString(DateTime.DATE_FULL)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div ref={scrollContainerRef} className={classes.terms}>
          <TermsAndConditions />
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleDecline}
            disabled={loading}
          >
            Decline
          </Button>
          <Button
            className={classes.button}
            color="primary"
            onClick={handleAccept}
            disabled={loading || !hasReadTerms}
          >
            Accept
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatedTermsDialog;
