import { useContext, useEffect, useMemo, useState } from "react";
import { endOfYesterday, getSixMonthsAgoFromDate } from "../utils/dates";
import {
  accumulateDailyServings,
  DiscretionaryPercentData,
  getDiscretionaryPercentData,
  getServings,
  groupServingsByTimePeriods,
  subscribeToRealTimeServings,
} from "../services/servings";
import { MemberContext } from "../components/providers/MemberProvider";
import { AggregateDailyServings, Servings } from "../models";
import { groupExclusionsByTimePeriods } from "../services/exclusions";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import { TimeFrameDuration } from "../constants";
import { SelectedTimeFrameBasketExclusionsContext } from "../components/providers/SelectedTimeFrameBasketExclusionsProvider";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";

const until = endOfYesterday();

const NUMBER_OF_HISTORICAL_TIME_PERIODS = 6;

const useDiscretionaryPercentHistory = () => {
  const { memberId, shoppingAllocation } = useContext(MemberContext)!;
  const { collected, featureFlags } = useContext(FeatureFlagContext);
  const featureFlagsLoading = !collected;
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const timePeriodsToShow = TimeFrameDuration[timeFrame];
  const timePeriodToLoad = useMemo(() => {
    return timePeriodsToShow.mapUnits(
      (x) => x * NUMBER_OF_HISTORICAL_TIME_PERIODS
    );
  }, [timePeriodsToShow]);

  const { exclusions } = useContext(SelectedTimeFrameBasketExclusionsContext);

  const [loading, setLoading] = useState(true);
  const [servingsPurchased, setServingsPurchased] = useState<
    AggregateDailyServings[]
  >([]);
  const [discretionaryPercentDataList, setDiscretionaryPercentDataList] =
    useState<DiscretionaryPercentData[]>([]);
  const [accumulatedServings, setaccumulatedServings] = useState<Servings[]>(
    []
  );

  useEffect(() => {
    if (featureFlagsLoading) return;

    if (
      featureFlags?.realTimeData?.enable?.everybody === true ||
      featureFlags?.realTimeData?.enable?.specificMembers?.includes(memberId)
    ) {
      setLoading(true);
      const unsubscribe = subscribeToRealTimeServings(
        memberId,
        timePeriodToLoad,
        until,
        (servings) => {
          setServingsPurchased(servings);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
      return () => {
        unsubscribe();
      };
    } else {
      const init = async () => {
        setLoading(true);
        const servings = await getServings(memberId, timePeriodToLoad, until);
        setServingsPurchased(servings);
        setLoading(false);
      };

      init();
    }
  }, [
    featureFlags?.realTimeData?.enable?.everybody,
    featureFlags?.realTimeData?.enable?.specificMembers,
    featureFlagsLoading,
    memberId,
    timePeriodToLoad,
  ]);

  useEffect(() => {
    const groupedServingsList = groupServingsByTimePeriods(
      servingsPurchased,
      timePeriodsToShow,
      NUMBER_OF_HISTORICAL_TIME_PERIODS
    );
    const groupedExclusionsList = groupExclusionsByTimePeriods(
      exclusions,
      timePeriodsToShow,
      NUMBER_OF_HISTORICAL_TIME_PERIODS
    );

    const percents = groupedServingsList.map((groupedServings, idx) => {
      const groupedExclusions = groupedExclusionsList[idx];
      const servings = accumulateDailyServings(
        groupedServings,
        groupedExclusions,
        shoppingAllocation
      );

      return getDiscretionaryPercentData(servings);
    });

    const accumulatedServings = groupedServingsList.map(
      (groupedServings, idx) => {
        const groupedExclusions = groupedExclusionsList[idx];
        const servings = accumulateDailyServings(
          groupedServings,
          groupedExclusions,
          shoppingAllocation
        );

        return servings;
      }
    );

    if (timeFrame === "Month") {
      const percentsWithMonths = percents.slice().reduce((acc, item, i) => {
        item.month = getSixMonthsAgoFromDate().reverse()[i];
        acc.push(item);
        return acc;
      }, [] as DiscretionaryPercentData[]);
      setDiscretionaryPercentDataList(percentsWithMonths);
    }

    setaccumulatedServings(accumulatedServings);
    setDiscretionaryPercentDataList(percents);
  }, [
    servingsPurchased,
    shoppingAllocation,
    exclusions,
    timePeriodsToShow,
    timeFrame,
  ]);

  return { discretionaryPercentDataList, accumulatedServings, loading };
};

export default useDiscretionaryPercentHistory;
