import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
  InfoModalTitle,
} from "../../../components-2/InfoModal";
import { FOOD_GROUP_PAGES_EVENT } from "../../../events";
import { FoodGroup } from "../../../models";

const ExclusionExplainer = (foodGroup: {
  foodGroup: FoodGroup | "discretionary";
}) => {
  return (
    <InfoModalContainer
      eventName={FOOD_GROUP_PAGES_EVENT.ITEMS_EXCLUDE_HELP_OPEN}
      eventCloseName={FOOD_GROUP_PAGES_EVENT.ITEMS_EXCLUDE_HELP_CLOSE}
      eventParams={{ foodgroup: foodGroup }}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalTitle>Exclude from shop</InfoModalTitle>
      <InfoModalContent>
        Use the exclusion feature to exclude any ‘out of the ordinary’ purchases
        which may impact your normal shopping behaviour. This could include
        things such as: Bulk buying, buying more food than usual for a
        particular event, or buying items for a friend or colleague which you
        don't plan on consuming in your household.
      </InfoModalContent>
      <InfoModalContent>
        Please note: if you have an item that falls across groups it will
        exclude it from all food groups.
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default ExclusionExplainer;
