import { makeStyles } from "@material-ui/core";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import { ReactComponent as SwapIcon } from "../../icons/swaps.svg";
import { ReactComponent as Chevron } from "../../icons/chevron-right.svg";
import { DARK_GREEN, WHITE } from "../../theme";
import { Link } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";

interface Props {
  setInsightEmpty: Dispatch<SetStateAction<boolean>>;
}

const HealthyOptionInsights = ({ setInsightEmpty }: Props) => {
  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      boxSizing: "border-box",
      padding: "16px 16px 6px",
      borderRadius: "8px",
      backgroundColor: DARK_GREEN,
      display: "flex",
      gap: "16px",
      height: "116px",
      [breakpoints.up("md")]: {
        height: "94px",
      },
    },
    icon: {
      width: "40px",
      height: "40px",
      flexShrink: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    text: {
      color: WHITE,
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    similarProductsNumber: {
      color: "#ADE8DD",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    showMe: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: WHITE,
      textDecoration: "none",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      paddingTop: "8px",
      paddingBottom: "8px",
      marginRight: "auto",
      marginTop: "auto",
      "& svg path": {
        width: "16px",
        height: "16px",
        stroke: WHITE,
      },
    },
  }))();

  const [healthyOptionEmpty, setHealthyOptionEmpty] = useState<boolean>(true);

  const { isLoading, purchasedItemsWithSwaps } = useHealthierOptions("all");
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  useEffect(() => {
    if (purchasedItemsWithSwaps.length > 0) {
      setHealthyOptionEmpty(false);
      setInsightEmpty(false);
    } else {
      setHealthyOptionEmpty(true);
      setInsightEmpty(true);
    }
  }, [purchasedItemsWithSwaps, setInsightEmpty, timeFrame]);

  if (isLoading || healthyOptionEmpty) return <></>;

  return (
    <div className={classes.root}>
      <SwapIcon className={classes.icon} />
      <div className={classes.content}>
        <p className={classes.text}>
          You have{" "}
          <span className={classes.similarProductsNumber}>
            {purchasedItemsWithSwaps.length}
          </span>{" "}
          similar products with a higher Health Star Rating.
        </p>

        <Link
          to="/healthier-options/plp?source=homepage"
          className={classes.showMe}
        >
          Show me <Chevron />
        </Link>
      </div>
    </div>
  );
};

export default HealthyOptionInsights;
