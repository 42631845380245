import { useMediaQuery, useTheme } from "@material-ui/core";
import { useContext, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  dismissInsightsBanner,
  subscribeToInstantUpdates,
} from "../../services/members";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useMember from "../../hooks/useMember";
import BreakSeparator from "../../components/BreakSeparator";
import useEvents from "../../hooks/useEvents";
import SuccessfulSignupDrawer from "./SuccessfulSignupDrawer";

const useStyles = makeStyles(({ breakpoints }) => ({
  bannerContainer: {
    marginTop: "24px",
    width: "100%",
    position: "relative",
  },
  bannerImage: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    maxWidth: "100%",
    userSelect: "none",
    pointerEvents: "none",
  },
  clickableArea: {
    position: "absolute",
    cursor: "pointer",
    [breakpoints.up("md")]: {
      right: "3.8%",
      top: "33.33%",
      width: "14%",
      height: "31%",
      borderRadius: "12px",
    },
    [breakpoints.down("sm")]: {
      bottom: "8%",
      left: "4.5%",
      width: "44%",
      height: "16.5%",
      borderRadius: "18px",
    },
  },
  closeButton: {
    color: "#3A403F",
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const INSTANT_UPDATES_BANNER_IMAGES = {
  desktop:
    "https://storage.googleapis.com/food-tracker-images-prod/instant-updates-banner-desktop.webp",
  mobile:
    "https://storage.googleapis.com/food-tracker-images-prod/instant-updates-banner-mobile.webp",
};

export const InstantUpdatesBanner = ({
  member,
}: {
  member: ReturnType<typeof useMember>["member"];
}) => {
  const [isSuccessDrawerOpen, setIsSuccessDrawerOpen] = useState(false);
  const {
    setIsOpenSidebar,
    setIsOpenCommunication,
    setFromInstantUpdatesBanner,
  } = useContext(SettingsContext);
  const classes = useStyles();
  const theme = useTheme();
  const { track } = useEvents();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const showInstantUpdatesBanner = useMemo(() => {
    const dismissedInsightsBanner = member?.dismissedInsightsBanner;
    const previouslySubscribedToInsights =
      member?.previouslySubscribedToInsights;
    if (dismissedInsightsBanner || previouslySubscribedToInsights) return false;

    const hasEmailNoInsights = member?.email && !member?.communicationInsights;
    const noEmail = !member?.email;

    return hasEmailNoInsights || noEmail || isSuccessDrawerOpen;
  }, [member, isSuccessDrawerOpen]);

  if (!showInstantUpdatesBanner) return null;

  const handleHide = async () => {
    await dismissInsightsBanner(member?.memberId!);
  };

  const handleClickableArea = async () => {
    if (member?.email) {
      setIsSuccessDrawerOpen(true);
      await subscribeToInstantUpdates(member?.memberId!);
      track("Instant Updates One Click Subscribe");
    } else {
      setIsOpenSidebar(true);
      setIsOpenCommunication(true);
      setFromInstantUpdatesBanner(true);
      track("Instant Updates Banner Click");
    }
  };

  return (
    <>
      {!isDesktop && <BreakSeparator marginBottom={8} />}
      <div className={classes.bannerContainer}>
        <img
          src={
            isDesktop
              ? INSTANT_UPDATES_BANNER_IMAGES.desktop
              : INSTANT_UPDATES_BANNER_IMAGES.mobile
          }
          alt="Instant updates banner"
          className={classes.bannerImage}
          draggable="false"
        />
        <div onClick={handleClickableArea} className={classes.clickableArea} />
        <button onClick={handleHide} className={classes.closeButton}>
          &#10005;
        </button>
        <SuccessfulSignupDrawer
          open={isSuccessDrawerOpen}
          setOpen={setIsSuccessDrawerOpen}
        />
      </div>
    </>
  );
};
