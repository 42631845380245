import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  COLOR_GREYSCALE,
  DEFAULT_HEADING_FONT_FAMILY,
  FALLBACK_FONTS,
  LIGHTER_CHARCOAL,
} from "../theme";
import { Box } from "@material-ui/core";

export const AccordionIcon = ExpandMoreIcon;
export const AccordionContainer = withStyles({
  root: {
    "& > * + *": {
      marginTop: "24px",
    },
  },
})(Box);
export const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      marginTop: "24px",
      marginBottom: "0px",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #E1E1E1",
    padding: "0",
    alignItems: "flex-start",
    minHeight: "0",
    "&.Mui-expanded": {
      minHeight: "0px",
    },
    "&.Mui-expanded:not(:first-child)": {
      marginTop: "24px",
    },
  },
  content: {
    fontFamily: DEFAULT_HEADING_FONT_FAMILY,
    lineHeight: "24px",
    fontSize: "16px",
    paddingRight: "26px",
    margin: "0",
    paddingBottom: "8px",
    "&.Mui-expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    color: COLOR_GREYSCALE[900],
    padding: "0px 12px",
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    fontFamily: FALLBACK_FONTS,
    size: "16px",
    lineHeight: "18.56px",
    letterSpacing: "0.3px",
    color: LIGHTER_CHARCOAL,
    padding: "16px 0 0 0",
  },
}))(MuiAccordionDetails);
