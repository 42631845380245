import { IconButton, makeStyles } from "@material-ui/core";
import { ReactComponent as ChevronCircle } from "../../icons/chevron-circle.svg";
import SwiperCore from "swiper";

const ArticleSwiperButtons = ({ swiper }: { swiper: SwiperCore | null }) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      display: "none",
      width: "100%",
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      zIndex: 10,
      [breakpoints.up("xl")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    prev: {
      padding: 0,
    },
    next: {
      padding: 0,
      transform: "rotate(180deg)",
    },
  }));

  const classes = useStyles();

  if (!swiper) return <></>;
  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => swiper.slidePrev()}
        className={classes.prev}
        aria-label="Previous"
      >
        <ChevronCircle />
      </IconButton>
      <IconButton
        onClick={() => swiper.slideNext()}
        className={classes.next}
        aria-label="Next"
      >
        <ChevronCircle />
      </IconButton>
    </div>
  );
};

export default ArticleSwiperButtons;
