import { HEALTHIER_RECIPE_URL, RECIPE_DISPLAY_LIMIT } from "../constants";

export const getHealthierRecipes = async (filters: string = "", limit: number = RECIPE_DISPLAY_LIMIT) => {
  let recipes = [];
  try {
    const result = await fetch(
      `${HEALTHIER_RECIPE_URL}${filters}.limit=${limit}.json`
    );
    recipes = await result.json();
  } catch (e) {
    console.log("Log: recipe fetch error", e);
  }

  return recipes;
};


export const getAllHealthierRecipes = async () => {
    let recipes = [];
    try {
      const result = await fetch(
        `${HEALTHIER_RECIPE_URL}.json`
      );
      recipes = await result.json();
    } catch (e) {
      console.log("Log: recipe fetch error", e);
    }
  
    return recipes;
  };
