import React, { FC } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { CHARCOAL } from "../theme";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props {
  title: string;
  link?: string;
  linkText?: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: CHARCOAL,
  },
  title: {
    margin: theme.spacing(2, 0, 3, 0),
    lineHeight: "40px",
    fontSize: "32px",
    [theme.breakpoints.up("md")]: {
      fontSize: "28px"
    }
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    position: "sticky",
    top: 0,
    zIndex: 3,
    backgroundColor: "#ffffff",
  },
  linkText: {
    margin: theme.spacing(1, 0, 1, 1),
    fontSize: "14px",
    fontFamily: "\"Gilroy-SemiBold\"",
    fontWeight: "normal",
    color: "black"
  }
}));

const PageHeader: FC<Props> = 
  ({ 
     title,
     link = "/",
     linkText = "Back to Food Tracker homepage",
  }) => {
  const classes = useStyles();
  return (
    <>
      <Link to={link} aria-label={linkText} className={classes.link}>
        <ArrowBackIcon className={classes.icon} />
        <Typography variant="h2" component="h2" className={classes.linkText}>
          {linkText}
        </Typography>
      </Link>
      {title && (
        <Typography variant="h2" component="h1" className={classes.title}>
          {title}
        </Typography>
      )}
    </>
  );
};

export default PageHeader;
