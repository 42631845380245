import { makeStyles } from "@material-ui/core";
import useMember from "../../hooks/useMember";
import FruitAndVegLeaderboardIntro from "./FruitAndVegLeaderboardIntro";
import FruitAndVegLeaderboardJoined from "./FruitAndVegLeaderboardJoined";
import { Dispatch, SetStateAction, useEffect } from "react";
import useEvents from "../../hooks/useEvents";
import { FRUIT_AND_VEG_CHALLENGE_EVENTS } from "../../events";

interface FruitAndVegLeaderboardProps {
  setTabValue: Dispatch<SetStateAction<string>>;
}

const FruitAndVegLeaderboard = ({
  setTabValue,
}: FruitAndVegLeaderboardProps) => {
  const { member } = useMember();
  const { track } = useEvents();

  useEffect(() => {
    return () => track(FRUIT_AND_VEG_CHALLENGE_EVENTS.EXITED_LEADERBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = makeStyles(({ breakpoints }) => ({
    pageWrapper: {
      position: "relative",
      marginTop: "32px",
      borderRadius: "12px",
      background: "#fff",
      padding: "16px",
      [breakpoints.up("md")]: {
        padding: "40px",
        marginBottom: "55px",
      },
    },
  }))();

  return (
    <div className={classes.pageWrapper}>
      {member?.eligibleForFruitAndVegMayCampaign &&
      member?.joinedFruitAndVegMayCampaignLeaderboard ? (
        <FruitAndVegLeaderboardJoined />
      ) : (
        <FruitAndVegLeaderboardIntro setTabValue={setTabValue} />
      )}
    </div>
  );
};

export default FruitAndVegLeaderboard;
