import { Typography } from "@material-ui/core";
import React from "react";
import ExternalLink from "../../components/ExternalLink";

import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
  InfoModalTitle,
} from "../InfoModal";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import {
  FAQ_ACCORDION_EVENT,
  HOMEPAGE_EVENT,
  NUTRIENT_TRACKER_EVENT,
} from "../../events";
import useEvents from "../../hooks/useEvents";

const NutrientTrackerExplainer = ({ source }: { source: string }) => {
  const { track } = useEvents();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const faqTitles = [
    "How are the swaps decided",
    "Why is there no target line for fat and sugar?",
    "How can I view my swap suggestions across previous months, weeks or fortnights?",
    "What about shopping I purchased from another store. Is this counted in Nutrient Tracker?",
    "What is my salt target?",
  ];
  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const handleOpenEvent = () => {
    switch (source) {
      case "nutrient-tracker":
        return NUTRIENT_TRACKER_EVENT.HELP_OPEN;
      case "homepage":
        return HOMEPAGE_EVENT.NT_HELP_OPEN;
      default:
        return NUTRIENT_TRACKER_EVENT.HELP_OPEN;
    }
  };
  const openEvent = handleOpenEvent();

  const handleCloseEvent = () => {
    switch (source) {
      case "nutrient-tracker":
        return NUTRIENT_TRACKER_EVENT.HELP_CLOSE;
      case "homepage":
        return HOMEPAGE_EVENT.NT_HELP_CLOSE;
      default:
        return NUTRIENT_TRACKER_EVENT.HELP_CLOSE;
    }
  };
  const closeEvent = handleCloseEvent();

  return (
    <InfoModalContainer
      eventName={openEvent}
      eventCloseName={closeEvent}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalTitle>Nutrient Tracker</InfoModalTitle>
      <InfoModalContent>
        <Typography variant="body1">
          Nutrient Tracker helps you keep track of the sugar, salt, and fat in
          your basket.
        </Typography>

        <Typography component="ul">
          <Typography variant="body2" component="li">
            The sugar tab will show you the total sugar and added sugar within
            your basket.
          </Typography>
          <Typography variant="body2" component="li">
            The salt tab will show you salt (sodium) within your basket and
            compare this against the Suggested Dietary Intake.
          </Typography>
          <Typography variant="body2" component="li">
            The fat tab will show you the total and saturated fat within your
            basket.
          </Typography>
          <Typography variant="body2" component="li">
            And if you want more, Nutrient Tracker will give you suggestions on
            what to swap for a healthier basket.
          </Typography>
        </Typography>

        <Typography variant="h2" style={{ marginBottom: "32px" }}>
          FAQS
        </Typography>
        <AccordionContainer>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AccordionIcon />}
            >
              How are the swaps decided
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                For each basket, we look at the top contributors to added sugar,
                salt and saturated fat. We then show these as well as suggested
                Healthier Options if they are available.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<AccordionIcon />}
            >
              Why is there no target line for fat and sugar?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Australian guidelines do not provide specific recommendations on
                saturated fat and added sugar intake.{" "}
                <ExternalLink
                  href="https://www.eatforhealth.gov.au/sites/default/files/files/the_guidelines/n55_australian_dietary_guidelines.pdf"
                  underline="always"
                  aria-label={"Australian Salt and Sugar Guideline"}
                  target="_blank"
                >
                  The guideline recommends that Australians
                </ExternalLink>{" "}
                limit intake of foods high in saturated fat and added sugar
                (often found in sometimes foods) and instead, crowd your plate
                with main foods.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3", 2)}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<AccordionIcon />}
            >
              How can I view my swap suggestions across previous months, weeks
              or fortnights?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Currently you can view swap suggestions for the latest week,
                fortnight or month depending on what you select. Watch this
                space for more updates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordionChange("panel4", 3)}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<AccordionIcon />}
            >
              What about shopping I purchased from another store. Is this
              counted in Nutrient Tracker?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                No, Nutrient Tracker is based on your recent Woolworths shops.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordionChange("panel5", 4)}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
              expandIcon={<AccordionIcon />}
            >
              What is my salt target?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">
                Salt targets are calculated based on your household set up and
                using Suggested Dietary Intake for adults and adequate Intake
                for children. If your basket total is below the target, there is
                no need to increase salt.
                <Typography variant="body1" style={{ fontStyle: "italic" }}>
                  * The target applies to healthy Australians. It does not apply
                  to people who need special dietary advice for a medical
                  condition.
                </Typography>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default NutrientTrackerExplainer;
