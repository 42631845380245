import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { SelectProps } from "@material-ui/core/Select/Select";

const buttonClassUseStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      background: "white",
      margin: 0,
    },
    "& .MuiSelect-root": {
      fontFamily: '"Gilroy-SemiBold"',
      color: "#1D1F20",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "20px",
    },
    "&. MuiSvgIcon-root": {
      color: "#242629",
    },
  },
  listRoot: {
    borderRadius: "8px",
    backgroundColor: "white",
    border: "1px solid #D3D6D9",
    "& > ul > .MuiMenuItem-root": {
      color: "#1D1F20",
      lineHeight: "20px",
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      margin: 0,
      paddingBottom: "8px",
      paddingTop: "8px",
    },
  },
}));

const SelectDropdown: FC<SelectProps> = ({
  value,
  onChange,
  children,
  ...props
}) => {
  const classes = buttonClassUseStyles();
  return (
    <FormControl variant="outlined" classes={classes} size={"small"}>
      <Select
        MenuProps={{ classes: { paper: classes.listRoot } }}
        IconComponent={KeyboardArrowDownIcon}
        value={value}
        onChange={onChange}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
