import { useContext } from "react";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import { TimeFrameDuration } from "../constants";
import { getDiscretionaryPercentData } from "../services/servings";
import useServingsUntilYesterday from "./useServingsUntilYesterday";

const useDiscretionaryPercent = () => {
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const timeFrameDuration = TimeFrameDuration[timeFrame];

  const { servingsPurchased, loadingServings } =
    useServingsUntilYesterday(timeFrameDuration);

  const { totalServings, emptyServings, discretionaryPercent } =
    getDiscretionaryPercentData(servingsPurchased);

  return {
    servingsPurchased,
    loadingServings,
    emptyServings,
    totalServings,
    discretionaryPercent,
  };
};

export default useDiscretionaryPercent;
