import { doc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import { Collections, db } from "./firebase";

export const storeFeedback = async (
  memberId: string,
  rating: number,
  comment?: string
) => {
  const feedback = {
    memberId,
    rating,
    comment,
    timestamp: DateTime.utc().toMillis(),
  };
  const memberRef = doc(db, Collections.Members, memberId);

  return Promise.all([
    setDoc(
      doc(
        db,
        Collections.FeedbackSubmissions,
        DateTime.utc().toMillis().toString()
      ),
      feedback
    ),
    setDoc(
      memberRef,
      { feedbackSubmittedOrDismissed: DateTime.utc().toMillis() },
      { merge: true }
    ),
  ]);
};

export const storeFeedbackDismissed = async (memberId: string) => {
  const memberRef = doc(db, Collections.Members, memberId);
  await setDoc(
    memberRef,
    { feedbackSubmittedOrDismissed: DateTime.utc().toMillis() },
    { merge: true }
  );
};
