import { useContext } from "react";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";
import { MemberContext } from "../components/providers/MemberProvider";

export default function useHidePrograms() {
  const { featureFlags } = useContext(FeatureFlagContext);
  const member = useContext(MemberContext);
  const { memberPrograms } = featureFlags;

  if (
    memberPrograms?.enable?.everybody ||
    memberPrograms?.enable?.specificMembers?.includes(member!.memberId)
  ) {
    return false;
  }
  return true;
}
