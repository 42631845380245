import { Document } from "@contentful/rich-text-types/dist/types/types";
import { FAQS } from "./hardcodedContentfulContent";

export interface FaqContent {
  question: string;
  answer: Document;
}

export const fetchFaqPageQuestions = async (): Promise<FaqContent[]> => {
  // Used to fetched from Contentful, now hard coded.
  return FAQS.data.faqPageCollection.items[0].questionsCollection.items.map(
    (item: any) => ({
      question: item.question,
      answer: item.answer.json,
    })
  );
};
