import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { CHARCOAL, LIGHT_GREY } from "../theme";
import { LinkWithImage } from "../models";
import ExternalLink from "./ExternalLink";

interface Props {
  content: LinkWithImage;
}

const useStyles = makeStyles(({ spacing }) => ({
  link: {
    textDecoration: "none !important",
  },
  root: {
    marginTop: spacing(2),
    paddingLeft: spacing(2),
    height: 80,
    background: LIGHT_GREY,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    color: CHARCOAL,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    marginTop: 0,
    width: 150,
  },
  image: {
    height: "95%",
  },
}));

const ChallengeTile: FC<Props> = ({ content }) => {
  const classes = useStyles();
  return (
    <ExternalLink href={content.url} target="_blank" className={classes.link}>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.text}>
          {content.title}
        </Typography>
        <img alt="" src={content.image.url} className={classes.image} />
      </div>
    </ExternalLink>
  );
};

export default ChallengeTile;
