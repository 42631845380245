import { ContentfulRichContent } from "../../models";
import {
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_VERSION,
} from "./hardcodedContentfulContent";

export interface TermsAndConditionsContent {
  sys: {
    id: string;
    publishedAt: string;
    publishedVersion: number;
  };
  content: ContentfulRichContent;
}

export const fetchTermsAndConditionsVersion = async (): Promise<number> => {
  // Used to fetched from Contentful, now hard coded.
  const [latest] =
    TERMS_AND_CONDITIONS_VERSION.data.termsAndConditionsPageCollection.items;
  return latest?.sys?.publishedVersion ?? -1;
};

export const fetchTermsAndConditions =
  async (): Promise<TermsAndConditionsContent> => {
    // Used to fetched from Contentful, now hard coded.
    //@ts-ignore
    return TERMS_AND_CONDITIONS.data.termsAndConditionsPageCollection.items[0];
  };
