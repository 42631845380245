import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as BarChart } from "../../icons/bar-chart.svg";
import FoodGroupInsightsContent from "./FoodGroupInsightsContent";
import { COLOR_GREYSCALE } from "../../theme";
import FoodGroupInsightsExplainer from "./FoodGroupInsightsExplainer";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  cardWrapper: {
    background: "white",
    position: "relative",
    padding: "16px",
    borderRadius: "12px",
    [breakpoints.up("md")]: {
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      marginTop: "16px",
      padding: "24px",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: 0,
    marginBottom: 10,
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    paddingBottom: 10,
    borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    [breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      paddingBottom: 8,
      marginBottom: 8,
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 30,
    marginRight: 8,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  mobileDivider: {
    marginTop: spacing(2),
    [breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const FoodGroupInsightsSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.cardWrapper}>
      <Typography variant="h2" component="h1" className={classes.header}>
        <span className={classes.icon}>
          <BarChart />
        </span>
        <span style={{ display: "inline-block", marginRight: 4 }}>
          Know your food groups
        </span>
        <FoodGroupInsightsExplainer />
      </Typography>
      <FoodGroupInsightsContent />
    </div>
  );
};

export default FoodGroupInsightsSection;
