import { createElement } from "react";
import { Link } from "react-router-dom";
import { FoodGroup } from "../../models";
import useElementBecameVisibleObserver from "../../hooks/useElementBecameVisibleObserver";
import { FoodGroupLabel, FoodGroupWithBGIconV2 } from "../../constants";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as ArrowRight } from "../../icons/chevron-right.svg";
import { ReactComponent as SometimesRoundedIcon } from "../../icons/wbg-sometimes-v2.svg";
import { COLOR_GREYSCALE, WHITE } from "../../theme";

const ORDERED_FOOD_GROUPS = [
  FoodGroup.Vegetables,
  FoodGroup.Fruit,
  FoodGroup.Dairy,
  FoodGroup.Protein,
  FoodGroup.Grains,
];

const IntakeFoodGroup = () => {
  const { containerRef } = useElementBecameVisibleObserver();

  const useStyles = makeStyles(({ spacing }) => ({
    iconLabel: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: spacing(1.5),
        width: "32px",
        height: "32px",
      },
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      color: "#1D1F20",
    },
    foodGroupLink: {
      position: "relative",
      textDecoration: "none",
      cursor: "pointer",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "4px",
      padding: "8px",
      borderRadius: "8px",
      backgroundColor: COLOR_GREYSCALE[50],
      boxSizing: "border-box",
      "&:hover .food-group-label": {
        textDecoration: "underline",
      },
    },
    disabled: {
      opacity: 0.3,
    },
    separator: {
      position: "absolute",
      height: "100%",
      right: "32px",
      top: 0,
      width: "3px",
      backgroundColor: WHITE,
    },
  }));

  const classes = useStyles();

  return (
    <div ref={containerRef} role="group">
      <Link to="/food-group/sometimes" className={classes.foodGroupLink}>
        <div className={classes.iconLabel}>
          <SometimesRoundedIcon />
          <span className="food-group-label">Sometimes foods</span>
        </div>
        <div className={classes.separator}></div>
        <ArrowRight />
      </Link>
      {ORDERED_FOOD_GROUPS.map((foodGroup, index) => (
        <Link
          to={`/food-group/${foodGroup}`}
          className={classes.foodGroupLink}
          key={index}
        >
          <div className={classes.iconLabel}>
            {createElement(FoodGroupWithBGIconV2[foodGroup])}
            <span className="food-group-label">
              {FoodGroupLabel[foodGroup]}
            </span>
          </div>
          <div className={classes.separator}></div>
          <ArrowRight />
        </Link>
      ))}
    </div>
  );
};

export default IntakeFoodGroup;
