import { DIETARY_PREFERENCES_DATA, DIETARY_PREFERENCES } from "./config";
import { cloneElement, useContext, useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import { DietaryPreferencesContext } from "../../components/providers/DietaryPreferencesProvider";

export const DietaryPreferenceCheckbox = ({
  preference,
}: {
  preference: DIETARY_PREFERENCES;
}) => {
  const { checked, setChecked, cancelledPref } = useContext(
    DietaryPreferencesContext
  );
  const isPreferenceChecked = useMemo(
    () => checked.includes(preference),
    [checked, preference]
  );

  const isPreferenceDisabled = useMemo(
    () =>
      (checked.length >= 3 && !isPreferenceChecked) ||
      cancelledPref.includes(preference),
    [checked.length, cancelledPref, preference, isPreferenceChecked]
  );

  const useStyles = makeStyles(() => ({
    goalContainer: {
      cursor: "pointer",
      opacity: isPreferenceDisabled ? 0.5 : 1,
      transition: "opacity 0.03s",
      border: `1px solid ${
        isPreferenceChecked ? DARK_GREEN : COLOR_GREYSCALE[200]
      }`,
      width: "100%",
      alignItems: "center",
      display: "flex",
      padding: "8px 16px 8px 8px",
      borderRadius: "12px",
    },
    goalText: {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      width: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    goalIcon: {
      marginRight: "12px",
      flexShrink: 0,
    },
    checkbox: {
      height: "20px",
      width: "20px",
      color: COLOR_GREYSCALE[400],
      padding: 0,
    },
  }));

  const classes = useStyles();
  const { name, icon } = DIETARY_PREFERENCES_DATA[preference];
  const handlePreferenceClick = () => {
    if (checked.includes(preference)) {
      setChecked(checked.filter((g) => g !== preference));
    } else if (!isPreferenceDisabled) {
      const toCancel: DIETARY_PREFERENCES[] =
        DIETARY_PREFERENCES_DATA[preference].cancels || [];
      const filteredPreferences = checked.filter(
        (check) => !toCancel.includes(check)
      );
      const checkedPrefs = [...filteredPreferences, preference];

      setChecked(checkedPrefs.sort());
    }
  };

  return (
    <div className={classes.goalContainer} onClick={handlePreferenceClick}>
      {cloneElement(icon, { className: classes.goalIcon })}
      <Typography variant={"body2"} className={classes.goalText}>
        {name}
      </Typography>
      <Checkbox
        className={classes.checkbox}
        color={"primary"}
        checked={checked.includes(preference)}
        disabled={isPreferenceDisabled}
      />
    </div>
  );
};
