import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { ReactComponent as BarBoxIcon } from "../icons/bar-box-icon.svg";
import NutrientTrackerTabs from "../components-2/nutrient-tracker/NutrientTrackerTabs";
import NutrientTrackerBarChart from "../components-2/nutrient-tracker/NutrientTrackerBarChart";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import NutrientTrackerExplainer from "../components-2/nutrient-tracker/NutrientTrackerExplainer";
import { HouseholdContext } from "../components/providers/HouseholdProvider";
import HealthierOptionsSwap from "../components-2/healthier-options/HealthierOptionsSwap";
import TimeFrameTabs from "../components/TimeFrameTabs";
import NutrientTrackerLearnMore from "../components-2/nutrient-tracker/NutrientTrackerLearnMore";
import HealthyInspiration from "../components/healthy-inspiration/HealthyInspiration";
import NutrientTrackerBlockqoutes from "../components-2/nutrient-tracker/NutrientTrackerBlockquotes";
import { PageLayout } from "../components-2/page-layout";
import { COLOR_GREYSCALE } from "../theme";
import { NutrientTrackerTypes } from "../models";
import { TimeFrame } from "../constants";
import useHealthierOptions from "../hooks/useHealthierOptions";
import useNutrients from "../hooks/useNutrients";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: "12px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    [breakpoints.up("md")]: {
      padding: "40px 40px 0",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  headerTitleContainer: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    [breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "36px",
      width: "100%",
      marginBottom: "0",
    },
  },
  headerTitle: {
    fontSize: "16px",
    letterSpacing: "0.3px",
    lineHeight: "20px",
    margin: "0px 4px",
    [breakpoints.up("md")]: {
      fontSize: "28px",
      lineHeight: "36px",
      margin: "0px 8px",
    },
  },
  barChartWrapper: {
    padding: "0px 16px",
    [breakpoints.up("md")]: {
      border: `1px solid ${COLOR_GREYSCALE[200]}`,
      margin: "32px 80px 24px",
      borderRadius: "12px",
    },
  },
  barChartWrapperTitle: {
    display: "none",
    margin: "0px",
    [breakpoints.up("md")]: {
      padding: "24px 40px 0",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "24px",
      display: "block",
      lineHeight: "32px",
      color: "#104239",
    },
  },
  hideOnDesktop: {
    display: "block",
    [breakpoints.up("md")]: {
      display: "none",
    },
  },
  hideOnMobile: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "block",
    },
  },
  blockquotesWrapper: {
    display: "block",
    paddingTop: "16px",
    [breakpoints.up("md")]: {
      paddingTop: "0px",
    },
  },
  barBoxIcon: {
    width: "16px",
    height: "16px",
    [breakpoints.up("md")]: {
      width: "20px",
      height: "20px",
    },
  },
  timeFrameTabsContainer: {
    margin: "32px 0 24px 0",
    [breakpoints.up("md")]: {
      margin: "40px 0",
    },
  },
  barChartContent: {
    [breakpoints.up("md")]: {
      padding: "0 40px 40px",
    },
    [breakpoints.up("lg")]: {
      display: "flex",
      gap: "16px",
    },
  },
  healthyInspirationWrapper: {
    margin: "24px 0px",
    [breakpoints.up("md")]: {
      padding: "16px",
      width: "93%",
    },
  },
}));

const NutrientTrackerPage = () => {
  const [type, setType] = useState<NutrientTrackerTypes["type"]>("sugar");
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const householdContext = useContext(HouseholdContext);
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { purchasedItemsWithSwaps } = useHealthierOptions("all");
  const { nutrientsTopContributors } = useNutrients();

  const itemsWithSwaps =
    (type ? nutrientsTopContributors?.[type] : purchasedItemsWithSwaps) || [];

  useEffect(() => {
    const queryType = query.get("type");
    if (queryType) {
      setType(query.get("type") as NutrientTrackerTypes["type"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSaltTarget = (timeFrame: TimeFrame) => {
    let dailySaltTarget = 0;

    householdContext!.forEach((household) => {
      dailySaltTarget += household.benchmark.saltTarget;
    });
    const weekly = dailySaltTarget * 7;
    const benchmarkTargets = {
      Day: dailySaltTarget,
      Week: weekly,
      Fortnight: weekly * 2,
      Month: weekly * 4,
    };

    return benchmarkTargets[timeFrame];
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <PageLayout title="Nutrient Tracker">
      <div className={classes.timeFrameTabsContainer}>
        <TimeFrameTabs textColorMobile={COLOR_GREYSCALE[600]} />
      </div>

      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerTitleContainer}>
            <BarBoxIcon className={classes.barBoxIcon} />
            <h2 className={classes.headerTitle}>Nutrient Tracker </h2>
            <NutrientTrackerExplainer source="nutrient-tracker" />
          </div>
          <NutrientTrackerTabs type={type} setType={setType} />
        </div>

        <div className={classes.barChartWrapper}>
          <h3 className={classes.barChartWrapperTitle}>
            {type.charAt(0).toUpperCase()}
            {type.slice(1)}
          </h3>

          <div className={classes.barChartContent}>
            <div>
              <NutrientTrackerBarChart
                type={type}
                timeFrame={timeFrame}
                saltTarget={getSaltTarget(timeFrame)}
              />
            </div>

            <div className={classes.blockquotesWrapper}>
              <NutrientTrackerBlockqoutes type={type} timeFrame={timeFrame} />
            </div>
          </div>
          <NutrientTrackerLearnMore type={type} />
        </div>

        {!isMobile ? (
          <div>
            {itemsWithSwaps.length ? (
              <HealthierOptionsSwap type={type} />
            ) : null}
            <div className={classes.healthyInspirationWrapper}>
              <HealthyInspiration source="home" articles={[]} />
            </div>
          </div>
        ) : null}
      </div>

      {isMobile ? (
        <div>
          {itemsWithSwaps.length ? <HealthierOptionsSwap type={type} /> : null}

          <div className={classes.healthyInspirationWrapper}>
            <HealthyInspiration source="home" articles={[]} />
          </div>
        </div>
      ) : null}
    </PageLayout>
  );
};
export default NutrientTrackerPage;
