import {
  useEffect,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useContext,
} from "react";
import useRecipes from "../../hooks/useRecipes";
import { FilterProps } from "../../hooks/useRecipes";
import { RECIPE_DISPLAY_LIMIT } from "../../constants";
import {
  DIETARY_PREFERENCES,
  DIETARY_PREFERENCES_DATA,
} from "../../components-2/dietary-preference/config";
import { DietaryPreferencesContext } from "./DietaryPreferencesProvider";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

interface Props {}

interface FilterGroup {
  name: string;
  filters: FilterProps[];
}

interface RecipeContextState {
  toggleFilter: boolean;
  setToggleFilter: Dispatch<SetStateAction<boolean>>;
  filterGroups: FilterGroup[];
  filteredRecipes: string[];
  setFilteredRecipes: Dispatch<SetStateAction<string[]>>;
  tempFilters: string[];
  setTempFilters: Dispatch<SetStateAction<string[]>>;
  availableFilters: string[];
  setAvailableFilters: Dispatch<SetStateAction<string[]>>;
  currentFilters: string[];
  setCurrentFilters: Dispatch<SetStateAction<string[]>>;
  dietaryPreferences: DIETARY_PREFERENCES[];
  setDietaryPreferences: Dispatch<SetStateAction<DIETARY_PREFERENCES[]>>;
  cancelledPreferences: DIETARY_PREFERENCES[];
  setCancelledPreferences: Dispatch<SetStateAction<DIETARY_PREFERENCES[]>>;
  recipeCounter: number;
  setRecipeCounter: Dispatch<SetStateAction<number>>;
  setActiveFoodGroup: Dispatch<SetStateAction<string[]>>;
  handleAvailablePreferences: (
    pref: DIETARY_PREFERENCES[]
  ) => DIETARY_PREFERENCES[];
}

export const RecipeContext = createContext<RecipeContextState>({
  toggleFilter: false,
  setToggleFilter: () => {},
  filterGroups: [],
  filteredRecipes: [],
  setFilteredRecipes: () => {},
  tempFilters: [],
  setTempFilters: () => {},
  availableFilters: [],
  setAvailableFilters: () => {},
  currentFilters: [],
  setCurrentFilters: () => {},
  dietaryPreferences: [],
  setDietaryPreferences: () => {},
  cancelledPreferences: [],
  setCancelledPreferences: () => {},
  recipeCounter: 0,
  setRecipeCounter: () => {},
  setActiveFoodGroup: () => {},
  handleAvailablePreferences: () => [],
});

const RecipeProvider: FC<Props> = ({ children }) => {
  const { filters, foodGroupFilters, formattedRecipes } = useRecipes();
  const hideDP = useHideDietaryPreferences();
  const { checked: preferences } = useContext(DietaryPreferencesContext);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [activeFoodGroup, setActiveFoodGroup] = useState<string[]>([]);
  const [recipeCounter, setRecipeCounter] =
    useState<number>(RECIPE_DISPLAY_LIMIT);
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);

  const [filteredRecipes, setFilteredRecipes] = useState<string[]>([]);
  const [tempFilters, setTempFilters] = useState<string[]>([]);
  const [availableFilters, setAvailableFilters] = useState<string[]>([]);
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);
  const [dietaryPreferences, setDietaryPreferences] = useState<
    DIETARY_PREFERENCES[]
  >([]);
  const [cancelledPreferences, setCancelledPreferences] = useState<
    DIETARY_PREFERENCES[]
  >([]);

  useEffect(() => {
    const filterKeys = Object.keys(filters) as Array<keyof typeof filters>;
    let currRecipes = [...formattedRecipes];
    let groupedFilters = {
      dietarypreferences: [] as string[],
      foodgroup: [] as string[],
      ingredients: [] as string[],
      mealtype: [] as string[],
    };

    tempFilters.forEach((filter) => {
      if (!hideDP && filter.includes("dietarypreferences:")) {
        let filtername = filter.replace("dietarypreferences:", "");
        // have lactose_free filter work as dairy_free filter
        if (filtername === "lactose_free") {
          filtername = "dairy_free";
        }
        groupedFilters.dietarypreferences.push(filtername);
      }
      if (filter.includes("foodgroup:")) {
        const filtername = filter.replace("foodgroup:", "");
        groupedFilters.foodgroup.push(filtername);
      }
      if (filter.includes("ingredients:")) {
        const filtername = filter.replace("ingredients:", "");
        groupedFilters.ingredients.push(filtername);
      }
      if (filter.includes("mealtype:")) {
        const filtername = filter.replace("mealtype:", "");
        groupedFilters.mealtype.push(filtername);
      }
    });

    if (groupedFilters.dietarypreferences.length > 0) {
      currRecipes = currRecipes.filter((recipe) => {
        const hasPreference = recipe.dietaryPreferences?.some((pref) =>
          groupedFilters.dietarypreferences?.includes(pref)
        );
        return hasPreference;
      });
    }

    if (groupedFilters.foodgroup.length > 0) {
      currRecipes = currRecipes.filter((recipe) => {
        const hasFoodGroup = recipe.foodGroup?.some((fg) =>
          groupedFilters.foodgroup?.includes(fg)
        );
        return hasFoodGroup;
      });
    }

    if (groupedFilters.ingredients.length > 0) {
      currRecipes = currRecipes.filter((recipe) => {
        const hasIngredient = recipe.ingredients?.some((ingredient) =>
          groupedFilters.ingredients?.includes(ingredient)
        );
        return hasIngredient;
      });
    }

    if (groupedFilters.mealtype.length > 0) {
      currRecipes = currRecipes.filter((recipe) => {
        const hasMealtype = recipe.mealType?.some((mealtype) =>
          groupedFilters.mealtype?.includes(mealtype)
        );
        return hasMealtype;
      });
    }

    const filterGroups = filterKeys.map((key) => {
      let filterOptions = filters[key];

      if (key === "ingredients") {
        const recipeWithIngredients = currRecipes.filter(
          (recipe) => recipe.ingredients && recipe.ingredients?.length > 0
        );

        const ingredients = recipeWithIngredients
          .map((recipe) => recipe.ingredients)
          .flat()
          .filter((item, i, items) => items.indexOf(item) === i) as string[];
        filterOptions = ingredients.map((item: string) => {
          return {
            id: item,
            name: item.replaceAll("-", " "),
          };
        });
      }

      if (key === "meal_type") {
        const recipeWithMealTypes = currRecipes.filter(
          (recipe) => recipe.mealType && recipe.mealType?.length > 0
        );

        const mealTypes = recipeWithMealTypes
          .map((recipe) => recipe.mealType)
          .flat()
          .filter((item, i, items) => items.indexOf(item) === i) as string[];
        filterOptions = mealTypes.map((item: string) => {
          return {
            id: item,
            name: item.replaceAll("-", " "),
          };
        });
      }

      return {
        name: key.replaceAll("_", " "),
        filters: filterOptions,
      };
    });
    const availablefilters = tempFilters.filter((filter) => {
      if (!hideDP && filter.includes("dietarypreferences:")) {
        const index = filterGroups.findIndex(
          (group) => group.name === "dietary preferences"
        );
        return filterGroups[index].filters.some(
          (f) => `dietarypreferences:${f.id}` === filter
        );
      }
      if (filter.includes("foodgroup:")) {
        const index = filterGroups.findIndex(
          (group) => group.name === "food group"
        );
        return filterGroups[index].filters.some(
          (f) => `foodgroup:${f.id}` === filter
        );
      }
      if (filter.includes("ingredients:")) {
        const index = filterGroups.findIndex(
          (group) => group.name === "ingredients"
        );
        return filterGroups[index].filters.some(
          (f) => `ingredients:${f.id}` === filter
        );
      }
      if (filter.includes("mealtype:")) {
        const index = filterGroups.findIndex(
          (group) => group.name === "meal type"
        );
        return filterGroups[index].filters.some(
          (f) => `mealtype:${f.id}` === filter
        );
      }
      return false;
    });

    setAvailableFilters(availablefilters);
    setFilterGroups(filterGroups);
  }, [
    tempFilters,
    formattedRecipes,
    activeFoodGroup,
    filters,
    foodGroupFilters,
    dietaryPreferences,
    hideDP,
    // currentFoodGroup,
    // userFilters,
  ]);

  useEffect(() => {
    let f = [] as string[];
    if (!hideDP) {
      setDietaryPreferences(preferences);
      f = preferences.map((pref) => "dietarypreferences:" + pref);
    }
    setFilteredRecipes(f);
    setCurrentFilters(f);
    setTempFilters(f);
  }, [hideDP, preferences]);

  useEffect(() => {
    const handleCancelledPreferences = () => {
      const disabledPreferences =
        dietaryPreferences
          .map((preference) => DIETARY_PREFERENCES_DATA[preference].cancels)
          .flat() || [];

      setCancelledPreferences(disabledPreferences);
    };

    handleCancelledPreferences();
  }, [dietaryPreferences]);

  useEffect(() => {
    const hasEverything = tempFilters.every((filter) =>
      availableFilters.includes(filter)
    );
    if (!hasEverything) {
      setTempFilters(availableFilters);
    }
    // eslint-disable-next-line
  }, [availableFilters]);

  const handleAvailablePreferences = (preferences: DIETARY_PREFERENCES[]) => {
    const cancelledPrefs = preferences
      .map((pref) => DIETARY_PREFERENCES_DATA[pref].cancels)
      .flat();

    const availablePreferences = preferences.filter(
      (pref) => !cancelledPrefs.includes(pref)
    );

    return availablePreferences;
  };

  const value = {
    toggleFilter,
    setToggleFilter,
    filterGroups,
    setFilteredRecipes,
    filteredRecipes,
    tempFilters,
    setTempFilters,
    availableFilters,
    setAvailableFilters,
    currentFilters,
    setCurrentFilters,
    dietaryPreferences,
    setDietaryPreferences,
    cancelledPreferences,
    setCancelledPreferences,
    recipeCounter,
    setRecipeCounter,
    setActiveFoodGroup,
    handleAvailablePreferences,
  };

  return (
    <RecipeContext.Provider value={value}>{children}</RecipeContext.Provider>
  );
};

export default RecipeProvider;
