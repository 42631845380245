import { Link, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as GoalTrackingIcon } from "../../icons/goal-tracking-icon.svg";
import { ReactComponent as GoalsEditPen } from "../../icons/goals-edit-pen.svg";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import { useContext } from "react";
import { GoalsContainer } from "./GoalsContainer";
import { GoalsExplainer } from "./GoalsExplainer";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useEvents from "../../hooks/useEvents";
import { GOALS_EVENTS } from "../../events";

const GoalsSummary = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      marginTop: 16,
      padding: "16px",
      borderRadius: 12,
      border: "none",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      overflow: "hidden",
      [breakpoints.up("md")]: {
        padding: "24px",
        boxSizing: "border-box",
        height: "100%",
      },
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D3D6D9",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      [breakpoints.up("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
    similarProductsText: {
      padding: "0px",
    },
    noSwapsText: {
      color: "#585C60",
      "& > ul": {
        margin: 0,
        marginTop: "10px",
      },
    },
    icon: {
      "& path": {
        fill: DARK_GREEN,
      },
    },
    editPen: {
      textDecoration: "none",
      color: COLOR_GREYSCALE[800],
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
  }));

  const { setIsOpenPersonalisation, setCloseAllOnExit } =
    useContext(SettingsContext);
  const { track } = useEvents();
  const classes = useStyles();
  const redirectToGoalsSettings = () => {
    setIsOpenPersonalisation("health");
    setCloseAllOnExit(true);
    track(GOALS_EVENTS.EDIT_CLICKED);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.headerTitle}>
        <div className={classes.titleContainer}>
          <GoalTrackingIcon className={classes.icon} />
          <Link
            tabIndex={0}
            onKeyPress={(e) => ifEnterOrSpace(e, redirectToGoalsSettings)}
            onClick={() => redirectToGoalsSettings()}
            style={{
              textDecoration: "none",
              color: COLOR_GREYSCALE[800],
              cursor: "pointer",
            }}
          >
            <div className={classes.title}>My goals</div>
          </Link>
          <GoalsExplainer />
        </div>
        <Link
          tabIndex={0}
          onKeyPress={(e) => ifEnterOrSpace(e, redirectToGoalsSettings)}
          onClick={() => redirectToGoalsSettings()}
          className={classes.editPen}
        >
          <GoalsEditPen />
        </Link>
      </Typography>
      <GoalsContainer redirectToGoalsSettings={redirectToGoalsSettings} />
    </div>
  );
};

export default GoalsSummary;
