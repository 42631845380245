import { FC } from "react";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { Block, BLOCKS, Inline, INLINES } from "@contentful/rich-text-types";
import { Document } from "@contentful/rich-text-types/dist/types/types";
import { Typography } from "@material-ui/core";
import BulletItem from "./BulletItem";
import ExternalLink from "./ExternalLink";

interface Props {
  content: Document;
}

const getContent = (node: Block | Inline) => (node.content[0] as any).value;

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="body1">{children}</Typography>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography variant="h1">{children}</Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography variant="body1" style={{ fontSize: 12 }}>
        {children}
      </Typography>
    ),
    [BLOCKS.UL_LIST]: (node) =>
      node.content.map((item, idx) => (
        <BulletItem key={idx} text={getContent((item as any).content[0])} />
      )),
    [INLINES.HYPERLINK]: (node, children) => (
      <ExternalLink href={node.data.uri} target="_blank">
        {children}
      </ExternalLink>
    ),
  },
};

const ContentfulRichText: FC<Props> = ({ content }) => (
  <>{documentToReactComponents(content, options)}</>
);

export default ContentfulRichText;
