import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as Basket } from "../../icons/basket.svg";
import BalanceOfShopV2BarChart from "./BalanceOfShopV2BarChart";
import { FC, useContext, useMemo } from "react";
import { FoodGroup } from "../../models";
import useHouseholdBenchmarkServings from "../../hooks/useHouseholdBenchmarkServings";
import { SelectedTimeFrameContext } from "../SelectedTimeFrameProvider";
import { TimeFrameDuration } from "../../constants";
import { isEmptyServings } from "../../services/servings";
import useHousehold from "../../hooks/useHousehold";
import useServingsUntilYesterday from "../../hooks/useServingsUntilYesterday";
import BalanceOfShopV2QuickInsights from "./BalanceOfShopV2QuickInsights";
import BalanceOfShopV2Explainer from "./BalanceOfShopV2Explainer";
import { COLOR_GREYSCALE } from "../../theme";
import BalanceOfShopHouseholdEmptyServing from "./BalanceOfShopHouseholdEmptyServing";
import { ConfettiContext } from "../providers/ConfettiProvider";
import BalanceOfShopV2DietaryPreference from "./BalanceOfShopV2DietaryPreference";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

export interface ServingData {
  foodGroup: FoodGroup;
  benchmarkServings: number;
  servingsPurchased: number;
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  cardWrapper: {
    background: "white",
    position: "relative",
    padding: "16px",
    borderRadius: "12px",
    [breakpoints.up("md")]: {
      marginTop: "16px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      padding: "24px",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    marginBottom: 10,
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    paddingBottom: 10,
    borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    [breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      marginBottom: 8,
      paddingBottom: 8,
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 30,
    borderRadius: 40,
    marginRight: 8,
  },
  description: {
    fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[700],
    marginTop: 0,
    marginBottom: "24px",
    [breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      marginBottom: "32px",
    },
  },
  canvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 99,
    pointerEvents: "none",
  },
}));

const BalanceOfShopV2: FC = () => {
  const classes = useStyles();
  const emptyDefault = 0;
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const { servingsPurchased, loadingServings } = useServingsUntilYesterday(
    TimeFrameDuration[timeFrame]
  );
  const householdBenchmarkServings = useHouseholdBenchmarkServings(
    TimeFrameDuration[timeFrame]
  );
  const { household } = useHousehold();
  const hideDP = useHideDietaryPreferences();

  const householdNotEmpty = useMemo(() => household.length > 0, [household]);
  const emptyServings = useMemo(() => {
    return (
      !householdNotEmpty ||
      (!!servingsPurchased && isEmptyServings(servingsPurchased))
    );
  }, [householdNotEmpty, servingsPurchased]);

  const { householdServingsData, sortedBelowBenchmark } = useMemo(() => {
    const householdServingsData = [
      {
        foodGroup: FoodGroup.Vegetables,
        benchmarkServings: emptyServings
          ? emptyDefault
          : householdBenchmarkServings.vegetables,
        servingsPurchased: servingsPurchased.vegetables,
      },
      {
        foodGroup: FoodGroup.Fruit,
        benchmarkServings: emptyServings
          ? emptyDefault
          : householdBenchmarkServings.fruit,
        servingsPurchased: servingsPurchased.fruit,
      },
      {
        foodGroup: FoodGroup.Protein,
        benchmarkServings: emptyServings
          ? emptyDefault
          : householdBenchmarkServings.protein,
        servingsPurchased: servingsPurchased.protein,
      },
      {
        foodGroup: FoodGroup.Grains,
        benchmarkServings: emptyServings
          ? emptyDefault
          : householdBenchmarkServings.grains,
        servingsPurchased: servingsPurchased.grains,
      },
      {
        foodGroup: FoodGroup.Dairy,
        benchmarkServings: emptyServings
          ? emptyDefault
          : householdBenchmarkServings.dairy,
        servingsPurchased: servingsPurchased.dairy,
      },
    ];

    const servingsWithPercentage = householdServingsData.map((data) => {
      return {
        ...data,
        percentage: Math.ceil(
          (data.servingsPurchased / data.benchmarkServings) * 100
        ),
      };
    });

    const sortedBelowBenchmark = servingsWithPercentage
      .filter((data) => data.percentage < 100)
      .sort((a, b) => a.percentage - b.percentage);

    return {
      householdServingsData,
      sortedBelowBenchmark,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servingsPurchased]);

  const { balanceOfShopConfettiRef } = useContext(ConfettiContext);

  return (
    <div className={classes.cardWrapper}>
      <canvas className={classes.canvas} ref={balanceOfShopConfettiRef} />
      <Typography variant="h2" component="h1" className={classes.header}>
        <span className={classes.icon}>
          <Basket />
        </span>
        <span style={{ display: "inline-block", marginRight: 4 }}>
          Balance of food groups
        </span>
        <BalanceOfShopV2Explainer />
      </Typography>
      <p className={classes.description}>
        How many serves you have, compared to the recommended amount (100%).
      </p>
      <BalanceOfShopV2BarChart
        householdServingsData={householdServingsData}
        householdNotEmpty={householdNotEmpty}
        emptyServings={emptyServings}
        timeFrame={timeFrame}
        loadingServings={loadingServings}
      />
      {!(emptyServings && householdNotEmpty) && !loadingServings && (
        <BalanceOfShopV2QuickInsights
          sortedBelowBenchmark={sortedBelowBenchmark}
        />
      )}
      {emptyServings && householdNotEmpty && !loadingServings && (
        <BalanceOfShopHouseholdEmptyServing />
      )}

      {!hideDP && <BalanceOfShopV2DietaryPreference />}
    </div>
  );
};

export default BalanceOfShopV2;
