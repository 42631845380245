import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { AgeRange, Gender, HealthCondition } from "../../models";
import { listHealthConditionOptions } from "../../services/contentful/healthconditions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FormErrors } from "../ConfigToolbarV2/HouseholdAddFormV2";
import { COLOR_GREYSCALE } from "../../theme";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_HOUSEHOLD_EVENT } from "../../events";

interface HealthConditionsFieldProps {
  formErrors: FormErrors;
  selectedAgeRange?: AgeRange;
  selectedGender?: Gender;
  values: HealthCondition[];
  onConditionsChange: (selected: HealthCondition[]) => void;
  isOnboarding?: boolean;
}

const useStyles = makeStyles(({ typography }) => ({
  fieldset: {
    border: 0,
    padding: 0,
    margin: 0,
    marginTop: "16px",
  },
  fieldsetLegend: {
    ...typography.h3,
    marginBottom: 0,
  },
  divider: {
    marginTop: "16px",
    marginBottom: "16px",
    border: "1px solid #E1E1E1",
  },
  healthConditionFormControlLabel: {
    "& .MuiTypography-root": {
      margin: 0,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
    },
  },
  consentFormControlLabel: {
    "& .MuiTypography-root": {
      margin: 0,
    },
  },
  consentCopy: {
    margin: 0,
    "& > li": {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[900],
    },
  },
}));

const HealthConditionsField: FC<HealthConditionsFieldProps> = ({
  selectedAgeRange,
  selectedGender,
  values,
  onConditionsChange,
  isOnboarding = false,
}) => {
  const classes = useStyles();
  const { track } = useEvents();

  const [options, setOptions] = useState<HealthCondition[]>([]);
  useEffect(() => {
    const initOptions = async () => {
      setOptions(
        selectedAgeRange && selectedGender
          ? await listHealthConditionOptions(selectedAgeRange, selectedGender)
          : []
      );
    };

    initOptions();
  }, [selectedAgeRange, selectedGender]);
  const handleChange =
    (condition: HealthCondition) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let selected: HealthCondition[];

      const index = values.findIndex((val) => val.id === condition.id);
      const containsHealthCondition = index >= 0;

      if (checked && !containsHealthCondition) {
        selected = [...values, condition];
      } else if (!checked && containsHealthCondition) {
        selected = [...values.slice(0, index), ...values.slice(index + 1)];
      } else {
        selected = [...values];
      }

      if (isOnboarding && checked) {
        track(
          condition.name === "Breastfeeding"
            ? ONBOARDING_HOUSEHOLD_EVENT.BREASTFEEDING_TICK
            : ONBOARDING_HOUSEHOLD_EVENT.PREGNANT_TICK
        );
      }
      onConditionsChange(selected);
    };

  return options.length ? (
    <>
      <fieldset className={classes.fieldset}>
        <FormGroup>
          {options.map((condition) => {
            const selected = Boolean(values.find((v) => v.id === condition.id));
            return (
              <FormControlLabel
                className={classes.healthConditionFormControlLabel}
                control={
                  <Checkbox
                    data-cs-mask=""
                    inputProps={{
                      tabIndex: 0,
                    }}
                    value={condition}
                    checked={selected}
                    onChange={handleChange(condition)}
                    name="healthConditions"
                    color="primary"
                  />
                }
                label={condition.name}
              />
            );
          })}
        </FormGroup>
      </fieldset>
    </>
  ) : null;
};

export default HealthConditionsField;
