import {
  Drawer,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Dispatch, FC, SetStateAction, useContext, useEffect } from "react";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import Close from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  backButtonAction?: () => void;
  contentContainerExtended?: boolean;
  fullHeight?: boolean;
  customTitle?: string;
}

export const SidebarSettingsContent: FC<Props & { showBackButton: boolean }> = ({
  children,
  setSidebarOpen,
  backButtonAction,
  contentContainerExtended = false,
  showBackButton,
  fullHeight = false,
  customTitle,
}) => {
  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      width: "100%",
      backgroundColor: "#FAF5EC",
      height: fullHeight ? "100vh" : "fit-content",
      overflowX: "hidden",
      overflowY: "auto",
      position: "relative",
      [breakpoints.up("md")]: {
        width: "391px",
        height: fullHeight ? "100vh" : "100%",
      },
    },
    greenCircle: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      overflow: "hidden",
      backgroundColor: DARK_GREEN,
      borderRadius: "1200px",
      bottom: "calc(100% - 138px)",
      height: "1200px",
      width: "1200px",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    sidebarHeader: {
      height: "86px",
      display: "flex",
      padding: "30px 8px 0px",
      boxSizing: "border-box",
      position: "relative",
      marginBottom: "32px",
      justifyContent: "space-between",
      alignItems: "center",
      [breakpoints.up(375)]: {
        padding: "30px 16px 0px",
      },
      [breakpoints.up("md")]: {
        height: "auto",
        padding: "32px 24px",
        boxSizing: "border-box",
        marginBottom: 0,
      },
    },
    icon: {
      color: "white",
      height: "24px",
      width: "24px",
    },
    iconButton: {
      padding: 0,
      marginTop: "16px",
      display: showBackButton ? "inline-block" : "none",
      [breakpoints.up("md")]: {
        marginTop: 0,
      },
      "& svg": {
        [breakpoints.up("md")]: {
          width: "28px",
          height: "28px",
        },
      },
      "& svg path": {
        [breakpoints.up("md")]: {
          fill: COLOR_GREYSCALE[900],
        },
      },
    },
    closeButton: {
      display: "flex",
      width: "0px",
      opacity: 0,
      pointerEvents: "none",
      [breakpoints.up("md")]: {
        width: "auto",
        padding: 0,
        marginLeft: "8px",
        pointerEvents: "all",
        opacity: 1,
      },

      "& path": {
        fill: COLOR_GREYSCALE[700],
      },
    },
    title: {
      color: "#F6EFDF",
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      textAlign: "center",
      margin: 0,
      fontSize: "22px",
      lineHeight: "28px",
      width: "218px",
      [breakpoints.up("md")]: {
        fontFamily: '"Gilroy-Bold"',
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "32px",
        width: "311px",
        color: DARK_GREEN,
        marginLeft: 0,
      },
    },
    sidebarContent: {
      position: "relative",
      padding: "0px 8px 16px",
      display: "flex",
      flexDirection: "column",
      rowGap: "4px",
      height: "auto",
      minHeight: contentContainerExtended ? "calc(100% - 138px)" : 0,
      [breakpoints.up(375)]: {
        padding: "0px 16px 16px",
      },
      [breakpoints.up("md")]: {
        minHeight: contentContainerExtended ? "calc(100% - 112px)" : 0,
      },
      [breakpoints.down("sm")]: {
        paddingBottom: "64px",
      },
    },
  }))();
  const { closeAllSidebar } = useContext(SettingsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <div className={classes.root}>
      <div className={classes.greenCircle}></div>
      <div className={classes.sidebarHeader}>
        <IconButton
          onClick={() =>
            backButtonAction ? backButtonAction() : setSidebarOpen(false)
          }
          className={classes.iconButton}
        >
          <ChevronLeftRoundedIcon className={classes.icon} />
        </IconButton>
        <h3 className={classes.title}>{customTitle || "Food Tracker Settings"}</h3>
        <IconButton
          tabIndex={isMobile ? -1 : 0}
          onClick={() => {
            backButtonAction ? backButtonAction() : <></>;
            closeAllSidebar();
          }}
          className={classes.closeButton}
        >
          <Close className={classes.icon} />
        </IconButton>
      </div>
      <div className={classes.sidebarContent}>{children}</div>
    </div>
  );
};

const SidebarSettings: FC<Props> = ({
  children,
  sidebarOpen,
  setSidebarOpen,
  backButtonAction,
  contentContainerExtended = false,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    if (sidebarOpen) {
      if (!isMobile && history.location.pathname === "/settings") {
        history.push("/");
      } else if (isMobile && history.location.pathname !== "/settings") {
        history.push("/settings");
      }
    }
  }, [history, isMobile, sidebarOpen]);

  if (isMobile) {
    if (!sidebarOpen) {
      return <></>;
    } else {
      return (
        <SidebarSettingsContent
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          contentContainerExtended={contentContainerExtended}
          backButtonAction={backButtonAction}
          showBackButton={isMobile}
        >
          {children}
        </SidebarSettingsContent>
      );
    }
  } else {
    return (
      <Drawer
        anchor="right"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <SidebarSettingsContent
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          contentContainerExtended={contentContainerExtended}
          backButtonAction={backButtonAction}
          showBackButton={isMobile}
        >
          {children}
        </SidebarSettingsContent>
      </Drawer>
    );
  }
};

export default SidebarSettings;
