import { FC } from "react";
import Lottie from "react-lottie";
import loadingAnimation from "../lotties/loader-success.json";

interface Props {
  size?: number;
}

const TrolleyLoader: FC<Props> = ({ size = 128 }) => {
  return (
    <Lottie
      options={{
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        loop: true,
        animationData: loadingAnimation,
        autoplay: true,
      }}
      height={size}
      width={size}
    />
  );
};

export default TrolleyLoader;
