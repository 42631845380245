import { DateTime } from "luxon";
import platform from "platform";
import { collection, doc } from "firebase/firestore";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import gtag, { install } from "ga-gtag";
import { Collections, db, batchQueue } from "./firebase";
import { isNonProdEnv } from "../utils/debug";

const GTAG_EVENT_PROP_PREFIX = "hl_";
const GTAG_MEASUREMENT_ID = process.env.REACT_APP_GTAG_MEASUREMENT_ID;

install(GTAG_MEASUREMENT_ID, {
  send_page_view: false,
  debug_mode: isNonProdEnv(),
});

export const sendGtagEvent = (
  memberId: string,
  event: string,
  props: Record<string, unknown> = {}
) => {
  gtag("event", snakeCase(event), {
    ...mapKeys(props, (_v, k) => `${GTAG_EVENT_PROP_PREFIX}${k}`),
    user_id: memberId,
    send_to: GTAG_MEASUREMENT_ID,
  });
};

export const track = (
  memberId: string,
  event: string,
  props: Record<string, unknown> = {}
) => {
  const eventRef = doc(collection(db, Collections.Events));

  batchQueue.enqueueSet(eventRef, {
    timestamp: DateTime.now().toMillis(),
    memberId,
    name: event,
    meta: getMeta(),
    properties: props,
  });

  sendGtagEvent(memberId, event, props);
};

const getMeta = () => ({
  userAgent: navigator.userAgent,
  locale: navigator.language,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  device: {
    manufacturer: platform.manufacturer,
    product: platform.product,
    architecture: platform.os?.architecture,
  },
  network: {
    type: navigator.connection?.type,
    /* @ts-ignore */
    effectiveType: navigator.connection?.effectiveType,
    /* @ts-ignore */
    downlinkSpeed: navigator.connection?.downlink,
    /* @ts-ignore */
    roundTripTime: navigator.connection?.rtt,
  },
  screen: {
    width: window.screen.width,
    height: window.screen.height,
    density: window.devicePixelRatio,
  },
  os: {
    name: platform.os?.family,
    version: platform.os?.version,
  },
  browser: {
    name: platform.name,
    version: platform.version,
    engine: platform.layout,
  },
  page: {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.origin + window.location.pathname,
  },
});
