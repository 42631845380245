import { makeStyles, Typography } from "@material-ui/core";
import { FC, useState } from "react";
import {
  InfoModalContainer,
  InfoModalDefaultButton,
} from "../../components-2/InfoModal";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../../components-2/Accordion";
import { FAQ_ACCORDION_EVENT, HOMEPAGE_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";

const BalanceOfShopV2Explainer: FC = () => {
  const { track } = useEvents();
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const faqTitles = [
    "How are serves calculated for each food group from my groceries?",
    "Who helped create the data that sits behind Food Tracker?",
    "What happens if I buy food for a special occasion or for someone else?",
    "I want to eat less processed, more wholefoods. How can this help?",
    "Is this different to tracking my macronutrients (protein, fat and carbohydrates) and micronutrients?",
    "Why doesn’t Food Tracker show calories?",
    "I noticed that some of my shopping items are not showing up in my Food Tracker. Why is this?",
  ];

  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = makeStyles(() => ({
    accordionContent: {
      margin: 0,
      "& a": {
        color: "#60A498",
      },
    },
  }))();

  return (
    <InfoModalContainer
      eventName={HOMEPAGE_EVENT.BALANCE_HELP_OPEN}
      eventCloseName={HOMEPAGE_EVENT.BALANCE_HELP_CLOSE}
      button={<InfoModalDefaultButton />}
    >
      <Typography variant="body1">
        This bar chart shows many serves of each food group you have, compared
        to the recommended amount (100%).
      </Typography>

      <Typography variant="body1">
        The main food groups come from the Australian Dietary Guidelines which
        show us the types and amount of foods to teat for optimal wellbeing.
      </Typography>

      <Typography variant="body1">
        The Balance of good groups chart shows you how many serves you’ve
        purchased from each food group, compared to the recommended amount.
        Which ones are below 100%? This will give you clues to which food group
        needs more love!
      </Typography>

      <Typography variant="body1">
        You can click on the food group name to learn more and see which items
        in your basket contribute to that group.
      </Typography>

      <Typography variant="h2" style={{ marginBottom: "32px" }}>
        FAQS
      </Typography>
      <AccordionContainer>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1", 0)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<AccordionIcon />}
          >
            How are serves calculated for each food group from my groceries?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              Your food items have been assigned to one of the five main food
              groups or sometimes foods. If a food item was made up of multiple
              ingredients (such as muesli), then the food is distributed across
              the appropriate food groups proportionally according to the
              ingredients.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2", 1)}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<AccordionIcon />}
          >
            Who helped create the data that sits behind Food Tracker?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              CSIRO nutrition researchers prepared the food database used in
              Food Tracker. The database calculates the amount of each food
              group in food products, and was prepared using an approach that
              was consistent with{" "}
              <a
                href="https://www.foodstandards.gov.au/science-data/food-composition-databases/ausnut-2011-13"
                target="_blank"
                rel="noreferrer"
              >
                published data files
              </a>{" "}
              available from the Australian Bureau of Statistics and Food
              Standard Australia and New Zealand and used in the 2011-13
              Australian National Nutrition Survey.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3", 2)}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={<AccordionIcon />}
          >
            What happens if I buy food for a special occasion or for someone
            else?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              You can choose to exclude this from your Food Tracker. To do this,
              click on the food group it belongs in and tick the "exclude" box
              next to it. This will then recalculate your totals.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleAccordionChange("panel4", 3)}
        >
          <AccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            expandIcon={<AccordionIcon />}
          >
            I want to eat less processed, more wholefoods. How can this help?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              The main food groups show us the types and amounts of foods to eat
              for optimal wellbeing. You can use Food Tracker to ensure you’re
              getting a variety of whole, unprocessed foods, in line with the
              Dietary Guidelines and portion sizes.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleAccordionChange("panel5", 4)}
        >
          <AccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            expandIcon={<AccordionIcon />}
          >
            Is this different to tracking my macronutrients (protein, fat and
            carbohydrates) and micronutrients?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              The Dietary Guidelines show us the types and amounts of foods to
              eat for optimal wellbeing, taking into account the essential macro
              and micronutrients the average person needs. If you require
              personalised advice for your individual circumstances, health
              requirements or dietary requirements, you should consult a
              healthcare professional.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleAccordionChange("panel6", 5)}
        >
          <AccordionSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
            expandIcon={<AccordionIcon />}
          >
            Why doesn’t Food Tracker show calories?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              Research suggests that focusing on healthy foods from the five
              main food groups (and the occasional treat) is far better for
              maintaining a healthy, balanced diet. You can use Food Tracker to
              see how your basket stacks up compared to your recommended serves
              of these foods. What’s more, the main food groups provide
              nutritional needs (everything from A to Z for our body to
              function) and for most people, may help with weight loss.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleAccordionChange("panel7", 6)}
        >
          <AccordionSummary
            aria-controls="panel7d-content"
            id="panel7d-header"
            expandIcon={<AccordionIcon />}
          >
            I noticed that some of my shopping items are not showing up in my
            Food Tracker. Why is this?
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className={classes.accordionContent}>
              We’ve been working hard to analyse the most common products
              available in Woolworths. In some cases, the specific item you
              purchased has not been individually analysed yet due to the
              transient nature of daily stock control. Missing an item? Log it
              with us at: tracker@healthylife.com.au
            </Typography>
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
    </InfoModalContainer>
  );
};

export default BalanceOfShopV2Explainer;
