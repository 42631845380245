import { makeStyles } from "@material-ui/core";
import { FC, useContext } from "react";
import RecipeCard from "./RecipeCard";
import useRecipes from "../../hooks/useRecipes";
import TrolleyLoader from "../../components/TrolleyLoader";
import { RECIPE_DISPLAY_LIMIT } from "../../constants";
import { RecipeContext } from "../../components/providers/RecipeProvider";
import { RecipeProps } from "../../models";

const useStyles = makeStyles(({ breakpoints }) => ({
  filterButton: {
    backgroundColor: "transparent",
    padding: 0,
    border: 0,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    color: "#1D1F20",
    cursor: "pointer",
  },
  filterIcon: {
    marginRight: "4px",
  },
  chevronIcon: {
    marginLeft: "10px",
  },
  recipes: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "8px",
    marginTop: "8px",
    [breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 1fr",
      gap: "24px 28px",
    },
  },
  clearAll: {
    color: "#007A71",
    textDecoration: "underline",
    backgroundColor: "transparent",
    border: 0,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    fontFamily: "Gilroy",
    [breakpoints.up("md")]: {
      marginRight: 32,
    },
  },
  filterButtons: {
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  filterTags: {
    padding: "4px 8px",
    backgroundColor: "#1D1F20",
    border: "solid 1px #D3D6D9",
    borderRadius: "20px",
    color: "#ffffff",
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "200px",
  },
  noRecipes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    gridColumn: "1 / span 2",
    marginTop: "32px",
  },
  loadMore: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
  },
  loadMoreBtn: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-Bold",
    fontWeight: "normal",
    padding: "12px 24px",
    backgroundColor: "#FFFFFF",
    border: "solid 2px #242629",
    color: "#1D1F20",
    borderRadius: "24px",
    cursor: "pointer",
  },
}));

const Recipes: FC = () => {
  const { isLoading, formattedRecipes } = useRecipes();
  const { filteredRecipes, recipeCounter, setRecipeCounter } =
    useContext(RecipeContext);

  const classes = useStyles();

  const handleLoadMore = async () => {
    await setRecipeCounter(recipeCounter + RECIPE_DISPLAY_LIMIT);
    const recipeItem = document.getElementById(`recipe-item-${recipeCounter}`);
    (recipeItem as HTMLElement).focus();
  };

  const filterByFoodGroups = (recipes: RecipeProps[]) => {
    let filteredRecipeByFoodGroups = recipes;
    const foodgroupFilters = filteredRecipes.filter((filter) =>
      filter.includes("foodgroup:")
    );
    const ingredientsFilters = filteredRecipes.filter((value) =>
      value.includes("ingredients:")
    );
    const mealtypeFilters = filteredRecipes.filter((value) =>
      value.includes("mealtype:")
    );

    if (foodgroupFilters.length > 0) {
      filteredRecipeByFoodGroups = filteredRecipeByFoodGroups.filter((recipe) =>
        recipe.foodGroup?.some((item) =>
          foodgroupFilters.includes(`foodgroup:${item}`)
        )
      );
    }

    if (ingredientsFilters.length > 0) {
      filteredRecipeByFoodGroups = filteredRecipeByFoodGroups.filter((recipe) =>
        recipe.ingredients?.some((item) =>
          ingredientsFilters.includes(`ingredients:${item}`)
        )
      );
    }

    if (mealtypeFilters.length > 0) {
      filteredRecipeByFoodGroups = filteredRecipeByFoodGroups.filter((recipe) =>
        recipe.mealType?.some((item) =>
          mealtypeFilters.includes(`mealtype:${item}`)
        )
      );
    }

    return filteredRecipeByFoodGroups;
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loader}>
          <TrolleyLoader />
        </div>
      ) : (
        <div className={classes.recipes}>
          {formattedRecipes.length ? (
            (filteredRecipes.length
              ? filterByFoodGroups(formattedRecipes)
              : formattedRecipes
            )
              .slice(0, recipeCounter)
              .map((recipe: any, i: number) => {
                return <RecipeCard key={recipe.id} index={i} {...recipe} />;
              })
          ) : (
            <div className={classes.noRecipes}>No recipes found.</div>
          )}
        </div>
      )}
      {(filteredRecipes.length
        ? filterByFoodGroups(formattedRecipes)
        : formattedRecipes
      ).length > recipeCounter && (
        <div className={classes.loadMore}>
          <button
            onClick={() => handleLoadMore()}
            className={classes.loadMoreBtn}
          >
            Load more results
          </button>
        </div>
      )}
    </>
  );
};

export default Recipes;
