import { Grid, makeStyles, Typography } from "@material-ui/core";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import useNutrients from "../../hooks/useNutrients";
import useEvents from "../../hooks/useEvents";
import { HEALTHIER_OPTIONS_EVENTS } from "../../constants";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import { HealthierOptionsFoodGroup, NutrientTrackerTypes } from "../../models";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import {
  FOOD_GROUP_PAGES_EVENT,
  HOMEPAGE_EVENT,
  MAIN_VS_SOMETIMES_EVENT,
  NUTRIENT_TRACKER_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../events";

SwiperCore.use([A11y]);

const HealthierOptionsCarousel = ({
  healthierOption = "all",
  forHomepage,
  filterByNutrients = null,
  source,
}: {
  healthierOption?: HealthierOptionsFoodGroup;
  forHomepage?: boolean;
  filterByNutrients?: NutrientTrackerTypes["type"] | null;
  source?: string;
}) => {
  const { purchasedItemsWithSwaps } = useHealthierOptions(healthierOption);
  const { nutrientsTopContributors } = useNutrients();
  const { track } = useEvents();
  const history = useHistory();

  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
      marginTop: spacing(2),
    },
    yourPurchasedItemsContainer: {
      "& > *": {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },
      "& .swiper": {
        overflow: "visible",
        [breakpoints.up("lg")]: {
          overflow: "hidden",
        },
      },
    },
    swiperSlide: {
      height: "184px",
      width: "136px",
      background: COLOR_GREYSCALE[50],
      borderRadius: "8px",
      cursor: "pointer",
      userSelect: "none",
      [breakpoints.up("lg")]: !forHomepage && {
        height: "auto",
      },
    },
    swiperItemContainer: {
      alignItems: "center",
      justifyContent: "center",
      padding: spacing(1),
    },
    swiperImageContainer: {
      cursor: "pointer",
      border: `0.8px solid ${COLOR_GREYSCALE[200]}`,
      borderRadius: "3.2px",
      padding: "20px",
      background: "white",
    },
    swiperImages: {
      width: "100%",
      height: "auto",
      userSelect: "none",
      pointerEvents: "none",
    },
    swiperText: {
      height: "40px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      marginTop: spacing(1),
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [breakpoints.up("lg")]: !forHomepage && {
        height: "auto",
        fontSize: "16px",
        lineHeight: "24px",
        maxHeight: "72px",
      },
    },
    swiperArrowIcon: {
      position: "absolute",
      right: "10px",
      top: "10px",
      margin: "6px",
      borderRadius: "40px",
      boxSizing: "content-box",
      background: DARK_GREEN,
      width: "16px",
      padding: "4px",
      height: "16px",
      "& > * > *": {
        fill: "white",
      },
    },
  }));
  const classes = useStyles();

  const handleTrackProductClick = () => {
    if (!forHomepage) {
      switch (source) {
        case "main-vs-sometimes":
          return MAIN_VS_SOMETIMES_EVENT.HO_PRODUCT;
        case "food-group":
          return FOOD_GROUP_PAGES_EVENT.HO_PRODUCT;
        case "sometimes-food":
          return SOMETIMES_FOOD_PAGE_EVENT.HO_PRODUCT;
        case "nutrient-tracker":
          return NUTRIENT_TRACKER_EVENT.HO_CLICKED;
      }
    }
    return HOMEPAGE_EVENT.HO_PRODUCT_CLICK;
  };

  const productClickTrack = handleTrackProductClick();

  const pushToPDP = (articleId: string) => {
    track(HEALTHIER_OPTIONS_EVENTS.CAROUSEL_PRODUCT_CLICK, { type: articleId });
    if (source === "food-group") {
      track(productClickTrack, { type: articleId, foodgroup: healthierOption });
    } else if (source === "nutrient-tracker") {
      track(productClickTrack, { type: filterByNutrients });
    } else {
      track(productClickTrack, { type: articleId });
    }
    history.push(
      `/healthier-options/pdp?back=home&articleId=${articleId}&source=${source}`
    );
  };

  const itemsWithSwaps =
    (filterByNutrients
      ? nutrientsTopContributors?.[filterByNutrients].slice(0, 5)
      : purchasedItemsWithSwaps) || [];

  const handleTrackScroll = () => {
    if (!forHomepage) {
      switch (source) {
        case "main-vs-sometimes":
          return track(MAIN_VS_SOMETIMES_EVENT.HO_SCROLL);
        case "food-group":
          return track(FOOD_GROUP_PAGES_EVENT.HO_SCROLL, {
            foodgroup: healthierOption,
          });
        case "sometimes-food":
          return track(SOMETIMES_FOOD_PAGE_EVENT.HO_SCROLL);
        case "nutrient-tracker":
          return track(NUTRIENT_TRACKER_EVENT.HO_SCROLL, {
            type: filterByNutrients,
          });
      }
    }
    return track(HOMEPAGE_EVENT.HO_SCROLL);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.yourPurchasedItemsContainer}
    >
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "0px",
          paddingTop: "16px",
        }}
      >
        <Swiper
          spaceBetween={forHomepage ? 16 : 8}
          watchSlidesProgress={true}
          slidesPerView="auto"
          allowTouchMove={true}
          breakpoints={{
            1024: {
              slidesPerView: forHomepage ? "auto" : 5,
            },
          }}
          onSlideChange={() => handleTrackScroll()}
        >
          {itemsWithSwaps.map((item, index) => {
            const hasHealthierOptions = item.healthierOptions.length >= 1;
            return (
              <SwiperSlide
                key={item.articleId}
                className={classes.swiperSlide}
                style={{
                  cursor: hasHealthierOptions ? "pointer" : "default",
                }}
              >
                <div
                  className={classes.swiperItemContainer}
                  tabIndex={hasHealthierOptions ? 0 : -1}
                  onKeyPress={(e) =>
                    hasHealthierOptions
                      ? ifEnterOrSpace(e, pushToPDP, item.articleId)
                      : null
                  }
                  onClick={() =>
                    hasHealthierOptions ? pushToPDP(item.articleId) : null
                  }
                >
                  <div
                    className={classes.swiperImageContainer}
                    style={{
                      cursor: hasHealthierOptions ? "pointer" : "default",
                    }}
                  >
                    {hasHealthierOptions && (
                      <HealthierOptionsIcon
                        className={classes.swiperArrowIcon}
                      />
                    )}
                    <img
                      src={item.imageURL ?? ""}
                      alt={item.description + " Photo"}
                      className={classes.swiperImages}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h3"
                      component="h2"
                      className={classes.swiperText}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default HealthierOptionsCarousel;
