import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as FoodtrackerLogo } from "../icons/foodtracker-logo-optout.svg";
import { ReactComponent as BarBackground } from "../icons/bar-background.svg";
import { COLOR_GREYSCALE, DARK_GREEN, WHITE } from "../theme";
import ExternalLink from "../components/ExternalLink";

const OptedOutPage = () => {
  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
      position: "relative",
      minWidth: "375px",
      minHeight: "100vh",
      overflow: "hidden",
      height: "fit-content",
      background: "#F6EFDF",
    },
    barBackground: {
      position: "absolute",
      zIndex: 0,
      [breakpoints.down("sm")]: {
        width: "490px",
        height: "462px",
        top: "33%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      [breakpoints.up("md")]: {
        width: "1047px",
        height: "1021px",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
    container: {
      padding: "0px 24px",
      zIndex: 2,
      height: "100%",
      minHeight: "100vh",
      alignContent: "flex-start",
      position: "relative",
      flexDirection: "column",
      [breakpoints.down("sm")]: {
        paddingTop: "144px",
        paddingBottom: "144px",
      },
      [breakpoints.up("md")]: {
        maxWidth: "600px",
        margin: "auto",
        paddingTop: "167px",
      },
    },
    welcomeHeader: {
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      margin: 0,
      color: COLOR_GREYSCALE[900],
    },
    linkText: {
      margin: 0,
      marginTop: "48px",
      fontSize: "20px",
      lineHeight: "26px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[700],
      textAlign: "center",
      [breakpoints.up("md")]: {
        marginTop: "100px",
      },
    },
    alertText: {
      color: "#DE0000",
    },
    h1: {
      position: "relative",
      margin: 0,
      marginBottom: "32px",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      color: DARK_GREEN,
    },
    body: {
      margin: 0,
      marginBottom: "32px",
      paddingBottom: "16px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
    },
    button: {
      marginTop: spacing(0),
      marginBottom: spacing(2),
      borderRadius: "12px",
      backgroundColor: "#B9E04C",
      boxShadow: "none",
      padding: "12px 24px",
      color: "#0A2822",
      "&:hover": {
        backgroundColor: "#A0C734",
        boxShadow: "none",
      },
    },
    ftLogo: {
      margin: "0 auto 48px",
    },
    hlBtn: {
      color: WHITE,
      padding: "12px 24px",
      backgroundColor: DARK_GREEN,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      textDecoration: "none !important",
      margin: "36px auto 0",
      borderRadius: "10px",
      [breakpoints.up("md")]: {
        margin: "54px auto 0",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <BarBackground className={classes.barBackground} />
      <Container style={{ padding: 0 }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"flex-start"}
          wrap={"nowrap"}
          className={classes.container}
        >
          <Grid item>
            <FoodtrackerLogo className={classes.ftLogo} />
          </Grid>
          <Grid item>
            <Typography variant="h2" component="h1" className={classes.h1}>
              You have successfully opted out of Food Tracker and all its
              communications
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.body}>
              We are sorry to see you go!
              <br />
              <br />
              If you change your mind in the future, you can come back to Food
              Tracker by linking your Everyday Rewards card and opting in.
            </Typography>
          </Grid>
          <Grid item>
            <ExternalLink
              href="https://healthylife.com.au"
              className={classes.hlBtn}
            >
              Go to Healthylife
            </ExternalLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OptedOutPage;
