import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { COLOR_GREYSCALE, COLOR_SUPPORT_SUCCESS } from "../../theme";
import LegendDot from "../LegendDot";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  woolworths: {
    color: COLOR_SUPPORT_SUCCESS[600],
    marginRight: `24px !important`,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: '"Gilroy-Bold"',
    fontWeight: "normal",
  },
  other: {
    fontFamily: '"Gilroy-Bold"',
    fontWeight: "normal",
    color: COLOR_GREYSCALE[700],
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const ShoppingAllocationLegend = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} aria-hidden={"true"}>
      <LegendDot color={COLOR_SUPPORT_SUCCESS[600]} />
      <Typography variant="body2" className={classes.woolworths}>
        Woolworths
      </Typography>
      <LegendDot color={COLOR_GREYSCALE[300]} />
      <Typography variant="body2" className={classes.other}>
        Other stores
      </Typography>
    </div>
  );
};

export default ShoppingAllocationLegend;
