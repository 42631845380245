import { withStyles } from "@material-ui/core/styles";
import { TableHead } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";

export const StyledTableHead = withStyles({
  root: {
    backgroundColor: "white",
    "&.MuiTableHead-root > tr > th": {
      fontFamily: "Gilroy-Semibold !important",
      fontSize: "13px !important",
      lineHeight: "20px !important",
      textAlign: "center",
      color: `${COLOR_GREYSCALE[900]}`,
      padding: "8px 12px !important",
    },
  },
})(TableHead);
