import SidebarSettings from "./SidebarSettings";
import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { useContext, useState } from "react";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { ReactComponent as HelpIcon } from "../../icons/help-circle.svg";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import ExternalLink from "../../components/ExternalLink";
import useEvents from "../../hooks/useEvents";
import { FAQ_ACCORDION_EVENT } from "../../events";
import { MemberContext } from "../../components/providers/MemberProvider";
import { FeatureFlagContext } from "../../components/providers/FeatureFlagProvider";

const SidebarHelpCentre = () => {
  const { setIsOpenSidebar, isOpenHelp, setIsOpenHelp } =
    useContext(SettingsContext);
  const { memberId } = useContext(MemberContext)! || {};
  const { featureFlags } = useContext(FeatureFlagContext);

  const { track } = useEvents();

  const [expanded, setExpanded] = useState<string | false>("faq1");
  const handleChange =
    (panel: string, index: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqs[index].title });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqs[index].title });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = makeStyles(({ breakpoints }) => ({
    container: {
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("md")]: {
        height: "100%",
        overflow: "auto",
      },
    },
    heading: {
      padding: "16px 24px",
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
      display: "flex",
      columnGap: "8px",
    },
    headingText: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    innerContent: {
      display: "flex",
      flexDirection: "column",
      padding: "16px 24px",
      boxSizing: "border-box",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    title: {
      margin: 0,
      marginBottom: "16px",
      color: COLOR_GREYSCALE[900],
    },
    button: {
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      margin: "16px",
      boxSizing: "border-box",
    },
    faqHeader: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      color: COLOR_GREYSCALE[900],
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    faqContent: {
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[700],
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      "& a": {
        color: "#458378",
      },
    },
  }))();

  const faqs = [
    {
      title:
        "How is Food Tracker different to other apps that track food intake?",
      content:
        "<p>Food Tracker is effortless compared to other food tracker apps! While most food intake apps require you to manually enter your food consumption, all you have to is shop and scan your Everyday Rewards Card, then Food Tracker will do the rest.</p><p>Food Tracker analyses your Woolworths grocery shopping against the Australian Dietary Guidelines to make it easy for you.</p>",
    },
    {
      title:
        "Why can't I track my calories to follow a diet plan on Food Tracker?",
      content:
        "<p>Research suggests that focusing on a healthy, balanced diet that incorporates the five main food groups is best for supporting health and wellbeing. Tracking your calories or following a diet plan may not always mean you're getting a variety of nutritious foods from each of the food groups.</p><p>If you require personalised advice for your individual circumstances, health requirements or dietary requirements, you should consult a healthcare professional.</p>",
    },
    {
      title:
        "What about the shopping that I've purchased from another store - is this counted?",
      content: `If you purchase items from other retailers, you can account for this in your settings under your “shopping allocation” settings. This will be reflected in Balance of your food groups but will not be the case for Nutrient Tracker or Healthier Options.`,
    },
    {
      title:
        "We have multiple Everyday Rewards Cards in our household. Can I link more than one to my Food Tracker?",
      content:
        "Currently only one Everyday Rewards Cards can be linked to each Food Tracker.",
    },
    {
      title: "The results in my Food Tracker look strange. Who can I speak to?",
      content: `Please email us to tell us! <a href="mailto:tracker@healthylife.com.au">tracker@healthylife.com.au</a>`,
    },
  ];

  if (
    featureFlags?.realTimeData?.enable?.everybody ||
    featureFlags?.realTimeData?.enable?.specificMembers?.includes(memberId)
  ) {
    faqs.unshift({
      title:
        "How long does it take for Food Tracker to update once I've shopped?",
      content:
        "Once you’ve done your shopping, you can expect your tracker to update instantly. Online shopping updates when your groceries are delivered.",
    });
  } else {
    faqs.unshift({
      title:
        "How long does it take for Food Tracker to update once I've shopped?",
      content:
        "Once you've done your shopping, you can expect your tracker to update by the next day. Please note: if you are shopping online, the date shown under your items will be the delivery date (not purchase date).",
    });
  }

  return (
    <SidebarSettings
      sidebarOpen={isOpenHelp}
      setSidebarOpen={setIsOpenHelp}
      backButtonAction={() => {
        setIsOpenHelp(false);
        setIsOpenSidebar(true);
      }}
    >
      <div className={classes.container}>
        <div className={classes.heading}>
          <HelpIcon width={24} height={24} />
          <h3 className={classes.headingText}>Help centre</h3>
        </div>
        <div className={classes.innerContent}>
          <h4 className={classes.title}>FAQs</h4>
          <AccordionContainer>
            {faqs.map((faq, i) => (
              <Accordion
                key={faq.title}
                expanded={expanded === `faq${i + 1}`}
                onChange={handleChange(`faq${i + 1}`, i)}
              >
                <AccordionSummary
                  aria-controls={`faq${i + 1}-content`}
                  id={`faq${i + 1}-header`}
                  expandIcon={<AccordionIcon />}
                  className={classes.faqHeader}
                >
                  {faq.title}
                </AccordionSummary>
                <AccordionDetails className={classes.faqContent}>
                  <div dangerouslySetInnerHTML={{ __html: faq.content }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionContainer>
          <ExternalLink
            className={classes.button}
            href="https://www.healthylife.com.au/discover/food-tracker-frequently-asked-questions"
            target="_blank"
          >
            View all FAQs
          </ExternalLink>
        </div>
      </div>
    </SidebarSettings>
  );
};

export default SidebarHelpCentre;
