import { Link, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as HealthierOptionsIcon } from "../../../icons/recipe-icon.svg";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";
import { COLOR_GREYSCALE, DARK_GREEN, LIGHTER_CHARCOAL } from "../../../theme";
import HealthierRecipesCarousel from "./HealthierRecipesCarousel";
import { useHistory } from "react-router-dom";
import { FoodGroup } from "../../../models";
import { FoodGroupLabel } from "../../../constants";
import RecipeExplainer from "../../../components-2/recipe/RecipeExplainer";
import { ifEnterOrSpace } from "../../../utils/keyboardNavigation";
import useEvents from "../../../hooks/useEvents";
import { FOOD_GROUP_PAGES_EVENT } from "../../../events";

const HealthierRecipes = ({
  iconColor = DARK_GREEN,
  foodGroup = "vegetables" as FoodGroup,
  showHeader = true,
}: {
  iconColor?: string;
  foodGroup?: FoodGroup;
  showHeader?: boolean;
}) => {
  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      marginTop: spacing(4),
      padding: "24px",
      borderRadius: 12,
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      overflow: "hidden",
      [breakpoints.up("lg")]: {
        boxShadow: "none",
        border: `solid 1px ${COLOR_GREYSCALE[200]}`,
      },
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D3D6D9",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
    },
    similarProductsText: {
      color: LIGHTER_CHARCOAL,
      letterSpacing: "0.3px",
      //mobile
      [breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
      //desktop
      [breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      [breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
      },
    },
    icon: {
      "& path": {
        fill: iconColor,
      },
    },
  }));
  const { track } = useEvents();
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {showHeader && (
        <Typography variant="h3" component="h1" className={classes.headerTitle}>
          <div className={classes.titleContainer}>
            <HealthierOptionsIcon className={classes.icon} />
            <Link
              tabIndex={0}
              onKeyPress={(e) =>
                ifEnterOrSpace(e, history.push, `/recipes?source=${foodGroup}`)
              }
              onClick={() => history.push(`/recipes?source=${foodGroup}`)}
              style={{ textDecoration: "none", color: COLOR_GREYSCALE[800] }}
            >
              <div className={classes.title}>Healthier Recipes</div>
            </Link>
            <RecipeExplainer />
          </div>
          <Link
            tabIndex={0}
            onKeyPress={(e) =>
              ifEnterOrSpace(e, () => {
                track(FOOD_GROUP_PAGES_EVENT.RECIPES_OPEN, {
                  foodgroup: foodGroup,
                });
                history.push(`/recipes?source=${foodGroup}`);
              })
            }
            onClick={() => {
              track(FOOD_GROUP_PAGES_EVENT.RECIPES_OPEN, {
                foodgroup: foodGroup,
              });
              history.push(`/recipes?source=${foodGroup}`);
            }}
            style={{
              textDecoration: "none",
              color: COLOR_GREYSCALE[800],
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChevronRight />
          </Link>
        </Typography>
      )}

      <Typography variant="body2" className={classes.similarProductsText}>
        We’ve made eating healthier easier with this curated range of recipes
        from Woolworths, featuring {FoodGroupLabel[foodGroup!]}.
        {!showHeader && (
          <Link
            tabIndex={0}
            onKeyPress={(e) =>
              ifEnterOrSpace(e, history.push, `/recipes?source=${foodGroup}`)
            }
            onClick={() => history.push(`/recipes?source=${foodGroup}`)}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: COLOR_GREYSCALE[700],
              display: "flex",
              alignItems: "center",
              fontFamily: "Gilroy-Bold",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.2px",
              gap: 8,
            }}
          >
            View all <ChevronRight />
          </Link>
        )}
      </Typography>

      <HealthierRecipesCarousel foodGroup={foodGroup!} />
    </div>
  );
};

export default HealthierRecipes;
