import { FC, useContext, useEffect, useState } from "react";
import {
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ExternalLink from "../../components/ExternalLink";
import { BasketArticle, HealthierOption } from "../../models";

import useHealthierOptions from "../../hooks/useHealthierOptions";
import useEvents from "../../hooks/useEvents";
import { HEALTHIER_OPTIONS_EVENTS } from "../../constants";
import { HealthierOptionsExplainer } from "./HealthierOptionsExplainer";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "../../icons/external-link.svg";
import {
  HEALTHIER_OPTIONS_ICONS,
  HealthierOptionsIconKey,
} from "./HealthierOptionsIconKey";
import { COLOR_GREYSCALE } from "../../theme";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import {
  FOOD_GROUP_PAGES_EVENT,
  HEALTHIER_OPTIONS_PDP_EVENT,
  HEALTHIER_OPTIONS_PLP_EVENT,
  HOMEPAGE_EVENT,
  NUTRIENT_TRACKER_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../events";
import { DietaryPreferencesContext } from "../../components/providers/DietaryPreferencesProvider";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

const useStyles = makeStyles(({ breakpoints }) => ({
  mainImageAndTextContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    //mobile
    [breakpoints.down("sm")]: {
      padding: "16px",
      paddingBottom: 0,
    },
    //desktop
    [breakpoints.up("md")]: {
      padding: "0px 16px",
    },
  },
  mainTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  mainImageText: {
    margin: "0",
    fontFamily: '"Gilroy-SemiBold"',
    fontWeight: "normal",
    color: "#000000",
    //mobile
    [breakpoints.down("sm")]: {
      size: "16px",
      lineHeight: "24px",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  mainImage: {
    height: "123px",
  },
  mainImageContainer: {
    height: "123px",
    width: "auto",
    paddingRight: "16px",
  },
  divider: {
    backgroundColor: "#D3D6D9",
    height: "1px",
    width: "100%",
    [breakpoints.down("sm")]: {
      margin: "16px 0",
    },
    [breakpoints.up("md")]: {
      margin: "24px 0",
    },
  },
  iconKeyIcon: {
    marginRight: "16px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "inline-block",
    margin: "0 4px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    //mobile
    [breakpoints.down("sm")]: {
      lineHeight: "24px",
      fontSize: "16px",
      letterSpacing: "0.1px",
    },
    //desktop
    [breakpoints.up("md")]: {
      lineHeight: "36px",
      fontSize: "28px",
    },
  },
  contentContainer: {
    [breakpoints.down("sm")]: {
      padding: "8px 16px 16px 16px",
      "& > * + *": {
        marginTop: "16px",
      },
    },
  },
  productContainer: {
    cursor: "pointer",
    display: "flex",
    [breakpoints.down("sm")]: {
      marginBottom: "4px",
    },
  },
  healthierOptionsContainer: {
    [breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(299px, 1fr))",
      gap: "24px",
    },
  },
  textAndImageContainer: {
    borderRadius: "8px 0px 0px 8px",
    background: COLOR_GREYSCALE[50],
    display: "flex",
    alignItems: "center",
    padding: "8px",
    marginRight: "3px",
    width: "80%",
    overflow: "hidden",
  },
  iconContainer: {
    background: COLOR_GREYSCALE[50],
    borderRadius: "0px 8px 8px 0px",
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
  imageContainer: {
    border: "1px solid #D3D6D9",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "3px",
    background: "white",
  },
  image: {
    display: "block",
    width: "auto",
    height: "auto",
    maxWidth: "42px",
    maxHeight: "42px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  iconExplainer: {
    background: "white",
    letterSpacing: "0.3px",
    //mobile
    [breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginTop: "8px",
    },
  },
  itemNameAndIconContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "8px",
    rowGap: "4px",
    width: "75%",
  },
  itemIconContainer: {},
  itemNameTextContainer: {
    width: "100%",
  },
  itemNameText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 0,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    //mobile
    [breakpoints.down("sm")]: {
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontFamily: '"Gilroy"',
      color: COLOR_GREYSCALE[900],
    },
  },
  container: {
    position: "relative",
    background: "white",
    borderRadius: "12px",
    [breakpoints.down("sm")]: {
      marginTop: "32px",
    },
    [breakpoints.up("md")]: {
      padding: "40px",
      marginTop: "40px",
    },
  },
  HealthierOptionsProductContainer: {
    [breakpoints.down("sm")]: {
      height: "fit-content",
    },
    [breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  HealthierOptionsProductDivider: {
    margin: "0px 24px",
  },
  desktopContainer: {
    [breakpoints.up("md")]: {
      marginTop: "32px",
      padding: "24px 40px",
      border: `1px solid ${COLOR_GREYSCALE[200]}`,
      borderRadius: "12px",
    },
  },
  itemDfIconContainer: {
    display: "flex",
    columnGap: "8px",
    marginLeft: "auto",
  },
  itemTitleDfIconContainer: {
    display: "flex",
    columnGap: "8px",
    marginBottom: "8px",
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
  },
  dpToggleBtn: {
    padding: 0,
    border: 0,
    backgroundColor: "transparent",
    textDecoration: "underline",
    letterSpacing: "0.3px",
    cursor: "pointer",
    color: COLOR_GREYSCALE[800],
    verticalAlign: "baseline",
    //mobile
    [breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}));

interface HealthierOptionsProductProps {
  articleId: string;
  healthierOption: BasketArticle;
}

const HealthierOptionsProduct = ({
  healthierOption,
  articleId,
}: HealthierOptionsProductProps) => {
  const classes = useStyles();
  const history = useHistory();
  const hideDP = useHideDietaryPreferences();
  const { getHealthierOptionDietaryPreferencesIcons } = useContext(
    DietaryPreferencesContext
  );
  if (!healthierOption) history.push("/healthier-options/plp");
  return (
    <ExternalLink
      href={"https://www.woolworths.com.au/shop/productdetails/" + articleId}
      className={classes.mainImageAndTextContainer}
    >
      <div aria-hidden className={classes.mainImageContainer}>
        <img
          src={healthierOption?.imageURL ?? ""}
          alt={"Image of " + healthierOption?.description}
          className={classes.mainImage}
        />
      </div>
      <div className={classes.mainTextContainer}>
        {!hideDP && healthierOption && (
          <div className={classes.itemTitleDfIconContainer}>
            {getHealthierOptionDietaryPreferencesIcons(healthierOption)}
          </div>
        )}
        <Typography
          variant="h3"
          component="h1"
          className={classes.mainImageText}
        >
          {healthierOption?.description}
        </Typography>
      </div>
    </ExternalLink>
  );
};

interface HealthierOptionsProductListProps {
  healthierOption: BasketArticle;
}

const HealthierOptionsProductList: FC<HealthierOptionsProductListProps> = ({
  healthierOption,
}) => {
  const classes = useStyles();
  const { track } = useEvents();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get("source");
  const { getHealthierOptionDietaryPreferencesIcons } = useContext(
    DietaryPreferencesContext
  );
  const { sortByDietaryPreference } = useHealthierOptions();
  const hideDP = useHideDietaryPreferences();

  const handlePDPEvent = () => {
    switch (source) {
      case "nutrient-tracker":
        return track(NUTRIENT_TRACKER_EVENT.PDP);
      case "ho-plp":
        return track(HEALTHIER_OPTIONS_PLP_EVENT.PDP);
      case "sometimes-food":
        return track(SOMETIMES_FOOD_PAGE_EVENT.PDP);
      case "food-group":
        return track(FOOD_GROUP_PAGES_EVENT.PDP);
      case "homepage":
        return track(HOMEPAGE_EVENT.PDP);
      default:
        return;
    }
  };

  const goToProductPDP = (articleId: string) => {
    track(HEALTHIER_OPTIONS_PDP_EVENT.HO_PRODUCT_CLICK, { type: articleId });
    handlePDPEvent();
    window.open(
      "https://www.woolworths.com.au/shop/productdetails/" + articleId,
      "_blank"
    );
  };

  const healthierOptionSwaps =
    (sortByDietaryPreference(
      healthierOption?.healthierOptions
    ) as HealthierOption[]) || [];
  return (
    <div className={classes.healthierOptionsContainer}>
      {healthierOptionSwaps.map(
        (article: HealthierOption, index: number, row: string | any[]) => (
          <div
            key={index}
            className={classes.productContainer}
            onClick={() => goToProductPDP(article.articleId)}
            tabIndex={0}
            onKeyPress={(e) =>
              ifEnterOrSpace(e, goToProductPDP, article.articleId)
            }
          >
            <div className={classes.textAndImageContainer}>
              <div className={classes.imageContainer} aria-hidden>
                <img
                  src={article.imageURL?.replace("/large/", "/medium/")}
                  onError={(e) => {
                    e.currentTarget.src = article.imageURL ?? "";
                  }}
                  alt={article.description}
                  className={classes.image}
                />
              </div>
              <div className={classes.itemNameAndIconContainer}>
                <div className={classes.icons}>
                  {HEALTHIER_OPTIONS_ICONS.filter(
                    ({ id }) =>
                      article.healthyAttributes[
                        id as keyof typeof article.healthyAttributes
                      ]
                  ).length > 0 && (
                    <div className={classes.itemIconContainer}>
                      {HEALTHIER_OPTIONS_ICONS.filter(
                        ({ id }) =>
                          article.healthyAttributes[
                            id as keyof typeof article.healthyAttributes
                          ]
                      ).map(({ Icon, name }) => (
                        <Icon
                          className={classes.iconKeyIcon}
                          aria-label={name}
                        />
                      ))}
                    </div>
                  )}
                  {!hideDP && (
                    <div className={classes.itemDfIconContainer}>
                      {getHealthierOptionDietaryPreferencesIcons(article)}
                    </div>
                  )}
                </div>
                <div className={classes.itemNameTextContainer}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.itemNameText}
                  >
                    {article.description}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.iconContainer}>
              <ExternalLinkIcon className={classes.icon} />
            </div>
          </div>
        )
      )}
    </div>
  );
};

const HealthierOptionsProductDisplayPageComp = () => {
  const classes = useStyles();
  const { setIsOpenPersonalisation } = useContext(SettingsContext);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const articleId = query.get("articleId");
  const { purchasedItemsWithSwaps } = useHealthierOptions("all");
  const healthierOption = purchasedItemsWithSwaps.filter(
    (option: { articleId: string }) => option.articleId === articleId
  )[0] as BasketArticle;
  const swapIds = healthierOption?.healthierOptions.map(
    (article) => article.articleId
  );

  const { track } = useEvents();
  const [viewTracked, setViewTracked] = useState(false);
  useEffect(() => {
    if (viewTracked || !healthierOption) return;
    const eventProps = {
      purchasedProduct: parseFloat(articleId!),
      swapsShownToUser: swapIds || [],
    };

    track(HEALTHIER_OPTIONS_EVENTS.PDP_PAGE_VIEW, eventProps);
    setViewTracked(true);
  }, [track, viewTracked, articleId, swapIds, healthierOption]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hideDP = useHideDietaryPreferences();

  return (
    <div className={classes.container}>
      {!isMobile && (
        <>
          <div className={classes.titleContainer}>
            <HealthierOptionsIcon />
            <Typography variant="h1" className={classes.title}>
              Healthier Options
            </Typography>
            <HealthierOptionsExplainer source="healthier-options-pdp" />
          </div>
          {hideDP ? (
            <Typography variant="body2" className={classes.iconExplainer}>
              Healthier Options does not take into account your individual
              circumstances, health requirements or specific dietary
              requirements.
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.iconExplainer}>
              Healthier Options factors in your selected{" "}
              <button
                className={classes.dpToggleBtn}
                onClick={() => setIsOpenPersonalisation("dietary")}
              >
                Dietary Preferences
              </button>
              . Look out for the corresponding logo on the products below to
              understand if they're right for you ie VE, V, GF etc
            </Typography>
          )}
        </>
      )}
      <div className={classes.desktopContainer}>
        <div className={classes.HealthierOptionsProductContainer}>
          <HealthierOptionsProduct
            articleId={articleId!}
            healthierOption={healthierOption}
          />
          {!isMobile && (
            <>
              <Divider
                className={classes.HealthierOptionsProductDivider}
                orientation="vertical"
                flexItem
                aria-hidden
              />
              <HealthierOptionsIconKey />
            </>
          )}
        </div>
        <Divider className={classes.divider} aria-hidden />
        <div className={classes.contentContainer}>
          {isMobile && (
            <>
              <div className={classes.titleContainer}>
                <HealthierOptionsIcon />
                <Typography variant="h1" className={classes.title}>
                  Healthier Options
                </Typography>
                <HealthierOptionsExplainer source="healthier-options-pdp" />
              </div>
              {hideDP ? (
                <Typography variant="body2" className={classes.iconExplainer}>
                  Healthier Options does not take into account your individual
                  circumstances, health requirements or specific dietary
                  requirements.
                </Typography>
              ) : (
                <Typography variant="body2" className={classes.iconExplainer}>
                  Healthier Options factors in your selected{" "}
                  <button
                    className={classes.dpToggleBtn}
                    onClick={() => setIsOpenPersonalisation("dietary")}
                  >
                    Dietary Preferences
                  </button>
                  . Look out for the corresponding logo on the products below to
                  understand if they're right for you ie VE, V, GF etc
                </Typography>
              )}
              <HealthierOptionsIconKey />
            </>
          )}
          <HealthierOptionsProductList healthierOption={healthierOption} />
        </div>
      </div>
    </div>
  );
};

export default HealthierOptionsProductDisplayPageComp;
