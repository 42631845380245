import {
  AccordionDetails,
  AccordionSummary,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useFetchFoodGroupPage from "../hooks/useFetchFoodGroupPage";
import { SOMETIMES_FOOD } from "../models";
import CenteredLoadingIndicator from "../components/CenteredLoadingIndicator";
import useEvents from "../hooks/useEvents";
import { useContext, useEffect, useState } from "react";
import { PageLayout } from "../components-2/page-layout";
import AboutFoodGroup from "../components/food-groups/about-foodgroup/AboutFoodGroup";
import BreakSeparator from "../components/BreakSeparator";
import TimeFrameTabs from "../components/TimeFrameTabs";
import { COLOR_GREYSCALE, WHITE } from "../theme";
import RecommendedServesExplainer from "../components/food-groups/recommended-serves/RecommendedServesExplainer";
import { ReactComponent as SometimesFoodIcon } from "../icons/sometimes-food-v2.svg";
import { ReactComponent as HealthierOptionsIcon } from "../icons/healthier-options-icon.svg";
import HealthyInspiration from "../components/healthy-inspiration/HealthyInspiration";
import HealthierOptions from "../components-2/healthier-options";
import { HealthierOptionsExplainer } from "../components-2/healthier-options/HealthierOptionsExplainer";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionContainer,
  AccordionIcon,
} from "../components-2/Accordion";
import TimeframeBarChart from "../components/food-groups/timeframe-bar-chart/TimeframeBarChart";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import SometimesFoodBasketDetails from "../components/sometimes-food-basket-details/SometimesFoodBasketDetails";
import SometimesFoodsBreakdown from "../components/sometimes-foods-breakdown/SometimesFoodsBreakdown";
import { SOMETIMES_FOOD_PAGE_EVENT } from "../events";

const SometimesFoodsPage = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    dataChartContainer: {
      marginBottom: "32px",
      backgroundColor: WHITE,
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("lg")]: {
        boxShadow: "none",
        border: `solid 1px ${COLOR_GREYSCALE[200]}`,
      },
    },
    sectionContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      [breakpoints.up("lg")]: {
        padding: "24px",
        gridTemplateColumns: "1fr 1fr",
      },
    },
    sections: {
      padding: "16px",
      "&.recommended-serves, &.barchart": {
        borderBottom: "solid 1px #C5C5C5",
        [breakpoints.up("lg")]: {
          gridColumn: "span 1",
          borderBottom: "none",
        },
      },
      "&.recommended-serves": {
        [breakpoints.up("lg")]: {
          paddingRight: "32px",
        },
      },
      "&.barchart": {
        [breakpoints.up("lg")]: {
          borderLeft: "solid 1px #C5C5C5",
          paddingLeft: "32px",
        },
      },
      "&.accordions": {
        padding: 0,
      },
    },
    accordionHeader: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      padding: 0,
      margin: 0,
      minHeight: "auto",
      transition: "all .3s ease-in-out",

      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "auto",
        paddingBottom: 16,
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiIconButton-root": {
        padding: 0,
        marginRight: 0,
      },
    },
    accordionContent: {
      padding: 0,
      marginTop: "8px",
    },
    mainContainer: {
      marginTop: 48,
      paddingBottom: 32,
      position: "relative",
      [breakpoints.up("lg")]: {
        marginTop: 0,
        paddingBottom: 0,
      },
    },
    desktopContainer: {
      [breakpoints.up("lg")]: {
        backgroundColor: WHITE,
        padding: "40px",
        marginTop: "40px",
        borderRadius: "12px",
      },
    },
    desktopTitleContainer: {
      marginBottom: 32,
    },
    titleHeader: {
      display: "flex",
      alignItems: "center",
      margin: 0,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "28px",
      lineHeight: "36px",
      color: COLOR_GREYSCALE[900],
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      borderRadius: 40,
      marginRight: 8,
      fill: "#FFD60A",
      "& svg": {
        width: 30,
        height: 30,
      },
    },
    desktopComponentContainer: {
      [breakpoints.up("lg")]: {
        paddingLeft: "48px",
        paddingRight: "48px",
        marginBottom: "56px",
      },
    },
  }));
  const classes = useStyles();
  const { track } = useEvents();
  const [viewTracked, setViewTracked] = useState(false);
  const [expandedChart, setExpandedChart] = useState<boolean>(true);
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  const theme = useTheme();
  const isDesktopBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  const history = useHistory();

  const handleChart = () => {
    const expanded = isDesktopBreakpoint || !expandedChart;
    if (expanded) {
      track(SOMETIMES_FOOD_PAGE_EVENT.TREND_OPEN);
    } else {
      track(SOMETIMES_FOOD_PAGE_EVENT.TREND_COLLAPSE);
    }
    setExpandedChart(expanded);
  };

  useEffect(() => {
    if (viewTracked) return;
    track("Sometimes Food Page Viewed");
    track(SOMETIMES_FOOD_PAGE_EVENT.LOAD);
    setViewTracked(true);
  }, [track, viewTracked]);

  useEffect(() => {
    if (isDesktopBreakpoint) {
      setExpandedChart(true);
    }
  }, [isDesktopBreakpoint]);

  const content = useFetchFoodGroupPage(SOMETIMES_FOOD);

  const categories = [
    {
      id: "sweet_savoury_snack_foods",
      title: "Sweet & savoury foods",
      alias: "Sweet & savoury foods",
      description:
        "This group contains sweet foods like lollies, cakes, biscuits, muesli bars and ice cream, and savoury foods like savoury biscuits, pastries and crisps.",
    },
    {
      id: "sugar_sweetened_beverages",
      title: "Sugar sweetened drinks",
      alias: "Sugar sweetened drinks",
      description:
        "This group contains drinks that have added sugar, such as soft drinks, sports drinks, sweetened waters and cordial.",
    },
    {
      id: "sugar_substitute_beverages",
      title: "Sugar substitute drinks",
      alias: "Sugar substitute drinks",
      description:
        "This group contains drinks with less sugar that may also have natural or artificial sweeteners.",
    },
    {
      id: "fried_foods_prepared_meals",
      title: "Fried foods & mixed foods",
      alias: "Fried foods & mixed foods",
      description:
        "This group contains convenience foods such as ready-made-pizza, garlic bread, spring rolls and other party foods.",
    },
    {
      id: "sauces_spreads_misc_ingredients",
      title: "Sauces, spreads & miscellaneous",
      alias: "Sauces, spreads & misc.",
      description:
        "This group contains things like sweet spreads, syrups, butter, mayonnaise, sugar, salt and gravy.",
    },
    {
      id: "processed_meat",
      title: "Processed meat",
      alias: "Processed meat",
      description:
        "This group contains foods like salami, bacon, burgers, sausages and other processed meats.",
    },
  ];

  if (!content) {
    return <CenteredLoadingIndicator loading />;
  }
  const onBackClick = () => {
    track(SOMETIMES_FOOD_PAGE_EVENT.BACK);
    history.goBack();
  };
  return (
    <PageLayout
      isHomePage={false}
      title="Sometimes foods & drinks"
      circleColor="#FFD60A"
      headingTextColor={COLOR_GREYSCALE[700]}
      menuButtonColor={COLOR_GREYSCALE[700]}
      backButtonColor={COLOR_GREYSCALE[700]}
      isFoodGroupPage
      foodGroup="discretionary"
      onBackClick={onBackClick}
    >
      {isDesktopBreakpoint && (
        <>
          <AboutFoodGroup foodGroup="sometimes" />
          <BreakSeparator marginTop={40} marginBottom={40} />
          <TimeFrameTabs
            style={{ margin: 0, marginRight: 24 }}
            bgColor={COLOR_GREYSCALE[800]}
            textColorDesktop={COLOR_GREYSCALE[800]}
          />
        </>
      )}

      {!isDesktopBreakpoint && (
        <TimeFrameTabs
          style={{ marginTop: 24 }}
          bgColor={COLOR_GREYSCALE[800]}
          textColorMobile={COLOR_GREYSCALE[800]}
        />
      )}

      <div className={classes.desktopContainer}>
        <div className={classes.mainContainer}>
          {isDesktopBreakpoint && (
            <div className={classes.desktopTitleContainer}>
              <Typography
                variant="h2"
                component="h1"
                className={classes.titleHeader}
              >
                <span className={classes.titleIcon}>
                  <SometimesFoodIcon />
                </span>
                <span style={{ display: "inline-block", marginRight: 4 }}>
                  Sometimes foods breakdown
                </span>
                <RecommendedServesExplainer foodGroup="sometimes" />
              </Typography>
            </div>
          )}

          <div className={classes.desktopComponentContainer}>
            <div className={classes.dataChartContainer}>
              <div className={classes.sectionContainer}>
                <div className={`${classes.sections} recommended-serves`}>
                  <SometimesFoodsBreakdown
                    showHeader={!isDesktopBreakpoint}
                    categories={categories}
                  />
                </div>
                <div className={`${classes.sections} barchart`}>
                  <AccordionContainer>
                    <Accordion expanded={expandedChart} onChange={handleChart}>
                      <AccordionSummary
                        aria-controls="barchart-content"
                        id="barchart-header"
                        expandIcon={!isDesktopBreakpoint && <AccordionIcon />}
                        className={classes.accordionHeader}
                      >
                        Last 6 {timeFrame}s
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionContent}>
                        <TimeframeBarChart foodGroup="discretionary" />
                      </AccordionDetails>
                    </Accordion>
                  </AccordionContainer>
                </div>
              </div>
              {!isDesktopBreakpoint && (
                <div className={`${classes.sections} accordions`}>
                  <SometimesFoodBasketDetails categories={categories} />
                </div>
              )}
            </div>
          </div>

          {isDesktopBreakpoint && (
            <div className={classes.desktopComponentContainer}>
              <div className={`${classes.sections} accordions`}>
                <SometimesFoodBasketDetails categories={categories} />
              </div>
            </div>
          )}

          {!isDesktopBreakpoint && <AboutFoodGroup foodGroup="sometimes" />}

          {isDesktopBreakpoint && (
            <div className={classes.desktopTitleContainer}>
              <Typography
                variant="h3"
                component="h1"
                className={classes.titleHeader}
              >
                <HealthierOptionsIcon className={classes.titleIcon} />
                <Link
                  onClick={() => history.push("/healthier-options/plp")}
                  style={{
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                  }}
                >
                  <span style={{ display: "inline-block", marginRight: 4 }}>
                    Healthier Options
                  </span>
                </Link>
                <HealthierOptionsExplainer source="sometimes-food" />
              </Typography>
            </div>
          )}
          <div className={classes.desktopComponentContainer}>
            <HealthierOptions
              iconColor={COLOR_GREYSCALE[600]}
              foodGroup="discretionary"
              marginTop={32}
              showHeader={!isDesktopBreakpoint}
              source="sometimes-food"
            />
          </div>

          {!isDesktopBreakpoint && <BreakSeparator />}
          <HealthyInspiration
            source="sometimes"
            articles={content.relatedArticles}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default SometimesFoodsPage;
