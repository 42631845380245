import React, { FC, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { Checkbox, Table, TableBody, TableHead } from "@material-ui/core";
import { DateTime } from "luxon";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableContainer from "@material-ui/core/TableContainer";
import keyBy from "lodash/keyBy";
import {
  BasketArticle,
  BasketDay,
  FoodGroup,
  Servings100g,
} from "../../../models";
import { getDayOfMonthSuffix } from "../../../utils/dates";
import { ServesThresholdsForProductBreakdown } from "../../../constants";
import { StyledTableRow } from "../../table/StyledTableRow";
import { getUniqueBasketArticleId } from "../../../services/basketdetails";
import { SelectedTimeFrameBasketExclusionsContext } from "../../providers/SelectedTimeFrameBasketExclusionsProvider";
import ExclusionExplainer from "./ExclusionExplainer";
import { COLOR_GREYSCALE } from "../../../theme";
import useEvents from "../../../hooks/useEvents";
import { FOOD_GROUP_PAGES_EVENT } from "../../../events";

interface Props {
  basketDetails: BasketDay[];
  foodGroup: FoodGroup | "discretionary";
}

const TableCell = withStyles({
  root: {
    // all of these to override styles being applied from healthylife website
    borderRight: "none !important",
    padding: "4px 16px !important",
    borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
  },
})(MuiTableCell);

const TableContainer = withStyles({
  root: {
    paddingRight: 0,
  },
})(MuiTableContainer);

const formatDate = (date: DateTime) => {
  const day = date.get("day");
  return date.toFormat(`d'${getDayOfMonthSuffix(day)}' MMM`);
};

const useStyles = makeStyles(({ spacing }) => ({
  tableContainer: {
    marginTop: spacing(-2),
    backgroundColor: COLOR_GREYSCALE[50],
    borderRadius: "8px",
  },
  tableCellHeading: {
    borderBottom: "none!important",
  },
  excludeCheckbox: {
    padding: 0,
  },
  tableHeading: {
    marginTop: spacing(1),
    marginBottom: spacing(0.5),
    color: "#3A3D40",
    fontFamily: '"Gilroy-SemiBold"',
    fontWeight: "normal",
  },
  purchaseDate: {
    backgroundColor: "rgba(255, 255, 255, 0.60)!important",
    borderBottom: "none!important",
  },
  tableText: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
}));

const BasketDetailsTable: FC<Props> = ({ basketDetails, foodGroup }) => {
  const classes = useStyles();
  const { track } = useEvents();

  const { exclusions, addExclusion, removeExclusion } = useContext(
    SelectedTimeFrameBasketExclusionsContext
  );

  const exclusionLookup = keyBy(exclusions, getUniqueBasketArticleId);

  const toggleExclude = (article: BasketArticle, excluded: boolean) => {
    if (excluded) {
      track(FOOD_GROUP_PAGES_EVENT.ITEMS_EXCLUDE_YES, {
        article: article,
        foodGroup,
      });
      addExclusion(article);
    } else {
      track(FOOD_GROUP_PAGES_EVENT.ITEMS_EXCLUDE_NO, {
        article: article,
        foodGroup,
      });
      removeExclusion(article);
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <TableCell className={classes.tableCellHeading}>
              <Typography variant="body2" className={classes.tableHeading}>
                Items
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCellHeading} align="center">
              <Typography variant="body2" className={classes.tableHeading}>
                Serves
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCellHeading} align="center">
              <Typography
                variant="body2"
                className={classes.tableHeading}
                style={{ minWidth: 80 }}
              >
                Exclude <ExclusionExplainer foodGroup={foodGroup} />
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {basketDetails.map(({ purchaseDate, articles }) => (
            <React.Fragment key={purchaseDate}>
              <StyledTableRow>
                <TableCell className={classes.purchaseDate}>
                  <Typography
                    variant="subtitle1"
                    component={"h3"}
                    style={{ color: "#585C60", textTransform: "uppercase" }}
                  >
                    {formatDate(DateTime.fromISO(purchaseDate))}
                  </Typography>
                </TableCell>
                <TableCell className={classes.purchaseDate} />
                <TableCell className={classes.purchaseDate} />
              </StyledTableRow>
              {articles
                .filter(
                  ({ [(foodGroup + "100g") as keyof Servings100g]: serves }) =>
                    foodGroup === "discretionary"
                      ? serves > 0
                      : serves >= ServesThresholdsForProductBreakdown[foodGroup]
                )
                .map((article, i) => {
                  const id = getUniqueBasketArticleId(article);
                  return (
                    <StyledTableRow key={id + i}>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          component={"h4"}
                          className={classes.tableText}
                        >
                          {article.quantity}x&nbsp;&nbsp;
                          {article.description}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle1"
                          component={"h4"}
                          className={classes.tableText}
                          style={{ fontFamily: "Gilroy" }}
                        >
                          {article[foodGroup].toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          size="small"
                          className={classes.excludeCheckbox}
                          // style={{ marginRight: smallScreen ? 0 : 72 }}
                          checked={!!exclusionLookup[id]}
                          onChange={(e) =>
                            toggleExclude(article, e.target.checked)
                          }
                          inputProps={{ "aria-label": "Exclude from shop" }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasketDetailsTable;
