export const WELCOME_SCREEN_EVENT = {
  CONSENT_TICK: "welcome_consent_tick", //
  NEXT: "welcome_next", //
};

export const ONBOARDING_HOUSEHOLD_EVENT = {
  AGE_GROUP: "onboarding_household_agegroup_", //
  GENDER_FEMALE: "onboarding_household_gender_female", //
  GENDER_MALE: "onboarding_household_gender_male", //
  GENDER_NOTICE: "onboarding_household_gender_notice", //
  ADD_ADULT: "onboarding_household_add_adult", //
  REMOVE: "onboarding_household_remove", //
  ADD_CHILD: "onboarding_household_add_child", //
  BREASTFEEDING_TICK: "onboarding_household_breastfeeding_tick", //
  PREGNANT_TICK: "onboarding_household_pregnant_tick", //
  NEXT: "onboarding_household_next", //
};

export const ONBOARDING_SHOPPING_ALLOCATION_EVENT = {
  SAVE: "onboarding_shopping_allocation_save", //
};

export const ONBOARDING_COMMUNICATIONS_EVENT = {
  SAVE: "onboarding_communications_save", //
  SKIP: "onboarding_communications_skip", //
  NAME: "onboarding_communications_name", //
  EMAIL: "onboarding_communications_email", //
  CONSENT: "onboarding_communications_consent", //
  UNCONSENT: "onboarding_communications_unconsent", //
};

export const HOMEPAGE_EVENT = {
  LOADED: "homepage_loaded", //
  TIMEPERIOD_WEEKLY: "homepage_timeperiod_weekly", //
  TIMEPERIOD_FORTNIGHTLY: "homepage_timeperiod_fortnightly", //
  TIMEPERIOD_MONTHLY: "homepage_timeperiod_monthly", //
  MENU_OPEN: "homepage_menu_open", //
  QUICKFEATURE_SELECT: "homepage_quickfeature_select", //
  QUICKFEATURE_SCROLLED: "homepage_quickfeature_scrolled", //
  QUICKFEATURE_HEALTHIER_OPTIONS: "homepage_quickfeature_healthier_options", //
  QUICKFEATURE_RECIPES: "homepage_quickfeature_recipes", //
  QUICKFEATURE_NUTRIENT_TRACKER: "homepage_quickfeature_nutrient_tracker", //
  QUICKFEATURE_MAIN_SOMETIMES: "homepage_quickfeature_main_sometimes", //
  QUICKFEATURE_HEALTHY_INSPIRATIONS:
    "homepage_quickfeature_healthy_inspirations", //
  BALANCE_HELP_OPEN: "homepage_balance_help_open", //
  BALANCE_HELP_CLOSE: "homepage_balance_help_close", //
  BALANCE_DAIRY_CLICK: "homepage_balance_dairy", //
  BALANCE_VEG_CLICK: "homepage_balance_veg", //
  BALANCE_MEAT_CLICK: "homepage_balance_meat", //
  BALANCE_GRAINS_CLICK: "homepage_balance_grains", //
  BALANCE_FRUIT_CLICK: "homepage_balance_fruit", //
  MAIN_SOMETIMES_NEXT: "homepage_main_sometimes_next", //
  MAIN_SOMETIMES_HELP_OPEN: "homepage_main_sometimes_help_open", //
  MAIN_SOMETIMES_HELP_CLOSE: "homepage_main_sometimes_help_close", //
  MAIN_SOMETIMES_MAIN_CLICK: "homepage_main_sometimes_main", //
  MAIN_SOMETIMES_SOMETIMES_CLICK: "homepage_main_sometimes_sometimes", //
  HO_NEXT: "homepage_ho_next", //
  HO_HELP_OPEN: "homepage_ho_help_open", //
  HO_HELP_CLOSE: "homepage_ho_help_close", //
  HO_OPEN: "homepage_ho_open", //
  HO_SCROLL: "homepage_ho_scroll", //
  HO_PRODUCT_CLICK: "homepage_ho_product_select", //
  PDP: "homepage_ho_pdp", //
  NT_HELP_OPEN: "homepage_nt_help_open", //
  NT_HELP_CLOSE: "homepage_nt_help_close", //
  NT_CLICK: "homepage_nt_open", //
  NT_SUGAR: "homepage_nt_sugar", //
  NT_SALT: "homepage_nt_salt", //
  NT_FAT: "homepage_nt_fat", //
  INSPIRATION_SCROLL: "homepage_inspiration_scroll", //
  INSPIRATION_VIEW_MORE: "homepage_inspiration_view_more", //
  INSPIRATION_CLICK: "homepage_inspiration_click", //
  THREE_CAROUSEL_SCROLL: "homepage_threecarousel_scroll", //
  DIETARY_PREFERENCE_HELP_OPEN: "homepage_dietary_preference_help_open", //
  DIETARY_PREFERENCE_HELP_CLOSE: "homepage_dietary_preference_help_close", //
  VIEW_PURCHASES_AND_LEADERBOARD: "homepage_view_purchases_and_leaderboard", //
};

export const MENU_SETTINGS_EVENT = {
  EXPLORE_FOODTRACKER_TOOLS_CLICK: "explore_foodtracker_tools_click", //
  PERSONALIZATION_CLICK: "personalization_click", //
  HELP_CLICK: "help_click", //
  COMMUNICATIONS_CLICK: "communications_click", //
  REWARDS_CLICK: "rewards_click", //
  RECOMMEND_TO_A_FRIEND_CLICK: "recommend_to_a_friend_click", //
  FEEDBACK_CLICK: "feedback_click", //
  OPT_OUT_CLICK: "opt_out_click", //
};

export const FOOD_GROUP_PAGES_EVENT = {
  LOADED: "loaded", //
  EXIT: "exit", //
  SERVES_HELP_OPEN: "serves_help_open", //
  SERVES_HELP_CLOSE: "serves_help_close", //
  TREND_COLLAPSE: "trend_collapse", //
  TREND_OPEN: "trend_open", //
  ITEMS_OPEN: "items_open", //
  ITEMS_CLOSE: "items_close", //
  ITEMS_EXCLUDE_YES: "items_exclude_yes", //
  ITEMS_EXCLUDE_NO: "items_exclude_no", //
  ITEMS_EXCLUDE_HELP_OPEN: "items_exclude_help_open", //
  ITEMS_EXCLUDE_HELP_CLOSE: "items_exclude_help_close", //
  LEARN_MORE_OPEN: "learn_more_open", //
  LEARN_MORE_CLOSE: "learn_more_close", //
  RECIPES_HELP_OPEN: "recipes_help_open", //
  RECIPES_HELP_CLOSE: "recipes_help_close", //
  RECIPES_OPEN: "recipes_open", //
  RECIPES_SCROLL: "recipes_scroll", //
  RECIPES_OPENRECIPE: "recipes_openrecipe", //
  HO_HELP_OPEN: "ho_help_open", //
  HO_HELP_CLOSE: "ho_help_close", //
  HO_OPEN: "ho_open", //
  HO_SCROLL: "ho_scroll", //
  HO_PRODUCT: "ho_product", //
  PDP: "ho_pdp", //
  INSPIRATION_SCROLL: "inspiration_scroll", //
  INSPIRATION_CLICK: "inspiration_click", //
  INSPIRATION_VIEW_MORE: "inspiration_view_more", //
};

export const SOMETIMES_FOOD_PAGE_EVENT = {
  LOAD: "sometimes_food_load", //
  BACK: "sometimes_food_back", //
  HELP_OPEN: "sometimes_food_help_open", //
  HELP_CLOSE: "sometimes_food_help_close", //
  TREND_COLLAPSE: "sometimes_trend_collapse", //
  TREND_OPEN: "sometimes_trend_open", //
  HO_HELP_OPEN: "sometimes_ho_help_open", //
  HO_HELP_CLOSE: "sometimes_ho_help_close", //
  HO_OPEN: "sometimes_ho_open", //
  HO_SCROLL: "sometimes_ho_scroll", //
  HO_PRODUCT: "sometimes_ho_product", //
  PDP: "sometimes_ho_pdp", //
  INSPIRATION_SCROLL: "sometimes_inspiration_scroll", //
  INSPIRATION_VIEW_MORE: "sometimes_inspiration_view_more", //
  INSPIRATION_CLICK: "sometimes_inspiration_click", //
  SEGMENTS_OPEN: "sometimes_segments_open", //
  SEGMENTS_CLOSE: "sometimes_segments_close", //
  LEARN_MORE_OPEN: "sometimes_learn_more_open", //
  LEARN_MORE_CLOSE: "sometimes_learn_more_close", //
};

export const MAIN_VS_SOMETIMES_EVENT = {
  HO_HELP_OPEN: "main_vs_sometimes_ho_help_open", //
  HO_HELP_CLOSE: "main_vs_sometimes_ho_help_close", //
  HO_OPEN: "main_vs_sometimes_ho_open", //
  HO_SCROLL: "main_vs_sometimes_ho_scroll", //
  HO_PRODUCT: "main_vs_sometimes_ho_product", //
  INSPIRATION_SCROLL: "main_vs_sometimes_inspiration_scroll", //
  INSPIRATION_VIEW_MORE: "main_vs_sometimes_inspiration_view_more", //
  INSPIRATION_CLICK: "main_vs_sometimes_inspiration_click", //
};

export const NUTRIENT_TRACKER_EVENT = {
  SUGAR: "nt_sugar", //
  SALT: "nt_salt", //
  FAT: "nt_fat", //
  LEARN_MORE_OPEN: "nt_learn_more_open", //
  LEARN_MORE_CLOSE: "nt_learn_more_close", //
  HO_CLICKED: "nt_ho_clicked", //
  HO_CLOSED: "nt_ho_closed", //
  HO_SCROLL: "nt_ho_scroll", //
  HO_OPEN: "nt_ho_open", //
  HELP_OPEN: "nt_help_open", //
  HELP_CLOSE: "nt_help_close", //
  PDP: "nt_ho_pdp", //
  INSPIRATION_SCROLL: "nt_inspiration_scroll", //
  INSPIRATION_VIEW_MORE: "nt_inspiration_view_more", //
  INSPIRATION_CLICK: "nt_inspiration_click", //
};

export const FAQ_ACCORDION_EVENT = {
  OPEN: "faq_accordion_open", //
  CLOSE: "faq_accordion_close", //
};

export const HEALTHIER_OPTIONS_PDP_EVENT = {
  HO_HELP_OPEN: "healthier_options_pdp_ho_help_open", //
  HO_HELP_CLOSE: "healthier_options_pdp_ho_help_close", //
  HO_PRODUCT_CLICK: "healthier_options_pdp_ho_product_select", //
};

export const HEALTHIER_OPTIONS_PLP_EVENT = {
  HO_HELP_OPEN: "plp_ho_help_open", //
  HO_HELP_CLOSE: "plp_ho_help_close", //
  HO_PRODUCT_CLICK: "plp_ho_product_select", //
  HO_CLOSED: "plp_ho_closed", //
  PDP: "plp_ho_pdp", //
};

export const GOALS_EVENTS = {
  PROGRESS_VIEW: "goal_progress_view",
  DELETED: "goals_deleted",
  SET: "goals_set",
  MODAL_OPEN: "goals_info_modal_opened",
  MODAL_CLOSED: "goals_info_modal_closed",
  EDIT_CLICKED: "goals_edit_clicked",
};

export const DIETARY_PREFERENCE_EVENTS = {
  DELETED: "dietary_preference_deleted",
  SET: "dietary_preference_set",
  SETTING_OPEN: "dietary_preference_setting_open",
  SETTING_CLOSE: "dietary_preference_setting_close",
};

export const FRUIT_AND_VEG_CHALLENGE_EVENTS = {
  HELP_OPEN: "fruit_and_veg_challenge_help_open",
  HELP_CLOSE: "fruit_and_veg_challenge_help_close",
  TAB_SELECT: "fruit_and_veg_challenge_tab_select",
  LEADERBOARD_OPT_IN: "fruit_and_veg_leaderboard_opt_in",
  LEADERBOARD_CANCEL_OPT_IN: "fruit_and_veg_leaderboard_cancel_opt_in",
  LEADERBOARD_TOGGLE_CITY: "fruit_and_veg_leaderboard_toggle_city",
  EXITED_LEADERBOARD: "fruit_and_veg_exited_leaderboard",
};
