import React, { RefObject, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { COLOR_GREYSCALE } from "../../theme";

export const ScrollIndicator = ({
  scrollRef,
}: {
  scrollRef: RefObject<HTMLDivElement>;
}) => {
  const WIDTH = 48;
  const MIN_WIDTH = 0; // Minimum width for the indicator in pixels
  const [scrollLeftPercentage, setScrollLeftPercentage] = useState(0);
  const [visiblePercentage, setVisiblePercentage] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef || !scrollRef.current) return;

      const scrollLeft = scrollRef.current.scrollLeft;
      const scrollWidth =
        scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

      setIsScrollable(scrollWidth > 0);

      const clientWidth = scrollRef.current.clientWidth;

      const visiblePerc = (clientWidth / scrollRef.current.scrollWidth) * 100;
      const scrollLeftPerc = (scrollLeft / scrollWidth) * 100;

      const indicatorWidth = Math.max((visiblePerc / 100) * WIDTH, MIN_WIDTH);

      const maxLeft = WIDTH - indicatorWidth;
      const leftInPixels = (scrollLeftPerc / 100) * maxLeft;

      setVisiblePercentage(visiblePerc);
      setScrollLeftPercentage(leftInPixels);
    };

    const scrollContainer = scrollRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef]);

  if (!isScrollable) return null;

  return (
    <div
      style={{
        marginTop: "8px",
        marginBottom: "8px",
        width: `${WIDTH}px`,
        height: "4px",
        backgroundColor: "#E1E1E1",
        borderRadius: "2px",
        overflow: "hidden",
      }}
    >
      <motion.div
        style={{
          position: "relative",
          width: `${Math.max((visiblePercentage / 100) * WIDTH, MIN_WIDTH)}px`,
          height: "100%",
          backgroundColor: COLOR_GREYSCALE[400],
          borderRadius: "2px",
        }}
        initial={{ left: 0 }}
        animate={{ left: `${scrollLeftPercentage}px` }}
        transition={{ duration: 0.2 }}
      />
    </div>
  );
};
