import { FC } from "react";
import { Link, makeStyles, Typography } from "@material-ui/core";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import PrimaryArrowButton from "../PrimaryArrowButton";
import { ExternalLinks } from "../../constants";
import ExternalLink from "../ExternalLink";

import React from "react";
import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalTitle,
  useCloseModal,
} from "../../components-2/InfoModal";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_HOUSEHOLD_EVENT } from "../../events";

const useStyles = makeStyles(({ spacing }) => ({
  linkContainer: {
    width: "fit-content",
    marginLeft: "auto",
  },
  link: {
    color: `${COLOR_GREYSCALE[900]} !important`,
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
  },
  buttonContainer: {
    textAlign: "center",
  },
  button: {
    backgroundColor: DARK_GREEN,
    color: "white",
    marginTop: "20px",
    borderRadius: "12px",
    "& .MuiButton-label": {
      alignItems: "normal",
    },
  },
}));

const GenderIdentificationNoticeContent: FC = () => {
  const closeModal = useCloseModal();
  const classes = useStyles();

  return (
    <>
      <InfoModalTitle>Gender Identification Notice</InfoModalTitle>
      <InfoModalContent>
        We understand these options may not align to how you identify. Nutrition
        needs are determined by physical differences between bodies.
        Recommendations are based on The{" "}
        <ExternalLink
          href={ExternalLinks.AustralianDietaryGuidelines}
          target="_blank"
        >
          Australian Dietary Guidelines
        </ExternalLink>
        . We recommend you seek individual dietary advice from a health
        professional in order to better understand which available selection
        would best suit your physiological needs.
      </InfoModalContent>
      <div className={classes.buttonContainer}>
        <PrimaryArrowButton onClick={closeModal} className={classes.button}>
          <span>Got it</span>
        </PrimaryArrowButton>
      </div>
    </>
  );
};

const GenderIdentificationNotice = ({
  isOnboarding = false,
}: {
  isOnboarding?: boolean;
}) => {
  const classes = useStyles();
  const { track } = useEvents();
  return (
    <InfoModalContainer
      eventName={"household_gender_notice"}
      button={
        <Typography variant="body2" className={classes.linkContainer}>
          <Link
            className={classes.link}
            tabIndex={0}
            onClick={() => {
              if (isOnboarding) {
                track(ONBOARDING_HOUSEHOLD_EVENT.GENDER_NOTICE);
              }
            }}
          >
            Gender identification notice
          </Link>
        </Typography>
      }
    >
      <GenderIdentificationNoticeContent />
    </InfoModalContainer>
  );
};

export default GenderIdentificationNotice;
