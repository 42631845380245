const retrieveFromLocalStorage = (
  key: string,
  defaultValue: string | null = null
) => localStorage.getItem(key) ?? defaultValue;

const storeInLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, JSON.stringify(value));

const keyPrefix = "__healthyLife.";

export const _lsKeys = {
  visitCount: `${keyPrefix}visitCount`,
};

export const getVisitCount = (): number =>
  Number(retrieveFromLocalStorage(_lsKeys.visitCount) || 0);

export const incrementVisitCount = (): number => {
  const count = getVisitCount() + 1;
  storeInLocalStorage(_lsKeys.visitCount, count);
  return count;
};
