import useMember from "../../hooks/useMember";
import React, { ReactElement, useState } from "react";
import { setShoppingAllocation } from "../../services/members";
import { OnboardingPageLayout } from "./OnboardingPageLayout";
import ShoppingAllocationV2 from "../../components/ConfigToolbarV2/ShoppingAllocationV2";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_SHOPPING_ALLOCATION_EVENT } from "../../events";

export const ShoppingAllocationStep = ({
  StepperComponent,
  step,
}: {
  StepperComponent: ReactElement;
  step: number;
}) => {
  const { track } = useEvents();
  const { member } = useMember();
  const [shoppingAllocationValues, setShoppingAllocationValues] = useState({
    ...member!.shoppingAllocation,
  });

  const customHandleNext = async () => {
    try {
      track(ONBOARDING_SHOPPING_ALLOCATION_EVENT.SAVE);
      await setShoppingAllocation(member!.memberId, shoppingAllocationValues);
      return true;
    } catch (e) {
      console.log("ERROR SAVING SHOPPING ALLOCATION");
      return false;
    }
  };

  return (
    <OnboardingPageLayout
      stepper={React.cloneElement(StepperComponent, {
        customHandleNext,
      })}
      stepNumber={step}
    >
      <ShoppingAllocationV2
        setShippingAllocationValues={setShoppingAllocationValues}
        shippingAllocationValues={shoppingAllocationValues}
        isOnboarding={true}
      />
      <div style={{ paddingTop: "8px" }} />
    </OnboardingPageLayout>
  );
};
