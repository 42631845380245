import { Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { FC } from "react";
import { ReactComponent as FatIcon } from "../../icons/swap-fat.svg";
import { ReactComponent as SugarIcon } from "../../icons/swap-sugar.svg";
import { ReactComponent as SaltIcon } from "../../icons/swap-salt.svg";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { COLOR_GREYSCALE } from "../../theme";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  iconKeyContainer: {
    backgroundColor: "white",
    borderRadius: spacing(1),
    border: `1px solid ${COLOR_GREYSCALE[100]}`,
    "& > * + *": {
      marginTop: "8px",
    },
  },
  iconKeyWhiteContainer: {
    borderRadius: "4px",
    //mobile
    [breakpoints.down("sm")]: {
      marginTop: "8px",
    },
    //desktop
    [breakpoints.up("md")]: {
      marginTop: "16px",
    },
  },
  mainTextContainer: {
    padding: 0,
    //mobile
    [breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
    //desktop
    [breakpoints.up("md")]: {
      margin: "0px 16px",
    },
  },
  iconContainer: {
    backgroundColor: COLOR_GREYSCALE[50],
    width: "100%",
    "& > *": {
      paddingRight: "8px",
    },
    //mobile
    [breakpoints.down("sm")]: {
      padding: "4px 8px 4px 8px",
      "& > *:last-child": {
        paddingTop: "8px",
      },
    },
    //desktop
    [breakpoints.up("md")]: {
      padding: "4px 16px",
    },
  },
  iconWrapperText: {
    fontStyle: "normal",
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    textTransform: "uppercase",
    color: COLOR_GREYSCALE[700],
    margin: 0,
    [breakpoints.down("sm")]: {
      fontFamily: "'Gilroy-SemiBold'",
      fontWeight: "normal",
    },
    //desktop
    [breakpoints.up("md")]: {
      fontFamily: "'Gilroy'",
    },
  },
  iconWrapperIcon: {
    marginRight: spacing(1),
  },
  mainText: {
    background: "white",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    padding: 0,
    margin: 0,
    color: COLOR_GREYSCALE[900],
    [breakpoints.down("sm")]: {
      paddingTop: "8px",
    },
    //desktop
    [breakpoints.up("md")]: {
      paddingTop: "16px",
    },
  },
  disclaimerTextContainer: {
    display: "flex",
    //mobile
    [breakpoints.down("sm")]: {
      margin: "4px 8px 8px 8px",
    },
    //desktop
    [breakpoints.up("md")]: {
      margin: "16px",
    },
  },
  disclaimerText: {
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[700],
    margin: 0,
  },
  disclaimerIcon: {
    width: 14,
    height: 14,
    paddingRight: spacing(0.5),
    color: COLOR_GREYSCALE[700],
    verticalAlign: "middle",
  },
}));

export const HEALTHIER_OPTIONS_ICONS = [
  {
    id: "lowerFat",
    name: "Lower Saturated Fat",
    Icon: FatIcon,
  },
  {
    id: "lowerSugar",
    name: "Lower sugar",
    Icon: SugarIcon,
  },
  {
    id: "lowerSalt",
    name: "Lower salt",
    Icon: SaltIcon,
  },
];

interface Icon {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string;
}

const IconWrapper: FC<Icon> = ({ Icon, text }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container direction="row" alignItems="center">
        <Grid item className={classes.iconWrapperIcon}>
          <Icon aria-label={text + " icon"} />
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.iconWrapperText}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const HealthierOptionsIconKey = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.iconKeyContainer}
      aria-label={"What the icons mean"}
      role={"group"}
    >
      <Grid item className={classes.mainTextContainer}>
        <Typography variant="body2" className={classes.mainText}>
          Healthier Options are based on your previous shop and ranked according
          to your dietary preferences and Health Star Rating. Always check product allergen & dietary info
          before consuming.
        </Typography>
      </Grid>

      <Grid item className={classes.iconKeyWhiteContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.iconContainer}
        >
          <IconWrapper Icon={FatIcon} text={"Lower Saturated Fat"} />
          <IconWrapper Icon={SugarIcon} text={"Lower sugar"} />
          <IconWrapper Icon={SaltIcon} text={"Lower salt"} />
        </Grid>

        <Grid item xs={12} className={classes.disclaimerTextContainer}>
          <InfoIcon className={classes.disclaimerIcon} />
          <Typography className={classes.disclaimerText}>
            At least 25% lower when compared to the original product
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
