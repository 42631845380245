import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

export const NoSwapsText = ({
  forHomepage = false,
}: {
  forHomepage?: boolean;
}) => {
  const useStyles = makeStyles(() => ({
    noSwapsText: {
      color: "#585C60",
      "& > ul": {
        margin: 0,
        marginTop: "10px",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      component={"span"}
      className={classes.noSwapsText}
    >
      {forHomepage ? (
        <>
          <span>
            Bummer! We don’t have anything to show you at the moment. Either:
          </span>
          <ul>
            <li>Your shop hasn’t updated yet or</li>
            <li>
              We don’t have any Healthier Options to suggest right now (what a
              star!)
            </li>
          </ul>
        </>
      ) : (
        <span>
          Healthier Options gives you similar products that are healthier, based
          on Health Star Rating. We don't have any Healthier Options to suggest
          right now (what a star!). Check in after your next shop.
        </span>
      )}
    </Typography>
  );
};
