import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  initializeAuth,
  signInWithCustomToken as fbSignInWithCustomToken,
} from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

import { ThrottledBatchQueue } from "../utils/throttledBatchQueue";
import { PROXIED_FIREBASE_AUTH_URL, PROXIED_FIRESTORE_URL } from "../constants";

const {
  REACT_APP_FIREBASE_PROJECT_ID: projectId,
  REACT_APP_FIREBASE_API_KEY: apiKey,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
  REACT_APP_FIREBASE_APP_ID: appId,
} = process.env;

const firebaseApp = initializeApp({
  apiKey,
  projectId,
  authDomain: `${projectId}.firebaseapp.com`,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId,
  appId,
});

const auth = initializeAuth(firebaseApp, {
  persistence: browserSessionPersistence,
});

if (auth?.config?.apiHost) {
  auth.config.apiHost = PROXIED_FIREBASE_AUTH_URL;
}

// Initialize Performance Monitoring and get a reference to the service
if (projectId) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const perf = getPerformance(firebaseApp);
}

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  experimentalForceLongPolling: true,
  host: PROXIED_FIRESTORE_URL,
});

export const signInWithCustomToken = (customAuthToken: string) =>
  fbSignInWithCustomToken(auth, customAuthToken);

export const Shards = {
  AggregateDailyServingsSharded: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ],
  AggregateDailyNutrientsSharded: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ],
  BasketDetailsSharded: ["1", "2", "3", "4"],
  RealTimeDailyBasketDetails: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  RealTimeDailyNutrients: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  RealTimeDailyServings: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};

export const Collections = {
  AggregateDailyServings: "aggregateDailyServings",
  AggregateDailyServingsSharded: "aggregateDailyServingsSharded",
  AggregateDailyNutrients: "aggregateDailyNutrients",
  AggregateDailyNutrientsSharded: "aggregateDailyNutrientsSharded",
  BasketSyncHistory: "basketSyncHistory",
  BasketDetails: "basketDetails",
  BasketDetailsSharded: "basketDetailsSharded",
  RealTimeDailyBasketDetails: "realTimeAggregateBasketDetails",
  RealTimeDailyNutrients: "realTimeAggregateDailyNutrients",
  RealTimeDailyServings: "realTimeAggregateDailyServings",
  BasketExclusions: "basketExclusions",
  DataUsageConsentHistory: "dataUsageConsentHistory",
  NewFeaturesAcknowledgements: "newFeaturesAcknowledgements",
  Events: "events",
  Household: "household",
  Members: "members",
  ArticleServings: "articleServings",
  FeedbackSubmissions: "feedbackSubmissions",
  Configurations: "configurations",
};

export const Configurations = {
  FeedbackDialog: "feedbackDialog",
  FeatureFlags: "featureFlags",
};

export const Fields = {
  RealTimeDailyBasketDetails: {
    PurchaseDate: "purchaseDate", // iso date
    Shard: "shard",
  },
  RealTimeDailyNutrients: {
    PurchaseDate: "purchaseDate", // epoch millis
    Shard: "shard",
  },
  RealTimeDailyServings: {
    PurchaseDate: "purchaseDate", // epoch millis
    Shard: "shard",
  },
  AggregateDailyServings: {
    PurchaseDate: "purchaseDate", // epoch millis
  },
  AggregateDailyServingsSharded: {
    PurchaseDate: "purchaseDate", // epoch millis
    Shard: "shard",
  },
  AggregateDailyNutrients: {
    PurchaseDate: "purchaseDate", // epoch millis
  },
  AggregateDailyNutrientsSharded: {
    PurchaseDate: "purchaseDate", // epoch millis
    Shard: "shard",
  },
  DataUsageConsentHistory: {
    Date: "date",
  },
  BasketSyncHistory: {
    RunDate: "runDate",
  },
  BasketDetailsSharded: {
    PurchaseDate: "purchaseDate", // iso date
    Shard: "shard",
  },
  BaseBasketDetails: {
    PurchaseDate: "purchaseDate", // iso date
  },
  BaseCachedBasketDetails: {
    PurchaseDate: "purchaseDate", // iso date
  },
  NewFeaturesAcknowledgement: {
    Version: "version",
  },
};

export const batchQueue = new ThrottledBatchQueue(db);
