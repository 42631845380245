export const ifEnterOrSpace = (
  e: { key: string; code: string },
  functionToRun?: (...args: any[]) => void,
  ...args: any[]
) => {
  if (
    (e.key === "Enter" || e.code === "Space") &&
    functionToRun !== undefined
  ) {
    functionToRun(...args);
  }
};
