import {
  BasketArticle,
  BasketDay,
  FoodGroup,
  HealthierOption,
  HealthierOptionsFoodGroup,
  Servings100g,
} from "../models";
import useBasketDetails from "./useBasketDetails";
import { ServesThresholdsForProductBreakdown } from "../constants";
import { useCallback, useContext, useMemo } from "react";
import { DietaryPreferencesContext } from "../components/providers/DietaryPreferencesProvider";
import { DIETARY_PREFERENCES_DATA } from "../components-2/dietary-preference/config";

const useHealthierOptions = (foodGroup: HealthierOptionsFoodGroup = "all") => {
  const {
    allFoodGroupsBasketDetails,
    basketDetailsLoading,
    basketDetailsError,
  } = useBasketDetails("discretionary");
  const MAX_AMOUNT_OF_SWAPS = 10;

  const { checked: preferences } = useContext(DietaryPreferencesContext);

  const filterByFoodGroup = useCallback(
    (article: BasketArticle, foodGroup: HealthierOptionsFoodGroup) => {
      switch (foodGroup) {
        case "all":
          return true;
        case "discretionary":
          return article[(foodGroup + "100g") as keyof Servings100g] > 0;
        default:
          return (
            article[(foodGroup + "100g") as keyof Servings100g] >=
            ServesThresholdsForProductBreakdown[foodGroup]
          );
      }
    },
    []
  );

  const hasHealthierOptions = (article: BasketArticle) =>
    article.hasHealthierOption;
  const isUniqueArticleId = (
    article: BasketArticle,
    index: number,
    self: BasketArticle[]
  ) => {
    // if somebody buys the same item on two different days, we only want to show it once.
    return (
      index ===
      self.findIndex((a: BasketArticle) => a.articleId === article.articleId)
    );
  };

  const sortByDietaryPreference = useCallback(
    (ho: any) => {
      if (ho && preferences.length > 0) {
        const preferenceKeys = preferences.map(
          (pref) => DIETARY_PREFERENCES_DATA[pref].code
        );
        const hasEveryPreferences = ho.filter(
          (h: BasketArticle | HealthierOption) => {
            const keys = Object.keys(h.dietaryPreferences);
            const hoPref = keys.filter(
              (k) =>
                h.dietaryPreferences[k as keyof typeof h.dietaryPreferences]
            );
            return preferenceKeys.every((pk) => hoPref.includes(pk));
          }
        );

        const hasEveryPreferencesSorted = hasEveryPreferences.sort(
          (
            h: BasketArticle | HealthierOption,
            o: BasketArticle | HealthierOption
          ) => {
            const hKeys = Object.keys(h.dietaryPreferences);
            const hPrefs = hKeys.filter(
              (hk) =>
                h.dietaryPreferences[hk as keyof typeof h.dietaryPreferences]
            );
            const oKeys = Object.keys(o.dietaryPreferences);
            const oPrefs = oKeys.filter(
              (ok) =>
                o.dietaryPreferences[ok as keyof typeof o.dietaryPreferences]
            );

            return hPrefs.length - oPrefs.length;
          }
        );

        const hasSomePreferences = ho.filter(
          (h: BasketArticle | HealthierOption) => {
            const keys = Object.keys(h.dietaryPreferences);
            const hoPref = keys.filter(
              (k) =>
                h.dietaryPreferences[k as keyof typeof h.dietaryPreferences]
            );
            return preferenceKeys.some((pk) => hoPref.includes(pk));
          }
        );

        const notInPreferences = ho.filter(
          (h: BasketArticle | HealthierOption) => {
            const keys = Object.keys(h.dietaryPreferences);
            const hoPref = keys.filter(
              (k) =>
                h.dietaryPreferences[k as keyof typeof h.dietaryPreferences]
            );
            return preferenceKeys.every((pk) => !hoPref.includes(pk));
          }
        );

        const articles = [
          hasEveryPreferencesSorted,
          hasSomePreferences,
          notInPreferences,
        ].flat();
        const uniqueArticles = articles.filter(
          (article, i) =>
            articles.findIndex((a) => a.articleId === article.articleId) === i
        );
        return uniqueArticles;
      }
      return ho as HealthierOption | BasketArticle;
    },
    [preferences]
  );

  const allPurchasedItemsWithSwaps = useMemo(() => {
    const allFoodGroupsBasketDetailsWithSwaps = allFoodGroupsBasketDetails
      .map((day: BasketDay) => day.articles)
      .flat()
      .reverse()
      .filter(
        (article: BasketArticle, index: number, self: BasketArticle[]) =>
          hasHealthierOptions(article) &&
          isUniqueArticleId(article, index, self)
      )
      .map((article: BasketArticle) => {
        return {
          ...article,
          healthierOptions: article.healthierOptions.slice(
            0,
            MAX_AMOUNT_OF_SWAPS
          ),
        };
      });
    return sortByDietaryPreference(
      allFoodGroupsBasketDetailsWithSwaps
    ) as BasketArticle[];
  }, [allFoodGroupsBasketDetails, sortByDietaryPreference]);

  const purchasedItemsWithSwaps = useMemo(() => {
    return allPurchasedItemsWithSwaps.filter((article) =>
      filterByFoodGroup(article, foodGroup)
    );
  }, [allPurchasedItemsWithSwaps, filterByFoodGroup, foodGroup]);

  const purchasedItemsFoodGroupCount = useMemo(() => {
    return {
      all: allPurchasedItemsWithSwaps.length,
      discretionary: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, "discretionary")
      ).length,
      fruit: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, FoodGroup.Fruit)
      ).length,
      vegetables: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, FoodGroup.Vegetables)
      ).length,
      grains: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, FoodGroup.Grains)
      ).length,
      protein: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, FoodGroup.Protein)
      ).length,
      dairy: allPurchasedItemsWithSwaps.filter((a: BasketArticle) =>
        filterByFoodGroup(a, FoodGroup.Dairy)
      ).length,
    };
  }, [allPurchasedItemsWithSwaps, filterByFoodGroup]);

  return {
    isLoading: basketDetailsLoading,
    isEmpty: purchasedItemsWithSwaps.length === 0,
    hasErrored: basketDetailsError,
    foodGroupCount: purchasedItemsFoodGroupCount,
    purchasedItemsWithSwaps: purchasedItemsWithSwaps,
    sortByDietaryPreference: sortByDietaryPreference,
  };
};

export default useHealthierOptions;
