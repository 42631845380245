import { FC, createElement, useContext, useEffect, useState } from "react";
import { FoodGroup } from "../models";
import useFetchFoodGroupPage from "../hooks/useFetchFoodGroupPage";
import CenteredLoadingIndicator from "../components/CenteredLoadingIndicator";
import useEvents from "../hooks/useEvents";
import AboutFoodGroup from "../components/food-groups/about-foodgroup/AboutFoodGroup";
import {
  FoodGroupColorIntake,
  FoodGroupLabel,
  FoodGroupRoundedIconV2,
} from "../constants";
import TimeFrameTabs from "../components/TimeFrameTabs";
import { PageLayout } from "../components-2/page-layout";
import { COLOR_GREYSCALE, LEAFY_GREEN, PINK_SUNRISE, WHITE } from "../theme";
import HealthierOptions from "../components-2/healthier-options";
import BreakSeparator from "../components/BreakSeparator";
import HealthyInspiration from "../components/healthy-inspiration/HealthyInspiration";
import {
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HealthierRecipes from "../components/food-groups/healthier-recipes";
import TimeframeBarChart from "../components/food-groups/timeframe-bar-chart/TimeframeBarChart";
import RecommendedServes from "../components/food-groups/recommended-serves/RecommendedServes";
import {
  Accordion,
  AccordionContainer,
  AccordionIcon,
} from "../components-2/Accordion";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import useBasketDetails from "../hooks/useBasketDetails";
import BasketDetailsTable from "../components/food-groups/basket-details/BasketDetailsTable";
import RecommendedServesExplainer from "../components/food-groups/recommended-serves/RecommendedServesExplainer";
import { ReactComponent as HealthierOptionsIcon } from "../icons/healthier-options-icon.svg";
import { ReactComponent as HealthierRecipesIcon } from "../icons/recipe-icon.svg";
import { useHistory } from "react-router-dom";
import { HealthierOptionsExplainer } from "../components-2/healthier-options/HealthierOptionsExplainer";
import RecipeExplainer from "../components-2/recipe/RecipeExplainer";
import { ifEnterOrSpace } from "../utils/keyboardNavigation";
import { FOOD_GROUP_PAGES_EVENT } from "../events";

interface Props {
  foodGroup: FoodGroup;
}

const FoodGroupPage: FC<Props> = ({ foodGroup }) => {
  const content = useFetchFoodGroupPage(foodGroup);
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  const theme = useTheme();
  const isDesktopBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));

  const useStyles = makeStyles(({ breakpoints }) => ({
    dataChartContainer: {
      marginBottom: "32px",
      backgroundColor: WHITE,
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("lg")]: {
        boxShadow: "none",
        border: `solid 1px ${COLOR_GREYSCALE[200]}`,
      },
    },
    sectionContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      [breakpoints.up("lg")]: {
        padding: "24px",
        gridTemplateColumns: "1fr 1fr",
        borderBottom: "solid 1px #C5C5C5",
      },
    },
    sections: {
      padding: "16px",
      "&.recommended-serves, &.barchart": {
        borderBottom: "solid 1px #C5C5C5",
        [breakpoints.up("lg")]: {
          gridColumn: "span 1",
          borderBottom: "none",
        },
      },
      "&.recommended-serves": {
        [breakpoints.up("lg")]: {
          paddingRight: "32px",
        },
      },
      "&.barchart": {
        [breakpoints.up("lg")]: {
          borderLeft: "solid 1px #C5C5C5",
          paddingLeft: "32px",
        },
      },
    },
    accordionHeader: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      padding: 0,
      margin: 0,
      minHeight: "auto",
      transition: "all .3s ease-in-out",

      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "auto",
        paddingBottom: 16,
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiIconButton-root": {
        padding: 0,
        marginRight: 0,
      },
    },
    accordionContent: {
      padding: 0,
      marginTop: "8px",
    },
    mainContainer: {
      marginTop: 48,
      paddingBottom: 32,
      position: "relative",
      [breakpoints.up("lg")]: {
        marginTop: 0,
        paddingBottom: 0,
      },
    },
    desktopContainer: {
      [breakpoints.up("lg")]: {
        backgroundColor: WHITE,
        padding: "40px",
        marginTop: "40px",
        borderRadius: "12px",
      },
    },
    desktopTitleContainer: {
      marginBottom: 32,
    },
    titleHeader: {
      display: "flex",
      alignItems: "center",
      margin: 0,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "28px",
      lineHeight: "36px",
      color: COLOR_GREYSCALE[900],
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      borderRadius: 40,
      marginRight: 8,
      fill: FoodGroupColorIntake[foodGroup],
      "& svg": {
        width: 30,
        height: 30,
      },
      "& path": {
        fill: FoodGroupColorIntake[foodGroup],
      },
    },
    desktopComponentContainer: {
      [breakpoints.up("lg")]: {
        paddingLeft: "48px",
        paddingRight: "48px",
        marginBottom: "56px",
      },
    },
    timeFrameTabsContainer: {
      margin: "32px 0 24px 0",
      [breakpoints.up("md")]: {
        margin: "40px 0",
      },
    },
  }));

  const classes = useStyles();

  const { track } = useEvents();
  const [viewTracked, setViewTracked] = useState(false);

  const { basketDetails } = useBasketDetails(foodGroup);

  const [expandedChart, setExpandedChart] = useState<boolean>(true);
  const [expandedPurchase, setExpandedPurchase] = useState<boolean>(false);
  const history = useHistory();
  const handleChart = () => {
    const expanded = isDesktopBreakpoint || !expandedChart;
    if (expanded) {
      track(FOOD_GROUP_PAGES_EVENT.TREND_OPEN, { foodGroup });
    } else {
      track(FOOD_GROUP_PAGES_EVENT.TREND_COLLAPSE, { foodGroup });
    }
    setExpandedChart(expanded);
  };
  const handlePurchase = () => {
    if (expandedPurchase) {
      track(FOOD_GROUP_PAGES_EVENT.ITEMS_CLOSE, { foodGroup });
    } else {
      track(FOOD_GROUP_PAGES_EVENT.ITEMS_OPEN, { foodGroup });
    }
    setExpandedPurchase(!expandedPurchase);
  };

  useEffect(() => {
    if (isDesktopBreakpoint) {
      setExpandedChart(true);
    }
  }, [isDesktopBreakpoint]);

  useEffect(() => {
    if (viewTracked) return;
    track("Food Group Page Viewed");
    track(FOOD_GROUP_PAGES_EVENT.LOADED, { foodGroup });
    setViewTracked(true);
  }, [track, viewTracked, foodGroup]);

  if (!content) {
    return <CenteredLoadingIndicator loading />;
  }

  const colorHandles = {
    grains: {
      headingColor: COLOR_GREYSCALE[800],
      backButtonColor: COLOR_GREYSCALE[800],
      menuButtonColor: COLOR_GREYSCALE[700],
      timeframeTabTextColor: COLOR_GREYSCALE[900],
    },
    vegetables: {
      headingColor: COLOR_GREYSCALE[800],
      backButtonColor: LEAFY_GREEN[700],
      menuButtonColor: LEAFY_GREEN[500],
      timeframeTabTextColor: LEAFY_GREEN[700],
    },
    protein: {
      headingColor: WHITE,
      backButtonColor: COLOR_GREYSCALE[900],
      menuButtonColor: PINK_SUNRISE[500],
      timeframeTabTextColor: COLOR_GREYSCALE[900],
    },
    dairy: {
      headingColor: WHITE,
      backButtonColor: WHITE,
      menuButtonColor: WHITE,
      timeframeTabTextColor: WHITE,
    },
    fruit: {
      headingColor: WHITE,
      backButtonColor: WHITE,
      menuButtonColor: WHITE,
      timeframeTabTextColor: WHITE,
    },
  };

  const onBackClick = () => {
    track(FOOD_GROUP_PAGES_EVENT.EXIT, { foodGroup });
    history.goBack();
  };

  return (
    <PageLayout
      isHomePage={false}
      title={FoodGroupLabel[foodGroup]}
      circleColor={FoodGroupColorIntake[foodGroup]}
      headingTextColor={colorHandles[foodGroup].headingColor}
      menuButtonColor={colorHandles[foodGroup].menuButtonColor}
      backButtonColor={colorHandles[foodGroup].backButtonColor}
      isFoodGroupPage
      foodGroup={foodGroup}
      onBackClick={onBackClick}
    >
      {isDesktopBreakpoint && (
        <>
          <AboutFoodGroup foodGroup={foodGroup} />
          <BreakSeparator marginTop={40} marginBottom={40} />
          <TimeFrameTabs
            bgColor={FoodGroupColorIntake[foodGroup]}
            textColorDesktop={COLOR_GREYSCALE[800]}
            activeTabTextColor={colorHandles[foodGroup].timeframeTabTextColor}
          />
        </>
      )}

      {!isDesktopBreakpoint && (
        <div className={classes.timeFrameTabsContainer}>
          <TimeFrameTabs
            bgColor={FoodGroupColorIntake[foodGroup]}
            textColorMobile={COLOR_GREYSCALE[800]}
            activeTabTextColor={colorHandles[foodGroup].timeframeTabTextColor}
          />
        </div>
      )}
      <div className={classes.desktopContainer}>
        <div className={classes.mainContainer}>
          {isDesktopBreakpoint && (
            <div className={classes.desktopTitleContainer}>
              <Typography
                variant="h2"
                component="h1"
                className={classes.titleHeader}
              >
                <span className={classes.titleIcon}>
                  {createElement(FoodGroupRoundedIconV2[foodGroup])}
                </span>
                <span style={{ display: "inline-block", marginRight: 4 }}>
                  Recommended serves
                </span>
                <RecommendedServesExplainer foodGroup={foodGroup} />
              </Typography>
            </div>
          )}
          <div className={classes.desktopComponentContainer}>
            <div className={classes.dataChartContainer}>
              <div className={classes.sectionContainer}>
                <div className={`${classes.sections} recommended-serves`}>
                  <RecommendedServes
                    foodGroup={foodGroup}
                    showHeader={!isDesktopBreakpoint}
                  />
                </div>
                <div className={`${classes.sections} barchart`}>
                  <AccordionContainer>
                    <Accordion expanded={expandedChart} onChange={handleChart}>
                      <AccordionSummary
                        aria-controls="barchart-content"
                        id="barchart-header"
                        expandIcon={!isDesktopBreakpoint && <AccordionIcon />}
                        className={classes.accordionHeader}
                        tabIndex={isDesktopBreakpoint ? -1 : 0}
                      >
                        Last 6 {timeFrame}s
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionContent}>
                        <TimeframeBarChart foodGroup={foodGroup} />
                      </AccordionDetails>
                    </Accordion>
                  </AccordionContainer>
                </div>
              </div>
              <div className={classes.sections}>
                <AccordionContainer>
                  <Accordion
                    expanded={expandedPurchase}
                    onChange={handlePurchase}
                  >
                    <AccordionSummary
                      aria-controls="purchased-content"
                      id="purchased-header"
                      expandIcon={<AccordionIcon />}
                      className={classes.accordionHeader}
                    >
                      Purchased items
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionContent}>
                      <BasketDetailsTable
                        foodGroup={foodGroup}
                        basketDetails={basketDetails}
                      />
                    </AccordionDetails>
                  </Accordion>
                </AccordionContainer>
              </div>
            </div>
          </div>

          {!isDesktopBreakpoint && <AboutFoodGroup foodGroup={foodGroup} />}

          {isDesktopBreakpoint && (
            <div className={classes.desktopTitleContainer}>
              <Typography
                variant="h3"
                component="h1"
                className={classes.titleHeader}
              >
                <HealthierRecipesIcon className={classes.titleIcon} />
                <Link
                  tabIndex={0}
                  onKeyPress={(e) =>
                    ifEnterOrSpace(
                      e,
                      history.push,
                      `/recipes?source=${foodGroup}`
                    )
                  }
                  onClick={() => history.push(`/recipes?source=${foodGroup}`)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                  }}
                >
                  <span style={{ display: "inline-block", marginRight: 4 }}>
                    Healthier Recipes
                  </span>
                </Link>
                <RecipeExplainer source="foodgroup" foodGroup={foodGroup} />
              </Typography>
            </div>
          )}
          <div className={classes.desktopComponentContainer}>
            <HealthierRecipes
              iconColor={FoodGroupColorIntake[foodGroup]}
              foodGroup={foodGroup}
              showHeader={!isDesktopBreakpoint}
            />
          </div>

          {isDesktopBreakpoint && (
            <div className={classes.desktopTitleContainer}>
              <Typography
                variant="h3"
                component="h1"
                className={classes.titleHeader}
              >
                <HealthierOptionsIcon className={classes.titleIcon} />
                <Link
                  tabIndex={0}
                  onKeyPress={(e) => {
                    track(FOOD_GROUP_PAGES_EVENT.HO_OPEN, { foodGroup });
                    ifEnterOrSpace(
                      e,
                      history.push,
                      "/healthier-options/plp?source=" + foodGroup
                    );
                  }}
                  onClick={() => {
                    track(FOOD_GROUP_PAGES_EVENT.HO_OPEN, { foodGroup });
                    history.push("/healthier-options/plp?source=" + foodGroup);
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: COLOR_GREYSCALE[800],
                  }}
                >
                  <span style={{ display: "inline-block", marginRight: 4 }}>
                    Healthier Options
                  </span>
                </Link>
                <HealthierOptionsExplainer
                  source="food-group"
                  foodGroup={foodGroup}
                />
              </Typography>
            </div>
          )}
          <div className={classes.desktopComponentContainer}>
            <HealthierOptions
              iconColor={FoodGroupColorIntake[foodGroup]}
              foodGroup={foodGroup}
              marginTop={32}
              showHeader={!isDesktopBreakpoint}
              source="food-group"
            />
          </div>
          {!isDesktopBreakpoint && <BreakSeparator />}
          <HealthyInspiration
            source={foodGroup}
            articles={content.relatedArticles}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default FoodGroupPage;
