import {
  ContentfulRichContent,
  FoodGroup,
  LinkWithImage,
  SOMETIMES_FOOD,
} from "../../models";
import {
  DAIRY_FOOD_GROUP_PAGE,
  FRUIT_FOOD_GROUP_PAGE,
  GRAINS_FOOD_GROUP_PAGE,
  PROTEIN_FOOD_GROUP_PAGE,
  SOMETIMES_FOOD_GROUP_PAGE,
  VEGETABLES_FOOD_GROUP_PAGE,
} from "./hardcodedContentfulContent";

export interface FoodGroupCategory {
  id: string;
  title: string;
  description: string;
  color: string;
}

interface FoodGroupPageBasicSection {
  type: "FoodGroupPageTextSection" | "FoodGroupPageServeInfoSection";
  content: ContentfulRichContent;
}

interface FoodGroupPageListSection {
  type: "FoodGroupPageNumberedListSection" | "FoodGroupPageTableSection";
  items: {
    title: string;
    description: string;
  }[];
}

export type FoodGroupPageSection =
  | FoodGroupPageBasicSection
  | FoodGroupPageListSection;

export interface FoodGroupPageContent {
  title: string;
  categories: FoodGroupCategory[];
  sections: FoodGroupPageSection[];
  relatedChallenge: LinkWithImage;
  relatedArticles: LinkWithImage[];
}

export const fetchFoodGroupPage = async (
  foodGroup: FoodGroup | typeof SOMETIMES_FOOD
): Promise<FoodGroupPageContent> => {
  let result;
  // Used to fetched from Contentful, now hard coded.
  switch (foodGroup) {
    case FoodGroup.Dairy:
      result = DAIRY_FOOD_GROUP_PAGE;
      break;
    case FoodGroup.Fruit:
      result = FRUIT_FOOD_GROUP_PAGE;
      break;
    case FoodGroup.Grains:
      result = GRAINS_FOOD_GROUP_PAGE;
      break;
    case FoodGroup.Protein:
      result = PROTEIN_FOOD_GROUP_PAGE;
      break;
    case FoodGroup.Vegetables:
      result = VEGETABLES_FOOD_GROUP_PAGE;
      break;
    default:
      result = SOMETIMES_FOOD_GROUP_PAGE;
  }

  const content = result.data.foodGroupPageCollection.items[0];
  const {
    categoriesCollection,
    relatedArticlesCollection,
    sectionsCollection,
    ...basicContent
  } = content;

  const sections = sectionsCollection.items.map((item: any) => ({
    type: item.__typename,
    content: item.content,
    items: item.itemsCollection?.items,
  }));

  //@ts-ignore
  return {
    ...basicContent,
    categories: categoriesCollection.items,
    sections,
    relatedArticles: relatedArticlesCollection.items,
  };
};
