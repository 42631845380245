import { PageLayout } from "../components-2/page-layout";
import HealthierOptionsProductListerPageComp from "../components-2/healthier-options/HealthierOptionsProductListerPageComp";

const HealthierOptionsProductListerPage = () => {
  return (
    <PageLayout title={"Healthier Options"}>
      <HealthierOptionsProductListerPageComp />
    </PageLayout>
  );
};

export default HealthierOptionsProductListerPage;
