import { DateTime } from "luxon";
import { useContext } from "react";
import { BasketDetailsContext } from "../components/providers/BasketDetailsProvider";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import {
  BasketDay,
  BasketSyncStatus,
  FoodGroup,
  Servings100g,
} from "../models";
import { endOfYesterday, getStartDateFromEndDate } from "../utils/dates";
import { ServesThresholdsForProductBreakdown } from "../constants";

const useBasketDetails = (foodGroup: FoodGroup | "discretionary") => {
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const { basketSyncStatus, basketDetails } = useContext(BasketDetailsContext);
  const timeFrameStart = getStartDateFromEndDate(timeFrame, endOfYesterday());

  const loading =
    basketSyncStatus === BasketSyncStatus.Unknown ||
    basketSyncStatus === BasketSyncStatus.Processing;

  const updating =
    basketSyncStatus === BasketSyncStatus.Processing &&
    basketDetails.length > 0;

  const error = basketSyncStatus === BasketSyncStatus.Error;

  const isWithinSelectedTimeFrame = ({ purchaseDate }: BasketDay) =>
    DateTime.fromISO(purchaseDate) >= timeFrameStart;

  const containsServesInFoodGroup = (day: BasketDay) =>
    day.articles.some((article) =>
      foodGroup === "discretionary"
        ? article[(foodGroup + "100g") as keyof Servings100g] > 0
        : article[(foodGroup + "100g") as keyof Servings100g] >=
          ServesThresholdsForProductBreakdown[foodGroup]
    );

  const allFoodGroupsBasketDetails = basketDetails.filter((day) =>
    isWithinSelectedTimeFrame(day)
  );

  const filteredBasketDetails = allFoodGroupsBasketDetails.filter((day) =>
    containsServesInFoodGroup(day)
  );

  const empty = !loading && !error && filteredBasketDetails.length === 0;
  const getAllBasketDetailsFromDate = (date: string) => {
    const basket = basketDetails.filter(
      (basket) =>
        DateTime.fromISO(basket.purchaseDate) >= DateTime.fromISO(date)
    );

    const mergedBaskets = basket.map((b) => b.articles).flat();

    return { basket, mergedBaskets };
  };

  return {
    basketSyncStatus,
    basketDetailsLoading: loading,
    basketDetailsUpdating: updating,
    basketDetailsError: error,
    basketDetailsEmpty: empty,
    basketDetails: filteredBasketDetails,
    allFoodGroupsBasketDetails: allFoodGroupsBasketDetails,
    getAllBasketDetailsFromDate: getAllBasketDetailsFromDate,
  };
};

export default useBasketDetails;
