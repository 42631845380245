import { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import FilterTag from "./RecipeFilterTag";
import { RecipeContext } from "../../../components/providers/RecipeProvider";
import useRecipes from "../../../hooks/useRecipes";

const useStyles = makeStyles(() => ({
  filterGroup: {
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
  },
  filterGroupLabel: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#79A303",
    margin: 0,
    marginBottom: "16px",
    textTransform: "capitalize",
  },
  filterGroupFilters: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
}));

const RecipeFilterGroups = ({
  isSelected = true,
}: {
  isSelected?: boolean;
}) => {
  const classes = useStyles();
  const { allIngredients, allMealTypes } = useRecipes();
  const { filterGroups, currentFilters, tempFilters } =
    useContext(RecipeContext);

  // we need to get full list of ingredients and meal types
  // for the pills to not disappear
  // when resetting filters while choosing food group
  const ingIndex = filterGroups.findIndex(
    (filter) => filter.name === "ingredients"
  );
  const mtIndex = filterGroups.findIndex(
    (filter) => filter.name === "meal type"
  );
  const filterGroupsCopy = [...filterGroups];
  // override with all ingredients and mealtype
  filterGroupsCopy[ingIndex] = {
    name: "ingredients",
    filters: allIngredients,
  };
  filterGroupsCopy[mtIndex] = {
    name: "meal type",
    filters: allMealTypes,
  };

  const filteredGroups = filterGroupsCopy.reduce((acc: any, group: any) => {
    const groupname = group.name.replace(" ", "");
    const selectedFilters = group.filters
      .filter((filter: any) =>
        isSelected ? currentFilters.includes(`${groupname}:${filter.id}`) : true
      )
      .sort((a: any, b: any) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
      .map((item: any) => ({ ...item, group }));
    return acc.concat(...selectedFilters);
  }, []);

  return (
    <div>
      {!isSelected &&
        filterGroups.map((group: any) => (
          <div className={classes.filterGroup} key={group.name}>
            {!isSelected && group.filters.length > 0 ? (
              <h4 className={classes.filterGroupLabel}>{group.name}</h4>
            ) : null}
            <div className={classes.filterGroupFilters}>
              {group.filters
                .filter((filter: any) => {
                  if (isSelected) {
                    return tempFilters.includes(filter.id);
                  }
                  return true;
                })
                .sort((a: any, b: any) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .map((filter: any) => {
                  const groupname = group.name.replace(" ", "");
                  return (
                    <FilterTag
                      group={group}
                      filter={filter}
                      isSelected={tempFilters.includes(
                        `${groupname}:${filter.id}`
                      )}
                    />
                  );
                })}
            </div>
          </div>
        ))}

      <div
        className={classes.filterGroupFilters}
        style={{ marginTop: isSelected ? "12px" : "0" }}
      >
        {isSelected &&
          filteredGroups.map((filter: any) => {
            if (filter.group.name === "dietary preferences") return null;
            return (
              <FilterTag
                group={filter.group}
                filter={filter}
                isSelected={true}
                isResultPills={true}
              />
            );
          })}
      </div>
    </div>
  );
};

export default RecipeFilterGroups;
