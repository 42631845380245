import React, { ReactElement, useRef } from "react";
import { StyledTableRow } from "./StyledTableRow";
import { StyledTable } from "./StyledTable";
import { StyledTableHead } from "./StyledTableHead";
import { StyledTableCell } from "./StyledTableCell";
import { StyledTableBody } from "./StyledTableBody";
import { StyledTableContainer } from "./StyledTableContainer";
import { ScrollIndicator } from "./ScrollIndicator";
import { Text } from "../Text";
import { COLOR_GREYSCALE, DARK_GREEN, LIGHTER_CHARCOAL } from "../../theme";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const inlineIcon = {
  display: "inline-flex",
  verticalAlign: "middle",
  paddingRight: "8px",
  width: "20px",
  boxSizing: "content-box",
};

export const HorizontalTable = ({
  headers,
  rows,
}: {
  headers: { text: string }[];
  rows: {
    Icon?: ReactElement;
    text?: string;
  }[][];
}) => {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <StyledTableContainer fullWidth={isMobile} ref={scrollRef}>
        <StyledTable size="small">
          <StyledTableHead>
            <StyledTableRow>
              {headers.map((header, index) => (
                <StyledTableCell
                  key={index}
                  rightBorder={index < headers.length - 1}
                >
                  {header.text}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {rows.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <StyledTableCell
                    key={cellIndex}
                    rightBorder={cellIndex < headers.length - 1}
                    bold={cellIndex === 0}
                    leftAlignHeader={cellIndex === 0}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        {cell.Icon &&
                          React.cloneElement(cell.Icon, { style: inlineIcon })}
                      </div>
                      <div>{cell.text}</div>
                    </div>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      {isMobile && <ScrollIndicator scrollRef={scrollRef} />}
    </div>
  );
};

export const VerticalSingleTable = ({
  headers,
  body,
}: {
  headers: { Icon: ReactElement; text: string }[];
  body: string;
}) => {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <StyledTableContainer fullWidth ref={scrollRef}>
        <StyledTable size="small" vertical>
          <StyledTableHead>
            <StyledTableRow vertical>
              {headers.map(({ Icon, text }) => {
                return (
                  <StyledTableCell
                    bold
                    leftAlignHeader
                    greenText
                    whiteBackground
                    rightBorder
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        {React.cloneElement(Icon, {
                          style: inlineIcon,
                          height: "20px",
                        })}
                      </div>
                      <div>{text}</div>
                    </div>
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody rightAlignBody>
            <Text variant={"body2"}>{body}</Text>
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      {isMobile && <ScrollIndicator scrollRef={scrollRef} />}
    </div>
  );
};

export const VerticalTable = ({
  rows,
}: {
  rows: { Icon: ReactElement; text: string; body: string }[];
}) => {
  const BORDER = `1px solid ${LIGHTER_CHARCOAL}`;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          borderRadius: "8px",
          border: BORDER,
          borderBottom: "none",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {rows.map((row, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  flex: "1 1 auto",
                  maxWidth: "50%",
                  background: "white",
                  padding: "8px 12px",
                  borderBottom: BORDER,
                  borderRight: BORDER,
                }}
              >
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                  }}
                >
                  {React.cloneElement(row.Icon, {
                    style: {
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    },
                  })}
                </div>
                <span
                  style={{
                    color: DARK_GREEN,
                    fontSize: "14px",
                    fontFamily: "Gilroy-Semibold",
                    lineHeight: "20px",
                    wordBreak: "break-word",
                  }}
                >
                  {row.text}
                </span>
              </div>

              <div
                style={{
                  flex: "1 1 auto",
                  padding: "8px 12px",
                  borderBottom: BORDER,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "50%",
                  wordBreak: "break-word",
                }}
              >
                <span
                  style={{
                    fontFamily: "Gilroy",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.3px",
                    color: COLOR_GREYSCALE[900],
                  }}
                >
                  {row.body}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const VerticalBlankTable = ({
  rows,
}: {
  rows: { icon?: ReactElement; text: string }[];
}) => {
  return (
    <div
      style={{
        margin: "12px 0px",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        gap: "12px",
        borderRadius: "8px",
        background: "white",
      }}
    >
      {rows.map((row) => {
        return (
          <div
            key={row.text}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                height: "20px",
                width: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
              }}
            >
              {row.icon ? (
                React.cloneElement(row.icon, {
                  style: {
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  },
                })
              ) : (
                <li
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    color: COLOR_GREYSCALE[900],
                  }}
                />
              )}
            </div>
            <Text variant="body2">{row.text}</Text>
          </div>
        );
      })}
    </div>
  );
};
