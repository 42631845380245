import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { AgeGroup, AgeRange, Gender, HealthCondition } from "../../models";
import { listAgeRangeOptions } from "../../services/contentful/agerange";
import { listGenderOptions } from "../../services/contentful/gender";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import LeftAlignedLabel from "../LeftAlignedLabel";
import GenderIdentificationNotice from "../household/GenderIdentificationNotice";
import HealthConditionsField from "../household/HealthConditionsField";
import { IconButton } from "@material-ui/core";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_HOUSEHOLD_EVENT } from "../../events";

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    marginTop: spacing(3),
  },
  title: {
    marginBottom: spacing(2),
  },
  formTitle: {
    marginBottom: spacing(2),
  },
  formError: {
    color: palette.error.main,
    marginBottom: spacing(2),
  },
  fieldset: {
    border: 0,
    padding: 0,
    margin: 0,
    marginBottom: spacing(1),
  },
  fieldsetLegend: {
    ...typography.h3,
    marginBottom: 0,
  },
  select: {
    "& .MuiInputBase-root": {
      margin: 0,
      marginTop: "16px",
    },
    "& .MuiSelect-root": {
      background: WHITE,
    },
  },
  actions: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginRight: spacing(2),
    textTransform: "uppercase",
  },
  chip: {
    padding: "2px 8px",
    borderRadius: "4px",
    background: COLOR_GREYSCALE[200],
    fontFamily: "'Gilroy-Semibold'",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.3px",
  },
  deleteButton: {
    padding: 0,
    color: "#242629",
  },
  chipAndDeleteContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export interface FormValues {
  ageGroup?: AgeGroup;
  ageRange?: AgeRange;
  gender?: Gender;
  healthConditions: HealthCondition[];
  consentCopy?: string;
  consented?: boolean;
}

export interface FormErrors {
  consent?: string;
  ageGroup?: string;
  ageRange?: string;
  gender?: string;
  healthConditions?: string;
}

export const CONSENT_COPY =
  "I consent to Healthylife collecting my breastfeeding and pregnancy status for the purpose of providing the Healthylife Food Tracker.";

export const ConsentCheckbox = ({
  consentValue,
  onConsentChange,
  consentError,
}: {
  consentValue: boolean;
  consentError: string | undefined;
  onConsentChange: (consented: boolean) => void;
}) => {
  const useStyles = makeStyles(({ typography, spacing }) => ({
    fieldset: {
      border: 0,
      padding: 0,
      margin: 0,
      marginTop: "16px",
    },
    fieldsetLegend: {
      ...typography.h3,
      marginBottom: 0,
    },
    divider: {
      marginTop: "16px",
      marginBottom: "16px",
      border: "1px solid #E1E1E1",
    },
    healthConditionFormControlLabel: {
      "& .MuiTypography-root": {
        margin: 0,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",
      },
    },
    consentFormControlLabel: {
      "& .MuiTypography-root": {
        margin: 0,
      },
    },
    consentCopy: {
      margin: 0,
      "& > li": {
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        fontFamily: "Gilroy",
        color: COLOR_GREYSCALE[900],
      },
    },
    alertText: {
      color: "#DE0000",
      marginBottom: spacing(3),
      paddingLeft: "46px",
    },
  }));
  const classes = useStyles();
  return (
    <div style={{ marginBottom: "40px" }}>
      <hr className={classes.divider} />
      <FormControlLabel
        checked={consentValue}
        className={classes.consentFormControlLabel}
        onChange={(e, checked) => onConsentChange(checked)}
        control={
          <Checkbox
            color="primary"
            style={
              Boolean(consentError)
                ? {
                    color: "#DE0000",
                  }
                : {}
            }
          />
        }
        label={
          <ul className={classes.consentCopy}>
            <li>{CONSENT_COPY}</li>
            <li>
              Recommended servings across the main food groups increase for
              those who are pregnant or breastfeeding.
            </li>
          </ul>
        }
      />
      {Boolean(consentError) && (
        <Typography variant="caption" className={classes.alertText}>
          {consentError}
        </Typography>
      )}
    </div>
  );
};

interface HouseholdAddFormV2Props extends HTMLAttributes<HTMLFormElement> {
  ageGroupFormNumber: number;
  formValues: FormValues;
  formErrors: FormErrors;
  onFormDelete: () => void;
  onFormValuesChange: (arg0: FormValues) => void;
  index: number;
  showDeleteButton: boolean;
  isOnboarding?: boolean;
}

export const HouseholdAddFormV2: FC<HouseholdAddFormV2Props> = ({
  ageGroupFormNumber,
  formValues,
  formErrors,
  onFormDelete,
  onFormValuesChange,
  index,
  showDeleteButton,
  isOnboarding = false,
  ...rest
}) => {
  const classes = useStyles();
  const { track } = useEvents();
  const [ageRangeOptions, setAgeRangeOptions] = useState<AgeRange[]>([]);
  const [genderOptions, setGenderOptions] = useState<Gender[]>([]);

  useEffect(() => {
    const initOptions = async () => {
      if (formValues.ageGroup) {
        setAgeRangeOptions(await listAgeRangeOptions(formValues.ageGroup));
        setGenderOptions(await listGenderOptions(formValues.ageGroup));
      }
    };
    initOptions();
  }, [formValues.ageGroup]);

  const handleSelectFieldChanged = (name: string) => async (event: any) => {
    const parsedEvent = JSON.parse(event.target.value);
    if (name === "gender") {
      if (parsedEvent.name === "Female") {
        track(ONBOARDING_HOUSEHOLD_EVENT.GENDER_FEMALE);
      } else {
        track(ONBOARDING_HOUSEHOLD_EVENT.GENDER_MALE);
      }
    } else if (name === "ageRange") {
      const name = parsedEvent.name;
      const ageGroup = name.replace(" - ", "_");
      track(ONBOARDING_HOUSEHOLD_EVENT.AGE_GROUP + ageGroup);
    }
    onFormValuesChange({
      ...formValues,
      healthConditions: [],
      [name]: parsedEvent,
    });
  };

  const handleHealthConditionsChanged = (
    healthConditions: HealthCondition[]
  ) => {
    onFormValuesChange({
      ...formValues,
      healthConditions,
      consented: formValues.consented,
      consentCopy: formValues.consented ? CONSENT_COPY : undefined,
    });
  };
  if (!formValues) return null;
  return (
    <form {...rest}>
      <div className={classes.chipAndDeleteContainer}>
        <span className={classes.chip}>
          {formValues.ageGroup?.name} {ageGroupFormNumber}
        </span>
        {showDeleteButton && (
          <IconButton
            aria-label="delete"
            className={classes.deleteButton}
            onClick={onFormDelete}
          >
            <RemoveCircleOutlineOutlinedIcon />
          </IconButton>
        )}
      </div>
      <fieldset className={classes.fieldset}>
        <LeftAlignedLabel
          id={`select-ageRange-${index}`}
          label="Age Group"
          style={
            Boolean(formErrors.ageRange)
              ? { marginBottom: "6px" }
              : { marginTop: "16px" }
          }
        >
          <TextField
            data-cs-mask=""
            id={`select-ageRange-${index}`}
            name="ageRange"
            className={`${classes.select}`}
            select
            fullWidth
            variant="outlined"
            placeholder=""
            value={JSON.stringify(formValues.ageRange)}
            onChange={handleSelectFieldChanged("ageRange")}
            error={Boolean(formErrors.ageRange)}
            helperText={formErrors.ageRange}
            aria-label={`${formValues.ageGroup?.name} age group select`}
          >
            {ageRangeOptions.map((ar) => (
              // @ts-ignore
              <MenuItem key={ar.id} value={JSON.stringify(ar)}>
                {ar.name}
              </MenuItem>
            ))}
          </TextField>
        </LeftAlignedLabel>
        <LeftAlignedLabel
          id="select-gender"
          label="Gender"
          style={
            Boolean(formErrors.gender)
              ? { marginBottom: "6px" }
              : { marginTop: "16px" }
          }
        >
          <TextField
            data-cs-mask=""
            id="select-gender"
            name="gender"
            className={classes.select}
            select
            fullWidth
            variant="outlined"
            placeholder=""
            value={JSON.stringify(formValues.gender)}
            onChange={handleSelectFieldChanged("gender")}
            error={Boolean(formErrors.gender)}
            helperText={formErrors.gender}
            aria-label={`${formValues.ageGroup?.name} gender select`}
          >
            {genderOptions.map((g) => (
              // @ts-ignore
              <MenuItem key={g.id} value={JSON.stringify(g)}>
                {g.name}
              </MenuItem>
            ))}
          </TextField>
        </LeftAlignedLabel>
      </fieldset>
      {index === 0 && (
        <GenderIdentificationNotice isOnboarding={isOnboarding} />
      )}
      <HealthConditionsField
        formErrors={formErrors}
        selectedAgeRange={formValues.ageRange}
        selectedGender={formValues.gender}
        values={formValues.healthConditions}
        onConditionsChange={handleHealthConditionsChanged}
        isOnboarding={isOnboarding}
      />
    </form>
  );
};
