import { Button, makeStyles } from "@material-ui/core";
import useEvents from "../../../hooks/useEvents";
import { useContext } from "react";
import { RecipeContext } from "../../../components/providers/RecipeProvider";
import { RECIPES_EVENTS } from "../../../constants";
import { COLOR_GREYSCALE } from "../../../theme";

const RecipeApplyCancelButtons = () => {
  const { track } = useEvents();
  const {
    setToggleFilter,
    availableFilters,
    setTempFilters,
    filteredRecipes,
    setFilteredRecipes,
    setCurrentFilters,
  } = useContext(RecipeContext);

  const classes = makeStyles(() => ({
    root: {
      marginTop: "16px",
      paddingTop: "16px",
      paddingBottom: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
    },
    button: {
      padding: "8px 12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      boxSizing: "border-box",
      width: "106px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "6px 12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      boxSizing: "border-box",
      width: "106px",
      boxShadow: "none",
      textTransform: "initial",
    },
  }))();

  return (
    <div className={classes.root}>
      <Button
        variant={"contained"}
        color={"default"}
        className={classes.buttonCancel}
        onClick={() => {
          setTempFilters(filteredRecipes);
          track(RECIPES_EVENTS.RLP_FILTER_CLOSE);
          setToggleFilter(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant={"contained"}
        color={"primary"}
        className={classes.button}
        onClick={() => {
          setFilteredRecipes(availableFilters);
          setCurrentFilters(availableFilters);
          setTempFilters(availableFilters);
          track(RECIPES_EVENTS.RLP_FILTER_APPLY_FILTER);
          setToggleFilter(false);
        }}
      >
        Apply
      </Button>
    </div>
  );
};

export default RecipeApplyCancelButtons;
