import { makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import NutrientBar from "./NutrientBar";
import { ReactComponent as IndicatorTriangle } from "../../icons/day-indicator-triangle.svg";
import {
  getBarYAxisMaxValueLabel,
  getBarYAxisLabels,
  kFormatter,
} from "./utils";
import { NutrientTrackerTypes } from "../../models";
import useNutrients from "../../hooks/useNutrients";
import { TimeFrame } from "../../constants";
import TrolleyLoader from "../../components/TrolleyLoader";
import { COLOR_FUNCTIONAL_RED, COLOR_GREYSCALE } from "../../theme";

interface NutrientTrackerBarChartProps {
  timeFrame: TimeFrame;
  type: NutrientTrackerTypes["type"];
  saltTarget: number;
}

const NutrientTrackerBarChart: FC<NutrientTrackerBarChartProps> = ({
  type,
  timeFrame,
  saltTarget,
}) => {
  const { nutrientsHistory, nutrientsHistoryLoading } = useNutrients();
  const [initialNutrientsLoad, setInitialNutrientsLoad] =
    useState<boolean>(true);

  useEffect(() => {
    if (!nutrientsHistoryLoading && initialNutrientsLoad) {
      setInitialNutrientsLoad(false);
    }
  }, [initialNutrientsLoad, nutrientsHistoryLoading]);

  const [yAxisLabels, setYAxisLabels] = useState<number[]>([]);
  const [maxLabelValue, setMaxLabelValue] = useState<number>(0);
  const legends = {
    sugar: {
      totalText: "Total sugar",
      valueText: "Added sugar",
      totalColor: COLOR_GREYSCALE[200],
      valueColor: COLOR_GREYSCALE[600],
      borderColor: "transparent",
    },
    salt: {
      totalText: "Salt (on target)",
      valueText: "Salt (over target)",
      valueColor: COLOR_FUNCTIONAL_RED[100],
      totalColor: COLOR_GREYSCALE[200],
      borderColor: "transparent",
    },
    fat: {
      totalText: "Total fat",
      valueText: "Saturated fat",
      totalColor: COLOR_GREYSCALE[200],
      valueColor: COLOR_GREYSCALE[600],
      borderColor: "transparent",
    },
  };

  useEffect(() => {
    const totalValues = nutrientsHistory.map(
      (nutrient) => nutrient[type].totalValue
    );
    if (totalValues.some((value) => value !== null)) {
      const labelMaxNum = Math.max(
        ...(totalValues.filter(
          (value) => typeof value === "number"
        ) as number[])
      );
      const yAxisLabelMaxValue = getBarYAxisMaxValueLabel(labelMaxNum);
      const yAxisLabels = getBarYAxisLabels(yAxisLabelMaxValue);
      setMaxLabelValue(yAxisLabelMaxValue);
      setYAxisLabels(yAxisLabels);
    } else {
      setYAxisLabels([]);
      setMaxLabelValue(0);
    }
  }, [nutrientsHistory, type]);

  const useStyles = makeStyles(({ breakpoints }) => ({
    barChartWrapper: {
      display: "flex",
      gap: "5%",
    },
    barChart: {
      display: "flex",
      gap: "14px",
      width: "100%",
      maxHeight: "200px",
      height: timeFrame === "Month" ? "100%" : "152px",
      [breakpoints.up("md")]: {
        // width: "50%",
      },
    },
    barYAxis: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingBottom: timeFrame === "Month" ? "25px" : "0px",
    },
    verticalLabel: {
      writingMode: "vertical-rl",
      transform: "rotate(180deg)",
      margin: 0,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      marginRight: "4px",
    },
    yAxisLabels: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      height: "100%",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      color: COLOR_GREYSCALE[700],
      minWidth: "30px",
    },
    barsContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      [breakpoints.up("md")]: {
        gap: "16px",
      },
    },
    legends: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      margin: "0px auto 16px auto",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      color: COLOR_GREYSCALE[700],
    },
    legend: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: COLOR_GREYSCALE[900],
    },
    legendCircle: {
      width: "19px",
      height: "12px",
      borderRadius: "2px",
      border: "solid 2px",
      marginRight: "4px",
      boxSizing: "border-box",
    },
    timeFrameContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    timeFrame: {
      width: "90%",
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
    timeFrameDuration: {
      display: "flex",
      justifyContent: "flex-end",
      fontSize: "12px",
      lineHeight: "16px",
      maxWidth: "100px",
      width: "100%",
      marginRight: "8px",
      color: COLOR_GREYSCALE[700],
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      margin: 0,
    },
    indicatorTriangleContainer: {
      display: "flex",
      justifyContent: "center",
      width: "50px",
    },
    arrow: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    arrowHead: {
      position: "absolute",
      borderRight: `1px solid ${COLOR_GREYSCALE[200]}`,
      borderBottom: `1px solid ${COLOR_GREYSCALE[200]}`,
      height: "6px",
      width: "6px",
      left: "10px",
      transform: "rotate(135deg)",
      bottom: "3px",
    },
    arrowBody: {
      position: "absolute",
      left: "10px",
      bottom: "6px",
      width: "90%",
      height: "1px",
      backgroundColor: COLOR_GREYSCALE[200],
    },
    hideOnMobile: {
      display: "none",
      [breakpoints.up("lg")]: {
        display: "flex",
      },
    },
  }));
  const classes = useStyles();

  const getVerticalLabel = () => {
    switch (type) {
      case "sugar":
        return "sugar (grams)";
      case "salt":
        return "sodium (mg)";
      case "fat":
        return "fat (grams)";
      default:
        return "sugar (grams)";
    }
  };

  let delayCounter = 0;

  const getLegends = () => {
    return (
      <div className={classes.legends}>
        <div className={classes.legend}>
          <span
            className={classes.legendCircle}
            style={{
              backgroundColor: legends[type].totalColor,
              borderColor: "transparent",
            }}
          ></span>{" "}
          {legends[type].totalText}
        </div>
        <div className={classes.legend}>
          <span
            className={classes.legendCircle}
            style={{
              backgroundColor: legends[type].valueColor,
              borderColor: legends[type].borderColor,
            }}
          ></span>{" "}
          {legends[type].valueText}
        </div>
      </div>
    );
  };

  if (initialNutrientsLoad) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TrolleyLoader size={80} />
      </div>
    );
  }

  return (
    <>
      {getLegends()}
      <div className={classes.barChartWrapper}>
        <div className={classes.barChart}>
          <div className={classes.barYAxis}>
            <p className={classes.verticalLabel}>
              Amount of {getVerticalLabel()}
            </p>
            <div className={classes.yAxisLabels}>
              {yAxisLabels.map((label) => (
                <span>{kFormatter(label)} </span>
              ))}
            </div>
          </div>
          <div className={classes.barsContainer}>
            {nutrientsHistory.map((nutrientValues) => {
              const hasValue = nutrientValues[type].totalValue !== null;
              if (hasValue) delayCounter++;
              return (
                <>
                  <NutrientBar
                    month={nutrientValues?.month}
                    total={nutrientValues[type].totalValue}
                    value={nutrientValues[type].innerValue}
                    maxLabelValue={maxLabelValue}
                    type={type}
                    delayIndex={hasValue ? delayCounter - 1 : -1}
                    saltTarget={saltTarget}
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.timeFrameContainer}>
        <div className={classes.timeFrame}>
          <p className={classes.timeFrameDuration}>
            6 {timeFrame.toLowerCase()}s ago
          </p>
          <div className={classes.arrow}>
            <div className={classes.arrowHead}></div>
            <div className={classes.arrowBody}></div>
          </div>
          <div className={classes.indicatorTriangleContainer}>
            <IndicatorTriangle width="12" height="12" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NutrientTrackerBarChart;
