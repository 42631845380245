import { FC } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as FastFoodIcon } from "../../icons/fastfood-icon.svg";
import { COLOR_GREYSCALE } from "../../theme";
import { ReactComponent as ColorGroup } from "../../icons/color-groups.svg";

interface Props {
  emptyServings: boolean;
  discretionaryPercent: number;
}

const PERCENT_DISPLAY_THRESHOLD = 11;

const MainVsSometimesFoodsBar: FC<Props> = ({
  emptyServings,
  discretionaryPercent,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      height: "36px",
    },
    mainBar: {
      width: "100%",
      display: "flex",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      height: "28px",
      backgroundColor: "#104239",
    },
    mainPercent: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      marginLeft: theme.spacing(1.5),
      fontSize: 14,
      fontWeight: "bold",
      color: "#fff",
    },
    sometimesBar: {
      display: "flex",
      alignItems: "center",
      borderRadius: emptyServings ? "4px" : "none",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      justifyContent: "flex-end",
      backgroundColor: emptyServings ? COLOR_GREYSCALE[200] : "#FFD60A",
      height: "28px",
    },
    sometimesPercent: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1.5),
      fontSize: 14,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      color: COLOR_GREYSCALE[900],
    },
    barContainer: {
      display: "flex",
      alignItems: "center",
      borderRight: emptyServings ? "none" : `2px solid #104239`,
      height: "100%",
    },
  }));

  const classes = useStyles();

  // looks weird with the "good food" purple in it with the no recent shopping overlay
  const discPercent = emptyServings ? 100 : discretionaryPercent;
  const goodPercent = emptyServings ? 0 : 100 - discPercent;

  return (
    <div
      className={classes.root}
      aria-label={`Your shop overall shows you your split between main food groups and sometimes foods and drinks.
          ${
            emptyServings
              ? "You currently have no data for this select time period."
              : `Currently your split is ${goodPercent}% main food groups and ${discPercent}% sometimes foods`
          }`}
      role="group"
    >
      <div
        className={classes.barContainer}
        style={{
          width: `${goodPercent}%`,
        }}
      >
        <div className={classes.mainBar} aria-hidden={"true"}>
          {goodPercent > PERCENT_DISPLAY_THRESHOLD && (
            <Typography variant="body2" className={classes.mainPercent}>
              <ColorGroup aria-hidden="true" /> {goodPercent}%
            </Typography>
          )}
        </div>
      </div>

      <div
        className={classes.sometimesBar}
        style={{ width: `${discPercent}%` }}
        aria-hidden={"true"}
      >
        {!emptyServings && discPercent > PERCENT_DISPLAY_THRESHOLD && (
          <Typography variant="body2" className={classes.sometimesPercent}>
            {discPercent}% <FastFoodIcon />
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MainVsSometimesFoodsBar;
