import {
  Button,
  Checkbox,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as BarBackground } from "../../icons/fv-leaderboard-bg.svg";
import { ReactComponent as FVLeaderboardIcon } from "../../icons/fv-leaderboard-intro.svg";
import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import useConfetti from "../../hooks/useConfetti";
import { ConfettiContext } from "../../components/providers/ConfettiProvider";
import useMember from "../../hooks/useMember";
import { setMemberJoinFruitAndVegLeaderboard } from "../../services/members";
import useEvents from "../../hooks/useEvents";
import { FRUIT_AND_VEG_CHALLENGE_EVENTS } from "../../events";

interface FruitAndVegLeaderboardIntroProps {
  setTabValue: Dispatch<SetStateAction<string>>;
}

const FruitAndVegLeaderboardIntro = ({
  setTabValue,
}: FruitAndVegLeaderboardIntroProps) => {
  const [agree, setAgree] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { track } = useEvents();
  const { member } = useMember();

  const classes = makeStyles(({ breakpoints }) => ({
    barBackground: {
      position: "absolute",
      zIndex: 0,
      width: "333px",
      height: "313px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    introText: {
      position: "relative",
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: "#0A2822",
      margin: 0,
      marginBottom: "28px",
      zIndex: 1,
      [breakpoints.up("lg")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
    greenBgTextContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: 0,
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "84px",
      height: "11px",
      zIndex: 0,
      [breakpoints.up("lg")]: {
        width: "101px",
        height: "13px",
      },
    },
    fvText: {
      zIndex: 1,
      position: "relative",
    },
    leaderboardIcon: {
      display: "block",
      margin: "0 auto",
    },
    checkBox: {
      padding: 0,
      marginRight: "10px",
      marginTop: "32px",
    },
    disclaimerText: {
      fontFamily: "'Gilroy'",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[800],
      margin: 0,
      marginTop: "32px",
      marginBottom: "16px",
    },
    joinCheckbox: {
      maxWidth: "382px",
      margin: "0 auto",

      "& input[type=checkbox]": {
        width: "100%",
        height: "100%",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#104239",
      },
    },
    buttonContainer: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 0px",
      maxWidth: "382px",
      margin: "0 auto",
      columnGap: "24px",
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      color: WHITE,
      flex: 1,
      "&:disabled": {
        boxShadow: "none",
        opacity: "0.4",
        backgroundColor: "#104239",
        color: WHITE,
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      flex: 1,
    },
    canvas: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      pointerEvents: "none",
    },
  }))();

  const { attemptFruitAndVegCampaignConfetti } = useConfetti();

  useEffect(() => {
    attemptFruitAndVegCampaignConfetti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fruitAndVegCampaignConfettiRef } = useContext(ConfettiContext);

  const handleJoin = async () => {
    setLoading(true);
    if (member?.memberId) {
      await setMemberJoinFruitAndVegLeaderboard(member.memberId);
      track(FRUIT_AND_VEG_CHALLENGE_EVENTS.LEADERBOARD_OPT_IN);
    }
    setLoading(false);
  };

  return (
    <>
      <canvas className={classes.canvas} ref={fruitAndVegCampaignConfettiRef} />
      <BarBackground className={classes.barBackground} />
      <div>
        <h3 className={classes.introText}>
          Join our{" "}
          <div className={classes.greenBgTextContainer}>
            <GreenRectangle className={classes.greenRectangle} />
            <span className={classes.fvText}>Fruit &amp; Veg</span>
          </div>{" "}
          Challenge leaderboard
        </h3>
        <FVLeaderboardIcon className={classes.leaderboardIcon} />
        <Grid
          item
          container
          xs={12}
          className={classes.joinCheckbox}
          style={{
            paddingTop: "8px",
            paddingBottom: 0,
            flexWrap: "nowrap",
            alignItems: "flex-start",
          }}
        >
          <Checkbox
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
            className={classes.checkBox}
            inputProps={{ "aria-label": "Opt in to fruit and veg leaderboard" }}
          />
          <Grid container>
            <Grid item>
              <Typography className={classes.disclaimerText}>
                Opt-in to view and participate anonymously – your progress will
                be celebrated without revealing your identity.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          <Button
            variant={"contained"}
            color={"default"}
            className={classes.buttonCancel}
            onClick={() => {
              track(FRUIT_AND_VEG_CHALLENGE_EVENTS.LEADERBOARD_CANCEL_OPT_IN);
              setTabValue("purchasedItems");
            }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            className={classes.button}
            disabled={loading || !agree}
            onClick={handleJoin}
          >
            {loading ? "Loading..." : "View"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FruitAndVegLeaderboardIntro;
