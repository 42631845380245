import SidebarSettings from "./SidebarSettings";
import { Button, makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { useContext, useState } from "react";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import { Feedback } from "../feedback";
import { ReactComponent as SuccessIcon } from "../../icons/success.svg";
import CheckIcon from "@material-ui/icons/Check";

const SidebarFeedback = () => {
  const { setIsOpenSidebar, isOpenFeedback, setIsOpenFeedback } =
    useContext(SettingsContext);

  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);

  const classes = makeStyles(({ breakpoints }) => ({
    container: {
      backgroundColor: WHITE,
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      height: "100%",
      overflow: "auto",
      padding: "120px 24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    button: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
      "&:disabled": {
        boxShadow: "none",
      },
    },
    buttonCancel: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[800],
      backgroundColor: "transparent",
      border: `solid 2px ${COLOR_GREYSCALE[800]}`,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      columnGap: "8px",
      boxSizing: "border-box",
      width: "135px",
      boxShadow: "none",
      textTransform: "initial",
    },
    successTexts: {
      width: "100%",
      boxSizing: "border-box",
      textAlign: "center",
      display: "flex",
      rowGap: "8px",
      flexDirection: "column",
      "& h4": {
        margin: 0,
        fontSize: "20px",
        lineHeight: "26px",
        letterSpacing: "0.1px",
        fontFamily: "Gilroy-Bold",
        fontWeight: "normal",
        color: COLOR_GREYSCALE[900],
      },
      "& p": {
        margin: 0,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.3px",
        fontFamily: "Gilroy",
        color: COLOR_GREYSCALE[900],
      },
    },
  }))();

  return (
    <SidebarSettings
      sidebarOpen={isOpenFeedback}
      setSidebarOpen={setIsOpenFeedback}
      backButtonAction={() => {
        setFeedbackSent(false);
        setIsOpenFeedback(false);
        setIsOpenSidebar(true);
      }}
      contentContainerExtended={true}
    >
      {!feedbackSent ? (
        <Feedback
          DoneButton={
            <Button
              variant={"contained"}
              color={"primary"}
              endIcon={<CheckIcon />}
              className={classes.button}
            >
              Done
            </Button>
          }
          BackButton={
            <Button
              variant={"contained"}
              color={"default"}
              className={classes.buttonCancel}
              onClick={() => setIsOpenFeedback(false)}
            >
              Cancel
            </Button>
          }
          onDoneClick={() => setFeedbackSent(true)}
          inSidebar={true}
        />
      ) : (
        <div className={classes.container}>
          <SuccessIcon style={{ marginBottom: 32 }} />
          <div className={classes.successTexts}>
            <h4>Success!</h4>
            <p>
              Thank you for answering this survey.
              <br />
              Your feedback is highly appreciated!
            </p>
          </div>
        </div>
      )}
    </SidebarSettings>
  );
};

export default SidebarFeedback;
