import { makeStyles } from "@material-ui/core";
import { InfoModalContainer } from "../../../components-2/InfoModal";
import { FoodGroup } from "../../../models";
import { COLOR_TEAL } from "../../../theme";
import AboutFoodGroupExplainerContent from "./AboutFoodGroupExplainerContent";
import {
  FOOD_GROUP_PAGES_EVENT,
  SOMETIMES_FOOD_PAGE_EVENT,
} from "../../../events";

const AboutFoodGroupExplainer = ({
  foodGroup,
}: {
  foodGroup: FoodGroup | "sometimes";
}) => {
  const useStyles = makeStyles(() => ({
    learnMore: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_TEAL[700],
      textDecoration: "none",
      border: 0,
      backgroundColor: "transparent",
      borderBottom: `solid 1px ${COLOR_TEAL[700]}`,
      margin: 0,
      marginRight: "auto",
      padding: 0,
    },
  }));
  const classes = useStyles();
  return (
    <InfoModalContainer
      eventName={
        foodGroup === "sometimes"
          ? SOMETIMES_FOOD_PAGE_EVENT.LEARN_MORE_OPEN
          : FOOD_GROUP_PAGES_EVENT.LEARN_MORE_OPEN
      }
      eventCloseName={
        foodGroup === "sometimes"
          ? SOMETIMES_FOOD_PAGE_EVENT.LEARN_MORE_CLOSE
          : FOOD_GROUP_PAGES_EVENT.LEARN_MORE_CLOSE
      }
      eventParams={{ foodgroup: foodGroup }}
      button={<button className={classes.learnMore}>Learn more</button>}
    >
      <AboutFoodGroupExplainerContent foodGroup={foodGroup} />
    </InfoModalContainer>
  );
};

export default AboutFoodGroupExplainer;
