import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  AggregateDailyServings,
  ConsentHistoryRecord,
  Member,
  NewFeaturesAcknowledgementRecord,
  ShoppingAllocation,
} from "../models";
import { track } from "./events";
import { Collections, Configurations, db, Fields, Shards } from "./firebase";
import { PROXIED_CLOUD_FUNCTIONS_URL } from "../constants";
import { getVisitCount } from "./localStorage";
import { GOALS } from "../components-2/goals/config";
import {
  shouldShowFruitsAndVegChallenge,
  shouldShowSwapsCampaign,
} from "../utils/misc";
import { DIETARY_PREFERENCE_EVENTS, GOALS_EVENTS } from "../events";
import { DIETARY_PREFERENCES } from "../components-2/dietary-preference/config";

const NO_VERSION = -1;

const getMemberRef = (memberId: string) =>
  doc(db, Collections.Members, memberId);

const currentDateTimeMillis = () => DateTime.utc().toMillis();

export const storeNewFeaturesAcknowledgement = async (
  memberId: string,
  version: number
) => {
  const newFeaturesAckDocRef = doc(
    db,
    Collections.Members,
    memberId,
    Collections.NewFeaturesAcknowledgements,
    version.toString()
  );

  const promise = setDoc(newFeaturesAckDocRef, {
    version,
    date: currentDateTimeMillis(),
  });

  track(memberId, "New Features Acknowledged", { version });

  return promise;
};

export const subscribeToLatestNewFeaturesAcknowledgment = (
  memberId: string,
  onChange: (version: number) => void
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.NewFeaturesAcknowledgements
    ),
    orderBy(Fields.NewFeaturesAcknowledgement.Version, "desc"),
    limit(1)
  );

  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      onChange(NO_VERSION);
    }

    snapshot.forEach((doc) => {
      const record = doc.data() as NewFeaturesAcknowledgementRecord;
      onChange(record.version);
    });
  });
};

export const storeConsent = async (
  memberId: string,
  consented: boolean,
  legals?: { id: string; version: string }
) => {
  try {
    const memberRef = getMemberRef(memberId);
    const consentHistoryRef = collection(
      db,
      Collections.Members,
      memberId,
      Collections.DataUsageConsentHistory
    );

    const member: Member = {
      id: memberId,
      dataUsageConsented: consented,
      consentedClient: "hl-web",
    };

    const consentHistory = {
      consented,
      date: currentDateTimeMillis(),
      legals,
    };

    // unfortunately, we need to make these calls sequentially, and cannot use batches or transactions.
    // because the firestore rules use the dataUsageConsented flag but we use the most recent consentHistory record,  we get into a race condition where we try to fetch basket details and sometimes get rejected by the firestore rules
    //TODO: consider replicating legals to member so we don't need to look at history
    await setDoc(memberRef, member, { merge: true });
    await addDoc(consentHistoryRef, consentHistory);

    track(memberId, "Consent Updated", { consented, legals });
  } catch (e) {
    track(memberId, "Consent Update Failed", { error: e });
    throw e;
  }
};

export const subscribeToVersionAndConsentTime = (
  memberId: string,
  onChange: (
    latestConsentTime: number | null,
    oldestConsentTime: number | null,
    version: number,
    isNewUser?: boolean
  ) => void
) => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.DataUsageConsentHistory
    ),
    orderBy(Fields.DataUsageConsentHistory.Date, "desc")
  );

  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      onChange(null, null, NO_VERSION, true);
    } else {
      const latestConsent = snapshot.docs[0].data() as ConsentHistoryRecord;
      const oldestConsent = snapshot.docs.reduce((acc: any, doc) => {
        const record = doc.data() as ConsentHistoryRecord;
        if (record.consented) {
          return record;
        }
        return acc;
      }, null);

      if (latestConsent?.consented) {
        onChange(
          latestConsent?.date,
          oldestConsent?.date,
          latestConsent?.legals?.version
        );
      }
    }
  });
};

export const subscribeToMemberAndConsent = (
  memberId: string,
  onMemberChange: (member: {
    visitCount: number;
    marketingConsented: boolean;
    dataUsageConsented: boolean;
    shoppingAllocation?: ShoppingAllocation;
    feedbackSubmittedOrDismissed?: number;
    email: string;
    hasEmailBeenEnteredBefore: boolean;
    name: string;
    hasNameBeenEnteredBefore: boolean;
    hasClickedPointsPromo: boolean;
    showNutrientTracker: boolean;
    eligibleForFruitAndVegMayCampaign: boolean;
    joinedFruitAndVegMayCampaignLeaderboard: boolean;
    fruitAndVegMayCampaignJoinDate: string;
    goals: GOALS[] | null;
    dietaryPreferences: DIETARY_PREFERENCES[] | null;
    communicationWrapUp: boolean;
    communicationInsights: boolean;
    communicationExpertAdvice: boolean;
    privateFirstTimeConsentingToTracker: boolean;
    previouslySubscribedToInsights: boolean;
    dismissedInsightsBanner: boolean;
  }) => void
) =>
  onSnapshot(getMemberRef(memberId), (snapshot) => {
    const member = snapshot.data();
    onMemberChange({
      email: member?.email,
      hasEmailBeenEnteredBefore:
        member?.hasEmailBeenEnteredBefore === undefined
          ? member?.email !== null && member?.email !== undefined
          : member?.hasEmailBeenEnteredBefore,
      name: member?.name,
      hasNameBeenEnteredBefore:
        member?.hasNameBeenEnteredBefore === undefined
          ? member?.name !== null && member?.name !== undefined
          : member?.hasNameBeenEnteredBefore,
      visitCount: member?.visitCount,
      marketingConsented: member?.email !== null,
      dataUsageConsented: member?.dataUsageConsented || false,
      shoppingAllocation: member?.shoppingAllocation,
      feedbackSubmittedOrDismissed: member?.feedbackSubmittedOrDismissed,
      hasClickedPointsPromo: member?.hasClickedPointsPromo || false,
      showNutrientTracker: member?.showNutrientTracker || false,
      goals: member?.goals || null,
      dietaryPreferences: member?.dietaryPreferences || null,
      eligibleForFruitAndVegMayCampaign:
        member?.eligibleForFruitAndVegMayCampaign || false,
      joinedFruitAndVegMayCampaignLeaderboard:
        member?.joinedFruitAndVegMayCampaignLeaderboard || false,
      fruitAndVegMayCampaignJoinDate:
        member?.fruitAndVegMayCampaignJoinDate || null,
      communicationWrapUp: member?.communicationWrapUp,
      communicationInsights: member?.communicationInsights,
      communicationExpertAdvice: member?.communicationExpertAdvice,
      privateFirstTimeConsentingToTracker:
        member?.privateFirstTimeConsentingToTracker || false,
      previouslySubscribedToInsights: member?.previouslySubscribedToInsights || false,
      dismissedInsightsBanner: member?.dismissedInsightsBanner || false,
    });
  });

export const setShoppingAllocation = (
  memberId: string,
  shoppingAllocation: ShoppingAllocation
) => {
  const memberRef = getMemberRef(memberId);
  return setDoc(memberRef, { shoppingAllocation }, { merge: true });
};

export const subscribeToInstantUpdates = async (memberId: string) => {
  const memberRef = getMemberRef(memberId);
  await setDoc(memberRef, { communicationInsights: true }, { merge: true });
  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-add-to-marketing/${memberId}`
    );
  } catch {}
};

export const dismissInsightsBanner = async (memberId: string) => {
  const memberRef = getMemberRef(memberId);
  return setDoc(memberRef, { dismissedInsightsBanner: true }, { merge: true });
};

const handlePreviouslySubscribedToInsights = async (
  memberRef: any,
  currentCommunicationInsights: boolean,
  newCommunicationInsights: boolean
) => {
  if (currentCommunicationInsights && !newCommunicationInsights) {
    await updateDoc(memberRef, { previouslySubscribedToInsights: true });
  }
};

export const subscribeToMarketing = async (
  memberId: string,
  email: string,
  name: string,
  communicationExpertAdvice: boolean,
  communicationWrapUp: boolean,
  communicationInsights: boolean
) => {
  const memberRef = getMemberRef(memberId);
  const memberDoc = await getDoc(memberRef);
  const currentMember = memberDoc.data();

  await handlePreviouslySubscribedToInsights(
    memberRef,
    currentMember?.communicationInsights,
    communicationInsights
  );

  await setDoc(
    memberRef,
    {
      name,
      email,
      communicationExpertAdvice,
      communicationWrapUp,
      communicationInsights,
    },
    { merge: true }
  );

  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-add-to-marketing/${memberId}`
    );
  } catch {}
};

export const changeMarketingEmail = async (
  memberId: string,
  oldEmail: string,
  newEmail: string
) => {
  const memberRef = getMemberRef(memberId);
  await setDoc(memberRef, { oldEmailToRemove: oldEmail }, { merge: true });
  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-remove-from-marketing/${memberId}`
    );
  } catch {}
  await updateDoc(memberRef, {
    oldEmailToRemove: deleteField(),
    email: newEmail,
  });
  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-add-to-marketing/${memberId}`
    );
  } catch {}
};

export const unsubscribeFromMarketing = async (
  memberId: string,
  emailToRemove: string
) => {
  const memberRef = getMemberRef(memberId);
  const memberDoc = await getDoc(memberRef);
  const currentMember = memberDoc.data();

  await handlePreviouslySubscribedToInsights(
    memberRef,
    currentMember?.communicationInsights,
    false
  );

  await setDoc(
    memberRef,
    {
      oldEmailToRemove: emailToRemove,
      email: null,
      communicationExpertAdvice: false,
      communicationWrapUp: false,
      communicationInsights: false,
    },
    { merge: true }
  );
  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-remove-from-marketing/${memberId}`
    );
  } catch {}
  await updateDoc(memberRef, {
    oldEmailToRemove: deleteField(),
  });
};

export const removeMemberFromEmailMarketing = async (
  memberId: string,
  emailToRemove: string
) => {
  const memberRef = getMemberRef(memberId);
  await setDoc(memberRef, { oldEmailToRemove: emailToRemove }, { merge: true });
  try {
    await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/insights/food-tracker-user-remove-from-marketing/${memberId}`
    );
  } catch {}
  await updateDoc(memberRef, {
    oldEmailToRemove: deleteField(),
  });
};

export const setMemberGoals = async (
  memberId: string,
  goals: GOALS[] | null
) => {
  const memberRef = getMemberRef(memberId);
  if (goals === null || goals.length === 0) {
    track(memberId, GOALS_EVENTS.DELETED);
  } else {
    track(memberId, GOALS_EVENTS.SET, { goals });
  }
  return await setDoc(
    memberRef,
    { goals: goals ? (goals.length > 0 ? goals : null) : null },
    { merge: true }
  );
};

export const setMemberDietaryPreference = async (
  memberId: string,
  dietaryPreferences: DIETARY_PREFERENCES[] | null
) => {
  const memberRef = getMemberRef(memberId);
  if (dietaryPreferences === null || dietaryPreferences.length === 0) {
    track(memberId, DIETARY_PREFERENCE_EVENTS.DELETED);
  } else {
    track(memberId, DIETARY_PREFERENCE_EVENTS.SET, { dietaryPreferences });
  }
  return await setDoc(
    memberRef,
    {
      dietaryPreferences:
        dietaryPreferences && dietaryPreferences.length > 0
          ? dietaryPreferences
          : null,
    },
    { merge: true }
  );
};

export const setMemberEmail = async (
  memberId: string,
  email: string | null,
  hasEmailBeenEnteredBefore: boolean
) => {
  const memberRef = getMemberRef(memberId);
  return await setDoc(
    memberRef,
    { email, hasEmailBeenEnteredBefore },
    { merge: true }
  );
};

export const setMemberName = async (
  memberId: string,
  name: string | null,
  hasNameBeenEnteredBefore: boolean
) => {
  const memberRef = getMemberRef(memberId);
  return await setDoc(
    memberRef,
    { name, hasNameBeenEnteredBefore },
    { merge: true }
  );
};

export const setMemberEligibleForSwapsCampaign = async (memberId: string) => {
  if (!shouldShowSwapsCampaign()) {
    const docs = await getDoc(
      doc(db, Collections.Configurations, Configurations.FeatureFlags)
    );
    const featureFlags = docs.data();
    if (
      !featureFlags?.earlySwapsCampaign?.enable?.specificMembers?.includes(
        memberId
      )
    ) {
      return;
    }
  }

  const memberRef = getMemberRef(memberId);
  const memberSnap = await getDoc(memberRef);
  if (memberSnap.exists()) {
    const memberData = memberSnap.data();
    if (memberData?.eligibleForSwapsCampaign !== true) {
      return await setDoc(
        memberRef,
        {
          eligibleForSwapsCampaign: true,
          joinedFruitAndVegMayCampaignLeaderboard: false,
          fruitAndVegMayCampaignLeaderboardSyncedToBigQuery: false,
          swapsCampaignSyncedToBigQuery: false,
        },
        { merge: true }
      );
    }
  }
};

export const setMemberJoinFruitAndVegChallenge = async (memberId: string) => {
  if (!shouldShowFruitsAndVegChallenge()) {
    const docs = await getDoc(
      doc(db, Collections.Configurations, Configurations.FeatureFlags)
    );
    const featureFlags = docs.data();
    if (
      !featureFlags?.fruitAndVegChallenge?.enable?.specificMembers?.includes(
        memberId
      )
    ) {
      return;
    }
  }

  const memberRef = getMemberRef(memberId);
  const memberSnap = await getDoc(memberRef);
  if (memberSnap.exists()) {
    const memberData = memberSnap.data();
    if (memberData?.eligibleForFruitAndVegMayCampaign !== true) {
      const currentDate = new Date();
      const perthDate = currentDate
        .toLocaleString("en-US", {
          timeZone: "Australia/Perth",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");

      return await setDoc(
        memberRef,
        {
          eligibleForFruitAndVegMayCampaign: true,
          joinedFruitAndVegMayCampaignLeaderboard: false,
          fruitAndVegMayCampaignLeaderboardSyncedToBigQuery: false,
          fruitAndVegMayCampaignSyncedToBigQuery: false,
          fruitAndVegMayCampaignJoinDate: `${perthDate[2]}-${perthDate[0]}-${perthDate[1]}`,
        },
        { merge: true }
      );
    } else {
      return await setDoc(
        memberRef,
        {
          eligibleForFruitAndVegMayCampaign: false,
          joinedFruitAndVegMayCampaignLeaderboard: false,
          fruitAndVegMayCampaignLeaderboardSyncedToBigQuery: false,
        },
        { merge: true }
      );
    }
  }
};

export const setMemberJoinFruitAndVegLeaderboard = async (memberId: string) => {
  const memberRef = getMemberRef(memberId);
  const memberSnap = await getDoc(memberRef);
  if (memberSnap.exists()) {
    return await setDoc(
      memberRef,
      {
        joinedFruitAndVegMayCampaignLeaderboard: true,
        fruitAndVegMayCampaignLeaderboardSyncedToBigQuery: false,
      },
      { merge: true }
    );
  }
};

export const incrementVisitCount = async (memberId: string) => {
  const memberRef = getMemberRef(memberId);
  const memberSnap = await getDoc(memberRef);

  let visitCount = 0;
  if (memberSnap.exists()) {
    const memberData = memberSnap.data();
    const legacyLocalStorageVisitCount = getVisitCount();
    if (!memberData.visitCount) {
      visitCount = isNaN(legacyLocalStorageVisitCount)
        ? 1
        : legacyLocalStorageVisitCount + 1;
    } else {
      visitCount =
        legacyLocalStorageVisitCount > memberData.visitCount
          ? legacyLocalStorageVisitCount + 1
          : memberData.visitCount + 1;
    }
  }

  return await setDoc(memberRef, { visitCount }, { merge: true });
};

export const getMostRecentShop = async (
  memberId: string
): Promise<DateTime | null> => {
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.AggregateDailyServingsSharded
    ),
    where(
      Fields.AggregateDailyServingsSharded.Shard,
      "in",
      Shards.AggregateDailyServingsSharded
    ),
    orderBy(Fields.AggregateDailyServingsSharded.PurchaseDate, "desc"),
    limit(1)
  );

  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return null;
  }

  const data = snapshot.docs[0].data() as AggregateDailyServings;
  return DateTime.fromMillis(data.purchaseDate);
};

export const getFruitAndVegData = async (jwt: string) => {
  try {
    return await fetch(
      `${PROXIED_CLOUD_FUNCTIONS_URL}/fruit-and-veg/${jwt}`
    ).then((res) => res.json());
  } catch {}
};
