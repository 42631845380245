import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { CSSProperties, FC } from "react";
import useEvents from "../../hooks/useEvents";
import { RECIPES_EVENTS } from "../../constants";
import { COLOR_GREYSCALE, LEAFY_GREEN } from "../../theme";

interface Props {
  index: number;
  title: string;
  image: string;
  prepTime: string;
  cookTime: string;
  complexity: string;
  description: string;
  url: string;
  style?: CSSProperties;
  smallDesktop?: boolean;
}

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: "flex",
    borderRadius: "8px",
    padding: "8px",
    backgroundColor: COLOR_GREYSCALE[50],
    gap: "16px",
    textDecoration: "none",
    [breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "28px",
      padding: "24px",
      letterSpacing: "0.1px",
    },
  },
  recipeImage: {
    display: "block",
    width: "80px",
    height: "70px",
    borderRadius: "4px",
    marginBottom: "auto",
    [breakpoints.up(375)]: {
      width: "100px",
      height: "100px",
    },
    [breakpoints.up("md")]: {
      width: "200px",
      height: "100%",
    },
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  recipeTitle: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    color: COLOR_GREYSCALE[900],
    margin: 0,
    marginBottom: "8px",
    [breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  description: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    fontFamily: "Gilroy",
    color: COLOR_GREYSCALE[700],
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 2,
    display: "-webkit-box",
    boxOrient: "vertical",
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  procedures: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "16px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontFamily: "Gilroy",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: "#585C60",
  },
  difficulty: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Gilroy",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: "#585C60",
  },
  amount: {
    marginTop: "4px",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    color: COLOR_GREYSCALE[900],
  },
  complexity: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  complexityDots: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
  round: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#FFF",
    border: "0.5px solid #C5C5C5",
  },
  roundColored: {
    backgroundColor: LEAFY_GREEN[300],
    border: `1px solid ${LEAFY_GREEN[300]}`,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentText: {
    color: COLOR_GREYSCALE[700],
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
  },
  divider: {
    display: "flex",
    height: "100%",
    width: "1px",
    margin: "0 10px",
    backgroundColor: COLOR_GREYSCALE[100],
    [breakpoints.up("md")]: {
      margin: "0 24px",
    },
  },
}));

const RecipeCard: FC<Props> = ({
  index,
  title,
  image,
  description,
  cookTime,
  prepTime,
  complexity,
  url,
  style = {},
  smallDesktop = false,
}) => {
  const classes = useStyles();
  const generateComplexityLevel = () => {
    const complexityLevel: number = Number(complexity);
    return (
      <div className={classes.complexityDots}>
        <div
          className={`${classes.round} ${
            1 <= complexityLevel && classes.roundColored
          }`}
        ></div>
        <div
          className={`${classes.round} ${
            2 <= complexityLevel && classes.roundColored
          }`}
        ></div>
        <div
          className={`${classes.round} ${
            3 <= complexityLevel && classes.roundColored
          }`}
        ></div>
        <div
          className={`${classes.round} ${
            4 <= complexityLevel && classes.roundColored
          }`}
        ></div>
      </div>
    );
  };
  const { track } = useEvents();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <a
      href={
        url +
        "?utm_medium=referral&utm_source=healthylife&utm_campaign=food-tracker-recipe&icmpid=healthylife-food-tracker-recipe"
      }
      className={classes.wrapper}
      rel="noreferrer"
      id={`recipe-item-${index}`}
      target="_blank"
      onClick={() => {
        track(RECIPES_EVENTS.OPENED_RECIPE, { url });
      }}
      style={{
        ...style,
        ...(smallDesktop &&
          !isMobile && {
            alignItems: "center",
          }),
      }}
    >
      <img
        src={image}
        alt={title}
        className={classes.recipeImage}
        loading="lazy"
        style={{
          ...(smallDesktop &&
            !isMobile && {
              width: "143px",
              height: "fit-content",
              margin: 0,
            }),
        }}
      />
      <div className={classes.cardDetails}>
        <h2 className={classes.recipeTitle}>{title}</h2>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <div
          className={classes.procedures}
          style={{
            ...(smallDesktop &&
              !isMobile && {
                marginTop: "8px",
              }),
          }}
        >
          <div className={classes.label}>
            <div className={classes.content}>
              <span className={classes.contentText}>PREP</span>
              <span className={classes.amount}>
                {Number(prepTime) > 0 ? prepTime + "m" : "-"}
              </span>
            </div>
            <div className={classes.divider}></div>
          </div>
          <div className={classes.label}>
            <div className={classes.content}>
              <span className={classes.contentText}>COOK</span>
              <span className={classes.amount}>
                {Number(cookTime) > 0 ? cookTime + "m" : "-"}
              </span>
            </div>
            <div className={classes.divider}></div>
          </div>
          <div className={classes.difficulty}>
            <span className={classes.contentText}>DIFFICULTY</span>{" "}
            {generateComplexityLevel()}
          </div>
        </div>
      </div>
    </a>
  );
};

export default RecipeCard;
