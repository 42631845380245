import { createContext, FC, useContext, useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import { HouseholdMemberWithBenchmark } from "../../models";
import { withId } from "../../services/contentful/benchmarks";
import { subscribeToHousehold } from "../../services/households";
import { DefaultBenchmark } from "../../constants";
import { MemberContext } from "./MemberProvider";
import { BenchmarksContext } from "./BenchmarksProvider";

interface Props {}

export const HouseholdContext = createContext<
  HouseholdMemberWithBenchmark[] | null
>(null);

const HouseholdProvider: FC<Props> = ({ children }) => {
  const member = useContext(MemberContext);
  const benchmarks = useContext(BenchmarksContext);

  const [household, setHousehold] = useState<
    HouseholdMemberWithBenchmark[] | null
  >(null);

  const memberId = member?.memberId;

  useEffect(() => {
    if (!memberId || !benchmarks) return;

    const unsubscribe = subscribeToHousehold(memberId, (changes) => {
      const withBenchmarks = reverse(
        sortBy(
          changes.map((hm) => ({
            ...hm,
            benchmark: withId(benchmarks, hm.benchmarkId) ?? DefaultBenchmark,
          })),
          (hm) => hm.benchmark.ageRange.from
        )
      );
      setHousehold(withBenchmarks);
    });

    return () => {
      unsubscribe();
    };
  }, [memberId, benchmarks]);

  return (
    <HouseholdContext.Provider value={household}>
      {children}
    </HouseholdContext.Provider>
  );
};

export default HouseholdProvider;
