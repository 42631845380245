import { Typography, makeStyles } from "@material-ui/core";
import React, { FC, useContext } from "react";

import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
} from "../InfoModal";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import { FAQ_ACCORDION_EVENT, FOOD_GROUP_PAGES_EVENT } from "../../events";
import { FoodGroup } from "../../models";
import useEvents from "../../hooks/useEvents";
import { COLOR_TEAL } from "../../theme";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

interface Props {
  source?: string;
  foodGroup?: FoodGroup;
}

const RecipeExplainer: FC<Props> = ({ source, foodGroup }) => {
  const { track } = useEvents();
  const hideDP = useHideDietaryPreferences();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { setIsOpenPersonalisation } = useContext(SettingsContext);
  const faqTitles = [
    "What makes these recipes healthier?",
    "How will these recipes help me achieve a balanced diet?",
    "I’ve found a recipe, what should I do now?",
    "Are Healthier Recipes suitable for my dietary requirements?",
  ];
  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };
  const params = source === "foodgroup" ? { foodgroup: foodGroup } : undefined;

  const classes = makeStyles(() => ({
    dpToggleBtn: {
      padding: 0,
      border: 0,
      backgroundColor: "transparent",
      textDecoration: "underline",
      letterSpacing: "0.3px",
      cursor: "pointer",
      color: COLOR_TEAL[700],
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Gilroy",
      verticalAlign: "baseline",
    },
  }))();

  return (
    <InfoModalContainer
      eventName={FOOD_GROUP_PAGES_EVENT.RECIPES_HELP_OPEN}
      eventCloseName={FOOD_GROUP_PAGES_EVENT.RECIPES_HELP_CLOSE}
      eventParams={params}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalContent>
        <Typography variant="body1">
          Get inspiration for dinners and lunches, easy snack and lunchbox
          ideas, plus much more tasty goodness with our healthier recipes from
          Woolworths.
        </Typography>

        <Typography variant="body1">
          You can easily filter by food group, main ingredient and meal type to
          find exactly what you are after in seconds.
        </Typography>

        <Typography variant="body1">
          Are you below the recommended serves on one of your five main food
          groups? Use the food group filter to find recipes that will help you
          meet your recommended serves.
        </Typography>

        <Typography variant="h2" style={{ marginBottom: "32px" }}>
          FAQS
        </Typography>
        <AccordionContainer>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AccordionIcon />}
            >
              What makes these recipes healthier?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Our collection of healthier recipes from Woolworths has been
                created based on their balance across the five main food groups
                and the nutritional profile of their ingredients.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<AccordionIcon />}
            >
              How will these recipes help me achieve a balanced diet?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                You can filter by food group to find recipes that will help you
                meet your recommended serves. Check the balance of your shop,
                then head to recipes and be inspired.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordionChange("panel3", 2)}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              expandIcon={<AccordionIcon />}
            >
              I’ve found a recipe, what should I do now?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Select “read more” and you’ll be taken to the recipe detail page
                with ingredients, method and more. If you’re in the Woolworths
                app, you can choose to add to your list or straight to cart if
                you wish.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordionChange("panel4", 3)}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={<AccordionIcon />}
            >
              Are Healthier Recipes suitable for my dietary requirements?
            </AccordionSummary>
            <AccordionDetails>
              {hideDP ? (
                <Typography variant="body1">
                  Healthier Recipes makes it easy to filter and view healthier
                  recipes based on food group, main ingredient and meal type. It
                  does not take into account your individual circumstances,
                  health requirements or specific dietary requirements and
                  should not be used, or relied upon, as dietary, health or
                  medical advice.
                </Typography>
              ) : (
                <Typography variant="body1">
                  Healthier Recipes factors in your selected{" "}
                  <button
                    className={classes.dpToggleBtn}
                    onClick={() => setIsOpenPersonalisation("dietary")}
                  >
                    Dietary Preferences
                  </button>{" "}
                  and makes it easy to filter and view healthier recipes based
                  on food group, main ingredient and meal type. This assists in
                  finding recipes that align with your dietary or lifestyle
                  preferences. It's not a substitute for medical advice or
                  reviewing a product label or recipe in full.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default RecipeExplainer;
