import { ExpandMoreOutlined } from "@material-ui/icons";
import React, { ChangeEvent, FC, ReactElement } from "react";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./StyledAccordion";
import { COLOR_GREYSCALE } from "../../theme";

interface Props {
  title: ReactElement;
  className?: string;
  expanded?: boolean;
  onChange?: (_event: ChangeEvent<{}>, expanded: boolean) => void;
  id?: string;
  ariaControls?: string;
}

const Accordion: FC<Props> = ({
  title,
  className,
  children,
  onChange,
  expanded,
  id,
  ariaControls,
}) => (
  <StyledAccordion
    elevation={0}
    style={{ backgroundColor: "transparent" }}
    TransitionProps={{ unmountOnExit: true }}
    className={className}
    onChange={onChange}
    expanded={expanded}
  >
    <StyledAccordionSummary
      expandIcon={<ExpandMoreOutlined />}
      IconButtonProps={{
        size: "small",
        style: {
          background: "transparent",
          border: `solid 1px ${COLOR_GREYSCALE[300]}`,
          color: COLOR_GREYSCALE[800],
          padding: "5px",
        },
      }}
      id={id}
      aria-controls={ariaControls}
    >
      {title}
    </StyledAccordionSummary>
    <StyledAccordionDetails>{children}</StyledAccordionDetails>
  </StyledAccordion>
);

export default Accordion;
