import { Container, makeStyles } from "@material-ui/core";
import { HashRouter, Route, Switch, useLocation } from "react-router-dom";
import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import { FoodGroup, SOMETIMES_FOOD } from "./models";
import useMember from "./hooks/useMember";
import useHousehold from "./hooks/useHousehold";
import MainPage from "./pages/MainPage";
import SometimesFoodsPage from "./pages/SometimesFoodsPage";
import ScrollToTop from "./components/ScrollToTop";
import FaqPage from "./pages/FaqPage";
import { HttpStatusCodes, TimeFrameDuration } from "./constants";
import CenteredLoadingIndicator from "./components/CenteredLoadingIndicator";
import useEvents from "./hooks/useEvents";
import FoodGroupPage from "./pages/FoodGroupPage";
import { sendGtagEvent } from "./services/events";
import OptedOutPage from "./pages/OptedOutPage";
import FeedbackDialog from "./components-2/feedback/FeedbackDialog";
import RecipePage from "./pages/RecipePage";
import useFeatureFlags from "./hooks/useFeatureFlags";
import HealthierOptionsProductDisplayPage from "./pages/HealthierOptionsProductDisplayPage";
import HealthierOptionsProductListerPage from "./pages/HealthierOptionsProductListerPage";
import NutrientTrackerPage from "./pages/NutrientTrackerPage";
import MainVsSometimesFoodsPage from "./pages/MainVsSometimesPage";
import OnboardingPage from "./pages/OnboardingPage";
import MemberRoute from "./components/MemberRoute";
import Sidebar from "./components-2/sidebar-settings/Sidebar";
import { SelectedTimeFrameContext } from "./components/SelectedTimeFrameProvider";
import useServingsUntilYesterday from "./hooks/useServingsUntilYesterday";
import FruitAndVegChallengePage from "./pages/FruitAndVegChallengePage";
import { FruitAndVegCampaignContext } from "./components/providers/FruitAndVegCampaignPurchasesProvider";

interface Props {
  errorStatusCode?: HttpStatusCodes;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 375,
    maxWidth: 1328,
    padding: "0",
    backgroundColor: "#faf5ec !important",
  },
}));

const PageViewLogger = () => {
  const location = useLocation();
  const { track } = useEvents();

  useEffect(() => {
    track("Page Viewed", { path: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

const Routes: FC<Props> = ({ errorStatusCode }) => {
  const classes = useStyles();
  const [visitTracked, setVisitTracked] = useState(false);
  const { member, loadingMember, incrementMemberVisitCount } = useMember();
  const { loadingHousehold, household } = useHousehold();
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const { loadingServings } = useServingsUntilYesterday(
    TimeFrameDuration[timeFrame]
  );
  const { loadingFeatureFlags } = useFeatureFlags();
  const { loading: loadingFruitAndVegCampaignData } = useContext(
    FruitAndVegCampaignContext
  );

  const hasError = Boolean(errorStatusCode);
  const loading =
    !hasError &&
    (loadingMember ||
      loadingFruitAndVegCampaignData ||
      loadingHousehold ||
      loadingFeatureFlags ||
      loadingServings);

  const ready = !loading && !hasError;

  useEffect(() => {
    if (!member?.memberId) return;
    if (loading) {
      sendGtagEvent(member?.memberId, "Tracker Loading");
    } else {
      sendGtagEvent(member?.memberId, "Tracker Loaded");
    }
  }, [loading, member?.memberId]);

  useEffect(() => {
    if (!member?.memberId || loadingMember || visitTracked) return;
    incrementMemberVisitCount();
    setVisitTracked(true);
  }, [
    visitTracked,
    member?.memberId,
    incrementMemberVisitCount,
    loadingMember,
  ]);

  const memberCheck = useMemo(() => {
    return household.length > 0;
  }, [household]);

  return (
    <HashRouter>
      <Switch>
        <Fragment>
          {ready && (
            <Route exact path="/onboarding" component={OnboardingPage} />
          )}
          <Route exact path="/opt-out" component={OptedOutPage} />
          <Container className={classes.root} vaul-drawer-wrapper={""}>
            <ScrollToTop />
            <CenteredLoadingIndicator size={128} loading={loading} />
            {hasError && (
              <Route render={() => <MainPage error={errorStatusCode} />} />
            )}
            {ready && (
              <>
                <PageViewLogger />
                <FeedbackDialog />
                <Route path="/faq" component={FaqPage} />
                <MemberRoute
                  exact
                  path={`/food-group/${FoodGroup.Grains}`}
                  Page={<FoodGroupPage foodGroup={FoodGroup.Grains} />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path={`/food-group/${FoodGroup.Vegetables}`}
                  Page={<FoodGroupPage foodGroup={FoodGroup.Vegetables} />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path={`/food-group/${FoodGroup.Protein}`}
                  Page={<FoodGroupPage foodGroup={FoodGroup.Protein} />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path={`/food-group/${FoodGroup.Dairy}`}
                  Page={<FoodGroupPage foodGroup={FoodGroup.Dairy} />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path={`/food-group/${FoodGroup.Fruit}`}
                  Page={<FoodGroupPage foodGroup={FoodGroup.Fruit} />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path={`/food-group/${SOMETIMES_FOOD}`}
                  Page={<SometimesFoodsPage />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path="/healthier-options/pdp"
                  Page={<HealthierOptionsProductDisplayPage />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path="/healthier-options/plp"
                  Page={<HealthierOptionsProductListerPage />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path="/nutrient-tracker"
                  Page={<NutrientTrackerPage />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  path="/main-vs-sometimes"
                  Page={<MainVsSometimesFoodsPage />}
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  whenFalse={memberCheck}
                  path="/recipes"
                  Page={<RecipePage />}
                  redirectTo="/onboarding"
                />
                <MemberRoute
                  exact
                  whenFalse={memberCheck}
                  path="/settings"
                  redirectTo="/onboarding"
                  Page={<Sidebar />}
                />
                <MemberRoute
                  exact
                  whenFalse={memberCheck}
                  path="/fruit-and-veg-challenge"
                  redirectTo="/onboarding"
                  Page={<FruitAndVegChallengePage />}
                />
                <MemberRoute
                  exact
                  path="/"
                  whenFalse={memberCheck}
                  redirectTo="/onboarding"
                  Page={<MainPage />}
                />
              </>
            )}
          </Container>
        </Fragment>
      </Switch>
    </HashRouter>
  );
};

export default Routes;
