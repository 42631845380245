import { NutrientTrackerTypes } from "../../models";

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? ((Math.sign(num) * Math.abs(num)) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const getBarYAxisMaxValueLabel = (maxnum: number) => {
  let roundedMaxNum: number; // set max rounded num to be used as max num in yaxis labels

  // round to nearest nth 0
  if (maxnum <= 100) {
    roundedMaxNum = Math.round(maxnum / 10) * 10 + 10;
  } else if (maxnum <= 1000) {
    roundedMaxNum = Math.round(maxnum / 100) * 100 + 100;
  } else if (maxnum <= 10000) {
    roundedMaxNum = Math.round(maxnum / 1000) * 1000 + 500;
  } else if (maxnum <= 100000) {
    roundedMaxNum = Math.round(maxnum / 10000) * 10000 + 10000;
  } else if (maxnum <= 150000) {
    roundedMaxNum = Math.round(maxnum / 15000) * 15000 + 15000;
  } else if (maxnum <= 200000) {
    roundedMaxNum = Math.round(maxnum / 20000) * 20000 + 20000;
  } else {
    roundedMaxNum = Math.round(maxnum / 30000) * 30000 + 30000;
  }

  return roundedMaxNum;
};

export const getBarYAxisLabels = (maxnum: number) => {
  return [
    Math.ceil(maxnum),
    Math.ceil((maxnum / 5) * 4),
    Math.ceil((maxnum / 5) * 3),
    Math.ceil((maxnum / 5) * 2),
    Math.ceil(maxnum / 5),
    0,
  ];
};

export const getDifferenceFromLastMonth = (
  nutrientsHistory: any,
  type: NutrientTrackerTypes["type"]
) => {
  let currentMonth: number =
    nutrientsHistory[nutrientsHistory.length - 1][type].innerValue ?? 1;
  let lastMonth: number =
    nutrientsHistory[nutrientsHistory.length - 2][type].innerValue ?? 0;

  const result = (((currentMonth - lastMonth) / lastMonth) * 100).toFixed(0);

  if (
    isNaN(Number(result)) ||
    Number(result) === Infinity ||
    Number(result) === -Infinity
  ) {
    return 0;
  }

  return Number(result);
};
