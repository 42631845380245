import {
  Checkbox,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as FoodTrackerBasket } from "../icons/food-tracker-basket.svg";
import { ReactComponent as FoodTrackerLogo } from "../icons/food-tracker-logo.svg";
import { ReactComponent as ThreeStarsIcon } from "../icons/three-stars-icon.svg";
import { ReactComponent as BarBackground } from "../icons/bar-background.svg";
import useMember from "../hooks/useMember";
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { COLOR_GREYSCALE, DARK_GREEN } from "../theme";
import ExternalLink from "../components/ExternalLink";
import { useTheme } from "@material-ui/core/styles";
import {
  fetchTermsAndConditions,
  TermsAndConditionsContent,
} from "../services/contentful/termsandconditions";
import { sendGtagEvent } from "../services/events";
import useEvents from "../hooks/useEvents";
import { WELCOME_SCREEN_EVENT } from "../events";

const FoodTrackerLogos = () => {
  const useStyles = makeStyles(() => ({
    foodTrackerBasket: {
      marginRight: "16px",
    },
  }));

  const classes = useStyles();
  return (
    <Grid container alignItems={"center"}>
      <Grid item>
        <FoodTrackerBasket className={classes.foodTrackerBasket} />
      </Grid>
      <Grid item>
        <FoodTrackerLogo />
      </Grid>
    </Grid>
  );
};

const PersonaliseTrackerBanner = () => {
  const useStyles = makeStyles(() => ({
    container: {
      marginTop: "16px",
      display: "flex",
      borderRadius: "8px",
      background: "white",
      borderLeft: `8px solid ${DARK_GREEN}`,
      padding: "12px 12px 12px 8px",
    },
    iconContainer: {
      marginRight: "8px",
    },
    textContainer: {},
    header: {
      margin: 0,
      color: DARK_GREEN,
      fontSize: "16px",
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    body: {
      margin: 0,
      marginTop: "4px",
      fontFamily: "'Gilroy'",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[800],
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <ThreeStarsIcon />
      </div>
      <div className={classes.textContainer}>
        <Typography variant={"h4"} className={classes.header}>
          Personalise Food Tracker
        </Typography>
        <Typography variant={"body2"} className={classes.body}>
          The next couple of steps will ensure we can give you the best Food
          Tracker experience personalised to you.
        </Typography>
      </div>
    </div>
  );
};

const ConsentCheckbox = ({
  consented,
  handleConsentChange,
}: {
  consented: boolean;
  handleConsentChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const useStyles = makeStyles(() => ({
    container: {
      marginTop: "24px",
      display: "flex",
    },
    checkboxContainer: {
      marginRight: "8px",
    },
    checkbox: {
      padding: 0,
    },
    textContainer: {},
    header: {
      margin: 0,
      color: DARK_GREEN,
      fontSize: "16px",
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    body: {
      margin: 0,
      fontFamily: "'Gilroy'",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[600],
    },
    hyperLink: {
      textDecoration: "underline",
      color: DARK_GREEN,
    },
  }));

  const FOOD_TRACKER_TERMS_AND_CONDITIONS =
    "https://www.healthylife.com.au/healthylife-food-tracker-terms-and-conditions";

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          checked={consented}
          onChange={handleConsentChange}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
          className={classes.checkbox}
        />
      </div>
      <div className={classes.textContainer}>
        <Typography variant={"body2"} className={classes.body}>
          I consent to link my Everyday Rewards membership card to get access to
          Healthylife Food Tracker and agree to the{" "}
          <ExternalLink
            className={classes.hyperLink}
            href={FOOD_TRACKER_TERMS_AND_CONDITIONS}
            target="_blank"
          >
            Food Tracker Terms & Conditions
          </ExternalLink>
          .
        </Typography>
      </div>
    </div>
  );
};

const WelcomePage = ({
  StepperComponent,
}: {
  StepperComponent: ReactElement;
}) => {
  const { member, storeMemberConsent } = useMember();
  const [disableConsentedScreen, setDisableConsentedScreen] = useState(false);
  const isMemberConsented = useMemo(() => {
    if (disableConsentedScreen) {
      return false;
    } else {
      return !!member?.consented;
    }
  }, [disableConsentedScreen, member?.consented]);

  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    wrapper: {
      position: "relative",
      minWidth: "375px",
      minHeight: "100vh",
      overflow: "hidden",
      height: "fit-content",
      background: "#F6EFDF",
    },
    barBackground: {
      position: "absolute",
      zIndex: 0,
      [breakpoints.down("sm")]: {
        width: "490px",
        height: "462px",
        top: "36%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      [breakpoints.up("md")]: {
        width: "1047px",
        height: "1021px",
        top: "43%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
    container: {
      padding: "0px 24px",
      zIndex: 2,
      height: "100%",
      minHeight: "100vh",
      alignContent: "flex-start",
      position: "relative",
      flexDirection: "column",
      [breakpoints.down("sm")]: {
        paddingTop: "144px",
      },
      [breakpoints.up("md")]: {
        maxWidth: "600px",
        margin: "auto",
        paddingTop: "167px",
      },
    },
    welcomeHeader: {
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      margin: 0,
      color: COLOR_GREYSCALE[900],
    },
    linkText: {
      margin: 0,
      marginTop: "48px",
      fontSize: "20px",
      lineHeight: "26px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[700],
      textAlign: "center",
      [breakpoints.up("md")]: {
        marginTop: "100px",
      },
    },
    personaliseTrackerContainer: {
      marginTop: isMemberConsented ? "100px" : "0px",
      [breakpoints.up("md")]: {
        marginTop: isMemberConsented ? "100px" : "0px",
      },
    },
    alertText: {
      color: "#DE0000",
    },
  }));

  const classes = useStyles();
  const { track } = useEvents();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [consented, setConsented] = useState(member?.consented || false);
  const handleConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    track(WELCOME_SCREEN_EVENT.CONSENT_TICK, {
      consented: event.target.checked,
    });
    setConsented(event.target.checked);
  };

  const [termsAndConditionsContent, setTermsAndConditionsContent] =
    useState<TermsAndConditionsContent>();

  useEffect(() => {
    const init = async () => {
      setTermsAndConditionsContent(await fetchTermsAndConditions());
    };

    init();
  }, []);
  const handleNextValidation = async () => {
    track(WELCOME_SCREEN_EVENT.NEXT);
    if (member?.consented === true) {
      return true;
    } else if (consented) {
      const { sys } = termsAndConditionsContent!;
      setDisableConsentedScreen(true);
      await storeMemberConsent(sys.id, sys.publishedVersion);
      if (member?.memberId) {
        sendGtagEvent(member?.memberId, "Submit Application");
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.wrapper}>
      <BarBackground className={classes.barBackground} />
      <Container style={{ padding: 0 }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"flex-start"}
          wrap={"nowrap"}
          className={classes.container}
        >
          <Grid item>
            <Typography variant={"h1"} className={classes.welcomeHeader}>
              Welcome to
            </Typography>
          </Grid>
          <Grid item>
            <FoodTrackerLogos />
          </Grid>
          {!isMemberConsented && (
            <Grid item>
              <Typography variant={"h2"} className={classes.linkText}>
                Link your Everyday Rewards card to access your shopping insights
              </Typography>
            </Grid>
          )}
          <Grid item className={classes.personaliseTrackerContainer}>
            <PersonaliseTrackerBanner />
          </Grid>
          {!isMemberConsented && (
            <Grid item>
              <ConsentCheckbox
                handleConsentChange={handleConsentChange}
                consented={consented}
              />
            </Grid>
          )}
          {StepperComponent && (
            <Grid
              item
              xs={isMobile}
              container
              alignItems={"flex-end"}
              style={{
                marginTop: isMobile && isMemberConsented ? "100px" : "24px",
                marginBottom: "28px",
                padding: "0px 8px",
              }}
            >
              {React.cloneElement(StepperComponent, {
                customHandleNext: handleNextValidation,
                nextButtonDisabled: !consented,
              })}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default WelcomePage;
