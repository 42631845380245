import React, { FC, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useMember from "../../hooks/useMember";
import { setMemberJoinFruitAndVegChallenge } from "../../services/members";
import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import ExternalLink from "../../components/ExternalLink";
import { useMediaQuery, useTheme } from "@material-ui/core";
import FruitAndVegChallengeExplainer from "./FruitAndVegChallengeExplainer";

const HeaderAndBody: FC = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const useStyles = makeStyles(({ breakpoints }) => ({
    title: {
      letterSpacing: "-0.8px",
      maxWidth: "70%",
      fontSize: "28px",
      lineHeight: "32px",
      fontFamily: "Gilroy-bold",
      margin: "0",
      color: "#0A2822",
      zIndex: 1,
      position: "relative",
      [breakpoints.up("lg")]: {
        fontSize: "36px",
        lineHeight: "48px",
        maxWidth: "100%",
      },
    },
    paragraph: {
      width: "77%",
      fontFamily: "Gilroy-Semibold",
      fontSize: "14px",
      maxWidth: "287px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "#585C60",
      margin: 0,
      [breakpoints.up("md")]: {
        maxWidth: "77%",
      },
      [breakpoints.up("lg")]: {
        maxWidth: "100%",
      },
    },
    paragraphOrangeText: {
      color: "#FD6400",
    },
    healthierOptionsContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: -1,
    },
    healthierOptionsText: {
      zIndex: 2,
      position: "relative",
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 137,
      height: 18,
      zIndex: 1,
      [breakpoints.up("lg")]: {
        width: "173px",
        height: "25px",
        left: "49%",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>
        Join the {!isDesktop && <br />}
        <div className={classes.healthierOptionsContainer}>
          <GreenRectangle className={classes.greenRectangle} />
          <span className={classes.healthierOptionsText}>Fruit &amp; Veg</span>
        </div>{" "}
        Challenge!
      </h1>
      <p className={classes.paragraph}>
        From apples to frozen peas and even tinned beans,{" "}
        <span className={classes.paragraphOrangeText}>
          collect 5x points per dollar
        </span>{" "}
        on any product that contains fruit or veg serves.*
      </p>
    </>
  );
};

const TermsAndConditions = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    tac: {
      color: "#585C60",
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    link: {
      color: "#60A498",
      textDecoration: "underline",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <p className={classes.tac}>
        *Offer starts 00:01 AEST 08/05/2024 and ends 23:59 AEST 05/06/2024.{" "}
        <ExternalLink
          href={"https://www.healthylife.com.au/discover/food-tracker"}
          target="_blank"
          className={classes.link}
        >
          T&Cs apply.
        </ExternalLink>
      </p>
    </>
  );
};

const FruitVegImage = () => {
  //Understand this is a hacky solution, but this will be deleted in a month anyway :)
  const useStyles = makeStyles(({ breakpoints }) => ({
    fruitVegImage: {
      position: "absolute",
      width: "62%",
      maxWidth: "212px",
      top: 0,
      right: 0,
      [breakpoints.up("lg")]: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "115%",
        maxWidth: "none",
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const imgSrc = useMemo(() => {
    return `https://storage.googleapis.com/food-tracker-images-prod/fruit-veg-${
      isDesktop ? "desktop" : "mobile"
    }.png`;
  }, [isDesktop]);

  return <img className={classes.fruitVegImage} alt={"banana"} src={imgSrc} />;
};

const JoinChallengeButton = () => {
  const { member } = useMember();
  const useStyles = makeStyles(({ breakpoints }) => ({
    button: {
      textTransform: "none",
      padding: "10px 24px",
      borderRadius: "10px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      fontFamily: "Gilroy-Bold",
      [breakpoints.up("md")]: {
        width: "fit-content",
      },
      [breakpoints.up("lg")]: {
        height: "fit-content",
        width: "max-content",
        fontSize: "16px",
        lineHeight: "24px",
        marginRight: "42px",
      },
    },
  }));
  const classes = useStyles();

  const handleJoinChallenge = () => {
    if (member?.memberId) {
      setMemberJoinFruitAndVegChallenge(member.memberId);
    }
  };

  return (
    <Button
      color={"primary"}
      onClick={handleJoinChallenge}
      className={classes.button}
    >
      Join challenge
    </Button>
  );
};

export const FruitAndVegChallengeIntro: FC = () => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "12px",
      padding: "16px 24px 16px 24px",
      gap: "24px",
      background: "white",
      boxShadow: "0px 1px 2px 0px #0000001A",
      position: "relative",
      overflow: "hidden",
      [breakpoints.up("md")]: {
        marginBottom: "12px",
      },
      [`@media (min-width: ${1024}px) and (max-width: ${1162}px)`]: {
        marginBottom: "48px",
      },
      [breakpoints.up("lg")]: {
        flexDirection: "row",
        alignItems: "center",
        gap: "24px",
      },
    },
    titleAndParagraphContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    imageAndTextContainer: {},
    imageContainer: {
      position: "relative",
      width: "250px",
      display: "flex",
      flexGrow: 1,
      flexShrink: 0,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return (
      <div className={classes.root}>
        <div className={classes.imageAndTextContainer}>
          <FruitVegImage />
          <div className={classes.titleAndParagraphContainer}>
            <HeaderAndBody />
          </div>
        </div>
        <JoinChallengeButton />
        <TermsAndConditions />
        <FruitAndVegChallengeExplainer />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <FruitVegImage />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <HeaderAndBody />
        <TermsAndConditions />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <JoinChallengeButton />
        <FruitAndVegChallengeExplainer />
      </div>
    </div>
  );
};
