import { makeStyles } from "@material-ui/core";
import { ReactComponent as Chevron } from "../../icons/chevron-right.svg";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { Link } from "react-router-dom";
import { FoodGroup, FoodGroups } from "../../models";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { FoodGroupColorIntake, FoodGroupRoundedIconV2 } from "../../constants";
import useDiscretionaryPercentHistory from "../../hooks/useDiscretionaryPercentHistory";
import { isNonProdEnv } from "../../utils/debug";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";

interface Props {
  setInsightEmpty: Dispatch<SetStateAction<boolean>>;
}

const FoodGroupInsight = ({ setInsightEmpty }: Props) => {
  const [highlightedFoodgroup, setHighlightedFoodgroup] = useState<FoodGroup>(
    FoodGroup.Dairy
  );
  const [differenceValue, setDifferenceValue] = useState<number>(0);
  const [messageType, setMessageType] = useState<"positive" | "negative">(
    "positive"
  );

  const [foodGroupEmpty, setFoodGroupEmpty] = useState<boolean>(true);

  const { loading, accumulatedServings } = useDiscretionaryPercentHistory();
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  const getValuesPerFoodGroup = (foodgroup: FoodGroup) => {
    return accumulatedServings.map((servings) => {
      return servings[foodgroup];
    });
  };

  const getFoodgroupPercentageDifference = (foodgroup: FoodGroup) => {
    const values = getValuesPerFoodGroup(foodgroup);
    const currValue = values[accumulatedServings.length - 1];
    if (currValue === 0) {
      return 0;
    }
    const filteredPrevValues = values.filter((value) => value > 0);
    if (filteredPrevValues.length >= 2) {
      const prevValuesTotal = filteredPrevValues.reduce((a, b) => a + b, 0);
      const prevPercentAverage = Math.round(
        prevValuesTotal / filteredPrevValues.length
      );
      const difference = Math.round(
        ((currValue - prevPercentAverage) / prevPercentAverage) * 100
      );
      return difference;
    }
  };

  useEffect(() => {
    if (loading) return;
    const foodGroupDifferences = [];

    for (const FoodGroup in FoodGroups) {
      const foodGroup = FoodGroups[FoodGroup];
      const foodGroupDifference = getFoodgroupPercentageDifference(foodGroup);
      if (foodGroupDifference) {
        foodGroupDifferences.push({
          type: foodGroup,
          absVal: Math.abs(foodGroupDifference),
          value: foodGroupDifference,
        });
      }
    }

    const sortedDifferences = foodGroupDifferences.sort(
      (a, b) => b.absVal - a.absVal
    );

    if (
      sortedDifferences.length === 0 ||
      sortedDifferences.every((entry) => entry.absVal === 0)
    ) {
      setFoodGroupEmpty(true);
      setInsightEmpty(true);
      setHighlightedFoodgroup(FoodGroup.Dairy);
    } else {
      setFoodGroupEmpty(false);
      setInsightEmpty(false);
      setHighlightedFoodgroup(sortedDifferences[0].type);
      sortedDifferences[0].value! >= 0
        ? setMessageType("positive")
        : setMessageType("negative");
      setDifferenceValue(sortedDifferences[0].absVal);
    }

    if (isNonProdEnv()) {
      console.log("Log: accumulatedServings", accumulatedServings);
    }
    // eslint-disable-next-line
  }, [loading, accumulatedServings, timeFrame]);

  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      boxSizing: "border-box",
      padding: "16px 16px 6px",
      borderRadius: "8px",
      backgroundColor: FoodGroupColorIntake[highlightedFoodgroup],
      display: "flex",
      gap: "16px",
      height: "116px",
      [breakpoints.up("md")]: {
        height: "94px",
      },
    },
    icon: {
      width: "40px",
      height: "40px",
      flexShrink: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    text: {
      color: ["vegetables", "grains"].includes(highlightedFoodgroup)
        ? COLOR_GREYSCALE[900]
        : WHITE,
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      margin: 0,
    },
    showMe: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: ["vegetables", "grains"].includes(highlightedFoodgroup)
        ? COLOR_GREYSCALE[900]
        : WHITE,
      textDecoration: "none",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      paddingTop: "8px",
      paddingBottom: "8px",
      marginRight: "auto",
      marginTop: "auto",
      "& svg path": {
        width: "16px",
        height: "16px",
        stroke: ["vegetables", "grains"].includes(highlightedFoodgroup)
          ? COLOR_GREYSCALE[900]
          : WHITE,
      },
    },
  }))();

  const Icon = FoodGroupRoundedIconV2[highlightedFoodgroup!];

  const message = {
    [FoodGroup.Dairy]: {
      positive:
        "Your average Dairy & alternatives serves are up by xxxx% compared to usual.",
      negative:
        "Your average Dairy & alternatives serves are down by xxxx% compared to usual.",
    },
    [FoodGroup.Grains]: {
      positive: "Your average Grains serves are up by xxxx% compared to usual.",
      negative:
        "Your average Grains serves are down by xxxx% compared to usual.",
    },
    [FoodGroup.Vegetables]: {
      positive:
        "Your average Veg & legumes serves are up by xxxx% compared to usual.",
      negative:
        "Your average Veg & legumes serves are down by xxxx% compared to usual.",
    },
    [FoodGroup.Fruit]: {
      positive: "Your average Fruit serves are up by xxxx% compared to usual.",
      negative:
        "Your average Fruit serves are down by xxxx% compared to usual.",
    },
    [FoodGroup.Protein]: {
      positive:
        "Your average Meat & alternatives serves are up by xxxx% compared to usual.",
      negative:
        "Your average Meat & alternatives serves are down by xxxx% compared to usual.",
    },
  };

  const insightMessage = loading
    ? "Loading..."
    : message[highlightedFoodgroup][messageType].replaceAll(
        "xxxx",
        differenceValue.toString()
      );

  if (loading || foodGroupEmpty) return <></>;

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <div className={classes.content}>
        <p className={classes.text}>{insightMessage}</p>

        <Link
          to={`/food-group/${highlightedFoodgroup}`}
          className={classes.showMe}
        >
          Show me <Chevron />
        </Link>
      </div>
    </div>
  );
};

export default FoodGroupInsight;
