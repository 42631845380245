import { formatGMTToSydneyISO, getSydneyISOTime } from "./dates";
import {
  EDR_EMAIL_SIGNUP_CAMPAIGN_END_DATE,
  EDR_EMAIL_SIGNUP_CAMPAIGN_START_DATE,
  POINTS_PROMO_END_DATE,
  POINTS_PROMO_START_DATE,
  WOW_EMAIL_SIGNUP_CAMPAIGN_END_DATE,
  WOW_EMAIL_SIGNUP_CAMPAIGN_START_DATE,
} from "../constants";

function addTwoWeeks(dateString: string) {
  const date = new Date(dateString);
  const twoWeeks = 14 * 24 * 60 * 60 * 1000;
  const newDate = new Date(date.getTime() + twoWeeks);
  return newDate.toUTCString();
}

export const getEnvironment = (): "prod" | "uat" | "dev" | null => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_ID) {
    case "gcp-wow-food-hlt-dial-prod":
      return "prod";
    case "gcp-wow-food-hlt-dial-uat":
      return "uat";
    case "gcp-wow-food-hlt-dial-dev":
      return "dev";
    default:
      return null;
  }
};

export const shouldShowSwapsCampaign = () => {
  if (!POINTS_PROMO_START_DATE || !POINTS_PROMO_END_DATE) return false;

  const currentDate = getSydneyISOTime();
  const promoStartDate = formatGMTToSydneyISO(POINTS_PROMO_START_DATE);
  const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE);

  return currentDate >= promoStartDate && currentDate <= promoEndDate;
};

export const shouldShowFruitsAndVegChallenge = () => {
  if (!POINTS_PROMO_START_DATE || !POINTS_PROMO_END_DATE) return false;

  const currentDate = getSydneyISOTime();
  const promoStartDate = formatGMTToSydneyISO(POINTS_PROMO_START_DATE);
  const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE);

  return currentDate >= promoStartDate && currentDate <= promoEndDate;
};

export const fruitAndVegChallengeDataRequired = () => {
  if (!POINTS_PROMO_START_DATE || !POINTS_PROMO_END_DATE) return false;

  const currentDate = getSydneyISOTime();
  const promoStartDate = formatGMTToSydneyISO(POINTS_PROMO_START_DATE);
  const promoEndDatePlus2Weeks = formatGMTToSydneyISO(
    addTwoWeeks(POINTS_PROMO_END_DATE!)
  );

  return currentDate >= promoStartDate && currentDate <= promoEndDatePlus2Weeks;
};

export const isWowApp = () => window.location.search.includes("wow-app");

export const isEdrApp = () => window.location.search.includes("edr-app");

export const withinEdrEmailCampaignDates = () => {
  if (
    !EDR_EMAIL_SIGNUP_CAMPAIGN_START_DATE ||
    !EDR_EMAIL_SIGNUP_CAMPAIGN_END_DATE
  ) {
    return false;
  }

  const currentDate = getSydneyISOTime();
  const promoStartDate = formatGMTToSydneyISO(
    EDR_EMAIL_SIGNUP_CAMPAIGN_START_DATE
  );
  const promoEndDate = formatGMTToSydneyISO(EDR_EMAIL_SIGNUP_CAMPAIGN_END_DATE);

  return currentDate >= promoStartDate && currentDate <= promoEndDate;
};

export const withinWowEmailCampaignDates = () => {
  if (
    !WOW_EMAIL_SIGNUP_CAMPAIGN_START_DATE ||
    !WOW_EMAIL_SIGNUP_CAMPAIGN_END_DATE
  )
    return false;

  const currentDate = getSydneyISOTime();
  const promoStartDate = formatGMTToSydneyISO(
    WOW_EMAIL_SIGNUP_CAMPAIGN_START_DATE
  );
  const promoEndDate = formatGMTToSydneyISO(WOW_EMAIL_SIGNUP_CAMPAIGN_END_DATE);

  return currentDate >= promoStartDate && currentDate <= promoEndDate;
};
