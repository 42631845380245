import { createContext, FC, useEffect, useReducer } from "react";
import { subscribeToFeatureFlags } from "../../services/featureflag";
import { FeatureFlags } from "../../models";

type FeatureFlagContextState = {
  collected: boolean;
  featureFlags: FeatureFlags;
};

type FeatureFlagContextAction = {
  type: 'update';
  payload: FeatureFlags;
};

const initialState: FeatureFlagContextState = {
  collected: false,
  featureFlags: {},
};

function reducer(state: FeatureFlagContextState, action: FeatureFlagContextAction): FeatureFlagContextState {
  switch (action.type) {
    case 'update':
      return { collected: true, featureFlags: action.payload };
    default:
      throw new Error();
  }
}

export const FeatureFlagContext = createContext<FeatureFlagContextState>(initialState);

const FeatureFlagProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const unsubscribe = subscribeToFeatureFlags((flags) => {
      dispatch({ type: 'update', payload: flags });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={state}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;