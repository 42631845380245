import { filterBasketByDiscretionaryCategory } from "../../services/basketdetails";
import SometimesFoodBasketDetailsAccordion from "./SometimesFoodBasketDetailsAccordion";
import useBasketDetails from "../../hooks/useBasketDetails";
import { AccordionContainer } from "../../components-2/Accordion";
import { useState } from "react";
import {
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SometimesFoodBasketDetailsTabPanel from "./SometimesFoodBasketDetailsTabPanel";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import useEvents from "../../hooks/useEvents";
import { SOMETIMES_FOOD_PAGE_EVENT } from "../../events";

interface Props {
  categories: {
    id: string;
    title: string;
    alias: string;
    description: string;
  }[];
}

const SometimesFoodBasketDetails = ({ categories }: Props) => {
  const useStyles = makeStyles(() => ({
    tabContainer: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "1px",
      "& .MuiTabScrollButton-root": {
        display: "none",
      },
    },
    tabHeaders: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: "8px",
      boxSizing: "border-box",
      fontFamily: "Gilroy",

      "& .MuiTab-root": {
        position: "relative",
        padding: "16px 16px 8px",
        boxSizing: "border-box",
        minHeight: 0,
        maxWidth: "none",
        minWidth: 0,
        width: "calc((100% - 32px) / 6)",
        textTransform: "none",
        backgroundColor: COLOR_GREYSCALE[50],
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.3px",
        color: COLOR_GREYSCALE[900],
        opacity: 1,
        borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,

        "& .MuiTab-wrapper": {
          textOverflow: "ellipsis",
          lineClamp: 1,
          display: "-webkit-box",
          boxOrient: "vertical",
          overflow: "hidden",
        },

        "&.active-tab": {
          position: "relative",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          backgroundColor: WHITE,
          border: `solid 1px ${COLOR_GREYSCALE[200]}`,
          borderBottom: `solid 1px ${WHITE}`,
          marginBottom: 0,
        },
      },
    },
    tabContents: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      padding: "24px 16px 16px",
      borderRadius: "8px",
      border: `solid 1px ${COLOR_GREYSCALE[200]}`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxSizing: "border-box",
      marginTop: "-2px",
    },
  }));

  const classes = useStyles();
  const { track } = useEvents();
  const theme = useTheme();
  const isDesktopBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  const { basketDetails } = useBasketDetails("discretionary");
  const [expanded, setExpanded] = useState<string | false>(
    "sweet_savoury_snack_foods"
  );
  const [activeTab, setActiveTab] = useState<string>(
    "sweet_savoury_snack_foods"
  );

  const handleChange = (panel: string) => {
    if (expanded === panel) {
      track(SOMETIMES_FOOD_PAGE_EVENT.SEGMENTS_CLOSE, { segment: panel });
      setExpanded(false);
    } else {
      track(SOMETIMES_FOOD_PAGE_EVENT.SEGMENTS_OPEN, { segment: panel });
      setExpanded(panel);
    }
  };

  const handleTabs = (panel: string) => {
    track(SOMETIMES_FOOD_PAGE_EVENT.SEGMENTS_CLOSE, { segment: activeTab });
    setActiveTab(panel);
    track(SOMETIMES_FOOD_PAGE_EVENT.SEGMENTS_OPEN, { segment: panel });
  };

  return (
    <div>
      {isDesktopBreakpoint ? (
        <>
          <Tabs className={classes.tabContainer}>
            <div className={classes.tabHeaders}>
              {categories.map((cat) => (
                <Tab
                  tabIndex={0}
                  label={cat.title}
                  onChange={() => handleTabs(cat.id)}
                  className={activeTab === cat.id ? "active-tab" : ""}
                />
              ))}
            </div>
          </Tabs>
          <div className={classes.tabContents}>
            {categories.map((category, index) => (
              <SometimesFoodBasketDetailsTabPanel
                key={index}
                activeTab={activeTab}
                id={category.id}
                title={category.title}
                description={category.description}
                basketDetails={filterBasketByDiscretionaryCategory(
                  basketDetails,
                  category.id
                )}
              />
            ))}
          </div>
        </>
      ) : (
        <AccordionContainer>
          {categories.map((category, index) => (
            <SometimesFoodBasketDetailsAccordion
              key={index}
              expanded={expanded === category.id}
              handleChange={() => handleChange(category.id)}
              id={category.id}
              title={category.title}
              description={category.description}
              basketDetails={filterBasketByDiscretionaryCategory(
                basketDetails,
                category.id
              )}
            />
          ))}
        </AccordionContainer>
      )}
    </div>
  );
};

export default SometimesFoodBasketDetails;
