import { createContext, FC, useContext, useEffect, useState } from "react";
import { BasketDay } from "../../models";
import { getFruitAndVegData } from "../../services/members";
import { AuthContext } from "./AuthProvider";
import {
  LeaderboardProps,
  UserLeaderboardProps,
} from "../../pages/FruitAndVegChallengePage";
import useMember from "../../hooks/useMember";
import { fruitAndVegChallengeDataRequired } from "../../utils/misc";

type FruitAndVegCampaignState = {
  basketDetails: BasketDay[];
  userLeaderboard: null | UserLeaderboardProps;
  localLeaderboard: LeaderboardProps[];
  globalLeaderboard: LeaderboardProps[];
  totalPoints: number;
  loading: boolean;
};

export const FruitAndVegCampaignContext =
  createContext<FruitAndVegCampaignState>({
    basketDetails: [],
    userLeaderboard: null,
    localLeaderboard: [],
    globalLeaderboard: [],
    totalPoints: 0,
    loading: true,
  });

const FruitAndVegCampaignPurchasesProvider: FC = ({ children }) => {
  const { member, loadingMember } = useMember();
  const { jwt } = useContext(AuthContext);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [basketDetails, setBasketDetails] = useState<BasketDay[]>([]);
  const [userLeaderboard, setUserLeaderboard] =
    useState<UserLeaderboardProps | null>(null);
  const [localLeaderboard, setLocalLeaderboard] = useState<LeaderboardProps[]>(
    []
  );
  const [globalLeaderboard, setGlobalLeaderboard] = useState<
    LeaderboardProps[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loadingMember || !jwt) return;

    let timeoutId: NodeJS.Timeout | undefined;

    const init = async () => {
      try {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, 3000);
        if (fruitAndVegChallengeDataRequired()) {
          const data = await getFruitAndVegData(jwt);
          setBasketDetails(data?.eligiblePurchasedItems ?? []);
          setTotalPoints(data?.userLeaderboard?.points ?? 0);
          setUserLeaderboard(data?.userLeaderboard ?? null);
          setLocalLeaderboard(data?.localLeaderboard ?? null);
          setGlobalLeaderboard(data?.globalLeaderboard ?? null);
        }
      } catch (e) {
        console.error("ERROR GETTING FRUIT AND VEG CAMPAIGN DATA");
      } finally {
        setLoading(false);
      }
    };

    init();

    return () => {
      if (timeoutId) {
        return clearTimeout(timeoutId);
      }
    };
  }, [jwt, member?.consented, loadingMember]);

  return (
    <FruitAndVegCampaignContext.Provider
      value={{
        loading,
        basketDetails,
        totalPoints,
        userLeaderboard,
        localLeaderboard,
        globalLeaderboard,
      }}
    >
      {children}
    </FruitAndVegCampaignContext.Provider>
  );
};

export default FruitAndVegCampaignPurchasesProvider;
