import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";

export const ZEST = "#D6DE57";
export const DARK_GREEN = "#104239";
export const TEAL = "#00837B";
export const SALMON = "#F7A38D";
export const LIGHT_GREY = "#F5F5F5";
export const CHARCOAL = "#242629";
export const LIGHTER_CHARCOAL = "#585C60";
export const GREYSCALE = "#767676";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GOLD = "#FFD60A";

export const LEAFY_GREEN = {
  10: "#E3F9A6",
  50: "#E3F9A6",
  100: "#D2EF82",
  200: "#B9E04C",
  300: "#97CC04",
  400: "#79A303",
  500: "#5B7A02",
  600: "#3C5202",
  700: "#2D3D01",
};

export const PINK_SUNRISE = {
  50: "#FFDAE3",
  100: "#FFC8D4",
  200: "#FFA9BD",
  300: "#FE89A3",
  400: "#FE4871",
  500: "#B92B4B",
  600: "#85162F",
  700: "#62071C",
};

export const COLOR_YELLOW = {
  50: "#FEFADD",
  100: "#FEF6BB",
  200: "#FDEF89",
  300: "#FCE856",
  400: "#E3D14D",
  700: "#7E742B",
};

export const COLOR_TEAL = {
  50: "#E6FAF8",
  100: "#CCF5F2",
  200: "#99EBE4",
  300: "#66E0D7",
  400: "#00CCBC",
  600: "#00A396",
  700: "#007A71",
};

export const COLOR_ZEST = {
  50: "#F3FADF",
  100: "#E6F5BF",
  200: "#D4ED90",
  300: "#C1E560",
  400: "#AECE56",
  700: "#617330",
};

export const FUNCTIONAL_BLUE = {
  10: "#E2F0FF",
  50: "#B0D7FF",
  100: "#6CB5FF",
  200: "#2893FF",
  300: "#0071E3",
};

export const FUNCTIONAL_ORANGE = {
  10: "#FFEACC",
  50: "#FFD599",
  100: "#FFBF66",
  200: "#FFAA33",
  300: "#FF9500",
  400: "#CC7700",
  500: "#995900",
};

export const COLOR_ORANGE = {
  50: "#FFEFEE",
  100: "#FEDEDD",
  200: "#FDBDBB",
  300: "#FC8C88",
  400: "#FA5B55",
  700: "#AF403B",
};

export const COLOR_ORANGE_FT = {
  50: "#FEE9DD",
  100: "#FDD3BB",
  200: "#FCBC99",
  300: "#FBA677",
  400: "#FA9055",
  700: "#AF653B",
};

export const COLOR_GREYSCALE = {
  50: "#F4F5F6",
  100: "#E9EBEC",
  200: "#D3D6D9",
  300: "#BEC2C6",
  400: "#A8ADB3",
  500: "#9299A0",
  600: "#757A80",
  700: "#585C60",
  800: "#3A3D40",
  900: "#1D1F20",
};

export const COLOR_FUNCTIONAL_RED = {
  100: "#FFA5A0",
};

export const COLOR_SUPPORT_INFO = {
  100: "#E6F1FC",
  200: "#B3D4F6",
  300: "#67AAEE",
  400: "#348DE8",
  500: "#0171E2",
};

export const COLOR_SUPPORT_SUCCESS = {
  100: "#EAFFE0",
  200: "#C1FFA3",
  300: "#9EFF70",
  400: "#45D600",
  500: "#34A400",
  600: "#2A8300",
};

export const COLOR_SUPPORT_ERROR = {
  100: "#FFEBEB",
  200: "#FFADAD",
  300: "#FF7A7A",
  400: "#FF1414",
  500: "#DE0000",
  600: "#B30E0E",
};

export const COLOR_SUPPORT_WARNING = {
  100: "#FFF1CC",
  200: "#FFE08F",
  300: "#FFD15C",
  400: "#FFC329",
  500: "#F5B100",
  600: "#E89806",
};

export const COLOR_SUPPORT_EVERYDAY_REWARDS = {
  500: "#FD6400",
};

export const COLOR_SUPPORT_AFTERPAY = {
  200: "#B2FCE4",
};

export const DEFAULT_HEADING_FONT_FAMILY = '"Gilroy-Bold"';
export const DEFAULT_FONT_FAMILY = '"Gilroy"';
export const FALLBACK_FONTS = [
  DEFAULT_FONT_FAMILY,
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
  "Arial",
  '"Noto Sans"',
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
].join(",");

const defaultTheme = createTheme();

const getMuiButton = ({ spacing }: Theme): Overrides["MuiButton"] => {
  return {
    outlined: {
      border: `2px solid ${CHARCOAL}`,
      textTransform: "none",
      padding: spacing(1.5, 3),
    },
    text: {
      textTransform: "none",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    root: {
      borderRadius: 24,
      fontSize: 14,
      color: CHARCOAL,
      fontFamily: '"Gilroy-Bold"',
      fontWeight: "normal",
      padding: spacing(1.5, 3),
      "&:focus-visible": {
        outline: "revert",
      },
    },
  };
};

const makeTheme = (overrides?: ThemeOptions) =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
    palette: {
      primary: {
        main: DARK_GREEN,
      },
      secondary: {
        main: TEAL,
      },
      text: {
        primary: CHARCOAL,
        secondary: LIGHTER_CHARCOAL,
      },
    },
    typography: {
      fontFamily: FALLBACK_FONTS,
      h1: {
        fontFamily: DEFAULT_HEADING_FONT_FAMILY,
        marginTop: 34,
        marginBottom: 8,
        fontSize: 32,
        fontWeight: "normal",
        lineHeight: "40px",
      },
      h2: {
        fontFamily: DEFAULT_HEADING_FONT_FAMILY,
        marginTop: 32,
        fontSize: 20,
        fontWeight: "normal",
        lineHeight: "24px",
      },
      h3: {
        fontFamily: DEFAULT_HEADING_FONT_FAMILY,
        marginTop: 24,
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: "24px",
      },
      h4: {
        fontFamily: DEFAULT_HEADING_FONT_FAMILY,
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "24px",
      },
      h6: {
        fontFamily: DEFAULT_HEADING_FONT_FAMILY,
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
      },
      body1: {
        fontSize: 14,
        lineHeight: "20px",
        marginTop: 20,
        marginBottom: 20,
      },
      body2: {
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "0.3px",
      },
      subtitle1: {
        fontSize: 12,
        lineHeight: "20px",
      },
      subtitle2: {
        fontSize: 12,
        lineHeight: "16px",
      },
    },
    props: {
      MuiButton: {
        variant: "contained",
      },
    },
    overrides: {
      MuiButton: getMuiButton(defaultTheme),
      MuiIconButton: {
        root: {
          "&:focus-visible": {
            outline: "revert",
          },
        },
      },
      MuiLink: {
        root: {
          color: "#007A71",
        },
      },
      MuiMenuItem: {
        root: {
          "&:before": {
            content: "none", // healthylife.com.au sets this and causes li circles to appear in MenuItems
          },
        },
      },
      MuiTableRow: {
        // more healthylife.com.au style overrides
        root: {
          "&:not(:first-of-type):nth-child(odd)": {
            backgroundColor: "transparent",
          },
          "&:first-of-type, &:not(:first-of-type)": {
            "& td, & th": {
              color: CHARCOAL,
              fontFamily: DEFAULT_FONT_FAMILY,
              fontWeight: 400,
              fontSize: 14,
              lineHeight: "20px",
              backgroundColor: "transparent",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            },
          },
        },
      },
    },
    ...overrides,
  });

export default makeTheme;
