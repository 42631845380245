import { DateTime, Duration } from "luxon";
import { TimeFrame, TimeFrameDuration } from "../constants";

export const getDayOfMonthSuffix = (dayOfMonth: number) => {
  if (dayOfMonth >= 11 && dayOfMonth <= 13) {
    return "th";
  }
  switch (dayOfMonth % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getSydneyISOTime = () => {
  return DateTime.local().setZone("Australia/Sydney").toISO();
};

export const formatGMTToSydneyISOMinusDay = (isoDate: string) => {
  const date = DateTime.fromISO(isoDate).minus({ days: 1 });
  return date.setZone("Australia/Sydney").toISO();
};

export const formatGMTToSydneyISO = (dateString: string) => {
  const date = new Date(dateString);
  return DateTime.fromJSDate(date).setZone("Australia/Sydney").toISO();
};

export const formatDate = (date: DateTime) => {
  const day = date.get("day");
  return date.toFormat(`EEE d'${getDayOfMonthSuffix(day)}' MMM`);
};

export const getSixMonthsAgoFromDate = (currentDate = new Date()) => {
  const monthShortcuts = [];

  for (let i = 0; i < 6; i++) {
    const monthShortcut = currentDate.toLocaleString("default", {
      month: "short",
    });
    monthShortcuts.push(monthShortcut);
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return monthShortcuts;
};

export const endOfYesterday = () =>
  DateTime.now().minus({ days: 1 }).endOf("day");

export const getStartDateFromEndDate = (
  timeFrame: TimeFrame,
  endDate: DateTime
) =>
  endDate
    .minus(TimeFrameDuration[timeFrame])
    .plus({
      days: 1,
    })
    .startOf("day");

export const oneMonthAgo = () =>
  DateTime.now().startOf("day").minus({ days: 29 }).toISODate();

export const getFromAndTo = (timeFrame: Duration, until: DateTime) => {
  const untilAtEndOfDay = until.endOf("day");
  const from = untilAtEndOfDay.minus(
    // we subtract 1ms ensure we get from beginning of first day, to end of last
    timeFrame.minus(Duration.fromObject({ milliseconds: 1 }))
  );
  return { from, to: untilAtEndOfDay };
};

export const generateDateRangesBackFromYesterday = (
  howMany: number,
  duration: Duration
) => {
  const ranges = [];
  let currentStart = endOfYesterday();
  for (let i = 0; i < howMany; i++) {
    ranges.push(getFromAndTo(duration, currentStart));
    currentStart = currentStart.minus(duration);
  }

  return ranges.reverse();
};
