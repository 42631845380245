import { COLOR_GREYSCALE } from "../../theme";
import { makeStyles, Typography } from "@material-ui/core";
import { Dispatch, FC, SetStateAction } from "react";
import RatingButtons from "./RatingButtons";
import { FeedbackValues } from "./index";

interface Props {
  feedbackValues: FeedbackValues;
  setFeedbackValues: Dispatch<SetStateAction<FeedbackValues>>;
  noRatingError: boolean;
  setNoRatingError: Dispatch<SetStateAction<boolean>>;
  inSidebar?: boolean;
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  heading: {
    margin: 0,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-Semibold'",
    fontWeight: "normal",
  },
  errorText: {
    marginTop: spacing(2),
    marginBottom: 0,
    color: palette.error.main,
  },
  ratingTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: spacing(3),
    marginBottom: spacing(1),
  },
  helperText: {
    color: COLOR_GREYSCALE[700],
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
  },
}));

const Rating: FC<Props> = ({
  feedbackValues,
  setFeedbackValues,
  noRatingError,
  setNoRatingError,
  inSidebar,
}) => {
  const classes = useStyles();
  const handleRatingChange = (rating: number) => {
    setNoRatingError(false);
    setFeedbackValues({
      ...feedbackValues,
      rating,
    });
  };

  return (
    <div>
      <Typography variant="h2" className={classes.heading}>
        How likely are you to recommend Food Tracker to a friend or colleague?
      </Typography>
      <div className={classes.ratingTextContainer}>
        <Typography variant="subtitle1" className={classes.helperText}>
          Not likely at all
        </Typography>
        <Typography variant="subtitle1" className={`${classes.helperText}`}>
          Extremely likely
        </Typography>
      </div>
      <RatingButtons
        feedbackValues={feedbackValues}
        onChange={handleRatingChange}
        inSidebar={inSidebar}
      />
      {noRatingError && (
        <Typography variant="body1" className={classes.errorText}>
          Please select a rating.
        </Typography>
      )}
    </div>
  );
};

export default Rating;
