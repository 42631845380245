import { Link, makeStyles, Typography } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";
import { FC } from "react";
import { HttpStatusCodes } from "../constants";

interface Props {
  error?: HttpStatusCodes;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  chartCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  chartCardIcon: {
    marginRight: 16,
  },
  chartCardText: {
    marginTop: 0,
    marginRight: 16,
    color: palette.secondary.main,
    textAlign: "left",
  },
  chartCardArrow: {
    color: palette.text.primary,
  },
  error: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing(3),
    marginBottom: spacing(4),
    background: palette.error.main,
    padding: spacing(3),
    borderRadius: 4,
  },
  errorIcon: {
    color: palette.error.contrastText,
  },
  errorText: {
    margin: 0,
    marginLeft: spacing(2),
    color: palette.error.contrastText,
    "& a": {
      color: palette.error.contrastText,
      textDecoration: "underline",
    },
  },
}));

const ErrorNotice: FC<Props> = ({ error }) => {
  const classes = useStyles();

  let Message;
  switch (error) {
    case HttpStatusCodes.Unauthorized:
      Message = () => (
        <>
          Please{" "}
          <Link href="/Account/Login?returnUrl=https://shop.healthylife.com.au/cart">
            login to your account
          </Link>{" "}
          and link your everyday rewards card to access access your shopping
          insights
        </>
      );
      break;
    case HttpStatusCodes.NotFound:
      Message = () => (
        <>
          Please <Link href="/Account">link your everyday rewards card</Link> to
          your account to access your shopping insights
        </>
      );
      break;
    default:
      Message = () => <>An unexpected error has occurred</>;
  }

  return (
    <div className={classes.error}>
      <WarningOutlined className={classes.errorIcon} />
      <Typography variant="body1" className={classes.errorText}>
        <Message />
      </Typography>
    </div>
  );
};

export default ErrorNotice;
