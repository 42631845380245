import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import StyledTabs from "../../components/tabs/StyledTabs";
import StyledTab from "../../components/tabs/StyledTab";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as Gold } from "../../icons/fv-gold.svg";
import { ReactComponent as Silver } from "../../icons/fv-silver.svg";
import { ReactComponent as Bronze } from "../../icons/fv-bronze.svg";
import { ReactComponent as First } from "../../icons/fv-first.svg";
import { ReactComponent as Second } from "../../icons/fv-second.svg";
import { ReactComponent as Third } from "../../icons/fv-third.svg";

import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import { ReactComponent as MapPin } from "../../icons/map-pin.svg";
// fruit veg icons
import { ReactComponent as Tomato } from "../../icons/fv-Tomato.svg";
import { ReactComponent as Carrot } from "../../icons/fv-Carrot.svg";
import { ReactComponent as Apple } from "../../icons/fv-Apple.svg";
import { ReactComponent as Bean } from "../../icons/fv-Bean.svg";
import { ReactComponent as Blueberry } from "../../icons/fv-Blueberry.svg";
import { ReactComponent as Cauliflower } from "../../icons/fv-Cauliflower.svg";
import { ReactComponent as Orange } from "../../icons/fv-Orange.svg";
import { ReactComponent as Chickpea } from "../../icons/fv-Chickpea.svg";
import { ReactComponent as Zucchini } from "../../icons/fv-Zucchini.svg";
import { ReactComponent as Grapes } from "../../icons/fv-Grape.svg";
import { ReactComponent as Broccoli } from "../../icons/fv-Broccoli.svg";
import { ReactComponent as Banana } from "../../icons/fv-Banana.svg";
import { ReactComponent as Strawberry } from "../../icons/fv-Strawberry.svg";
import { ReactComponent as Pear } from "../../icons/fv-Pear.svg";
import { ReactComponent as Peach } from "../../icons/fv-Peach.svg";
import { ReactComponent as Lentil } from "../../icons/fv-Lentil.svg";
import { ReactComponent as Lettuce } from "../../icons/fv-Lettuce.svg";
import { ReactComponent as Legume } from "../../icons/fv-Legume.svg";
import { ReactComponent as Eggplant } from "../../icons/fv-Eggplant.svg";
import { ReactComponent as Pea } from "../../icons/fv-Splitpea.svg";
import useConfetti from "../../hooks/useConfetti";
import { ConfettiContext } from "../../components/providers/ConfettiProvider";
import useEvents from "../../hooks/useEvents";
import { FRUIT_AND_VEG_CHALLENGE_EVENTS } from "../../events";
import { formatGMTToSydneyISO, getSydneyISOTime } from "../../utils/dates";
import { POINTS_PROMO_END_DATE } from "../../constants";
import { addTwoWeeks } from "../fruit-and-veg-challenge/FruitAndVegChallengeJoined";
import { FruitAndVegCampaignContext } from "../../components/providers/FruitAndVegCampaignPurchasesProvider";

const FruitAndVegLeaderboardJoined = () => {
  const [location, setLocation] = useState<string>("all");
  const {
    userLeaderboard: user,
    globalLeaderboard,
    localLeaderboard,
  } = useContext(FruitAndVegCampaignContext);

  const { track } = useEvents();

  const currDate = getSydneyISOTime();
  const promoEndDate = formatGMTToSydneyISO(POINTS_PROMO_END_DATE!);
  const promoEndDatePlus2Weeks = formatGMTToSydneyISO(
    addTwoWeeks(POINTS_PROMO_END_DATE!)
  );
  const campaignEnded =
    currDate >= promoEndDate && currDate <= promoEndDatePlus2Weeks;

  const classes = makeStyles(({ breakpoints }) => ({
    title: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      margin: 0,
      marginBottom: "16px",
      color: "#0A2822",
    },
    greenBgTextContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: 0,
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "84px",
      height: "11px",
      zIndex: 0,
      [breakpoints.up("lg")]: {
        width: "101px",
        height: "13px",
      },
    },
    fvText: {
      zIndex: 1,
      position: "relative",
    },
    topGrid: {
      display: "grid",
      gridTemplateColumns: "1fr",
      columnGap: "32px",

      [breakpoints.up("lg")]: {
        gridTemplateColumns: campaignEnded ? "1fr 1fr" : "1fr",
        alignItems: "center",
      },
    },
    rankingAndTabs: {
      display: "flex",
      flexDirection: "column",
      [breakpoints.up("lg")]: {
        flexDirection: campaignEnded ? "column" : "row-reverse",
        justifyContent: campaignEnded ? "center" : "flex-end",
        marginRight: campaignEnded ? 0 : "auto",
        marginBottom: campaignEnded ? 0 : "16px",
        width: "100%",
      },
    },
    myRanking: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "16px",
      [breakpoints.up("lg")]: {
        marginBottom: campaignEnded ? "16px" : 0,
        flex: 1,
      },
    },
    labels: {
      display: "flex",
      border: `solid 1px ${COLOR_GREYSCALE[300]}`,
      backgroundColor: COLOR_GREYSCALE[50],
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      flexDirection: "row",
      alignItems: "center",
      padding: "4px",
      width: "100%",
      boxSizing: "border-box",
    },
    myRank: {
      flexDirection: "row",
      alignItems: "center",
      border: "solid 1px #2B6359",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      padding: "8px 0",
      backgroundColor: "#FAF5EC",
      display: "flex",
      marginTop: "-1px",
      boxSizing: "border-box",
      width: "100%",
    },
    ranking: {
      padding: "0 12px",
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      minWidth: "57px",
      boxSizing: "border-box",
      textAlign: "center",
      alignSelf: "center",
      "&.label": {
        fontFamily: "Gilroy",
        color: COLOR_GREYSCALE[800],
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
      },
    },
    name: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy-SemiBold",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      display: "flex",
      columnGap: "8px",
      alignItems: "center",
      flex: 1,
      "&.label": {
        fontFamily: "Gilroy",
        color: COLOR_GREYSCALE[800],
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
      },
    },
    points: {
      padding: "0 12px",
      color: COLOR_GREYSCALE[600],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      alignSelf: "center",
      "&.label": {
        fontFamily: "Gilroy",
        color: COLOR_GREYSCALE[800],
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
      },
    },
    location: {
      margin: 0,
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "8px",
    },
    pin: {
      marginLeft: "4px",
    },
    tabs: {
      boxSizing: "border-box",
      padding: "8px",
      backgroundColor: WHITE,
      borderRadius: "16px",
      border: `solid 1px ${COLOR_GREYSCALE[400]}`,
      width: "100%",
      marginBottom: "16px",
      [breakpoints.up("lg")]: {
        margin: campaignEnded ? 0 : "auto 16px auto 0",
        alignItems: "flex-start",
        width: campaignEnded ? "100%" : "fit-content",
      },
    },
    tab: {
      padding: "8px 16px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      lineHeight: "20px",
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[700],
      borderRadius: "8px",
      flex: 1,
      maxWidth: "none",
      "&.Mui-selected": {
        backgroundColor: COLOR_GREYSCALE[700],
        color: WHITE,
      },
      [breakpoints.up("lg")]: {
        lineHeight: "24px",
        fontSize: "16px",
        width: "163.5px",
      },
    },
    rankingTable: {
      width: "100%",
      overflow: "hidden",
      borderRadius: "12px",
      border: `solid 1px ${COLOR_GREYSCALE[200]}`,
      marginBottom: "16px",

      "& table": {
        width: "100%",
        borderSpacing: 0,
        borderCollapse: "collapse",

        "& tr": {
          display: "flex",
          borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
          marginTop: "-1px",
        },

        "& thead th": {
          backgroundColor: COLOR_GREYSCALE[50],
          color: COLOR_GREYSCALE[800],
          fontFamily: "Gilroy-SemiBold",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.3px",
          padding: "16px 12px",

          "&:nth-child(2)": {
            flex: 1,
            textAlign: "left",
          },
        },

        "& tbody td": {
          color: COLOR_GREYSCALE[800],
          fontFamily: "Gilroy",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.3px",
          padding: "8px 12px",

          "&:nth-child(2)": {
            fontFamily: "Gilroy-SemiBold",
          },
        },

        "& tbody tr:last-of-type": {
          borderBottom: "none",
        },
      },
    },

    topThreeRanking: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      margin: "16px 0",
    },
    firstPlace: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
      margin: "0px 16px",
      textAlign: "center",

      "& p": {
        fontFamily: "Gilroy-SemiBold",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.3px",
        color: COLOR_GREYSCALE[800],
        margin: 0,
      },
      "& span": {
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",
        color: COLOR_GREYSCALE[700],
      },
    },
    runnerUp: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
      margin: "0px 16px",
      textAlign: "center",
      marginTop: "40px",

      "& p": {
        fontFamily: "Gilroy-SemiBold",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: COLOR_GREYSCALE[800],
        margin: 0,
      },
      "& span": {
        fontFamily: "Gilroy",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        color: COLOR_GREYSCALE[700],
      },
    },
    fpIcon: {
      border: "solid 2px #EECC21",
      borderRadius: "50%",
    },
    spIcon: {
      border: `solid 1.5px ${COLOR_GREYSCALE[300]}`,
      borderRadius: "50%",
    },
    tpIcon: {
      border: "solid 1.5px #D5941F",
      borderRadius: "50%",
    },
    canvas: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 99,
      pointerEvents: "none",
    },
  }))();

  const { attemptFruitAndVegCampaignConfetti } = useConfetti();

  useEffect(() => {
    attemptFruitAndVegCampaignConfetti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fruitAndVegCampaignConfettiRef } = useContext(ConfettiContext);

  const handleTabChange = (_event: React.ChangeEvent<{}>, value: any) => {
    setLocation(value);
    if (value === "city") {
      track(FRUIT_AND_VEG_CHALLENGE_EVENTS.LEADERBOARD_TOGGLE_CITY, {
        city: user?.city,
      });
    }
  };

  const leaderboardRanking =
    location === "all" ? globalLeaderboard : localLeaderboard;

  const leaderboardTop4To10 = leaderboardRanking.slice(3, 10);

  const renderAvatar = (
    name: string,
    height: number = 40,
    width: number = 40,
    className: string = ""
  ) => {
    let Avatar;
    const fvname = name?.split(" ")[1] || "Tomato";

    switch (fvname) {
      case "Apple":
        Avatar = Apple;
        break;
      case "Orange":
        Avatar = Orange;
        break;

      case "Pear":
        Avatar = Pear;
        break;

      case "Banana":
        Avatar = Banana;
        break;

      case "Peach":
        Avatar = Peach;
        break;

      case "Broccoli":
        Avatar = Broccoli;
        break;

      case "Bean":
        Avatar = Bean;
        break;

      case "Chickpea":
        Avatar = Chickpea;
        break;

      case "Lentil":
        Avatar = Lentil;
        break;

      case "Lettuce":
        Avatar = Lettuce;
        break;

      case "Tomato":
        Avatar = Tomato;
        break;

      case "Legume":
        Avatar = Legume;
        break;

      case "Grape":
        Avatar = Grapes;
        break;

      case "Eggplant":
        Avatar = Eggplant;
        break;

      case "Strawberry":
        Avatar = Strawberry;
        break;

      case "Blueberry":
        Avatar = Blueberry;
        break;

      case "Cauliflower":
        Avatar = Cauliflower;
        break;

      case "Carrot":
        Avatar = Carrot;
        break;

      case "Pea":
        Avatar = Pea;
        break;

      case "Zucchini":
        Avatar = Zucchini;
        break;

      default:
        Avatar = Tomato;
        break;
    }

    return <Avatar height={height} width={width} className={className} />;
  };

  const splitNameLine = (name: string) => {
    const ns = name.split(" ");

    return (
      <>
        {ns[0]}
        <br />
        {ns[1]}
        <br />
        {ns[2]}
      </>
    );
  };

  return (
    <>
      <h3 className={classes.title}>
        {campaignEnded ? (
          <>
            <div className={classes.greenBgTextContainer}>
              <GreenRectangle className={classes.greenRectangle} />
              <span className={classes.fvText}>Fruit &amp; Veg</span>
            </div>{" "}
            Challenge Rankings
          </>
        ) : (
          "Leaderboard"
        )}
      </h3>

      <div className={classes.topGrid}>
        {campaignEnded && (
          <>
            <canvas
              className={classes.canvas}
              ref={fruitAndVegCampaignConfettiRef}
            />
            <div className={classes.topThreeRanking}>
              <div className={classes.runnerUp}>
                <Second />
                {renderAvatar(
                  leaderboardRanking[1]?.name,
                  40,
                  40,
                  classes.spIcon
                )}
                <p>
                  {(leaderboardRanking[1] &&
                    splitNameLine(leaderboardRanking[1]?.name)) ||
                    "-"}
                </p>
                <span>{leaderboardRanking[1]?.points} pts</span>
              </div>
              <div className={classes.firstPlace}>
                <First />
                {renderAvatar(
                  leaderboardRanking[0]?.name,
                  56,
                  56,
                  classes.fpIcon
                )}
                <p>
                  {(leaderboardRanking[0] &&
                    splitNameLine(leaderboardRanking[0]?.name)) ||
                    "-"}
                </p>
                <span>{leaderboardRanking[0]?.points} pts</span>
              </div>
              <div className={classes.runnerUp}>
                <Third />
                {renderAvatar(
                  leaderboardRanking[2]?.name,
                  40,
                  40,
                  classes.tpIcon
                )}
                <p>
                  {(leaderboardRanking[2] &&
                    splitNameLine(leaderboardRanking[2]?.name)) ||
                    "-"}
                </p>
                <span>{leaderboardRanking[2]?.points ?? "-"} pts</span>
              </div>
            </div>
          </>
        )}

        <div className={classes.rankingAndTabs}>
          <div className={classes.myRanking}>
            <div className={classes.labels}>
              <div className={`${classes.ranking} label`}>Rank</div>
              <div className={`${classes.name} label`}>Name</div>
              <div className={`${classes.points} label`}>Bonus points</div>
            </div>
            <div className={classes.myRank}>
              <div className={classes.ranking}>
                #
                {location === "all"
                  ? user?.globalPosition
                  : user?.localPosition}
              </div>
              <div className={classes.name}>
                {renderAvatar(user?.name!)}
                {user?.name} (you)
              </div>
              <div className={classes.points}>{user?.points}</div>
            </div>
          </div>
          {user?.city !== "UNKNOWN_CITY" && (
            <StyledTabs
              value={location}
              onChange={handleTabChange}
              className={classes.tabs}
            >
              <StyledTab className={classes.tab} value="all" label="All" />
              <StyledTab
                className={classes.tab}
                value="city"
                label="Your City"
              />
            </StyledTabs>
          )}
        </div>
      </div>

      {location === "city" && user?.city !== "UNKNOWN_CITY" && (
        <p className={classes.location}>
          Location: <MapPin className={classes.pin} /> {user?.city}*
        </p>
      )}
      <div className={classes.rankingTable}>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Bonus points</th>
            </tr>
          </thead>
          <tbody>
            {!campaignEnded && (
              <>
                <tr>
                  <td className={classes.ranking}>
                    <Gold />
                  </td>
                  <td className={classes.name}>
                    {renderAvatar(leaderboardRanking[0]?.name)}{" "}
                    {leaderboardRanking[0]?.name || "-"}
                  </td>
                  <td className={classes.points}>
                    {leaderboardRanking[0]?.points ?? "-"}
                  </td>
                </tr>
                {leaderboardRanking[1] && (
                  <tr>
                    <td className={classes.ranking}>
                      <Silver />
                    </td>
                    <td className={classes.name}>
                      {renderAvatar(leaderboardRanking[1]?.name)}{" "}
                      {leaderboardRanking[1]?.name || "-"}
                    </td>
                    <td className={classes.points}>
                      {leaderboardRanking[1]?.points ?? "-"}
                    </td>
                  </tr>
                )}
                {leaderboardRanking[2] && (
                  <tr>
                    <td className={classes.ranking}>
                      <Bronze />
                    </td>
                    <td className={classes.name}>
                      {renderAvatar(leaderboardRanking[2]?.name)}{" "}
                      {leaderboardRanking[2]?.name || "-"}
                    </td>
                    <td className={classes.points}>
                      {leaderboardRanking[2]?.points ?? "-"}
                    </td>
                  </tr>
                )}
              </>
            )}
            {/* Map ranking here from top 4 - 10 */}
            {leaderboardTop4To10.map((user) => (
              <tr>
                <td className={classes.ranking}>#{user.position}</td>
                <td className={classes.name}>
                  {renderAvatar(user.name)} {user.name}
                </td>
                <td className={classes.points}>{user.points}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {location === "city" && user?.city !== "UNKNOWN_CITY" && (
        <p className={classes.location}>
          *Location is determined by area with most spend in the last 30 days.
        </p>
      )}
    </>
  );
};

export default FruitAndVegLeaderboardJoined;
