import { Typography, makeStyles } from "@material-ui/core";
import { BasketDay } from "../../models";
import BasketDetailsTable from "../food-groups/basket-details/BasketDetailsTable";
import { COLOR_GREYSCALE } from "../../theme";

interface Props {
  id: string;
  activeTab: string;
  description: string;
  title: string;
  basketDetails: BasketDay[];
}

const SometimesFoodBasketDetailsTabPanel = ({
  id,
  activeTab,
  title,
  description,
  basketDetails,
}: Props) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
    },
    title: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      marginBottom: "16px",
    },
    description: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[700],
      margin: 0,
      marginBottom: "16px",
    },
    purchasedText: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: "#1D1F29",
      margin: 0,
      marginBottom: "24px",
    },
  }));
  const classes = useStyles();

  return (
    <div role="tabpanel" hidden={id !== activeTab} className={classes.root}>
      <Typography variant="subtitle1" component="h1" className={classes.title}>
        {title}
      </Typography>
      {basketDetails.length > 0 ? (
        <>
          <Typography variant="subtitle1" className={classes.description}>
            {description}
          </Typography>
          <Typography variant="body1" className={classes.purchasedText}>
            Purchased items
          </Typography>

          <BasketDetailsTable
            basketDetails={basketDetails}
            foodGroup={"discretionary"}
          />
        </>
      ) : (
        <Typography variant="body1" className={classes.description}>
          Bummer! We don't have any products to show you at the moment. Check
          back once you've shopped.
        </Typography>
      )}
    </div>
  );
};

export default SometimesFoodBasketDetailsTabPanel;
