import { FC, createElement } from "react";
import { makeStyles } from "@material-ui/core";
import {
  FoodGroupRoundedIconV2,
  FoodGroupColorIntake,
  FoodGroupLabel,
} from "../../constants";
import { ServingData } from "./BalanceOfShopV2";
import { ReactComponent as Bulb } from "../../icons/lightbulb.svg";
import { ReactComponent as Party } from "../../icons/partying-face.svg";
import { ReactComponent as ArrowRight } from "../../icons/chevron-right.svg";
import { Link } from "react-router-dom";
import { COLOR_GREYSCALE, DARK_GREEN, LEAFY_GREEN, WHITE } from "../../theme";
import { HOMEPAGE_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";
import { FoodGroup } from "../../models";

interface Props {
  sortedBelowBenchmark: ServingData[];
}

const BalanceOfShopV2QuickInsights: FC<Props> = ({ sortedBelowBenchmark }) => {
  const { track } = useEvents();
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      display: "flex",
      position: "relative",
      overflow: "hidden",
      padding: "12px",
      paddingLeft: "16px",
      borderRadius: "8px",
      gap: "10px",
      marginTop: "81px",
      backgroundColor: COLOR_GREYSCALE[50],
      "&::before": {
        content: `" "`,
        width: "8px",
        left: 0,
        top: 0,
        position: "absolute",
        display: "block",
        height: "100%",
        backgroundColor: DARK_GREEN,
      },
      [breakpoints.up("md")]: {
        marginTop: "108px",
        marginBottom: "16px",
      },
      "&.withLongLabels": {
        [breakpoints.up("md")]: {
          marginTop: "86px",
          marginBottom: 0,
        },
      },
    },
    withRecommendedServes: {
      backgroundColor: "rgba(254, 250, 221, 0.70)",
      border: "none",
    },
    belowBenchmark: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "#1D1F20",
    },
    insightDescription: {
      letterSpacing: "0.3px",
      color: "#1D1F20",
      margin: 0,
      marginBottom: "12px",
      fontSize: "14px",
      lineHeight: "20px",
    },
    foodGroupLinks: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: "10px",
      marginLeft: "-32px",
      [breakpoints.up("md")]: {
        marginLeft: 0,
        justifyContent: "flex-start",
        gap: "6px 8px",
      },
    },
    link: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      padding: "4px",
      borderRadius: "12px",
      textDecoration: "none",
      color: "#3A3D40",
      [breakpoints.up("md")]: {
        width: "auto",
        padding: "4px 8px",
        "& svg": {
          flexShrink: 0,
        },
      },
    },
    linkArrow: {
      "& path": {
        stroke: COLOR_GREYSCALE[50],
      },
    },
    linkLabel: {
      display: "none",
      [breakpoints.up("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.3px",
        fontFamily: "Gilroy",
        color: WHITE,
        display: "flex",
      },
      "&.dark": {
        color: COLOR_GREYSCALE[900],
      },
      "&.veggies": {
        color: LEAFY_GREEN[700],
      },
    },
  }));

  const classes = useStyles();
  const Icon = sortedBelowBenchmark.length > 0 ? Bulb : Party;
  const insightDescription =
    sortedBelowBenchmark.length > 0
      ? "These food groups are below your recommended serves."
      : "You’ve met your recommended serves for all 5 food groups. Well done!";

  const fgBelowBenchmark: string[] = sortedBelowBenchmark.map(
    (bb) => bb.foodGroup
  );

  const hasLongLabels = ["vegetables", "dairy", "protein"].every((fg) =>
    fgBelowBenchmark.includes(fg)
  );

  const handleEvent = (foodGroup: FoodGroup) => {
    switch (foodGroup) {
      case "dairy":
        track(HOMEPAGE_EVENT.BALANCE_DAIRY_CLICK);
        return;
      case "vegetables":
        track(HOMEPAGE_EVENT.BALANCE_VEG_CLICK);
        return;
      case "protein":
        track(HOMEPAGE_EVENT.BALANCE_MEAT_CLICK);
        return;
      case "grains":
        track(HOMEPAGE_EVENT.BALANCE_GRAINS_CLICK);
        return;
      case "fruit":
        track(HOMEPAGE_EVENT.BALANCE_FRUIT_CLICK);
        return;
      default:
        return;
    }
  };
  return (
    <div
      className={`${classes.root} ${
        !(sortedBelowBenchmark.length > 0) && classes.withRecommendedServes
      }
      ${(hasLongLabels || fgBelowBenchmark.length > 3) && "withLongLabels"}
      `}
    >
      <Icon style={{ minWidth: 24 }} />
      <div className={classes.belowBenchmark}>
        <p className={classes.insightDescription}>{insightDescription}</p>
        {sortedBelowBenchmark.length > 0 && (
          <div className={classes.foodGroupLinks}>
            {sortedBelowBenchmark.map(({ foodGroup }, index) => {
              return (
                <Link
                  key={index}
                  to={`/food-group/${foodGroup}`}
                  aria-label={`Go to ${foodGroup}`}
                  className={classes.link}
                  onClick={() => handleEvent(foodGroup)}
                  style={{
                    backgroundColor: FoodGroupColorIntake[foodGroup],
                  }}
                >
                  {createElement(FoodGroupRoundedIconV2[foodGroup], {
                    width: 24,
                    height: 24,
                  })}
                  <ArrowRight className={`${classes.linkArrow} ${foodGroup}`} />
                  <span
                    className={`${classes.linkLabel} ${
                      ["protein", "grains"].includes(foodGroup) && "dark"
                    }
                    ${foodGroup === "vegetables" && "veggies"}
                    `}
                  >
                    {FoodGroupLabel[foodGroup] === "Vegetables & legumes"
                      ? "Veg & legumes"
                      : FoodGroupLabel[foodGroup]}
                  </span>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceOfShopV2QuickInsights;
