import {
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import { COLOR_GREYSCALE } from "../../theme";

import { useHistory } from "react-router-dom";
import useNutrients from "../../hooks/useNutrients";
import useEvents from "../../hooks/useEvents";
import { HEALTHIER_OPTIONS_EVENTS } from "../../constants";
import { NutrientTrackerTypes } from "../../models";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: spacing(2),
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxHeight: "300px",
    overflowY: "scroll",
  },
  itemImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `0.8px solid ${COLOR_GREYSCALE[200]}`,
    borderRadius: "3.2px",
    padding: "3px",
    background: "white",
    [breakpoints.up("md")]: {
      width: "180px",
      height: "180px",
    },
  },
  listItem: {
    display: "flex",
    gap: "3px",
    height: "auto",
  },
  itemImageAndTextContainer: {
    display: "flex",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    alignItems: "center",
    padding: "8px",
    background: COLOR_GREYSCALE[50],
    gap: "8px",
    width: "100%",
  },
  itemText: {
    marginTop: "0px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
  },
  healthierOptionsIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    background: COLOR_GREYSCALE[50],
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  healthierOptionsIcon: {
    "& path": {
      fill: "#000",
      width: "20",
      height: "20",
    },
  },
}));

const HealthierOptionsList = ({
  type,
}: {
  type: NutrientTrackerTypes["type"];
}) => {
  const { purchasedItemsWithSwaps } = useHealthierOptions("all");
  const { nutrientsTopContributors } = useNutrients();

  const itemsWithSwaps = type
    ? nutrientsTopContributors?.[type].slice(0, 3)
    : purchasedItemsWithSwaps;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { track } = useEvents();
  const history = useHistory();
  const classes = useStyles();
  const pushToPDP = (articleId: string) => {
    track(HEALTHIER_OPTIONS_EVENTS.CAROUSEL_PRODUCT_CLICK, { type: articleId });
    history.push(
      `/healthier-options/pdp?back=home&articleId=${articleId}&source=nutrient-tracker`
    );
  };

  return (
    <div className={classes.listContainer}>
      {(itemsWithSwaps || []).map((item, index) => {
        const hasHealthierOptions = item.healthierOptions.length >= 1;
        return (
          <div
            className={classes.listItem}
            onClick={() =>
              hasHealthierOptions ? pushToPDP(item.articleId) : null
            }
            onKeyPress={(e) =>
              hasHealthierOptions &&
              ifEnterOrSpace(e, pushToPDP, item.articleId)
            }
            tabIndex={hasHealthierOptions ? 0 : -1}
            key={item.articleId}
          >
            <div className={classes.itemImageAndTextContainer}>
              <div className={classes.itemImageContainer}>
                <img
                  src={item.imageURL ?? ""}
                  alt={item.description + " Photo"}
                  height={isMobile ? 40 : 140}
                  width={isMobile ? 40 : 140}
                />
              </div>
              <div>
                <Typography
                  variant="h6"
                  component="h3"
                  className={classes.itemText}
                >
                  {item.description}
                </Typography>
              </div>
            </div>
            <div className={classes.healthierOptionsIconContainer}>
              {hasHealthierOptions && (
                <HealthierOptionsIcon
                  className={classes.healthierOptionsIcon}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HealthierOptionsList;
