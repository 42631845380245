import { OnboardingPageLayout } from "./OnboardingPageLayout";
import { cloneElement, ReactElement, useState } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CHARCOAL, COLOR_GREYSCALE } from "../../theme";
import { Grid } from "@material-ui/core";
import { GOALS } from "../goals/config";
import useMember from "../../hooks/useMember";
import { setMemberGoals } from "../../services/members";
import { GoalEditBar } from "../goals/GoalEditBar";

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-Bold'",
    fontWeight: "normal",
  },
  body: {
    margin: 0,
    marginTop: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
  },
  subTitle: {
    margin: 0,
    color: CHARCOAL,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-SemiBold'",
    fontWeight: "normal",
    marginTop: "24px",
    marginBottom: "24px",
  },
  gridContainer: {
    "& > *:not(:first-child)": {
      marginTop: "16px",
    },
  },
  disclaimer: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textAlign: "center",
    color: COLOR_GREYSCALE[700],
  },
}));

export const GoalsStep = ({
  StepperComponent,
  step,
}: {
  StepperComponent: ReactElement;
  step: number;
}) => {
  const { member } = useMember();
  const classes = useStyles();
  const [checked, setChecked] = useState<GOALS[]>(member?.goals ?? []);
  const [saveLoading, setSaveLoading] = useState(false);
  const onSave = async () => {
    setSaveLoading(true);
    await setMemberGoals(member!.memberId, checked);
    setSaveLoading(false);
    return true;
  };

  return (
    <OnboardingPageLayout
      stepper={cloneElement(StepperComponent, {
        customHandleNext: onSave,
        nextButtonLoading: saveLoading,
      })}
      stepNumber={step}
    >
      <Typography variant="h3" component="h2" className={classes.title}>
        What's your goal?
      </Typography>
      <Typography className={classes.body}>
        Choose your specific health goal(s) so you can track what's important to
        you.
      </Typography>
      <Typography component="h3" className={classes.subTitle}>
        Please select a maximum of 3:
      </Typography>
      <Grid container className={classes.gridContainer}>
        {Object.keys(GOALS).map((goalKey) => {
          const goal = GOALS[goalKey as keyof typeof GOALS];
          return (
            <GoalEditBar
              key={goal}
              goal={goal}
              checked={checked}
              setChecked={setChecked}
            />
          );
        })}
      </Grid>
      <Typography component="h4" className={classes.disclaimer}>
        * Protein includes Meat, Dairy & alternatives
      </Typography>
    </OnboardingPageLayout>
  );
};
