import { makeStyles } from "@material-ui/core/styles";
import { DARK_GREEN } from "../../theme";
import { motion } from "framer-motion";
import { Button, Typography } from "@material-ui/core";
import React, { Dispatch, memo, ReactElement, SetStateAction } from "react";
import { OpenCard } from "./openCard";

interface CardProps {
  image: string;
  title: string;
  uniqueKey: string;
  setOpenCard: Dispatch<SetStateAction<string | null>>;
  layoutId: string;
  content: {
    buttonColor: string;
    title: string | ReactElement;
    image: string;
    content: (ReactElement | string)[];
  }[];
  isOpen: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export const DefaultCard = memo(
  ({
    uniqueKey,
    title,
    image,
    setOpenCard,
    layoutId,
    content,
    isOpen,
    setOpenDrawer,
  }: CardProps) => {
    const useStyles = makeStyles(({ breakpoints }) => ({
      normalCard: {
        WebkitTapHighlightColor: "transparent",
        boxShadow: "0px 4px 8px 0px #0000001A",
        width: "100%",
        height: "176px",
        minHeight: "176px",
        borderRadius: "8px",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "left center",
          position: "absolute",
          top: 0,
          left: 0,
        },
      },
      button: {
        padding: "2px 12px 2px 12px",
        borderRadius: "19px",
        backgroundColor: DARK_GREEN,
        boxShadow: "none",
        "& span": {
          color: "white",
          fontFamily: "Gilroy-SemiBold",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.1px",
          textTransform: "none",
        },
        "&:hover": {
          color: "white",
          backgroundColor: "rgb(16 66 57 / 60%) !important",
        },
      },
    }));
    const classes = useStyles();
    return (
      <motion.div key={uniqueKey}>
        <motion.div
          onClick={() => {
            if (isOpen) return;
            setOpenCard(uniqueKey);
          }}
          className={classes.normalCard}
          layoutId={layoutId}
          transition={{ duration: 0.445 }}
          style={{
            borderRadius: "8px",
          }}
        >
          <motion.div
            style={{
              backgroundSize: "auto 310px",
              backgroundImage: `url(${image})`,
              height: "100%",
              backgroundPosition: "center",
            }}
          />
          <motion.div
            style={{
              position: "absolute",
              bottom: "0px",
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 16px",
              boxSizing: "border-box",
              borderRadius: "0px 0px 8px 8px",
              backgroundColor: "rgb(145 144 144 / 90%)",
            }}
          >
            <Typography
              style={{
                margin: 0,
                height: "100%",
                color: "white",
                fontFamily: "Gilroy-SemiBold",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.1px",
              }}
            >
              {title}
            </Typography>
            <motion.div>
              <Button className={classes.button}>View</Button>
            </motion.div>
          </motion.div>
        </motion.div>
        {isOpen && (
          <OpenCard
            title={title}
            content={content}
            setOpenCard={setOpenCard}
            layoutId={layoutId}
            setOpenDrawer={setOpenDrawer}
          />
        )}
      </motion.div>
    );
  }
);
