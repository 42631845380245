import React, { FC, ReactNode } from "react";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { COLOR_GREYSCALE } from "../theme";

interface TextProps {
  variant?: TypographyProps["variant"];
  component?: React.ElementType;
  children: ReactNode;
  style?: React.CSSProperties;
}

const customVariantStyles: Partial<
  Record<Exclude<TypographyProps["variant"], undefined>, React.CSSProperties>
> = {
  h1: {
    fontSize: "24px",
    lineHeight: "32px",
    fontFamily: "Gilroy-SemiBold",
    margin: 0,
    color: COLOR_GREYSCALE[900],
    fontWeight: 600,
  },
  h2: {
    fontSize: "20px",
    lineHeight: "24px",
    fontFamily: "Gilroy-SemiBold",
    margin: 0,
    color: COLOR_GREYSCALE[900],
    fontWeight: 600,
  },
  h3: {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Gilroy-SemiBold",
    margin: 0,
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[900],
    marginTop: "16px",
    marginBottom: "8px",
  },
  body1: {
    fontFamily: "Gilroy",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[700],
    margin: 0,
  },
  body2: {
    fontFamily: "Gilroy",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: COLOR_GREYSCALE[700],
    margin: 0,
  },
  subtitle1: {
    fontFamily: "Gilroy",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    color: COLOR_GREYSCALE[700],
    margin: 0,
  },
};

export const Text: FC<TextProps> = ({
  variant = "body2",
  component = "p",
  children,
  style = {},
}) => {
  const variantStyle = variant ? customVariantStyles[variant] : {};

  return (
    <Typography
      variant={variant}
      component={component}
      style={{
        ...variantStyle,
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};
