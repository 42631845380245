import React, { useEffect, useState } from "react";
import { Fab, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  fab: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "flex",
      zIndex: 99,
      position: "fixed",
      bottom: spacing(2),
      right: spacing(2),
    },
  },
}));

const FloatingBackButton = ({ onClick }: { onClick?: () => void }) => {
  const classes = useStyles();
  const history = useHistory();
  const [fabOpacity, setFabOpacity] = useState(1);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollTop]);

  const handleScroll = () => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop > lastScrollTop) {
      // Scrolling down
      setFabOpacity(0.5);
    } else {
      // Scrolling up
      setFabOpacity(1);
    }

    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
  };

  return (
    <Fab
      color="primary"
      aria-label="go back"
      className={classes.fab}
      style={{ opacity: fabOpacity, transition: "opacity 0.3s ease" }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          history.goBack();
        }
      }}
    >
      <ArrowBackIcon />
    </Fab>
  );
};

export default FloatingBackButton;
