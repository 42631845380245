import { withStyles } from "@material-ui/core/styles";
import { Paper, TableContainer, TableContainerProps } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";
import { forwardRef, Ref } from "react";

interface StyledTableContainerProps extends TableContainerProps {
  fullWidth?: boolean;
}

//@ts-ignore
const style = (theme) => ({
  root: (props: { fullWidth?: boolean }) => ({
    border: `1px solid ${COLOR_GREYSCALE[700]}`,
    borderRadius: "4px",
    boxShadow: "none",
    whiteSpace: props.fullWidth ? "nowrap" : "normal",
    borderBottom: "none",
    [theme.breakpoints.up("md")]: {
      overflowX: "normal",
    },
    [theme.breakpoints.down("sm")]: {
      overflowX: props.fullWidth ? "scroll" : "hidden",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }),
});
//@ts-ignore
export const StyledTableContainer = withStyles(style)(
  forwardRef((props: StyledTableContainerProps, ref: Ref<HTMLDivElement>) => (
    <TableContainer component={Paper} ref={ref} {...props} />
  ))
);
