import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, COLOR_TEAL, WHITE } from "../../../theme";
import { FoodGroup } from "../../../models";
import AboutFoodGroupExplainer from "./AboutFoodGroupExplainer";

interface Props {
  foodGroup: FoodGroup | "sometimes";
}

const AboutFoodGroup = ({ foodGroup }: Props) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      borderRadius: "12px",
      backgroundColor: WHITE,
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("md")]: {
        marginTop: "32px",
      },
    },
    title: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "normal",
      letterSpacing: "0.3px",
      color: "#1D1F29",
      margin: 0,
      paddingBottom: "8px",
      marginBottom: "8px",
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    },
    description: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[700],
      margin: 0,
      marginBottom: "8px",
    },
    learnMore: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_TEAL[700],
      textDecoration: "none",
      borderBottom: `solid 1px ${COLOR_TEAL[700]}`,
      marginRight: "auto",
    },
  }));

  const classes = useStyles();
  const aboutCopy: {
    [key in FoodGroup | "sometimes"]: { title: string; description: string };
  } = {
    dairy: {
      title: "Why you should milk the day",
      description:
        "Dairy is not only delicious (hello, cheese!) but an important source of calcium - nutritious goodness important for bone health. Dairy also provides protein, vitamins and minerals such as vitamin A, D, B12 and zinc.",
    },
    fruit: {
      title: "Fruit for thought",
      description:
        "Fruit is nature’s candy: sweet, delicious but also nutritious! Naturally high in fibre, vitamins, minerals and antioxidants that are beneficial for wellbeing and really do make your body sing!",
    },
    grains: {
      title: "Get gains on grains",
      description:
        "Grains are packed with goodness, containing carbs (an essential energy source), protein, fibre and a wide range of vitamins and minerals including iron, vitamin E, zinc and magnesium. What’s not to love?",
    },
    vegetables: {
      title: "Live life on the veg!",
      description:
        "Ah, the unsung heroes of the food world (except maybe for kale). There’s a reason why we need to eat handfuls of veggies and legumes each day: they’re loaded with vitamins, minerals, antioxidants and fibre. ",
    },
    protein: {
      title: "To meat or not to meat?",
      description:
        "Whether you're a meat eater or prefer to stick with the alternatives, this food group provides protein, essential for the body's cells to grow and repair and to make hormones and enzymes. These foods also provide nutrients like iron, zinc, vitamins and essential fatty acids.",
    },
    sometimes: {
      title: "It’s all about balance!",
      description:
        "Sweet or savoury? Whatever your preference, the sweet spot for achieving a balanced and nutritious diet comes down to moderation. Balance is key!",
    },
  };
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{aboutCopy[foodGroup].title}</h2>
      <p className={classes.description}>{aboutCopy[foodGroup].description}</p>
      <AboutFoodGroupExplainer foodGroup={foodGroup} />
    </div>
  );
};

export default AboutFoodGroup;
