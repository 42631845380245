import { ReactComponent as SometimesFoodIcon } from "../../icons/wbg-sometimes-v2.svg";
import { ReactComponent as FruitIcon } from "../../icons/wbg-fruit-v2.svg";
import { ReactComponent as VegetableIcon } from "../../icons/wbg-vegetables-v2.svg";
import { ReactComponent as ProteinIcon } from "../../icons/wbg-protein-v2.svg";
import { ReactComponent as AddedSugarIcon } from "../../icons/wbg-added-sugar-v2.svg";
import { NutrientsHistory, Servings } from "../../models";
import { DiscretionaryPercentData } from "../../services/servings";

export enum GOALS {
  INCREASE_VEGETABLE_INTAKE = "increase_vegetable_intake",
  INCREASE_FRUIT_INTAKE = "increase_fruit_intake",
  DECREASE_ADDED_SUGAR = "decrease_added_sugar",
  DECREASE_SOMETIMES = "decrease_sometimes",
  INCREASE_PROTEIN_INTAKE = "increase_protein_intake",
}

export const GOAL_DATA = {
  [GOALS.INCREASE_VEGETABLE_INTAKE]: {
    goalType: "increase",
    foodGroup: "vegetables",
    foodGroups: null,
    nutrient: null,
    sometimes: false,
    name: "Eat more vegetables",
    icon: <VegetableIcon />,
    addAstrixToOnboarding: false,
  },
  [GOALS.INCREASE_FRUIT_INTAKE]: {
    goalType: "increase",
    foodGroup: "fruit",
    foodGroups: null,
    nutrient: null,
    sometimes: false,
    name: "Eat more fruit",
    icon: <FruitIcon />,
    addAstrixToOnboarding: false,
  },
  [GOALS.DECREASE_ADDED_SUGAR]: {
    goalType: "decrease",
    foodGroup: null,
    foodGroups: null,
    nutrient: "addedSugar",
    sometimes: false,
    name: "Reduce added sugar",
    icon: <AddedSugarIcon />,
    addAstrixToOnboarding: false,
  },
  [GOALS.DECREASE_SOMETIMES]: {
    goalType: "decrease",
    foodGroup: null,
    foodGroups: null,
    nutrient: null,
    sometimes: true,
    name: "Eat less sometimes foods",
    icon: <SometimesFoodIcon />,
    addAstrixToOnboarding: false,
  },
  [GOALS.INCREASE_PROTEIN_INTAKE]: {
    goalType: "increase",
    foodGroup: null,
    foodGroups: ["protein", "dairy"],
    nutrient: null,
    sometimes: false,
    name: "Eat more protein",
    icon: <ProteinIcon />,
    addAstrixToOnboarding: true,
  },
};

const computeDifferencePercentage = (
  currentMonth: number,
  lastMonth: number
): number | null => {
  if (
    isNaN(currentMonth) ||
    isNaN(lastMonth) ||
    lastMonth <= 0 ||
    currentMonth <= 0
  ) {
    return null;
  }

  const result = (((currentMonth - lastMonth) / lastMonth) * 100).toFixed(0);

  if (
    isNaN(Number(result)) ||
    Number(result) === Infinity ||
    Number(result) === -Infinity
  ) {
    return null;
  }

  return Number(result);
};

export const getMultipleServingsGoalPercentage = (
  accumulatedServings: Servings[],
  foodGroups: string[]
): number | null => {
  const mergedFoodGroups = accumulatedServings.map((s) => {
    const mergedFoodGroup = foodGroups.reduce(
      (acc, curr) => acc + s[curr as keyof Servings],
      0
    );
    return {
      mergedFoodGroup: mergedFoodGroup <= 0.01 ? null : mergedFoodGroup,
    };
  }) as unknown as Servings[];

  return getServingsGoalPercentage(mergedFoodGroups, "mergedFoodGroup");
};

export const getServingsGoalPercentage = (
  accumulatedServings: Servings[],
  foodGroup: string
): number | null => {
  const currentMonth =
    accumulatedServings[accumulatedServings.length - 1][
      foodGroup as keyof Servings
    ] ?? 0;
  const lastMonth =
    accumulatedServings[accumulatedServings.length - 2][
      foodGroup as keyof Servings
    ] ?? 0;

  return computeDifferencePercentage(currentMonth, lastMonth);
};

export const getSometimesGoalPercentage = (
  discretionaryPercentData: DiscretionaryPercentData[]
): number | null => {
  const currentMonth =
    discretionaryPercentData[discretionaryPercentData.length - 1]
      .discretionaryPercent ?? 0;
  const lastMonth =
    discretionaryPercentData[discretionaryPercentData.length - 2]
      .discretionaryPercent ?? 0;

  return computeDifferencePercentage(currentMonth, lastMonth);
};

export const getAddedSugarGoalPercentage = (
  nutrientsHistory: NutrientsHistory[]
): number | null => {
  const currentMonth =
    nutrientsHistory[nutrientsHistory.length - 1].sugar.innerValue ?? 0;
  const lastMonth =
    nutrientsHistory[nutrientsHistory.length - 2].sugar.innerValue ?? 0;

  return computeDifferencePercentage(currentMonth, lastMonth);
};
