import { DateTime } from "luxon";
import { HouseholdMember } from "../models";

import { Collections, db } from "./firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { track } from "./events";

export type HouseholdChangeListener = (household: HouseholdMember[]) => void;

export const subscribeToHousehold = (
  memberId: string,
  onChange: HouseholdChangeListener
): (() => void) => {
  const q = query(
    collection(db, Collections.Members, memberId, Collections.Household)
  );
  return onSnapshot(
    q,
    (snapshot) => {
      const household: HouseholdMember[] = [];
      snapshot.forEach((result) => {
        household.push({ id: result.id, ...result.data() } as HouseholdMember);
      });
      onChange(household);
    },
    (error) => {
      console.error("Error reading household snapshot:", error.message);
    }
  );
};

export const addToHousehold = async (
  memberId: string,
  benchmarkId: string,
  consent?: string
): Promise<HouseholdMember> => {
  const ref = collection(
    db,
    Collections.Members,
    memberId,
    Collections.Household
  );

  const householdMember = {
    createdAt: DateTime.utc().toMillis(),
    benchmarkId,
    ...(consent ? { consented: true, consentCopy: consent } : {}),
  };

  const docRef = await addDoc(ref, householdMember);

  track(memberId, "Household Added", { id: docRef.id, benchmarkId });

  return { id: docRef.id, ...householdMember };
};

export const removeFromHousehold = async (
  memberId: string,
  hm: HouseholdMember
): Promise<void> => {
  const docRef = doc(
    db,
    Collections.Members,
    memberId,
    Collections.Household,
    hm.id
  );
  await deleteDoc(docRef);

  track(memberId, "Household Removed", { id: hm.id });
};
