import { Grid, makeStyles, Typography } from "@material-ui/core";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import useEvents from "../../../hooks/useEvents";
import { RECIPES_EVENTS } from "../../../constants";
import { FoodGroup } from "../../../models";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../../theme";
import useRecipes from "../../../hooks/useRecipes";
import { RecipeProps } from "../../recipe/Recipes";
import { FOOD_GROUP_PAGES_EVENT } from "../../../events";
import { cloneElement, useContext } from "react";
import {
  DIETARY_PREFERENCES,
  DIETARY_PREFERENCES_DATA,
} from "../../../components-2/dietary-preference/config";
import { RecipeContext } from "../../providers/RecipeProvider";
import useHideDietaryPreferences from "../../../hooks/useHideDietaryPreferences";

SwiperCore.use([A11y]);

const HealthierRecipesCarousel = ({ foodGroup }: { foodGroup: FoodGroup }) => {
  // Get filtered recipe
  const { recipes, foodGroupFilters } = useRecipes();
  const { handleAvailablePreferences } = useContext(RecipeContext);
  const hideDP = useHideDietaryPreferences();

  const foodGroupKeys = {
    dairy: "dairyAndAlternatives",
    protein: "meatAndAlternatives",
    fruit: "fruit",
    vegetables: "vegetablesAndLegumes",
    grains: "grains",
  };

  const foodgroupRecipes = () => {
    const filteredRecipeItemsInFoodGroup: RecipeProps[] = [];
    const fgKeys = Object.keys(foodGroupFilters) as Array<
      keyof typeof foodGroupFilters
    >;
    let ingredientsInFg: string[][] = [];
    fgKeys.forEach((key) => {
      if (key === foodGroupKeys[foodGroup!]) {
        ingredientsInFg = [...ingredientsInFg, foodGroupFilters[key]];
      }
    });
    let foodGroupIngredients = ingredientsInFg
      .flat()
      .map((ing) => ing.toLowerCase());
    const uniqueIngredientsInFg = foodGroupIngredients.filter(function (
      item,
      pos,
      self
    ) {
      return self.indexOf(item) === pos;
    });
    recipes.forEach((recipe) => {
      let tags: string[] = [];
      recipe.tags.forEach((tag) => {
        const tagList = tag.split("/");
        tags = [...tags, tagList].flat();
      });
      uniqueIngredientsInFg.forEach((filter) => {
        if (tags.includes(filter)) {
          filteredRecipeItemsInFoodGroup.push(recipe);
        }
      });
    });
    const filteredRecipeItemsId = filteredRecipeItemsInFoodGroup.map(
      (item) => item.id
    );
    const uniqueFilteredRecipeIds = filteredRecipeItemsId.filter(function (
      item,
      pos,
      self
    ) {
      return self.indexOf(item) === pos;
    });
    const uniqueFilteredFoodGroupRecipes = recipes.filter((recipe) =>
      uniqueFilteredRecipeIds.includes(recipe.id)
    );
    const foodgroupRecipeItems = uniqueFilteredFoodGroupRecipes.splice(0, 10);

    return foodgroupRecipeItems;
  };

  const { track } = useEvents();
  const recipeItems = foodgroupRecipes();
  const haveDietaryPreferenceProps = recipeItems.some(
    (recipe) =>
      recipe.dietaryPreferences && recipe.dietaryPreferences.length > 0
  );

  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
      marginTop: spacing(2),
    },
    yourPurchasedItemsContainer: {
      "& > *": {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },

      "& .swiper": {
        overflow: "visible",
        [breakpoints.up("lg")]: {
          overflow: "hidden",
        },
      },
    },
    swiperSlide: {
      height: haveDietaryPreferenceProps && !hideDP ? "217px" : "184px",
      width: "136px",
      background: COLOR_GREYSCALE[50],
      borderRadius: "8px",
      [breakpoints.up("lg")]: {
        height: "auto",
      },
    },
    swiperItemContainer: {
      alignItems: "center",
      justifyContent: "center",
      padding: spacing(1),
      "& a": {
        textDecoration: "none",
      },
    },
    swiperImageContainer: {
      cursor: "pointer",
      border: `0.8px solid ${COLOR_GREYSCALE[200]}`,
      borderRadius: "3.2px",
      background: "white",
    },
    swiperImages: {
      width: "100%",
      height: "auto",
      userSelect: "none",
      pointerEvents: "none",
      objectFit: "cover",
      objectPosition: "center",
      display: "block",
    },
    swiperText: {
      height: "40px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      marginTop: spacing(1),
      fontFamily: '"Gilroy-SemiBold"',
      fontWeight: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: COLOR_GREYSCALE[900],
      [breakpoints.up("lg")]: {
        height: "48px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    swiperArrowIcon: {
      position: "absolute",
      right: "10px",
      top: "10px",
      margin: "6px",
      borderRadius: "40px",
      boxSizing: "content-box",
      background: DARK_GREEN,
      width: "16px",
      padding: "4px",
      height: "16px",
      "& > * > *": {
        fill: "white",
      },
    },
    dfIcons: {
      display: "flex",
      flexDirection: "row",
      columnGap: "8px",
      marginTop: "8px",
    },
  }));
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.yourPurchasedItemsContainer}
    >
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: "0px",
          paddingTop: "16px",
        }}
      >
        <Swiper
          spaceBetween={8}
          slidesPerView="auto"
          allowTouchMove={true}
          breakpoints={{
            1024: {
              slidesPerView: 5,
            },
          }}
          onSlideChange={() =>
            track(FOOD_GROUP_PAGES_EVENT.RECIPES_SCROLL, { foodGroup })
          }
        >
          {recipeItems.map((item, index) => (
            <SwiperSlide key={index} className={classes.swiperSlide}>
              <div className={classes.swiperItemContainer}>
                <a
                  href={
                    item.url +
                    "?utm_medium=referral&utm_source=healthylife&utm_campaign=food-tracker-recipe&icmpid=healthylife-food-tracker-recipe"
                  }
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => {
                    track(RECIPES_EVENTS.CAROUSEL_RECIPE_CLICK, {
                      foodgroup: foodGroup,
                      title: item.title,
                      url: item.url,
                    });
                    track(FOOD_GROUP_PAGES_EVENT.RECIPES_OPENRECIPE, {
                      foodgroup: foodGroup,
                      title: item.title,
                      url: item.url,
                    });
                  }}
                >
                  <div className={classes.swiperImageContainer}>
                    <img
                      src={item.image ?? ""}
                      alt={item.description + " Photo"}
                      className={classes.swiperImages}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h3"
                      component="h2"
                      className={classes.swiperText}
                    >
                      {item.title}
                    </Typography>
                    {!hideDP && item.dietaryPreferences && (
                      <div className={classes.dfIcons}>
                        {handleAvailablePreferences(
                          item.dietaryPreferences as DIETARY_PREFERENCES[]
                        )?.map((pref) =>
                          cloneElement(
                            DIETARY_PREFERENCES_DATA[
                              pref as keyof typeof DIETARY_PREFERENCES_DATA
                            ].icon,
                            {
                              width: 21,
                              height: 21,
                            }
                          )
                        )}
                      </div>
                    )}
                  </div>
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default HealthierRecipesCarousel;
