import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import useFeedbackDialogConfig from "./useFeedbackDialogConfig";
import useMember from "./useMember";

const useApplyFeedbackDialogConfig = () => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const config = useFeedbackDialogConfig();
  const { member } = useMember();

  const handleScrollAtBottom = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom) {
      setShowFeedbackDialog(true);
    }
  }, [setShowFeedbackDialog]);
  
  useEffect(() => {
    if (!config?.enable.value 
        || member?.isNewUser 
        || member === null
        || !member.consented
        || (member?.visitCount && (member?.visitCount < 5))
        || !member.thirtySecondsOnSite
    ) {
      return;
    }

    const notFirstFeedbackPromptAndOnePlusMonthSinceLast = member?.feedbackSubmittedOrDismissed 
      ? member.feedbackSubmittedOrDismissed < DateTime.local().plus({ days: -30 }).toMillis() 
      : false
    
    const firstFeedbackPromptAndConsentedTwoPlusWeek = !member?.feedbackSubmittedOrDismissed && member?.oldestConsentTime 
      ? member.oldestConsentTime < DateTime.local().plus({ days: -14 }).toMillis()
      : false
    
    if (
      firstFeedbackPromptAndConsentedTwoPlusWeek ||
      notFirstFeedbackPromptAndOnePlusMonthSinceLast
    ) {
      if (config.display.bottomOfPage) {
        window.addEventListener("scroll", handleScrollAtBottom, {
          passive: true,
        });
        return () => {
          window.removeEventListener("scroll", handleScrollAtBottom);
        };
      } else {
        setShowFeedbackDialog(true);
      }
    }

  }, [config, setShowFeedbackDialog, handleScrollAtBottom, member]);

  return showFeedbackDialog;
};

export default useApplyFeedbackDialogConfig;
