import {
  collection,
  DocumentData,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { DateTime, Duration } from "luxon";
import {
  AggregateDailyNutrients,
  BasketArticle,
  BasketExclusion,
  NutrientHistory,
  NutrientsHistory,
  NutrientValue,
} from "../models";
import {
  generateDateRangesBackFromYesterday,
  getFromAndTo,
} from "../utils/dates";
import { Collections, db, Fields, Shards } from "./firebase";
import { getUniqueBasketArticleId } from "./basketdetails";
import { TimeFrame, TimeFrameDuration } from "../constants";

const getNutrientsQuery = (
  timeFrame: Duration,
  until: DateTime,
  memberId: string
) => {
  const { to, from } = getFromAndTo(timeFrame, until);
  return query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.AggregateDailyNutrientsSharded
    ),
    where(
      Fields.AggregateDailyNutrientsSharded.Shard,
      "in",
      Shards.AggregateDailyNutrientsSharded
    ),
    where(
      Fields.AggregateDailyNutrientsSharded.PurchaseDate,
      ">=",
      from.toMillis()
    ),
    where(
      Fields.AggregateDailyNutrientsSharded.PurchaseDate,
      "<=",
      to.toMillis()
    )
  );
};
export const accumulateExclusionsHistory = (
  aggregatedDailyExclusions: BasketExclusion[]
): NutrientsHistory => {
  const accumulateExclusion = (
    acc: NutrientHistory,
    nutrientValue: NutrientValue,
    packSizeMultiplier: number,
    valueType: keyof NutrientHistory
  ) => {
    if (nutrientValue) {
      acc[valueType] =
        (acc[valueType] || 0) + nutrientValue * packSizeMultiplier;
    }
    return acc;
  };

  return aggregatedDailyExclusions.reduce(
    (acc, nutrients) => {
      accumulateExclusion(
        acc.sugar,
        nutrients.addedSugarsPer100g,
        nutrients.packSizeMultiplier,
        "innerValue"
      );
      accumulateExclusion(
        acc.sugar,
        nutrients.totalSugarsPer100g,
        nutrients.packSizeMultiplier,
        "totalValue"
      );

      accumulateExclusion(
        acc.salt,
        nutrients.sodiumPer100g,
        nutrients.packSizeMultiplier,
        "innerValue"
      );
      accumulateExclusion(
        acc.salt,
        nutrients.sodiumPer100g,
        nutrients.packSizeMultiplier,
        "totalValue"
      );

      accumulateExclusion(
        acc.fat,
        nutrients.fatSaturatedPer100g,
        nutrients.packSizeMultiplier,
        "innerValue"
      );
      accumulateExclusion(
        acc.fat,
        nutrients.fatTotalPer100g,
        nutrients.packSizeMultiplier,
        "totalValue"
      );

      return acc;
    },
    {
      sugar: {
        innerValue: null,
        totalValue: null,
      },
      salt: {
        innerValue: null,
        totalValue: null,
      },
      fat: {
        innerValue: null,
        totalValue: null,
      },
    }
  );
};

export const accumulateNutrientsHistory = (
  aggregatedDailyNutrients: AggregateDailyNutrients[]
): NutrientsHistory => {
  const accumulateNutrient = (
    acc: NutrientHistory,
    nutrientValue: NutrientValue,
    valueType: keyof NutrientHistory
  ) => {
    if (nutrientValue) {
      acc[valueType] = (acc[valueType] || 0) + nutrientValue;
    }
    return acc;
  };

  return aggregatedDailyNutrients.reduce(
    (acc, { nutrients }) => {
      accumulateNutrient(acc.sugar, nutrients.addedSugar_G, "innerValue");
      accumulateNutrient(acc.sugar, nutrients.totalSugar_G, "totalValue");

      accumulateNutrient(acc.salt, nutrients.sodium_MG, "innerValue");
      accumulateNutrient(acc.salt, nutrients.sodium_MG, "totalValue");

      accumulateNutrient(acc.fat, nutrients.saturatedFat_G, "innerValue");
      accumulateNutrient(acc.fat, nutrients.totalFat_G, "totalValue");

      return acc;
    },
    {
      sugar: {
        innerValue: null,
        totalValue: null,
      },
      salt: {
        innerValue: null,
        totalValue: null,
      },
      fat: {
        innerValue: null,
        totalValue: null,
      },
    }
  );
};

const requiredAttributes = [
  "fatSaturatedPer100g",
  "fatTotalPer100g",
  "sodiumPer100g",
  "addedSugarsPer100g",
  "totalSugarsPer100g",
  "packSizeMultiplier",
];

export const hasRequiredExclusionAttributes = (
  exclusions: BasketExclusion[]
) => {
  if (exclusions.length === 0) return true;
  return exclusions.every((exclusion) =>
    requiredAttributes.every((attribute) => exclusion.hasOwnProperty(attribute))
  );
};

export const addRequiredExclusionAttributesToExclusions = (
  exclusions: BasketExclusion[],
  basketArticles: BasketArticle[]
) => {
  return exclusions
    .filter(
      (exclusion) =>
        !requiredAttributes.every((attribute) =>
          exclusion.hasOwnProperty(attribute)
        )
    )
    .map(({ excludedAt, ...exclusion }) => {
      const matchedArticle = basketArticles.find(
        (basketArticle) =>
          getUniqueBasketArticleId(basketArticle) ===
          getUniqueBasketArticleId(exclusion)
      );
      if (!matchedArticle) {
        return exclusion;
      }
      const {
        fatSaturatedPer100g,
        fatTotalPer100g,
        sodiumPer100g,
        addedSugarsPer100g,
        totalSugarsPer100g,
        packSizeMultiplier,
      } = matchedArticle;
      return {
        ...exclusion,
        fatSaturatedPer100g,
        fatTotalPer100g,
        sodiumPer100g,
        addedSugarsPer100g,
        totalSugarsPer100g,
        packSizeMultiplier,
      };
    });
};

export const groupNutrientsByTimePeriods = (
  nutrientsList: AggregateDailyNutrients[],
  timePeriod: Duration,
  howMany: number
): AggregateDailyNutrients[][] => {
  const dateRanges = generateDateRangesBackFromYesterday(howMany, timePeriod);
  return dateRanges.map((dateRange) =>
    nutrientsList.filter(
      (nutrients) =>
        nutrients.purchaseDate >= dateRange.from.toMillis() &&
        nutrients.purchaseDate <= dateRange.to.toMillis()
    )
  );
};

const toAggregateDailyNutrients = (snapshot: QuerySnapshot<DocumentData>) => {
  return snapshot.docs.map((doc) => {
    const data = doc.data() as AggregateDailyNutrients;
    if (data.nutrients.addedSugar_G > data.nutrients.totalSugar_G) {
      // The values for added sugars are approximations, sourced from the Ausnut Database.
      // Occasionally, these values exceed the total sugar values (derived from the NIP), in which case they should be adjusted to match the total sugar values.
      // This adjustment was advised by our nutritionists, Kate and Bonnie.
      data.nutrients.addedSugar_G = data.nutrients.totalSugar_G;
    }
    return data;
  });
};

const toRealTimeAggregateDailyNutrients = (
  snapshot: QuerySnapshot<DocumentData>
) => {
  const aggregates: Map<string, AggregateDailyNutrients> = new Map();

  snapshot.docs.forEach((doc) => {
    const transaction = doc.data() as AggregateDailyNutrients;
    const dateKey = DateTime.fromMillis(transaction.purchaseDate).toISODate(); // Converts timestamp to 'YYYY-MM-DD'
    if (
      transaction.nutrients.addedSugar_G > transaction.nutrients.totalSugar_G
    ) {
      transaction.nutrients.addedSugar_G = transaction.nutrients.totalSugar_G;
    }

    if (!aggregates.has(dateKey)) {
      aggregates.set(dateKey, { ...transaction });
    } else {
      const existingT = aggregates.get(dateKey)!;
      existingT.nutrients.addedSugar_G += transaction.nutrients.addedSugar_G;
      existingT.nutrients.saturatedFat_G +=
        transaction.nutrients.saturatedFat_G;
      existingT.nutrients.sodium_MG += transaction.nutrients.sodium_MG;
      existingT.nutrients.totalFat_G += transaction.nutrients.totalFat_G;
      existingT.nutrients.totalSugar_G += transaction.nutrients.totalSugar_G;
    }
  });

  return Array.from(aggregates.values());
};

export type NutrientsHistoryChangeListener = (
  nutrientsHistory: AggregateDailyNutrients[]
) => void;

export const subscribeToRealTimeNutrients = (
  memberId: string,
  timeFrame: TimeFrame,
  until: DateTime,
  onChange: NutrientsHistoryChangeListener
) => {
  const NUMBER_OF_HISTORICAL_TIME_PERIODS = 6;
  const timePeriodsToShow = TimeFrameDuration[timeFrame];
  const queryTimeFrame = timePeriodsToShow.mapUnits(
    (x) => x * NUMBER_OF_HISTORICAL_TIME_PERIODS
  );
  const { from, to } = getFromAndTo(queryTimeFrame, until);
  const q = query(
    collection(
      db,
      Collections.Members,
      memberId,
      Collections.RealTimeDailyNutrients
    ),
    where(
      Fields.RealTimeDailyNutrients.Shard,
      "in",
      Shards.RealTimeDailyNutrients
    ),
    where(Fields.RealTimeDailyNutrients.PurchaseDate, ">=", from.toMillis()),
    where(Fields.RealTimeDailyNutrients.PurchaseDate, "<=", to.toMillis())
  );

  return onSnapshot(
    q,
    (snapshot) => {
      onChange(toRealTimeAggregateDailyNutrients(snapshot));
    },
    (error) => {
      console.error("Error reading real time nutrients:", error.message);
    }
  );
};

export const getNutrients = async (
  memberId: string,
  timeFrame: Duration,
  until: DateTime
): Promise<AggregateDailyNutrients[]> => {
  const q = getNutrientsQuery(timeFrame, until, memberId);
  const snapshot = await getDocs(q);
  return toAggregateDailyNutrients(snapshot);
};

export const subtractExclusionsFromHistory = (
  nutrientsHistory: NutrientsHistory[],
  nutrientsExclusionsHistory: NutrientsHistory[]
): NutrientsHistory[] => {
  if (nutrientsHistory.length !== nutrientsExclusionsHistory.length) {
    throw new Error(
      "nutrientsHistory and nutrientsExclusionsHistory must be of the same length"
    );
  }

  return nutrientsHistory.map((nutrientHistory, i) => {
    const exclusions = nutrientsExclusionsHistory[i];

    return {
      sugar: {
        innerValue: nutrientHistory.sugar.innerValue
          ? Math.ceil(
              (nutrientHistory.sugar.innerValue || 0) -
                (exclusions.sugar.innerValue || 0)
            )
          : null,
        totalValue: nutrientHistory.sugar.totalValue
          ? Math.ceil(
              (nutrientHistory.sugar.totalValue || 0) -
                (exclusions.sugar.totalValue || 0)
            )
          : null,
      },
      salt: {
        innerValue: nutrientHistory.salt.innerValue
          ? Math.ceil(
              (nutrientHistory.salt.innerValue || 0) -
                (exclusions.salt.innerValue || 0)
            )
          : null,
        totalValue: nutrientHistory.salt.totalValue
          ? Math.ceil(
              (nutrientHistory.salt.totalValue || 0) -
                (exclusions.salt.totalValue || 0)
            )
          : null,
      },
      fat: {
        innerValue: nutrientHistory.fat.innerValue
          ? Math.ceil(
              (nutrientHistory.fat.innerValue || 0) -
                (exclusions.fat.innerValue || 0)
            )
          : null,
        totalValue: nutrientHistory.fat.totalValue
          ? Math.ceil(
              (nutrientHistory.fat.totalValue || 0) -
                (exclusions.fat.totalValue || 0)
            )
          : null,
      },
    };
  });
};

export const filterTopContributors = (
  products: BasketArticle[],
  attributeKey: keyof BasketArticle,
  attributeThreshold: number,
  isThresholdPer100g: boolean,
  productsToShow: number,
  useQuantityAdjustedPackSizeMultiplier: boolean
) => {
  const articleIds = new Set<string>();
  return products
    .filter((product) => {
      // Remove products not meeting thresholds
      if (typeof product[attributeKey] !== "number") return false;
      const attributeValue = product[attributeKey] as unknown as number;
      let attributeValueForThreshold;
      if (useQuantityAdjustedPackSizeMultiplier) {
        attributeValueForThreshold = isThresholdPer100g
          ? attributeValue
          : attributeValue * (product.packSizeMultiplier / product.quantity);
      } else {
        attributeValueForThreshold = isThresholdPer100g
          ? attributeValue
          : attributeValue * product.packSizeMultiplier;
      }
      return attributeValueForThreshold >= attributeThreshold;
    })
    .sort((p1, p2) => {
      // Products with Healthier Options should be shown first, regardless of their contribution
      if (p1.hasHealthierOption && !p2.hasHealthierOption) return -1;
      if (!p1.hasHealthierOption && p2.hasHealthierOption) return 1;
      // Sort products by highest contributor to lowest
      const p1AttributeValue = p1[attributeKey] as unknown as number;
      const p2AttributeValue = p2[attributeKey] as unknown as number;
      if (useQuantityAdjustedPackSizeMultiplier) {
        return (
          p2AttributeValue * (p2.packSizeMultiplier / p2.quantity) -
          p1AttributeValue * (p1.packSizeMultiplier / p1.quantity)
        );
      } else {
        return (
          p2AttributeValue * p2.packSizeMultiplier -
          p1AttributeValue * p1.packSizeMultiplier
        );
      }
    })
    .filter((product) => {
      // Remove duplicate products
      if (product.articleId && articleIds.has(product.articleId)) {
        return false;
      }
      if (product.articleId) {
        articleIds.add(product.articleId);
      }
      return true;
    })
    .slice(0, productsToShow);
};
