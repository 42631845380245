import { Dialog, IconButton } from "@material-ui/core";
import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useState,
} from "react";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { LIGHTER_CHARCOAL } from "../theme";
import useEvents from "../hooks/useEvents";
import { ReactComponent as Help } from "../icons/help-thin.svg";
import { ifEnterOrSpace } from "../utils/keyboardNavigation";

const useStyles = makeStyles(() => ({
  dialogPadding: {
    paddingTop: "32px",
    paddingBottom: "32px",
    paddingLeft: "24px",
    paddingRight: "24px",
    position: "relative",
    //do not change the below to vh or else it will break android app integration
    height: "fit-content",
    "& strong, & b": {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
    },
  },
  closeButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: LIGHTER_CHARCOAL,
  },
  helpModalMainContent: {
    "& > * + *": {
      marginTop: "16px",
    },
  },
}));

export const InfoModalDefaultButton: FC = (props) => {
  return (
    <IconButton style={{ padding: 0 }} aria-label="Info" {...props}>
      <Help />
    </IconButton>
  );
};

export const InfoModalTitle: FC = ({ children }) => {
  return (
    <Typography variant="h4" component="h4" aria-live="polite">
      {children}
    </Typography>
  );
};

export const InfoModalSubTitle: FC = ({ children }) => {
  return (
    <Typography variant="h6" component="h6">
      {children}
    </Typography>
  );
};

export const InfoModalContent: FC = ({ children }) => {
  return (
    <Typography
      variant="body2"
      style={{
        color: LIGHTER_CHARCOAL,
      }}
    >
      {children}
    </Typography>
  );
};

const CloseModalContext = createContext<(() => void) | undefined>(undefined);

export const useCloseModal = () => {
  const context = useContext(CloseModalContext);
  if (!context) {
    throw new Error(
      "useCloseModal must be used within a CloseModalContext provider"
    );
  }
  return context;
};

export const InfoModalContainer: FC<{
  button: ReactElement;
  children: ReactElement | ReactElement[];
  eventName: string;
  eventCloseName?: string;
  eventParams?: object;
}> = ({ button, children, eventName, eventCloseName, eventParams }) => {
  const { track } = useEvents();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const onClose = () => {
    if (eventCloseName) {
      if (eventParams) {
        const params = eventParams as Record<string, unknown>;
        track(eventCloseName!, params);
      } else {
        track(eventCloseName!);
      }
    }
    setOpen(false);
  };

  const handleOpen = () => {
    if (eventParams) {
      const params = eventParams as Record<string, unknown>;
      track(eventName, params);
    } else {
      track(eventName);
    }

    setOpen(true);
  };

  const handleKeyPress = (e: { key: string; code: string }) => {
    ifEnterOrSpace(e, handleOpen);
  };

  return (
    <>
      {React.cloneElement(button, {
        onClick: handleOpen,
        onKeyPress: handleKeyPress,
      })}
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen={false}
        maxWidth={"sm"}
        fullWidth={true}
        TransitionProps={{ role: "presentation" } as any}
        PaperProps={{ role: "main", className: classes.dialogPadding } as any}
      >
        <IconButton
          aria-label="Close Button"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <CloseModalContext.Provider value={onClose}>
          <div className={classes.helpModalMainContent}>{children}</div>
        </CloseModalContext.Provider>
      </Dialog>
    </>
  );
};
