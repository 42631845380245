import { withStyles } from "@material-ui/core/styles";
import { TableBody } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";

export const StyledTableBody = withStyles({
  root: (props: { rightAlignBody?: boolean }) => ({
    ...(props.rightAlignBody
      ? {
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: `1px solid ${COLOR_GREYSCALE[700]}`,
        }
      : {}),
  }),
})(TableBody);
