import React, { createElement } from "react";
import { FoodGroups } from "../../models";
import {
  FoodGroupBarChartIcon as FoodGroupSvgIcon,
  FoodGroupLabel,
} from "../../constants";
import { makeStyles, Typography } from "@material-ui/core";
import { CHARCOAL } from "../../theme";

const FoodGroupLegend = ({
  isOnboarding,
  inSidebar,
}: {
  isOnboarding: boolean;
  inSidebar?: boolean;
}) => {
  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
      marginTop: spacing(2),
      display: inSidebar ? "grid" : "flex",
      gridTemplateColumns: "1fr 1fr",
      flexWrap: "wrap",
      columnGap: "16px",
      rowGap: "8px",
      [breakpoints.up("md")]: isOnboarding
        ? {}
        : {
            marginBottom: "16px",
            marginTop: "16px",
          },
    },
    iconAndName: {
      display: "flex",
      alignItems: "center",
      [breakpoints.down("sm")]: {
        width: "139px",
        marginTop: "8px",
        marginRight: "16px",
      },
      [breakpoints.up("md")]: isOnboarding
        ? {
            width: "139px",
            marginTop: "8px",
            marginRight: "16px",
          }
        : {
            width: "100%",
            height: "fit-content",
            marginTop: "16px",
          },
    },
    text: {
      marginLeft: spacing(1),
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      color: CHARCOAL,
    },
    foodGroupIcon: {
      width: "36px",
      minWidth: "36px",
      height: "36px",
      minHeight: "36px",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root} aria-hidden={"true"}>
      {FoodGroups.map((foodGroup) => (
        <div className={classes.iconAndName}>
          {createElement(FoodGroupSvgIcon[foodGroup], {
            className: classes.foodGroupIcon,
          })}
          <Typography variant="body2" className={classes.text}>
            {FoodGroupLabel[foodGroup]}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default FoodGroupLegend;
