import { ReactComponent as FilterRecipeIcon } from "../../../icons/filter-recipe.svg";
import { ChevronRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { cloneElement, useContext } from "react";
import useEvents from "../../../hooks/useEvents";
import { RECIPES_EVENTS } from "../../../constants";
import RecipeFilterGroups from "./RecipeFilterGroups";
import { RecipeContext } from "../../../components/providers/RecipeProvider";
import {
  DIETARY_PREFERENCES,
  DIETARY_PREFERENCES_DATA,
} from "../../dietary-preference/config";
import useHideDietaryPreferences from "../../../hooks/useHideDietaryPreferences";

const useStyles = makeStyles(({ breakpoints }) => ({
  filter: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingBottom: "16px",
      borderBottom: "solid 1px #E9EBEC",
      rowGap: "16px",
      flexWrap: "wrap",
    },
  },
  filterButton: {
    backgroundColor: "transparent",
    padding: 0,
    border: 0,
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    color: "#1D1F20",
    cursor: "pointer",
  },
  filterIcon: {
    marginRight: "4px",
  },
  chevronIcon: {
    marginLeft: "10px",
  },
  filterButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterList: {
    display: "flex",
    gap: "16px",
    marginTop: "8px",
    flexWrap: "wrap",
    [breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  filterTags: {
    padding: "4px 8px",
    backgroundColor: "#1D1F20",
    border: "solid 1px #D3D6D9",
    borderRadius: "20px",
    color: "#ffffff",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  dietaryFilters: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
}));

const RecipeFilterButton = () => {
  const { track } = useEvents();
  const {
    setToggleFilter,
    currentFilters,
    setCurrentFilters,
    setTempFilters,
    setFilteredRecipes,
  } = useContext(RecipeContext);

  const hideDP = useHideDietaryPreferences();

  const classes = useStyles();
  return (
    <>
      <div className={classes.filterButtons}>
        <button
          className={classes.filterButton}
          onClick={() => {
            track(RECIPES_EVENTS.RLP_FILTER_OPEN);
            setToggleFilter(true);
          }}
        >
          <FilterRecipeIcon className={classes.filterIcon} /> Filter
          <ChevronRight className={classes.chevronIcon} />
        </button>
        {!hideDP && (
          <div className={classes.dietaryFilters}>
            {currentFilters.map((filter) => {
              if (!filter.includes("dietarypreferences:")) return null;
              const diet = filter.split(":")[1];
              const PreferenceIcon = cloneElement(
                DIETARY_PREFERENCES_DATA[diet as DIETARY_PREFERENCES].icon,
                {
                  width: 24,
                  height: 24,
                  onClick: () => {
                    const filteredPref = currentFilters.filter(
                      (pref) => pref !== "dietarypreferences:" + diet
                    );
                    setFilteredRecipes(filteredPref);
                    setCurrentFilters(filteredPref);
                    setTempFilters(filteredPref);
                  },
                }
              );
              return PreferenceIcon;
            })}
          </div>
        )}
      </div>

      <RecipeFilterGroups />
    </>
  );
};

export default RecipeFilterButton;
