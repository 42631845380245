import { FC } from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import { LIGHT_GREY } from "../theme";

interface Props {
  message: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    minWidth: 375,
  },
  title: {
    marginBottom: spacing(3),
  },
  panel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 4,
    background: LIGHT_GREY,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
    minHeight: 350,
    maxWidth: 350,
    padding: spacing(4),
    textAlign: "center",
  },
  errorText: {
    color: palette.secondary.main,
    marginBottom: spacing(3),
  },
  errorIcon: {
    color: palette.secondary.main,
    height: 64,
    width: 64,
  },
}));

const FatalErrorMessage: FC<Props> = ({ message }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <Typography variant="h2" className={classes.title}>
        Healthylife Food Tracker
      </Typography>

      <div className={classes.panel}>
        <Typography variant="h2" className={classes.errorText}>
          {message}
        </Typography>

        <SentimentDissatisfiedOutlinedIcon className={classes.errorIcon} />
      </div>
    </Container>
  );
};

export default FatalErrorMessage;
