import { FC } from "react";
import { Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import { formatDate } from "../utils/dates";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { COLOR_GREYSCALE } from "../theme";

interface Props {
  from: DateTime;
  to: DateTime;
  ariaHidden?: boolean;
  textColorMobile?: string;
  textColorDesktop?: string;
}

const DateRangeIndicator: FC<Props> = ({
  from,
  to,
  ariaHidden = false,
  textColorMobile = "#fff",
  textColorDesktop = "#fff",
}) => {
  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      color: textColorMobile ? textColorMobile : COLOR_GREYSCALE[800],
      fontSize: "12px",
      textAlign: "center",
      fontFamily: "Gilroy",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      [breakpoints.up("md")]: {
        fontSize: "16px",
        textAlign: "right",
        color: textColorDesktop ? textColorDesktop : COLOR_GREYSCALE[800],
      },
    },
  }));
  const classes = useStyles();
  const formattedFrom = formatDate(from);
  const formattedTo = formatDate(to);
  return (
    <Typography
      variant="body2"
      component={"span"}
      className={classes.root}
      aria-label={`Results being shown from ${formattedFrom} to ${formattedTo}`}
      role="group"
      aria-hidden={ariaHidden}
    >
      <div aria-hidden="true">
        {formattedFrom} - {formattedTo}
      </div>
    </Typography>
  );
};

export default DateRangeIndicator;
