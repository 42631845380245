import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { SettingType } from "../../components-2/sidebar-settings/SidebarPersonalisation";

interface Props {}

interface SettingsContextState {
  isOpenSidebar: boolean;
  setIsOpenSidebar: Dispatch<SetStateAction<boolean>>;
  isOpenTools: boolean;
  setIsOpenTools: Dispatch<SetStateAction<boolean>>;
  isOpenHelp: boolean;
  setIsOpenHelp: Dispatch<SetStateAction<boolean>>;
  isOpenFeedback: boolean;
  setIsOpenFeedback: Dispatch<SetStateAction<boolean>>;
  isOpenRewards: boolean;
  setIsOpenRewards: Dispatch<SetStateAction<boolean>>;
  isOpenOptout: boolean;
  setIsOpenOptout: Dispatch<SetStateAction<boolean>>;
  isOpenPersonalisation: SettingType | false;
  setIsOpenPersonalisation: Dispatch<SetStateAction<SettingType | false>>;
  isOpenCommunication: boolean;
  setIsOpenCommunication: Dispatch<SetStateAction<boolean>>;
  closeAllSidebar: () => void;
  isAdditionalPageOpen: boolean;
  closeAllOnExit: boolean;
  setCloseAllOnExit: Dispatch<SetStateAction<boolean>>;
  fromInstantUpdatesBanner: boolean;
  setFromInstantUpdatesBanner: Dispatch<SetStateAction<boolean>>;
}

export const SettingsContext = createContext<SettingsContextState>({
  isOpenSidebar: false,
  setIsOpenSidebar: () => {},
  isOpenTools: false,
  setIsOpenTools: () => {},
  isOpenHelp: false,
  setIsOpenHelp: () => {},
  isOpenFeedback: false,
  setIsOpenFeedback: () => {},
  isOpenRewards: false,
  setIsOpenRewards: () => {},
  isOpenOptout: false,
  setIsOpenOptout: () => {},
  isOpenPersonalisation: false,
  setIsOpenPersonalisation: () => {},
  isOpenCommunication: false,
  setIsOpenCommunication: () => {},
  closeAllSidebar: () => {},
  isAdditionalPageOpen: false,
  closeAllOnExit: false,
  setCloseAllOnExit: () => {},
  fromInstantUpdatesBanner: false,
  setFromInstantUpdatesBanner: () => {},
});

const SettingsProvider: FC<Props> = ({ children }) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenTools, setIsOpenTools] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const [isOpenRewards, setIsOpenRewards] = useState(false);
  const [isOpenOptout, setIsOpenOptout] = useState(false);
  const [isOpenPersonalisation, setIsOpenPersonalisation] = useState<
    SettingType | false
  >(false);
  const [isOpenCommunication, setIsOpenCommunication] = useState(false);
  const [closeAllOnExit, setCloseAllOnExit] = useState(false);
  const [fromInstantUpdatesBanner, setFromInstantUpdatesBanner] = useState(false);

  const closeAllSidebar = () => {
    setIsOpenHelp(false);
    setIsOpenTools(false);
    setIsOpenFeedback(false);
    setIsOpenRewards(false);
    setIsOpenSidebar(false);
    setIsOpenPersonalisation(false);
    setIsOpenCommunication(false);
    setIsOpenOptout(false);
    setCloseAllOnExit(false);
    setFromInstantUpdatesBanner(false);
  };

  const isAdditionalPageOpen = useMemo(() => {
    return (
      isOpenTools ||
      isOpenHelp ||
      isOpenFeedback ||
      isOpenRewards ||
      isOpenOptout ||
      isOpenPersonalisation !== false ||
      isOpenCommunication
    );
  }, [
    isOpenCommunication,
    isOpenFeedback,
    isOpenHelp,
    isOpenOptout,
    isOpenPersonalisation,
    isOpenRewards,
    isOpenTools,
  ]);

  const value = {
    isOpenSidebar,
    setIsOpenSidebar,
    isOpenTools,
    setIsOpenTools,
    isOpenHelp,
    setIsOpenHelp,
    isOpenFeedback,
    setIsOpenFeedback,
    isOpenRewards,
    setIsOpenRewards,
    isOpenOptout,
    setIsOpenOptout,
    isOpenPersonalisation,
    setIsOpenPersonalisation,
    isOpenCommunication,
    setIsOpenCommunication,
    closeAllSidebar,
    isAdditionalPageOpen,
    closeAllOnExit,
    setCloseAllOnExit,
    fromInstantUpdatesBanner,
    setFromInstantUpdatesBanner,
  };
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
