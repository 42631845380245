import { withStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";

export const StyledTableRow = withStyles({
  root: (props: { vertical?: boolean }) => ({
    ...(props.vertical
      ? {
          display: "flex",
          flexDirection: "column",
        }
      : {}),
  }),
})(TableRow);
