import { Button, Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { AnimatedImageCard } from "../animated-image-card";
import {
  AnimatedDrawer,
  ArticleCard,
  BackButton,
  CancelButton,
  ExternalArticleLink,
  InternalPageLink,
} from "../animated-drawer";
import React, {
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as ScannerLogo } from "../../icons/logo-scanner.svg";
import { Text } from "../Text";
import { DIETARY_PREFERENCES, DIETARY_PREFERENCES_DATA } from "./config";
import RecipeCard from "../recipe/RecipeCard";
import { CHARCOAL, WHITE } from "../../theme";
import { motion } from "framer-motion";
import { SettingsContext } from "../../components/providers/SettingsProvider";

const Overlay = ({ open }: { open: boolean }) => (
  <motion.div
    initial={false}
    animate={{ opacity: open ? 1 : 0 }}
    transition={{ duration: open ? 0.3 : 0 }}
    style={{
      pointerEvents: open ? "auto" : "none",
      zIndex: 1,
      position: "fixed",
      background: "#faf5ec",
      willChange: "opacity",
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      borderRadius: "10px",
    }}
  />
);

const SpecificDietaryPreferenceScreen = ({
  setOpenDrawer,
  openCard,
  selectedDietaryPreference,
  setOpenCard,
  setSelectedDietaryPreference,
}: {
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  openCard: string | null;
  selectedDietaryPreference: DIETARY_PREFERENCES | null;
  setOpenCard: Dispatch<SetStateAction<string | null>>;
  setSelectedDietaryPreference: (
    preference: DIETARY_PREFERENCES | null
  ) => void;
}) => {
  if (selectedDietaryPreference === null) {
    return null;
  }
  const {
    DRAWER_CONTENT: { CARD_CONTENT, RECIPES, ARTICLES },
    name,
    ARTICLE_LINK,
    BLACK_ICON,
  } = DIETARY_PREFERENCES_DATA[selectedDietaryPreference];
  return (
    <>
      <Overlay open={openCard !== null} />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={() => setSelectedDietaryPreference(null)} />
          <CancelButton onClick={() => setOpenDrawer(false)} />
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {cloneElement(BLACK_ICON)} <Text variant={"h1"}>{name}</Text>
        </div>
        <Divider style={{ margin: "24px 0px" }} />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Text variant={"h2"}>Navigating your nutrition</Text>
          <Text variant={"body2"}>Select and discover</Text>
        </div>
      </div>
      {CARD_CONTENT.map(({ content, title, image }, index) => {
        return (
          <AnimatedImageCard
            key={title}
            content={content}
            title={title}
            image={image}
            uniqueKey={title}
            openCard={openCard}
            setOpenCard={setOpenCard}
            layoutId={title}
            setOpenDrawer={setOpenDrawer}
          />
        );
      })}
      <Divider style={{ margin: "24px 0px" }} />
      <Text variant={"h2"}>{name} recipes you'll love</Text>
      {RECIPES.map((recipe, index) => {
        return (
          <RecipeCard
            smallDesktop
            {...recipe}
            index={index}
            style={{
              backgroundColor: WHITE,
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
            }}
          />
        );
      })}
      <InternalPageLink text={"View all recipes"} route={"/recipes"} />
      <Divider style={{ margin: "24px 0px" }} />
      <Text variant={"h2"}>Popular {name.toLowerCase()} articles</Text>
      {ARTICLES.map((article, index) => {
        return <ArticleCard {...article} />;
      })}
      <ExternalArticleLink
        href={ARTICLE_LINK}
        text={"View more on Healthylife"}
        utmContent={"dietary-preferences"}
      />
    </>
  );
};

const SelectDietaryPreferenceScreen = ({
  setSelectedDietaryPreference,
  setOpenDrawer,
}: {
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  setSelectedDietaryPreference: (
    preference: DIETARY_PREFERENCES | null
  ) => void;
}) => {
  const { setIsOpenPersonalisation } = useContext(SettingsContext);
  const openDietaryPreferencesSettings = () => {
    setOpenDrawer(false);
    setTimeout(() => setIsOpenPersonalisation("dietary"), 500);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: "24px",
            }}
          >
            <ScannerLogo
              style={{
                height: "64px",
                width: "64px",
              }}
            />
            <CancelButton onClick={() => setOpenDrawer(false)} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Text variant={"h1"}>
              Enjoy Food Tracker,
              <br /> your way
            </Text>
            <Text variant={"body1"}>
              Explore our expert advice and tips on how to achieve a balanced
              diet with your dietary preferences in mind.
            </Text>
          </div>
          <Divider style={{ margin: "24px 0px" }} />
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {Object.values(DIETARY_PREFERENCES_DATA).map(
              ({ BLACK_ICON, name, id }) => {
                return (
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                    }}
                    endIcon={
                      <ChevronRight
                        style={{
                          color: CHARCOAL,
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    }
                    onClick={() => setSelectedDietaryPreference(id)}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        textTransform: "none",
                        fontFamily: "Gilroy-Semibold",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                      }}
                    >
                      {cloneElement(BLACK_ICON)} {name}
                    </span>
                  </Button>
                );
              }
            )}
          </div>
        </div>
        <span
          onClick={openDietaryPreferencesSettings}
          style={{
            fontFamily: "Gilroy",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            textDecorationLine: "underline",
            color: "#458378",
            alignSelf: "center",
            marginTop: "16px",
            cursor: "pointer",
          }}
        >
          Edit my dietary preferences
        </span>
      </div>
    </>
  );
};

export const DietaryPreferenceDrawer = ({
  openButton,
}: {
  openButton: ReactElement;
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCard, setOpenCard] = useState<string | null>(null);
  const [justOpened, setJustOpened] = useState(true);
  const [selectedDietaryPreference, setSelectedDietaryPreference] =
    useState<DIETARY_PREFERENCES | null>(null);
  const resetDrawerState = () => {
    setOpenCard(null);
    setJustOpened(true);
    setSelectedDietaryPreference(null);
  };

  useEffect(() => {
    if (!openDrawer) return;
    let timeout: NodeJS.Timeout | undefined;
    if (justOpened) {
      timeout = setTimeout(() => setJustOpened(false), 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer]);

  const [isFading, setIsFading] = useState(false);
  const handleSwitch = (preference: DIETARY_PREFERENCES | null) => {
    setIsFading(true);
    setTimeout(() => {
      setSelectedDietaryPreference(preference);
      setIsFading(false);
    }, 200);
  };

  useEffect(() => {
    Object.values(DIETARY_PREFERENCES_DATA).forEach((DP) => {
      DP.DRAWER_CONTENT.CARD_CONTENT.forEach((card) => {
        new Image().src = card.image;
      });
    });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  return (
    <AnimatedDrawer
      openButton={openButton}
      open={openDrawer}
      setOpen={setOpenDrawer}
      onClose={() => {
        setOpenDrawer(false);
        setTimeout(resetDrawerState, 500);
      }}
    >
      <motion.div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          overflow: "auto",
          width: "100%",
          height: "100%",
          position: "relative",
          maxWidth: "506px",
        }}
        animate={{
          x: 0,
          transition: {
            duration: justOpened ? 0 : 0.3,
            type: "spring",
            bounce: 0,
          },
          transitionEnd: {
            transform: "none",
            x: 0,
          },
        }}
        initial={{
          x: `${
            110 * (isMobile ? (selectedDietaryPreference === null ? -1 : 1) : 1)
          }%`,
        }}
        key={selectedDietaryPreference}
      >
        <motion.div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "24px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            height: "100%",
            opacity: isFading ? 0 : 1,
            transition: "opacity 0.2s",
          }}
        >
          {selectedDietaryPreference === null ? (
            <SelectDietaryPreferenceScreen
              setOpenDrawer={setOpenDrawer}
              setSelectedDietaryPreference={handleSwitch}
            />
          ) : (
            <SpecificDietaryPreferenceScreen
              setOpenDrawer={setOpenDrawer}
              openCard={openCard}
              setOpenCard={setOpenCard}
              selectedDietaryPreference={selectedDietaryPreference}
              setSelectedDietaryPreference={handleSwitch}
            />
          )}
        </motion.div>
      </motion.div>
    </AnimatedDrawer>
  );
};
