import { AgeGroup, AgeRange } from "../../models";
import { mapToAgeGroup } from "./agegroup";
import { AGE_RANGE_OPTIONS } from "./hardcodedContentfulContent";

export const mapToAgeRange = (item: any): AgeRange => ({
  id: item.sys.id,
  name: item.name,
  from: item.from,
  to: item.to,
  group: mapToAgeGroup(item.group),
  enabled: item.enabled,
});

export const listAgeRangeOptions = async (
  ageGroup: AgeGroup
): Promise<AgeRange[]> => {
  // Used to fetched from Contentful, now hard coded.
  const { items } = AGE_RANGE_OPTIONS.data.ageRangeCollection;
  return items
    .map((item: any) => ({
      id: item.sys.id,
      name: item.name,
      from: item.from,
      to: item.to,
      group: mapToAgeGroup(item.group),
      enabled: item.enabled,
    }))
    .filter((item: AgeRange) => item.enabled && item.group.id === ageGroup.id);
};
