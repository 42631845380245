import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { FoodGroup } from "../../models";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { getEnvironment } from "../../utils/misc";

interface Props {
  circleColor: string;
  foodGroup: FoodGroup | "discretionary" | "main";
}

const Footer = ({ foodGroup, circleColor }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const environment = getEnvironment();

  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      width: "100%",
      position: "relative",
      height: "336px",
      overflow: "hidden",
      paddingTop: "40px",
      boxSizing: "content-box",
      [breakpoints.up("md")]: {
        height: "197px",
        paddingTop: "80px",
        marginTop: 40,
      },
    },
    bottomCircle: {
      backgroundColor: circleColor,
      width: 1600,
      height: 1600,
      borderRadius: "100%",
      position: "absolute",
      top: 40,
      left: "50%",
      transform: "translateX(-50%)",
      overflow: "hidden",
      display: "block",
      [breakpoints.only("sm")]: {
        borderRadius: "40%",
      },
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
  }))();

  const images = {
    main: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/main-wsh.png`,
        alt: "Healthy meal",
        styles: {
          top: isDesktop ? -52 : 24,
          left: isDesktop ? "calc(100% - 340px)" : "calc(100% - 336px)",
          width: isDesktop ? 420 : 480,
          height: isDesktop ? 420 : 480,
        } as CSSProperties,
      },
      image2: null,
    },
    dairy: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/dairy1-wsh.png`,
        alt: "dairy cheese",
        styles: {
          top: isDesktop ? 30 : 60,
          left: isDesktop ? -60 : -197,
          width: 380,
          height: 380,
        } as CSSProperties,
      },
      image2: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/dairy2-wsh.png`,
        alt: "dairy milk",
        styles: {
          top: isDesktop ? 0 : -34,
          left: isDesktop ? "calc(100% - 389px)" : "calc(100% - 276px)",
          width: 420,
          height: 296,
        } as CSSProperties,
      },
    },
    grains: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/grains1-wsh.png`,
        alt: "grains bread",
        styles: {
          top: isDesktop ? 30 : 5,
          left: isDesktop ? 0 : -46,
          width: 210,
          height: 210,
        } as CSSProperties,
      },
      image2: {
        src: isDesktop
          ? `https://storage.googleapis.com/food-tracker-images-${environment}/grains3-wsh.png`
          : `https://storage.googleapis.com/food-tracker-images-${environment}/grains2-wsh.png`,
        alt: "grains oats",
        styles: {
          top: isDesktop ? 86 : 135,
          left: isDesktop ? "calc(100% - 279px)" : "calc(100% - 175px)",
          width: isDesktop ? 300 : 225,
          height: isDesktop ? 300 : 225,
        } as CSSProperties,
      },
    },
    vegetables: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/vegetables1-wsh.png`,
        alt: "vegetable carrots",
        styles: {
          top: isDesktop ? 82 : 227,
          left: isDesktop ? 35 : -48,
          width: 240,
          height: 194,
        } as CSSProperties,
      },
      image2: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/vegetables2-wsh.png`,
        alt: "vegetable broccoli",
        styles: {
          top: isDesktop ? -93 : -130,
          left: isDesktop ? "calc(100% - 320px)" : "calc(100% - 287px)",
          width: isDesktop ? 380 : 420,
          height: isDesktop ? 400 : 442,
        } as CSSProperties,
      },
    },
    fruit: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/fruit1-wsh.png`,
        alt: "fruit orange",
        styles: {
          top: isDesktop ? "calc(100% - 172px)" : "calc(100% - 190px)",
          left: isDesktop ? 10 : 0,
          width: isDesktop ? 152 : 180,
          height: isDesktop ? 152 : 180,
        } as CSSProperties,
      },
      image2: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/fruit2-wsh.png`,
        alt: "fruit bananas",
        styles: {
          top: isDesktop ? 0 : -20,
          left: isDesktop ? "calc(100% - 200px)" : "calc(100% - 220px)",
          width: isDesktop ? 200 : 250,
          height: isDesktop ? 200 : 250,
        } as CSSProperties,
      },
    },
    protein: {
      image1: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/protein1-wsh.png`,
        alt: "protein eggs",
        styles: {
          top: isDesktop ? 0 : -10,
          left: isDesktop ? 0 : -50,
          width: isDesktop ? 200 : 220,
          height: isDesktop ? 205 : 226,
        } as CSSProperties,
      },
      image2: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/protein2-wsh.png`,
        alt: "protein almonds",
        styles: {
          top: isDesktop ? 140 : 225,
          left: isDesktop ? "calc(100% - 210px)" : "calc(100% - 185px)",
          width: isDesktop ? 175 : 190,
          height: isDesktop ? 175 : 190,
        } as CSSProperties,
      },
    },
    discretionary: {
      image1: {
        alt: "sometimes foods M&M chocolates",
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/sometimes1-wsh.png`,
        styles: {
          top: isDesktop ? 100 : 170,
          left: isDesktop ? 0 : -30,
          width: 175,
          height: 175,
        } as CSSProperties,
      },
      image2: {
        src: `https://storage.googleapis.com/food-tracker-images-${environment}/sometimes2-wsh.png`,
        alt: "sometimes foods chips",
        styles: {
          top: isDesktop ? 30 : 0,
          left: isDesktop ? "calc(100% - 235px)" : "calc(100% - 155px)",
          width: 210,
          height: 210,
        } as CSSProperties,
      },
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.bottomCircle} />
      <img
        src={images[foodGroup]?.image1!.src}
        alt={images[foodGroup]?.image1!.alt}
        style={{
          ...images[foodGroup]?.image1!.styles,
          position: "absolute",
        }}
      />
      {images[foodGroup]?.image2 && (
        <img
          src={images[foodGroup]?.image2!.src}
          alt={images[foodGroup]?.image2!.alt}
          style={{
            ...images[foodGroup]?.image2!.styles,
            position: "absolute",
          }}
        />
      )}
    </div>
  );
};

export default Footer;
