import { GOAL_DATA, GOALS } from "./config";
import React, { cloneElement, Dispatch, SetStateAction, useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { COLOR_GREYSCALE, DARK_GREEN } from "../../theme";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";

export const GoalEditBar = ({
  goal,
  checked,
  setChecked,
}: {
  goal: GOALS;
  checked: GOALS[];
  setChecked: Dispatch<SetStateAction<GOALS[]>>;
}) => {
  const isGoalChecked = useMemo(() => checked.includes(goal), [checked, goal]);
  const isGoalDisabled = useMemo(
    () => checked.length >= 3 && !isGoalChecked,
    [checked.length, isGoalChecked]
  );
  const useStyles = makeStyles(() => ({
    goalContainer: {
      cursor: "pointer",
      opacity: isGoalDisabled ? 0.5 : 1,
      transition: "opacity 0.03s",
      border: `1px solid ${isGoalChecked ? DARK_GREEN : COLOR_GREYSCALE[200]}`,
      width: "100%",
      alignItems: "center",
      display: "flex",
      padding: "8px 16px 8px 8px",
      borderRadius: "12px",
    },
    goalText: {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      width: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    goalIcon: {
      marginRight: "12px",
      flexShrink: 0,
    },
    checkbox: {
      height: "20px",
      width: "20px",
      color: COLOR_GREYSCALE[400],
      padding: 0,
    },
  }));

  const classes = useStyles();
  const { name, icon, addAstrixToOnboarding } = GOAL_DATA[goal];
  const handleGoalClick = () => {
    if (checked.includes(goal)) {
      setChecked(checked.filter((g) => g !== goal));
    } else if (!isGoalDisabled) {
      setChecked([...checked, goal]);
    }
  };

  return (
    <div className={classes.goalContainer} onClick={handleGoalClick}>
      {cloneElement(icon, { className: classes.goalIcon })}
      <Typography variant={"body2"} className={classes.goalText}>
        {name}
        {addAstrixToOnboarding && "*"}
      </Typography>
      <Checkbox
        className={classes.checkbox}
        color={"primary"}
        checked={checked.includes(goal)}
        disabled={isGoalDisabled}
      />
    </div>
  );
};
