import { Link, makeStyles, Typography } from "@material-ui/core";
import { COLOR_GREYSCALE, LIGHTER_CHARCOAL } from "../../theme";
import React from "react";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import { HealthierOptionsFoodGroup } from "../../models";
import { useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";

const SimilarProductsText = ({
  className,
  highlightColor = LIGHTER_CHARCOAL,
  foodGroup = "all",
  showHeader = true,
}: {
  className?: string;
  highlightColor?: string;
  foodGroup?: HealthierOptionsFoodGroup;
  showHeader?: boolean;
}) => {
  const history = useHistory();
  const useStyles = makeStyles(({ breakpoints }) => ({
    similarProductsText: {
      color: LIGHTER_CHARCOAL,
      letterSpacing: "0.3px",
      //mobile
      [breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
      //desktop
      [breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      [breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
      },
    },
    similarProductsNumber: {
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: highlightColor,
    },
  }));
  const classes = useStyles();
  const { purchasedItemsWithSwaps } = useHealthierOptions(foodGroup);
  return (
    <Typography
      variant="body2"
      component={"span"}
      className={`${classes.similarProductsText} ${className}`}
    >
      <div>
        You have{" "}
        <span className={classes.similarProductsNumber}>
          {purchasedItemsWithSwaps.length}
        </span>{" "}
        similar products with a higher Health Star Rating
      </div>
      {!showHeader && (
        <Link
          tabIndex={0}
          onKeyPress={(e) =>
            ifEnterOrSpace(e, history.push, "/healthier-options/plp")
          }
          onClick={() => history.push("/healthier-options/plp")}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: COLOR_GREYSCALE[700],
            display: "flex",
            alignItems: "center",
            fontFamily: "Gilroy-Bold",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            gap: 8,
          }}
        >
          View all <ChevronRight />
        </Link>
      )}
    </Typography>
  );
};

export default SimilarProductsText;
