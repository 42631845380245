import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE } from "../../theme";
import { ReactComponent as Success } from "../../icons/success.svg";

const SaveSuccess = () => {
  const classes = makeStyles(() => ({
    container: {
      display: "flex",
      padding: "120px 0",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
    },
    success: {
      margin: "24px 0px 8px",
      fontFamily: "Gilroy-Bold",
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[900],
      textAlign: "center",
    },
    message: {
      margin: 0,
      fontFamily: "Gilroy",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[900],
      textAlign: "center",
      width: "295px",
    },
  }))();
  return (
    <div className={classes.container}>
      <Success />
      <p className={classes.success}>Success!</p>
      <p className={classes.message}>
        The changes have been made successfully!
      </p>
    </div>
  );
};

export default SaveSuccess;
