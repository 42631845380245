import { doc, getDoc } from "firebase/firestore";
import { FeedbackDialogConfiguration } from "../models";
import { Collections, Configurations, db } from "./firebase";

export const getFeedbackConfiguration =
  async (): Promise<FeedbackDialogConfiguration> => {
    const feedbackWidgetRef = doc(
      db,
      Collections.Configurations,
      Configurations.FeedbackDialog
    );
    const feedbackWidgetSnap = await getDoc(feedbackWidgetRef);
    const config = feedbackWidgetSnap.data();
    return {
      enable: {
        value: config?.enable.value,
        timestamp: config?.enable.timestamp,
      },
      display: {
        delay: config?.display.delay,
        bottomOfPage: config?.display.bottomOfPage,
      },
    };
  };
