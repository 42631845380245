import { useContext, useEffect, useState } from "react";
import { MemberContext } from "../components/providers/MemberProvider";
import {
  AggregateDailyNutrients,
  BasketArticle,
  BasketExclusion,
  NutrientsHistory,
  NutrientsTopContributors,
} from "../models";
import { endOfYesterday, getSixMonthsAgoFromDate } from "../utils/dates";
import { SelectedTimeFrameBasketExclusionsContext } from "../components/providers/SelectedTimeFrameBasketExclusionsProvider";
import {
  accumulateExclusionsHistory,
  accumulateNutrientsHistory,
  filterTopContributors,
  getNutrients,
  groupNutrientsByTimePeriods,
  subscribeToRealTimeNutrients,
  subtractExclusionsFromHistory,
} from "../services/nutrients";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import { TimeFrame, TimeFrameDuration } from "../constants";
import { groupExclusionsByTimePeriods } from "../services/exclusions";
import useBasketDetails from "./useBasketDetails";
import { FeatureFlagContext } from "../components/providers/FeatureFlagProvider";
import { Duration } from "luxon";

const until = endOfYesterday();
const NUMBER_OF_HISTORICAL_TIME_PERIODS = 6;

export const TOP_CONTRIBUTORS_CONFIG = {
  MAX_PRODUCTS_TO_SHOW: 10,
  THRESHOLD: {
    ADDED_SUGAR: {
      IS_PER_100G: false,
      VALUE: 5,
    },
    SATURATED_FAT: {
      IS_PER_100G: true,
      VALUE: 3.5,
    },
    SODIUM: {
      IS_PER_100G: true,
      VALUE: 120,
    },
  },
};

const getNutrientsHistory = (
  aggregateDailyNutrients: AggregateDailyNutrients[],
  timePeriodsToShow: Duration,
  exclusions: BasketExclusion[],
  timeFrame: TimeFrame
) => {
  const groupedNutrientsByTimePeriods = groupNutrientsByTimePeriods(
    aggregateDailyNutrients,
    timePeriodsToShow,
    NUMBER_OF_HISTORICAL_TIME_PERIODS
  );
  const groupedExclusionsByTimePeriods = groupExclusionsByTimePeriods(
    exclusions,
    timePeriodsToShow,
    NUMBER_OF_HISTORICAL_TIME_PERIODS
  );

  const nutrientsHistory = groupedNutrientsByTimePeriods.map(
    accumulateNutrientsHistory
  );
  const nutrientsExclusionsHistory = groupedExclusionsByTimePeriods.map(
    accumulateExclusionsHistory
  );
  const nutrientsHistoryWithExclusionsRemoved = subtractExclusionsFromHistory(
    nutrientsHistory,
    nutrientsExclusionsHistory
  );

  if (timeFrame === "Month") {
    return nutrientsHistoryWithExclusionsRemoved
      .slice()
      .reduce((acc, item, i) => {
        item.month = getSixMonthsAgoFromDate().reverse()[i];
        acc.push(item);
        return acc;
      }, [] as NutrientsHistory[]);
  } else {
    return nutrientsHistoryWithExclusionsRemoved;
  }
};

export default function useNutrients() {
  const { memberId } = useContext(MemberContext)! || {};
  const { exclusions } = useContext(SelectedTimeFrameBasketExclusionsContext);
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const { collected, featureFlags } = useContext(FeatureFlagContext);
  const featureFlagsLoading = !collected;

  const {
    allFoodGroupsBasketDetails,
    basketDetailsLoading,
    basketDetailsError,
  } = useBasketDetails("discretionary");

  const [nutrientsHistoryLoading, setNutrientsHistoryLoading] = useState(true);
  const [nutrientsHistory, setNutrientsHistory] = useState<NutrientsHistory[]>(
    Array(NUMBER_OF_HISTORICAL_TIME_PERIODS).fill({
      sugar: {
        innerValue: null,
        totalValue: null,
      },
      salt: {
        innerValue: null,
        totalValue: null,
      },
      fat: {
        innerValue: null,
        totalValue: null,
      },
    })
  );

  const [nutrientsTopContributorsLoading, setNutrientsTopContributorsLoading] =
    useState(true);
  const [nutrientsTopContributors, setNutrientsTopContributors] =
    useState<NutrientsTopContributors | null>(null);

  useEffect(() => {
    if (!memberId || !collected) return;
    if (
      featureFlags?.realTimeData?.enable?.everybody === true ||
      featureFlags?.realTimeData?.enable?.specificMembers?.includes(memberId)
    ) {
      setNutrientsHistoryLoading(true);
      const unsubscribeToRealTimeNutrients = subscribeToRealTimeNutrients(
        memberId,
        timeFrame,
        until,
        (changes) => {
          setNutrientsHistory(
            getNutrientsHistory(
              changes,
              TimeFrameDuration[timeFrame],
              exclusions,
              timeFrame
            )
          );
          setNutrientsHistoryLoading(false);
        }
      );
      return () => {
        unsubscribeToRealTimeNutrients();
      };
    } else {
      const init = async () => {
        setNutrientsHistoryLoading(true);
        const timePeriodsToShow = TimeFrameDuration[timeFrame];
        const aggregateDailyNutrients = await getNutrients(
          memberId,
          timePeriodsToShow.mapUnits(
            (x) => x * NUMBER_OF_HISTORICAL_TIME_PERIODS
          ),
          until
        );
        const history = getNutrientsHistory(
          aggregateDailyNutrients,
          timePeriodsToShow,
          exclusions,
          timeFrame
        );
        setNutrientsHistory(history);
        setNutrientsHistoryLoading(false);
      };

      init();
    }
  }, [
    collected,
    exclusions,
    featureFlags?.realTimeData?.enable?.everybody,
    featureFlags?.realTimeData?.enable?.specificMembers,
    memberId,
    timeFrame,
  ]);

  useEffect(() => {
    if (basketDetailsLoading || featureFlagsLoading) return;
    setNutrientsTopContributorsLoading(true);
    if (allFoodGroupsBasketDetails.length === 0 || basketDetailsError) {
      setNutrientsTopContributors(null);
      setNutrientsTopContributorsLoading(false);
      return;
    }
    const purchasedProducts = allFoodGroupsBasketDetails
      .flatMap((day) => day.articles)
      .filter(
        (product: BasketArticle) =>
          !exclusions.some(
            (exclusion) =>
              exclusion.articleId === product.articleId &&
              exclusion.purchaseDate === product.purchaseDate
          )
      );

    const {
      MAX_PRODUCTS_TO_SHOW,
      THRESHOLD: { SODIUM, ADDED_SUGAR, SATURATED_FAT },
    } = TOP_CONTRIBUTORS_CONFIG;

    const useQuantityAdjustedPackSizeMultiplier =
      featureFlags.cachedMemberBasket?.enable?.everybody === true ||
      featureFlags.cachedMemberBasket?.enable?.specificMembers?.includes(
        memberId
      ) ||
      featureFlags.realTimeData?.enable?.everybody === true ||
      featureFlags.realTimeData?.enable?.specificMembers?.includes(memberId) ||
      false;

    const saltProducts = filterTopContributors(
      purchasedProducts,
      "sodiumPer100g",
      SODIUM.VALUE,
      SODIUM.IS_PER_100G,
      MAX_PRODUCTS_TO_SHOW,
      useQuantityAdjustedPackSizeMultiplier
    );
    const sugarProducts = filterTopContributors(
      purchasedProducts,
      "addedSugarsPer100g",
      ADDED_SUGAR.VALUE,
      ADDED_SUGAR.IS_PER_100G,
      MAX_PRODUCTS_TO_SHOW,
      useQuantityAdjustedPackSizeMultiplier
    );
    const fatProducts = filterTopContributors(
      purchasedProducts,
      "fatSaturatedPer100g",
      SATURATED_FAT.VALUE,
      SATURATED_FAT.IS_PER_100G,
      MAX_PRODUCTS_TO_SHOW,
      useQuantityAdjustedPackSizeMultiplier
    );

    setNutrientsTopContributors({
      salt: saltProducts,
      sugar: sugarProducts,
      fat: fatProducts,
    });
    setNutrientsTopContributorsLoading(false);
    // eslint-disable-next-line
  }, [
    basketDetailsLoading,
    exclusions,
    basketDetailsError,
    allFoodGroupsBasketDetails.length,
    collected,
    featureFlags?.cachedMemberBasket?.enable?.everybody,
    featureFlags?.cachedMemberBasket?.enable?.specificMembers,
    featureFlags?.realTimeData?.enable?.everybody,
    featureFlags?.realTimeData?.enable?.specificMembers,
  ]);

  return {
    nutrientsHistory,
    nutrientsHistoryLoading,
    nutrientsTopContributors,
    nutrientsTopContributorsLoading,
  };
}
