import { Fab, Grid, Typography, makeStyles } from "@material-ui/core";
import { DARK_GREEN, DEFAULT_HEADING_FONT_FAMILY } from "../../theme";
import { FeedbackValues } from "./index";
import { FC } from "react";

interface Props {
  feedbackValues: FeedbackValues;
  onChange: (rating: number) => void;
  inSidebar?: boolean;
}

const RatingButtons: FC<Props> = ({ feedbackValues, onChange, inSidebar }) => {
  const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    ratingButton: {
      width: spacing(3),
      height: spacing(3),
      minHeight: spacing(3),
      color: palette.text.primary,
      backgroundColor: palette.grey[100],
      "&:hover": {
        backgroundColor: inSidebar ? "#458378" : DARK_GREEN,
        color: palette.common.white,
      },
      "&.active": {
        backgroundColor: inSidebar ? "#458378" : DARK_GREEN,
        color: palette.common.white,
      },
      boxShadow: "none",
      [breakpoints.up("sm")]: {
        width: inSidebar ? spacing(3) : spacing(4),
        height: inSidebar ? spacing(3) : spacing(4),
        minHeight: inSidebar ? spacing(3) : spacing(4),
      },
    },
    numberRatingText: {
      fontFamily: DEFAULT_HEADING_FONT_FAMILY,
      [breakpoints.up("sm")]: {
        fontSize: spacing(2),
        lineHeight: "24px",
      },
    },
    selectedRating: {
      backgroundColor: inSidebar ? "#458378" : DARK_GREEN,
      color: palette.common.white,
    },
  }));
  const classes = useStyles();

  return (
    <Grid container justifyContent={"space-between"}>
      {[...Array(11)].map((_e, idx) => (
        <Grid item key={idx}>
          <Fab
            onClick={() => onChange(idx)}
            size="small"
            className={`${classes.ratingButton} ${
              feedbackValues.rating === idx ? classes.selectedRating : ""
            }`}
          >
            <Typography variant="body2" className={classes.numberRatingText}>
              {idx}
            </Typography>
          </Fab>
        </Grid>
      ))}
    </Grid>
  );
};

export default RatingButtons;
