import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import FatalErrorMessage from "./FatalErrorMessage";

const AppErrorFallback: FC<FallbackProps> = ({ error }) => (
  <FatalErrorMessage
    message={`An unexpected error occurred: ${error.message}`}
  />
);

export default AppErrorFallback;
