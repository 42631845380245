import React from "react";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../../../components-2/Accordion";
import {
  InfoModalContent,
  InfoModalSubTitle,
  InfoModalTitle,
} from "../../../components-2/InfoModal";
import { FoodGroup } from "../../../models";
import { makeStyles } from "@material-ui/core";
import useEvents from "../../../hooks/useEvents";
import { FAQ_ACCORDION_EVENT } from "../../../events";

const AboutFoodGroupExplainerContent = ({
  foodGroup,
}: {
  foodGroup: FoodGroup | "sometimes";
}) => {
  const Content = () => {
    switch (foodGroup) {
      case "dairy":
        return <Dairy />;
      case "fruit":
        return <Fruit />;
      case "grains":
        return <Grains />;
      case "vegetables":
        return <Vegetables />;
      case "protein":
        return <Protein />;
      case "sometimes":
        return <Sometimes />;
      default:
        return <NotFound />;
    }
  };
  return <Content />;
};

const NotFound = () => {
  return (
    <>
      <InfoModalContent>Not Found</InfoModalContent>
    </>
  );
};

const Dairy = () => {
  return (
    <>
      <InfoModalTitle>What are Dairy & alternatives?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>Milk can be fresh, dried, evaporated, or UHT (long life).</li>
          <li>
            Cheese includes all hard cheese products (either reduced fat or full
            fat options).
          </li>
          <li>
            Yoghurt includes reduced or full fat, plain and flavoured and soy
            and other alternative yoghurts (calcium fortified).
          </li>
          <li>
            Calcium fortified plant-based milk alternatives, such as almond
            milk. Where possible, choose products fortified with at least 100mg
            calcium per 100mL.
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        Low or reduced fat milk, yoghurt and cheese choices are recommended for
        most people two years and over.
      </InfoModalContent>
      <InfoModalSubTitle>What's not counted:</InfoModalSubTitle>
      <InfoModalContent>
        <ul>
          <li>
            Dairy alternatives that are not fortified, for example almond milk
            without added calcium will not count towards your serves.
          </li>
          <li>
            Butter and cream are not included as they are considered to be
            sometimes foods.
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalTitle>What does a serve look like?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>1 cup (250 ml) of milk</li>
          <li>½ cup (120 ml) evaporated milk</li>
          <li>2 slices or 40g hard cheese, such as cheddar</li>
          <li>½ cup (120 g) ricotta cheese</li>
          <li>¾ cup (200 g) yoghurt</li>
        </ul>
      </InfoModalContent>
    </>
  );
};

const Fruit = () => {
  return (
    <>
      <InfoModalTitle>What’s great about Fruit?</InfoModalTitle>
      <InfoModalContent>
        Like veggies, different coloured fruits provide different nutrients so
        variety is the key. It’s recommended to aim for 2 serves of fruit per
        day. Fresh, frozen, dried, juice (no added sugar) and diced / canned (in
        fruit juice) all count!
      </InfoModalContent>
      <InfoModalTitle>What does a serve look like?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>1 medium apple, banana, orange or pear</li>
          <li>2 small apricots, kiwi fruits or plums</li>
          <li>1 cup diced or canned fruit (no added sugar)</li>
        </ul>
      </InfoModalContent>
      <InfoModalSubTitle>In moderation:</InfoModalSubTitle>
      <InfoModalContent>
        <ul>
          <li>125 ml (½ cup) fruit juice (no added sugar)</li>
          <li>
            30 g dried fruit (for example, 4 dried apricot halves, 1½
            tablespoons of sultanas)
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        <strong>Tip:</strong> Where possible, choose whole fruit over dried
        fruit or fruit juice to help look after your dental health. 
      </InfoModalContent>
    </>
  );
};

const Grains = () => {
  return (
    <>
      <InfoModalTitle>What’s great about Grains?</InfoModalTitle>
      <InfoModalContent>
        As well as being a key source of energy, protein, fibre, vitamins and
        minerals, high fibre grains are also beneficial to our gut health. Most
        people need 4-6 serves of grains per day.
      </InfoModalContent>
      <InfoModalTitle>Which foods are included in Grains?</InfoModalTitle>
      <InfoModalContent>
        Wheat, oats, rice, rye, barley, millet, quinoa and corn are popular
        grain foods. Grains can be broken down into 4 main groups:
      </InfoModalContent>
      <InfoModalContent>
        <ul>
          <li>
            Breads - wholemeal, wholegrain, white, rye, pita, lavash, naan,
            focaccia, crispbreads, damper
          </li>
          <li>
            Breakfast cereals - ready to eat, high fibre (wholegrain) oats,
            porridge, muesli, wholewheat biscuits
          </li>
          <li>
            Grains - rice, barley, corn, polenta, buckwheat, spelt, millet,
            sorghum, triticale, rye, quinoa, semolina
          </li>
          <li>
            Other products - pasta, noodles, English muffin, crumpet, rice
            cakes, couscous, bulgur, popcorn, flour
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        <strong>Tip:</strong> When it comes to grains, it’s best to choose
        wholegrain varieties, as these are more nutrient rich and higher in
        fibre. When searching for wholegrain-based foods, look for “wholegrain”
        or “wholemeal” listed in the ingredients.
      </InfoModalContent>
      <InfoModalTitle>What does a serve look like?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>1 slice (40g) bread</li>
          <li>½ medium (40g) roll or flat bread</li>
          <li>
            ½ cup (75-120g) cooked rice, pasta, noodles, barley, buckwheat,
            semolina, polenta, bulgur or quinoa
          </li>
          <li>½ cup (120g) cooked porridge</li>
          <li>2/3 cup (30g) wheat cereal flakes</li>
          <li>¼ cup (30g) muesli</li>
          <li>3 (35g) crispbreads</li>
          <li>1 (60g) crumpet</li>
          <li>1 small (35g) English muffin or scone</li>
        </ul>
      </InfoModalContent>
    </>
  );
};

const Vegetables = () => {
  return (
    <>
      <InfoModalTitle>What’s great about Vegetables & legumes?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>
            Different coloured veggies provide different types of nutrients so
            change them up to keep things interesting while giving your body a
            great mix of what it needs to thrive! 
          </li>
          <li>
            Eating five or more servings of veggies each day may help with
            maintaining a healthy weight, and help reduce the risk of long-term
            illnesses such as heart conditions and type 2 diabetes.
          </li>
          <li>
            Legumes are the seeds of a plant (think beans and peas, chickpeas,
            lentils and lupins). They count as both veggies and protein in the
            diet and also contain fibre, vitamins and minerals. 
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalTitle>What does a serve look like?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>
            ½ cup cooked green or orange veggies (for example, broccoli,
            spinach, carrots or pumpkin)
          </li>
          <li>½ cup chopped raw or frozen veggies</li>
          <li>
            ½ cup cooked dried or canned beans, peas or lentils (preferably with
            no added salt)
          </li>
          <li>1 cup green leafy or raw salad vegetables</li>
          <li>½ cup sweet corn</li>
          <li>
            ½ medium potato or other starchy vegetables (sweet potato, taro or
            cassava)
          </li>
          <li>1 medium tomato</li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        Fresh, frozen and canned all count towards your Vegetables & legumes
        serves.
      </InfoModalContent>
      <InfoModalContent>
        <strong>Tip:</strong> Legumes are counted in two food groups: Vegetables
        & legumes AND Meat & alternatives. That’s right, two for one!
      </InfoModalContent>
    </>
  );
};

const Protein = () => {
  return (
    <>
      <InfoModalTitle>What are Meat & Alternatives?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>
            These include both animal foods like meat, poultry, fish and eggs as
            well as plant foods like tofu, nuts, seeds, legumes and beans. 
          </li>
          <li>Most people need 2-3 serves of Meat & alternatives per day.</li>
        </ul>
      </InfoModalContent>
      <InfoModalTitle>
        Which foods are included in Meat & alternatives?
      </InfoModalTitle>
      <InfoModalContent>
        These foods can be broken down into six main categories:
      </InfoModalContent>
      <InfoModalContent>
        <ul>
          <li>
            Lean meats - beef, lamb, veal, pork, kangaroo, lean (lower salt)
            sausages
          </li>
          <li>Poultry - chicken, turkey, duck, emu, goose, bush birds</li>
          <li>
            Fish and seafood - fish, prawns, crab, lobster, mussels, oysters,
            scallops, clams
          </li>
          <li>
            Legumes/beans & other - all beans, lentils, chickpeas, split peas,
            tofu, tempeh, textured vegetable protein (TVP)
          </li>
          <li>
            Nuts and seeds - almonds, pine nuts, walnut, macadamia, hazelnut,
            cashew, peanut, nut spreads, pumpkin seeds, sesame seeds, sunflower
            seeds, brazil nuts
          </li>
          <li>Eggs - chicken eggs, duck eggs</li>
        </ul>
      </InfoModalContent>
      <InfoModalTitle>What does a serve look like?</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>
            65g cooked lean red meats such as beef, lamb, veal, pork, goat or
            kangaroo (about 90-100g raw)
          </li>
          <li>80g cooked lean poultry such as chicken or turkey (100g raw)</li>
          <li>
            100g cooked fish fillet (about 115g raw) or one small can of fish
          </li>
          <li>2 large (120g) eggs</li>
          <li>
            1 cup (150g) cooked or canned legumes/beans such as lentils, chick
            peas or split peas (preferably with no added salt)
          </li>
          <li>170g tofu</li>
          <li>
            30g nuts, seeds, peanut or almond butter or tahini or other nut or
            seed paste (no added salt)*
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        <strong>Fun fact:</strong> Legumes are counted in two food groups:
        Vegetables & legumes AND Meat & alternatives. That’s right, two for one!
      </InfoModalContent>
      <InfoModalContent>
        *Keep in mind that while nuts and seeds contain many beneficial
        nutrients and similar energy, they have less protein, iron and zinc than
        animal sources.
      </InfoModalContent>
    </>
  );
};

const Sometimes = () => {
  const { track } = useEvents();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const faqTitles = [
    "One of my items is showing up in sometimes foods. I thought this was healthy?",
    "Are natural and artificial sweeteners healthy?",
  ];
  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, { title: faqTitles[titleIndex] });
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, { title: faqTitles[titleIndex] });
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = makeStyles(() => ({
    accordionHeader: {
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      padding: 0,
      margin: 0,
      minHeight: "auto",
      transition: "all .3s ease-in-out",

      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "auto",
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiIconButton-root": {
        padding: 0,
        marginRight: 0,
      },
    },
    accordionContent: {
      display: "block",
      padding: 0,
      marginTop: "8px",
      "& a": {
        color: "#60A498",
        textDecoration: "underline",
      },
    },
  }))();

  return (
    <>
      <InfoModalTitle>The low-down on Sometimes foods</InfoModalTitle>
      <InfoModalContent>
        <ul>
          <li>
            Like the name, these foods are recommended to have, well, sometimes
            (and in small amounts). This is because they’re usually high in
            energy but low in nutrients the body needs.
          </li>
          <li>
            Sometimes foods typically contain high levels of saturated fat,
            added sugar, salt and/or alcohol. Examples include cakes, biscuits,
            confectionary, chocolate, cream, potato chips, savoury and sweet
            pastries, sauces and sugar sweetened beverages.
          </li>
        </ul>
      </InfoModalContent>
      <InfoModalTitle>Which foods are Sometimes foods?</InfoModalTitle>
      <InfoModalContent>
        These are six categories of sometimes foods:
      </InfoModalContent>
      <InfoModalContent>
        <ul>
          <li>Sweet & savoury foods</li>
          <li>Sugar sweetened drinks</li>
          <li>Sugar substitute drinks</li>
          <li>Fried foods & mixed foods</li>
          <li>Sauces, spreads & miscellaneous</li>
          <li>Processed meat</li>
        </ul>
      </InfoModalContent>
      <InfoModalContent>
        <strong>Expert tip:</strong> Try crowding out sometimes foods and focus
        on the ones you’d like to eat more of. Crowd your basket with foods from
        the five main foods groups: Vegetables & legumes, Fruit, Meat &
        alternatives, Grains, Dairy & alternatives.
      </InfoModalContent>
      <InfoModalSubTitle>
        It’s okay to enjoy sometimes food in moderation.
      </InfoModalSubTitle>
      <InfoModalContent>
        While food provides us with nutrition, we also eat for other reasons
        like enjoyment and social connection. It’s important not to focus on
        just one food or one meal, but on your whole diet. It’s OK to have these
        sometimes foods in small amounts as part of a balanced nutritious diet
        and to enjoy them mindfully.
      </InfoModalContent>
      <InfoModalTitle>FAQs</InfoModalTitle>
      <InfoModalContent>
        <AccordionContainer>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="barchart-content"
              id="barchart-header"
              expandIcon={<AccordionIcon />}
              className={classes.accordionHeader}
            >
              {faqTitles[0]}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Sometimes foods have been determined based on the{" "}
              <a
                href="https://www.eatforhealth.gov.au/guidelines"
                target="_blank"
                rel="noreferrer"
              >
                Australian Health Survey: Users' Guide
              </a>
              , 2011-13 — Discretionary Food List.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="barchart-content"
              id="barchart-header"
              expandIcon={<AccordionIcon />}
              className={classes.accordionHeader}
            >
              {faqTitles[1]}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              Some may opt for sweeteners because they are aiming to reduce
              their sugar intake. But how can we determine which may be the
              better option? Or should sweeteners be avoided?{" "}
              <a
                href="https://www.healthylife.com.au/learn/natural-sweeteners-vs-artificial-sweeteners"
                target="_blank"
                rel="noreferrer"
              >
                Read more from our experts.
              </a>
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
      </InfoModalContent>
    </>
  );
};

export default AboutFoodGroupExplainerContent;
