import { createElement, forwardRef } from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { FoodGroup } from "../../models";
import {
  FoodGroupColorIntake,
  FoodGroupLabel,
  FoodGroupRoundedIconV2,
  TimeFrame,
} from "../../constants";
import { Link, useHistory } from "react-router-dom";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";

interface Props {
  foodGroup: FoodGroup;
  benchmarkServings: number;
  servingsPurchased: number;
  keyIndex: number;
  timeFrame: TimeFrame;
  noServingHousehold?: boolean;
  servingLessFifty?: boolean;
  servingExceeded?: boolean;
}

const BalanceOfShopV2Bar = forwardRef<HTMLDivElement, Props>(
  (
    {
      foodGroup,
      benchmarkServings,
      servingsPurchased,
      keyIndex,
      timeFrame,
      noServingHousehold,
      servingLessFifty,
      servingExceeded,
    },
    ref
  ) => {
    const theme = useTheme();
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
    const history = useHistory();
    const { track } = useEvents();
    const useStyles = makeStyles(({ breakpoints }) => ({
      barGroup: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      },
      bar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "0%",
        maxHeight: servingExceeded ? "calc(150% + 28px)" : "calc(100% + 28px)",
        transition: `height .75s ${(keyIndex + 1) * 0.5}s ease-in-out`,
        width: "24px",
        minHeight: "24px",
        borderRadius: "71.5px",
        justifySelf: "center",
        alignSelf: "flex-end",
        position: "relative",
        marginBottom: "-30px",
        cursor: "pointer",
        "&:hover .tooltip": {
          display: "flex",
        },
        "& svg": {
          marginBottom: "2px",
        },
        [breakpoints.up("md")]: {
          width: "40px",
          minHeight: "40px",
          maxHeight: servingExceeded
            ? "calc(150% + 44px)"
            : "calc(100% + 44px)",
          marginBottom: "-44px",

          "& svg": {
            width: "32px",
            height: "32px",
            marginBottom: "4px",
          },
        },
      },
      servingPercentage: {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        fontFamily: "Gilroy-SemiBold",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.2px",
        top: "-16px",
      },
      foodgroupLink: {
        textDecoration: "none",
        marginTop: "36px",
        position: "absolute",
        top: "100%",
        [breakpoints.up("md")]: {
          marginTop: "50px",
        },
      },
      foodGroupLogoAndText: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        fontFamily: "Gilroy",
        fontSize: "10px",
        lineHeight: "14px",
        letterSpacing: "0.3px",
        textAlign: "center",
        color: "#1D1F20",
        rowGap: "2px",
        [breakpoints.up("md")]: {
          fontSize: "14px",
          lineHeight: "20px",
          minWidth: "132px",
        },
      },
    }));

    const classes = useStyles();
    const multiplier = servingLessFifty ? 2 : 1;
    const height = noServingHousehold
      ? 0
      : Math.ceil((servingsPurchased / benchmarkServings) * 100) * multiplier;

    const handleEvent = () => {
      switch (foodGroup) {
        case "dairy":
          track(HOMEPAGE_EVENT.BALANCE_DAIRY_CLICK);
          return;
        case "vegetables":
          track(HOMEPAGE_EVENT.BALANCE_VEG_CLICK);
          return;
        case "protein":
          track(HOMEPAGE_EVENT.BALANCE_MEAT_CLICK);
          return;
        case "grains":
          track(HOMEPAGE_EVENT.BALANCE_GRAINS_CLICK);
          return;
        case "fruit":
          track(HOMEPAGE_EVENT.BALANCE_FRUIT_CLICK);
          return;
        default:
          return;
      }
    };

    return (
      <div className={classes.barGroup} onClick={() => handleEvent()}>
        <div
          className={classes.bar}
          ref={ref}
          style={{
            height: `
            calc(${height + "%"} + ${
              height > 0
                ? isMdBreakpoint
                  ? "44px"
                  : "28px"
                : isMdBreakpoint
                ? "40px"
                : "24px"
            })
            `,
            backgroundColor: FoodGroupColorIntake[foodGroup],
          }}
          onClick={() => {
            history.push(`/food-group/${foodGroup}`);
          }}
          role="progressbar"
          aria-label={`Your shopping history over the past ${timeFrame} shows you have an estimated ${height}% of your recommended serves of ${FoodGroupLabel[foodGroup]}`}
        >
          {createElement(FoodGroupRoundedIconV2[foodGroup])}
        </div>
        <Link to={`/food-group/${foodGroup}`} className={classes.foodgroupLink}>
          <div className={classes.foodGroupLogoAndText}>
            <span className="food-group-label">
              {FoodGroupLabel[foodGroup] === "Vegetables & legumes"
                ? "Veg & legumes"
                : FoodGroupLabel[foodGroup]}
            </span>
          </div>
        </Link>
      </div>
    );
  }
);

export default BalanceOfShopV2Bar;
