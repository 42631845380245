import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Collections, db, Fields } from "./firebase";
import { BasketArticle, BasketExclusion } from "../models";
import { track } from "./events";
import { DateTime, Duration } from "luxon";
import {
  endOfYesterday,
  generateDateRangesBackFromYesterday,
} from "../utils/dates";
import { getUniqueBasketArticleId } from "./basketdetails";

const getDocRef = (memberId: string, article: BasketArticle) =>
  doc(
    db,
    Collections.Members,
    memberId,
    Collections.BasketExclusions,
    getUniqueBasketArticleId(article)
  );

export const addExclusion = async (
  memberId: string,
  article: BasketArticle
): Promise<BasketExclusion> => {
  const docRef = getDocRef(memberId, article);

  const { ...baseArticle } = article;

  const exclusion = {
    excludedAt: DateTime.utc().toMillis(),
    ...baseArticle,
  };

  await setDoc(docRef, exclusion);

  track(memberId, "Basket Exclusion Added", {
    id: docRef.id,
    articleId: article.articleId,
    purchaseDate: article.purchaseDate,
  });

  return exclusion;
};

export const removeExclusion = async (
  memberId: string,
  article: BasketArticle
) => {
  const docRef = getDocRef(memberId, article);
  await deleteDoc(docRef);
  track(memberId, "Basket Exclusion Removed", { id: docRef.id });
};

export const getBasketExclusions = async (
  memberId: string
): Promise<BasketExclusion[]> => {
  const to = endOfYesterday().endOf("day");
  const from = to.minus(
    Duration.fromObject({ months: 6 }).minus(
      Duration.fromObject({ milliseconds: 1 })
    )
  );

  const q = query(
    collection(db, Collections.Members, memberId, Collections.BasketExclusions),
    where(Fields.BaseBasketDetails.PurchaseDate, ">=", from.toISODate()),
    where(Fields.BaseBasketDetails.PurchaseDate, "<=", to.toISODate())
  );

  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data() as BasketExclusion);
};

export const groupExclusionsByTimePeriods = (
  basketExclusions: BasketExclusion[],
  timePeriod: Duration,
  howMany: number
): BasketExclusion[][] => {
  const dateRanges = generateDateRangesBackFromYesterday(howMany, timePeriod);
  return dateRanges.map((dateRange) =>
    basketExclusions.filter(
      (exclusion) =>
        exclusion.purchaseDate >= dateRange.from.toISODate() &&
        exclusion.purchaseDate <= dateRange.to.toISODate()
    )
  );
};
