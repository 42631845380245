import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { TimeFrame } from "../../constants";

type ConfettiContextState = {
  balanceOfShopConfettiRef: MutableRefObject<HTMLCanvasElement | null> | null;
  fruitAndVegCampaignConfettiRef: MutableRefObject<HTMLCanvasElement | null> | null;
  confettiTriggeredTimeframes: TimeFrame[] | null;
  setConfettiTriggeredTimeframes: Dispatch<SetStateAction<TimeFrame[]>> | null;
  setTriggeredFruitAndVegConfetti: Dispatch<SetStateAction<boolean>> | null;
  triggeredFruitAndVegConfetti: boolean;
};

export const ConfettiContext = createContext<ConfettiContextState>({
  balanceOfShopConfettiRef: null,
  fruitAndVegCampaignConfettiRef: null,
  confettiTriggeredTimeframes: null,
  setConfettiTriggeredTimeframes: null,
  setTriggeredFruitAndVegConfetti: null,
  triggeredFruitAndVegConfetti: false,
});

const ConfettiProvider: FC = ({ children }) => {
  const balanceOfShopConfettiRef = useRef<HTMLCanvasElement | null>(null);
  const fruitAndVegCampaignConfettiRef = useRef<HTMLCanvasElement | null>(null);
  const [triggeredFruitAndVegConfetti, setTriggeredFruitAndVegConfetti] =
    useState(false);
  const [confettiTriggeredTimeframes, setConfettiTriggeredTimeframes] =
    useState<TimeFrame[]>([]);

  return (
    <ConfettiContext.Provider
      value={{
        balanceOfShopConfettiRef,
        fruitAndVegCampaignConfettiRef,
        confettiTriggeredTimeframes,
        setConfettiTriggeredTimeframes,
        setTriggeredFruitAndVegConfetti,
        triggeredFruitAndVegConfetti,
      }}
    >
      {children}
    </ConfettiContext.Provider>
  );
};

export default ConfettiProvider;
