/* eslint-disable jsx-a11y/anchor-is-valid */
import { makeStyles, Typography } from "@material-ui/core";
import { FC } from "react";
import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
  InfoModalSubTitle,
  InfoModalTitle,
} from "../InfoModal";
import { FRUIT_AND_VEG_CHALLENGE_EVENTS } from "../../events";
import { ChevronRight } from "@material-ui/icons";
import { COLOR_GREYSCALE } from "../../theme";
import { ReactComponent as GreenRectangle } from "../../icons/green-rectangle.svg";
import useMember from "../../hooks/useMember";

const FruitAndVegChallengeExplainer: FC = () => {
  const { member } = useMember();
  const classes = makeStyles(() => ({
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
    },
    accordionContent: {
      marginTop: 0,
      "& a": {
        color: "#60A498",
      },
    },
    titleIcon: {
      marginRight: "8px",
    },
    explainerText: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy-SemiBold",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      columnGap: "8px",
    },
    explainerBtn: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      columnGap: "8px",
      width: "100%",
      paddingTop: "12px",
      borderTop: "solid 1px #E9EBEC",
      boxSizing: "border-box",
    },
    text: {
      color: COLOR_GREYSCALE[700],
      "& a": {
        color: "#60A498",
      },
      "& ul, & ol": {
        paddingInlineStart: "16px",
      },
      "& ul li, & ol li,": {
        marginBottom: "12px",
      },
    },
    title: {
      letterSpacing: "-0.8px",
      fontSize: "24px",
      lineHeight: "28px",
      fontFamily: "Gilroy-bold",
      margin: "0",
      color: COLOR_GREYSCALE[900],
      zIndex: 1,
      position: "relative",
    },
    fruitAndVegChallengeTitleContainer: {
      position: "relative",
      display: "inline-block",
      zIndex: -1,
    },
    challengeText: {
      zIndex: 2,
      position: "relative",
    },
    greenRectangle: {
      position: "absolute",
      top: "50%",
      left: "51%",
      transform: "translate(-50%, -50%)",
      width: 115,
      height: 17,
      zIndex: 1,
    },
    bottomImage: {
      display: "block",
      width: "100%",
      maxWidth: "300px",
      margin: "0 auto",
      marginTop: "-16px",
    },
  }))();

  return (
    <InfoModalContainer
      eventName={FRUIT_AND_VEG_CHALLENGE_EVENTS.HELP_OPEN}
      eventCloseName={FRUIT_AND_VEG_CHALLENGE_EVENTS.HELP_CLOSE}
      button={
        member?.eligibleForFruitAndVegMayCampaign ? (
          <InfoModalDefaultButton />
        ) : (
          <div className={classes.explainerBtn}>
            <p className={classes.explainerText}>About this challenge</p>
            <ChevronRight />
          </div>
        )
      }
    >
      <InfoModalContent>
        <h1 className={classes.title}>
          <div className={classes.fruitAndVegChallengeTitleContainer}>
            <GreenRectangle className={classes.greenRectangle} />
            <span className={classes.challengeText}>Fruit &amp; Veg</span>
          </div>{" "}
          Challenge!
        </h1>
      </InfoModalContent>
      <InfoModalTitle>How it works:</InfoModalTitle>
      <InfoModalContent>
        <Typography variant="body1" className={classes.text}>
          <ol
            style={{
              listStyleType: "decimal",
            }}
          >
            <li>Login to Food Tracker and join the Fruit & Veg Challenge.</li>
            <li>
              Shop any product containing a serve of fruit or veg - that's
              anything from apples to frozen peas and even tinned beans.{" "}
              <a
                href="https://www.healthylife.com.au/discover/food-tracker"
                target="_blank"
                rel="noreferrer"
              >
                See more details here
              </a>
              .
            </li>
            <li>Collect 5x points per dollar on any qualifying product.</li>
            <li>Your points will be loaded within 3 days of your shop.</li>
            <li>Check your progress and points in Food Tracker.</li>
          </ol>
        </Typography>
      </InfoModalContent>
      <InfoModalSubTitle>
        What counts as a serve of fruit or veggies?
      </InfoModalSubTitle>
      <InfoModalContent>
        <Typography variant="body1" className={classes.text}>
          <ul>
            <li>
              Fruit - fresh fruit, frozen, dried, fruit juice (no added sugar)
              as well as canned fruit in juice (no added sugar).
            </li>
            <li>
              Vegetables - fresh, frozen, canned vegetables and legumes as well
              as dried legumes.
            </li>
            <li>
              <a
                href="https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/65bdda4c-503a-4a9f-9e32-bdb1198cb096/Fruit%20%26%20Veg%20challenge%20MAY%202%20-%20PDF%20list%20on%20product%20inclusions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                See full list here.
              </a>
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" className={classes.text}>
          *Offer starts: 00:01 AEST 08/05/2024 and ends 23:59 AEST 05/06/2024.{" "}
          <a
            href="https://www.healthylife.com.au/discover/food-tracker"
            target="_blank"
            rel="noreferrer"
          >
            T&C's apply
          </a>
        </Typography>

        <Typography variant="body1" className={classes.text}>
          <a
            href="https://www.healthylife.com.au/discover/eat-more-veggies-sign-up"
            target="_blank"
            rel="noreferrer"
          >
            Explore the amazing health benefits of veggies
          </a>
           and ways to meet your daily serves. From creative recipes and meal
          planning like a pro to encouraging even the fussiest of eaters.
        </Typography>

        <img
          src="https://storage.googleapis.com/food-tracker-images-prod/fruit-veggies-group.png"
          alt="fruits and vegetables"
          className={classes.bottomImage}
        />
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default FruitAndVegChallengeExplainer;
