import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import { COLOR_GREYSCALE } from "../../theme";
import ExternalLink from "../../components/ExternalLink";
import { NutrientTrackerTypes } from "../../models";
import { NUTRIENT_TRACKER_EVENTS } from "../../constants";
import useEvents from "../../hooks/useEvents";
import { NUTRIENT_TRACKER_EVENT } from "../../events";

const useStyles = makeStyles(({ breakpoints }) => ({
  title: {
    fontFamily: "Gilroy-SemiBold",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "0px",
    [breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  },
  overrideAccordionStyle: {
    margin: "0px",
    padding: "0px",
    borderBottom: "none",
  },
}));

const NutrientTrackerLearnMore = ({ type }: NutrientTrackerTypes) => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const { track } = useEvents();

  const handleAccordionChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      track(NUTRIENT_TRACKER_EVENTS.LEARN_MORE_DROP_DOWN_CLICK, { type });
      const trackEvent = newExpanded
        ? NUTRIENT_TRACKER_EVENT.LEARN_MORE_OPEN
        : NUTRIENT_TRACKER_EVENT.LEARN_MORE_CLOSE;
      track(trackEvent, { type });
      setExpanded(newExpanded ? panel : false);
    };

  const classes = useStyles();

  const SugarContent = () => (
    <>
      <ExternalLink
        href="https://www.foodstandards.gov.au/science/monitoringnutrients/ausnut/foodnutrient/Pages/Determining-the-amount-of-added-sugars-and-free-sugars-in-foods-listed-in-the-AUSNUT-201113-dataset.aspx"
        target="_blank"
      >
        Natural sugars are present{" "}
      </ExternalLink>
      in many of the whole foods we eat such as fruit, vegetables, dairy foods
      and grains. The Australian Dietary Guidelines recommend{" "}
      <ExternalLink
        href="https://www.eatforhealth.gov.au/food-essentials/fat-salt-sugars-and-alcohol"
        target="_blank"
      >
        limiting foods{" "}
      </ExternalLink>
      with added sugars, which{" "}
      <ExternalLink
        href="https://www.eatforhealth.gov.au/food-essentials/discretionary-food-and-drink-choices"
        target="_blank"
      >
        are often found in sometimes foods.
      </ExternalLink>
      <Typography
        variant="body2"
        component="p"
        style={{
          marginTop: "20px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Simple tips to reduce added sugar:
      </Typography>
      <Typography component="ul">
        <Typography variant="body2" component="li">
          Crowd your basket with the five main food groups
        </Typography>
        <Typography variant="body2" component="li">
          Look for products with No Added Sugar or those that are low sugar
          (hint: ~4 grams equals roughly one teaspoon of sugar, which is useful
          when looking at the serving size information).
        </Typography>
        <Typography variant="body2" component="li">
          Swap soft drink, cordial and energy drinks for sparkling water. Try
          adding in some fresh ingredients for natural flavouring (our faves
          include cucumber and mint!).
        </Typography>
        <Typography variant="body2" component="li">
          Craving something sweet? Go for naturally sweet alternatives like
          fresh fruit.
        </Typography>
        <Typography variant="body2" component="li">
          Use Healthier Options to find products with a higher Health Star
          Rating.
        </Typography>
        <Typography variant="body2" component="li">
          Use the “low sugar” filter when shopping Woolworths online.
        </Typography>
        <Typography variant="body2" component="li">
          The figures shown in the sugar Nutrient Tracker is total sugar from
          the Nutrition Information Panel, based on available product
          information.
        </Typography>
      </Typography>
      <Typography variant="body1" component="i">
        Added sugar has been calculated using the{" "}
        <ExternalLink
          href="https://www.foodstandards.gov.au/science/monitoringnutrients/ausnut/Pages/default.aspx"
          target="_blank"
        >
          AUSNUT{" "}
        </ExternalLink>
        database which was prepared by CSIRO nutrition researchers.
      </Typography>
    </>
  );

  const SaltContent = () => (
    <>
      <Typography
        variant="body2"
        component="p"
        style={{
          color: COLOR_GREYSCALE[800],
        }}
      >
        Salt (or sodium) is naturally occurring in almost all foods (including
        fruit and veg), but is often added in larger amounts to sometimes foods.
        The Australian Dietary Guidelines recommend limiting foods that are high
        in salt as it is linked with high blood pressure and other health
        conditions.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          marginTop: "20px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Simple tips to reduce salt:
      </Typography>
      <Typography component="ul">
        <Typography variant="body2" component="li">
          Crowd your basket with the five main food groups
        </Typography>
        <Typography variant="body2" component="li">
          Look for products with low salt or No Added Salt.
        </Typography>
        <Typography variant="body2" component="li">
          Swap salty snack foods with crispbread or popcorn.
        </Typography>
        <Typography variant="body2" component="li">
          Use herbs and spices when cooking, this may reduce the requirement for
          extra salt.
        </Typography>
        <Typography variant="body2" component="li">
          Use Healthier Options to find products with a higher Health Star
          Rating.
        </Typography>
        <Typography variant="body2" component="li">
          Use the “low salt” filter when shopping Woolworths online.
        </Typography>
      </Typography>

      <Typography
        variant="body2"
        component="p"
        style={{
          margin: "20px 0px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Important note:
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          color: COLOR_GREYSCALE[800],
        }}
      >
        *The target provides a realistic goal for Australians to reduce overall
        salt intake, based on{" "}
        <ExternalLink
          href="https://www.eatforhealth.gov.au/nutrient-reference-values/nutrients/sodium"
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Suggested Dietary Intake for adults and Adequate Intake for children.
        </ExternalLink>{" "}
        If your basket total is below the target, there is no need to increase
        salt. 
      </Typography>

      <Typography
        variant="body2"
        component="p"
        style={{
          margin: "20px 0px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Disclaimer:
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          color: COLOR_GREYSCALE[800],
        }}
      >
        The figures shown in the salt Nutrient Tracker is the sodium content
        from the Nutrition Information Panel, based on available product
        information.{" "}
        <ExternalLink
          href="https://www.eatforhealth.gov.au/nutrient-reference-values/nutrients/sodium"
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          Total sodium intake
        </ExternalLink>{" "}
        (sodium chloride) consumption but may also be found in food additives in
        very small amounts.
      </Typography>
    </>
  );

  const FatContent = () => (
    <>
      <Typography
        variant="body2"
        component="p"
        style={{
          color: COLOR_GREYSCALE[800],
        }}
      >
        Fat is an important part of a healthy diet. Did you know there are
        different types of fats? Saturated fat is the unhealthy type of fat and
        is found in many sometimes foods. The Australian Dietary Guidelines
        recommend limiting foods that are high in saturated fat as it is linked
        with heart disease and other health conditions.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          marginTop: "20px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Simple tips to reduce fat:
      </Typography>
      <Typography component="ul">
        <Typography variant="body2" component="li">
          Crowd your basket with main foods from the five food groups and
          prioritise healthy fats sources like nuts, seeds, avocado and oily
          fish.
        </Typography>
        <Typography variant="body2" component="li">
          Choose lean cuts of meat.
        </Typography>
        <Typography variant="body2" component="li">
          Swap to healthier oils such as Extra Virgin Olive Oil.
        </Typography>
        <Typography variant="body2" component="li">
          Use Healthier Options to find products with a higher Health Star
          Rating.
        </Typography>
        <Typography variant="body2" component="li">
          Use the “low saturated fat”  filter when shopping Woolworths online.
        </Typography>
      </Typography>

      <Typography
        variant="body2"
        component="p"
        style={{
          margin: "20px 0px",
          fontFamily: "Gilroy-SemiBold",
          fontWeight: "normal",
          color: COLOR_GREYSCALE[800],
        }}
      >
        Disclaimer:
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          color: COLOR_GREYSCALE[800],
        }}
      >
        The figures shown in the fat Nutrient Tracker is total fat and saturated
        fat from the Nutrition Information Panel, based on available product
        information.
      </Typography>
    </>
  );

  return (
    <AccordionContainer
      style={{
        padding: "16px",
        borderTop: `1px solid ${COLOR_GREYSCALE[200]}`,
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<AccordionIcon />}
          className={classes.overrideAccordionStyle}
        >
          <div className={classes.title}>Learn more about {type}</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" component="p" style={{ marginTop: 0 }}>
            {type === "sugar" && <SugarContent />}
            {type === "salt" && <SaltContent />}
            {type === "fat" && <FatContent />}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  );
};

export default NutrientTrackerLearnMore;
