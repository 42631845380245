import React, { cloneElement, useContext, useState } from "react";
import { DietaryPreferencesContext } from "../providers/DietaryPreferencesProvider";
import { makeStyles } from "@material-ui/core";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { DIETARY_PREFERENCES_DATA } from "../../components-2/dietary-preference/config";
import { SettingsContext } from "../providers/SettingsProvider";
import { ReactComponent as Chevron } from "../../icons/chevron-right.svg";
import { DietaryPreferenceDrawer } from "../../components-2/dietary-preference/DietaryPreferenceDrawer";
import { ChevronRight } from "@material-ui/icons";
import BalanceOfShopV2DietaryPreferenceExplainer from "./BalanceOfShopV2DietaryPreferenceExplainer";
import useMember from "../../hooks/useMember";
import useFeatureFlags from "../../hooks/useFeatureFlags";

const BalanceOfShopV2DietaryPreference = () => {
  const { checked } = useContext(DietaryPreferencesContext);
  const { setIsOpenPersonalisation } = useContext(SettingsContext);
  const classes = makeStyles(() => ({
    root: {
      marginTop: "16px",
      padding: checked.length > 0 ? "8px 12px" : "8px",
      borderRadius: "8px",
      border: `solid 1px ${WHITE}`,
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.12)",
      background:
        "linear-gradient(94deg, #F3F3F3 9.44%, #F7F7F7 48.36%, rgba(238, 238, 238, 0.93) 87.28%)",
    },
    textAndIconsContainer: {
      display: "flex",
      flexDirection: "column",
    },
    textAndIcons: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: "8px",
      borderBottom: `dashed 1px ${COLOR_GREYSCALE[200]}`,
      marginBottom: "8px",
    },
    icon: {
      display: "flex",
      columnGap: "8px",
    },
    text: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      maxWidth: "180px",
      margin: 0,
    },
    explainer: {
      display: "flex",
    },
    explainerText: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      columnGap: "8px",
    },
    setPrefButton: {
      border: 0,
      padding: 0,
      backgroundColor: "transparent",
      height: "16px",
      marginLeft: "-3px",
    },
    setPrefText: {
      color: COLOR_GREYSCALE[900],
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      margin: 0,
      minWidth: "275px",
    },
    explainerBtn: {
      display: "flex",
      justifyContent: "space-between",
      columnGap: "8px",
      width: "100%",
    },
  }))();

  const { member } = useMember();
  const [memberId] = useState(member!.memberId);
  const { featureFlags } = useFeatureFlags();
  const showNewDPCards =
    featureFlags.showNewDPCards?.enable?.everybody ||
    featureFlags.showNewDPCards?.enable?.specificMembers?.includes(memberId);

  const renderWithPreferences = () => {
    return (
      <div className={classes.textAndIconsContainer}>
        <div className={classes.textAndIcons}>
          <p className={classes.text}>You have dietary preferences selected</p>
          <div className={classes.icon}>
            {checked.map((ch) => {
              const pref = DIETARY_PREFERENCES_DATA[ch];

              return cloneElement(pref.icon, {
                key: pref.id,
                width: 24,
                height: 24,
              });
            })}
          </div>
        </div>
        {showNewDPCards ? (
          <DietaryPreferenceDrawer
            openButton={
              <div className={classes.explainerBtn}>
                <p className={classes.explainerText}>
                  Find out how this impacts your Food Tracker
                </p>
                <ChevronRight />
              </div>
            }
          />
        ) : (
          <div className={classes.explainer}>
            <BalanceOfShopV2DietaryPreferenceExplainer />
          </div>
        )}
      </div>
    );
  };

  const renderWithoutPreferences = () => {
    return (
      <div className={classes.textAndIconsContainer}>
        <div className={classes.explainer}>
          <div
            className={classes.explainerText}
            onClick={() => setIsOpenPersonalisation("dietary")}
          >
            <p className={classes.setPrefText}>
              Have{" "}
              <strong style={{ fontFamily: "Gilroy-SemiBold" }}>
                dietary preferences
              </strong>
              ? Set them here
            </p>
            <button className={classes.setPrefButton}>
              <Chevron width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {checked.length > 0
        ? renderWithPreferences()
        : renderWithoutPreferences()}
    </div>
  );
};

export default BalanceOfShopV2DietaryPreference;
