import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as InsightsLogo } from "../../icons/file-search.svg";
import { COLOR_GREYSCALE } from "../../theme";
import { HealthyInsightsExplainer } from "./HealthyInsightsExplainer";
import HealthyOptionInsights from "./HealthyOptionInsights";
import NutrientInsights from "./NutrientInsights";
import FoodGroupInsight from "./FoodGroupInsight";
import { useContext, useEffect, useState } from "react";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";
import useNutrients from "../../hooks/useNutrients";
import useDiscretionaryPercentHistory from "../../hooks/useDiscretionaryPercentHistory";
import useHealthierOptions from "../../hooks/useHealthierOptions";
import LoadingAnimation from "../loading-animation/LoadingAnimation";

const HealthyInsights = () => {
  const [nutrientEmpty, setNutrientEmpty] = useState<boolean>(false);
  const [foodGroupEmpty, setFoodGroupEmpty] = useState<boolean>(false);
  const [healthyOptionEmpty, setHealthyOptionEmpty] = useState<boolean>(false);

  const [insightsLoading, setInsightsLoading] = useState<boolean>(true);

  const { timeFrame } = useContext(SelectedTimeFrameContext);

  const { loading: discServesLoading } = useDiscretionaryPercentHistory();
  const { nutrientsHistoryLoading } = useNutrients();
  const { isLoading: healthierOptionsLoading } = useHealthierOptions("all");

  useEffect(() => {
    const isInsightsLoading =
      discServesLoading || nutrientsHistoryLoading || healthierOptionsLoading;
    setInsightsLoading(isInsightsLoading);
  }, [discServesLoading, nutrientsHistoryLoading, healthierOptionsLoading]);

  useEffect(() => {
    setNutrientEmpty(false);
    setFoodGroupEmpty(false);
    setHealthyOptionEmpty(false);
  }, [timeFrame]);

  const classes = makeStyles(({ breakpoints }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      padding: "16px",
      borderRadius: 12,
      border: "none",
      overflow: "hidden",
      minHeight: "420px",
      [breakpoints.up("md")]: {
        minHeight: "0px",
        padding: "24px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
        marginTop: 16,
        boxSizing: "border-box",
        height: "100%",
      },
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D3D6D9",
      [breakpoints.up("md")]: {
        marginBottom: "16px",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px 0 8px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      color: COLOR_GREYSCALE[800],
      [breakpoints.up("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
    icon: {
      height: "30px",
    },
    copy: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[700],
      margin: 0,
      marginBottom: "16px",
      [breakpoints.up("md")]: {
        marginBottom: "24px",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.3px",
      },
    },
    insightsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  }))();
  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.headerTitle}>
        <div className={classes.titleContainer}>
          <InsightsLogo className={classes.icon} />
          <div className={classes.title}>Insights</div>
          <HealthyInsightsExplainer />
        </div>
      </Typography>
      {insightsLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {nutrientEmpty && foodGroupEmpty && healthyOptionEmpty ? (
            <p className={classes.copy}>
              Bummer! We don't have any insights to show you at the moment.
              Check back once you've shopped.
            </p>
          ) : (
            <>
              <p className={classes.copy}>
                This shows your latest trend vs the average of the previous five{" "}
                {timeFrame.toLocaleLowerCase()}s.
              </p>
              <div className={classes.insightsContainer}>
                <NutrientInsights setInsightEmpty={setNutrientEmpty} />
                <HealthyOptionInsights
                  setInsightEmpty={setHealthyOptionEmpty}
                />
                <FoodGroupInsight setInsightEmpty={setFoodGroupEmpty} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HealthyInsights;
