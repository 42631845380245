import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR_GREYSCALE } from "../../theme";
import { Typography } from "@material-ui/core";
import ExternalLink from "../../components/ExternalLink";
import { ReactComponent as Collect500PointsIcon } from "../../icons/collect-500-points-circle-v2.svg";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

export interface BannerProps {
  isHomePage?: boolean;
}

export const ONBOARDING_COMMUNICATION_STEP = "?step=communication";
export const EdrBanner: FC<BannerProps> = ({ isHomePage = false }) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: isHomePage ? "inherit" : "100%",
      marginBottom: isHomePage ? "24px" : "inherit",
      background: isHomePage ? "#FFFFFF" : COLOR_GREYSCALE[50],
      boxShadow: "0px 1px 2px 0px #0000001A",
      borderRadius: "12px",
      padding: "16px",
    },
    orangeTitle: {
      margin: 0,
      color: "#FD6400",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19.6px",
    },
    textAndIconContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: isHomePage ? "8px" : "16px",
    },
    iconContainer: {
      marginLeft: "16px",
      display: "flex",
      alignItems: "center",
    },
    termsAndConditionsText: {
      color: COLOR_GREYSCALE[700],
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      fontFamily: "Gilroy",
      margin: 0,
    },
    listText: {
      margin: 0,
      marginTop: "8px",
      color: COLOR_GREYSCALE[700],
      fontFamily: "Gilroy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      "& > *:not(:first-child)": {
        marginTop: "4px",
      },
    },
    icon: {
      height: isHomePage ? "92px" : "inherit",
      width: isHomePage ? "87px" : "inherit",
    },
    buttonContainer: {
      marginTop: "16px",
    },
    button: {
      borderRadius: "10px",
      textTransform: "none",
      padding: "10px 24px",
      width: "100%",
    },
    hyperlink: {
      color: COLOR_GREYSCALE[700],
    },
  }));
  const history = useHistory();
  const handleSubscribeClick = () => {
    history.push("/onboarding" + ONBOARDING_COMMUNICATION_STEP);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textAndIconContainer}>
        <div>
          <Typography variant="h3" className={classes.orangeTitle}>
            You’re almost there
          </Typography>
          <ul className={classes.listText}>
            <li>
              You’re linked to Food Tracker! Now subscribe to our handy tips.
            </li>
            <li>This will unlock your 500 points.</li>
          </ul>
        </div>
        <div className={classes.iconContainer}>
          <Collect500PointsIcon className={classes.icon} />
        </div>
      </div>
      <div>
        <Typography variant="body1" className={classes.termsAndConditionsText}>
          Offer ends 23:59 AEDT 26/11/23. Points will be rewarded within 7 days
          from the end of the promotional period.{" "}
          <ExternalLink
            className={classes.hyperlink}
            underline={"always"}
            target="_blank"
            aria-label={"Terms and Conditions"}
            href={"https://www.healthylife.com.au/discover/food-tracker"}
          >
            T&Cs apply
          </ExternalLink>
          .
        </Typography>
      </div>
      {isHomePage && (
        <div className={classes.buttonContainer}>
          <Button
            color={"primary"}
            className={classes.button}
            onClick={handleSubscribeClick}
          >
            Subscribe now
          </Button>
        </div>
      )}
    </div>
  );
};
