import { makeStyles, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { FeedbackValues } from "./index";

interface Props {
  feedbackValues: FeedbackValues;
  setFeedbackValues: Dispatch<SetStateAction<FeedbackValues>>;
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  heading: {
    margin: 0,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontFamily: "'Gilroy-Semibold'",
    fontWeight: "normal",
  },
  textArea: {
    width: "100%",
    "& .MuiInputBase-root": {
      marginTop: "8px",
      marginBottom: 0,
    },
  },
}));

const Comment: FC<Props> = ({ feedbackValues, setFeedbackValues }) => {
  const classes = useStyles();

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackValues({ ...feedbackValues, comment: event.target.value });
  };
  return (
    <div>
      <Typography variant="h2" className={classes.heading}>
        How can we improve Food Tracker?
      </Typography>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        className={classes.textArea}
        placeholder="Write your comments here..."
        onChange={handleCommentChange}
      />
    </div>
  );
};

export default Comment;
