import React, { ReactElement, useCallback, useEffect, useState } from "react";
import WelcomePage from "./WelcomePage";
import { Stepper } from "../components-2/onboarding/Stepper";
import { ShoppingAllocationStep } from "../components-2/onboarding/ShoppingAllocationStep";
import { HouseholdSettingStep } from "../components-2/onboarding/HouseholdSettingStep";
import { CommunicationStep } from "../components-2/onboarding/CommunicationStep";
import { useHistory, useLocation } from "react-router-dom";
import { ONBOARDING_COMMUNICATION_STEP } from "../components-2/edr-banner";
import { GoalsStep } from "../components-2/onboarding/GoalsStep";
import { DietaryPreferenceStep } from "../components-2/onboarding/DietaryPreferenceStep";
import useOnboardingStepper from "../hooks/useOnboardingStepper";
import useHideDietaryPreferences from "../hooks/useHideDietaryPreferences";

export interface StepProps {
  StepperComponent: ReactElement;
  step: number;
  handleNext: () => void;
  handleSkip: () => void;
}

export default function OnboardingPage() {
  const { STEP_TITLES } = useOnboardingStepper();
  const hideDP = useHideDietaryPreferences();
  const amountOfSteps = Object.keys(STEP_TITLES).length + 1;
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const getTitleKey = useCallback(
    (searchTitle: string): number => {
      for (let key in STEP_TITLES) {
        if (STEP_TITLES[+key].title === searchTitle) {
          return +key;
        }
      }
      return 0;
    },
    [STEP_TITLES]
  );

  useEffect(() => {
    if (location.search.includes(ONBOARDING_COMMUNICATION_STEP)) {
      setActiveStep(getTitleKey("Communication"));
    }
  }, [location.search, getTitleKey]);

  const handleNext = () => {
    if (activeStep === amountOfSteps - 1) {
      history.push("/");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  function StepWrapper(
    Component: React.FC<StepProps & any>,
    step: number,
    props?: any
  ): ReactElement {
    return (
      <Component
        StepperComponent={
          <Stepper
            activeStep={activeStep}
            amountOfSteps={amountOfSteps}
            handleNext={handleNext}
            handleSkip={handleNext}
          />
        }
        step={step}
        handleNext={handleNext}
        handleSkip={handleNext}
        {...props}
      />
    );
  }

  function getStepContent(step: number): ReactElement {
    switch (step) {
      case 0:
        return StepWrapper(WelcomePage, step);
      case 1:
        return StepWrapper(HouseholdSettingStep, step);
      case 2:
        return !hideDP
          ? StepWrapper(DietaryPreferenceStep, step)
          : StepWrapper(ShoppingAllocationStep, step);
      case 3:
        return !hideDP
          ? StepWrapper(ShoppingAllocationStep, step)
          : StepWrapper(GoalsStep, step);
      case 4:
        return !hideDP
          ? StepWrapper(GoalsStep, step)
          : StepWrapper(CommunicationStep, step);
      case 5:
        return !hideDP ? StepWrapper(CommunicationStep, step) : <></>;
      default:
        return <></>;
    }
  }

  return getStepContent(activeStep);
}
