import React, { FC } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { ReactComponent as BulletPointIcon } from "../icons/bullet-point.svg";

interface Props {
  text?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: theme.spacing(1),
  },
  bulletIcon: {
    margin: theme.spacing(0, 2),
  },
}));

const BulletItem: FC<Props> = ({ text, children, style }) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.item} style={style}>
      <span>
        <BulletPointIcon className={classes.bulletIcon} aria-hidden="true" />
      </span>
      {text}
      {children}
    </Typography>
  );
};

export default BulletItem;
