import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { OnboardingPageLayout } from "./OnboardingPageLayout";

import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CHARCOAL, COLOR_GREYSCALE } from "../../theme";

import useHousehold from "../../hooks/useHousehold";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Button from "@material-ui/core/Button";
import {
  ConsentCheckbox,
  FormErrors,
  FormValues,
  HouseholdAddFormV2,
} from "../../components/ConfigToolbarV2/HouseholdAddFormV2";
import { listAgeGroupOptions } from "../../services/contentful/agegroup";
import useBenchmarks from "../../hooks/useBenchmarks";
import { matchToBenchmark } from "../../services/contentful/benchmarks";
import useEvents from "../../hooks/useEvents";
import { ONBOARDING_HOUSEHOLD_EVENT } from "../../events";

export const HouseholdSettingStep = ({
  StepperComponent,
  step,
}: {
  StepperComponent: ReactElement;
  step: number;
}) => {
  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    title: {
      margin: 0,
      marginBottom: "24px",
      color: CHARCOAL,
      fontSize: "20px",
      lineHeight: "26px",
      letterSpacing: "0.1px",
      fontFamily: "'Gilroy-Bold'",
      fontWeight: "normal",
    },
    button: {
      padding: "8px 20px",
      alignItems: "center",
      borderRadius: "10px",
      "& .MuiButton-endIcon": {
        color: COLOR_GREYSCALE[900],
      },
    },
    buttonText: {
      margin: 0,
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[900],
    },
    alertText: {
      color: "#DE0000",
      marginBottom: spacing(3),
    },
  }));

  const classes = useStyles();
  const { currentBenchmarks } = useBenchmarks();
  const { addToHousehold /*removeFromHousehold*/ } = useHousehold();
  const [consented, setConsented] = useState(false);
  const [householdRequiredError, setHouseholdRequiredError] = useState(false);
  const [forms, setForms] = useState<
    {
      formValues: FormValues;
      formErrors: FormErrors;
    }[]
  >([]);

  const addForm = async (ageGroupType: "Adult" | "Child") => {
    setHouseholdRequiredError(false);
    const ageGroupOptions = await listAgeGroupOptions();
    const selectedAgeGroup = ageGroupOptions.find(
      (ageGroup) => ageGroup.name === ageGroupType
    );
    setForms((prev) => [
      ...prev,
      {
        formValues: {
          ageGroup: selectedAgeGroup,
          healthConditions: [],
          consented: false,
        },
        formErrors: {},
      },
    ]);
  };

  const addAdult = async () => {
    track(ONBOARDING_HOUSEHOLD_EVENT.ADD_ADULT);
    await addForm("Adult");
  };

  const addChild = async () => {
    track(ONBOARDING_HOUSEHOLD_EVENT.ADD_CHILD);
    await addForm("Child");
  };

  const validateSingleForm = (formValues: FormValues): FormErrors => {
    let errors: FormErrors = {};

    if (formValues.healthConditions.length > 0 && !formValues.consented) {
      errors.consent = "Consent required to continue";
    }

    if (!formValues.ageRange) {
      errors.ageRange = "Please select an age range";
    }

    if (!formValues.gender) {
      errors.gender = "Please select a gender";
    }

    return errors;
  };

  const validateForms = (): {
    formValues: FormValues;
    formErrors: FormErrors;
  }[] => {
    return forms.map(({ formValues }) => {
      const errors = validateSingleForm(formValues);
      return {
        formValues,
        formErrors: errors,
      };
    });
  };

  const submitForms = async () => {
    const householdsToAdd = forms.map(({ formValues }) => {
      return {
        benchmark: matchToBenchmark(
          currentBenchmarks,
          formValues.ageRange!,
          formValues.gender!,
          formValues.healthConditions
        ),
        consentCopy: formValues.consentCopy,
      };
    });

    const addToHouseholdPromises = householdsToAdd.map((household) =>
      addToHousehold(household.benchmark, household.consentCopy)
    );

    try {
      await Promise.all(addToHouseholdPromises);
    } catch (error) {
      console.error("Error adding one or more households:", error);
    }
  };
  const { track } = useEvents();
  const handleSaveAndNext = async () => {
    const validationResults = validateForms();
    const hasError = validationResults.some(
      ({ formErrors }) => Object.keys(formErrors).length > 0
    );

    if (hasError) {
      setForms(validationResults);
      track(ONBOARDING_HOUSEHOLD_EVENT.NEXT, {
        hasFormError: true,
      });
      return false;
    } else if (validationResults.length === 0) {
      setHouseholdRequiredError(true);
      track(ONBOARDING_HOUSEHOLD_EVENT.NEXT, {
        emptyHouseholdError: true,
      });
      return false;
    } else {
      track(ONBOARDING_HOUSEHOLD_EVENT.NEXT, {
        success: true,
      });
      await submitForms();
      return true;
    }
  };

  const ageGroupFormNumbers = useMemo(() => {
    let adultCount = 0;
    let childCount = 0;

    return forms.map((form) => {
      if (form.formValues.ageGroup?.name === "Adult") {
        adultCount++;
        return adultCount;
      } else if (form.formValues.ageGroup?.name === "Child") {
        childCount++;
        return childCount;
      }
      return 0;
    });
  }, [forms]);

  const handleConsentChanged = (consented: boolean) => {
    setConsented(consented);
    setForms((forms) => {
      return forms.map((form) => {
        if (form.formValues.healthConditions.length !== 0) {
          if (consented) {
            delete form.formErrors.consent;
          }
          form.formValues.consented = consented;
        }
        return form;
      });
    });
  };

  const { consentLocation, formsRequiringConsentCount } = useMemo(() => {
    let consentLocation = -1;
    let formsRequiringConsentCount = 0;
    for (let i = 0; i < forms.length; i++) {
      if (forms[i].formValues.healthConditions.length > 0) {
        formsRequiringConsentCount++;
        if (formsRequiringConsentCount === 1) {
          consentLocation = i;
        }
      }
    }
    return { consentLocation, formsRequiringConsentCount };
  }, [forms]);

  useEffect(() => {
    handleConsentChanged(consented);
  }, [consented, formsRequiringConsentCount]);

  return (
    <OnboardingPageLayout
      stepper={React.cloneElement(StepperComponent, {
        customHandleNext: handleSaveAndNext,
      })}
      stepNumber={step}
    >
      <Typography variant="h3" component="h2" className={classes.title}>
        Tell us who you shop for
      </Typography>
      {forms.map(({ formValues, formErrors }, idx) => {
        const showDeleteButton = forms.length === 1 ? true : idx !== 0;
        const showConsentCheckbox =
          formsRequiringConsentCount === 1 && idx === consentLocation;
        const showMarginBottom =
          idx + 1 !== forms.length && !showConsentCheckbox;
        return (
          <>
            <HouseholdAddFormV2
              isOnboarding
              style={{
                marginBottom: showMarginBottom ? "24px" : "0px",
              }}
              showDeleteButton={showDeleteButton}
              index={idx}
              key={idx}
              ageGroupFormNumber={ageGroupFormNumbers[idx]}
              formValues={formValues}
              formErrors={formErrors}
              onFormDelete={() => {
                track(ONBOARDING_HOUSEHOLD_EVENT.REMOVE);
                const newForms = [...forms];
                newForms.splice(idx, 1);
                setForms(newForms);
              }}
              onFormValuesChange={(newValues) => {
                const newForms = [...forms];
                newForms[idx].formValues = newValues;
                if (Object.keys(formErrors).length > 0) {
                  newForms[idx].formErrors = validateSingleForm(newValues);
                }
                setForms(newForms);
              }}
            />
            {showConsentCheckbox && (
              <ConsentCheckbox
                onConsentChange={handleConsentChanged}
                consentValue={consented}
                consentError={formErrors.consent}
              />
            )}
          </>
        );
      })}
      {householdRequiredError && (
        <Typography variant="caption" className={classes.alertText}>
          You need to add at least one person for your Food Tracker to be
          accurate.
        </Typography>
      )}
      <div
        style={{
          marginTop:
            forms.length > 0 &&
            (formsRequiringConsentCount !== 1 ||
              (forms.length > 1 && consentLocation + 1 !== forms.length))
              ? "40px"
              : "0px",
          marginBottom: "32px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          className={classes.button}
          endIcon={<AddRoundedIcon />}
          onClick={addAdult}
          style={{
            marginRight: "24px",
          }}
        >
          <Typography className={classes.buttonText}>ADD ADULT</Typography>
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={addChild}
          endIcon={<AddRoundedIcon />}
        >
          <Typography className={classes.buttonText}>ADD CHILD</Typography>
        </Button>
      </div>
      {formsRequiringConsentCount > 1 && (
        <ConsentCheckbox
          onConsentChange={handleConsentChanged}
          consentValue={consented}
          consentError={
            forms.filter((form) => form.formErrors.consent !== undefined)[0]
              ?.formErrors?.consent
          }
        />
      )}
    </OnboardingPageLayout>
  );
};
