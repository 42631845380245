import {
  cloneElement,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useMember from "../../hooks/useMember";
import {
  DIETARY_PREFERENCES,
  DIETARY_PREFERENCES_DATA,
} from "../../components-2/dietary-preference/config";
import { BasketArticle, HealthierOption } from "../../models";
import useHideDietaryPreferences from "../../hooks/useHideDietaryPreferences";

interface Props {}

interface DietaryPreferencesContextState {
  checked: DIETARY_PREFERENCES[];
  setChecked: Dispatch<SetStateAction<DIETARY_PREFERENCES[]>>;
  cancelledPref: DIETARY_PREFERENCES[];
  setCancelledPref: Dispatch<SetStateAction<DIETARY_PREFERENCES[]>>;
  getHealthierOptionDietaryPreferencesIcons: (
    healthierOption: BasketArticle | HealthierOption
  ) => ReactNode[] | null;
}

export const DietaryPreferencesContext =
  createContext<DietaryPreferencesContextState>({
    checked: [],
    setChecked: () => {},
    cancelledPref: [],
    setCancelledPref: () => {},
    getHealthierOptionDietaryPreferencesIcons: () => [],
  });

const DietaryPreferencesProvider: FC<Props> = ({ children }) => {
  const { member } = useMember();
  const hideDP = useHideDietaryPreferences();
  const [checked, setChecked] = useState<DIETARY_PREFERENCES[]>([]);
  const [cancelledPref, setCancelledPref] = useState<DIETARY_PREFERENCES[]>([]);

  useEffect(() => {
    setChecked(member?.dietaryPreferences || []);
  }, [member?.dietaryPreferences]);

  useEffect(() => {
    const handleCancelledPreferences = () => {
      const disabledPreferences = checked
        .map((preference) => DIETARY_PREFERENCES_DATA[preference].cancels)
        .flat();
      setCancelledPref(disabledPreferences || []);
    };

    handleCancelledPreferences();
  }, [checked]);

  const getHealthierOptionDietaryPreferencesIcons = (
    healthierOption: BasketArticle | HealthierOption
  ) => {
    if (!hideDP && healthierOption && healthierOption.dietaryPreferences) {
      const preferences = Object.keys(healthierOption.dietaryPreferences)
        .filter(
          (df) =>
            healthierOption.dietaryPreferences[
              df as keyof typeof healthierOption.dietaryPreferences
            ]
        )
        .map((df) => {
          let pref = "vegan";
          switch (df) {
            case "vegan":
              pref = "vegan";
              break;
            case "vegetarian":
              pref = "vegetarian";
              break;
            case "lactoseFree":
              pref = "lactose_free";
              break;
            case "dairyFree":
              pref = "dairy_free";
              break;
            case "glutenFree":
              pref = "gluten_free";
              break;
          }

          return pref;
        });

      const cancelledPreferences = preferences
        .map(
          (pref) =>
            DIETARY_PREFERENCES_DATA[
              pref as keyof typeof DIETARY_PREFERENCES_DATA
            ].cancels
        )
        .flat()
        .filter((val, index, arr) => arr.indexOf(val) === index);

      const availablePreferences = preferences.filter(
        (pref) => !cancelledPreferences.includes(pref as DIETARY_PREFERENCES)
      );

      return availablePreferences.sort().map((pref) =>
        cloneElement(
          DIETARY_PREFERENCES_DATA[pref as DIETARY_PREFERENCES].icon,
          {
            width: 21,
            height: 21,
          }
        )
      );
    }

    return null;
  };

  const value = {
    checked,
    setChecked,
    cancelledPref,
    setCancelledPref,
    getHealthierOptionDietaryPreferencesIcons,
  };
  return (
    <DietaryPreferencesContext.Provider value={value}>
      {children}
    </DietaryPreferencesContext.Provider>
  );
};

export default DietaryPreferencesProvider;
