import { Typography, makeStyles } from "@material-ui/core";
import { FoodGroup } from "../../../models";
import { ReactComponent as Bulb } from "../../../icons/lightbulb.svg";
import { ReactComponent as Alert } from "../../../icons/alert-circle.svg";
import { COLOR_GREYSCALE, LEAFY_GREEN } from "../../../theme";
import RecommendedServesExplainer from "./RecommendedServesExplainer";
import {
  FoodGroupColorIntake,
  FoodGroupLabel,
  FoodGroupRoundedIconV2,
  TimeFrameDuration,
} from "../../../constants";
import { createElement, useContext, useEffect, useState } from "react";
import { SelectedTimeFrameContext } from "../../SelectedTimeFrameProvider";
import useHouseholdBenchmarkServings from "../../../hooks/useHouseholdBenchmarkServings";
import useServingsUntilYesterday from "../../../hooks/useServingsUntilYesterday";
import RecommendedServesVerticalBar from "./RecommendedServesVerticalBar";

const RecommendedServes = ({
  foodGroup,
  showHeader = true,
}: {
  foodGroup: FoodGroup;
  showHeader?: boolean;
}) => {
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const timeFrameDuration = TimeFrameDuration[timeFrame];
  const { servingsPurchased } = useServingsUntilYesterday(timeFrameDuration);
  const empty = servingsPurchased[foodGroup] === 0;
  const useStyles = makeStyles(() => ({
    header: {
      display: "flex",
      alignItems: "center",
      margin: 0,
      marginBottom: 10,
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      paddingBottom: 10,
      borderBottom: `solid 1px ${COLOR_GREYSCALE[200]}`,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      borderRadius: 40,
      marginRight: 8,
      "& svg": {
        width: 24,
        height: 24,
      },
    },
    description: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: COLOR_GREYSCALE[700],
      marginTop: 0,
      marginBottom: empty ? "24px" : "44px",
    },
    insights: {
      display: "flex",
      position: "relative",
      overflow: "hidden",
      padding: "12px",
      paddingLeft: "16px",
      borderRadius: "8px",
      gap: "10px",
      marginTop: "36px",
      backgroundColor: COLOR_GREYSCALE[50],
      "&::before": {
        content: `" "`,
        width: "8px",
        left: 0,
        top: 0,
        position: "absolute",
        display: "block",
        height: "100%",
        backgroundColor: FoodGroupColorIntake[foodGroup],
      },
    },
    insightsIcon: {
      "& path": {
        fill: FoodGroupColorIntake[foodGroup],
      },
    },
    alertsIcon: {
      "& path": {
        stroke: FoodGroupColorIntake[foodGroup],
      },
    },
    belowBenchmark: {
      fontFamily: "Gilroy",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: "#1D1F20",
    },
    insightDescription: {
      letterSpacing: "0.3px",
      color: "#1D1F20",
      margin: 0,
      marginBottom: "12px",
      fontSize: "14px",
      lineHeight: "20px",
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
    highlight: {
      color:
        foodGroup === "vegetables"
          ? LEAFY_GREEN[500]
          : FoodGroupColorIntake[foodGroup],
      fontFamily: "Gilroy-Bold",
      fontWeight: "normal",
    },
  }));
  const classes = useStyles();

  const householdBenchmarkServings = useHouseholdBenchmarkServings(
    TimeFrameDuration[timeFrame]
  );

  const [percent, setPercent] = useState<number | null>(null);

  useEffect(() => {
    if (servingsPurchased) {
      const fraction =
        servingsPurchased[foodGroup] / householdBenchmarkServings[foodGroup];
      const percentage =
        isNaN(fraction) || !isFinite(fraction) ? 0 : Math.ceil(fraction * 100);
      setPercent(percentage);
    }
  }, [foodGroup, householdBenchmarkServings, servingsPurchased]);

  const aboveRecommended = (percent ?? 0) >= 100;

  const insightBelowRecommendedMessage: { [key in FoodGroup]: string } = {
    dairy: `It’s time to get moo-ving. Try our simple tips below to help you hit your goals.`,
    fruit: `Time to up your fruit! Try our simple tips below to help you hit your goals.`,
    grains: `Get the gains from grains. Try our simple tips below to help you hit your goals.`,
    vegetables: `It's time to veg up! Try our simple tips below to help you hit your goals.`,
    protein: `Struggling to "meat" your serves? Try our simple tips below to help you hit your goals.`,
  };

  const insightAboveRecommendedMessage: { [key in FoodGroup]: string } = {
    dairy: `Dairy good! Keep up your winning streak with the help of our simple tips below.`,
    fruit: `Berry good! Keep up your winning streak with the help of our simple tips below.`,
    grains: `You're on a (bread) roll. Keep up your winning streak with the help of our simple tips below.`,
    vegetables: `You’re kale-ing it! Keep up your winning streak with the help of our simple tips below.`,
    protein: `You're putting the "pro" in protein. Keep up your winning streak with the help of our simple tips below.`,
  };

  return (
    <>
      {showHeader && (
        <Typography variant="h2" component="h1" className={classes.header}>
          <span className={classes.icon}>
            {createElement(FoodGroupRoundedIconV2[foodGroup])}
          </span>
          <span style={{ display: "inline-block", marginRight: 4 }}>
            Recommended serves
          </span>
          <RecommendedServesExplainer foodGroup={foodGroup} />
        </Typography>
      )}
      <p className={classes.description}>
        Your purchased {FoodGroupLabel[foodGroup]} serves compared to the
        recommended amount.
      </p>
      <RecommendedServesVerticalBar
        foodGroup={foodGroup}
        percent={percent}
        empty={empty}
      />
      <div className={classes.insights}>
        {empty ? (
          <Alert className={classes.alertsIcon} style={{ minWidth: 24 }} />
        ) : (
          <Bulb className={classes.insightsIcon} style={{ minWidth: 24 }} />
        )}
        <div className={classes.belowBenchmark}>
          {empty ? (
            "Bummer! We don’t have anything to show you at the moment. Check back once you’ve shopped."
          ) : (
            <>
              {aboveRecommended ? (
                <p className={classes.insightDescription}>
                  You're above your recommended serves.
                </p>
              ) : (
                <p className={classes.insightDescription}>
                  You’re{" "}
                  <span className={classes.highlight}>
                    {100 - (percent ?? 0)}%
                  </span>{" "}
                  behind on recommended serves.
                </p>
              )}
              <p className={classes.insightDescription}>
                {aboveRecommended
                  ? insightAboveRecommendedMessage[foodGroup]
                  : insightBelowRecommendedMessage[foodGroup]}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RecommendedServes;
