import React, { FC } from "react";
import { ButtonProps } from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrimaryIconButton from "./PrimaryIconButton";

const PrimaryArrowButton: FC<ButtonProps & { loading?: boolean }> = ({
  children,
  loading,
  ...props
}) => (
  <PrimaryIconButton loading={loading} icon={ArrowForwardIcon} {...props}>
    {children}
  </PrimaryIconButton>
);

export default PrimaryArrowButton;
