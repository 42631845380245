import { useContext } from "react";
import { SelectedTimeFrameContext } from "../components/SelectedTimeFrameProvider";
import confetti from "canvas-confetti";
import { ConfettiContext } from "../components/providers/ConfettiProvider";

export default function useConfetti() {
  const { timeFrame } = useContext(SelectedTimeFrameContext);

  const {
    balanceOfShopConfettiRef,
    fruitAndVegCampaignConfettiRef,
    confettiTriggeredTimeframes,
    setConfettiTriggeredTimeframes,
    triggeredFruitAndVegConfetti,
    setTriggeredFruitAndVegConfetti,
  } = useContext(ConfettiContext);

  const attemptFruitAndVegCampaignConfetti = () => {
    if (
      !fruitAndVegCampaignConfettiRef?.current ||
      !setTriggeredFruitAndVegConfetti ||
      triggeredFruitAndVegConfetti
    ) {
      return;
    }
    setTriggeredFruitAndVegConfetti(true);
    const myConfetti = confetti.create(fruitAndVegCampaignConfettiRef.current, {
      resize: true,
      useWorker: true,
    });

    setTimeout(() => {
      myConfetti({
        particleCount: 300,
        spread: 170,
        origin: { y: 0 },
      });
    }, 100);
  };

  const attemptBalanceOfShopConfetti = () => {
    if (
      !confettiTriggeredTimeframes ||
      !setConfettiTriggeredTimeframes ||
      !balanceOfShopConfettiRef?.current ||
      confettiTriggeredTimeframes.includes(timeFrame)
    ) {
      return;
    }

    setConfettiTriggeredTimeframes((prev) => [...prev, timeFrame]);

    const myConfetti = confetti.create(balanceOfShopConfettiRef.current, {
      resize: true,
      useWorker: true,
    });

    setTimeout(() => {
      myConfetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }, 100);
  };

  return {
    attemptBalanceOfShopConfetti,
    attemptFruitAndVegCampaignConfetti,
  };
}
