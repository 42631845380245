import React, { FC, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DARK_GREEN } from "../../theme";
import { getHomePageTitle, HomePageHeader } from "./HomePageHeader";
import { OtherPageHeader } from "./OtherPageHeader";
import { OnboardingPageHeader } from "./OnboardingPageHeader";
import Sidebar from "../sidebar-settings/Sidebar";
import Footer from "./Footer";
import { FoodGroup } from "../../models";
import FloatingBackButton from "../../components/FloatingBackButton";
import { useMediaQuery } from "@material-ui/core";

interface BasePageLayoutProps {
  /** Page content to be rendered below the header. */
  children: React.ReactNode;
}

interface HomePageLayoutProps extends BasePageLayoutProps {
  isHomePage: true;
  isOnboarding?: false;

  /** Callback function to be called when the menu button is clicked. */
  onMenuClick?: () => void;

  /** The users name to display in the header. */
  usersName?: string | null;
}

interface OnboardingLayoutProps extends BasePageLayoutProps {
  isOnboarding: true;
  title: string;
  stepNumber: number;
  isHomePage?: false;
}

interface OtherPageLayoutProps extends BasePageLayoutProps {
  isHomePage?: false;
  isOnboarding?: false;
  isFoodGroupPage?: boolean;

  /** The title to be displayed in the header. */
  title: string;

  /** The background color for the circle. Defaults to DARK_GREEN. */
  circleColor?: string;

  /** Callback function to be called when the back button is clicked. */
  onBackClick?: () => void;

  /** Callback function to be called when the menu button is clicked. */
  onMenuClick?: () => void;

  /** Flag to determine if the menu button is hidden. Defaults to false. */
  hideMenuButton?: boolean;

  /** Flag to determine if the back button is hidden. Defaults to false. */
  hideBackButton?: boolean;

  /** Heading text color for accessibility */
  headingTextColor?: string;

  /** Back button color for accessibility */
  backButtonColor?: string;

  /** Menu button color for accessibility */
  menuButtonColor?: string;

  /** Determine food group */
  foodGroup?: FoodGroup | "discretionary";
}

type PageLayoutProps =
  | HomePageLayoutProps
  | OtherPageLayoutProps
  | OnboardingLayoutProps;

/**
 * A layout component to structure pages with a consistent header and background.
 */
export const PageLayout: FC<PageLayoutProps> = (props) => {
  const { children, isHomePage, isOnboarding } = props;
  const title = isHomePage ? getHomePageTitle() : props.title;
  const usersName = isHomePage ? props.usersName : "";
  const circleColor =
    isHomePage || isOnboarding ? DARK_GREEN : props.circleColor ?? DARK_GREEN;
  const hideBackButton =
    isHomePage || isOnboarding ? true : props.hideBackButton ?? false;
  const hideMenuButton =
    isHomePage || isOnboarding ? true : props.hideMenuButton ?? false;
  const isFoodGroupPage =
    !(isHomePage || isOnboarding) && props.isFoodGroupPage;
  const onBackClick =
    isHomePage || isOnboarding ? undefined : props.onBackClick;
  const stepNumber = isOnboarding ? props.stepNumber : undefined;
  const onMenuClick = isOnboarding ? undefined : props.onMenuClick;
  // const theme = useTheme();

  const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
      position: "relative",
      height: "fit-content",
      [breakpoints.down("sm")]: {
        ...(isOnboarding && {
          minWidth: "375px",
          background: "#F3E9D3",
        }),
        //When the widget is built and mounted, scrolling breaks without the below.
        //It is sort of a hack, can revisit after Tracker 2.0 is built.
        overflowY:
          process.env.NODE_ENV === "development" ? "hidden" : "visible",
        overflowX: "hidden",
      },
    },
    greenCircle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      overflow: "hidden",
      backgroundColor: circleColor,
      //mobile
      [breakpoints.down("sm")]: {
        top: isFoodGroupPage ? "-400px" : "-410px",
        height: isHomePage ? "1200px" : "1100px",
        width: "1200px",
      },
      [breakpoints.only("xs")]: {
        borderRadius: "50%",
      },
      [breakpoints.only("sm")]: {
        borderRadius: "40%",
      },
      //desktop
      [breakpoints.up("md")]: {
        top: 0,
        height: isHomePage ? "240px" : isFoodGroupPage ? "204px" : "500px",
        transform: "translateX(-50%)",
        width: "100vw",
      },
    },
    contentWrapper: {
      height: "100%",
      position: "relative",
      zIndex: 2,
      padding: `0px ${spacing(1)}px`,
      [breakpoints.up(375)]: {
        padding: `0px ${spacing(2)}px`,
      },
      [breakpoints.up("sm")]: {
        padding: `0px ${spacing(3)}px`,
      },
      ...(isOnboarding && {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "100vh",
      }),
    },
    childrenWrapper: {
      position: "relative",
      zIndex: 3,
      ...(isOnboarding && {
        height: "100%",
        flexGrow: 1,
        display: "contents",
      }),
    },

    bottomCircle: {
      backgroundColor: circleColor,
      width: 1600,
      height: 1600,
      borderRadius: "100%",
      position: "absolute",
      top: "calc(100% - 336px)",
      left: "50%",
      transform: "translateX(-50%)",
      overflow: "hidden",
      display: "none",
      [breakpoints.only("sm")]: {
        borderRadius: "40%",
      },
      [breakpoints.up("md")]: {
        display: "block",
        borderRadius: 0,
        top: "auto",
        bottom: 0,
        height: "197px",
        transform: "translateX(-50%)",
        width: "100vw",
      },
    },
  }));

  const contentWrapperRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Sidebar />
      <div aria-hidden className={classes.greenCircle} />
      <div
        role={"main"}
        style={{
          overflow: "visible",
        }}
      >
        <div ref={contentWrapperRef} className={classes.contentWrapper}>
          {isHomePage ? (
            <HomePageHeader
              title={title}
              usersName={usersName}
              onMenuClick={onMenuClick}
            />
          ) : isOnboarding ? (
            <OnboardingPageHeader title={title} stepNumber={stepNumber} />
          ) : (
            <OtherPageHeader
              title={title}
              onBackClick={onBackClick}
              onMenuClick={onMenuClick}
              hideMenuButton={hideMenuButton}
              hideBackButton={hideBackButton}
              headingTextColor={props.headingTextColor}
              backButtonColor={props.backButtonColor}
              menuButtonColor={props.menuButtonColor}
            />
          )}
          <div className={classes.childrenWrapper}>{children}</div>
          {!isHomePage && !isOnboarding && isMobile && <FloatingBackButton />}
        </div>
      </div>
      <div aria-hidden className={classes.bottomCircle} />
      <Footer
        circleColor={circleColor}
        foodGroup={isFoodGroupPage ? props.foodGroup! : "main"}
      />
    </div>
  );
};
