import { ContentfulRichContent } from "../../models";
import { MAIN_PAGE_CONTENT } from "./hardcodedContentfulContent";

export interface MainPageContent {
  barContent: ContentfulRichContent;
  dialContent: ContentfulRichContent;
  discretionaryContent: ContentfulRichContent;
}

export const fetchMainPageContent = async (): Promise<MainPageContent> => {
  // Used to fetched from Contentful, now hard coded.
  //@ts-ignore
  return MAIN_PAGE_CONTENT.data.mainPageCollection.items[0];
};
