import { track } from "./events";
import { signInWithCustomToken } from "./firebase";
import { PROXIED_CLOUD_FUNCTIONS_URL } from "../constants";


class HttpError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export const getImpersonationJwt = async (
  memberId: string,
  idToken?: string
): Promise<string> => {
  const response = await fetch(
    `${PROXIED_CLOUD_FUNCTIONS_URL}/auth/issue-impersonation-jwt/${memberId}`,
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );

  const data = await response.json();
  return data.token;
};

export const exchangeJwtForCustomAuthToken = async (
  jwt: string
): Promise<string> => {
  const response = await fetch(`${PROXIED_CLOUD_FUNCTIONS_URL}/auth/custom-token`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token: jwt }),
  });

  if (response.status !== 200) {
    if (response.status === 401) {
      throw new HttpError(401, "Invalid token");
    }
    throw new HttpError(
      response.status,
      "Unexpected error occurred while exchanging token"
    );
  }

  const data = await response.json();

  return data.token;
};

export const signIn = async (customAuthToken: string) => {
  try {
    const { user } = await signInWithCustomToken(customAuthToken);
    await track(user.uid, "Member SignedIn");
    return user;
  } catch (error: any) {
    throw new HttpError(401, error.message);
  }
};
