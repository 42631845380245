import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton } from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import TimeFrameTabs from "../../components/TimeFrameTabs";
import { SettingsContext } from "../../components/providers/SettingsProvider";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";
import { ReactComponent as FoodTrackerLogo } from "../../icons/ft-logo.svg";
import { isEdrApp, isWowApp } from "../../utils/misc";

const headerStyles = makeStyles(({ breakpoints }) => ({
  root: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "29px 4px 0px 4px",
    [breakpoints.up("md")]: {
      alignItems: "center",
      height: "95px",
    },
  },
  icon: {
    color: "white",
    height: "24px",
    width: "24px",
  },
  iconButton: {
    height: "42px",
    width: "42px",
    marginBottom: "auto",
  },
  iconWhiteBackground: {
    background: "rgb(255 255 255 / 50%)",
    "&:hover": {
      background: "rgb(255 255 255 / 25%)",
    },
  },
  logoAndGreeting: {
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    flex: 1,
    width: "190px",
    height: "64px",
  },
  logoMobile: {
    display: "flex",
    width: "143px",
    height: "48px",
    marginBottom: "32px",
    [breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoDesktopContainer: {
    display: "none",
    [breakpoints.up("md")]: {
      display: "flex",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
}));

export const StickyHomePageHeader = (props: {
  circleColor: string;
  onMenuClick?: () => void;
}) => {
  const { circleColor, onMenuClick } = props;
  const { isOpenSidebar, setIsOpenSidebar } = useContext(SettingsContext);
  const classes = headerStyles();
  const classes2 = makeStyles(({ spacing, breakpoints }) => ({
    stickyHeaderContainer: {
      position: "fixed",
      backgroundColor: circleColor,
      width: `100%`,
      padding: "29px 0px",
    },
    stickyHeader: {
      width: "100%",
      position: "absolute",
      top: 0,
      zIndex: 10,
    },
  }));
  const stickyClasses = classes2();

  const defaultOnMenuClick = () => setIsOpenSidebar(!isOpenSidebar);

  return (
    <div className={stickyClasses.stickyHeader} {...props}>
      <div className={stickyClasses.stickyHeaderContainer}>
        <div
          style={{
            padding: "0px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <TimeFrameTabs
              style={{ marginTop: 0 }}
              textColorMobile="#fff"
              sticky
            />
          </div>
          <div>
            <IconButton
              className={`${classes.iconWhiteBackground} ${classes.iconButton}`}
              onClick={onMenuClick ? onMenuClick : defaultOnMenuClick}
            >
              <MenuRoundedIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const HomePageHeader = ({
  title,
  onMenuClick,
  usersName,
}: {
  title: string;
  usersName: string | undefined | null;
  onMenuClick?: () => void;
}) => {
  const { track } = useEvents();
  const { isOpenSidebar, setIsOpenSidebar } = useContext(SettingsContext);
  const defaultOnMenuClick = () => {
    track(HOMEPAGE_EVENT.MENU_OPEN);
    setIsOpenSidebar(!isOpenSidebar);
  };
  const classes = headerStyles();

  const isEDRorWOW = isEdrApp() || isWowApp();

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <div className={classes.logoAndGreeting}>
          {!isEDRorWOW && <FoodTrackerLogo className={classes.logoMobile} />}

          <Typography
            style={{
              color: "#F6EFDF",
              fontSize: "22px",
              lineHeight: "28px",
              fontFamily: '"Gilroy-Bold"',
              fontWeight: "normal",
              margin: 0,
            }}
            component={"h1"}
          >
            {title}
            {usersName && (
              <span
                style={{
                  fontFamily: "Gilroy",
                }}
              >
                {`, ${usersName}`}
              </span>
            )}
          </Typography>
        </div>
      </Grid>
      {!isEDRorWOW && (
        <Grid item className={classes.logoDesktopContainer}>
          <FoodTrackerLogo className={classes.logo} />
        </Grid>
      )}

      <Grid item>
        <IconButton
          className={`${classes.iconWhiteBackground} ${classes.iconButton}`}
          onClick={onMenuClick ? onMenuClick : defaultOnMenuClick}
          aria-label="Menu"
        >
          <MenuRoundedIcon className={classes.icon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const getHomePageTitle = () => {
  const date = new Date();
  const hours = date.getHours();

  let greeting: string;

  if (hours >= 5 && hours <= 11) {
    greeting = "Morning";
  } else if (hours >= 12 && hours <= 17) {
    greeting = "Afternoon";
  } else {
    greeting = "Evening";
  }

  return greeting;
};
