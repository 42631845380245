import useMember from "../../hooks/useMember";
import { FruitAndVegChallengeIntro } from "./FruitAndVegChallengeIntro";
import FruitAndVegChallengeJoined from "./FruitAndVegChallengeJoined";

const FruitAndVegChallenge = () => {
  const { member } = useMember();

  return (
    <>
      {member?.eligibleForFruitAndVegMayCampaign ? (
        <FruitAndVegChallengeJoined />
      ) : (
        <FruitAndVegChallengeIntro />
      )}
    </>
  );
};

export default FruitAndVegChallenge;
