import { FC } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { DiscretionaryPercentData } from "../../services/servings";
import { LIGHT_GREY } from "../../theme";
import clsx from "clsx";

interface Props {
  discretionaryPercentData: DiscretionaryPercentData;
  loading: boolean;
}

const BAR_HEIGHT = 150;
const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    borderRadius: 5,
    width: 36,
    height: BAR_HEIGHT,
    position: "relative",
    display: "flex",
  },
  fill: {
    width: 36,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#104239",
    position: "absolute",
    bottom: 0,
    transition: "height 0.5s",
  },
  percent: {
    marginTop: spacing(1),
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
  lowPercent: {
    position: "absolute",
    bottom: spacing(0.5),
    left: 0,
    right: 0,
  },
  noData: {
    color: palette.text.secondary,
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
  },
}));
const MainVsSometimesFoodsTrendBar: FC<Props> = ({
  discretionaryPercentData,
  loading,
}) => {
  const classes = useStyles();
  const { discretionaryPercent, emptyServings } = discretionaryPercentData;
  const goodPercent = 100 - discretionaryPercent;
  const showEmpty = emptyServings && !loading;
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: showEmpty ? LIGHT_GREY : "#FFD60A",
      }}
    >
      {showEmpty ? null : (
        <div
          className={classes.fill}
          style={{
            height: (BAR_HEIGHT * goodPercent) / 100,
          }}
        >
          <Typography
            variant="body2"
            className={clsx(classes.percent, {
              [classes.lowPercent]: goodPercent <= 15,
            })}
            aria-label={`Main: ${goodPercent}% - Sometimes: ${discretionaryPercent}%`}
            role={"group"}
          ></Typography>
        </div>
      )}
    </div>
  );
};

export default MainVsSometimesFoodsTrendBar;
