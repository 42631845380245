import { Tabs, withStyles } from "@material-ui/core";

// TODO work out how to type this properly
// @ts-ignore
const StyledTabs: typeof Tabs = withStyles((theme) => ({
  root: {
    minHeight: "auto",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        columnGap: "8px",
      },
    },
    "&.sticky .MuiTabs-flexContainer": {
      columnGap: "4px",
    },
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 3,
    "& > span": {
      maxWidth: 60,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} />);

export default StyledTabs;
