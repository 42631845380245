import { Tooltip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  FC,
  ReactChild,
  ReactElement,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { WHITE } from "../../../theme";
import {
  NewFeatureId,
  NewFeaturesContext,
  NewFeatureState,
} from "../context/NewFeaturesProvider";
import NewFeatureContent from "./NewFeatureContent";

const tooltipBackgroundColor = WHITE;

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: tooltipBackgroundColor,
  },
  tooltip: {
    backgroundColor: tooltipBackgroundColor,
    padding: theme.spacing(2),
    fontSize: 12,
    minWidth: 180,
    pointerEvents: "auto",
    color: theme.palette.text.primary,
  },
}));

interface Props {
  id: NewFeatureId;
  children: ReactElement<any, any>;
  title: string;
  description: ReactChild;
}

export const NewFeatureTooltip: FC<Props> = ({
  id,
  title,
  description,
  children,
}) => {
  const { getState, onAcknowledge, onBack } = useContext(NewFeaturesContext);
  const [newFeatureState, setNewFeatureState] = useState<NewFeatureState>();
  const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
  const tooltipClasses = useStylesTooltip();

  useEffect(() => {
    setNewFeatureState(getState(id));
  }, [id, getState]);

  useLayoutEffect(() => {
    if (newFeatureState?.open && scrollRef) {
      scrollRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [newFeatureState?.open, scrollRef]);

  if (!newFeatureState) {
    return <>{children}</>;
  }

  const { currentStep, totalSteps, open } = newFeatureState;

  return (
    <>
      <Tooltip
        classes={tooltipClasses}
        title={
          <NewFeatureContent
            onAcknowledge={onAcknowledge}
            onBack={onBack}
            currentStep={currentStep}
            totalSteps={totalSteps}
            title={title}
          >
            {description}
          </NewFeatureContent>
        }
        open={open}
        arrow
      >
        <div ref={setScrollRef}>{children}</div>
      </Tooltip>
    </>
  );
};
