import { FC } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as HealthierOptionsIcon } from "../../icons/healthier-options-icon.svg";
import { ReactComponent as MainVsSometimesIcon } from "../../icons/main-vs-sometimes-icon.svg";
import { ReactComponent as NutrientTrackerIcon } from "../../icons/nutrient-tracker-icon.svg";
import { ReactComponent as RecipesIcon } from "../../icons/recipes-green-icon.svg";
import { ReactComponent as HealthInspiration } from "../../icons/health-inspiration-logo.svg";
import FeatureCarousel from "./FeatureCarousel";
import { DARK_GREEN } from "../../theme";
import { useMediaQuery } from "@material-ui/core";
import { isEdrApp, isWowApp } from "../../utils/misc";

export const FEATURE_IDS = {
  HEALTH_INSPIRATION: "healthInspiration",
};

export const FeatureCarouselWithHeader: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isEDRorWOW = isEdrApp() || isWowApp();

  const useStyles = makeStyles(({ breakpoints }) => ({
    root: {
      marginTop: "12px",
      [breakpoints.up("md")]: {
        background: "white",
        padding: "32px",
        borderRadius: "8px",
        margin: "48px 0px",
      },
    },
    headerText: {
      fontFamily: "'Gilroy-Semibold'",
      fontWeight: "normal",
      margin: 0,
      display: isEDRorWOW ? "block" : "none",
      [breakpoints.down("sm")]: {
        marginTop: "32px",
        marginBottom: "12px",
        paddingLeft: "4px",
        fontSize: "22px",
        lineHeight: "28px",
        color: "white",
      },
      [breakpoints.up("md")]: {
        display: "block",
        color: DARK_GREEN,
        marginBottom: "24px",
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    healthierOptionsIcon: {
      [breakpoints.up("md")]: {
        width: "32px",
        height: "32px",
      },
    },
    recipesIcon: {
      [breakpoints.up("md")]: {
        width: "24px",
        height: "24px",
      },
    },
    nutrientTrackerIcon: {
      [breakpoints.up("md")]: {
        width: "32.5px",
        height: "32.5px",
      },
    },
    goalTrackingIcon: {
      [breakpoints.up("md")]: {
        width: "32px",
        height: "32px",
      },
    },
    mainVsSometimesIcon: {
      [breakpoints.up("md")]: {
        width: "23px",
        height: "28px",
      },
    },
    healthInspirationsIcon: {
      [breakpoints.up("md")]: {
        width: "30px",
        height: "30px",
      },
    },
    foodGroupInsightsIcon: {
      [breakpoints.up("md")]: {
        width: "30px",
        height: "30px",
      },
    },
  }));

  const classes = useStyles();

  const FEATURES = [
    {
      title: "Healthier Options",
      icon: <HealthierOptionsIcon className={classes.healthierOptionsIcon} />,
      route: "/healthier-options/plp?source=homepage",
    },
    {
      title: "Nutrient Tracker",
      icon: <NutrientTrackerIcon className={classes.nutrientTrackerIcon} />,
      route: "/nutrient-tracker",
    },
    {
      title: "Recipes",
      icon: <RecipesIcon className={classes.recipesIcon} />,
      route: "/recipes",
    },
    /*  {
      title: "Goal Tracking",
      icon: <GoalTrackingIcon className={classes.goalTrackingIcon} />,
      scrollId: "goal-tracking",
    },*/
    {
      title: "Main vs Sometimes",
      icon: <MainVsSometimesIcon className={classes.mainVsSometimesIcon} />,
      route: `/main-vs-sometimes`,
    },
    {
      title: "Healthy Inspiration",
      icon: <HealthInspiration className={classes.healthInspirationsIcon} />,
      scrollId: FEATURE_IDS.HEALTH_INSPIRATION,
    },
    /*{
      title: "Food group insights",
      icon: <FoodGroupInsightsIcon className={classes.foodGroupInsightsIcon} />,
      scrollId: "food-group-insights",
    },*/
  ].filter((feature) => {
    if (isMobile) {
      return feature.title !== "Healthy Inspiration";
    } else {
      return true;
    }
  });

  return (
    <div className={classes.root}>
      <Typography className={classes.headerText}>My Food Tracker</Typography>
      <FeatureCarousel features={FEATURES} />
    </div>
  );
};
