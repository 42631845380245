import makeStyles from "@material-ui/core/styles/makeStyles";
import { createElement, FC } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { CircularProgress, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginRight: spacing(2),
    textTransform: "uppercase",
    borderRadius: "12px",
    backgroundColor: "#B9E04C",
    boxShadow: "none",
    padding: "12px 24px",
    color: "#0A2822",
    "&:hover": {
      backgroundColor: "#A0C734",
      boxShadow: "none",
    },
  },
  icon: {
    marginLeft: spacing(1),
    fontSize: 22,
  },
}));

const PrimaryIconButton: FC<
  ButtonProps & {
    loading?: boolean;
    icon: OverridableComponent<SvgIconTypeMap>;
  }
> = ({ children, loading, icon, ...props }) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} color="primary" {...props}>
      {children}
      {loading ? (
        <CircularProgress size="1.25rem" className={classes.icon} />
      ) : (
        <>
          {createElement(icon, {
            className: classes.icon,
          })}
        </>
      )}
    </Button>
  );
};

export default PrimaryIconButton;
