import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { TimeFrame } from "../constants";
import useEvents from "../hooks/useEvents";
import { endOfYesterday, getStartDateFromEndDate } from "../utils/dates";
import DateRangeIndicator from "./DateRangeIndicator";
import { SelectedTimeFrameContext } from "./SelectedTimeFrameProvider";
import StyledTab from "./tabs/StyledTab";
import StyledTabs from "./tabs/StyledTabs";
import { COLOR_GREYSCALE, WHITE } from "../theme";
import { HOMEPAGE_EVENT } from "../events";

const YESTERDAY = endOfYesterday();

const TimeFrameTabs = ({
  style,
  sticky = false,
  isHomePage = false,
  bgColor = COLOR_GREYSCALE[700],
  textColorMobile,
  textColorDesktop,
  reverseOrder = false,
  activeTabTextColor,
}: {
  style?: object;
  sticky?: boolean;
  bgColor?: string;
  textColorMobile?: string;
  textColorDesktop?: string;
  direction?: "row" | "column";
  reverseOrder?: boolean;
  activeTabTextColor?: string;
  isHomePage?: boolean;
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: reverseOrder ? "row-reverse" : "row",
        alignItems: "center",
      },
    },
    tabs: {
      padding: sticky ? "4px" : "8px",
      backgroundColor: WHITE,
      borderRadius: "16px",
      border: `solid 1px ${COLOR_GREYSCALE[400]}`,
      [theme.breakpoints.up("md")]: {
        margin: reverseOrder ? "0 0 0 24px" : "0 24px 0 0",
      },
    },
    dateRangeContainer: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        marginTop: "0px",
        marginBottom: "0px",
        display: "flex",
        alignItems: "center",
      },
    },
    tab: {
      padding: "8px 16px",
      fontFamily: "Gilroy-Semibold",
      fontWeight: "normal",
      lineHeight: "20px",
      fontSize: "14px",
      letterSpacing: "0.1px",
      color: COLOR_GREYSCALE[700],
      borderRadius: "8px",
      ...(sticky && { minHeight: "32px" }),
      "&.Mui-selected": {
        backgroundColor: bgColor,
        color: activeTabTextColor ? activeTabTextColor : WHITE,
      },
      [theme.breakpoints.up("md")]: {
        lineHeight: "24px",
        width: "120px",
        fontSize: "16px",
      },
    },
  }));
  const classes = useStyles();

  const { track } = useEvents();
  const { timeFrame, setTimeFrame } = useContext(SelectedTimeFrameContext);

  const handleTabChange = (_event: React.ChangeEvent<{}>, value: any) => {
    if (isHomePage) {
      if (value === "Month") {
        track(HOMEPAGE_EVENT.TIMEPERIOD_MONTHLY);
      } else if (value === "Fortnight") {
        track(HOMEPAGE_EVENT.TIMEPERIOD_FORTNIGHTLY);
      } else if (value === "Week") {
        track(HOMEPAGE_EVENT.TIMEPERIOD_WEEKLY);
      }
    }
    setTimeFrame(value);
    track("TimeFrame Changed", { selected: value });
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        value={timeFrame}
        onChange={handleTabChange}
        className={`${classes.tabs} ${sticky && "sticky"}`}
        style={style}
      >
        <StyledTab
          className={classes.tab}
          value={TimeFrame.Week}
          label="Weekly"
        />
        <StyledTab
          className={classes.tab}
          value={TimeFrame.Fortnight}
          label="Fortnightly"
        />
        <StyledTab
          className={classes.tab}
          value={TimeFrame.Month}
          label="Monthly"
        />
      </StyledTabs>
      <div className={classes.dateRangeContainer}>
        <DateRangeIndicator
          from={getStartDateFromEndDate(timeFrame, YESTERDAY)}
          to={YESTERDAY}
          textColorMobile={textColorMobile}
          textColorDesktop={textColorDesktop}
        />
      </div>
    </div>
  );
};

export default TimeFrameTabs;
