import SidebarCommunication from "./SidebarCommunication";
import SidebarFeedback from "./SidebarFeedback";
import SidebarHelpCentre from "./SidebarHelpCentre";
import SidebarMain from "./SidebarMain";
import SidebarOptout from "./SidebarOptout";
import SidebarPersonalisation from "./SidebarPersonalisation";
import SidebarRewards from "./SidebarRewards";
import SidebarTools from "./SidebarTools";
import { useLocation } from "react-router-dom";
import { useContext, useMemo } from "react";
import FloatingBackButton from "../../components/FloatingBackButton";
import { SettingsContext } from "../../components/providers/SettingsProvider";

const Sidebar = () => {
  const location = useLocation();
  const showBackButton = useMemo(() => {
    return location.pathname === "/settings";
  }, [location.pathname]);
  const { closeAllSidebar } = useContext(SettingsContext);

  return (
    <>
      {showBackButton && <FloatingBackButton onClick={closeAllSidebar} />}
      <SidebarMain />
      <SidebarTools />
      <SidebarHelpCentre />
      <SidebarFeedback />
      <SidebarRewards />
      <SidebarOptout />
      <SidebarPersonalisation />
      <SidebarCommunication />
    </>
  );
};

export default Sidebar;
