import { Document } from "@contentful/rich-text-types/dist/types/types";

export enum FoodGroup {
  Grains = "grains",
  Vegetables = "vegetables",
  Protein = "protein",
  Dairy = "dairy",
  Fruit = "fruit",
}

export type HealthierOptionsFoodGroup = FoodGroup | "all" | "discretionary";

export const FoodGroups = [
  FoodGroup.Vegetables,
  FoodGroup.Fruit,
  FoodGroup.Dairy,
  FoodGroup.Protein,
  FoodGroup.Grains,
];

export const SOMETIMES_FOOD = "sometimes";

export interface AgeGroup {
  id: string;
  name: string;
  enabled: boolean;
}

export interface AgeRange {
  id: string;
  name: string;
  from: number;
  to: number;
  group: AgeGroup;
  enabled: boolean;
}

export interface Gender {
  id: string;
  name: string;
  applicableAgeGroups: AgeGroup[];
  enabled: boolean;
}

export interface HealthCondition {
  id: string;
  name: string;
  applicableAgeRanges: AgeRange[];
  applicableGenders: Gender[];
  consentCopy?: ContentfulRichContent;
  precedence: number;
  enabled: boolean;
}

export interface HouseholdMember {
  id: string;
  createdAt: number;
  benchmarkId: string;
}

export interface HouseholdMemberWithBenchmark extends HouseholdMember {
  benchmark: Benchmark;
}

export interface Member {
  id: string;
  dataUsageConsented: boolean;
  consentedClient: string;
}

export interface Servings {
  grains: number;
  vegetables: number;
  protein: number;
  dairy: number;
  fruit: number;
  discretionary: number;
}

export interface Servings100g {
  grains100g: number;
  vegetables100g: number;
  protein100g: number;
  dairy100g: number;
  fruit100g: number;
  discretionary100g: number;
}

export interface AggregateDailyServings {
  purchaseDate: number;
  servings: Servings;
}

export interface Nutrients {
  addedSugar_G: number;
  totalSugar_G: number;
  saturatedFat_G: number;
  totalFat_G: number;
  sodium_MG: number;
}

export interface AggregateDailyNutrients {
  purchaseDate: number;
  nutrients: Nutrients;
}

export type NutrientValue = number | null;

export interface NutrientHistory {
  innerValue: NutrientValue;
  totalValue: NutrientValue;
}

export interface NutrientsHistory {
  month?: string;
  sugar: NutrientHistory;
  salt: NutrientHistory;
  fat: NutrientHistory;
}

export interface NutrientsTopContributors {
  sugar: BasketArticle[];
  salt: BasketArticle[];
  fat: BasketArticle[];
}

export interface Benchmark {
  id: string;
  title: string;
  ageRange: AgeRange;
  gender: Gender;
  healthCondition?: HealthCondition;
  dailyServings: Servings;
  enabled: boolean;
  saltTarget: number;
}

export interface LinkWithImage {
  title: string;
  url: string;
  image: ContentfulImage;
}

export interface ContentfulImage {
  url: string;
  description?: string;
}

export interface ContentfulRichContent {
  json: Document;
}

export interface ConsentHistoryRecord {
  date: number;
  consented: boolean;
  legals: {
    id: string;
    version: number;
  };
}

export interface NewFeaturesAcknowledgementRecord {
  version: number;
  date: number;
}

export enum BasketSyncStatus {
  Unknown = "UNKNOWN",
  Processing = "PROCESSING",
  Complete = "COMPLETE",
  Error = "ERROR",
}

export interface BasketSyncHistoryRecord {
  status: BasketSyncStatus;
  message?: string;
}

export interface BaseBasketArticle extends Servings {
  articleId: string;
  quantity: number;
}

export interface BaseBasketArticleWithPurchaseDate extends BaseBasketArticle {
  purchaseDate: string;
}

export interface HealthierOption {
  articleId: string;
  description: string;
  imageURL: string;
  healthyAttributes: {
    higherHealthStarRating: boolean;
    lowerFat: boolean | null;
    lowerSugar: boolean | null;
    lowerSalt: boolean | null;
  };
  dietaryPreferences: BasketArticleDietaryPreferences;
}

export interface BasketArticleDietaryPreferences {
  dairyFree: boolean;
  lactoseFree: boolean;
  glutenFree: boolean;
  vegan: boolean;
  vegetarian: boolean;
}

export interface BasketArticle extends BaseBasketArticleWithPurchaseDate {
  healthierOptions: HealthierOption[];
  imageURL: string | null;
  hasHealthierOption: boolean;
  dietaryPreferences: BasketArticleDietaryPreferences;
  description: string;
  discretionaryCategory: string;
  grains100g: number;
  vegetables100g: number;
  protein100g: number;
  dairy100g: number;
  fruit100g: number;
  discretionary100g: number;
  packSizeMultiplier: number;
  sodiumPer100g: number | null;
  fatSaturatedPer100g: number | null;
  fatTotalPer100g: number | null;
  addedSugarsPer100g: number | null;
  totalSugarsPer100g: number | null;
  addedSugars: number | null;
  fatSaturated: number | null;
  fatTotal: number | null;
  sodium: number | null;
  totalSugars: number | null;
  amountSpent: number;
  basketKey: string;
}

export interface BasketExclusion extends BasketArticle {
  excludedAt: number;
}

export interface BasketDay {
  purchaseDate: string;
  articles: BasketArticle[];
}

export type ShoppingAllocation = {
  [fg in FoodGroup]: number;
};

export interface FeedbackDialogConfiguration {
  enable: { value: boolean; timestamp: { seconds: number } };
  display: { delay: number; bottomOfPage: boolean };
}

export interface ShippingAllocationValues {
  grains: number;
  vegetables: number;
  protein: number;
  dairy: number;
  fruit: number;
}

export interface FeatureFlags {
  showNewDPCards?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  cachedMemberBasket?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  memberPrograms?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  dietaryPreferences?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  earlySwapsCampaign?: {
    enable?: {
      specificMembers?: string[];
    };
  };
  realTimeData?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  fruitAndVegChallenge?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  showCommunicationInsights?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
  showCommunicationWrapUp?: {
    enable?: {
      everybody?: boolean;
      specificMembers?: string[];
    };
  };
}

export interface FAQProps {
  title: string;
  content: ContentfulRichContent;
}

export interface NutrientTrackerTypes {
  type: "sugar" | "salt" | "fat";
}

export interface RecipeProps {
  id: string;
  title: string;
  image: string;
  prepTime: string;
  cookTime: string;
  complexity: string;
  description: string;
  url: string;
  tags: string[];
  foodGroup?: string[];
  ingredients?: string[];
  mealType?: string[];
  dietaryPreferences?: string[];
}
