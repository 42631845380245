import { makeStyles, Typography } from "@material-ui/core";
import {
  COLOR_GREYSCALE,
  COLOR_SUPPORT_ERROR,
  COLOR_SUPPORT_SUCCESS,
} from "../../theme";
import { cloneElement } from "react";
import { ReactComponent as ArrowUp } from "../../icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../icons/arrow-down.svg";
import { GOAL_DATA, GOALS } from "./config";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import { useHistory } from "react-router-dom";
import useEvents from "../../hooks/useEvents";
import { GOALS_EVENTS } from "../../events";

export const GoalViewBar = ({
  goal,
  percentage,
}: {
  goal: GOALS;
  percentage: number | null;
}) => {
  const { name, icon, goalType, nutrient, foodGroup, foodGroups, sometimes } =
    GOAL_DATA[goal];
  const useStyles = makeStyles(({ palette, breakpoints }) => ({
    root: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 77px",
      gridColumnGap: "3px",
      overflow: "hidden",
      cursor: "pointer",
      [breakpoints.up("md")]: {
        gridColumnGap: "8px",
      },
    },
    goalContainer: {
      alignItems: "center",
      display: "flex",
      gap: "8px",
      padding: "12px",
      borderRadius: "8px 0px 0px 8px",
      background: COLOR_GREYSCALE[50],
    },
    dataContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: COLOR_GREYSCALE[50],
      padding: "8px 7px",
      borderRadius: "0px 8px 8px 0px",
    },
    goalText: {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      width: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: 1,
    },
    noDataText: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.3px",
      textDecoration: "underline",
    },
    percentageWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: palette.text.primary,
      fontFamily: "Gilroy",
      borderRadius: "4px",
      padding: "0 8px",
      [breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    arrowWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "2px",
    },
    goalIcon: {
      flexShrink: 0,
    },
    arrowDownIcon: {
      margin: "5px",
      "& path": {
        fill:
          goalType === "increase"
            ? COLOR_SUPPORT_ERROR[500]
            : COLOR_SUPPORT_SUCCESS[500],
      },
    },
    arrowUpIcon: {
      "& path": {
        fill:
          goalType === "increase"
            ? COLOR_SUPPORT_SUCCESS[500]
            : COLOR_SUPPORT_ERROR[500],
      },
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const { track } = useEvents();
  const handleGoalClick = () => {
    track(GOALS_EVENTS.PROGRESS_VIEW, { goal: name });
    if (foodGroup) {
      history.push("/food-group/" + foodGroup);
    } else if (foodGroups) {
      history.push("/food-group/" + foodGroups[0]);
    } else if (nutrient === "addedSugar") {
      history.push("/nutrient-tracker?type=sugar");
    } else if (sometimes) {
      history.push("/food-group/sometimes");
    }
  };

  return (
    <div
      className={classes.root}
      onClick={handleGoalClick}
      onKeyPress={(e) => ifEnterOrSpace(e, handleGoalClick)}
      tabIndex={0}
    >
      <div className={classes.goalContainer}>
        {cloneElement(icon, { className: classes.goalIcon })}
        <Typography variant={"body2"} className={classes.goalText}>
          {name}
        </Typography>
      </div>
      <div className={classes.dataContainer}>
        {percentage !== null ? (
          <Typography
            variant="h6"
            component="h2"
            className={classes.percentageWrapper}
          >
            <>
              {percentage > 0 && "+"}
              {percentage}%
              {percentage !== 0 && (
                <div className={classes.arrowWrapper}>
                  {percentage < 0 ? (
                    <ArrowDown className={classes.arrowDownIcon} />
                  ) : (
                    <ArrowUp className={classes.arrowUpIcon} />
                  )}
                </div>
              )}
            </>
          </Typography>
        ) : (
          <Typography variant={"body2"} className={classes.noDataText}>
            No data
          </Typography>
        )}
      </div>
    </div>
  );
};
