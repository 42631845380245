import maxBy from "lodash/maxBy";
import noop from "lodash/noop";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  storeNewFeaturesAcknowledgement,
  subscribeToLatestNewFeaturesAcknowledgment,
} from "../../../services/members";
import { MemberContext } from "../../providers/MemberProvider";
import BlockingBackdrop from "./BlockingBackdrop";

interface Props {}

export interface NewFeatureState {
  open: boolean;
  currentStep: number;
  totalSteps: number;
}

interface Context {
  getState: (id: NewFeatureId) => NewFeatureState;
  onAcknowledge: () => Promise<void>;
  onBack: () => void;
  isFeaturesAcknowledged: boolean;
}

export type NewFeatureId =
  | "household-members"
  | "shopping-allocation"
  | "sometimes-food-breakdown"
  | "shopping-history"
  | "graph-help";

interface NewFeature {
  id: NewFeatureId;
  version: number;
}

// Ordered new features. Use version to show incremental changes with each new release.
const allNewFeatures: NewFeature[] = [
  { id: "household-members", version: 1 },
  { id: "shopping-allocation", version: 1 },
  { id: "sometimes-food-breakdown", version: 1 },
  { id: "shopping-history", version: 1 },
  { id: "graph-help", version: 1 },
];

interface MemberNewFeatures {
  ids: NewFeatureId[];
  version: number;
}

const EMPTY_FEATURES = {
  ids: [],
  version: -1,
};

export const NewFeaturesContext = createContext<Context>({
  getState: () => ({
    open: false,
    currentStep: 0,
    totalSteps: 0,
  }),
  onAcknowledge: () => Promise.resolve(),
  onBack: noop,
  isFeaturesAcknowledged: false,
});

export const NewFeaturesProvider: FC<Props> = ({ children }) => {
  const member = useContext(MemberContext);
  const [
    memberLatestAcknowledgementVersion,
    setMemberLatestAcknowledgementVersion,
  ] = useState<number>();
  const [registeredTooltipFeatures, setRegisteredTooltipFeatures] = useState<
    NewFeatureId[]
  >([]);
  const [openFeature, setOpenFeature] = useState<NewFeatureId>();
  const [newFeatures, setNewFeatures] =
    useState<MemberNewFeatures>(EMPTY_FEATURES);

  const memberId = member?.memberId;

  useEffect(() => {
    if (memberId) {
      return subscribeToLatestNewFeaturesAcknowledgment(memberId, (version) => {
        setMemberLatestAcknowledgementVersion(version);
      });
    }
  }, [memberId]);

  useEffect(() => {
    // Do not attempt to allow new features to be enabled until we know all the <NewFeatureTooltip /> components are rendered.
    if (
      registeredTooltipFeatures.length === allNewFeatures.length &&
      memberLatestAcknowledgementVersion !== undefined
    ) {
      const relevantFeatures = allNewFeatures.filter(
        (feat) => feat.version > memberLatestAcknowledgementVersion
      );
      if (relevantFeatures.length) {
        const featureIds = relevantFeatures.map((feat) => feat.id);
        setNewFeatures({
          ids: featureIds,
          version: maxBy(relevantFeatures, "version")!.version,
        });
        setOpenFeature(featureIds[0]);
      } else {
        setNewFeatures(EMPTY_FEATURES);
        setOpenFeature(undefined);
      }
    }
  }, [memberLatestAcknowledgementVersion, registeredTooltipFeatures]);

  const getState = useCallback(
    (id: NewFeatureId): NewFeatureState => {
      if (!registeredTooltipFeatures.includes(id)) {
        setRegisteredTooltipFeatures([...registeredTooltipFeatures, id]);
      }
      return {
        open: openFeature === id,
        currentStep: newFeatures.ids.indexOf(id) + 1,
        totalSteps: newFeatures.ids.length,
      };
    },
    [registeredTooltipFeatures, openFeature, newFeatures]
  );

  const onAcknowledge = useCallback(async () => {
    if (openFeature && memberId) {
      const nextIndex = newFeatures.ids.indexOf(openFeature) + 1;
      if (nextIndex < newFeatures.ids.length) {
        setOpenFeature(newFeatures.ids[nextIndex]);
      } else {
        await storeNewFeaturesAcknowledgement(memberId, newFeatures.version);
        setOpenFeature(undefined);
      }
    }
  }, [memberId, newFeatures, openFeature]);

  const onBack = useCallback(() => {
    if (openFeature) {
      const previousIndex = newFeatures.ids.indexOf(openFeature) - 1;
      if (previousIndex >= 0) {
        setOpenFeature(newFeatures.ids[previousIndex]);
      }
    }
  }, [newFeatures, openFeature]);

  return (
    <NewFeaturesContext.Provider
      value={{
        getState,
        onAcknowledge,
        onBack,
        isFeaturesAcknowledged: !!openFeature,
      }}
    >
      {children}
      <BlockingBackdrop open={!!openFeature} />
    </NewFeaturesContext.Provider>
  );
};
