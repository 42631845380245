import { Duration } from "luxon";
import { AgeGroup, Benchmark, FoodGroup } from "./models";
import tinycolor, { ColorInput } from "tinycolor2";

import { ReactComponent as grainsPieIcon } from "./icons/pie-grains.svg";
import { ReactComponent as vegetablesPieIcon } from "./icons/pie-vegetables.svg";
import { ReactComponent as proteinPieIcon } from "./icons/pie-protein.svg";
import { ReactComponent as dairyPieIcon } from "./icons/pie-dairy.svg";
import { ReactComponent as fruitPieIcon } from "./icons/pie-fruit.svg";

import { ReactComponent as grainsBarIcon } from "./icons/wbg-grains-v2.svg";
import { ReactComponent as vegetablesBarIcon } from "./icons/wbg-vegetables-v2.svg";
import { ReactComponent as proteinBarIcon } from "./icons/wbg-protein-v2.svg";
import { ReactComponent as dairyBarIcon } from "./icons/wbg-dairy-v2.svg";
import { ReactComponent as fruitBarIcon } from "./icons/wbg-fruit-v2.svg";

import { ReactComponent as grainsRoundedIcon } from "./icons/rounded-grains.svg";
import { ReactComponent as vegetablesRoundedIcon } from "./icons/rounded-vegetables.svg";
import { ReactComponent as proteinRoundedIcon } from "./icons/rounded-protein.svg";
import { ReactComponent as dairyRoundedIcon } from "./icons/rounded-dairy.svg";
import { ReactComponent as fruitRoundedIcon } from "./icons/rounded-fruit.svg";

import { ReactComponent as grainsRoundedIconV2 } from "./icons/rounded-grains-v2.svg";
import { ReactComponent as vegetablesRoundedIconV2 } from "./icons/rounded-veg-v2.svg";
import { ReactComponent as proteinRoundedIconV2 } from "./icons/rounded-protein-v2.svg";
import { ReactComponent as dairyRoundedIconV2 } from "./icons/rounded-dairy-v2.svg";
import { ReactComponent as fruitRoundedIconV2 } from "./icons/rounded-fruit-v2.svg";

import { TEAL } from "./theme";
import { getEnvironment } from "./utils/misc";

export enum TimeFrame {
  Day = "Day",
  Week = "Week",
  Fortnight = "Fortnight",
  Month = "Month",
}

export const TimeFrameDuration = {
  [TimeFrame.Day]: Duration.fromObject({ days: 1 }),
  [TimeFrame.Week]: Duration.fromObject({ weeks: 1 }),
  [TimeFrame.Fortnight]: Duration.fromObject({ weeks: 2 }),
  [TimeFrame.Month]: Duration.fromObject({ weeks: 4 }),
};

export const FoodGroupLabel = {
  [FoodGroup.Grains]: "Grains",
  [FoodGroup.Vegetables]: "Vegetables & legumes",
  [FoodGroup.Protein]: "Meat & alternatives",
  [FoodGroup.Dairy]: "Dairy & alternatives",
  [FoodGroup.Fruit]: "Fruit",
};

export const FoodGroupColorIntake = {
  [FoodGroup.Grains]: "#FF9300",
  [FoodGroup.Vegetables]: "#97CC04",
  [FoodGroup.Protein]: "#FC4871",
  [FoodGroup.Dairy]: "#0071E1",
  [FoodGroup.Fruit]: "#A847DA",
};

const lighten = (color: ColorInput) =>
  tinycolor(color).setAlpha(0.2).toHex8String();

const darken = (color: ColorInput) => tinycolor(color).darken(10).toHexString();

const mapColors = (transformFn: (color: ColorInput) => string) =>
  Object.entries(FoodGroupColorIntake).reduce((result, [foodGroup, color]) => {
    result[foodGroup] = transformFn(color);
    return result;
  }, {} as { [key: string]: string });

export const FoodGroupColorRecommended = mapColors(lighten);

export const FoodGroupColorExceeded = mapColors(darken);

export const FoodGroupPieChartIcon = {
  [FoodGroup.Grains]: grainsPieIcon,
  [FoodGroup.Vegetables]: vegetablesPieIcon,
  [FoodGroup.Protein]: proteinPieIcon,
  [FoodGroup.Dairy]: dairyPieIcon,
  [FoodGroup.Fruit]: fruitPieIcon,
};

export const FoodGroupBarChartIcon = {
  [FoodGroup.Grains]: grainsBarIcon,
  [FoodGroup.Vegetables]: vegetablesBarIcon,
  [FoodGroup.Protein]: proteinBarIcon,
  [FoodGroup.Dairy]: dairyBarIcon,
  [FoodGroup.Fruit]: fruitBarIcon,
};

export const FoodGroupRoundedIcon = {
  [FoodGroup.Grains]: grainsRoundedIcon,
  [FoodGroup.Vegetables]: vegetablesRoundedIcon,
  [FoodGroup.Protein]: proteinRoundedIcon,
  [FoodGroup.Dairy]: dairyRoundedIcon,
  [FoodGroup.Fruit]: fruitRoundedIcon,
};

export const FoodGroupRoundedIconV2 = {
  [FoodGroup.Grains]: grainsRoundedIconV2,
  [FoodGroup.Vegetables]: vegetablesRoundedIconV2,
  [FoodGroup.Protein]: proteinRoundedIconV2,
  [FoodGroup.Dairy]: dairyRoundedIconV2,
  [FoodGroup.Fruit]: fruitRoundedIconV2,
};

export const FoodGroupWithBGIconV2 = {
  [FoodGroup.Grains]: grainsBarIcon,
  [FoodGroup.Vegetables]: vegetablesBarIcon,
  [FoodGroup.Protein]: proteinBarIcon,
  [FoodGroup.Dairy]: dairyBarIcon,
  [FoodGroup.Fruit]: fruitBarIcon,
};

export const FoodGroupPageColors = {
  [FoodGroup.Grains]: "#F5B100",
  [FoodGroup.Vegetables]: TEAL,
  [FoodGroup.Protein]: "#DD3515",
  [FoodGroup.Dairy]: "#0360B1",
  [FoodGroup.Fruit]: "#80871C",
  discretionary: "#D19CE9",
};

export const ExternalLinks = {
  HowMuchEachDay:
    "https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day",
  AustralianDietaryGuidelines: "https://www.eatforhealth.gov.au/",
  TermsAndConditions: "https://www.healthylife.com.au/terms-conditions",
  PrivacyPolicy: "https://www.healthylife.com.au/privacy-policy",
  CollectionNotice:
    "https://www.healthylife.com.au/why-we-collect-your-personal-information-collection-notice",
  FrequentlyAskedQuestions:
    "https://www.healthylife.com.au/healthylife-food-tracker-frequently-asked-questions",
  HealthStarRating:
    "http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/Content/home",
  HealthStarRatingCalculator:
    "http://www.healthstarrating.gov.au/internet/healthstarrating/publishing.nsf/Content/excel-calculator",
};

const DefaultAgeGroup: AgeGroup = {
  id: "default",
  name: "Adult",
  enabled: false,
};

export const DefaultBenchmark: Benchmark = {
  id: "default",
  title: "Default",
  ageRange: {
    id: "default",
    name: "19 - 50",
    from: 19,
    to: 50,
    group: DefaultAgeGroup,
    enabled: false,
  },
  gender: {
    id: "default",
    name: "Male",
    applicableAgeGroups: [DefaultAgeGroup],
    enabled: false,
  },
  dailyServings: {
    grains: 6,
    vegetables: 5.5,
    fruit: 2,
    dairy: 2.5,
    protein: 3,
    discretionary: 3,
  },
  enabled: false,
  saltTarget: 2000,
};

export const DefaultShoppingAllocation = {
  [FoodGroup.Vegetables]: 1,
  [FoodGroup.Protein]: 1,
  [FoodGroup.Dairy]: 1,
  [FoodGroup.Fruit]: 1,
  [FoodGroup.Grains]: 1,
};

export const ServesThresholdsForProductBreakdown = {
  [FoodGroup.Vegetables]: 0.15,
  [FoodGroup.Protein]: 0.04,
  [FoodGroup.Dairy]: 0.04,
  [FoodGroup.Fruit]: 0.08,
  [FoodGroup.Grains]: 0.1,
};

export enum HttpStatusCodes {
  Unauthorized = 401,
  NotFound = 404,
}

export const NUTRIENT_TRACKER_EVENTS = {
  TOP_CONTRIBUTOR_CLICK: "nutrient_tracker_top_contributor_click",
  SWITCH_NUTRIENT: "nutrient_tracker_switch_nutrient",
  HELP_MODAL_OPEN: "nutrient_tracker_help_modal_open",
  HELP_MODAL_CLOSE: "nutrient_tracker_help_modal_close",
  PROGRAM_READ_MORE_CLICK: "nutrient_tracker_program_read_more_click",
  LEARN_MORE_DROP_DOWN_CLICK: "nutrient_tracker_learn_more_drop_down_click",
};

export const HEALTHIER_OPTIONS_EVENTS = {
  PLP_PAGE_VIEW: "healthier_options_plp_page_view",
  PLP_FILTER: "healthier_options_plp_filter",
  PLP_PRODUCT_CLICK: "healthier_options_plp_product_click",
  PDP_PAGE_VIEW: "healthier_options_pdp_page_view",
  CAROUSEL_GREEN_CIRCLE_BUTTON_CLICK:
    "healthier_options_carousel_green_circle_button_click",
  CAROUSEL_MAIN_BUTTON_CLICK: "healthier_options_carousel_main_button_click",
  CAROUSEL_PREVIOUS_BUTTON_CLICK:
    "healthier_options_carousel_previous_button_click",
  CAROUSEL_NEXT_BUTTON_CLICK: "healthier_options_carousel_next_button_click",
  CAROUSEL_PRODUCT_CLICK: "healthier_options_carousel_product_click",
  FAQ_ACCORDION_OPEN: "healthier_options_faq_accordion_open",
  FAQ_ACCORDION_CLOSE: "healthier_options_faq_accordion_close",
  HELP_MODAL_OPEN: "healthier_options_help_modal_open",
  HELP_MODAL_CLOSE: "healthier_options_help_modal_close",
};

export const PERSONALISE_MODAL_EVENTS = {
  OPEN: "personalise_modal_open",
  CLOSE: "personalise_modal_close",
  SAVE: "personalise_modal_save",
};

export const RECIPES_EVENTS = {
  MAIN_TILE_LEARN_MORE_CLICK: "recipes_main_tile_learn_more_click",
  HELP_MODAL_OPEN: "recipes_help_modal_open",
  HELP_MODAL_CLOSE: "recipes_help_modal_close",
  FAQ_ACCORDION_OPEN: "recipes_faq_accordion_open",
  FAQ_ACCORDION_CLOSE: "recipes_faq_accordion_close",
  RLP_FILTER_OPEN: "recipes_rlp_filter_open",
  RLP_FILTER_CLOSE: "recipes_rlp_filter_close",
  RLP_FILTER_APPLY_FILTER: "recipes_rlp_filter_apply_filter",
  RLP_FILTER_SELECT_FOOD_GROUP: "recipes_rlp_filter_select_food_group",
  RLP_FILTER_SELECT_MEAL_TYPE: "recipes_rlp_filter_select_meal_type",
  RLP_FILTER_SELECT_INGREDIENT: "recipes_rlp_filter_select_ingredient",
  OPENED_RECIPE: "recipes_sent_to_wow_recipe",
  CAROUSEL_RECIPE_CLICK: "healthier_recipes_carousel_recipe_click",
};

export const NO_CONSENT_REDIRECT_URL =
  process.env.REACT_APP_NO_CONSENT_REDIRECT_URL ??
  "https://www.healthylife.com.au/healthylife-food-tracker";

export const UNAUTHORIZED_REDIRECT_URL =
  process.env.REACT_APP_UNAUTHORIZED_REDIRECT_URL ??
  "https://www.healthylife.com.au/account/login?returnUrl=/food-tracker";

export const NOT_FOUND_REDIRECT_URL =
  process.env.REACT_APP_NOT_FOUND_REDIRECT_URL ??
  "https://www.healthylife.com.au/account/login-success?returnUrl=/food-tracker";

export const REVERSE_PROXY_URL = process.env.REACT_APP_REVERSE_PROXY_BASE_URL;
export const HTTPS_REVERSE_PROXY_URL = "https://" + REVERSE_PROXY_URL;

export const PROXIED_FIRESTORE_URL = REVERSE_PROXY_URL + "/firestore";
export const PROXIED_FIREBASE_AUTH_URL = REVERSE_PROXY_URL + "/identitytoolkit";
export const PROXIED_CLOUD_FUNCTIONS_URL =
  HTTPS_REVERSE_PROXY_URL + `/cloudfunctions-${getEnvironment()}`;

export const POINTS_PROMO_START_DATE =
  process.env.REACT_APP_POINTS_PROMO_START_DATE;
export const POINTS_PROMO_END_DATE =
  process.env.REACT_APP_POINTS_PROMO_END_DATE;

export const WOW_EMAIL_SIGNUP_CAMPAIGN_START_DATE =
  process.env.REACT_APP_WOW_EMAIL_SIGN_UP_CAMPAIGN_START_DATE;
export const WOW_EMAIL_SIGNUP_CAMPAIGN_END_DATE =
  process.env.REACT_APP_WOW_EMAIL_SIGN_UP_CAMPAIGN_END_DATE;

export const EDR_EMAIL_SIGNUP_CAMPAIGN_START_DATE =
  process.env.REACT_APP_EDR_EMAIL_SIGN_UP_CAMPAIGN_START_DATE;
export const EDR_EMAIL_SIGNUP_CAMPAIGN_END_DATE =
  process.env.REACT_APP_EDR_EMAIL_SIGN_UP_CAMPAIGN_END_DATE;

export const HEALTHIER_RECIPE_URL =
  "https://foodhub.woolworths.com.au/content/woolworths-foodhub/en.mobile.tag=healthier-easier";

export const RECIPE_DISPLAY_LIMIT = 10;
