import React from "react";
import { ReactComponent as VeganCardIcon } from "../../icons/dietary-v-black.svg";
import { ReactComponent as VegetarianCardIcon } from "../../icons/dietary-vg-black.svg";
import { ReactComponent as GlutenFreeCardIcon } from "../../icons/dietary-gf-black.svg";
import { ReactComponent as DairyFreeCardIcon } from "../../icons/dietary-df-black.svg";
import { ReactComponent as LactoseFreeCardIcon } from "../../icons/dietary-lf-black.svg";
import { ReactComponent as KidneyBeansIcon } from "../../icons/kidney-beans.svg";
import { ReactComponent as SoyBeansIcon } from "../../icons/soy-beans.svg";
import { ReactComponent as CannelliniBeansIcon } from "../../icons/cannellini-beans.svg";
import { ReactComponent as ChickpeasIcon } from "../../icons/chickpeas.svg";
import { ReactComponent as AlmondsIcon } from "../../icons/almonds.svg";
import { ReactComponent as TofuIcon } from "../../icons/tofu.svg";
import { ReactComponent as TempehIcon } from "../../icons/tempeh.svg";
import { ReactComponent as TexturedVegProteinIcon } from "../../icons/textured-veg-protein.svg";
import { ReactComponent as PumpkinSeedsIcon } from "../../icons/pumpkin-seeds.svg";
import { ReactComponent as SesameSeedsIcon } from "../../icons/sesame-seeds.svg";
import { ReactComponent as AlmondMilkIcon } from "../../icons/almond-milk.svg";
import { ReactComponent as SoyMilkIcon } from "../../icons/soy-milk.svg";
import { ReactComponent as OatMilkIcon } from "../../icons/oat-milk.svg";
import { ReactComponent as YoghurtIcon } from "../../icons/yoghurt.svg";
import { ReactComponent as CheddarIcon } from "../../icons/cheddar.svg";
import { ReactComponent as ChickenEggsIcon } from "../../icons/chicken-eggs.svg";
import { ReactComponent as AppleIcon } from "../../icons/apple.svg";
import { ReactComponent as BokChoyIcon } from "../../icons/bokchoy.svg";
import { ReactComponent as RiceIcon } from "../../icons/rice.svg";
import { ReactComponent as BreadRollIcon } from "../../icons/bread-roll.svg";
import { ReactComponent as WhiteBreadIcon } from "../../icons/white-bread.svg";
import { ReactComponent as WhiteRiceIcon } from "../../icons/white-rice.svg";
import { ReactComponent as BasmatiIcon } from "../../icons/basmati.svg";
import { ReactComponent as MuesliIcon } from "../../icons/muesli.svg";
import { ReactComponent as LightbulbIcon } from "../../icons/lightbulb-2.svg";
import { ReactComponent as SardinesIcon } from "../../icons/sardines.svg";
import { ReactComponent as FigsIcon } from "../../icons/figs.svg";
import {
  HorizontalTable,
  VerticalBlankTable,
  VerticalSingleTable,
  VerticalTable,
} from "../table";
import { Text } from "../Text";
import Blockquote from "../Blockquote";

const inlineIcon = {
  display: "inline-flex",
  verticalAlign: "middle",
};

export const VEGAN_DRAWER_CONTENT = {
  TITLE: "Vegan",
  RECIPES: [
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-barbecued-smashed-potato-salad",
      id: "healthier-barbecued-smashed-potato-salad",
      title: "Healthier Barbecued Smashed Potato Salad",
      description:
        "Drizzled with a tangy mustard dressing, this delicious healthier potato salad is the perfect addition to your next summer barbecue.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2401-barbecued-smashed-potato-salad:Square-1300x1300?fmt=png-alpha",
      prepTime: "15",
      cookTime: "35",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-veggie-and-balsamic-onion-skewers",
      id: "healthier-veggie-and-balsamic-onion-skewers",
      title: "Healthier Veggie & Balsamic Onion Skewers",
      description:
        "A perfect summer appetiser or light meal, these tasty meat-free skewers can be customised to your liking. Simply thread your fave veggies onto metal skewers and grill until tender. Serve drizzled with a moreish basil pesto.",
      complexity: "3",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2401-veggie-balsamic-onion-skewers:Square-1300x1300?fmt=png-alpha",
      prepTime: "30",
      cookTime: "10",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/watermelon-and-raspberry-iceblocks",
      id: "watermelon-and-raspberry-iceblocks",
      title: "Watermelon & Raspberry Iceblocks",
      description:
        "Get creative this summer and let the kids cool off with these homemade watermelon and raspberry iceblocks that are bursting with fruity flavours.",
      complexity: "1",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/Watermelon-and-raspberry-iceblocks:Square-1300x1300?fmt=png-alpha",
      prepTime: "15",
      cookTime: "0",
    },
  ],
  ARTICLES: [
    {
      url: "https://www.healthylife.com.au/learn/best-milk-alternative",
      title: "5 vegan milk alternatives to consider trying",
      description: "Vegan milks are popping up more and more on superm...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
    },
    {
      url: "https://www.healthylife.com.au/learn/is-david-attenborough-a-vegan",
      title: "5 common vegan questions answered",
      description: "Is David Attenborough a vegan? You might be surpri...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/1907e80d-3e2c-4e0b-8437-0874444bdbce/vegan-faqs.webp",
    },
  ],
  CARD_CONTENT: [
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-1-1.png",
      title: "Balancing your diet",
      content: [
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-1-1.png",
          title: "What to eat",
          content: [
            "If following a vegan diet: protein, iron, zinc and calcium are important nutrients to keep an eye on as they can often be lacking on a vegan diet.",
            <br />,
            "You can use the “Eat more protein” goal to help you meet serves of these important nutrients.",
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-1-2.png",
          title: "Make plant proteins priority",
          content: [
            "Choose a variety of colourful legumes such as lentils, peas and beans. Legumes are important sources of protein, iron and zinc in vegan diets.",
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-1-3.png",
          title: "Not all plant proteins are created equal",
          content: [
            <HorizontalTable
              headers={[
                { text: "Protein Type" },
                { text: "Serve size" },
                { text: "Grams of protein" },
              ]}
              rows={[
                [
                  { Icon: <TofuIcon />, text: "Firm Tofu" },
                  { text: "170g" },
                  { text: "20g" },
                ],
                [
                  { Icon: <KidneyBeansIcon />, text: "Kidney Beans" },
                  { text: "1 cup" },
                  { text: "10g" },
                ],
                [
                  { Icon: <AlmondsIcon />, text: "Raw almonds" },
                  { text: "30g" },
                  { text: "6g" },
                ],
              ]}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-2-1.png",
      title: "Meet your serves",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-2-1.png",
          title: "Cool beans",
          content: [
            "Choose a variety of colourful legumes such as lentils, peas and beans. Legumes are important sources of protein, iron and zinc in vegan diets.",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <KidneyBeansIcon />,
                  text: "Kidney Beans",
                },
                {
                  Icon: <SoyBeansIcon />,
                  text: "Soy beans",
                },
                {
                  Icon: <CannelliniBeansIcon />,
                  text: "Cannellini beans",
                },
                {
                  Icon: <ChickpeasIcon />,
                  text: "Chickpeas",
                },
              ]}
              body={"1 cup cooked"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-2-2.png",
          title: "Don’t forget the triple T’s",
          content: [
            "Tofu, tempeh and textured vegetable protein also count toward your serves.",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <TofuIcon />,
                  text: "Tofu",
                },
                {
                  Icon: <TempehIcon />,
                  text: "Tempeh",
                },
                {
                  Icon: <TexturedVegProteinIcon />,
                  text: "Textured vegetable protein",
                },
              ]}
              body={"170g"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-2-3.png",
          title: "Go nuts",
          content: [
            "Nuts and seeds contain many beneficial nutrients but keep in mind they have less protein, iron and zinc. Snack on a handful of almonds, sprinkle a handful of pumpkin or sesame seeds on your meal or try a nut spread on your crackers or sandwich.",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <AlmondsIcon />,
                  text: "Almonds",
                },
                {
                  Icon: <PumpkinSeedsIcon />,
                  text: "Pumpkin seeds",
                },
                {
                  Icon: <SesameSeedsIcon />,
                  text: "Sesame seeds",
                },
              ]}
              body={"30g (or a handful)"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-2-4.png",
          title: "Plant-based dairy still counts!",
          content: [
            "Look for calcium fortified plant milks such as almond, soy or oat milk. Where possible, choose products fortified with at least 100mg calcium per 100mL.",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <AlmondMilkIcon />,
                  text: "Almond milk",
                },
                {
                  Icon: <SoyMilkIcon />,
                  text: "Soy milk",
                },
                {
                  Icon: <OatMilkIcon />,
                  text: "Oat milk",
                },
              ]}
              body={"1 cup"}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-3-1.png",
      title: "Hot tips",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-3-1.png",
          title: "Up your iron",
          content: [
            "Leafy greens such as spinach provide iron. Vitamin C supports iron absorption from plants.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Squeeze some lemon juice on your leafy greens or pair veggies like broccoli or capsicum with your meal."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-3-2.png",
          title: (
            <span>
              Look for <VeganCardIcon style={inlineIcon} /> on your Healthier
              Options and Recipes
            </span>
          ),
          content: [
            "This assists in finding products and recipes that align with your dietary or lifestyle preferences.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "While a helpful tool, it's not a substitute for medical advice or reviewing product labels in full."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-3-3.png",
          title: "Need vitamin B12?",
          content: [
            "Vitamin B12 is only found in animal products and therefore some individuals may require a supplement.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Remember, everyone’s needs are different. If you need further advice, it’s important to discuss this with a healthcare professional."
              }
              icon={<LightbulbIcon />}
              hyperlink={
                "https://www.healthylife.com.au/telehealth/telehealth-appointment"
              }
            />,
          ],
        },
      ],
    },
  ],
};

export const VEGETARIAN_DRAWER_CONTENT = {
  TITLE: "Vegetarian",
  RECIPES: [
    {
      url: "https://www.woolworths.com.au/shop/recipes/orange-spiced-porridge-with-blueberries",
      id: "orange-spiced-porridge-with-blueberries",
      title: "Orange-spiced Porridge with Blueberries",
      description:
        "A comforting flask of porridge bursting with aromatic flavours makes a great on-the-go meal. Whip up this quick, easy, nutritious recipe for the kids.",
      complexity: "1",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2306-orange-spiced-porridge-with-blueberries:Square-1300x1300?fmt=png-alpha",
      prepTime: "5",
      cookTime: "15",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/spinach-ricotta-and-tomato-toastie",
      id: "spinach-ricotta-and-tomato-toastie",
      title: "Spinach, Ricotta & Tomato Toastie",
      description:
        "Embrace Italian flavours in this warm, toasted sandwich with low-fat ricotta and thick Roma tomatoes. ",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2305-spinach-ricotta-and-tomato-toastie:Square-1300x1300?fmt=png-alpha",
      prepTime: "15",
      cookTime: "5",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-apple-pie",
      id: "healthier-apple-pie",
      title: "Healthier Apple Pie ",
      description:
        "For a delicious, Snow White-inspired apple pie that is sure to please the whole family, choose this healthier version with Granny Smith apples, ricotta and filo pastry. ",
      complexity: "3",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/healthier-apple-pie:Square-1300x1300?fmt=png-alpha",
      prepTime: "30",
      cookTime: "35",
    },
  ],
  ARTICLES: [
    {
      url: "https://www.healthylife.com.au/learn/best-sources-of-iron-for-vegetarians",
      title: "The best sources of iron for vegetarians",
      description:
        "Iron is kind of a big deal. But if you're vegetarian, how do...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
    },
    {
      url: "https://www.healthylife.com.au/learn/high-protein-vegetarian-foods",
      title: "How to get more high protein vegetarian foods in your diet",
      description: "If you're vegetarian or vega...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/1907e80d-3e2c-4e0b-8437-0874444bdbce/vegan-faqs.webp",
    },
  ],
  CARD_CONTENT: [
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-1-1.png",
      title: "Balancing your diet",
      content: [
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-1-1.png",
          title: "What to eat",
          content: [
            "If you’re following a vegetarian diet: protein, B12, iron and zinc are key nutrients to keep an eye on as they can often be lacking in the diet.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                'Set yourself up for success with the "Eat more protein" goal to ensure you\'re nailing those crucial nutrient servings.'
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-1-2.png",
          title: "Enjoy meat alternatives",
          content: [
            "A duo of eggs packs a punch, fulfilling a protein serve in one go! When it comes to plant-based proteins, it’s important to choose a variety of protein sources to make sure you’re having a well-rounded vegetarian diet.",
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/v-card-1-3.png",
          title: "Not all vegetarian proteins are created equal",
          content: [
            <HorizontalTable
              headers={[
                { text: "Protein Type" },
                { text: "Serve size" },
                { text: "Grams of protein" },
              ]}
              rows={[
                [
                  { Icon: <ChickenEggsIcon />, text: "Chicken eggs" },
                  { text: "2 (Large)" },
                  { text: "15g" },
                ],
                [
                  { Icon: <KidneyBeansIcon />, text: "Kidney Beans" },
                  { text: "1 cup" },
                  { text: "10g" },
                ],
                [
                  { Icon: <TofuIcon />, text: "Firm Tofu" },
                  { text: "170g" },
                  { text: "20g" },
                ],
                [
                  { Icon: <AlmondsIcon />, text: "Raw almonds" },
                  { text: "30g" },
                  { text: "6g" },
                ],
              ]}
            />,
            <Text variant={"subtitle1"}>
              *Individual products may vary, this is a guide only
            </Text>,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-1.png",
      title: "Hit your serves",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-1.png",
          title: "Scrambled, poached or fried?",
          content: [
            "Eggs are a great source of protein, containing all nine essential amino acids needed to meet your body’s needs.  Plus, eggs are delish and can be enjoyed in so many ways!",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <ChickenEggsIcon />,
                  text: "Chicken eggs",
                },
              ]}
              body={"2"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-2.png",
          title: "Cool beans",
          content: [
            "Choose a variety of colourful legumes such as lentils, peas and beans. These foods play a crucial role in providing protein, iron, and zinc.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Try livening up your salads, stir fries and casseroles with kidney beans, soy beans, cannellini beans or chickpeas"
              }
              icon={<LightbulbIcon />}
            />,
            <div style={{ margin: "32px 0px" }} />,
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <KidneyBeansIcon />,
                  text: "Kidney beans",
                },
                {
                  Icon: <SoyBeansIcon />,
                  text: "Soy beans",
                },
                {
                  Icon: <CannelliniBeansIcon />,
                  text: "Cannellini beans",
                },
                {
                  Icon: <ChickpeasIcon />,
                  text: "Chickpeas",
                },
              ]}
              body={"1 cup cooked"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-3.png",
          title: "Don’t forget the triple T’s",
          content: [
            "Tofu, tempeh and textured vegetable protein also count toward your serves, and offer a great base for flavour in your meals. Think tofu stir fries, tempeh satay and more!",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <TofuIcon />,
                  text: "Tofu",
                },
                {
                  Icon: <TempehIcon />,
                  text: "Tempeh",
                },
                {
                  Icon: <TexturedVegProteinIcon />,
                  text: "Textured vegetable protein",
                },
              ]}
              body={"170g"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-4.png",
          title: "Go nuts",
          content: [
            "Nuts and seeds offer many beneficial nutrients but keep in mind they have less protein, iron and zinc compared to legumes. Snack on a handful of almonds, sprinkle a handful of pumpkin or sesame seeds on your meal or try a nut spread on your crackers or sandwich.",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <AlmondsIcon />,
                  text: "Almonds",
                },
                {
                  Icon: <PumpkinSeedsIcon />,
                  text: "Pumpkin seeds",
                },
                {
                  Icon: <SesameSeedsIcon />,
                  text: "Sesame seeds",
                },
              ]}
              body={"30g (or a handful)"}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-2-5.png",
          title: "Love your dairy",
          content: [
            "Milk, cheese and yoghurt count towards the Dairy & alternatives food group. These foods are an important source of calcium, protein, vitamins and minerals such as vitamin A, D, B12 and zinc.",
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-3-1.png",
      title: "Hot tips",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-3-1.png",
          title: "Balance is key",
          content: [
            "To reap the many benefits a vegetarian diet offers, it’s recommended to eat a balanced diet of fruit, veggies, grains, legumes, nuts, seeds, eggs and dairy.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "A good rule of thumb is to eat the rainbow. Fill your plate with a range of colours and aim for variety in fruit, vegetables, legumes, nuts and seeds"
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/vg-card-3-2.png",
          title: (
            <span>
              Look for <VegetarianCardIcon style={inlineIcon} /> on your
              Healthier Options and Recipes
            </span>
          ),
          content: [
            "This assists in finding products and recipes that align with your dietary or lifestyle preferences.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "While a helpful tool, it's not a substitute for medical advice or reviewing product labels in full."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
      ],
    },
  ],
};

export const GLUTEN_FREE_DRAWER_CONTENT = {
  TITLE: "Gluten free",
  RECIPES: [
    {
      url: "https://www.woolworths.com.au/shop/recipes/tomato-and-basil-omelette-wrap",
      id: "tomato-and-basil-omelette-wrap",
      title: "Tomato & Basil Omelette Wrap",
      description:
        "The classic flavour combination of tomato and basil elevates this nutritious omelette, making it an easy snack option for kids.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2309-tomato-and-basil-omelette-wrap:Square-1300x1300?fmt=png-alpha",
      prepTime: "15",
      cookTime: "5",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/cottage-cheese-ice-cream",
      id: "cottage-cheese-ice-cream",
      title: "Cottage Cheese Ice-cream",
      description:
        "Whip up a delicious and naturally protein-rich peanut-butter ice-cream with just six ingredients!",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2305-cottage-cheese-ice-cream:Square-1300x1300?fmt=png-alpha",
      prepTime: "245",
      cookTime: "0",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-veggie-and-balsamic-onion-skewers",
      id: "healthier-veggie-and-balsamic-onion-skewers",
      title: "Healthier Veggie & Balsamic Onion Skewers",
      description:
        "A perfect summer appetiser or light meal, these tasty meat-free skewers can be customised to your liking. Simply thread your fave veggies onto metal skewers and grill until tender. Serve drizzled with a moreish basil pesto.",
      complexity: "3",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2401-veggie-balsamic-onion-skewers:Square-1300x1300?fmt=png-alpha",
      prepTime: "30",
      cookTime: "10",
    },
  ],
  ARTICLES: [
    {
      url: "https://www.healthylife.com.au/learn/wheat-intolerance-symptoms",
      title: "Is bloating a symptom of wheat intolerance?",
      description:
        "If you’ve ever suffered from bloating, you know it can b...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
    },
  ],
  CARD_CONTENT: [
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-1-1.png",
      title: "Balancing your diet",
      content: [
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-1-1.png",
          title: "What to eat",
          content: [
            "Opt for wholegrain, gluten free cereals and breads. Look for the words “gluten free” and “wholegrain” or “wholemeal” on the pack.",
            <br />,
            "Confused on what’s gluten free or not? Grains that are gluten free include corn/maize, rice, quinoa, buckwheat, millet, amaranth, soy and legume flours.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Use the “Gluten free” filter when shopping for your grains on Woolworths online."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-1-2.png",
          title: "Don’t forget the main food groups",
          content: [
            "While gluten free grain options are key for the Grains food group, don’t forget the other main food groups: Fruit, Vegetables & legumes, Meat & alternatives, Dairy & alternatives for a well-rounded, balanced diet.",
            <br />,
            "You’ll find there are plenty of naturally gluten free foods. These include:",
            <VerticalBlankTable
              rows={[
                { icon: <AppleIcon />, text: "Fresh fruit" },
                { icon: <BokChoyIcon />, text: "Fresh veggies" },
                {
                  icon: <AlmondsIcon />,
                  text: "Unprocessed poultry and meat, eggs, legumes, nuts and seeds",
                },
                {
                  icon: <RiceIcon />,
                  text: "Dairy foods and alternatives such as milk, cheese and yoghurt",
                },
              ]}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-2-1.png",
      title: "Hit your serves",
      content: [
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-2-1.png",
          title: "How many grains do you actually need?",
          content: [
            "As well as being a key source of energy, protein, fibre, vitamins and minerals, grains are great for our gut health. Most people need 4-6 serves of grains per day",
            <Text variant={"h3"}>Serving sizes</Text>,
            <VerticalTable
              rows={[
                {
                  Icon: <WhiteBreadIcon />,
                  text: "Gluten free bread",
                  body: "1 slice (40g)",
                },
                {
                  Icon: <BreadRollIcon />,
                  text: "Gluten free roll or flat bread",
                  body: "½ medium (40g)",
                },
                {
                  Icon: <WhiteRiceIcon />,
                  text: "Cooked rice, gluten free pasta, buckwheat, polenta or quinoa",
                  body: "½ cup (75-120g)",
                },
                {
                  Icon: <MuesliIcon />,
                  text: "Gluten free muesli",
                  body: "¼ cup (30g)",
                },
              ]}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-3-1.png",
      title: "Hot tips",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-3-1.png",
          title: "Balance is key",
          content: [
            "If following a gluten free diet, it’s important to eat a balanced diet of fruit, veggies, gluten-free grains, legumes, nuts, seeds, eggs and dairy.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "A good rule of thumb is to eat the rainbow. Fill your plate with a range of colours and aim for variety."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-3-2.png",
          title: (
            <span>
              Look for <GlutenFreeCardIcon style={inlineIcon} /> on your
              Healthier Options and Recipes
            </span>
          ),
          content: [
            "This assists in finding products and recipes that align with your dietary or lifestyle preferences.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "While a helpful tool, it's not a substitute for medical advice or reviewing product labels in full."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/gf-card-3-3.png",
          title: "Get expert advice",
          content: [
            "If you think gluten may be affecting your health, it’s important to discuss this with a healthcare professional. ",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Remember, everyone’s needs are different. If you need further advice, it’s important to discuss this with a healthcare professional."
              }
              icon={<LightbulbIcon />}
              hyperlink={
                "https://www.healthylife.com.au/telehealth/telehealth-appointment"
              }
            />,
          ],
        },
      ],
    },
  ],
};

export const DAIRY_FREE_DRAWER_CONTENT = {
  TITLE: "Dairy free",
  RECIPES: [
    {
      url: "https://www.woolworths.com.au/shop/recipes/sushi-rice-paper-rolls",
      id: "sushi-rice-paper-rolls",
      title: "Sushi Rice Paper Rolls",
      description:
        "This creative recipe makes a great healthier snack for kids. It&#39;s packed with colour, easy to hold and delicious – tick, tick, tick.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/fi-2309-sushi-rice-paper-rolls:Square-1300x1300?fmt=png-alpha",
      prepTime: "30",
      cookTime: "0",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/chicken-and-corn-noodle-soup",
      id: "chicken-and-corn-noodle-soup",
      title: "Chicken & Corn Noodle Soup",
      description:
        "Pop this hearty chicken and corn noodle soup in a flask for a nutritious and delicious pack-and-go lunch. Full of flavour, the whole family will love this tasty recipe.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2306-chicken-and-corn-noodle-soup:Square-1300x1300?fmt=png-alpha",
      prepTime: "10",
      cookTime: "35",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/easy-meatballs",
      id: "easy-meatballs",
      title: "Easy Meatballs ",
      description:
        "Cook up a batch of this super yummy meatball recipe and stir through cooked spaghetti, serve in a sub or on rice. The kids will be asking for more.",
      complexity: "1",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2207-easy-meatballs:Square-1300x1300?fmt=png-alpha",
      prepTime: "10",
      cookTime: "30",
    },
  ],
  ARTICLES: [
    {
      url: "https://www.healthylife.com.au/learn/best-milk-alternative",
      title: "5 vegan milk alternatives to consider trying",
      description: "Vegan milks are popping up more and more on superm...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
    },
  ],
  CARD_CONTENT: [
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-1-1.png",
      title: "Balancing your diet",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-1-1.png",
          title: "What to eat",
          content: [
            "Since dairy products contain high amounts of calcium, you’ll need to look for calcium fortified alternatives to hit your daily requirements:",
            <VerticalBlankTable
              rows={[
                {
                  text: "Calcium fortified plant-based milk alternatives still count towards the Dairy food group.",
                },
                {
                  text: "Look for calcium fortified plant milks and yoghurts such as almond, soy or oat milk. Where possible, choose products fortified with at least 100mg calcium per 100mL. ",
                },
              ]}
            />,
            <Text variant={"h3"}>How much is a serve?</Text>,
            <VerticalSingleTable
              headers={[
                {
                  Icon: <AlmondMilkIcon />,
                  text: "Calcium fortified almond milk",
                },
                {
                  Icon: <SoyMilkIcon />,
                  text: "Calcium fortified soy milk",
                },
                {
                  Icon: <OatMilkIcon />,
                  text: "Calcium fortified oat milk",
                },
              ]}
              body={"1 cup"}
            />,
            <Text variant={"subtitle1"} style={{ marginTop: "8px" }}>
              *Individual products may vary, this is a guide only
            </Text>,
          ],
        },
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-1-2.png",
          title: "Let’s not forget the main food groups",
          content: [
            "If following a dairy free diet, it’s important to eat a balanced diet with each of the main food groups.",
            <br />,
            "Many of the main food groups contain foods that are naturally free of dairy. These include",
            <VerticalBlankTable
              rows={[
                {
                  icon: <AppleIcon />,
                  text: "Fresh fruit",
                },
                {
                  icon: <BokChoyIcon />,
                  text: "Fresh veggies",
                },
                {
                  icon: <AlmondsIcon />,
                  text: "Unprocessed poultry and meat, eggs, legumes, nuts and seeds",
                },
                {
                  icon: <BasmatiIcon />,
                  text: "Wholegrains",
                },
              ]}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-2-1.png",
      title: "Hit your serves",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-2-1.png",
          title: "Enjoy vegan sources of calcium",
          content: [
            "Dairy offers a good source of calcium, so being dairy free means it’s important to get an adequate intake of calcium from other sources. Different foods will have different amounts, so here’s a quick snapshot of some vegan sources of calcium.",
            <Text variant={"h3"}>How much calcium is in a serve?</Text>,
            <HorizontalTable
              headers={[
                { text: "Food" },
                { text: "Serve size" },
                { text: "Calcium per serve (mg)" },
              ]}
              rows={[
                [
                  { Icon: <TofuIcon />, text: "Firm Tofu" },
                  { text: "170g" },
                  { text: "540" },
                ],
                [
                  {
                    Icon: <SardinesIcon />,
                    text: "Sardines, canned with bones",
                  },
                  { text: "85g" },
                  { text: "460" },
                ],
                [
                  { Icon: <SoyBeansIcon />, text: "Cooked soybeans" },
                  { text: "175g" },
                  { text: "175" },
                ],
                [
                  { Icon: <SesameSeedsIcon />, text: "Sesame seeds" },
                  { text: "15g" },
                  { text: "9" },
                ],
                [
                  { Icon: <BokChoyIcon />, text: "Cooked cabbage or bok choy" },
                  { text: "1 cup" },
                  { text: "140" },
                ],
                [
                  { Icon: <AlmondsIcon />, text: "Almonds" },
                  { text: "30g" },
                  { text: "75" },
                ],
                [
                  { Icon: <FigsIcon />, text: "Dried figs" },
                  { text: "2" },
                  { text: "60" },
                ],
              ]}
            />,
            <Text variant={"subtitle1"} style={{ marginTop: "8px" }}>
              *Individual products may vary, this is a guide only
            </Text>,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-3-1.png",
      title: "Hot tips",
      content: [
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-3-2.png",
          title: (
            <span>
              Look for <DairyFreeCardIcon style={inlineIcon} /> on your
              Healthier Options and Recipes
            </span>
          ),
          content: [
            "This helps you spot products and recipes that align with your dietary or lifestyle preferences.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "While a helpful tool, it's not a substitute for medical advice or reviewing product labels in full."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/df-card-3-3.png",
          title: "Get expert advice",
          content: [
            "If you feel your dietary intake of calcium is constantly low, or if you need further dietary advice while going dairy free, it’s important to discuss this with a healthcare professional.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "A Nutritionist can help ensure you're getting enough calcium in your diet, especially when the recommended amount changes for different ages and life stages"
              }
              icon={<LightbulbIcon />}
              hyperlink={
                "https://www.healthylife.com.au/telehealth/telehealth-appointment"
              }
            />,
          ],
        },
      ],
    },
  ],
};

export const LACTOSE_FREE_DRAWER_CONTENT = {
  TITLE: "Lactose free",
  RECIPES: [
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-beef-tacos",
      id: "healthier-beef-tacos",
      title: "Healthier Beef Tacos",
      description:
        "For a healthier family dinner that's ready in 20 minutes, try this nutritionally approved taco recipe. ",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2201-taco-bowl:Square-1300x1300?fmt=png-alpha",
      prepTime: "10",
      cookTime: "10",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/satay-tofu-and-rice-bowls",
      id: "satay-tofu-and-rice-bowls",
      title: "Satay Tofu & Rice Bowls",
      description:
        "Stuck on meal ideas? This nutritionally approved satay bowl is just what you're looking for. It's ready in 20 minutes and perfect for lunch or dinner.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2112-satay-tofu-amd-rice-bowls:Square-1300x1300?fmt=png-alpha",
      prepTime: "10",
      cookTime: "10",
    },
    {
      url: "https://www.woolworths.com.au/shop/recipes/healthier-spiced-beef-and-green-bean-rice",
      id: "healthier-spiced-beef-and-green-bean-rice",
      title: "Healthier Spiced Beef & Green Bean Rice",
      description:
        "For full flavour, you've got to try this punchy pilaf. Made with brown rice, lean beef mince and green beans, it'll quickly become a family favourite.",
      complexity: "2",
      image:
        "https://foodhub.scene7.com/is/image/woolworthsltdprod/2201-Healthier-spiced-beef-and-green-bean-pilaf:Square-1300x1300?fmt=png-alpha",
      prepTime: "15",
      cookTime: "85",
    },
  ],
  ARTICLES: [
    {
      url: "https://www.healthylife.com.au/learn/lactose-intolerance",
      title: "Lactose Intolerance: What is it, symptoms &...",
      description: "Do you get bloating, nausea and abdominal cramps whe...",
      image:
        "https://assets-us-01.kc-usercontent.com/3e01c88d-6d32-0086-9f07-7574b3104890/ae7c5553-6eaf-4d11-8509-09e8587dd3bf/vegan-milk-alternatives.webp",
    },
  ],
  CARD_CONTENT: [
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-1-1.png",
      title: "Balancing your diet",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-1-1.png",
          title: "What’s lactose intolerance?",
          content: [
            "It’s when the body doesn’t produce enough lactase enzyme to break down lactose (a natural sugar found in dairy foods).",
            <br />,
            "The undigested lactose travels to the large intestine where it may cause unpleasant gut symptoms like bloating, diarrhoea or gas.",
            <img
              src={
                "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lactose-breakdown-chart-large.png"
              }
              alt={"Lactose Breakdown Graph"}
              style={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "8px",
              }}
            />,
            <Text variant={"subtitle1"}>
              When lactose enters the small intestine, the enzyme lactase is
              released to break lactose down into glucose and galactose
            </Text>,
          ],
        },
        {
          buttonColor: "#1D1F20",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-1-2.png",
          title: "What to eat",
          content: [
            "Lactose intolerance might mean you’re scanning the dairy aisle for lactose free options. But this doesn’t mean you have to avoid all dairy foods - it’s more about which foods and quantities you can tolerate.",
            <Text variant={"h3"}>Lactose free or low lactose options</Text>,
            <VerticalTable
              rows={[
                {
                  Icon: <OatMilkIcon />,
                  text: "Lactose free milk, yoghurt and cheeses",
                  body: "These contain the goodness of dairy but without the lactose. These products are usually produced by adding the enzyme lactase to break down the lactose so they don’t cause stomach upsets. Look out for lactose free on the label.",
                },
                {
                  Icon: <YoghurtIcon />,
                  text: "Yoghurt",
                  body: "Usually lower in lactose as the bacteria (good gut bugs) help to break it down.",
                },
                {
                  Icon: <CheddarIcon />,
                  text: "Hard cheeses (such as cheddar)",
                  body: "These are very low in lactose and therefore may be tolerated.",
                },
              ]}
            />,
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "Sometimes it’s about the quantity over time. Spread lactose-containing foods out during the day or eat them with other foods to slow down digestion."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-2-1.png",
      title: "Hit your serves",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-2-1.png",
          title: "Balance is key",
          content: [
            "Being lactose free shouldn’t stop you from enjoying a balanced, healthy diet from the other food groups including fruits, veggies, grains, meats, legumes, nuts, seeds and eggs.",
            <Text variant={"h3"}>
              Enjoy dairy and non-dairy sources of calcium
            </Text>,
            <HorizontalTable
              headers={[
                { text: "Food" },
                { text: "Serve size" },
                { text: "Calcium per serve (mg)" },
              ]}
              rows={[
                [
                  { Icon: <OatMilkIcon />, text: "Lactose free milk" },
                  { text: "1 cup" },
                  { text: "260" },
                ],
                [
                  { Icon: <YoghurtIcon />, text: "Yoghurt" },
                  { text: "¾ cup" },
                  { text: "370" },
                ],
                [
                  { Icon: <CheddarIcon />, text: "Hard cheeses" },
                  { text: "40g" },
                  { text: "280" },
                ],
                [
                  {
                    Icon: <SardinesIcon />,
                    text: "Sardines, canned with bones",
                  },
                  { text: "85g" },
                  { text: "460" },
                ],
                [
                  { Icon: <SoyBeansIcon />, text: "Cooked soybeans" },
                  { text: "175g" },
                  { text: "175" },
                ],
                [
                  { Icon: <BokChoyIcon />, text: "Cooked cabbage or bok choy" },
                  { text: "1 cup" },
                  { text: "140" },
                ],
                [
                  { Icon: <AlmondsIcon />, text: "Almonds" },
                  { text: "30g" },
                  { text: "75" },
                ],
              ]}
            />,
            <Text variant={"subtitle1"} style={{ marginTop: "8px" }}>
              *Individual products may vary, this is a guide only
            </Text>,
          ],
        },
      ],
    },
    {
      image:
        "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-3-1.png",
      title: "Hot tips",
      content: [
        {
          buttonColor: "white",
          image:
            "https://storage.googleapis.com/food-tracker-images-prod/df-screens/lf-card-3-1.png",
          title: (
            <span>
              Look for <LactoseFreeCardIcon style={inlineIcon} /> on your
              Healthier Options and Recipes
            </span>
          ),
          content: [
            "This helps you spot products and recipes that align with your dietary or lifestyle preferences.",
            <div style={{ margin: "16px 0px" }} />,
            <Blockquote
              whiteBg
              text={
                "While a helpful tool, it's not a substitute for medical advice or reviewing product labels in full."
              }
              icon={<LightbulbIcon />}
            />,
          ],
        },
      ],
    },
  ],
};
