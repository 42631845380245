import { useContext, useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import TimeFrameTabs from "../components/TimeFrameTabs";
import { makeStyles } from "@material-ui/core/styles";
import { PageLayout } from "../components-2/page-layout";
import RecipeList from "../components-2/recipe/RecipeList";
import RecipeFilters from "../components-2/recipe/Filters/RecipeFilters";
import useEvents from "../hooks/useEvents";
import RecipeHeader from "../components-2/recipe/RecipeHeader";
import RecipeFilterButton from "../components-2/recipe/Filters/RecipeFilterButton";
import { RecipeContext } from "../components/providers/RecipeProvider";
import { RECIPES_EVENTS } from "../constants";
import Drawer from "../components-2/Drawer";
import { COLOR_GREYSCALE } from "../theme";
import { useLocation } from "react-router-dom";
import { FilterProps } from "../hooks/useRecipes";
import { DietaryPreferencesContext } from "../components/providers/DietaryPreferencesProvider";
import useHideDietaryPreferences from "../hooks/useHideDietaryPreferences";

const RecipePage = () => {
  const {
    toggleFilter,
    setToggleFilter,
    filterGroups,
    setFilteredRecipes,
    setDietaryPreferences,
    setCurrentFilters,
    setTempFilters,
  } = useContext(RecipeContext);
  const { track } = useEvents();

  const { checked: preferences } = useContext(DietaryPreferencesContext);
  const hideDP = useHideDietaryPreferences();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    let f = [] as string[];
    if (!hideDP) {
      setDietaryPreferences(preferences);
      f = preferences.map((pref) => "dietarypreferences:" + pref);
    }
    if (query.get("source")) {
      let source = query.get("source");
      switch (query.get("source")) {
        case "vegetables":
          source = "vegetablesAndLegumes";
          break;
        case "protein":
          source = "meatAndAlternatives";
          break;
        case "dairy":
          source = "dairyAndAlternatives";
          break;
        default:
          source = query.get("source");
      }
      const foodGroup: FilterProps = filterGroups[1].filters.find(
        (item: any) => item.id === source
      )!;
      setFilteredRecipes([...f, "foodgroup:" + foodGroup.id]);
      setCurrentFilters([...f, "foodgroup:" + foodGroup.id]);
      setTempFilters([...f, "foodgroup:" + foodGroup.id]);
    } else {
      setFilteredRecipes(f);
      setCurrentFilters(f);
      setTempFilters(f);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles(({ breakpoints }) => ({
    recipePageWrapper: {
      marginTop: "24px",
      borderRadius: "12px",
      background: "#fff",
      padding: "16px",
      [breakpoints.up("md")]: {
        padding: "40px",
      },
    },
    hideOnMobile: {
      display: "none",
      [breakpoints.up("md")]: {
        display: "block",
      },
    },
    hideOnDesktop: {
      display: "block",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    timeFrameTabsContainer: {
      margin: "32px 0 24px 0",
      [breakpoints.up("md")]: {
        margin: "40px 0",
      },
    },
  }));
  const classes = useStyles();
  return (
    <PageLayout title={"Recipes"}>
      <div className={classes.timeFrameTabsContainer}>
        <TimeFrameTabs textColorMobile={COLOR_GREYSCALE[600]} />
      </div>

      <div className={classes.recipePageWrapper}>
        <div className={toggleFilter ? classes.hideOnMobile : ""}>
          <RecipeHeader />
          <RecipeFilterButton />
          <RecipeList />
        </div>

        {!isMobile ? (
          <div className={classes.hideOnMobile}>
            <Drawer
              anchor="right"
              isDrawerOpen={toggleFilter}
              handleDrawer={() => {
                track(RECIPES_EVENTS.RLP_FILTER_APPLY_FILTER);
                setToggleFilter(false);
              }}
            >
              <RecipeFilters />
            </Drawer>
          </div>
        ) : (
          toggleFilter && <RecipeFilters />
        )}
      </div>
    </PageLayout>
  );
};

export default RecipePage;
