import { useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as ArrowUp } from "../../icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../icons/arrow-down.svg";
import { COLOR_GREYSCALE, WHITE } from "../../theme";
import { ReactComponent as BarBoxIcon } from "../../icons/bar-box-icon.svg";
import useNutrients from "../../hooks/useNutrients";
import { getDifferenceFromLastMonth } from "./utils";
import NutrientTrackerExplainer from "./NutrientTrackerExplainer";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { SelectedTimeFrameContext } from "../../components/SelectedTimeFrameProvider";
import { ifEnterOrSpace } from "../../utils/keyboardNavigation";
import useEvents from "../../hooks/useEvents";
import { HOMEPAGE_EVENT } from "../../events";

const NutrientTrackerSummary = () => {
  const { nutrientsHistory } = useNutrients();
  const { timeFrame } = useContext(SelectedTimeFrameContext);
  const history = useHistory();
  const { track } = useEvents();

  const nutrientTrackerEmpty = useMemo(() => {
    const { fat, sugar, salt } = nutrientsHistory[nutrientsHistory.length - 1];
    return (
      fat.innerValue === null &&
      fat.totalValue === null &&
      sugar.innerValue === null &&
      sugar.totalValue === null &&
      salt.innerValue === null &&
      salt.totalValue === null
    );
  }, [nutrientsHistory]);

  const nutrientTrackerCompareTimePeriodEmpty = useMemo(() => {
    const { fat, sugar, salt } = nutrientsHistory[nutrientsHistory.length - 2];
    return (
      fat.innerValue === null &&
      fat.totalValue === null &&
      sugar.innerValue === null &&
      sugar.totalValue === null &&
      salt.innerValue === null &&
      salt.totalValue === null
    );
  }, [nutrientsHistory]);
  const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
    root: {
      background: "#FFF",
      position: "relative",
      marginTop: spacing(2),
      padding: "16px",
      borderRadius: 12,
      overflow: "hidden",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      [breakpoints.up("md")]: {
        padding: "24px",
        width: "100%",
      },
    },
    summaryWrapper: {
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "4px",
      width: "100%",
    },
    summary: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      background: COLOR_GREYSCALE[50],
      padding: "8px",
      overflow: "auto",
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
    percentageWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color:
        nutrientTrackerCompareTimePeriodEmpty || nutrientTrackerEmpty
          ? "rgb(88, 92, 96)"
          : palette.text.primary,
      background: WHITE,
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      borderRadius: "4px",
      padding: "0 8px",
    },
    arrowWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "2px",
    },
    label: {
      marginTop: "8px",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      alignItems: "center",
      color: nutrientTrackerEmpty ? COLOR_GREYSCALE[500] : COLOR_GREYSCALE[700],
    },
    latestValue: {
      marginTop: nutrientTrackerEmpty ? "inherit" : "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: nutrientTrackerEmpty ? COLOR_GREYSCALE[500] : COLOR_GREYSCALE[800],
    },
    headerTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      marginTop: "0px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D3D6D9",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      display: "inline-block",
      margin: "0 4px",
      fontSize: "16px",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.3px",
      [breakpoints.up("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
      },
    },
  }));

  const classes = useStyles();

  const summary: { type: "sugar" | "salt" | "fat"; label: string }[] = [
    {
      type: "sugar",
      label: "Added sugar",
    },
    {
      type: "salt",
      label: "Salt",
    },
    {
      type: "fat",
      label: "Saturated fat",
    },
  ];

  const handleTrack = (type: "sugar" | "salt" | "fat") => {
    switch (type) {
      case "sugar":
        return track(HOMEPAGE_EVENT.NT_SUGAR);
      case "salt":
        return track(HOMEPAGE_EVENT.NT_SALT);
      case "fat":
        return track(HOMEPAGE_EVENT.NT_FAT);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" component="h1" className={classes.headerTitle}>
        <div className={classes.titleContainer}>
          <BarBoxIcon />
          <Link
            to="/nutrient-tracker"
            aria-label="Go to Nutrient Tracker Page"
            onClick={() => {
              track(HOMEPAGE_EVENT.NT_CLICK);
            }}
            style={{ textDecoration: "none", color: COLOR_GREYSCALE[800] }}
          >
            <div className={classes.title}>Nutrient Tracker</div>
          </Link>

          <NutrientTrackerExplainer source="homepage" />
        </div>
        <Link
          to="/nutrient-tracker"
          aria-label="Go to Nutrient Tracker Page"
          onClick={() => {
            track(HOMEPAGE_EVENT.NT_CLICK);
          }}
          style={{
            textDecoration: "none",
            color: COLOR_GREYSCALE[800],
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChevronRight />
        </Link>
      </Typography>
      <Typography variant="body2" style={{ color: "#585C60" }}>
        {nutrientTrackerEmpty
          ? "Bummer! We don’t have anything to show you at the moment. Check back once you’ve shopped."
          : `Key nutrients in your basket compared to last ${timeFrame.toLowerCase()}`}
      </Typography>

      <div className={classes.summaryWrapper}>
        {summary.map((item, i) => {
          const difference = getDifferenceFromLastMonth(
            nutrientsHistory,
            item.type
          );
          const absoluteDifference = Math.abs(difference);
          return (
            <div
              className={classes.summary}
              key={i}
              tabIndex={0}
              onKeyPress={(e) =>
                ifEnterOrSpace(e, () => {
                  handleTrack(item.type);
                  history.push(`/nutrient-tracker?type=${item.type}`);
                })
              }
              onClick={() => {
                handleTrack(item.type);
                history.push(`/nutrient-tracker?type=${item.type}`);
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                className={classes.percentageWrapper}
              >
                {nutrientTrackerEmpty ? (
                  "0%"
                ) : nutrientTrackerCompareTimePeriodEmpty ? (
                  "No data"
                ) : (
                  <>
                    {absoluteDifference}%
                    <div className={classes.arrowWrapper}>
                      {difference < 0 ? <ArrowDown /> : <ArrowUp />}
                    </div>
                  </>
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                component="h2"
                className={classes.label}
              >
                {item.label}
              </Typography>
              <Typography
                variant="subtitle2"
                component="h2"
                className={classes.latestValue}
              >
                {nutrientTrackerEmpty ? (
                  "No data"
                ) : (
                  <>
                    {
                      nutrientsHistory[nutrientsHistory.length - 1][item.type]
                        .innerValue
                    }
                    {item.type === "salt" ? " mg" : " g"}
                  </>
                )}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NutrientTrackerSummary;
