import { makeStyles, Typography } from "@material-ui/core";
import { useState, FC } from "react";
import ExternalLink from "../../components/ExternalLink";

import {
  InfoModalContainer,
  InfoModalContent,
  InfoModalDefaultButton,
} from "../InfoModal";

import {
  Accordion,
  AccordionContainer,
  AccordionDetails,
  AccordionIcon,
  AccordionSummary,
} from "../Accordion";
import { FAQ_ACCORDION_EVENT, HOMEPAGE_EVENT } from "../../events";
import useEvents from "../../hooks/useEvents";

const useStyles = makeStyles(() => ({
  description: {
    letterSpacing: "0.3px",
    lineHeight: "20px",
  },
}));

const MainVsSometimesExplainer: FC = () => {
  const { track } = useEvents();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const faqTitles = [
    "What is a Sometimes foods?",
    "One of my items is showing up in sometimes foods. I thought this was healthy?",
  ];
  const handleAccordionChange =
    (panel: string, titleIndex: number) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        track(FAQ_ACCORDION_EVENT.OPEN, {title: faqTitles[titleIndex]});
      } else {
        track(FAQ_ACCORDION_EVENT.CLOSE, {title: faqTitles[titleIndex]});
      }
      setExpanded(newExpanded ? panel : false);
    };

  const classes = useStyles();
  return (
    <InfoModalContainer
      eventName={HOMEPAGE_EVENT.MAIN_SOMETIMES_HELP_OPEN}
      eventCloseName={HOMEPAGE_EVENT.MAIN_SOMETIMES_HELP_CLOSE}
      button={<InfoModalDefaultButton />}
    >
      <InfoModalContent>
        <Typography variant="body1" component="p">
          This shows you the overall split between main foods and Sometimes
          foods.
        </Typography>

        <Typography variant="body1" component="ul">
          <Typography variant="body2" component="li">
            Main foods support health and wellbeing
          </Typography>
          <Typography variant="body2" component="li">
            Sometimes foods are okay to eat in moderation, as part of a balanced
            and nutritious diet.
          </Typography>
        </Typography>

        <Typography variant="body1" component="p">
          Click through to see which items in your basket contribute to
          sometimes foods.
        </Typography>

        <Typography variant="h2" style={{ marginBottom: "32px" }}>
          FAQS
        </Typography>
        <AccordionContainer>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordionChange("panel1", 0)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AccordionIcon />}
            >
              What is a Sometimes foods?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" className={classes.description}>
                Sometimes food and drinks (also known as discretionary foods)
                are recommended to have only sometimes and in small amounts.
                These foods are high in additional energy (kilojoules) and low
                in nutrients the body needs. Sometimes foods typically contain
                high levels of saturated fats, sugar, salt and/or alcohol.
                Examples include cakes, biscuits, confectionery, chocolate,
                cream, potato chips and sugar sweetened beverages.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordionChange("panel2", 1)}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<AccordionIcon />}
            >
              One of my items is showing up in sometimes foods. I thought this
              was healthy?
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Sometimes foods have been determined based on the{" "}
                <ExternalLink
                  href="https://www.eatforhealth.gov.au/guidelines/guidelines"
                  target="_blank"
                  aria-label={"Health Star Rating"}
                  underline="always"
                >
                  Australian Health Survey: Users'
                </ExternalLink>{" "}
                Guide, 2011-13 — Discretionary Food List.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionContainer>
      </InfoModalContent>
    </InfoModalContainer>
  );
};

export default MainVsSometimesExplainer;
