import { MuiThemeProvider, ThemeOptions } from "@material-ui/core";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HttpStatusCodes } from "../constants";
import Routes from "../Routes";
import makeTheme from "../theme";
import AppErrorFallback from "./AppErrorFallback";
import { NewFeaturesProvider } from "./new-features/";
import AuthProvider from "./providers/AuthProvider";
import BasketDetailsProvider from "./providers/BasketDetailsProvider";
import BenchmarksProvider from "./providers/BenchmarksProvider";
import HouseholdProvider from "./providers/HouseholdProvider";
import MemberProvider from "./providers/MemberProvider";
import RealtimeListProvider from "./providers/RealtimeListProvider";
import SelectedTimeFrameBasketExclusionsProvider from "./providers/SelectedTimeFrameBasketExclusionsProvider";
import SelectedTimeFrameProvider from "./SelectedTimeFrameProvider";
import PersonalizationProvider from "./providers/PersonalizationProvider";
import RecipeProvider from "./providers/RecipeProvider";
import FeatureFlagProvider from "./providers/FeatureFlagProvider";
import SettingsProvider from "./providers/SettingsProvider";
import ConfettiProvider from "./providers/ConfettiProvider";
import DietaryPreferencesProvider from "./providers/DietaryPreferencesProvider";
import FruitAndVegCampaignPurchasesProvider from "./providers/FruitAndVegCampaignPurchasesProvider";

interface Props {
  jwt?: string;
  theme?: ThemeOptions;
  errorStatusCode?: HttpStatusCodes;
  onError?: (error: Error) => void;
}

const App: FC<Props> = ({ jwt, theme, errorStatusCode, onError }) => {
  const themeOverrides = theme ?? ({} as ThemeOptions);

  return (
    <MuiThemeProvider theme={makeTheme(themeOverrides)}>
      <ErrorBoundary
        FallbackComponent={AppErrorFallback}
        onError={onError ?? console.error}
      >
        <SettingsProvider>
          <BenchmarksProvider>
            <AuthProvider jwt={jwt}>
              <FeatureFlagProvider>
                <MemberProvider>
                  <DietaryPreferencesProvider>
                    <SelectedTimeFrameProvider>
                      <BasketDetailsProvider>
                        <FruitAndVegCampaignPurchasesProvider>
                          <SelectedTimeFrameBasketExclusionsProvider>
                            <RealtimeListProvider>
                              <HouseholdProvider>
                                <NewFeaturesProvider>
                                  <PersonalizationProvider>
                                    <RecipeProvider>
                                      <ConfettiProvider>
                                        <Routes
                                          errorStatusCode={errorStatusCode}
                                        />
                                      </ConfettiProvider>
                                    </RecipeProvider>
                                  </PersonalizationProvider>
                                </NewFeaturesProvider>
                              </HouseholdProvider>
                            </RealtimeListProvider>
                          </SelectedTimeFrameBasketExclusionsProvider>
                        </FruitAndVegCampaignPurchasesProvider>
                      </BasketDetailsProvider>
                    </SelectedTimeFrameProvider>
                  </DietaryPreferencesProvider>
                </MemberProvider>
              </FeatureFlagProvider>
            </AuthProvider>
          </BenchmarksProvider>
        </SettingsProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
};

export default App;
